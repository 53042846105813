import { Component, OnInit, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { SupplierCompanyFe } from 'src/app/model/data-suppliers/company/SupplierCompanyFe';
import { REQUEST_TASK_STATUS } from 'src/app/model/data-suppliers/request/RequestFe';
import { RequestGroupFe } from 'src/app/model/data-suppliers/request/RequestGroupFe';
import { QuestionDataTableFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionDataTableFe';
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize';
import { EntityFe } from 'src/app/model/taxonomy/EntityFe';
import { ContactFe } from 'src/app/model/user/ContactFe';
import { LanguageService } from 'src/app/services/LanguageServiceFe';
import { ResponsiveService } from 'src/app/services/ResponsiveService';
import { StateServiceFe } from 'src/app/services/StateServiceFe';
import { DateUtil } from 'src/app/utils/DateUtil';
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent';

@Component({
  selector: 'request-creation-launcher',
  templateUrl: './request-creation-launcher.component.html',
  styleUrls: ['./request-creation-launcher.component.scss', "../data-suppliers.component.scss"]
})
export class RequestCreationLauncherComponent extends AbstractLanguageComponent implements OnInit {
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  allRequestGroups: RequestGroupFe[]
  loadingInProgress = false
  selectedReqGroup: RequestGroupFe | null
  @Output() selectedReqGroupSubject: Subject<RequestGroupFe|null> = new Subject()
  viewNo = 1
  type = 'new';

  requestGroups: RequestGroupFe[] = []
  filteredRequestGroups: RequestGroupFe[] = []
  searchKeyword: string = '';
  sortedBy = null
  sortedByOptions = [
    {name: this.locale('locale_key.pages.datahub.sort.option.created_date'), value: 'createdDate', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.created_date_desc'), value: 'createdDateDes'},
    {name: this.locale('locale_key.pages.datahub.sort.option.deadline'), value: 'deadline', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.deadline_desc'), value: 'deadlineDes'},
    {name: this.locale('locale_key.pages.datahub.sort.option.name'), value: 'name', divider: true},
    {name: this.locale('locale_key.pages.datahub.sort.option.name_desc'), value: 'nameDes'}
  ]

  selectedStatus = null;
  customMultipleFilters: {name: string, value: SupplierCompanyFe | ContactFe | EntityFe, level?: number}[] = []
  filteredCompanies: SupplierCompanyFe[] = []
  filteredDataReporters: ContactFe[] = []
  filteredEntities: EntityFe[] = []
  taxonomyInfo
  

  constructor (public stateService: StateServiceFe, private modalService: BsModalService, languageService: LanguageService,  private responsive: ResponsiveService) {
    super(languageService)
    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    });

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    });
  }

  async ngOnInit (): Promise<void> {
    this.allRequestGroups = await this.stateService.getRequestGroups();
    let {depTaxonomy} = await this.stateService.getTaxonomyInfos()
    this.taxonomyInfo = depTaxonomy
    this.setFilterData()
  }

  setFilterData() {
    this.applyFilters();
    this.filterCompanies();
    this.filterDataReporters();
    this.filterEntities();
  }


  closeModal () {
    this.modalService.hide();
    document.body.classList.remove("modal-open");
  }

  create () {
    if (this.type == 'new') {
      this.selectedReqGroup = null;
    }
    this.selectedReqGroupSubject.next(this.selectedReqGroup);
    this.closeModal()
  }
  
  sort(option?) {
    if (option) {
      this.sortedBy = option; 
    }
    let sortFun;
    if (this.sortedBy.value == 'createdDate') {
      sortFun = (a: RequestGroupFe, b: RequestGroupFe) => {
        return DateUtil.getTimeDifference(a.date , b.date)
      }
    } else if (this.sortedBy.value == 'createdDateDes') {
      sortFun = (a: RequestGroupFe, b: RequestGroupFe) => {
        return DateUtil.getTimeDifference(b.date , a.date)
      }
    } else if (this.sortedBy.value == 'deadline') {
      sortFun = (a: RequestGroupFe, b: RequestGroupFe) => {
        return DateUtil.getTimeDifference(a.dueDate , b.dueDate)
      }
    } else if (this.sortedBy.value == 'deadlineDes') {
      sortFun = (a: RequestGroupFe, b: RequestGroupFe) => {
        return DateUtil.getTimeDifference(b.dueDate , a.dueDate)
      }
    } else if (this.sortedBy.value == 'name') {
      sortFun = (a: RequestGroupFe, b: RequestGroupFe) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1 
        } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1
        } else {
          return 0
        }
      }
    } else if (this.sortedBy.value == 'nameDes') {
      sortFun = (a: RequestGroupFe, b: RequestGroupFe) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return 1 
        } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return -1
        } else {
          return 0
        }
      }
    }
    if (sortFun) {
      this.filteredRequestGroups = this.filteredRequestGroups.sort(sortFun);
    }
  }

  applyFilters() {
    this.applyShowOnlyFilter();
  }

  applyShowOnlyFilter() {
    if (this.selectedStatus) {
      this.requestGroups = this.allRequestGroups.filter(f => f.status == this.selectedStatus)
    } else {
      this.requestGroups = this.allRequestGroups
    }
    this.applyCustomFilters();
  }

  clearAllFilters() {
    this.customMultipleFilters = [];
    this.selectedStatus = null;
    this.applyFilters();
  }

  changeCustomFilters(event: Event, value: SupplierCompanyFe | ContactFe | EntityFe, level?: number) {
    if ((event.target as HTMLInputElement).checked) {
      if (value instanceof SupplierCompanyFe) {
        this.customMultipleFilters.push({name: value.supplierCompanyname, value})
      } else if (value instanceof ContactFe) {
        this.customMultipleFilters.push({name: value.getName(), value})
      } else if (value instanceof EntityFe) {
        this.customMultipleFilters.push({name: value.getTaxonomyTitle(this.taxonomyInfo), value, level})
      } 
    } else {
      this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != value)
    }
    this.applyFilters();
  }

  applyCustomFilters() {
    if (this.customMultipleFilters.length > 0) {
      this.requestGroups = this.requestGroups.filter(requestGroup => {
        for (let f of this.customMultipleFilters) {
          let value = f.value
          if (value instanceof SupplierCompanyFe) {
            for (let request of requestGroup.requests) {
              if (request.supplierCompanyId == value['supplierCompanyId']) {
                return true;
              }
            }
          } else if (value instanceof ContactFe) {
            for (let request of requestGroup.requests) {
              if (request.assigneeAffId == value['affiliationId']) {
                return true;
              }
            }
          } else if (value instanceof EntityFe) {
            let taxonomyKeys = new Set<string>();
            if (f.level == 0) {
              let parent = this.taxonomyInfo.childrenSortedByOrdinal(value.key)
              let child = []
              parent.forEach(item => child.push(...this.taxonomyInfo.childrenSortedByOrdinal(item.key)))
              child.forEach(childItem => taxonomyKeys.add(childItem.key))
            } else if (f.level == 1) {
              let child = this.taxonomyInfo.childrenSortedByOrdinal(value.key)
              child.forEach(childItem => taxonomyKeys.add(childItem.key))
            } else {
              taxonomyKeys.add(value.key)
            }
            if (requestGroup.requests[0]?.questionnaire?.sections) {
              for (let section of requestGroup.requests[0].questionnaire.sections) {
                if (section.taxonomyKey && taxonomyKeys.has(section.taxonomyKey)) {
                  return true;
                }
              }
            }
          }
        }
        return false;
      });
    }
    this.applySearchFilter();
  }

  isCustomFilterSelected(value: any) {
    return this.customMultipleFilters.find(f => f.value == value);
  }

  applySearchFilter(keyword?: string) {
    if (keyword || keyword == '') {
      this.searchKeyword = keyword;
    }
    this.filteredRequestGroups = this.requestGroups.filter(rg => {
      if (rg.title.match(new RegExp(this.searchKeyword, "i"))) {
        return true;
      }
      let requests =rg.requests.filter(req=> req.getAssigneeName().match(new RegExp(this.searchKeyword, "i")))
      return requests.length > 0
    })
    if (this.sortedBy) {
      this.sort();
    }
  }

  async filterCompanies(keyword: string = '') {
    let relatedCompanyIds = new Set();
    this.allRequestGroups.forEach(rg => rg.requests.forEach(request => relatedCompanyIds.add(request.supplierCompanyId)));
    let companies = await this.stateService.getPartners()
    let relatedCompanies = companies.filter(company => relatedCompanyIds.has(company.supplierCompanyId));
    this.filteredCompanies = relatedCompanies.filter(f => f.supplierCompanyname.match(new RegExp(keyword, "i")))
  }

  async filterDataReporters(keyword: string = '') {
    let relatedContactAffIds = new Set();
    this.allRequestGroups.forEach(rg => rg.requests.forEach(request => relatedContactAffIds.add(request.assigneeAffId)));
    let contacts = await this.stateService.getActiveContacts()
    let relatedContacts = contacts.filter(contact => relatedContactAffIds.has(contact.affiliationId));
    this.filteredDataReporters = relatedContacts.filter(f => f.getName().match(new RegExp(keyword, "i")))
  }

  async filterEntities(keyword: string = '') {
    let allRootEntities = this.taxonomyInfo.childrenSortedByOrdinal()
    this.filteredEntities = allRootEntities.filter(root => {
      if (root.getLabel().match(new RegExp(keyword, "i"))) {
        return true
      }
      let parents = this.taxonomyInfo.childrenSortedByOrdinal(root.key)
      if (parents.find(p => p.getLabel().match(new RegExp(keyword, "i")))) {
        return true;
      }
      let found = false;
      for (let i = 0; i < parents.length; i++) {
        let children = this.taxonomyInfo.childrenSortedByOrdinal(parents[i].key)
        if (children.find(p => p.getLabel().match(new RegExp(keyword, "i")))) {
          found = true;
          return true;
        } 
      }
      return found;
    })
  }

  deselectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className);
    (Array.from(allCheckboxes)).forEach((el: HTMLInputElement) => el.checked = false)
    if (className == 'companyCheckbox') {
      this.filteredCompanies.forEach(company => { 
        this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != company)
      })
    } else if (className == 'contactCheckbox') {
      this.filteredDataReporters.forEach(contact => { 
        this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != contact)
      })
    } else if (className == 'entityCheckbox') {
      this.filteredEntities.forEach(enitity => { 
        this.customMultipleFilters = this.customMultipleFilters.filter(f => f.value != enitity)
      })
    }
    this.applyFilters();
  }

  selectAll(className: string) {
    let allCheckboxes = document.getElementsByClassName(className);
    (Array.from(allCheckboxes)).forEach((el: HTMLInputElement) => el.checked = true)
    if (className == 'companyCheckbox') {
      this.filteredCompanies.forEach(company => { 
        if (!this.customMultipleFilters.find(f => f.value == company)) {
          this.customMultipleFilters.push({name: company.supplierCompanyname, value: company})
        } 
      })
    } else if (className == 'contactCheckbox') {
      this.filteredDataReporters.forEach(contact => { 
        if (!this.customMultipleFilters.find(f => f.value == contact)) {
          this.customMultipleFilters.push({name: contact.getName(), value: contact})
        } 
      })
    } else if (className == 'entityCheckbox') {
      this.filteredEntities.forEach(enitity => { 
        if (!this.customMultipleFilters.find(f => f.value == enitity)) {
          this.customMultipleFilters.push({ name: enitity.getTaxonomyTitle(this.taxonomyInfo), value: enitity})
        } 
      })
    }
    this.applyFilters();
  }

  getCustomFilterText() {
    let text = '';
    if (this.selectedStatus == REQUEST_TASK_STATUS.CREATED) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.sent_requests')}`
    } else if (this.selectedStatus == REQUEST_TASK_STATUS.IN_PROGRESS) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.progress_requests')}`
    } else if (this.selectedStatus == REQUEST_TASK_STATUS.APPROVED) {
      text = `${this.locale('locale_key.pages.datahub.data_hub_home.filter_text.completed_requests')}`
    }
    if (this.selectedStatus && this.customMultipleFilters.length > 0) {
      text += ` ${this.locale('locale_key.pages.datahub.data_entries.and')} `;
    }
    text += this.customMultipleFilters.map(f => f.name).join(', ');
    return text;
  }

}
import { HostListener, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { RouterFe } from "src/app/route/RouterFe";
import { KpiDefFe } from "../model/kpi/KpiDefFe";
import { KpiDataFe } from "../model/kpi/KpiDataFe";
import { DashboardFe } from "../model/dashboard/DashboardFe";
import { DirectEntryInfoFe } from "../model/directEntry/DirectEntryInfoFe";
import { TaxonomyInfoFe } from "../model/taxonomy/TaxonomyInfoFe";
import { KpiSubjectFe } from "../model/subject/KpiSubjectFe";
import { DashboardSubjectFe } from "../model/subject/DashboardSubjectFe";
import { StageTableInfoSubjectFe } from "../model/subject/StageTableInfoSubjectFe";
import { OrgInfoSubjectFe } from "../model/subject/OrgInfoSubjectFe";
import { ActionFe } from "../model/subject/ActionFe";
import { OrganizationInfoFe } from "../model/organization/OrganizationInfoFe";
import { TableDataFe } from "../model/schema/TableDataFe";
import { OrganizationEntityFe } from "../model/organization/OrganizationEntityFe";
import { LoginServiceFe } from "./LoginServiceFe";
import { TaxonomyFilterFe } from "../model/filter/TaxonomyFilterFe";
import { OrganizaationFilter } from "../model/filter/OrganizationFilter";
import { PartnerInvitationFe } from "../model/data-suppliers/company/PartnerInvitationFe";
import { CompanyFe, CompanyTypeFe } from "../model/data-suppliers/company/CompanyFe";
import { READ_STATUS, RequestFe, REQUEST_TASK_STATUS } from "../model/data-suppliers/request/RequestFe";
import { RequestGroupFe } from "../model/data-suppliers/request/RequestGroupFe";
import { AbstractActivityFe } from "../model/data-suppliers/timeline/AbstractActivityFe";
import { TaskFe, TASK_STATUS } from "../model/data-suppliers/task/TaskFe";
import { NotificationOverviewFe } from "../model/notificatiton/NotificationOverviewFe";
import { AccessibleCompanyFe } from "../model/org/AccessibleCompanyFe";
import { RoutesFe } from "../route/RoutesFe";
import { ActivatedRoute, Router } from "@angular/router";
import { ManagerInvitationFe } from "../model/invitation/ManagerInvitationFe";
import { ContactInvitationFe } from "../model/data-suppliers/company/ContactInvitationFe";
import { RouteServiceFe } from "../route/RouteServiceFe";
import { ManagerInvitationExtendedFe } from "../model/invitation/ManagerInvitationExtended";
import { Manager_RegisterFe } from "../model/invitation/Manager_RegisterFe";
import { ManagerInvitationSubjectFe } from "../model/subject/ManagerInvitationSubjectFe";
import { Contact_RegisterFe } from "../model/data-suppliers/company/Contact_RegisterFe";
import { ContactInvitationSubjectFe } from "../model/subject/ContactInvitationSubjectFe";
import { Partner_RegisterFe } from "../model/data-suppliers/company/Partner_RegisterFe";
import { PartnerInvitationSubjectFe } from "../model/subject/PartnerInvitationSubjectFe";
import { ManagerInvitation_AddFe } from "../model/invitation/ManagerInvitation_AddFe";
import { ManagerInvitation_Reject } from "../model/invitation/ManagerInvitation_Reject";
import { ContactInvitation_AddFe } from "../model/data-suppliers/company/ContactInvitation_AddFe";
import { ContactInvitation_RejectFe } from "../model/data-suppliers/company/ContactInvitation_RejectFe";
import { PartnerInvitation_AddFe } from "../model/data-suppliers/company/PartnerInvitation_AddFe";
import { SupplierCompanyFe } from "../model/data-suppliers/company/SupplierCompanyFe";
import { PartnerInvitationnExistedUserCompaniesFe } from "../model/data-suppliers/company/PartnerInvitationnExistedUserCompaniesFe";
import { PartnerInvitation_CheckExistingUserCompaniesFe } from "../model/data-suppliers/company/PartnerInvitation_CheckExistingUserCompaniesFe";
import { Partner_RegisterReuseExistedUserCompanyIdFe } from "../model/data-suppliers/company/Partner_RegisterReuseExistedUserCompanyIdFe";
import { PartnerInvitation_CheckIdClashFe } from "../model/data-suppliers/company/PartnerInvitation_CheckIdClashFe";
import { PartnerInvitationnIdClashCompanyFe } from "../model/data-suppliers/company/PartnerInvitationnIdClashCompanyFe";
import { Partner_RegisterNonExistedCompanyFe } from "../model/data-suppliers/company/Partner_RegisterNonExistedCompanyFe";
import { Partner_RegisterWithIdClashResolutionFe } from "../model/data-suppliers/company/Partner_RegisterWithIdClashResolutionFe";
import { ContactInvitationExtendedFe } from "../model/data-suppliers/company/ContactInvitationExtendedFe";
import { PartnerInvitationExtendedFe } from "../model/data-suppliers/company/PartnerInvitationnExtendedFe";
import { Company_AddFe } from "../model/invitation/Company_AddFe";
import { RequestGroup_AddFe } from "../model/data-suppliers/request/add/RequestGroup_AddFe";
import { RequestFolder_AddFe } from "../model/data-suppliers/request/add/RequestFolder_AddFe";
import { AcceptSubmissionActivityFe } from "../model/data-suppliers/timeline/AcceptSubmissionActivityFe";
import { CloseRequestActivityFe } from "../model/data-suppliers/timeline/CloseRequestActivityFe";
import { CreateRequestActivityFe } from "../model/data-suppliers/timeline/CreateRequestActivityFe";
import { RejectSubmissionActivityFe } from "../model/data-suppliers/timeline/RejectSubmissionActivityFe";
import { SubmitMessageActivityFe } from "../model/data-suppliers/timeline/SubmitMessageActivityFe";
import { AbstractActivity_AddFe } from "../model/data-suppliers/timeline/add/AbstractActivity_AddFe";
import { RejectSubmissionActivity_AddFe } from "../model/data-suppliers/timeline/add/RejectSubmissionActivity_AddFe";
import { AcceptSubmissionActivity_AddFe } from "../model/data-suppliers/timeline/add/AcceptSubmissionActivity_AddFe";
import { SubmitMessageActivity_AddFe } from "../model/data-suppliers/timeline/add/SubmitMessageActivityFe_AddFe";
import { CloseRequestActivity_AddFe } from "../model/data-suppliers/timeline/add/CloseRequestActivity_AddFe";
import { FileDataFe } from "../model/file/FileDataFe";
import { ActivityTypeConversionFe } from "../model/data-suppliers/timeline/ActivityTypeConversionFe";
import { TaxonomyInfoSubjectFe } from "../model/subject/TaxonomyInfoSubjectFe";
import { IdUtil } from "../utils/IdUtil";
import { QuestionnaireFe } from "../model/data-suppliers/request/questionnaire/QuestionnaireFe";
import { SubmitAnswersActivityFe } from "../model/data-suppliers/timeline/SubmitAnswersActivityFe";
import { SubmitAnswersActivity_AddFe } from "../model/data-suppliers/timeline/add/SubmitAnswersActivity_AddFe";
import { ActivityStatusFe } from "../model/data-suppliers/timeline/ActivityStatusFe";
import { ChangePasswordFe } from "../model/org/ChangePasswordFe";
import { SharedDashboardFe } from "../model/shared/SharedDashboardFe";
import { SharedDashboard_AddFe } from "../model/shared/add/SharedDashboard_AddFe";
import { RecipientInfoFe } from "../model/dashboard/RecipientInfoFe";
import { RecipientInfo_DeleteFe } from "../model/shared/edit/RecipientInfo_DeleteFe";
import { RecipientInfo_AddFe } from "../model/shared/add/RecipientInfo_AddFe";
import { RecipientInfo_ModifyFe } from "../model/shared/edit/RecipientInfo_ModifyFe";
import { SharedResourceTypeFe } from "../model/shared/SharedResourceTypeFe";
import { VisitorServiceFe } from "./VisitorServiceFe";
import { UserServiceFe } from "./UserServiceFe";
import { Dashboard_AddFe } from "../model/dashboard/add/Dashboard_AddFe";
import { EmailFe } from "../model/email/EmailFe";
import { AnswerTypeConversionFe } from "../model/data-suppliers/timeline/answer/AnswerTypeConversionFe";
import { ManagerFe } from "../model/user/ManagerFe";
import { ContactFe } from "../model/user/ContactFe";
import { AffiliationRoleFe } from "../model/user/AffiliationRoleFe";
import { InvitationStatusFe } from "../model/invitation/InvitationStatusFe";
import { CustomerSuccessFe } from "../model/user/CustomerSuccessFe";
import { AdminFe } from "../model/user/AdminFe";
import { AffiliationStatus } from "../model/user/AffiliationStatusFe";
import { Admin_AddFe } from "../model/user/Admin_AddFe";
import { CustomerSuccess_AddFe } from "../model/user/CustomerSuccess_AddFe";
import { AbstractAffiliationFe } from "../model/user/AbstractAffiliationFe";
import { CustomerSuccess_UpdateFe } from "../model/user/CustomerSuccess_UpdateFe";
import { CustomerSuccessSubjectFe } from "../model/subject/CustomerSuccessSubjectFe";
import { AdminSubjectFe } from "../model/subject/AdminSubjectFe";
import { ErrorsFe, OpenErrorsFe } from "../utils/KNOWN_ERRORS";
import { SignInMethodConversionFe } from "../model/signInMethod/SignInMethodConversionFe";
import { AlertServiceFe } from "./AlertServiceFe";
import { SignInMethodUpdateFe } from "../model/signInMethod/SignInMethodUpdateFe";
import { RequestFolderFe } from "../model/data-suppliers/request/RequestFolderFe";
import { LanguageService } from "./LanguageServiceFe";
import { ViewSubjectFe } from "../model/subject/ViewSubjectFe";
import { ViewFe } from "../model/subject/ViewFe";
import { EntityFe } from "../model/taxonomy/EntityFe";
import { DateUtil } from "../utils/DateUtil";
import { AutomatedFeedInfoFe } from "../model/automatedFeed/AutomatedFeedInfoFe";
import { AutomatedFeedSystemFe } from "../model/automatedFeed/AutomatedFeedSystemFe";
import { DomSanitizer } from "@angular/platform-browser";
import { RecentlyOpenedItemFe } from "../model/datahub/RecentlyOpenedItemFe";
import { FileSourceFe } from "../model/file/FileSourceFe";
import { InsightDefFe } from "../model/insight/InsightDefFe";
import { InsightFe } from "../model/insight/InsightFe";
import { InsightSubjectFe } from "../model/subject/InsightSubjectFe";
import { AnswerFe } from "../model/data-suppliers/timeline/answer/AnswerFe";
import { DisconnectSectionsActivity_AddFe } from "../model/data-suppliers/timeline/add/DisconnectSectionsActivity_AddFe";
import { ConnectSectionsActivity_AddFe } from "../model/data-suppliers/timeline/add/ConnectSectionsActivity_AddFe";
import { ConnectSectionsActivityFe } from "../model/data-suppliers/timeline/ConnectSectionsActivityFe";
import { DisconnectSectionsActivityFe } from "../model/data-suppliers/timeline/DisconnectSectionsActivityFe";
import { RequestGroup_UpdateFe } from "../model/data-suppliers/request/RequestGroup_UpdateFe";
import { UnitFe } from "../components/unit-systems/model/UnitFe";
import { UnitMeasurementTypes } from "../components/unit-systems/model/UnitMeasurementType";
import _ from "lodash";
import { AnswerAttachmentFe } from "../model/data-suppliers/timeline/answer/AnswerAttachmentFe";
import { UnitPrefixes } from "../components/unit-systems/model/UnitPrefixes";
import { CustomEmissionFactorFe } from "../model/emissions/CustomEmissionFactorFe";
import { GlobalEmissionFactorFe } from "../model/emissions/GlobalEmissionFactorFe";
import { EmailResponseFe } from "../model/email/EmailResponseFe";
import { GlobalDatabaseFe } from "../model/emissions/GlobalDatabaseFe";
import { AbstractProjectInfoFe } from "../model/project/AbstractProjectInfoFe";
import { PROJECT_TYPE } from "../model/project/ProjectTypeFe";
import { AbstractProjectInfoAddFe } from "../model/project/add/AbstractProjectInfoAddFe";
import { CCProjectInfoFe } from "../model/project/CCProjectInfoFe";
import { CSRDProjectInfoFe } from "../model/project/CSRDProjectInfoFe";
import { CSRDProjectInfoAddFe } from "../model/project/add/CSRDProjectInfoAddFe";
import moment from "moment";
import { environment } from "src/environments/environment";
import { MathJsInstance } from "mathjs";
import { UnitServiceFe } from "./UnitServiceFe";

@Injectable({
  providedIn: "root"
})
export class StateServiceFe {
  public notificatonOveriew: NotificationOverviewFe;
  public activeWorkspace: AccessibleCompanyFe;
  public workspaceSubject: Subject<AccessibleCompanyFe> = new Subject<AccessibleCompanyFe>();
  public readNotification: any;
  public viewSubject: Subject<ViewSubjectFe> = new Subject<ViewSubjectFe>();
  public notificationSubject: Subject<any> = new Subject<any>();
  public insights: InsightFe[];
  public insightSubject: Subject<InsightSubjectFe> = new Subject<InsightSubjectFe>();
  private dashboards: DashboardFe[];
  private dashboardsRecipients: RecipientInfoFe[];
  private selectedDashbard: DashboardFe | SharedDashboardFe;
  public dashboardSubject: Subject<DashboardSubjectFe> = new Subject<DashboardSubjectFe>();
  private sharedDashboards: SharedDashboardFe[];
  public sharedDashboardInsights: InsightFe[];
  public selectedInsight: InsightFe;
  private stageTableInfos: DirectEntryInfoFe[];
  public stageTableInfoSubject: Subject<StageTableInfoSubjectFe> = new Subject<StageTableInfoSubjectFe>();
  private automatedFeedSystems: AutomatedFeedSystemFe[];
  private taxonomyInfos: { depTaxonomy: TaxonomyInfoFe; newTaxonomy: TaxonomyInfoFe };
  public depTaxonomyInfoSubject: Subject<TaxonomyInfoSubjectFe> = new Subject<TaxonomyInfoSubjectFe>();
  public newTaxonomyInfoSubject: Subject<TaxonomyInfoSubjectFe> = new Subject<TaxonomyInfoSubjectFe>();
  private orgInfos: { depOrg: OrganizationInfoFe; newOrg: OrganizationInfoFe };
  public depOrgInfoSubject: Subject<OrgInfoSubjectFe> = new Subject<OrgInfoSubjectFe>();
  public newOrgInfoSubject: Subject<OrgInfoSubjectFe> = new Subject<OrgInfoSubjectFe>();
  private orgTables: TableDataFe[];
  public orgTablesUpdatedSubject: Subject<TableDataFe[]> = new Subject<TableDataFe[]>();
  public taxonomyFilter: TaxonomyFilterFe;
  public organizationFilter: OrganizaationFilter;

  private contacts: ContactFe[];
  private contactInvitations: ContactInvitationFe[];
  public contactInvitationUpdatedSubject: Subject<ContactInvitationSubjectFe> = new Subject<ContactInvitationSubjectFe>();
  private partners: SupplierCompanyFe[];
  private partnerInvitations: PartnerInvitationFe[];
  public partnerInvitationUpdatedSubject: Subject<PartnerInvitationSubjectFe> = new Subject<PartnerInvitationSubjectFe>();
  private managerInvitations: ManagerInvitationFe[];
  public managerInvitationUpdatedSubject: Subject<ManagerInvitationSubjectFe> = new Subject<ManagerInvitationSubjectFe>();

  private tasks: TaskFe[];
  private allTaskActivities: AbstractActivityFe[];
  public taskTimelineItemsAddedSubject: Subject<AbstractActivityFe> = new Subject<AbstractActivityFe>();
  public taskTimelineItemsUpdatedSubject: Subject<AbstractActivityFe> = new Subject<AbstractActivityFe>();

  public requestTimelineItemsAddedSubject: Subject<AbstractActivityFe> = new Subject<AbstractActivityFe>();
  public requestTimelineItemsUpdatedSubject: Subject<AbstractActivityFe> = new Subject<AbstractActivityFe>();
  private requestGroups: RequestGroupFe[];
  private requests: RequestFe[];
  private requestFolders: RequestFolderFe[];
  private allRequestActivities: AbstractActivityFe[];
  public requestGroupAddedSubject: Subject<RequestGroupFe> = new Subject<RequestGroupFe>();
  public requestGroupUpdatedSubject: Subject<RequestGroupFe> = new Subject<RequestGroupFe>();

  private allExplorerFiles: FileDataFe[];
  public explorerFilesUpdatedSubject: Subject<FileDataFe> = new Subject<FileDataFe>();

  public units: UnitFe[];
  public unitEvaluator: MathJsInstance;
  public unitsUpdated: Subject<UnitFe[]> = new Subject<UnitFe[]>();
  
  public globalEmissionFactors: GlobalEmissionFactorFe[];
  public customEmissionFactors: CustomEmissionFactorFe[];
  public globalDatabases: GlobalDatabaseFe[]
  

  public projects: AbstractProjectInfoFe[]
  public projectsUpdated= new Subject<void>();

  public activeProject: AbstractProjectInfoFe;
  public activeProjectSubject = new Subject<AbstractProjectInfoFe>();

  public insightCacheInvalidated: Subject<InsightFe[]> = new Subject<InsightFe[]>();

  //ADMIN
  private emails: EmailFe[];
  public accessibleCompaniesUpdatedSubject: Subject<AccessibleCompanyFe> = new Subject<AccessibleCompanyFe>();
  private mgmtCompanies: CompanyFe[];
  public mgmtCompaniesUpdatedSubject: Subject<CompanyFe> = new Subject<CompanyFe>();
  private allContacts: ContactFe[];
  private allManagers: ManagerFe[];
  private allCustomerSuccess: CustomerSuccessFe[];
  private inactiveCustomerSuccess: CustomerSuccessFe[];
  public csUpdatedSubject: Subject<CustomerSuccessSubjectFe> = new Subject<CustomerSuccessSubjectFe>();
  private allAdmins: AdminFe[];
  public adminUpdatedSubject: Subject<AdminSubjectFe> = new Subject<AdminSubjectFe>();
  public inviteAgainManager: ManagerFe;

  public cacheLoaded: boolean = false;
  public cacheLoadedSubject: Subject<boolean> = new Subject<boolean>();

  public initCacheInProgress: boolean = false;
  public initCacheSubject: Subject<boolean> = new Subject<boolean>();

  private lastPulse: String;

  constructor(
    private alertService: AlertServiceFe,
    private backendService: RouterFe,
    private loginService: LoginServiceFe,
    private ErrorsFe: ErrorsFe,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private routeService: RouteServiceFe,
    private visitorService: VisitorServiceFe,
    private userService: UserServiceFe,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private unitService: UnitServiceFe
  ) {
    this.loginService.loggedInSubject.subscribe(async (loggedIn: boolean) => {
      if (loggedIn) {
        await this.determinActiveWorkspace();
      } else {
        this.cleanCache();
      }
    });
    if (this.loginService.loggedIn) {
      this.determinActiveWorkspace();
    }
    this.visitorService.verifiedSubject.subscribe(async (verified: boolean) => {
      if (verified) {
        await this.loadVisitorsData(this.visitorService.user.purpose);
      } else {
        this.cleanCache();
      }
    });
    if (this.visitorService.isValid) {
      this.loadVisitorsData(this.visitorService.user.purpose);
    }
    /*
      Periodically poll backend to determine 
      to check if calculations were run, then fetch
      any data that depends on calculations
    */
    const pulseFreq = environment.pulseFreqSec || 10 * 1000;
    setInterval(() => {
      if (this.loginService.loggedIn) {
        this.pulse(this.backendService)
      }
    }, pulseFreq);
  }

  async pulse(backendService) {
    if(!this.lastPulse){
      this.lastPulse = moment().toISOString();
    }
    // Check if any events happened after last pulse
    const eventsHappenedAfterLastPulse = await backendService.pulse(this.lastPulse);
    if(eventsHappenedAfterLastPulse){
      //Invalidate what needs to be invalidated that depends on messaging service
      // insights
      const insights = await this.getInsights(true);
      this.insightCacheInvalidated.next(insights);
      this.lastPulse = moment().toISOString();
    }    
  }

  async determinActiveWorkspace() {
    const workspaceAction = this.activatedRoute.snapshot.queryParams["workspaceAction"];
    if (workspaceAction == "changeWorkspace") {
      return;
    }

    let userInfo = this.loginService.getUserInfo();
    if (userInfo.activeWorkspaceCompanyId && userInfo.activeWorkspaceRoleType) {
      if (userInfo.activeWorkspaceRoleType == AffiliationRoleFe.SUSTAINABILITY_MANAGER) {
        this.activeWorkspace = userInfo.accessibleCompanies.find((c) => c.companyId == userInfo.activeWorkspaceCompanyId && (userInfo.isAdmin || c.affiliationRole == AffiliationRoleFe.SUSTAINABILITY_MANAGER));
        await this.loadWorkspace(this.activeWorkspace);
        this.router.navigate([RoutesFe.HOME.fullPath()]);
      } else if (userInfo.activeWorkspaceRoleType == AffiliationRoleFe.DATA_OWNER) {
        this.activeWorkspace = userInfo.accessibleCompanies.find((c) => c.companyId == userInfo.activeWorkspaceCompanyId && c.affiliationRole == AffiliationRoleFe.DATA_OWNER);
        await this.loadWorkspace(this.activeWorkspace);
        this.router.navigate([RoutesFe.HOME.fullPath()]);
      }
    } else {
      this.router.navigate([RoutesFe.WORKSPACES.fullPath()]);
    }
  }

  cleanCache() {
    this.cacheLoaded = false;
    this.cacheLoadedSubject.next(false);
    this.notificatonOveriew = undefined;
    this.readNotification = undefined;
    this.notificationSubject = new Subject<any>();
    this.insights = undefined;
    this.insightSubject = new Subject<InsightSubjectFe>();
    this.dashboards = undefined;
    this.selectedDashbard = undefined;
    this.selectedInsight = undefined;
    this.sharedDashboards = undefined;
    this.dashboardsRecipients = undefined;
    this.dashboardSubject = new Subject<DashboardSubjectFe>();
    this.taxonomyInfos = undefined;
    this.depTaxonomyInfoSubject = new Subject<TaxonomyInfoSubjectFe>();
    this.newTaxonomyInfoSubject = new Subject<TaxonomyInfoSubjectFe>();
    this.orgInfos = undefined;
    this.orgTables = undefined;
    this.depOrgInfoSubject = new Subject<OrgInfoSubjectFe>();
    this.newOrgInfoSubject = new Subject<OrgInfoSubjectFe>();
    this.stageTableInfos = undefined;
    this.stageTableInfoSubject = new Subject<StageTableInfoSubjectFe>();
    this.automatedFeedSystems = undefined;
    this.partnerInvitations = undefined;
    this.partnerInvitationUpdatedSubject = new Subject<PartnerInvitationSubjectFe>();
    this.contactInvitations = undefined;
    this.contactInvitationUpdatedSubject = new Subject<ContactInvitationSubjectFe>();
    this.managerInvitations = undefined;
    this.managerInvitationUpdatedSubject = new Subject<ManagerInvitationSubjectFe>();
    this.contacts = undefined;
    this.partners = undefined;
    this.requestGroups = undefined;
    this.requests = undefined;
    this.allRequestActivities = undefined;
    this.tasks = undefined;
    this.allTaskActivities = undefined;
    this.allExplorerFiles = undefined;
    this.activeWorkspace = undefined;
    this.sharedDashboardInsights = undefined;
    this.emails = undefined;
    this.mgmtCompanies = undefined;
    this.allManagers = undefined;
    this.allContacts = undefined;
    this.allAdmins = undefined;
    this.allCustomerSuccess = undefined;
    this.units = undefined
    this.customEmissionFactors = undefined;
    this.projects = undefined;
    this.activeProject = undefined
  }

  markNotificationRead(notification: any) {
    this.readNotification = notification;
    this.notificationSubject.next(notification);
  }

  async getNotificationOverview(): Promise<NotificationOverviewFe> {
    if (!this.notificatonOveriew) {
      let transfer = await this.backendService.getNotificationOverview();
      this.notificatonOveriew = await NotificationOverviewFe.fromTransfer(transfer);
    }
    return this.notificatonOveriew;
  }

  async checkCacheStatus() {
    if (this.initCacheInProgress) {
      this.router.navigate([RoutesFe.LOADING.fullPath()]);
    } else if (!this.cacheLoaded) {
      this.router.navigate([RoutesFe.WORKSPACES.fullPath()]);
    }
  }

  async loadWorkspace(company: AccessibleCompanyFe, changed = false) {
    try {
      if (company) {
        if (this.activeWorkspace && changed) {
          this.updateRecentlyOpenedItems();
        }
        if (!(this.cacheLoaded && company.companyId == this.activeWorkspace.companyId && (this.loginService.loginUser.isAdmin || company.affiliationRole == this.activeWorkspace.affiliationRole))) {
          if (this.activeWorkspace && company.companyId == this.activeWorkspace.companyId && (this.loginService.loginUser.isAdmin || company.affiliationRole == this.activeWorkspace.affiliationRole)) {
            this.backendService.setWorkspaceId(company.companyId);
          } else {
            this.cleanCache();
            if (company.affiliationRole == AffiliationRoleFe.DATA_OWNER) {
              let workspaceChangedInBackend = await this.backendService.changeWorkspace(company.companyId, AffiliationRoleFe.DATA_OWNER, this.loginService.getGoogleUserCreds());
            } else {
              let workspaceChangedInBackend = await this.backendService.changeWorkspace(company.companyId, AffiliationRoleFe.SUSTAINABILITY_MANAGER, this.loginService.getGoogleUserCreds());
            }
          }
          this.loginService.loginUser.activeWorkspaceCompanyId = company.companyId;
          this.loginService.loginUser.activeWorkspaceRoleType = company.affiliationRole;
          await this.loadWorkspaceData(company, changed);
        }
      }
    } catch (error) {
      let knownError = this.ErrorsFe.matchError(error.error);
      switch (knownError) {
        case OpenErrorsFe.SIGNIN_METHOD_NOT_ALLOWED_FOR_WORKSPACE_ERROR:
          const workspace = company.companyName;
          const signInMethod = SignInMethodConversionFe.fromGoogleCreds(this.loginService.getGoogleUserCreds()).provider;
          this.alertService.showError(`Sign in method [ ${signInMethod} ] not allowed for workspace [ ${workspace} ] `);
          break;
      }
      throw error;
    }
  }

  async loadWorkspaceData(company: AccessibleCompanyFe, reload = false) {
    if (!this.cacheLoaded || reload) {
      this.cleanCache();
      this.initCacheInProgress = true;
      this.initCacheSubject.next(true);
      this.router.navigate([RoutesFe.LOADING.fullPath()]);
      this.activeWorkspace = company;

      if (this.activeWorkspace.affiliationRole == AffiliationRoleFe.DATA_OWNER) {
        let tasksPromise = this.getTasks();
        let allTaskActivitiesPromise = this.getAllTaskActivities();
        let requestGroupsPromise = this.getRequestGroups();
        let sharedDashboardPromise = this.getSharedDashboards();
        let kpisPromise = this.getSharedDashboardInsights();

        let promises: Promise<any>[] = [tasksPromise, allTaskActivitiesPromise, requestGroupsPromise, sharedDashboardPromise, kpisPromise];
        await Promise.all(promises);
        await this.adjustTaskData();
      } else if (this.activeWorkspace.companyType == CompanyTypeFe.MANAGEMENT) {
        let dashboardPromise = this.getDashboards()
        let dashboardRecipientsPromise = this.getDashboardRecipients()
        let sharedDashboardPromise = this.getSharedDashboards()
        let dashboardKpisPromise = this.getSharedDashboardInsights()
        let insightsPromise = this.getInsights()
        let notificatonOveriewPromise = this.getNotificationOverview()
        let taxonomyInfosPromise = this.getTaxonomyInfos(true)
        let stageTableInfosPromise = this.getStageTableInfos(true)
        let requestGroupsPromise = this.getRequestGroups()
        let requestsPromise = this.getAllRequests()
        let allRequestActivitiesPromise = this.getAllRequestActivities()
        let allRgFoldersPromise = this.getRequestFolders()
        let allContactsPromise = this.getContacts()
        let allPartnersPromise = this.getPartners()
        let recentlyOpenedItemsPromise = this.fetchNewRecentlyOpenedItems()
        let customFactorPromise = this.getCustomEmissionFactors()
        let databasePromise = this.getGlobalDatabases()
        let globalFactorPromise = this.getGlobalEmissionFactors()          
        let unitsPromise = this.getUnits()
  
        let promises: Promise<any>[] = [
          // dashboardPromise,
          // sharedDashboardPromise,
          // dashboardKpisPromise,
          // insightsPromise,
          taxonomyInfosPromise,
          stageTableInfosPromise,
          requestGroupsPromise,
          requestsPromise,
          allRgFoldersPromise,
          allRequestActivitiesPromise,
          allContactsPromise,
          allPartnersPromise,
          dashboardRecipientsPromise,
          recentlyOpenedItemsPromise,
          customFactorPromise,
          databasePromise,
          globalFactorPromise,
          unitsPromise
        ];
        await Promise.all(promises);
        await this.adjustRequestGroupData();
        await this.setDashboardRecipients();
        await this.adjustGlobalEmissionFactors() 
      }
      
      this.workspaceSubject.next(company);
      this.cacheLoaded = true;
      this.cacheLoadedSubject.next(true);
      this.initCacheInProgress = false;
      this.initCacheSubject.next(false);
    }
  }

  async loadVisitorsData(resource: string) {
    this.initCacheInProgress = true;
    this.initCacheSubject.next(true);
    this.router.navigate([RoutesFe.VISITOR_LOADING.fullPath()]);

    if (resource == SharedResourceTypeFe.DASHBOARD) {
      let sharedDashboardPromise = this.getSharedDashboards();
      let kpisPromise = this.getSharedDashboardInsights();
      let promises: Promise<any>[] = [sharedDashboardPromise, kpisPromise];
      await Promise.all(promises);
    }
    this.cacheLoaded = true;
    this.cacheLoadedSubject.next(true);
    this.initCacheInProgress = false;
    this.initCacheSubject.next(false);
    this.router.navigate([RoutesFe.VISITOR_SHARED_DASHBOARD.fullPath()]);
  }

  async getAutomatedFeedSystems(reload = false): Promise<AutomatedFeedSystemFe[]> {
    if (this.activeWorkspace.companyType == CompanyTypeFe.MANAGEMENT && (reload || !this.automatedFeedSystems)) {
      let feedInfos = await this.backendService.listAutomatedFeedInfos();
      let { depTaxonomy, newTaxonomy } = await this.getTaxonomyInfos();
      let automatedFeedInfos = feedInfos.map((sm) => AutomatedFeedInfoFe.fromTransfer(sm, depTaxonomy));
      let promises: Promise<any>[] = [];
      this.automatedFeedSystems = [];
      automatedFeedInfos.forEach((feed) => {
        let system = this.automatedFeedSystems.find((system) => system.name == feed.systemName);
        if (!system) {
          system = new AutomatedFeedSystemFe(feed.systemName, feed.logoStorageId, []);
          this.automatedFeedSystems.push(system);
        }
        system.automatedFeeds.push(feed);
      });
      this.automatedFeedSystems.forEach((system) => promises.push(this.downloadLogoFile(system.logoStorageId)));
      let results = await Promise.all(promises);
      results.forEach((content, index) => {
        let blob = new Blob([content]);
        let url = URL.createObjectURL(blob);
        this.automatedFeedSystems[index].imgSrc = this.domSanitizer.bypassSecurityTrustUrl(url);
      });
    }
    return this.automatedFeedSystems;
  }

  async downloadLogoFile(fileName: any): Promise<Blob> {
    return this.backendService.downloadLogoFile(this.activeWorkspace.companyId, fileName);
  }

  async deleteAutomatedFeedInfo(automatedFeedInfo: AutomatedFeedInfoFe, automatedSystem: AutomatedFeedSystemFe): Promise<boolean> {
    automatedFeedInfo.updateInfoInProgress = true;
    let deleted = await this.backendService.deleteAutomatedFeedInfo(automatedFeedInfo);
    if (deleted) {
      let system = this.automatedFeedSystems.find((system) => system.name === automatedSystem.name);
      system.automatedFeeds = system.automatedFeeds.filter((d) => d.id != automatedFeedInfo.id);
      if (system.automatedFeeds.length == 0) {
        this.automatedFeedSystems = this.automatedFeedSystems.filter((d) => d.name === automatedSystem.name);
      }
      automatedFeedInfo.updateInfoInProgress = false;
      return true;
    }
    automatedFeedInfo.updateInfoInProgress = false;
    return false;
  }

  async connecAutomatedFeedToPipeline(automatedFeedInfo: AutomatedFeedInfoFe) {
    try {
      automatedFeedInfo.connectionInProgress = true;
      let done = await this.backendService.connecAutomatedFeedTableToPipeline(automatedFeedInfo.id);
      automatedFeedInfo.transformed = done;
      if (done) {
        automatedFeedInfo.modified = false;
      }
      await this.updateAffectedInsights([automatedFeedInfo.taxonomyKey]);
      automatedFeedInfo.connectionInProgress = false;
    } catch (err) {
      automatedFeedInfo.connectionInProgress = false;
      throw err;
    }
  }

  async disconnectAutomatedFeedFromPipeline(automatedFeedInfo: AutomatedFeedInfoFe) {
    try {
      automatedFeedInfo.connectionInProgress = true;
      let done = await this.backendService.disconnectAutomatedFeedTableFromPipeline(automatedFeedInfo.id);
      automatedFeedInfo.transformed = !done;
      await this.updateAffectedInsights([automatedFeedInfo.taxonomyKey]);
      automatedFeedInfo.connectionInProgress = false;
    } catch (err) {
      automatedFeedInfo.connectionInProgress = false;
      throw err;
    }
  }

  async getStageTableInfos(reload = false): Promise<DirectEntryInfoFe[]> {
    if (this.activeWorkspace.companyType == CompanyTypeFe.MANAGEMENT && (reload || !this.stageTableInfos)) {
      let backendStageList = await this.backendService.listStageTableInfos();
      let { depTaxonomy, newTaxonomy } = await this.getTaxonomyInfos();
      this.stageTableInfos = backendStageList.map((sm) => DirectEntryInfoFe.fromTransfer(sm, depTaxonomy));
      this.stageTableInfos = this.stageTableInfos.sort(DirectEntryInfoFe.sort);
      let stageTableInfoSubject = new StageTableInfoSubjectFe(ActionFe.LIST, this.stageTableInfos);
      this.stageTableInfoSubject.next(stageTableInfoSubject);
    }
    return this.stageTableInfos;
  }

  async initStageTableInfo(stageTableInfo: DirectEntryInfoFe): Promise<boolean> {
    let done = await this.backendService.initStageDataEntry(stageTableInfo);
    if (done) {
      let { depTaxonomy, newTaxonomy } = await this.getTaxonomyInfos();
      stageTableInfo = DirectEntryInfoFe.fromTransfer(stageTableInfo, depTaxonomy);
      this.stageTableInfos.push(stageTableInfo);
      this.stageTableInfos = this.stageTableInfos.sort(DirectEntryInfoFe.sort);
      let subject = new StageTableInfoSubjectFe(ActionFe.CREATED, stageTableInfo);
      this.stageTableInfoSubject.next(subject);
      return true;
    } else {
      return false;
    }
  }

  async copyStageTableInfo(sourceStageTableInfo: DirectEntryInfoFe, destStageTableInfo: DirectEntryInfoFe): Promise<boolean> {
    let done = await this.backendService.copyStageDataEntry(sourceStageTableInfo.id, destStageTableInfo);
    if (done) {
      let { depTaxonomy, newTaxonomy } = await this.getTaxonomyInfos();
      destStageTableInfo = DirectEntryInfoFe.fromTransfer(destStageTableInfo, depTaxonomy);
      this.stageTableInfos.push(destStageTableInfo);
      this.stageTableInfos = this.stageTableInfos.sort(DirectEntryInfoFe.sort);
      let subject = new StageTableInfoSubjectFe(ActionFe.CREATED, destStageTableInfo);
      this.stageTableInfoSubject.next(subject);
      return true;
    } else {
      return false;
    }
  }

  async updateStageTableInfo(stageTableInfo: DirectEntryInfoFe): Promise<boolean> {
    let originalStageTableInfo = this.stageTableInfos.find((d) => d.id == stageTableInfo.id);
    originalStageTableInfo.updateInfoInProgress = true;
    let updated = await this.backendService.updateStageTableInfo(stageTableInfo);
    if (updated) {
      originalStageTableInfo.sourceFile = stageTableInfo.sourceFile;
      originalStageTableInfo.lastModified = new Date();
      originalStageTableInfo.lastModifiedString = DateUtil.toString3(originalStageTableInfo.lastModified);
      let subject = new StageTableInfoSubjectFe(ActionFe.UPDATED, originalStageTableInfo.id);
      this.stageTableInfoSubject.next(subject);
      originalStageTableInfo.updateInfoInProgress = false;
      return true;
    }
    originalStageTableInfo.updateInfoInProgress = false;
    return false;
  }

  async deleteStageTableInfo(stageTableInfo: DirectEntryInfoFe): Promise<boolean> {
    stageTableInfo.updateInfoInProgress = true;
    let disconnected = await this.disconnectStageTableFromPipeline(stageTableInfo);
    let deleted = await this.backendService.deleteStageDataEntry(stageTableInfo);
    if (deleted) {
      this.stageTableInfos = this.stageTableInfos.filter((d) => d.id != stageTableInfo.id);
      let subject = new StageTableInfoSubjectFe(ActionFe.DELETED, stageTableInfo.id);
      this.stageTableInfoSubject.next(subject);
      stageTableInfo.updateInfoInProgress = false;
      return true;
    }
    stageTableInfo.updateInfoInProgress = false;
    return false;
  }

  async connecStageTableToPipeline(stageTableInfo: DirectEntryInfoFe) {
    try {
      stageTableInfo.connectionInProgress = true;
      let done = await this.backendService.connecStageTableToPipeline(stageTableInfo.id);
      stageTableInfo.transformed = done;
      if (done) {
        stageTableInfo.modified = false;
      }
      await this.updateAffectedInsights([stageTableInfo.taxonomyKey]);
      let { depTaxonomy } = await this.getTaxonomyInfos();
      const entity = depTaxonomy.entityByKey(stageTableInfo.taxonomyKey);
      if (entity && entity.dataGridService) {
        entity.dataGridService.resetTable();
      }
      stageTableInfo.connectionInProgress = false;
    } catch (err) {
      stageTableInfo.connectionInProgress = false;
      throw err;
    }
  }

  async disconnectStageTableFromPipeline(stageTableInfo: DirectEntryInfoFe) {
    try {
      stageTableInfo.connectionInProgress = true;
      let done = await this.backendService.disconnectStageTableFromPipeline(stageTableInfo.id);
      stageTableInfo.transformed = !done;
      await this.updateAffectedInsights([stageTableInfo.taxonomyKey]);
      let { depTaxonomy } = await this.getTaxonomyInfos();
      const entity = depTaxonomy.entityByKey(stageTableInfo.taxonomyKey);
      if (entity && entity.dataGridService) {
        entity.dataGridService.resetTable();
      }
      stageTableInfo.connectionInProgress = false;
    } catch (err) {
      stageTableInfo.connectionInProgress = false;
      throw err;
    }
  }

  async updateAffectedUserInsights(taxonomyKeys: string[]): Promise<void> {
    let promises: Promise<any>[] = [];
    taxonomyKeys.forEach((key) => {
      let insights = this.insights.filter((insight) => insight.definition.dataSeries.find((ds) => ds.dataPoints.find((dp) => dp.taxonomyKey == key)));
      if (insights.length > 0) {
        let kpiIds = insights.map((k) => k.id);
        promises.push(this.backendService.getInsight(kpiIds));
      }
    });
    let result = await Promise.all(promises);
    result.forEach((backendKpis) => {
      backendKpis.map((backendKpi) => {
        let updatedKpi = InsightFe.fromTransfer(backendKpi);
        this.insights = this.insights.filter((uk) => uk.id != updatedKpi.id);
        this.insights.push(updatedKpi);
        this.insightSubject.next(new InsightSubjectFe(ActionFe.UPDATED, updatedKpi));
      });
    });
  }

  // // // async getUserKpis(reload = false): Promise<KpiDataFe[]> {
  // // //   if ((this.activeWorkspace.companyType == CompanyTypeFe.MANAGEMENT && (!this.userKpis || reload))) {
  // // //     let backednkpiDatas = await this.backendService.getUserKpisData().toPromise();
  // // //     this.userKpis = backednkpiDatas.map((kg) => KpiDataFe.fromTransfer(kg));
  // // //     this.userKpisSubject.next(new KpiSubjectFe(ActionFe.LIST, this.userKpis));
  // // //     return this.userKpis;
  // // //   }
  // // //   return this.userKpis;
  // // // }

  // // async createNewUserKpi(kpi: KpiDefFe): Promise<any> {
  // //   let newKpiBe = await this.backendService.addKpiDefinition(kpi).toPromise();
  // //   let newKpiFe = KpiDataFe.fromTransfer(newKpiBe);
  // //   if (this.userKpis) {
  // //     this.userKpis.push(newKpiFe);
  // //   }
  // //   let subject = new KpiSubjectFe(ActionFe.CREATED, newKpiFe);
  // //   this.userKpisSubject.next(subject);
  // //   return newKpiFe;
  // // }

  // // async modifyUserKpi(kpi: KpiDefFe): Promise<any> {
  // //   let modifiedKpiBe = await this.backendService.updateKpiDefinition(kpi).toPromise();
  // //   let modifiedKpiFe = KpiDataFe.fromTransfer(modifiedKpiBe);
  // //   let index = this.userKpis.findIndex(userkpi => userkpi.id == modifiedKpiBe.id)
  // //   if(index>-1){
  // //     this.userKpis[index] = modifiedKpiFe;
  // //   }
  // //   let subject = new KpiSubjectFe(ActionFe.UPDATED, modifiedKpiFe);
  // //   this.userKpisSubject.next(subject);
  // //   return modifiedKpiFe;
  // // }

  // async deleteUserKpi(kpiId: string): Promise<boolean> {
  //   let deleted = await this.backendService.deleteKpiDefinition(kpiId).toPromise();
  //   if (deleted) {
  //     this.userKpis = this.userKpis.filter((k) => k.id != kpiId);
  //   }
  //   let subject = new KpiSubjectFe(ActionFe.DELETED, kpiId);
  //   this.userKpisSubject.next(subject);
  //   return deleted;
  // }

  async updateAffectedInsights(taxonomyKeys: string[]): Promise<void> {
    let promises: Promise<any>[] = [];
    taxonomyKeys.forEach((key) => {
      let insights = this.insights.filter((insight) => insight.definition.dataSeries.find((ds) => ds.dataPoints.find((dp) => dp.taxonomyKey == key)));
      if (insights.length > 0) {
        let kpiIds = insights.map((k) => k.id);
        promises.push(this.backendService.getInsight(kpiIds));
      }
    });
    let result = await Promise.all(promises);
    result.forEach((backendKpis) => {
      backendKpis.map((backendKpi) => {
        let updatedKpi = InsightFe.fromTransfer(backendKpi);
        this.insights = this.insights.filter((uk) => uk.id != updatedKpi.id);
        this.insights.push(updatedKpi);
        this.insightSubject.next(new InsightSubjectFe(ActionFe.UPDATED, updatedKpi));
      });
    });
  }

  async getInsights(reload = false): Promise<InsightFe[]> {
    if (this.activeWorkspace.companyType == CompanyTypeFe.MANAGEMENT && (!this.insights || reload)) {
      let backednkpiDatas = await this.backendService.getInsights().toPromise();
      this.insights = backednkpiDatas.map((kg) => InsightFe.fromTransfer(kg));
      this.insightSubject.next(new InsightSubjectFe(ActionFe.LIST, this.insights));
      return this.insights;
    }
    return this.insights;
  }

  async addInsightDef(insightDef: InsightDefFe): Promise<any> {
    let insightBe = await this.backendService.addInsightDef(insightDef).toPromise();
    let insightFe = InsightFe.fromTransfer(insightBe);
    if (this.insights) {
      this.insights.unshift(insightFe);
    }
    let subject = new InsightSubjectFe(ActionFe.CREATED, insightFe);
    this.insightSubject.next(subject);
    return insightFe;
  }

  async modifyInsightDef(insight: InsightDefFe): Promise<any> {
    let modifiedInsightBe = await this.backendService.updateInsightDef(insight).toPromise();
    let modifiedInsightFe = InsightFe.fromTransfer(modifiedInsightBe);
    let index = this.insights.findIndex((userkpi) => userkpi.id == modifiedInsightBe.id);
    if (index > -1) {
      this.insights[index] = modifiedInsightFe;
    }
    let subject = new InsightSubjectFe(ActionFe.UPDATED, modifiedInsightFe);
    this.insightSubject.next(subject);
    return modifiedInsightFe;
  }

  async deleteInsightDef(insightDefId: string): Promise<boolean> {
    let deleted = await this.backendService.deleteInsightDef(insightDefId).toPromise();
    if (deleted) {
      this.insights = this.insights.filter((k) => k.id != insightDefId);
    }
    let subject = new InsightSubjectFe(ActionFe.DELETED, insightDefId);
    this.insightSubject.next(subject);
    return deleted;
  }

  public selectInsight(insight: InsightFe) {
    this.selectedInsight = insight;
    let subject = new InsightSubjectFe(ActionFe.SELECT, insight);
    this.insightSubject.next(subject);
  }

  async getSharedDashboardInsights(reload = false): Promise<InsightFe[]> {
    if (!this.sharedDashboardInsights || reload) {
      let email;
      if (this.userService.isUserAVisitor()) {
        email = this.visitorService.user.email;
      } else {
        email = this.loginService.loginUser.loginTimeEmail;
      }
      let backednkpiDatas = await this.backendService.getSharedDashboardInsights(email);
      this.sharedDashboardInsights = backednkpiDatas.map((kg) => InsightFe.fromTransfer(kg));
      return this.sharedDashboardInsights;
    }
    return this.sharedDashboardInsights;
  }

  public selectDashboard(dashboard: DashboardFe | SharedDashboardFe) {
    this.selectedDashbard = dashboard;
    let subject = new DashboardSubjectFe(ActionFe.SELECT, dashboard);
    this.dashboardSubject.next(subject);
  }

  public async getSelectedDashbaord(): Promise<DashboardFe | SharedDashboardFe> {
    if (!this.dashboards) {
      await this.getDashboards();
    }
    if (!this.selectedDashbard && this.dashboards.length > 0) {
      this.selectedDashbard = this.dashboards[0];
    }
    return this.selectedDashbard;
  }

  public async getDashboards(reload = false): Promise<DashboardFe[]> {
    if (this.activeWorkspace.companyType == CompanyTypeFe.MANAGEMENT && (reload || !this.dashboards)) {
      let transfers: DashboardFe[] = await this.backendService.listDashboards().toPromise();
      let dashboards = transfers.map((t) => DashboardFe.fromTransfer(t));
      dashboards.sort((a, b) => a.name.localeCompare(b.name));
      this.dashboards = dashboards;
      let subject = new DashboardSubjectFe(ActionFe.LIST, dashboards);
      this.dashboardSubject.next(subject);
      if (this.dashboards && this.dashboards.length > 0) {
        this.selectDashboard(dashboards[0]);
      }
    }
    return this.dashboards;
  }

  public async getDashboardRecipients(reload = false): Promise<RecipientInfoFe[]> {
    if (this.activeWorkspace.companyType == CompanyTypeFe.MANAGEMENT && (reload || !this.dashboardsRecipients)) {
      let recipients: RecipientInfoFe[] = await this.backendService.listDashboardRecipients();
      this.dashboardsRecipients = recipients.map((t) => RecipientInfoFe.fromTransfer(t));
    }
    return this.dashboardsRecipients;
  }

  public async setDashboardRecipients(): Promise<boolean> {
    if (!this.dashboardsRecipients) {
      await this.getDashboardRecipients(true);
    }
    if (!this.dashboards) {
      await this.getDashboards(true);
    }
    if (this.activeWorkspace.companyType == CompanyTypeFe.MANAGEMENT && this.dashboardsRecipients && this.dashboards) {
      this.dashboards.forEach((d) => (d.recipients = []));
      this.dashboardsRecipients.forEach((recipient) => {
        let dashboard = this.dashboards.find((d) => d.sharedId == recipient.objectSharedId);
        dashboard.recipients.push(recipient);
      });
    }
    return true;
  }

  async createNewDashboard(newdashboard: Dashboard_AddFe): Promise<DashboardFe> {
    let transfer = Dashboard_AddFe.toTransfer(newdashboard);
    let added = await this.backendService.addDashboard(transfer);
    let dashboard = DashboardFe.fromDashboardAdd(newdashboard);
    this.dashboards.push(dashboard);
    this.selectedDashbard = dashboard;
    let subject = new DashboardSubjectFe(ActionFe.SELECT, dashboard);
    this.dashboardSubject.next(subject);
    return dashboard;
  }

  async modifyDashboard(dashboard: DashboardFe): Promise<DashboardFe> {
    let transfer = dashboard.toTransfer();
    let added = await this.backendService.updateDashboard(transfer).toPromise();
    dashboard.charts.forEach((c) => {
      c.setting.width = c.setting.newWidth ? c.setting.newWidth : c.setting.width;
      c.setting.height = c.setting.newHeight ? c.setting.newHeight : c.setting.height;
    });
    let index = -1;
    this.dashboards.find((d, i) => {
      if (d.id == dashboard.id) {
        index = i;
        return true;
      } else {
        return false;
      }
    });
    this.dashboards[index] = dashboard;
    if (this.selectedDashbard && this.selectedDashbard instanceof DashboardFe && this.selectedDashbard.id == dashboard.id) {
      this.selectedDashbard = dashboard;
    }
    let subject = new DashboardSubjectFe(ActionFe.SELECT, dashboard);
    this.dashboardSubject.next(subject);
    return dashboard;
  }

  async deleteDashboard(dashboardId: string): Promise<boolean> {
    let deleted = await this.backendService.deleteDashboard(dashboardId).toPromise();
    this.dashboards = this.dashboards.filter((d) => d.id != dashboardId);
    let subject = new DashboardSubjectFe(ActionFe.DELETED, dashboardId);
    this.dashboardSubject.next(subject);
    this.selectDashboard(this.dashboards[0]);
    return deleted;
  }

  public async getSharedDashboards(reload = false): Promise<SharedDashboardFe[]> {
    if (reload || !this.sharedDashboards) {
      let transfers: SharedDashboardFe[] = await this.backendService.listSharedDashboards();
      this.sharedDashboards = transfers.map((t) => SharedDashboardFe.fromTransfer(t));
    }
    return this.sharedDashboards;
  }

  // async getSharedDashboardKpis (reload = false): Promise<KpiDataFe[]> {
  //   if (!this.sharedDashboardKpis || reload) {
  //     let email
  //     if (this.userService.isUserAVisitor()) {
  //       email = this.visitorService.user.email;
  //     } else {
  //       email = this.loginService.loginUser.loginTimeEmail;
  //     }
  //     let backednkpiDatas = await this.backendService.getSharedDashboardKpiData(email);
  //     this.sharedDashboardKpis = backednkpiDatas.map((kg) => KpiDataFe.fromTransfer(kg));
  //     return this.sharedDashboardKpis;
  //   }
  //   return this.sharedDashboardKpis;
  // }

  public async createNewSharedDashboard(dashboard: SharedDashboard_AddFe): Promise<void> {
    let added = await this.backendService.addSharedDashboard(dashboard);
    (this.selectedDashbard as DashboardFe).sharedId = dashboard.sharedId;
    (this.selectedDashbard as DashboardFe).recipients = dashboard.recipients.map((r) => RecipientInfoFe.fromRecipientInfoAdd(r, dashboard.sharedId));
  }

  public async deleteSharedDashboard(dashboardSharedId: string): Promise<boolean> {
    let done = await this.backendService.deleteSharedDashboard(dashboardSharedId);
    (this.selectedDashbard as DashboardFe).sharedId = null;
    (this.selectedDashbard as DashboardFe).recipients = [];
    return done;
  }

  public async deleteSharedDashboardRecipient(recipient: RecipientInfo_DeleteFe, objectSharedId: string): Promise<boolean> {
    let removed = await this.backendService.deleteSharedDashboardRecipient(recipient, objectSharedId);
    (this.selectedDashbard as DashboardFe).recipients = (this.selectedDashbard as DashboardFe).recipients.filter((r) => r.email !== recipient.email);
    return removed;
  }

  public async updateSharedDashboardRecipients(recipients: (RecipientInfo_AddFe | RecipientInfo_ModifyFe)[], objectSharedId: string): Promise<boolean> {
    let updated = await this.backendService.updateSharedDashboardRecipient(recipients, objectSharedId);
    recipients.forEach((r) => {
      if (r instanceof RecipientInfo_AddFe) (this.selectedDashbard as DashboardFe).recipients.push(RecipientInfoFe.fromRecipientInfoAdd(r, objectSharedId));
      else {
        let existing = (this.selectedDashbard as DashboardFe).recipients.find((recpi) => recpi.email == r.email);
        existing.accessType = r.accessType;
      }
    });
    return updated;
  }

  public async verifySharedDashboardEmail(objectSharedId: string, email: string): Promise<boolean> {
    let verified = await this.backendService.verifySharedDashboardEmail(objectSharedId, email);
    return verified;
  }

  public async generateSharedDashboardVerificationCode(email: string): Promise<boolean> {
    let generated = await this.backendService.generateSharedDashboardVerificationCode(email);
    return generated;
  }

  public async getTaxonomyInfos(reload = false): Promise<{ depTaxonomy: TaxonomyInfoFe; newTaxonomy: TaxonomyInfoFe }> {
    if (this.activeWorkspace.companyType == CompanyTypeFe.MANAGEMENT && (reload || !this.taxonomyInfos)) {
      let taxonomyInfos = await this.backendService.getCompanyDataTaxonomyInfos();
      let depTaxonomyBe = taxonomyInfos.depTaxonomy;
      let newTaxonomyBe = taxonomyInfos.newTaxonomy;
      let depTaxonomy = TaxonomyInfoFe.fromTransfer(depTaxonomyBe, undefined, this.languageService);
      let newTaxonomy = TaxonomyInfoFe.fromTransfer(newTaxonomyBe, depTaxonomy, this.languageService);
      this.taxonomyInfos = { depTaxonomy: depTaxonomy, newTaxonomy: newTaxonomy };
      let depTaxonomySubject = new TaxonomyInfoSubjectFe(ActionFe.UPDATED, depTaxonomy);
      let newTaxonomySubject = new TaxonomyInfoSubjectFe(ActionFe.UPDATED, newTaxonomy);
      this.depTaxonomyInfoSubject.next(depTaxonomySubject);
      this.newTaxonomyInfoSubject.next(newTaxonomySubject);
      this.taxonomyFilter = new TaxonomyFilterFe(this.taxonomyInfos.depTaxonomy);
    }
    return this.taxonomyInfos;
  }

  public async updateNewTaxonomyInfo(taxonomy: TaxonomyInfoFe): Promise<boolean> {
    let updated = await this.backendService.updateNewTaxonomyInfo(taxonomy);
    taxonomy.diffDeployment(this.taxonomyInfos.depTaxonomy);
    if (updated) {
      let newTaxonomySubject = new TaxonomyInfoSubjectFe(ActionFe.UPDATED, taxonomy);
      this.newTaxonomyInfoSubject.next(newTaxonomySubject);
      return true;
    } else {
      return false;
    }
  }

  public async upgradeTaxonomyInfo(): Promise<boolean> {
    let taxonomyInfos = await this.backendService.deployNewTaxonomyInfo();
    if (taxonomyInfos) {
      let depTaxonomyBe = taxonomyInfos.depTaxonomy;
      let newTaxonomyBe = taxonomyInfos.newTaxonomy;
      let depTaxonomy = TaxonomyInfoFe.fromTransfer(depTaxonomyBe, undefined, this.languageService);
      let newTaxonomy = TaxonomyInfoFe.fromTransfer(newTaxonomyBe, depTaxonomy, this.languageService);
      this.taxonomyInfos = { depTaxonomy: depTaxonomy, newTaxonomy: newTaxonomy };
      let depTaxonomySubject = new TaxonomyInfoSubjectFe(ActionFe.UPDATED, depTaxonomy);
      let newTaxonomySubject = new TaxonomyInfoSubjectFe(ActionFe.UPDATED, newTaxonomy);
      this.depTaxonomyInfoSubject.next(depTaxonomySubject);
      this.newTaxonomyInfoSubject.next(newTaxonomySubject);
      return true;
    } else {
      return false;
    }
  }

  public async getOrgInfos(reload = false): Promise<{ depOrg: OrganizationInfoFe; newOrg: OrganizationInfoFe }> {
    if (this.activeWorkspace.companyType == CompanyTypeFe.MANAGEMENT && (reload || !this.orgInfos)) {
      let orgInfos = await this.backendService.getOrganizationInfos();
      let depOrgBe = orgInfos.depOrg;
      let newOrgBe = orgInfos.newOrg;
      let depOrg = OrganizationInfoFe.fromTransfer(depOrgBe, undefined);
      let newOrg = OrganizationInfoFe.fromTransfer(newOrgBe, depOrg);
      this.orgInfos = { depOrg: depOrg, newOrg: newOrg };
      let depOrgSubject = new OrgInfoSubjectFe(ActionFe.UPDATED, depOrg);
      let newOrgSubject = new OrgInfoSubjectFe(ActionFe.UPDATED, newOrg);
      this.depOrgInfoSubject.next(depOrgSubject);
      this.newOrgInfoSubject.next(newOrgSubject);
    }
    return this.orgInfos;
  }

  public async updateNewOrgInfo(newOrgInfo: OrganizationInfoFe): Promise<boolean> {
    let updated = await this.backendService.updateNewOrganizationInfo(newOrgInfo);
    newOrgInfo.diffDeployment(this.orgInfos.depOrg);
    if (updated) {
      let newOrgSubject = new OrgInfoSubjectFe(ActionFe.UPDATED, newOrgInfo);
      this.newOrgInfoSubject.next(newOrgSubject);
      return true;
    } else {
      return false;
    }
  }

  public async upgradeOrganizationalInfo(): Promise<boolean> {
    let orgInfos = await this.backendService.deployNewOrganizationInfo();
    if (orgInfos) {
      let depOrgBe = orgInfos.depOrg;
      let newOrgBe = orgInfos.newOrg;
      let depOrg = OrganizationInfoFe.fromTransfer(depOrgBe, undefined);
      let newOrg = OrganizationInfoFe.fromTransfer(newOrgBe, depOrg);
      this.orgInfos = { depOrg: depOrg, newOrg: newOrg };
      let depOrgSubject = new OrgInfoSubjectFe(ActionFe.UPDATED, depOrg);
      let newOrgSubject = new OrgInfoSubjectFe(ActionFe.UPDATED, newOrg);
      this.depOrgInfoSubject.next(depOrgSubject);
      this.newOrgInfoSubject.next(newOrgSubject);
      return true;
    } else {
      return false;
    }
  }

  public async getOrganizationTableDatas(force: boolean = false): Promise<TableDataFe[]> {
    let { depOrg, newOrg } = await this.getOrgInfos();
    if (this.activeWorkspace.companyType == CompanyTypeFe.MANAGEMENT && (!this.orgTables || force)) {
      let backednOrgTables = await this.backendService.readOrganizationTables();
      this.orgTables = backednOrgTables.map((bot) => {
        let entity: OrganizationEntityFe = depOrg.entities.find((e) => e.key == bot.key);
        let dataTableFe = TableDataFe.fromTransfer(bot, entity, this.languageService.getDisplayActiveLanguage());
        return dataTableFe;
      });
      this.organizationFilter = new OrganizaationFilter(this.orgInfos.depOrg, this.orgTables);
      this.orgTablesUpdatedSubject.next(this.orgTables);
    }
    return this.orgTables;
  }

  public async updateOrganizationTableData(diffTables: TableDataFe[]): Promise<boolean> {
    let backednOrgTables = await this.backendService.updateOrganizatonTables(diffTables);
    this.orgTables = backednOrgTables.map((bot) => {
      let entity: OrganizationEntityFe = this.orgInfos.depOrg.entities.find((e) => e.key == bot.key);
      let dataTableFe = TableDataFe.fromTransfer(bot, entity, this.languageService.getDisplayActiveLanguage());
      return dataTableFe;
    });
    this.orgTablesUpdatedSubject.next(this.orgTables);
    return true;
  }

  async getPartners(reload = false): Promise<SupplierCompanyFe[]> {
    if (!this.partners || reload) {
      let partnersBe = await this.backendService.getPartners();
      this.partners = partnersBe?.map((partner) => SupplierCompanyFe.fromTransfer(partner));
    }
    return this.partners;
  }

  async getContacts(reload = false): Promise<ContactFe[]> {
    if (!this.contacts || reload) {
      let contactsBe = await this.backendService.getContacts();
      this.contacts = contactsBe?.map((contact) => ContactFe.fromTransfer(contact));
    }
    let requests = await this.getAllRequests();
    let dashboards = await this.getDashboards();
    this.contacts.forEach((c) => {
      c.dataRequests = requests.filter((r) => r.assigneeAffId == c.affiliationId);
      c.sharedDashboards = dashboards.filter((d) => d.recipients.filter((r) => r.affId == c.affiliationId).length > 0);
    });
    return this.contacts;
  }

  async getActiveContacts(reload = false): Promise<ContactFe[]> {
    if (!this.contacts || reload) {
      await this.getContacts();
    }
    let activeContacts = this.contacts.filter((c) => c.affiliationStatus == AffiliationStatus.ACTIVE);
    return activeContacts;
  }

  async getPartnerInvitations(reload = false): Promise<PartnerInvitationFe[]> {
    if (!this.partnerInvitations || reload) {
      let backendData = await this.backendService.getPartnerInvitations();
      this.partnerInvitations = backendData.map((pi) => PartnerInvitationFe.fromTransfer(pi));
      return this.partnerInvitations;
    }
    return this.partnerInvitations;
  }

  async inviteNewPartner(partnerInvitation: PartnerInvitation_AddFe): Promise<boolean> {
    let isAdded = await this.backendService.addPartnerInvitation(partnerInvitation);
    if (isAdded) {
      let newInvitation = await this.checkExistedContactEmailForPartnerInvitation(partnerInvitation.uuid);
      this.partnerInvitations.push(newInvitation.invitation);
      let subject = new PartnerInvitationSubjectFe(ActionFe.CREATED, newInvitation.invitation);
      this.partnerInvitationUpdatedSubject.next(subject);
      return true;
    }
    return false;
  }

  async acceptPartnerInvitation(uuid: string): Promise<boolean> {
    let isAccepted = await this.backendService.acceptPartnerInvitation(uuid);
    if (isAccepted) {
      return true;
    }
    return false;
  }

  async rejectPartnerInvitation(partnerInvitation: PartnerInvitationFe, rejectionReason: string, rejectionExplanation: string): Promise<boolean> {
    let isRejected = await this.backendService.rejectPartnerInvitation(partnerInvitation.uuid, rejectionReason, rejectionExplanation);
    if (isRejected) {
      return true;
    }
    return false;
  }

  async cancelPartnerInvitation(uuid: string): Promise<boolean> {
    let isCancelled = await this.backendService.cancelPartnerInvitation(uuid);
    if (isCancelled) {
      const invitation = this.partnerInvitations.find((pi) => pi.uuid === uuid);
      invitation.invitationStatus = InvitationStatusFe.CANCELED;
      let subject = new PartnerInvitationSubjectFe(ActionFe.UPDATED, invitation);
      this.partnerInvitationUpdatedSubject.next(subject);
      return true;
    }
    return false;
  }

  async registerPartner(register: Partner_RegisterFe): Promise<boolean> {
    let isRegistered = await this.backendService.registerPartner(register);
    if (isRegistered) {
      return true;
    }
    return false;
  }

  async checkExistedContactEmailForPartnerInvitation(uuid: string): Promise<PartnerInvitationExtendedFe> {
    let invitationExtendedBe = await this.backendService.checkExistedContactEmailForPartnerInvitation(uuid);
    let invitationExtended = PartnerInvitationExtendedFe.fromTransfer(invitationExtendedBe);
    return invitationExtended;
  }

  async checkExistingUserCompanies(details: PartnerInvitation_CheckExistingUserCompaniesFe): Promise<PartnerInvitationnExistedUserCompaniesFe> {
    let existingCompaniesBe = await this.backendService.checkExistingUserCompanies(details);
    let existingCompanies = PartnerInvitationnExistedUserCompaniesFe.fromTransfer(existingCompaniesBe);
    return existingCompanies;
  }

  async registerExistingCompany(details: Partner_RegisterReuseExistedUserCompanyIdFe): Promise<boolean> {
    let isRegistered = await this.backendService.registerExistingCompany(details);
    if (isRegistered) {
      return true;
    }
    return false;
  }

  async registerNonExistingCompany(details: Partner_RegisterNonExistedCompanyFe): Promise<boolean> {
    let isRegistered = await this.backendService.registerNonExistingCompany(details);
    if (isRegistered) {
      return true;
    }
    return false;
  }

  async registerAfterClashResolution(details: Partner_RegisterWithIdClashResolutionFe): Promise<boolean> {
    let isRegistered = await this.backendService.registerAfterClashResolution(details);
    if (isRegistered) {
      return true;
    }
    return false;
  }

  async checkIdClash(details: PartnerInvitation_CheckIdClashFe): Promise<PartnerInvitationnIdClashCompanyFe> {
    let idClashBe = await this.backendService.checkIdClash(details);
    let idClash = PartnerInvitationnIdClashCompanyFe.fromTransfer(idClashBe);
    return idClash;
  }

  async getContactInvitationExtendedByUUID(uuid: string): Promise<ContactInvitationExtendedFe> {
    let invitationExtendedBe = await this.backendService.getContactInvitationExtendedByUUID(uuid);
    let contactInvitation = ContactInvitationExtendedFe.fromTransfer(invitationExtendedBe);
    return contactInvitation;
  }

  async getContactInvitations(reload = false): Promise<ContactInvitationFe[]> {
    if (!this.contactInvitations || reload) {
      let backendData = await this.backendService.getContactInvitations();
      this.contactInvitations = backendData.map((ci) => ContactInvitationFe.fromTransfer(ci));
      return this.contactInvitations;
    }
    return this.contactInvitations;
  }

  async inviteNewContact(contactInvitation: ContactInvitation_AddFe): Promise<ContactInvitationFe> {
    let isAdded = await this.backendService.addContactInvitation(contactInvitation);
    if (isAdded) {
      let newInvitation = await this.getContactInvitationExtendedByUUID(contactInvitation.uuid);
      this.contactInvitations.push(newInvitation.invitation);
      let subject = new ContactInvitationSubjectFe(ActionFe.CREATED, newInvitation.invitation);
      this.contactInvitationUpdatedSubject.next(subject);
      return newInvitation.invitation;
    }
  }

  async acceptContactInvitation(uuid: string): Promise<boolean> {
    let isAccepted = await this.backendService.acceptContactInvitation(uuid);
    if (isAccepted) {
      return true;
    }
    return false;
  }

  async rejectContactInvitation(invitation: ContactInvitation_RejectFe): Promise<boolean> {
    let isRejected = await this.backendService.rejectContactInvitation(invitation);
    if (isRejected) {
      return true;
    }
    return false;
  }

  async cancelContactInvitation(uuid: string): Promise<boolean> {
    let isCancelled = await this.backendService.cancelContactInvitation(uuid);
    if (isCancelled) {
      const invitation = this.contactInvitations.find((pi) => pi.uuid === uuid);
      invitation.invitationStatus = InvitationStatusFe.CANCELED;
      let subject = new ContactInvitationSubjectFe(ActionFe.UPDATED, invitation);
      this.contactInvitationUpdatedSubject.next(subject);
      return true;
    }
    return false;
  }

  async registerContact(register: Contact_RegisterFe): Promise<boolean> {
    let isRegistered = await this.backendService.registerContact(register);
    if (isRegistered) {
      return true;
    }
    return false;
  }

  async getManagerInvitationExtendedByUUID(uuid: string): Promise<ManagerInvitationExtendedFe> {
    let invitationExtendedBe = await this.backendService.getManagerInvitationExtendedByUUID(uuid);
    let managerInvitationExtended = ManagerInvitationExtendedFe.fromTransfer(invitationExtendedBe);
    return managerInvitationExtended;
  }

  async registerManager(register: Manager_RegisterFe): Promise<boolean> {
    let isRegistered = await this.backendService.registerManager(register);
    if (isRegistered) {
      return true;
    }
    return false;
  }

  async inviteNewManager(managerInvitation: ManagerInvitation_AddFe): Promise<boolean> {
    let isAdded = await this.backendService.addManagerInvitation(managerInvitation);
    if (isAdded) {
      let newInvitation = await this.getManagerInvitationExtendedByUUID(managerInvitation.uuid);
      this.managerInvitations.push(newInvitation.invitation);
      let subject = new ManagerInvitationSubjectFe(ActionFe.CREATED, newInvitation.invitation);
      this.managerInvitationUpdatedSubject.next(subject);
      return true;
    }
    return false;
  }

  async acceptManagerInvitation(uuid: string): Promise<boolean> {
    let isAccepted = await this.backendService.acceptManagerInvitation(uuid);
    if (isAccepted) {
      return true;
    }
    return false;
  }

  async rejectManagerInvitation(invitation: ManagerInvitation_Reject): Promise<boolean> {
    let isRejected = await this.backendService.rejectManagerInvitation(invitation);
    if (isRejected) {
      return true;
    }
    return false;
  }

  async cancelManagerInvitation(uuid: string): Promise<boolean> {
    let isCanceled = await this.backendService.cancelManagerInvitation(uuid);
    if (isCanceled) {
      const invitation = this.managerInvitations.find((pi) => pi.uuid === uuid);
      invitation.invitationStatus = InvitationStatusFe.CANCELED;
      let subject = new ManagerInvitationSubjectFe(ActionFe.UPDATED, invitation);
      this.managerInvitationUpdatedSubject.next(subject);
      return true;
    }
    return false;
  }

  async addManagementCompany(company: Company_AddFe): Promise<string> {
    let newCompanyId = await this.backendService.addManagementCompany(company);
    if (newCompanyId) {
      let newCompany = new AccessibleCompanyFe(this.loginService.loginUser.userId, newCompanyId, company.name, CompanyTypeFe.MANAGEMENT, this.activeWorkspace.affiliationId, this.activeWorkspace.affiliationRole, 0, 0, 0, [], false);
      this.loginService.loginUser.accessibleCompanies.push(newCompany);
      let mgmtCompany = new CompanyFe(newCompanyId, company.name, CompanyTypeFe.MANAGEMENT, company.purpose, company.countryCode, company.industryGroup, company.industry, company.website, []);
      this.mgmtCompaniesUpdatedSubject.next(mgmtCompany);
      this.accessibleCompaniesUpdatedSubject.next(newCompany);
    }
    return newCompanyId;
  }

  async getPartner(companyId: string): Promise<SupplierCompanyFe> {
    const companyFe = this.partners.find((c) => c.supplierCompanyId === companyId)!;
    return companyFe;
  }

  async getContact(affiliationId: string): Promise<ContactFe> {
    const userFe = this.contacts.find((user) => user.affiliationId === affiliationId)!;
    return userFe;
  }

  async addRequestGroup(addRequestGroup: RequestGroup_AddFe, keepDraft: boolean): Promise<RequestGroupFe> {
    const copyRequestGroup = _.cloneDeep(addRequestGroup)
    if (keepDraft) {
      copyRequestGroup.id = IdUtil.next();
      copyRequestGroup.draft = true;
      const savedCopy = RequestGroupFe.formTransfer(await this.backendService.addRequestGroup(copyRequestGroup));
      this.requestGroups.unshift(savedCopy);
    }
    
    const savedRequestGroupBe = await this.backendService.addRequestGroup(addRequestGroup);
    if (savedRequestGroupBe) {
      let savedRequestGroupFe = RequestGroupFe.formTransfer(savedRequestGroupBe);
      this.requestGroups.unshift(savedRequestGroupFe);
      this.requestGroupAddedSubject.next(savedRequestGroupFe);

      if (!addRequestGroup.draft) {
        savedRequestGroupFe.requests = [];

        addRequestGroup.requests.forEach(async (r) => {
          let assignee = await this.getContact(r.assigneeAffId);
          let requestFe = new RequestFe(
            r.id,
            assignee.affiliationCompanyId,
            assignee.supplierCompanyname,
            assignee.affiliationId,
            assignee.userFirstName,
            assignee.userLastName,
            REQUEST_TASK_STATUS.CREATED,
            READ_STATUS.READ,
            addRequestGroup.id,
            addRequestGroup.questionnaire
          );

          requestFe.questionnaire = new QuestionnaireFe([], addRequestGroup.questionnaire.autoConnect, r.id);
          addRequestGroup.questionnaire.sections.forEach((section) => {
            requestFe.questionnaire.sections.push(section);
          });
          
          let activity: AbstractActivityFe = new CreateRequestActivityFe(
            IdUtil.next(),
            this.activeWorkspace.affiliationId,
            this.loginService.loginUser.firstName,
            this.loginService.loginUser.lastName,
            this.activeWorkspace.companyId,
            this.activeWorkspace.companyName,
            new Date(),
            null,
            false,
            READ_STATUS.READ,
            r.id,
            savedRequestGroupFe.id,
            savedRequestGroupFe.title,
            this.languageService
          );

          this.allRequestActivities.unshift(activity);
          requestFe.timelineItems.push(activity);
          savedRequestGroupFe.requests.push(requestFe);
          this.requests.push(requestFe);
        });
      }
      return savedRequestGroupFe;
    }
    return undefined;
  }

  async updateRequestGroupQuestionnaire(requestGroup: RequestGroup_UpdateFe) {
    await this.backendService.updateRequestGroupQuestionnaire(requestGroup);
    let reqs = this.requests.filter((r) => r.requestGroupId == requestGroup.requestGroupId);
    reqs.forEach((req) => (req.questionnaire = requestGroup.questionnaire));
  }

  async getRequestGroups(reload = false): Promise<RequestGroupFe[]> {
    if (!this.requestGroups || reload) {
      let requestGroupsBe = await this.backendService.getRequestGroups();
      this.requestGroups = requestGroupsBe?.map((rg) => {
        return RequestGroupFe.formTransfer(rg);
      });
    }
    return this.requestGroups;
  }

  async deleteDraftRequestGroup(id: string): Promise<void> {
    let deleted = await this.backendService.deleteDraftRequestGroup(id);
    this.requestGroups = this.requestGroups.filter((g) => g.id != id);
  }

  async getAllRequests(reload = false): Promise<RequestFe[]> {
    if (!this.requests || reload) {
      let requestsBe = await this.backendService.getAllRequests();
      let taxonomy = await this.getTaxonomyInfos();
      this.requests = requestsBe?.map((rg) => RequestFe.formTransfer(rg, taxonomy.depTaxonomy));
    }
    return this.requests;
  }

  async getRequestsByGroupId(requestGroupId: string) {
    let rgs = await this.getRequestGroups();
    let rg = rgs.find((rg) => rg.id == requestGroupId);
    let reqs = await this.getAllRequests();
    let req = reqs.filter((r) => r.requestGroupId == requestGroupId);
    if (rg) rg.requests = req;
    return req;
  }

  async getAllRequestActivities(reload = false): Promise<AbstractActivityFe[]> {
    if (!this.allRequestActivities || reload) {
      let activitiesBe = await this.backendService.getAllRequestActivities();
      let activities = [];
      activitiesBe.forEach((activityBe) => {
        let activityFe = ActivityTypeConversionFe.fromTransfer(activityBe, this.languageService);
        if (activityFe) {
          activities.push(activityFe);
        }
      });
      this.allRequestActivities = activities;
      for (let a of this.allRequestActivities) {
        await this.adjustActivity(a);
      }
    }
    return this.allRequestActivities;
  }

  async adjustActivity(a: AbstractActivityFe) {
    let requests = await this.getRequestsByGroupId(a.requestGroupId);
    let req = requests.find((r) => r.id == a.requestId);
    if (req) {
      a.requestAssigneeAffId = req.assigneeAffId;
      a.requestAssigneeFirstName = req.assigneeFirstName;
      a.requestAssigneeLastName = req.assigneeLastName;
      a.requestAssigneeCompanyName = req.supplierCompanyName;
    }
  }

  async adjustRequestGroupData() {
    let allReqActivities = await this.getAllRequestActivities();
    for (let rg of this.requestGroups) {
      rg.requests = await this.getRequestsByGroupId(rg.id);
      for (let r of rg.requests) {
        let activities = [...allReqActivities.filter((a) => a?.requestId == r.id)];
        activities.forEach((a) => {
          if (a instanceof SubmitAnswersActivityFe) {
            a.setStatus(activities);
          }
        });
        r.timelineItems = activities.sort((a, b) => new Date(a.submissionDate).getTime() - new Date(b.submissionDate).getTime());
        r.questionnaire.adjustQuestionnaire(r.timelineItems);
        r.questionnaire.sections.forEach((section) => {
          if (section.taxonomyKey) {
            let a = r.timelineItems
              .slice()
              .reverse()
              .find((a) => (a instanceof ConnectSectionsActivityFe && a.connectedSectionIds?.includes(section.id)) || (a instanceof DisconnectSectionsActivityFe && a.disconnectedSectionIds?.includes(section.id)));
            if (a instanceof ConnectSectionsActivityFe) {
              section.isConnected = true;
            } else {
              section.isConnected = false;
            }
            if (section.entity) {
              section.questions.forEach((q) => q.setMappedToCols(section.entity));
            }
          }
        });
      }
      rg.setStatus();
    }
  }

  async adjustTaskData() {
    let allTaskActivities = await this.getAllTaskActivities();
    let rgs = await this.getRequestGroups();
    this.tasks.forEach((t) => {
      let taskActivities = allTaskActivities.filter((a) => a.requestId == t.id);
      t.questionnaire.setStatus(taskActivities);
      t.timelineItems = taskActivities.sort((a, b) => new Date(a.submissionDate).getTime() - new Date(b.submissionDate).getTime());
      t.timelineItems.forEach((a) => {
        if (a instanceof SubmitAnswersActivityFe) {
          a.setStatus(taskActivities);
        }
      });
      t.requestGroup = rgs.find((rg) => rg.id == t.requestGroupId);
    });
  }

  async addRequestTimelineItem(addActivity: AbstractActivity_AddFe, requestGroupId: string, requestId: string): Promise<AbstractActivityFe> {
    let requestGroup = this.requestGroups.find((rg) => rg.id == requestGroupId);
    let request = requestGroup.requests.find((r) => r.id == requestId);
    let isAdded = false;
    let activity: AbstractActivityFe;
    if (addActivity instanceof AcceptSubmissionActivity_AddFe) {
      isAdded = await this.backendService.acceptQuestionnaire(addActivity);
      request.status = REQUEST_TASK_STATUS.APPROVED;
      activity = new AcceptSubmissionActivityFe(
        addActivity.id,
        this.activeWorkspace.affiliationId,
        this.loginService.loginUser.firstName,
        this.loginService.loginUser.lastName,
        this.activeWorkspace.companyId,
        this.activeWorkspace.companyName,
        new Date(),
        addActivity.message,
        addActivity.draft,
        READ_STATUS.READ,
        addActivity.requestTaskId,
        addActivity.requestGroupId,
        addActivity.requestGroupLabel,
        addActivity.acceptedActivityId,
        this.languageService
      );
      let acceptedAct = request.timelineItems.find((a) => a.id == addActivity.acceptedActivityId);
      (acceptedAct as SubmitAnswersActivityFe).status = ActivityStatusFe.APPROVED;
      this.requestTimelineItemsUpdatedSubject.next(acceptedAct);
      this.adjustActivity(activity);
    } else if (addActivity instanceof RejectSubmissionActivity_AddFe) {
      isAdded = await this.backendService.rejectQuestionnaire(addActivity);
      request.status = REQUEST_TASK_STATUS.IN_PROGRESS;
      activity = new RejectSubmissionActivityFe(
        addActivity.id,
        this.activeWorkspace.affiliationId,
        this.loginService.loginUser.firstName,
        this.loginService.loginUser.lastName,
        this.activeWorkspace.companyId,
        this.activeWorkspace.companyName,
        new Date(),
        addActivity.message,
        addActivity.draft,
        READ_STATUS.READ,
        addActivity.requestTaskId,
        addActivity.requestGroupId,
        addActivity.requestGroupLabel,
        addActivity.rejectedActivityId,
        this.languageService
      );
      let rejectedAct = request.timelineItems.find((a) => a.id == addActivity.rejectedActivityId);
      (rejectedAct as SubmitAnswersActivityFe).status = ActivityStatusFe.REJECTED;
      this.requestTimelineItemsUpdatedSubject.next(rejectedAct);
      this.adjustActivity(activity);
    } else if (addActivity instanceof SubmitMessageActivity_AddFe) {
      isAdded = await this.backendService.submitRequestMessage(addActivity);
      activity = new SubmitMessageActivityFe(
        addActivity.id,
        this.activeWorkspace.affiliationId,
        this.loginService.loginUser.firstName,
        this.loginService.loginUser.lastName,
        this.activeWorkspace.companyId,
        this.activeWorkspace.companyName,
        new Date(),
        addActivity.message,
        addActivity.draft,
        READ_STATUS.READ,
        addActivity.requestTaskId,
        addActivity.requestGroupId,
        addActivity.requestGroupLabel,
        this.languageService
      );
    } else if (addActivity instanceof CloseRequestActivity_AddFe) {
      isAdded = await this.backendService.closeRequest(addActivity);
      request.status = REQUEST_TASK_STATUS.CLOSED;
      activity = new CloseRequestActivityFe(
        addActivity.id,
        this.activeWorkspace.affiliationId,
        this.loginService.loginUser.firstName,
        this.loginService.loginUser.lastName,
        this.activeWorkspace.companyId,
        this.activeWorkspace.companyName,
        new Date(),
        addActivity.message,
        addActivity.draft,
        READ_STATUS.READ,
        addActivity.requestTaskId,
        addActivity.requestGroupId,
        addActivity.requestGroupLabel,
        this.languageService
      );
      requestGroup.setStatus();
    } else if (addActivity instanceof ConnectSectionsActivity_AddFe) {
      isAdded = await this.backendService.connectRequestTableToPipeline(addActivity);
      activity = new ConnectSectionsActivityFe(
        addActivity.id,
        this.activeWorkspace.affiliationId,
        this.loginService.loginUser.firstName,
        this.loginService.loginUser.lastName,
        this.activeWorkspace.companyId,
        this.activeWorkspace.companyName,
        new Date(),
        addActivity.message,
        addActivity.draft,
        READ_STATUS.READ,
        addActivity.requestTaskId,
        addActivity.requestGroupId,
        addActivity.requestGroupLabel,
        addActivity.submittedAnswerActivityId,
        addActivity.connectedAnswers.map((answer) => answer.id),
        this.languageService
      );
    } else if (addActivity instanceof DisconnectSectionsActivity_AddFe) {
      isAdded = await this.backendService.disconnectRequestTableFromPipeline(addActivity);
      activity = new DisconnectSectionsActivityFe(
        addActivity.id,
        this.activeWorkspace.affiliationId,
        this.loginService.loginUser.firstName,
        this.loginService.loginUser.lastName,
        this.activeWorkspace.companyId,
        this.activeWorkspace.companyName,
        new Date(),
        addActivity.message,
        addActivity.draft,
        READ_STATUS.READ,
        addActivity.requestTaskId,
        addActivity.requestGroupId,
        addActivity.requestGroupLabel,
        addActivity.submittedAnswerActivityId,
        addActivity.disconnectedSections.map((section) => section.id),
        this.languageService
      );
    }

    if (isAdded) {
      request.timelineItems.push(activity);
      this.allRequestActivities.unshift(activity);
      this.requestTimelineItemsAddedSubject.next(activity);
      return activity;
    }
    return null;
  }

  async markAllRequestActivitiesRead(requestGroup: RequestGroupFe, request: RequestFe): Promise<void> {
    let unreadActivities = request.timelineItems.filter((a) => a.readStatus == READ_STATUS.UNREAD);
    if (unreadActivities.length > 0) {
      await this.backendService.markAllRequestActivitiesRead(request.id);
      request.readStatus = READ_STATUS.READ;
      this.activeWorkspace.noOfUnreadActivities -= unreadActivities.length;
      unreadActivities.forEach((a) => (a.readStatus = READ_STATUS.READ));
      let areAllRequestsRead = requestGroup.requests.every((r) => r.readStatus == READ_STATUS.READ);
      if (areAllRequestsRead) {
        requestGroup.readStatus = READ_STATUS.READ;
      }
    }
  }

  async getTasks(reload = false): Promise<TaskFe[]> {
    if (!this.tasks || reload) {
      let tasksBe = await this.backendService.getTasks();
      let tasks = tasksBe?.map((r) => TaskFe.fromTransfer(r));
      this.tasks = tasks;
    }
    return this.tasks;
  }

  async getAllTaskActivities(reload = false): Promise<AbstractActivityFe[]> {
    if (!this.allTaskActivities || reload) {
      let activitiesBe = await this.backendService.getAllTaskActivities();
      let activities = [];
      activitiesBe.forEach((activityBe) => {
        let activityFe = ActivityTypeConversionFe.fromTransfer(activityBe, this.languageService);
        activities.push(activityFe);
      });
      this.allTaskActivities = activities;
    }
    return this.allTaskActivities;
  }

  async markAllTaskActivitiesRead(task: TaskFe): Promise<void> {
    let unreadActivities = task.timelineItems.filter((a) => a.readStatus == READ_STATUS.UNREAD);
    if (unreadActivities.length > 0) {
      await this.backendService.markAllTaskActivitiesRead(task.managementCompanyId, task.id);
      this.activeWorkspace.noOfUnreadActivities -= unreadActivities.length;
      task.readStatus = READ_STATUS.READ;
      unreadActivities.forEach((a) => (a.readStatus = READ_STATUS.READ));
    }
  }

  async addTaskTimelineItem(addActivity: AbstractActivity_AddFe, task: TaskFe): Promise<AbstractActivityFe> {
    let isAdded = false;
    let activity: AbstractActivityFe;
    if (addActivity instanceof SubmitAnswersActivity_AddFe) {
      isAdded = await this.backendService.submitAnswers(addActivity);
      activity = new SubmitAnswersActivityFe(
        addActivity.id,
        this.activeWorkspace.affiliationId,
        this.loginService.loginUser.firstName,
        this.loginService.loginUser.lastName,
        this.activeWorkspace.companyId,
        this.activeWorkspace.companyName,
        new Date(),
        addActivity.message,
        addActivity.draft,
        READ_STATUS.READ,
        addActivity.requestTaskId,
        addActivity.requestGroupId,
        addActivity.requestGroupLabel,
        addActivity.answers.map((a) => AnswerTypeConversionFe.fromTransfer(a)),
        this.languageService
      );
      if (!activity.draft) task.status = TASK_STATUS.IN_PROGRESS;
    } else if (addActivity instanceof SubmitMessageActivity_AddFe) {
      isAdded = await this.backendService.submitTaskMessage(addActivity);
      activity = new SubmitMessageActivityFe(
        addActivity.id,
        this.activeWorkspace.affiliationId,
        this.loginService.loginUser.firstName,
        this.loginService.loginUser.lastName,
        this.activeWorkspace.companyId,
        this.activeWorkspace.companyName,
        new Date(),
        addActivity.message,
        addActivity.draft,
        READ_STATUS.READ,
        addActivity.requestTaskId,
        addActivity.requestGroupId,
        addActivity.requestGroupLabel,
        this.languageService
      );
    }
    if (isAdded) {
      task.timelineItems.push(activity);
      this.taskTimelineItemsAddedSubject.next(activity);
      this.allTaskActivities.unshift(activity);
      return activity;
    }
    return null;
  }

  async updateDraftAnswer(activity: SubmitAnswersActivity_AddFe, draftActivity: SubmitAnswersActivityFe) {
    let task = this.tasks.find((t) => t.id == activity.requestTaskId);
    let a = task.timelineItems.find((a) => a.id == activity.id);
    a = new SubmitAnswersActivityFe(
      draftActivity.id,
      draftActivity.submitterAffId,
      draftActivity.submitterFirstName,
      draftActivity.submitterLastName,
      draftActivity.submitterCompanyId,
      draftActivity.submitterCompanyName,
      new Date(),
      activity.message,
      activity.draft,
      READ_STATUS.READ,
      activity.requestTaskId,
      activity.requestGroupId,
      activity.requestGroupLabel,
      activity.answers.map((a) => AnswerTypeConversionFe.fromTransfer(a)) as AnswerFe[],
      this.languageService
    );
    await this.backendService.updateDraftAnswer(activity);

    this.taskTimelineItemsUpdatedSubject.next(a);
    let index = this.allTaskActivities.findIndex((act) => act.id == a.id);
    this.allTaskActivities[index] = a;
  }

  async getAllFiles(reload = false) {
    if (!this.allExplorerFiles || reload) {
      let files = await this.backendService.listAllFiles(this.activeWorkspace.companyId);
      this.allExplorerFiles = files.map((file) => new FileDataFe(file));
      this.allExplorerFiles.sort((a, b) => DateUtil.getTimeDifference(b.timeCreated, a.timeCreated));
      let requestGroups = await this.getRequestGroups()
      this.allExplorerFiles.forEach((file) => {
        if (file.requestGroupId) {
          let rg = requestGroups.find(rg => rg.id == file.requestGroupId);
          let request = rg.requests.find(r => r.id == file.requestId);
          let question = request.questionnaire.getQuestions().find(r => r.id == file.questionId)
          if (rg && request && question) {
            const label = AnswerAttachmentFe.getTitle(rg.title, question.question, request.getAssigneeName(), file.timeCreated, file.label)
            file.label = label
          }
        } 
      })
    }
    return this.allExplorerFiles;
  }

  async uploadExplorerFile(fileData: any, file: FileDataFe): Promise<any> {
    let data = await this.backendService.uploadExplorerFile(this.activeWorkspace.companyId, fileData);
    file.uploaderFirstName = this.loginService.loginUser.firstName;
    file.uploaderLastName = this.loginService.loginUser.lastName;
    file.uploaderCompany = this.activeWorkspace.companyName;
    file.uplaoderAffId = this.activeWorkspace.affiliationId;
    file.uploaderCompanyId = this.activeWorkspace.companyName;
    file.source = FileSourceFe.EXPLORER;
    this.explorerFilesUpdatedSubject.next(file);
    this.allExplorerFiles.unshift(file);
    return data;
  }

  async deleteExplorerFile(fileName: string): Promise<any> {
    let data = await this.backendService.deleteExplorerFile(fileName);
    this.allExplorerFiles = this.allExplorerFiles.filter((f) => f.label != fileName);
    return data;
  }

  async uploadAttachmentFile(managementCOMPANYId: string, selectedFile: any): Promise<any> {
    let data = await this.backendService.uploadAttachmentFile(managementCOMPANYId, selectedFile);
    return data;
  }

  async downloadAttachmentFile(managementCOMPANYId: string, fileName: any): Promise<Blob> {
    return this.backendService.downloadAttachmentFile(managementCOMPANYId, fileName);
  }

  async uploadDataTableFile(managementCOMPANYId: string, selectedFile: any): Promise<any> {
    let data = await this.backendService.uploadDataTableFile(managementCOMPANYId, selectedFile);
    return data;
  }

  async downloadDataTableFile(managementCOMPANYId: string, fileName: any): Promise<Blob> {
    let file = await this.backendService.downloadDataTableFile(managementCOMPANYId, fileName);
    return file;
  }

  async changePassword(userObj: ChangePasswordFe): Promise<boolean> {
    let changed = await this.backendService.changePassword(userObj);
    return changed;
  }

  async changeLanguage(langCode: string): Promise<boolean> {
    return await this.backendService.changeLanguage(langCode);
  }

  async changeProfileEmailLanguage(profileLangCode: string, emailLangCode: string): Promise<boolean> {
    return await this.backendService.changeProfileEmailLanguage(profileLangCode, emailLangCode);
  }

  async changeAllEmailLanguage(langCode: string): Promise<boolean> {
    return await this.backendService.changeEmailLanguage(langCode);
  }

  async getCompanyEmailLanguage(): Promise<any> {
    const activeWorkspace: string = this.activeWorkspace.companyId;
    return await this.backendService.getCompanyEmailLanguage(activeWorkspace);
  }

  async getEmailInvitationLang(email: string): Promise<any> {
    return await this.backendService.getEmailInvitationLang(email);
  }

  async getAllEmailTemplates(): Promise<EmailResponseFe> {
    const response = await this.backendService.getAllEmailTemplates();
    return await EmailResponseFe.fromTransfer(response);
  }

  async resetAllTemplates(): Promise<any> {
    return await this.backendService.resetAllTemplates();
  }

  async resetTemplate(emailName: string): Promise<any> {
    return await this.backendService.resetTemplate(emailName);
  }

  async saveTemplate(fileName: string, langCode: string, template: string): Promise<any> {
    return await this.backendService.saveTemplate(fileName, langCode, template);
  }

  async getEmails(reload = false): Promise<EmailFe[]> {
    let emails = await this.backendService.getEmails();
    this.emails = emails.map((e) => EmailFe.fromTransfer(e));
    return this.emails;
  }

  async getDistinctColumnValues(colName: string, tableName: string): Promise<any[]> {
    let values = await this.backendService.getDistinctColumnValues(colName, tableName);
    return values;
  }

  async readMasterTable(entityKey: string, pageSize: number, pageNo: number, rowIdsThatItShouldHave?: string[]): Promise<TableDataFe> {
    let table = await this.backendService.readMasterTable(entityKey, pageSize, pageNo, rowIdsThatItShouldHave);
    return table;
  }

  async readWholeMasterTable(entityKey: string): Promise<TableDataFe> {
    let table = await this.backendService.readWholeMasterTable(entityKey);
    return table;
  }

  async getMasterTableRowsForKpiRow(kpiDef: KpiDefFe, kpiId: string, kpiRowId: string, entity: EntityFe): Promise<TableDataFe> {
    let backendTableData: any = await this.backendService.getMasterTableRowsForKpiRow(kpiDef.id, kpiId, kpiRowId);
    let table = TableDataFe.fromTransfer(backendTableData, entity, this.languageService.getDisplayActiveLanguage());
    table.label = kpiDef.title;
    return table;
  }

  async getMasterTablesForInsightKpiRow(insightDef: InsightDefFe, kpiId: string, kpiRowId: string): Promise<TableDataFe[]> {
    let backendTables: any = await this.backendService.getMasterTableRowsForInsightKpiRow(insightDef.id, kpiId, kpiRowId);
    let { depTaxonomy } = await this.getTaxonomyInfos();
    let tables = backendTables.map((table) => {
      let key = table.key;
      let entity = depTaxonomy.entityByKey(key);
      return TableDataFe.fromTransfer(table, entity, this.languageService.getDisplayActiveLanguage());
    });
    return tables;
  }

  async getAllContacts(reload = false): Promise<ContactFe[]> {
    if (!this.allContacts || reload) {
      let contacts = await this.backendService.getAllContacts();
      this.allContacts = contacts.map((contact) => ContactFe.fromTransfer(contact));
    }
    return this.allContacts;
  }

  async getAllCustomerSuccess(reload = false): Promise<CustomerSuccessFe[]> {
    if (!this.allCustomerSuccess || reload) {
      let cs: AbstractAffiliationFe[] = await this.backendService.getAllCustomerSuccess();
      let companies = await this.getAllManagementCompanies();
      this.allCustomerSuccess = [];
      this.inactiveCustomerSuccess = [];
      cs.forEach((c) => {
        let company = companies.find((com) => com.id == c.affiliationCompanyId);
        if (c.affiliationStatus == AffiliationStatus.ACTIVE) {
          let customerSuccess = this.allCustomerSuccess.find((a) => a.userId == c.userId);
          if (!customerSuccess) {
            customerSuccess = CustomerSuccessFe.fromTransfer(c);
            this.allCustomerSuccess.push(customerSuccess);
          }
          let acompany = new AccessibleCompanyFe(customerSuccess.userId, c.affiliationCompanyId, company.name, company.companyAccountType, c.affiliationId, AffiliationRoleFe.CUSTOMER_SUCCESS, 0, 0, 0, c.signInMethods, c.mfaEnabled);
          acompany.lastLoggedAt = c.lastLoggedAt ? new Date(c.lastLoggedAt) : null;
          acompany.accessReceivedAt = new Date(c.activationDate);
          customerSuccess.accessibleCompanies.push(acompany);
        } else {
          let customerSuccess = CustomerSuccessFe.fromTransfer(c);
          let acompany = new AccessibleCompanyFe(customerSuccess.userId, c.affiliationCompanyId, company.name, company.companyAccountType, c.affiliationId, AffiliationRoleFe.CUSTOMER_SUCCESS, 0, 0, 0, c.signInMethods, c.mfaEnabled);
          customerSuccess.joinedOn = new Date(c.activationDate);
          customerSuccess.deactivationDate = new Date(c.deactivationDate);
          customerSuccess.accessibleCompanies.push(acompany);
          this.inactiveCustomerSuccess.push(customerSuccess);
        }
      });
      this.allCustomerSuccess.forEach((c) => {
        c.setJoinedOn();
        c.setLastLoggedAt();
      });
    }
    return this.allCustomerSuccess;
  }

  getInactiveCS() {
    return this.inactiveCustomerSuccess;
  }

  async getAllAdmins(reload = false): Promise<AdminFe[]> {
    if (!this.allAdmins || reload) {
      let admins = await this.backendService.getAllAdmins();
      this.allAdmins = admins.map((c) => AdminFe.fromTransfer(c));
    }
    return this.allAdmins;
  }

  async getAllManagers(reload = false): Promise<ManagerFe[]> {
    if (!this.allManagers || reload) {
      let managers = await this.backendService.getAllManagers();
      this.allManagers = managers.map((manager) => ManagerFe.fromTransfer(manager));
    }
    return this.allManagers;
  }

  async getAllManagerInvitations(reload = false): Promise<ManagerInvitationFe[]> {
    if (!this.managerInvitations || reload) {
      let backendData = await this.backendService.getAllManagerInvitations();
      this.managerInvitations = backendData.map((ci) => ManagerInvitationFe.fromTransfer(ci));
      return this.managerInvitations;
    }
    return this.managerInvitations;
  }

  async getAllContactInvitations(reload = false): Promise<ContactInvitationFe[]> {
    if (!this.contactInvitations || reload) {
      let backendData = await this.backendService.getAllContactInvitations();
      this.contactInvitations = backendData.map((ci) => ContactInvitationFe.fromTransfer(ci));
      return this.contactInvitations;
    }
    return this.contactInvitations;
  }

  async getManagementCompanyById(id: string): Promise<CompanyFe> {
    let companyBe = await this.backendService.getManagementCompanyById(id);
    let companyFe = CompanyFe.fromTransfer(companyBe);
    return companyFe;
  }

  async getAllManagementCompanies(reload = false): Promise<CompanyFe[]> {
    if (!this.mgmtCompanies || reload) {
      let comapnies = await this.backendService.getAllManagementCompanies();
      this.mgmtCompanies = comapnies.map((company) => CompanyFe.fromTransfer(company));
    }
    return this.mgmtCompanies;
  }

  async loginAs(affiliationId: string, affiliationRole: string, companyId: string) {
    let userInfo = this.loginService.getUserInfo();
    this.cleanCache();
    this.activeWorkspace = userInfo.accessibleCompanies.find((c) => c.companyId == companyId);
    this.activeWorkspace.affiliationId = affiliationId;
    this.activeWorkspace.affiliationRole = affiliationRole;
    let workspaceChangedInBackend = await this.backendService.loginAs(companyId, affiliationRole, affiliationId);
    this.loginService.loginUser.activeWorkspaceCompanyId = companyId;
    this.loginService.loginUser.activeWorkspaceRoleType = affiliationRole;
    await this.loadWorkspaceData(this.activeWorkspace, true);
    this.router.navigate([RoutesFe.HOME.fullPath()]);
  }

  async deactivateAff({ affiliationId }) {
    const deactivated = await this.backendService.deactivateAff(affiliationId);
  }

  async deactivateContact(contact: ContactFe) {
    const deactivated = await this.backendService.deactivateContact(contact);
    let c = this.contacts.find((c) => c.affiliationId == contact.affiliationId && c.affiliationCompanyId == contact.affiliationCompanyId);
    c.affiliationStatus = AffiliationStatus.INACTIVE;
    c.deactivationDate = new Date();
    return deactivated;
  }

  async deactivateAllCSAff(userId) {
    const deactivated = await this.backendService.deactivateAllCSAffs(userId);
    let index = this.allCustomerSuccess.findIndex((c) => c.userId === userId);
    this.allCustomerSuccess[index].status == AffiliationStatus.INACTIVE;
    this.allCustomerSuccess[index].deactivationDate = new Date();
    let subject = new CustomerSuccessSubjectFe(ActionFe.DELETED, this.allCustomerSuccess[index]);
    this.csUpdatedSubject.next(subject);
    this.inactiveCustomerSuccess.push(this.allCustomerSuccess[index]);
    this.allCustomerSuccess.splice(index, 1);
  }

  async assignMainManager(affiliationId) {
    const assigned = await this.backendService.setMainManager(affiliationId, true);
    let manager = this.allManagers.find((m) => m.affiliationId === affiliationId);
    manager.isMainManager = true;
  }

  async removeMainManager(affiliationId) {
    const removed = await this.backendService.setMainManager(affiliationId, false);
    let manager = this.allManagers.find((m) => m.affiliationId === affiliationId);
    manager.isMainManager = false;
  }

  setInviteAgainManager(user: ManagerFe) {
    this.inviteAgainManager = user;
  }

  async addAdmin(admin_add: Admin_AddFe): Promise<AdminFe> {
    let adminBe = await this.backendService.addAdmin(admin_add);
    let admin = AdminFe.fromTransfer(adminBe);
    this.allAdmins.push(admin);
    return admin;
  }

  async addCS(cs_add: CustomerSuccess_AddFe): Promise<void> {
    let csBe = await this.backendService.addCS(cs_add);
    let cs = CustomerSuccessFe.fromTransfer(csBe);
    cs_add.assignedCompanys.forEach((c) => {
      let acompany = new AccessibleCompanyFe(cs.userId, c.id, c.name, c.companyAccountType, null, AffiliationRoleFe.CUSTOMER_SUCCESS, 0, 0, 0, [], false);
      acompany.accessReceivedAt = new Date();
      cs.accessibleCompanies.push(acompany);
    });
    cs.setJoinedOn();
    this.allCustomerSuccess.push(cs);
    let subject = new CustomerSuccessSubjectFe(ActionFe.CREATED, cs);
    this.csUpdatedSubject.next(subject);
  }

  async updateCS(cs_update: CustomerSuccess_UpdateFe): Promise<void> {
    await this.backendService.updateCS(cs_update);
    let index = this.allCustomerSuccess.findIndex((c) => c.userId === cs_update.userId);
    this.allCustomerSuccess[index].accessibleCompanies = this.allCustomerSuccess[index].accessibleCompanies.filter((c) => !cs_update.removedCompanyIds.includes(c.companyId));
    cs_update.newAssignedCompanyIds.forEach((c) => {
      let acompany = new AccessibleCompanyFe(cs_update.userId, c.id, c.name, c.companyAccountType, null, AffiliationRoleFe.CUSTOMER_SUCCESS, 0, 0, 0, [], false);
      acompany.accessReceivedAt = new Date();
      this.allCustomerSuccess[index].accessibleCompanies.push(acompany);
    });
    let subject = new CustomerSuccessSubjectFe(ActionFe.UPDATED, this.allCustomerSuccess[index]);
    this.csUpdatedSubject.next(subject);
  }

  async updateCompanySignInSettings(signInSettings: SignInMethodUpdateFe) {
    await this.backendService.updateCompanySignInSettings(signInSettings);
    let company = this.mgmtCompanies.find((c) => c.id == signInSettings.companyId);
    company.signInMethods = signInSettings.signInMethods;
  }

  async updateAffSignInSettings(signInSettings: SignInMethodUpdateFe, userType: string = null) {
    let hasAffiliationsWithMfaEnabled = await this.backendService.updateAffSignInSettings(signInSettings);
    let user: AbstractAffiliationFe;
    if (userType == "SM") {
      user = this.allManagers.find((c) => c.affiliationId == signInSettings.affiliationId);
    } else if (userType == "DO") {
      let user = this.allContacts.find((c) => c.affiliationId == signInSettings.affiliationId);
    }
    user.signInMethods = signInSettings.signInMethods;
    user.mfaEnabled = signInSettings.mfaRequired;
    return hasAffiliationsWithMfaEnabled;
  }

  async updateAdminSignInSettings(signInSettings: SignInMethodUpdateFe) {
    await this.backendService.updateAdminSignInSettings(signInSettings);
    let admin = this.allAdmins.find((c) => c.affiliationId == signInSettings.affiliationId);
    admin.signInMethods = signInSettings.signInMethods;
    admin.mfaEnabled = signInSettings.mfaRequired;
  }

  async updateCSSignInSettings(signInSettings: SignInMethodUpdateFe) {
    await this.backendService.updateCsSignInSettings(signInSettings);
    let user = this.allCustomerSuccess.find((c) => c.userId == signInSettings.userId);
    user.signInMethods = signInSettings.signInMethods;
    user.mfaEnabled = signInSettings.mfaRequired;
  }

  async copyTaxonomyDataToNewCompany(newCompanyId: string, companyId: string) {
    let copied = await this.backendService.copyTaxonomyDataToNewCompany(newCompanyId, companyId);
    return copied;
  }

  async upgradeTaxnomyDataForNewCompany(newCompanyId: string) {
    let upgraded = await this.backendService.deployNewTaxonomyInfo(newCompanyId);
    return upgraded;
  }

  async copyKPIsToNewCompany(newCompanyId: string, companyId: string) {
    let copied = await this.backendService.copyKPIsToNewCompany(newCompanyId, companyId);
    return copied;
  }

  async copyDashboardsToNewCompany(newCompanyId: string, companyId: string) {
    let copied = await this.backendService.copyDashboardsToNewCompany(newCompanyId, companyId);
    return copied;
  }

  async copyDraftRequestsToNewCompany(newCompanyId: string, companyId: string) {
    let copied = await this.backendService.copyDraftRequestsToNewCompany(newCompanyId, companyId);
    return copied;
  }

  async addRequestFolder(newRequestFolder: RequestFolder_AddFe) {
    await this.backendService.addRequestFolder(newRequestFolder);
    let folder = RequestFolderFe.fromTransfer(newRequestFolder);
    this.requestFolders.splice(0, 0, folder);
  }

  async updateRequestFolder(newRequestFolder: RequestFolder_AddFe) {
    await this.backendService.updateRequestFolder(newRequestFolder);
    let folders = await this.getRequestFolders();
    let folder = folders.find((folder) => folder.id === newRequestFolder.id);
    folder = RequestFolderFe.fromTransfer(newRequestFolder);
  }

  async deleteRequestFolder(requestFolder: RequestFolderFe) {
    await this.backendService.deleteRequestFolder(requestFolder);
    this.requestFolders = this.requestFolders.filter((folder) => folder.id != requestFolder.id);
  }

  async getRequestFolders(reload = false): Promise<RequestFolderFe[]> {
    if (!this.requestFolders || reload) {
      let requestFoldersBe = await this.backendService.getRequestFolders();
      this.requestFolders = requestFoldersBe?.map((requestFolder) => {
        return RequestFolderFe.fromTransfer(requestFolder);
      });
    }
    return this.requestFolders;
  }

  public setView(view: ViewFe, obj?: any): void {
    this.viewSubject.next(new ViewSubjectFe(view, obj));
  }

  async updateRecurrence(requestGroup: RequestGroup_AddFe) {
    await this.backendService.updateRecurrence(requestGroup);
    let rgs = await this.getRequestGroups();
    let rg = rgs.find((rg) => rg.id === requestGroup.id);
    rg.recurrence = requestGroup.recurrence;
    this.requestGroupUpdatedSubject.next(rg);
  }

  async updateReminder(requestGroup: RequestGroup_AddFe) {
    await this.backendService.updateReminder(requestGroup);
    let rgs = await this.getRequestGroups();
    let rg = rgs.find((rg) => rg.id === requestGroup.id);
    rg.recurrence = requestGroup.recurrence;
    this.requestGroupUpdatedSubject.next(rg);
  }

  async remindNow(requestGroup: RequestGroup_AddFe) {
    await this.backendService.remindNow(requestGroup);
    let rgs = await this.getRequestGroups();
    let rg = rgs.find((rg) => rg.id === requestGroup.id);
    rg.recurrence = requestGroup.recurrence;
    this.requestGroupUpdatedSubject.next(rg);
  }

  async stopRecurrence(requestGroup: RequestGroup_AddFe) {
    await this.backendService.stopRecurrence(requestGroup);
    let rgs = await this.getRequestGroups();
    let rg = rgs.find((rg) => rg.id === requestGroup.id);
    rg.recurrence = requestGroup.recurrence;
    this.requestGroupUpdatedSubject.next(rg);
  }

  async updateRecentlyOpenedItems() {
    let items = this.activeWorkspace.recentlyOpenedItems.map((item) => RecentlyOpenedItemFe.fromTransfer(item));
    await this.backendService.updateRecentlyOpenedItems(items);
  }

  async fetchNewRecentlyOpenedItems() {
    let itemsBe = await this.backendService.fetchRecentlyOpenedItems();
    let itemsFe = itemsBe.map((item) => RecentlyOpenedItemFe.fromTransfer(item));
    if (this.activeWorkspace) {
      this.activeWorkspace.recentlyOpenedItems = itemsFe;
    }
  }

  async generateUnitPrefixes() {
    const unitEvaluator = this.unitEvaluator;
    //Generate prefixes
    this.units = this.units.map((unit) => {
      unit.prefixList = [];
      //if has prefixes
      if (unit.hasPrefixes) {  
        const symbolList = [unit.symbol, ...unit.aliases.split(",")];
        symbolList.forEach(symbol => {
          UnitPrefixes.forEach((prefix) => {
            const unitPrefix = new UnitFe({
              name: `${prefix.name}~${unit.name}`,
              symbol: `${prefix.symbol}${symbol}`,
              measurementType: unit.measurementType,
              shouldDisplay: true
            });

            const unitPrefixLong = new UnitFe({
              name: `${prefix.name}~${unit.name}`,
              symbol: `${prefix.name}${symbol}`,
              measurementType: unit.measurementType,
              shouldDisplay: true
            });

            // Ensure prefix can be converted before adding to list
            try{
              const exp = `1${unitPrefix.symbol} to ${unit.symbol}`;
              unitEvaluator.evaluate(exp);

              const check = _.find(unit.prefixList,{symbol:unitPrefix.symbol});
              if(_.isEmpty(check)){
                unit.prefixList.push(unitPrefix);
              }
            }catch(err){}

            try{
              const exp = `1${unitPrefixLong.symbol} to ${unit.symbol}`;
              unitEvaluator.evaluate(exp);

              const check = _.find(unit.prefixList,{symbol:unitPrefixLong.symbol});
              if(_.isEmpty(check)){
                unit.prefixList.push(unitPrefixLong);
              }
            }catch(err){}
          });
        })
        return unit;
      }
      return unit;
    });
  }

  async getUnits(reload = false, companyId?: string): Promise<UnitFe[]> {
    if (!this.units || reload) {
      if (!companyId) {
        companyId = this.activeWorkspace.companyId;
      }
      const unitsTransfered = await this.backendService.getUnits(companyId);
      const units = unitsTransfered.map((unitTransfered) => new UnitFe({ ...unitTransfered }));
      this.units = units;

      // add no unit option
      const noUnit = this.unitService.getNoUnit();
      this.units.push(noUnit);
      this.units = _.sortBy(this.units, [(unit) => unit.name.toLowerCase()]);
      await this.generateUnitPrefixes();
      this.unitEvaluator = this.unitService.generateUnitEvaluator({ units: this.units });

      //Generate MTCodes for each unit
      this.units = this.units.map((unit) => {
        unit.MTCode = this.unitService.generateMTCode({ unit, units });
        return unit;
      });
    }
    
    return this.units;
  }

  async addUnit(unit: UnitFe) {
    await this.backendService.addUnit(unit);
    this.units.push(unit);
    await this.generateUnitPrefixes();
    this.unitEvaluator = this.unitService.generateUnitEvaluator({ units: this.units });
    this.unitsUpdated.next(this.units);
  }

  async deleteUnit(unit: UnitFe) {
    await this.backendService.deleteUnit(unit);
    this.units = this.units.filter((u) => u.symbol != unit.symbol);
    await this.generateUnitPrefixes();
    this.unitEvaluator = this.unitService.generateUnitEvaluator({ units: this.units });
    this.unitsUpdated.next(this.units);
  }

  async editUnit({ unit, oldUnitSymbol }) {
    await this.backendService.editUnit({ unit, oldUnitSymbol });
    await this.getUnits(true);
    this.unitsUpdated.next(this.units);
  }
  
  async getGlobalDatabases(reload = false) {
    if (reload || !this.globalDatabases) {
      let factorsBe = await this.backendService.getGlobalDatabases()
      this.globalDatabases = factorsBe.map(f => GlobalDatabaseFe.fromTransfer(f))
    }
    return this.globalDatabases
  }

  async getGlobalEmissionFactors(reload = false) {
    if (reload || !this.globalEmissionFactors) {
      let factorsBe = await this.backendService.getGlobalEmissionFactors()
      this.globalEmissionFactors = factorsBe.map(f => GlobalEmissionFactorFe.fromTransfer(f))
    }
    return this.globalEmissionFactors
  }
  
  async getCustomEmissionFactors(reload = false, companyId: string = null) {
    if (reload || !this.customEmissionFactors) {
      if (!companyId) {
        companyId = this.activeWorkspace.companyId
      }
      let factorsBe = await this.backendService.getCustomEmissionFactors(companyId)
      this.customEmissionFactors = factorsBe.map(f => CustomEmissionFactorFe.fromTransfer(f))
    }
    return this.customEmissionFactors
  }
  
  async addEmissionFactor(ef: CustomEmissionFactorFe) {
    await this.backendService.addEmissionFactor(ef);
    this.customEmissionFactors.push(ef)
  }

  async adjustGlobalEmissionFactors() {
    if (!this.globalDatabases) {
      await this.getGlobalDatabases();
    }
    if (!this.globalEmissionFactors) {
      await this.getGlobalEmissionFactors();
    }
    const databases = await this.getGlobalDatabseMap()
    this.globalEmissionFactors.forEach(factor => {
      factor.relatedDatabase = databases.get(factor.databaseId);
    })
  }

  async getGlobalDatabseMap() {
    if (!this.globalDatabases) {
      await this.getGlobalDatabases();
    }
    return new Map(this.globalDatabases.map(db => [db.id, db]))
  }

  async updateMasterTable(tableData: TableDataFe) {
    await this.backendService.updateMasterTable(tableData);
  }

  getActiveProject(): AbstractProjectInfoFe | undefined{
    return this.activeProject
  }

  updateActiveProject(project: AbstractProjectInfoFe) {
    this.activeProject = project
    this.activeProjectSubject.next(project)
  }

  async getProjectInfos(reload = false): Promise<AbstractProjectInfoFe[]> {
    if (!this.projects || reload) {
      let projectInfosBe = await this.backendService.getProjectInfos();
      this.projects = projectInfosBe.map((p) => PROJECT_TYPE.fromTransfer(p));
    }

    let withoutTimestamp = this.projects.filter(p => _.isEmpty(p.createdAt));
    withoutTimestamp = _.sortBy(withoutTimestamp,["name"])
    
    let withTimestamp = this.projects.filter(p => !_.isEmpty(p.createdAt));
    withTimestamp = _.sortBy(withTimestamp,["createdAt"]).reverse();

    this.projects = [ ...withTimestamp, ...withoutTimestamp ];
    return this.projects;
  }

  async createNewProject(newProject: AbstractProjectInfoAddFe): Promise<AbstractProjectInfoFe> {
    let project: AbstractProjectInfoFe
    if (newProject instanceof CCProjectInfoFe) {
      let beProject = await this.backendService.addCCProjectInfo(newProject); 
      project = CCProjectInfoFe.fromTransfer(beProject);
    } else if (newProject instanceof CSRDProjectInfoAddFe) {
      let beProject = await this.backendService.addCSRDProjectInfo(newProject); 
      project = CSRDProjectInfoFe.fromTransfer(beProject);
    }
    this.projects.push(project);
    this.projectsUpdated.next();
    return project
  }

  async deleteProject(project: AbstractProjectInfoFe): Promise<boolean> {
    let deleted = await this.backendService.deleteProject(project.id);
    if (deleted) {
      this.projects = this.projects.filter((p) => p.id != project.id);
    }
    this.projectsUpdated.next();
    return deleted;
  }

  async updateCsrdProject(project: CSRDProjectInfoFe) {
    await this.backendService.updateCsrdProjectInfo(project);
    this.projects = this.projects.map( _project => {
      return (_project.id == project.id)? project : _project;
    })
    this.projectsUpdated.next();
    this.updateActiveProject(project)
    return project
  }

  getUnitEvaluator() {
    let evaluater;
    if (_.isEmpty(this.unitEvaluator)) {
      const units = this.units;
      evaluater = this.unitService.generateUnitEvaluator({ units });
      this.unitEvaluator = evaluater;
    } else {
      evaluater = this.unitEvaluator;
    }
    return evaluater;
  }

  getBackendService(): RouterFe {
    return this.backendService
  }

 }

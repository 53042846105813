import { Subject } from "rxjs";
import { ChartSettingFeNew } from "src/app/model/chart/ChartSettingFeNew";
import { DataSeriesFe } from "src/app/model/insight/DataSeriesFe";
import { InsightFe } from "src/app/model/insight/InsightFe";
import { KpiFe2 } from "src/app/model/insight/KpiFe2";
import { ColumnSchemaFe } from "src/app/model/schema/ColumnSchemaFe";
import { RowFe } from "src/app/model/schema/RowFe";
import { RowStatusFe } from "src/app/model/schema/RowStatusFe";
import { TableDataFe } from "src/app/model/schema/TableDataFe";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import * as XLSX from 'xlsx';
import { DataGridRow } from "../projects/data-grid-ui/model/DataGridRow";
import { DataGridTableData } from "../projects/data-grid-ui/model/DataGridTableData";
import { DataGridTableMode } from "../projects/data-grid-ui/model/DataGridTableMode";
import { DataGridObserverInterface } from "../projects/data-grid-ui/service/DataGridObserverInterface";
import { DataGridServiceInterface } from "../projects/data-grid-ui/service/DataGridServiceInterface";
import { DataGridColumnType } from "../projects/data-grid-ui/model/DataGridColumnType";
export class KpiDataGridIntergationService implements DataGridServiceInterface {
  stateService: StateServiceFe
  // entity: EntityFe
  currentPageData: DataGridTableData
  table: TableDataFe
  observer: DataGridObserverInterface | undefined
  mode: string = DataGridTableMode.SELECT_TABLE_ROW
  hasTablePagination = false
  insight: InsightFe
  kpi: KpiFe2
  openMasterTable = new Subject();
  selectedRow: DataGridRow
  downloading = false

  constructor (insight: InsightFe, stateService: StateServiceFe) {
    this.insight = insight
    this.stateService = stateService
  }

  setTable(setting: ChartSettingFeNew, render = true) {
    let kpi = this.getKpi(this.insight, setting)
    this.kpi = kpi
    let schema: ColumnSchemaFe[] = [] 
    if (kpi.rows && kpi.rows.length > 0) {
      Object.keys(kpi.rows[0].rowValues).map((key) => {
        let col;
        let dataSeries = this.insight.definition.dataSeries.find((ds: DataSeriesFe) => ds.id == key)
        if (dataSeries) {
          col = dataSeries
        } else {
          let grouping = this.insight.definition.groupings.find((ds: DataSeriesFe) => ds.id == key)
          if (grouping) {
            col = grouping
          } else if (this.insight.definition.timeGrouping && (key =='YEAR' || key =='QUARTER' || key =='MONTH' || key =='WEEK')) {
            col = {label: key}
          }
        }
        if (col) {
          let colSchema = new ColumnSchemaFe();
          colSchema.name = col.label
          colSchema.label = col.label
          colSchema.type = col instanceof DataSeriesFe ? DataGridColumnType.NUMERIC : DataGridColumnType.STRING 
          if (colSchema.type === DataGridColumnType.NUMERIC) {
            colSchema.unitSymbol = this.insight.definition.unitSymbol ? this.insight.definition.unitSymbol : this.insight.definition.unitLabel
            colSchema.name += ` (${colSchema.unitSymbol})`
            colSchema.label += ` (${colSchema.unitSymbol})`
          }
          colSchema.showUnitCol = false
          schema.push(colSchema)  
        }
      })
    }

    let rows = kpi.applyFilters(setting).map((row, index) => {
      let rowFe = new RowFe()
      rowFe.ordinal = index;
      rowFe.rowId = row.rowId;
      rowFe.values = Object.values(row.rowValues);
      rowFe.status = RowStatusFe.STORED
      return rowFe;
    })

    this.table = new TableDataFe()
    this.table.key = this.insight.id
    this.table.label = this.insight.title
    this.table.name = this.insight.title
    this.table.dataSchema = schema
    this.table.rows = rows
    this.table.rowCount = rows.length
    if (render) {
      this.renderTable()
    }
  }

  registerObserver(observer: DataGridObserverInterface): void {
    this.observer = observer
  }

  getMode (): string {
    return this.mode
  }

  getMainTableData (): DataGridTableData {
    let dgtd = this.table.toDataGridTableData()
    return dgtd
  }

  hasMainTablePagination (): boolean {
    return this.hasTablePagination
  }

  async loadMainTablePage(pageSize: number, pageNumber: number): Promise<DataGridTableData> {
    this.currentPageData = this.table.toDataGridTableData();
    this.currentPageData.rows = this.currentPageData.rows.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    this.currentPageData.rowCount = this.currentPageData.rows.length
    return this.currentPageData
  }

  markDataModified (modified: boolean, isDataValid: boolean): void {
  }

  hasDataModifiedElseWhere (): boolean {
    return false
  }

  saveReferenceTable (table:DataGridTableData): void {
  }

  async exportSelectedRows(rows: DataGridRow[]): Promise<void> {
    this.selectedRow = rows[0];
    this.openMasterTable.next(true);
  }

  getReferenceTables (): DataGridTableData[] {
    return []
  }

  getNoOfMinRows (): number {
    return 5;
  }

  async downloadCurrentPage(exportType?: string, title?: string) {
    this.downloading = true
    const extension: string = (exportType && ['csv', 'xlsx'].includes(exportType)) ? exportType : 'xlsx';
    let fileName = title ? `${title}.${extension}` : (this.insight.title ? `${this.insight.title}.${extension}` : `chart-data.${extension}`);

    var data = [];
    data[0] = [];
    for (let col of this.table.dataSchema) {
      data[0].push(col.getColumnName())
    }

    this.table.rows.forEach((r, i) => {
      data.push([]);
      r.values.map((val) => data[i+1].push(val))
    })
    
    const sheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet);
    await XLSX.writeFile(workbook, fileName);
    this.downloading = false
  }
  
  getKpi(insight: InsightFe, chartSetting: ChartSettingFeNew): KpiFe2 {
    if (insight.isTimed() && chartSetting.principalComparisonTime) {
      return insight.kpis[chartSetting.timeScaleIndex]
    } else if (!insight.isTimed() && chartSetting.principalCategoryFieldIndex == -1) {
      return insight.kpis[0]
    } else if (chartSetting.principalCategoryFieldIndex > -1) {
      if (insight.isTimed()) {
        return insight.kpis[chartSetting.timeScaleIndex]
      } else {
        return insight.kpis[0]
      }
    } else {
      throw Error('No chart implementation found for chart-type: ' + chartSetting.chartType.id)
    }
  }

  renderTable() {
    this.observer?.renderNewDataTable()
  }
}
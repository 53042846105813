import { QuestionFe } from "./QuestionFe";
import { QUESTION_TYPE } from "./QuestionTypeFe"
import { BooleanStatementFe } from "./condition/BooleanStatementFe";
import { AnswerDate_AddFe } from "../../timeline/add/answer/AnswerDate_AddFe";
import { IdUtil } from "src/app/utils/IdUtil";
import { AnswerDateFe } from "../../timeline/answer/AnswerDateFe";
import { BooleanStatementBuilderFe } from "./condition/BooleanStatementBuilderFe";
import { FormControl } from "@angular/forms";
import { TaxonomyAttributeFe } from "src/app/model/taxonomy/TaxonomyAttributeFe";
import { EntityFe } from "src/app/model/taxonomy/EntityFe";
import * as _ from "lodash"
import { PredefinedDateOptionFe } from "./predefinedoption/PredefinedDateOptionFe";

export class QuestionDateFe extends QuestionFe {
  range: boolean
  mappedToColsRange: TaxonomyAttributeFe[] = []

  rangeControl = new FormControl('specific')
  newAnswer: AnswerDate_AddFe = new AnswerDate_AddFe(IdUtil.next(), this.sectionId, this.id, null, null, null, false)
  answer: AnswerDateFe

  predefinedOptions: PredefinedDateOptionFe[]
  customValuesAllowedWhenPredefinedOptionsSet: boolean = true

  constructor(
      id: string, 
      sectionId: string,
      ordinal: number | null,
      followUpCondition: BooleanStatementFe | null,
      question: string,
      desc: string | null,
      comments: boolean,
      required: boolean,
      mappedToColKeys: string[] | null,
      range: boolean,
      public mappedToColKeysRange: string[] | null,
      predefinedOptions: PredefinedDateOptionFe[],
      customValuesAllowedWhenPredefinedOptionsSet: boolean,
      csrdDatapointId: string | undefined
    ) {
    super(
      id, 
      sectionId,
      QUESTION_TYPE.DATE,
      ordinal, 
      followUpCondition, 
      question, 
      desc, 
      comments, 
      required,
      mappedToColKeys, 
      csrdDatapointId
    );
    this.range = range
    this.newAnswer.range = range
    this.predefinedOptions = predefinedOptions
    this.customValuesAllowedWhenPredefinedOptionsSet = customValuesAllowedWhenPredefinedOptionsSet
  }

  public static fromTransfer(transfer: any, sectionId): QuestionDateFe {
    transfer.followUpCondition = transfer.followUpCondition ? BooleanStatementBuilderFe.fromFormulaString(transfer.followUpCondition) : null
    let item = this.setQuestion(transfer, sectionId);
    return item
  }
  
  public static setQuestion (transfer: any, sectionId?): QuestionDateFe {
    let item = new QuestionDateFe (
      transfer.id,
      sectionId ? sectionId : transfer.sectionId,
      transfer.ordinal,
      transfer.followUpCondition,
      transfer.question,
      transfer.desc,
      transfer.comments,
      transfer.required,
      transfer.mappedToColKeys,
      transfer.range,
      transfer.mappedToColKeysRange,
      transfer.predefinedOptions? transfer.predefinedOptions.map(item => PredefinedDateOptionFe.fromJsonObj(item)): [],
      transfer.customValuesAllowedWhenPredefinedOptionsSet?? true,
      transfer.csrdDatapointId
    )    
    item.rangeControl.setValue(item.range? 'range' : 'specific')
    item.isFollowUpQues = !!transfer.followUpCondition
    return item
  }
  
  isRangeFieldSelected(col: TaxonomyAttributeFe): boolean {
    let isSelected = this.mappedToColKeysRange.includes(col.key)
    return isSelected
  }
  
  toggleRangeField(col: TaxonomyAttributeFe) {
    if (this.isRangeFieldSelected(col)) {
      this.mappedToColsRange = this.mappedToColsRange.filter(column => column != col)
    } else {
      this.mappedToColsRange.push(col)
    }
    this.mappedToColKeysRange = this.mappedToColsRange.map(col => col.key)
  }

  setMappedToColsRange(entity: EntityFe) {
    if (this.mappedToColKeysRange && _.isArray(this.mappedToColKeysRange) && entity)
    this.mappedToColsRange = this.mappedToColKeysRange.map(key => entity.getColByKey(key))
  }

  getSortedPredfinedDateOptions(): PredefinedDateOptionFe[] {
    return this.predefinedOptions
    .filter((item) => item)
    .sort((a: PredefinedDateOptionFe, b: PredefinedDateOptionFe) => {
      if (a.from && b.from) {
        return new Date(a.from).getTime() - new Date(b.from).getTime()
      }
      return 0;
    })
  }
}
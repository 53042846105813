<ng-template #unitEditor>
  <div class="unit_editor">
    <div class="header" style="">
      <div class="title" style="color: var(--grey-white, #fff); font-family: Open Sans; font-size: 16px; font-style: normal; font-weight: 400; line-height: normal">
        <ng-container *ngIf="state == 'ADD_UNIT'">{{ locale("locale_key.general.unit_editor.add_custom") }}</ng-container>
        <ng-container *ngIf="state == 'EDIT_UNIT'">
          <ng-container *ngIf="unit.isCustom">
            {{ locale("locale_key.general.unit_editor.edit_custom_unit") }}
          </ng-container>

          <ng-container *ngIf="!unit.isCustom">
            {{ locale("locale_key.master_table.edit_standard_unit") }}
          </ng-container>
        </ng-container>
      </div>
      <i class="control close las la-times" style="font-size: 18px; color: var(--grey-white, #fff)" (click)="close()"></i>
    </div>
    <div class="body" style="padding: 20px 16px">
      <!-- NAME SECTION -->
      <div class="section name" style="margin-bottom: 20px">
        <div class="name" style="margin-bottom: 10px">
          <span class="label" style="color: var(--primary-colors-primary, #374659); font-family: Open Sans; font-size: 14px; font-style: normal; font-weight: 600; line-height: normal; margin-right: 10px">
            {{ locale("locale_key.file_picker.name.title") }}
          </span>
          *
        </div>
        <input
          [(ngModel)]="nameInput"
          type="text"
          placeholder="{{ locale('locale_key.pages.units.example.kilogram') }}"
          style="
            font-size: 14px;
            border: 1px solid var(--primary-colors-secondary, #d3d3d3);
            background: var(--grey-white, #fff);
            box-shadow: 0px 0px 0px 0px #cbdafc;
            width: 100%;
            height: 38px;
            padding: 0 12px;
            outline: none;
          " />
        <div
          class="error_box"
          [style.display]="hasError['name'] ? 'block' : 'none'"
          style="
            margin-top: 10px;
            color: var(--alert-red, #b8615f);
            font-family: Open Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.32px;
            padding: 16px;
            border-radius: 4px;
            border: 1px solid var(--alert-red, #b8615f);
            background: var(--alert-red-100, #f8efef);
          ">
          {{ errorMsg.name }}
        </div>
      </div>

      <!-- IS COMPOUND SECTION -->
      <div *ngIf="showCustomEditables()" class="section isCompound" style="margin-bottom: 20px">
        <div class="name" style="margin-bottom: 10px">
          <span class="label" style="color: var(--primary-colors-primary, #374659); font-family: Open Sans; font-size: 14px; font-style: normal; font-weight: 600; line-height: normal; margin-right: 2px">
            {{ locale("locale_key.pages.units.is_combiantion_of_other_units") }}
          </span>
          <span class="label light">
            {{ locale('locale_key.pages.units.example.kg_kwh') }}
          </span>
          *
        </div>
        <div class="isCompoundInputGroup" style="display: flex; align-items: center; gap: 20px">
          <div style="display: flex; align-items: center; gap: 8px">
            <input type="radio" name="isCompound" value="true" [(ngModel)]="isCompoundInput" />
            <label for="true">{{ locale("locale_key.general.buttons.yes") }}</label>
          </div>

          <div style="display: flex; align-items: center; gap: 8px">
            <input type="radio" name="isCompound" value="false" checked [(ngModel)]="isCompoundInput" />
            <label for="false">{{ locale("locale_key.general.buttons.no") }}</label>
          </div>
        </div>
        <!-- <div
          class="error_box"
          style="
            margin-top: 10px;
            color: var(--alert-red, #b8615f);
            font-family: Open Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.32px;
            padding: 16px;
            border-radius: 4px;
            border: 1px solid var(--alert-red, #b8615f);
            background: var(--alert-red-100, #f8efef);
          ">
          {{ errorMsg.name }}
        </div> -->
      </div>

      <!-- SYMBOL SECTION -->
      <div class="section symbol" style="margin-bottom: 20px">
        <div class="name" style="margin-bottom: 10px">
          <span class="label" style="color: var(--primary-colors-primary, #374659); font-family: Open Sans; font-size: 14px; font-style: normal; font-weight: 600; line-height: normal; margin-right: 10px">
            {{ locale("locale_key.general.unit_editor.symbol_or_abbreviation") }}
          </span>
          *
        </div>
        <input
          type="text"
          placeholder="{{ slocale('eg. kg') }}"
          [(ngModel)]="symbolInput"
          [class.is_disabled]="disableEditSymbol()"
          [disabled]="disableEditSymbol()"
          style="font-size: 14px; border: 1px solid var(--primary-colors-secondary, #d3d3d3); background: var(--grey-white, #fff); box-shadow: 0px 0px 0px 0px #cbdafc; width: 100%; height: 38px; padding: 0 12px; outline: none" />
        <div
          class="error_box"
          [style.display]="hasError['symbol'] ? 'block' : 'none'"
          style="
            margin-top: 10px;
            color: var(--alert-red, #b8615f);
            font-family: Open Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.32px;
            padding: 16px;
            border-radius: 4px;
            border: 1px solid var(--alert-red, #b8615f);
            background: var(--alert-red-100, #f8efef);
          ">
          {{ errorMsg.symbol }}
        </div>
      </div>


      <!-- COMPOUND SYMBOL SECTION -->
      <div class="section compoundSymbol" style="margin-bottom: 20px" *ngIf="isCompoundInput == 'true'">
        <div class="name" style="margin-bottom: 10px">
          <span class="label" style="color: var(--primary-colors-primary, #374659); font-family: Open Sans; font-size: 14px; font-style: normal; font-weight: 600; line-height: normal; margin-right: 10px">
            {{ locale("locale_key.pages.units.select_units_makes_new_unit") }}
          </span>
          *
        </div>
        <div class="compoundSymbolInputs">
          <unit-selector
            [mode]="'select'"
            [selectBy]="'symbol'"
            (selectedUnitChanged)="leftCompoundSymbolChanged({ unit: $event })"
            [selectedUnitContainer]="leftSelectedUnitContainer"
            [selectedUnitSymbol]="leftSymbolInput"
            [source]="'add_datapoint_modal'"
            [customUnits]="getCustomUnits()"
            [unitsByMeasurementType]="getUnitsByMeasurementType()">
          </unit-selector>
          <span>Per</span>
          <unit-selector
            [mode]="'select'"
            [selectBy]="'symbol'"
            (selectedUnitChanged)="rightCompoundSymbolChanged({ unit: $event })"
            [selectedUnitContainer]="rightSelectedUnitContainer"
            [selectedUnitSymbol]="rightSymbolInput"
            [source]="'add_datapoint_modal'"
            [customUnits]="getCustomUnits()"
            [unitsByMeasurementType]="getUnitsByMeasurementType()">
          </unit-selector>
        </div>
      </div>


      <!-- MEASUREMENT TYPE SECTION -->
      <div *ngIf="showCustomEditables() && isCompoundInput == 'false'" class="section measurementType" style="margin-bottom: 20px">
        <div class="name" style="margin-bottom: 10px">
          <span class="label" style="color: var(--primary-colors-primary, #374659); font-family: Open Sans; font-size: 14px; font-style: normal; font-weight: 600; line-height: normal; margin-right: 10px">
            {{ locale("locale_key.general.unit_editor.measurement_type") }}
          </span>
          *
        </div>
        <unit-selector
          [mode]="'select'"
          [selectBy]="'measurementType'"
          [selectedMeasurementTypeContainer]="measurementTypeInput"
          (selectedMeasurementTypeChanged)="onSelectedMeasurementTypeChanged($event)"
          [source]="'unit_editor_modal'"></unit-selector>
        <div
          class="error_box"
          [style.display]="hasError['measurementType'] ? 'block' : 'none'"
          style="
            margin-top: 10px;
            color: var(--alert-red, #b8615f);
            font-family: Open Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.32px;
            padding: 16px;
            border-radius: 4px;
            border: 1px solid var(--alert-red, #b8615f);
            background: var(--alert-red-100, #f8efef);
          ">
          {{ errorMsg.measurementType }}
        </div>
      </div>

      <!-- IS CONVERTIBLE SECTION -->
      <div *ngIf="showCustomEditables() && isCompoundInput == 'false'" class="section isConvertible" style="margin-bottom: 20px">
        <div class="name" style="margin-bottom: 10px">
          <span class="label" style="color: var(--primary-colors-primary, #374659); font-family: Open Sans; font-size: 14px; font-style: normal; font-weight: 600; line-height: normal; margin-right: 2px">
            {{ locale("locale_key.general.unit_editor.unit_convertible_question") }}
          </span>
          *
        </div>
        <div class="isConvertibleInputGroup" style="display: flex; align-items: center; gap: 20px">
          <div style="display: flex; align-items: center; gap: 8px">
            <input type="radio" name="isConvertible" value="true" [(ngModel)]="isConvertibleInput" (click)="isConvertibleChanged()" />
            <label for="true">{{ locale("locale_key.general.buttons.yes") }}</label>
          </div>

          <div style="display: flex; align-items: center; gap: 8px">
            <input type="radio" name="isConvertible" value="false" checked [(ngModel)]="isConvertibleInput" (click)="isConvertibleChanged()" />
            <label for="false">{{ locale("locale_key.general.buttons.no") }}</label>
          </div>
        </div>
        <div
          class="error_box"
          [style.display]="hasError['isConvertible'] ? 'block' : 'none'"
          style="
            margin-top: 10px;
            color: var(--alert-red, #b8615f);
            font-family: Open Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.32px;
            padding: 16px;
            border-radius: 4px;
            border: 1px solid var(--alert-red, #b8615f);
            background: var(--alert-red-100, #f8efef);
          ">
          {{ errorMsg.isConvertible }}
        </div>
      </div>
      <div class="section conversionFormula" style="margin-bottom: 20px" *ngIf="isConvertibleInput == 'true' && isCompoundInput == 'false'">
        <div class="name" style="margin-bottom: 10px">
          <span class="label" style="color: var(--primary-colors-primary, #374659); font-family: Open Sans; font-size: 14px; font-style: normal; font-weight: 600; line-height: normal; margin-right: 2px">
            {{ locale("locale_key.general.add_conversion_value_or_formula") }}
          </span>
        </div>
        <div class="conversionFormulaBuilder" style="display: flex; gap: 20px">
          <div
            class="conversionFormulaBuilderUnitName"
            style="display: flex; align-items: center; color: var(--Primary-Colors-Primary, #374659); font-family: Open Sans; font-size: 14px; font-style: normal; font-weight: 600; line-height: normal; max-height: 44px">
            1 {{ nameInput }}({{ symbolInput }}) =
          </div>
          <div style="flex: 1">
            <formula-builder
              [showEditMode]="showEditMode"
              [calculation]="unitCalculation"
              [showDatapoints]="showDatapoints"
              [showAddNewDataPointBtn]="showAddNewDataPointBtn"
              [includeMeasurementTypes]="includeMeasurementTypes"
              [showCalculationErrors]="showCalculationErrors"></formula-builder>
          </div>
        </div>
        <!-- <div
          class="error_box"
          style="
            margin-top: 10px;
            color: var(--alert-red, #b8615f);
            font-family: Open Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.32px;
            padding: 16px;
            border-radius: 4px;
            border: 1px solid var(--alert-red, #b8615f);
            background: var(--alert-red-100, #f8efef);
          ">
          {{ errorMsg.name }}
        </div> -->
      </div>

      <div *ngIf="showCustomEditables() && isCompoundInput == 'false'" class="section hasPrefixes" style="margin-bottom: 20px">
        <div class="name" style="margin-bottom: 10px">
          <span
            class="label"
            style="
              color: var(--primary-colors-primary, #374659);
              font-family: Open Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-right: 2px;
            ">
            {{ locale("locale_key.pages.units.validation.are_there_multiples_units") }}
          </span>
          *
        </div>
        <div class="hasPrefixesInputGroup" style="display: flex; align-items: center; gap: 20px">
          <div style="display: flex; align-items: center; gap: 8px">
            <input type="radio" name="hasPrefixes" value="true" [(ngModel)]="hasPrefixesInput" />
            <label for="true">{{ locale("locale_key.general.buttons.yes") }}</label>
          </div>

          <div style="display: flex; align-items: center; gap: 8px">
            <input type="radio" name="hasPrefixes" value="false" checked [(ngModel)]="hasPrefixesInput" />
            <label for="false">{{ locale("locale_key.general.buttons.no") }}</label>
          </div>
        </div>
      </div>
      <!-- DESCRIPTION SECTION -->
      <div class="section description" style="margin-bottom: 20px">
        <div class="name" style="margin-bottom: 10px">
          <span class="label" style="color: var(--primary-colors-primary, #374659); font-family: Open Sans; font-size: 14px; font-style: normal; font-weight: 600; line-height: normal; margin-right: 10px">
            {{ locale("locale_key.master_table.data_point.desc") }}
          </span>
        </div>
        <textarea
          placeholder="{{ locale('locale_key.master_table.add_description_about_this_unit') }}"
          [(ngModel)]="descriptionInput"
          style="
            font-size: 14px;
            border: 1px solid var(--primary-colors-secondary, #d3d3d3);
            background: var(--grey-white, #fff);
            box-shadow: 0px 0px 0px 0px #cbdafc;
            width: 100%;
            min-height: 80px;
            padding: 0 12px;
            outline: none;
          "></textarea>
      </div>

      <div
        *ngIf="savingUnit && state == 'ADD_UNIT'"
        class="section saving_unit_message"
        style="margin-bottom: 20px; border-radius: 4px; border: 1px solid var(--Alert-Green, #68c781); background: var(--Alert-Green-300, #d4f1dc); padding: 16px">
        {{ locale("locale_key.general.new_custom_unit_added_message") }}
      </div>
    </div>

    <div class="footer" style="padding: 12px; display: flex; justify-content: flex-end; align-items: center; gap: 16px">
      <div
        *ngIf="!savingUnit && state == 'EDIT_UNIT' && unit.isCustom"
        class="control delete_unit"
        (click)="startDeleteUnit()"
        style="
          display: flex;
          height: 40px;
          padding: 10px 24px;
          justify-content: center;
          align-items: center;
          border-radius: 3px;
          border: 1px solid var(--Primary-Colors-Primary, #374659);
          background: var(--Grey-White, #fff);
          color: var(--primary-colors-primary, #374659);
          font-family: Open Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          cursor: pointer;
          margin-right: auto;
        ">
        {{ locale("locale_key.general.buttons.delete") }}
      </div>

      <div
        *ngIf="!savingUnit"
        class="control cancel_save_unit"
        (click)="startCancelEditingUnits()"
        style="
          display: flex;
          height: 40px;
          padding: 10px 24px;
          justify-content: center;
          align-items: center;
          border-radius: 3px;
          background: var(--primary-colors-secondary, #d3d3d3);
          color: var(--primary-colors-primary, #374659);
          font-family: Open Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          cursor: pointer;
        ">
        {{ locale("locale_key.general.buttons.cancel") }}
      </div>

      <div class="ball-pulse loader-primary" *ngIf="savingUnit">
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div
        *ngIf="!savingUnit"
        class="control confirm_save_unit"
        (click)="saveUnit()"
        style="
          display: flex;
          height: 39px;
          padding: 10px 24px;
          justify-content: center;
          align-items: center;
          border-radius: 3px;
          background: var(--primary-colors-primary, #374659);
          color: var(--default-white, #fff);
          font-family: Open Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          cursor: pointer;
        ">
        {{ locale("locale_key.general.buttons.save") }}
      </div>

      <div
        *ngIf="false"
        class="control confirm_save_without_reviewing"
        style="
          display: flex;
          height: 39px;
          padding: 10px 24px;
          justify-content: center;
          align-items: center;
          border-radius: 3px;
          background: var(--primary-colors-primary, #374659);
          color: var(--default-white, #fff);
          font-family: Open Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          cursor: pointer;
        ">
        {{ locale("locale_key.general.unit_editor.save_without_reviewing") }}
      </div>

      <div
        *ngIf="false"
        class="control confirm_review_data"
        style="
          display: flex;
          height: 39px;
          padding: 10px 24px;
          justify-content: center;
          align-items: center;
          border-radius: 3px;
          background: var(--primary-colors-primary, #374659);
          color: var(--default-white, #fff);
          font-family: Open Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          cursor: pointer;
        ">
        {{ locale("locale_key.general.unit_editor.review_data") }}
      </div>
    </div>
  </div>
  <div class="unit_data_reviewer"></div>
</ng-template>

<ng-template #deleteUnitModal>
  <div class="delete_category_modal" style="padding: 16px">
    <div class="header" style="display: flex; justify-content: space-between; align-items: center; padding-bottom: 16px">
      <div class="title" style="color: var(--primary-colors-primary, #374659); font-family: Open Sans; font-size: 24px; font-style: normal; font-weight: 700; line-height: normal">
        {{ locale("locale_key.general.delete_unit", { name: unit.name }) }}
      </div>
      <div class="close_control">
        <i class="las la-times" (click)="cancelDeleteUnit()" style="font-size: 24px; color: #374659; opacity: 0.3; cursor: pointer"></i>
      </div>
    </div>
    <div class="body" style="padding-top: 10px; padding-bottom: 20px">
      <div class="message" style="color: var(--primary-colors-primary, #374659); font-family: Open Sans; font-size: 16px; font-style: normal; font-weight: 400; line-height: normal">
        {{ locale("locale_key.general.unit_editor.confirm_delete_custom_unit") }}
      </div>
    </div>
    <div class="footer">
      <div class="controls" style="display: flex; align-items: center; justify-content: flex-end">
        <div class="ball-pulse loader-primary" *ngIf="deletingUnit">
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div
          *ngIf="!deletingUnit"
          class="cancel_control"
          (click)="cancelDeleteUnit()"
          style="
            padding: 10px 16px;
            display: flex;
            width: 120px;
            height: 35px;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 3px;
            background: #d3d3d3;
            color: #374659;
            user-select: none;
            cursor: pointer;
          ">
          {{ locale("locale_key.general.buttons.cancel") }}
        </div>
        <div
          *ngIf="!deletingUnit"
          class="delete_control"
          (click)="confirmDeleteUnit()"
          style="
            margin-left: 16px;
            padding: 10px 16px;
            display: flex;
            width: 120px;
            height: 35px;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 3px;
            background: #b8615f;
            color: #fff;
            user-select: none;
            cursor: pointer;
          ">
          {{ locale("locale_key.general.buttons.delete") }}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cancelEditingUnitModal>
  <div class="delete_category_modal" style="padding: 16px">
    <div class="header" style="display: flex; justify-content: space-between; align-items: center; padding-bottom: 16px">
      <div
        class="title"
        style="
          color: var(--primary-colors-primary, #374659);
          font-family: Open Sans;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        ">
        {{ locale("locale_key.pages.insight_detail.cancel") }}
      </div>
      <div class="close_control">
        <i class="las la-times" (click)="exitCancelEditingUnits()" style="font-size: 24px; color: #374659; opacity: 0.3; cursor: pointer"></i>
      </div>
    </div>
    <div class="body" style="padding-top: 10px; padding-bottom: 20px">
      <div
        class="message"
        style="
          color: var(--primary-colors-primary, #374659);
          font-family: Open Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        ">
        {{ locale("locale_key.master_table.action_will_not_saved") }}
      </div>
    </div>
    <div class="footer">
      <div class="controls" style="display: flex; align-items: center; justify-content: flex-end">
        <div
          class="cancel_control"
          (click)="exitCancelEditingUnits()"
          style="
            padding: 10px 16px;
            display: flex;
            width: 120px;
            height: 35px;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 3px;
            background: #d3d3d3;
            color: #374659;
            user-select: none;
            cursor: pointer;
            color: var(--Primary-Colors-Primary, #374659);
            text-align: center;

            /* Body Reg */
            font-family: Open Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-wrap: nowrap;
          ">
          {{ locale("locale_key.pages.data_categories.back_to_editing") }}
        </div>
        <div
          class="delete_control"
          (click)="confirmCancelEditingUnits()"
          style="
            margin-left: 16px;
            padding: 10px 16px;
            display: flex;
            width: 120px;
            height: 35px;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 3px;
            background: var(--Primary-Colors-Primary, #374659);
            color: #fff;
            user-select: none;
            cursor: pointer;
          ">
          {{ locale("locale_key.pages.insight_detail.cancel") }}
        </div>
      </div>
    </div>
  </div>
</ng-template>

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import {
  CSRDDataPointSettings,
  CSRDDisclosureSettings,
  CSRDDisclosureSettingsStatus,
  CSRDEsrsSettings,
  CSRDProjectInfoFe,
  CSRDTopicSettings
} from "src/app/model/project/CSRDProjectInfoFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import _ from "lodash";
import { CsrdDefintionService } from "../../../CsrdDefintionService";

@Component({
  selector: "csrd-dr-overview",
  templateUrl: "./dr-overview.component.html",
  styleUrls: ["./dr-overview.component.scss"]
})
export class DrOverviewComponent extends AbstractLanguageComponent implements OnInit {
  public DR_STATUSES = CSRDDisclosureSettingsStatus;
  drStatus = CSRDDisclosureSettingsStatus.NOT_STARTED;

  @Output() startAssessment = new EventEmitter<boolean>();
  @Output() startDataRequest = new EventEmitter<boolean>();

  @Input() activeCsrdProject: CSRDProjectInfoFe;
  @Input() activeTopic: any;
  @Input() activeEsrs: any;
  @Input() activeDr: any;

  stats = {
    totalDatapoints: 0,
    materialDatapoints: 0,
    materialOmittedDatapoints: 0,
    nonMaterialDatapoints: 0,
    nonMaterialVoluntaryDatapoints: 0
  };

  csrdDatapoints;

  constructor(languageService: LanguageService, public drService: CsrdDefintionService) {
    super(languageService);
  }

  ngOnInit(): void {
    //Check if DR is mandatory
    if (this.activeEsrs.isMandatory) {
      this.activeDr.isMandatory = true;
    }

    this.csrdDatapoints = this.activeDr.csrdDatapoints.map((datapoint) => {
      //Replace newline with <br> in description properties
      datapoint.phaseIn = datapoint.phaseIn?.split("\n").join("</br>");
      datapoint.conditional = datapoint.conditional?.split("\n").join("</br>");
      datapoint.description = datapoint.description?.split("\n").join("</br>");
      //If application requirements is a string, make it an array
      if(typeof datapoint.applicationRequirements == "string"){
        datapoint.applicationRequirements = [datapoint.applicationRequirements];
      }
      datapoint.applicationRequirements = datapoint.applicationRequirements?.map((applicationRequirement) => applicationRequirement?.split("\n").join("</br>"));
      datapoint.supplementalInfo = datapoint.supplementalInfo?.split("\n").join("</br>");
      datapoint.supplementalReference = datapoint.supplementalReference?.split("\n").join("</br>");
      //If application footnotes is a string, make it an array
      if(typeof datapoint.applicationFootnotes == "string"){
        datapoint.applicationFootnotes = [datapoint.applicationFootnotes]  
      }
      datapoint.applicationFootnotes = datapoint.applicationFootnotes?.map((applicationFootnote) => applicationFootnote?.split("\n").join("</br>"));
      datapoint.relationToOtherStandards = datapoint.relationToOtherStandards?.split("\n").join("</br>");

      //Check if datapoint is mandatory
      if (datapoint.isMandatory) {
        datapoint.isMaterial = true;
      }

      datapoint.showMoreInfo = false;
      datapoint.isOmitted = false;
      datapoint.canOmit = false;
      datapoint.hasPhaseIn = false;
      datapoint.collectVoluntarily = false;
      datapoint.showComment = false;

      const { newEsrsSettings, newDisclosureSettings } = this.getNewTopicEsrsAndDisclosureSettings();
      this.drStatus = newDisclosureSettings.status;
      // Get from Esrs Settings first, then get from csrdProject Settings
      if (!datapoint.isMandatory) {
        datapoint.isMaterial = newEsrsSettings.isMaterial;
      }

      let newDatapointSettings: CSRDDataPointSettings;
      newDisclosureSettings.csrdDatapoints.forEach((datapointSettings) => {
        if (datapoint.id == datapointSettings.id) {
          newDatapointSettings = _.cloneDeep(datapointSettings);
        }
      });

      if (!_.isEmpty(newDatapointSettings)) {
        datapoint.isOmitted = newDatapointSettings.isOmitted;
        datapoint.isMaterial = newDatapointSettings.isMaterial;
        datapoint.collectVoluntarily = newDatapointSettings.collectVoluntarily;
        datapoint.comment = newDatapointSettings.comment;
      }

      //Calculate stats
      this.tickStats(datapoint);

      return datapoint;
    });

    this.updateActiveDR();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeDr) {
      this.updateActiveDR();
    }
  }

  private updateActiveDR() {
    if (this.activeDr) {
      this.drService.setActiveDR(this.activeDr.code, this.activeDr.name);
    }
  }

  startDrAssessment() {
    this.startAssessment.emit(true);
  }

  resumeDrAssessment() {
    /*
      Currently all "startAssessment" does is open the DR edit page.
      which is what we need, if the logic changes 
      implement a resumeDrAssessment function path
    */
    this.startAssessment.emit(true);
  }
  
  createRequests() {
    this.startDataRequest.emit(true)
  }

  resumeCreateRequest() {
    this.startDataRequest.emit(true)
  }

  getNewTopicEsrsAndDisclosureSettings(): {
    newTopicSettings: CSRDTopicSettings;
    newEsrsSettings: CSRDEsrsSettings;
    newDisclosureSettings: CSRDDisclosureSettings;
  } {
    let newTopicSettings: CSRDTopicSettings;
    this.activeCsrdProject.definition.csrdSettings.forEach((topicSettings) => {
      if (topicSettings.id == this.activeTopic.id) {
        newTopicSettings = _.cloneDeep(topicSettings);
      }
    });

    if (_.isEmpty(newTopicSettings)) {
      newTopicSettings = {
        id: this.activeTopic.id,
        esrs: []
      };
    }

    let newEsrsSettings: CSRDEsrsSettings;
    newTopicSettings.esrs.forEach((esrsSettings) => {
      if (esrsSettings.id == this.activeEsrs.id) {
        newEsrsSettings = _.cloneDeep(esrsSettings);
      }
    });

    if (_.isEmpty(newEsrsSettings)) {
      newEsrsSettings = {
        id: this.activeEsrs.id,
        isAssessed: false,
        isMaterial: false,
        isMandatory: !!this.activeEsrs.isMandatory,
        disclosures: [],
        selectedSubSubTopics: [],
        selectedSubTopics: []
      };
    }

    let newDisclosureSettings: CSRDDisclosureSettings;
    newEsrsSettings.disclosures.forEach((disclosureSettings) => {
      if (disclosureSettings.id == this.activeDr.id) {
        newDisclosureSettings = _.cloneDeep(disclosureSettings);
      }
    });

    if (_.isEmpty(newDisclosureSettings)) {
      newDisclosureSettings = {
        id: this.activeDr.id,
        status: CSRDDisclosureSettingsStatus.NOT_STARTED,
        hasDraft: false,
        isMandatory: !!this.activeDr.isMandatory,
        draft: {
          csrdDatapoints: []
        },
        questionnaire: [],
        csrdDatapoints: []
      };
    }

    return { newTopicSettings, newEsrsSettings, newDisclosureSettings };
  }

  updateNewTopicEsrsAndDisclosureSettings({
    newTopicSettings,
    newEsrsSettings,
    newDisclosureSettings
  }: {
    newTopicSettings: CSRDTopicSettings;
    newEsrsSettings: CSRDEsrsSettings;
    newDisclosureSettings: CSRDDisclosureSettings;
  }) {
    let existingDisclosureFound = false;
    newEsrsSettings.disclosures = newEsrsSettings.disclosures.map((disclosureSettings) => {
      if (disclosureSettings.id == newDisclosureSettings.id) {
        existingDisclosureFound = true;
        return newDisclosureSettings;
      }
      return disclosureSettings;
    });
    if (!existingDisclosureFound) {
      newEsrsSettings.disclosures.push(newDisclosureSettings);
    }

    let existingEsrsFound = false;
    newTopicSettings.esrs = newTopicSettings.esrs.map((esrsSettings) => {
      if (esrsSettings.id == newEsrsSettings.id) {
        existingEsrsFound = true;
        return newEsrsSettings;
      }
      return esrsSettings;
    });
    if (!existingEsrsFound) {
      newTopicSettings.esrs.push(newEsrsSettings);
    }

    let existingTopicFound = false;
    this.activeCsrdProject.definition.csrdSettings = this.activeCsrdProject.definition.csrdSettings.map((topicSettings) => {
      if (topicSettings.id == newTopicSettings.id) {
        existingTopicFound = true;
        return newTopicSettings;
      }
      return topicSettings;
    });
    if (!existingTopicFound) {
      this.activeCsrdProject.definition.csrdSettings.push(newTopicSettings);
    }
  }

  tickStats(datapoint) {
    if ((datapoint.isMaterial && !datapoint.isOmitted) || (!datapoint.isMaterial && datapoint.collectVoluntarily)) {
      this.stats.totalDatapoints += 1;
    }

    if (datapoint.isMaterial) {
      this.stats.materialDatapoints += 1;
    } else {
      this.stats.nonMaterialDatapoints += 1;
    }

    if (datapoint.isMaterial && datapoint.isOmitted) {
      this.stats.materialOmittedDatapoints += 1;
    }

    if (!datapoint.isMaterial && datapoint.collectVoluntarily) {
      this.stats.nonMaterialVoluntaryDatapoints += 1;
    }
  }
}

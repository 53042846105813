<div class="modal-header main-modal-header" style="border-bottom: none" *ngIf="showHeader">
  <h4 class="modal-title mb-0 pb-0 ms-2 mb-0">{{ requestTitle }} [{{ locale("locale_key.pages.data_request.action.blank") }}]</h4>
  <button type="button" class="btn close" (click)="closeModal()">
    <span aria-hidden="true"><i class="las la-times"></i></span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="loadingInfo">
    <div class="loader-wrapper mt-5">
      <div class="loader-container">
        <div class="ball-grid-pulse loader-primary">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!loadingInfo">
    <div class="row mb-0">
      <div class="col-12 mt-0 pt-0 mx-0 px-0" style="max-height: 70vh">
        <div class="m-0 p-0">
          <div class="scroll pt-0 px-3" style="max-height: 70vh; height: 70vh">
            <div class="row">
              <div class="d-flex flex-row justify-content-between align-items-center">
                <div class="my-auto w-100">
                 
                  <button class="btn tertiary-button ms-1 mt-1" (click)="backToMainQuestions()" *ngIf="selectedMainQuestion"><i class="la la-arrow-left me-1"></i>{{ slocale("Back to main questions") }}</button>
                </div>
               
              </div>
            </div>

            <div class="row text-end my-auto" *ngIf="!selectedMainQuestion && !isCSRDProjectPage">
              <a class="p-1 cursor" (click)="editMapping = !editMapping">
                <span class="ms-auto p-1 border me-2">
                  <i class="las la-sitemap py-1 size-15 my-auto" style="position: relative; top: 3px; transform: rotate(-90deg)"></i>
                  <span class="text-muted my-auto cursor"> {{ !editMapping ? locale("locale_key.modal.questionnaire_preview.show_mapping_info"): slocale('Hide mapping info') }}</span>
                </span>
              </a>
            </div>
            <!-- First page showing main questions -->
            <div class="accordion mt-2" id="sectionParentPreview" *ngIf="!selectedMainQuestion">
              <div class="accordion-item" *ngFor="let section of sections; let sectionIndex = index">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button px-0 pe-3 bg-white"
                    type="button"
                    data-bs-toggle="collapse"
                    attr.data-bs-target="#sectionAccordion{{ sectionIndex }}Preview"
                    aria-expanded="true"
                    attr.aria-controls="sectionAccordion{{ sectionIndex }}Preview"
                    id="section{{ sectionIndex }}Preview"
                  >
                    <div class="d-flex flex-row my-auto p-2"  *ngIf="!isCSRDProjectPage">
                        <h5 class="my-auto ms-2 d-inline me-4" style="min-width: fit-content !important;">{{ locale("locale_key.modal.questionnaire_preview.section_number", { sectionNumber: sectionIndex + 1 }) }}</h5>
                      <ng-container *ngIf="editMapping" class="d-inline m-auto">
                        <span class="badge badge-info w-auto py-auto my-auto px-2" *ngIf="section.entity" style="min-width: fit-content !important;"> {{ section.entity.getTaxonomyTitle(taxonomyInfo) }} </span>
                        <!-- <span class="badge badge-info w-auto py-auto my-auto px-2" *ngIf="section.name" style="min-width: fit-content !important;"> {{ section.name }} </span> -->
                        <button class="dropdown-item ms-0 ps-1" type="button" *ngIf="!section.taxonomyKey" (click)="mappingEdited = true; section.editTaxonomy = true">
                          <i class="las la-edit ms-2" style="position: relative; top: 1px"></i>
                        </button>
                      </ng-container>
                    </div>
                    <div class="row g-0 w-100" *ngIf="isCSRDProjectPage">
                      <div class="col-lg-9 col-md-12 mb-2 mb-lg-0">
                        <div class="d-flex align-items-center h-100">
                          <h5 class="mb-0 ms-2 text-nowrap">{{ locale("locale_key.wizard.create_questionnaire.section.title", { sectionNumber: sectionIndex + 1 })}}</h5>                    
                          <!-- Edit Mode -->
                            <!-- <ng-container *ngIf="isEditMode[sectionIndex]; else viewMode">
                              <input type="text" [(ngModel)]="section.taxonomyKey" class="form-control ms-2 bg-comp-grey text-dark" style=" display: inline-block;" (input)="updateInputWidth($event)" id="large-input"/>
                              <i class="la la-undo ms-2 text-dark" (click)="cancelEditTitle(sectionIndex)" style="cursor: pointer;position:relative;left:-30px;z-index: 1000;" *ngIf="screenSize.isLargeSize()|| screenSize.isXLargeSize()"></i>
                              <i class="la la-check ms-2 success-text" 
                                (click)="saveTitle(sectionIndex)" 
                                style="cursor: pointer;" 
                                [ngStyle]="screenSize.isLargeSize() || screenSize.isXLargeSize() ? { 'position': 'relative', 'left': '-20px' } : {}">
                              </i>

                              <i class="la la-times ms-2 danger" 
                                (click)="cancelEditTitle(sectionIndex)" 
                                style="cursor: pointer;" 
                                [ngStyle]="screenSize.isLargeSize() || screenSize.isXLargeSize() ? { 'position': 'relative', 'left': '-20px' } : {}">
                              </i>
                            </ng-container> -->
                            

                            <!-- View Mode -->
                            <ng-template #viewMode> 
                              <span class="dark-text ms-2 text-wrap">{{ section.taxonomyKey }}</span>
                              <span (click)="toggleEditMode(sectionIndex)">
                                <i class="la la-edit ms-2" style=" cursor: pointer;"></i>
                              </span>
                            </ng-template>
                        </div>
                      </div>
                      <!-- <div class="col-lg-3 col-md-12">
                        <div class="d-flex flex-row justify-content-between align-items-center h-100">
                          <div class="form-check text-nowrap">
                            <input type="checkbox" class="form-check-input" id="proofDocCheck{{sectionIndex}}" style="position: relative; top: -2px"/>
                            <label class="form-check-label dark-text text-wrap ms-1" for="proofDocCheck{{sectionIndex}}">
                              {{ slocale("Require proof documents for all questions") }}
                            </label>
                          </div>
                          <span (click)="stopImmediatePropagation($event)">
                            <div class="btn-group" (click)="stopImmediatePropagation($event)">
                              <div class="description-btn d-flex justify-content-end remove-arrow-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="la la-ellipsis-v size-15 mt-auto my-auto" (click)="stopImmediatePropagation($event)"></i>
                              </div>
                              <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                                <button class="dropdown-item ms-0 ps-1" type="button" (click)="section.editTaxonomy = true"><i class="las la-edit mr-25" style="position: relative; top: 1px"></i> {{ locale("locale_key.general.button.edit") }}</button>
                                <button class="dropdown-item ms-0 ps-1" type="button" (click)="duplicateSection(section, $event)"><i class="las la-copy mr-25" style="position: relative; top: 1px"></i> {{ locale("locale_key.general.buttons.duplicate") }}</button>
                                <button class="dropdown-item ms-0 ps-1" type="button" (click)="deleteSection(section, $event)"><i class="la la-trash-o"></i> {{ locale("locale_key.pages.kpi_library.delete_kpi_modal.button.delete") }}</button>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div> -->
                    </div>
                  </button>
                </h2>
                <div id="sectionAccordion{{ sectionIndex }}Preview" class="accordion-collapse collapse show" data-bs-parent="#sectionParent">
                  <div class="accordion-body bg-comp-grey p-3">
                    <ng-container *ngIf="!isCSRDProjectPage">
                      <div class="d-flex w-100 flex-column bg-white">
                        <div class="row-design mx-2 border p-2" *ngIf="section.editTaxonomy">
                          <h6>
                            {{ locale("locale_key.modal.questionnaire_preview.store_answers_with_data_categories") }}?
                            <i class="la la-info-circle dropdown-toggle ms-2 info-icon" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                            <div class="dropdown-menu arrow p-2 info-popover">
                              <p>{{ locale("locale_key.modal.questionnaire_preview.create_table_confirmation") }}</p>
                            </div>
                          </h6>
                          <div class="d-flex flex-row px-1 mt-1">
                            <button class="btn tertiary-button custom-button me-2" (click)="section.initializeTaxonomy()" [ngClass]="{ 'btn-pink': section.isTaxonomyConnected }">{{ locale("locale_key.general.buttons.yes") }}</button>
                            <button class="btn tertiary-button custom-button" (click)="section.intializeNameControl()" [ngClass]="{ 'btn-pink': !section.isTaxonomyConnected }">{{ locale("locale_key.general.buttons.no") }}</button>
                          </div>
                          <ng-container *ngIf="section.isTaxonomyConnected">
                            <p class="pt-3 pb-0 mb-0">{{ locale("locale_key.modal.questionnaire_preview.related_category_question") }}</p>
                            <div class="w-100" [ngClass]="{ 'pe-3': screenSize.isXLargeSize() || screenSize.isLargeSize() }">
                              <category-selection [entityKey]="section.taxonomyKey" [editedTaxonomy]="true" (entitySelected)="section.setTaxonomy($event, taxonomyInfo)"></category-selection>
                            </div>
                            <div class="d-flex text-end">
                              <button class="btn primary-button custom-button me-2 ms-auto" (click)="section.editTaxonomy = false">
                                {{ locale("locale_key.general.buttons.confirm") }}
                              </button>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="!section.isTaxonomyConnected">
                            <p class="pt-3 pb-0 mb-0">{{ locale("locale_key.modal.questionnaire_preview.section_name_instruction") }}</p>
                            <input class="w-50" [formControl]="section.nameControl" />
                            <div class="d-flex text-end">
                              <button class="btn primary-button custom-button me-2 ms-auto" (click)="section.name = section.nameControl.value; section.editTaxonomy = false">
                                {{ locale("locale_key.general.buttons.confirm") }}
                              </button>
                            </div>
                          </ng-container>
                        </div>
                      
                        <div class="m-2" *ngFor="let question of getFilteredQuestionsForSection(section); let i = index">
                          <div class="row ps-2">
                            <div class="col-7 px-0 w-100">
                              <div class="d-flex flex-column flex-lg-row justify-content-between">
                                <div class="text-wrap px-2 w-75">
                                  <h5 class="mt-2">{{ questionsRefNumber.get(question.id) }} {{ question.question }}</h5>
                                  <small class="text-muted multiline">{{ question.desc }}</small>
                                </div>
                                <div class="d-flex flex-row justify-content-start justify-content-lg-end px-2">
                                  <a class="btn my-2 me-2 p-2 tertiary-button mb-auto" *ngIf="question.hasFollowUpQuestion" (click)="selectMainQuestion(section, question)">
                                    {{ slocale("Go to follow up questions") }}
                                  </a>
                                </div>
                              </div>
                              <span class="col-6" style="font-size: 12px; text-align: end" *ngIf="editMapping && section.taxonomyKey && !question.editMapping && !requestService.isAttachmentQues(question)">
                                <ng-container *ngIf="question.isMapped && question.mappedToCols">
                                  <span class="text-muted small">{{ locale("locale_key.modal.questionnaire_preview.data_added_to") }}</span>
                                  <span class="badge badge-info w-auto py-auto my-auto"> {{ section.entity.getTaxonomyTitle(taxonomyInfo) }} </span>
                                  <span class="badge badge-info w-auto py-auto my-auto">
                                    <ng-container *ngFor="let mappedToCol of question.mappedToCols; let firstCol = first"> <span *ngIf="!firstCol">/</span>{{ mappedToCol?.getLabel() }}</ng-container>
                                  </span>
                                </ng-container>
                                <ng-container *ngIf="question.isMapped && question.range && question.mappedToColsRange">
                                  <span class="badge badge-info w-auto py-auto my-auto">
                                    <ng-container *ngFor="let mappedToCol of question.mappedToColsRange; let firstCol = first"> <span *ngIf="!firstCol">/</span>{{ mappedToCol?.getLabel() }}</ng-container>
                                  </span>
                                </ng-container>
                                <span class="text-muted small" *ngIf="section.taxonomyKey && !question.isMapped">{{ locale("locale_key.modal.questionnaire_preview.not_added_to_data_categories") }}</span>
                                <a (click)="mappingEdited = true; question.setEditMapping()" *ngIf="section.taxonomyKey && !question.isMapped"><i class="la la-edit size-15 my-auto" style="position: relative; top: 2px"></i></a>
                              </span>
                            </div>
                            <ng-container *ngIf="question.editMapping">
                              <div class="row-design border p-2" *ngIf="section.taxonomyKey && !requestService.isDataTableQues(question)">
                                <h6>
                                  {{ locale("locale_key.modal.questionnaire_preview.which_data_points_in") }}
                                  <span class="badge badge-info w-auto py-auto my-auto"> {{ section.entity.getTaxonomyTitle(taxonomyInfo) }}</span>
                                  {{ locale("locale_key.modal.questionnaire_preview.collect_with_this_question") }}
                                </h6>
                                <div class="d-flex flex-row mt-1">
                                  <div class="flex-grow-1 d-flex">
                                    <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        [checked]="!question.isMapped"
                                        (click)="requestService.toggleQuesMapping($event, question)"
                                        id="sec{{ sectionIndex }}ques{{ i }}"
                                        style="position: relative; top: 1px"
                                      />
                                      <label class="form-check-label" for="sec{{ sectionIndex }}ques{{ i }}">
                                        {{ locale("locale_key.modal.questionnaire_preview.exclude_from_mapping") }}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div class="mt-2" *ngIf="question.isMapped">
                                  <div class="card columnCard me-3 mb-2" *ngFor="let c of requestService.findColumnsByType(question, section)" [ngClass]="{ colSelected: question.isFieldSelected(c) }" (click)="question.toggleField(c)">
                                    <div class="row px-3">
                                      <span class="p-0 col-10 label" [tooltip]="c.getLabel()" placement="right" container="body" delay="500">{{ c.getLabel() }}</span>
                                      <span class="col-2 p-0" *ngIf="question.isFieldSelected(c)">
                                        <i class="la la-check fieldSelected"></i>
                                      </span>
                                    </div>
                                    <div class="desc" [tooltip]="c.getDescription()" placement="right" container="body" delay="500">{{ c.getDescription() }}</div>
                                    <span
                                      >{{ locale("locale_key.pages.kpi_library.data_type") }}: <b>{{ c.datatype | titlecase }}</b></span
                                    >
                                    <div class="newTag" *ngIf="c.isNew">{{ locale("locale_key.modal.questionnaire_preview.new") }}</div>
                                  </div>
                                  <ng-container *ngIf="question.range">
                                    <div>{{ locale("locale_key.wizard.create_questionnaire.map_second_data_input") }}</div>
                                    <div class="card columnCard me-3 mb-2" *ngFor="let c of requestService.findColumnsByType(question, section)" [ngClass]="{ colSelected: question.isRangeFieldSelected(c) }" (click)="question.toggleRangeField(c)">
                                      <div class="row px-3">
                                        <span class="p-0 col-10 label" [tooltip]="c.getLabel()" placement="right" container="body" delay="500">{{ c.getLabel() }}</span>
                                        <span class="col-2 p-0" *ngIf="question.isRangeFieldSelected(c)">
                                          <i class="la la-check fieldSelected"></i>
                                        </span>
                                      </div>
                                      <div class="desc" [tooltip]="c.getDescription()" placement="right" container="body" delay="500">{{ c.getDescription() }}</div>
                                      <span
                                        >{{ locale("locale_key.pages.kpi_library.data_type") }}: <b>{{ c.datatype | titlecase }}</b></span
                                      >
                                      <div class="newTag" *ngIf="c.isNew">{{ locale("locale_key.wizard.create_questionnaire.new") }}</div>
                                    </div>
                                  </ng-container>
                                </div>
                                <div class="d-flex justify-content-end">
                                  <button
                                    class="btn primary-button custom-button me-2"
                                    [disabled]="question.isMapped ? question.mappedToColKeys.length == 0 && (question.range ? question.mappedToColKeysRange.length == 0 : true) : false"
                                    (click)="question.confirmMapping()"
                                  >
                                    {{ locale("locale_key.general.buttons.confirm") }}
                                  </button>
                                </div>
                              </div>
                              <div class="row-design border p-2" *ngIf="requestService.isDataTableQues(question)">
                                <h6 *ngIf="section.taxonomyKey">
                                  {{ locale("locale_key.modal.questionnaire_preview.which_data_points_in") }}
                                  <span class="badge badge-info w-auto py-auto my-auto"> {{ section.entity.getTaxonomyTitle(taxonomyInfo) }}</span>
                                  {{ locale("locale_key.modal.questionnaire_preview.collect_with_this_question") }}
                                </h6>
                                <div class="row">
                                  <div class="col-7 me-0 pe-1">
                                    <div class="col-12 px-1" *ngIf="question.isMapped">
                                      <div class="card columnCard me-3 mb-2" *ngFor="let c of requestService.findColumnsByType(question, section)" [ngClass]="{ colSelected: question.isFieldSelected(c) }" (click)="question.toggleField(c, question)">
                                        <div class="row px-3">
                                          <span class="p-0 col-10 label" [tooltip]="c.getLabel()" placement="right" container="body" delay="500">{{ c.getLabel() }}</span>
                                          <span class="col-2 p-0" *ngIf="question.isFieldSelected(c)">
                                            <i class="la la-check fieldSelected"></i>
                                          </span>
                                        </div>
                                        <div class="desc" [tooltip]="c.getDescription()" placement="right" container="body" delay="500">{{ c.getDescription() }}</div>
                                        <span
                                          >{{ locale("locale_key.pages.kpi_library.data_type") }}: <b>{{ locale("locale_key.general.data_type.number") }}</b></span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-5 ms-0 ps-1">
                                    <div class="row my-5 px-0 mx-0">
                                      <div class="col-1 mx-0 px-0 m-auto">
                                        <span class="mx-0 px-0"><i class="la la-caret-right"></i></span>
                                      </div>
                                      <div class="col-11 mx-0 px-0">
                                        <datagrid-table-editor [dataGrid]="question.requestItemDataGridService"></datagrid-table-editor>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="d-flex justify-content-end">
                                  <button class="btn primary-button custom-button me-2" [disabled]="question.isMapped ? question.mappedToColKeys.length == 0 : false" (click)="question.confirmMapping()">
                                    {{ locale("locale_key.general.buttons.confirm") }}
                                  </button>
                                </div>
                              </div>
                            </ng-container>
                            <div class="d-flex flex-column justify-content-start ps-3 mb-2">
                              <div class="col mt-1 mx-0 px-0" *ngIf="requestService.isTextQues(question)">
                                <textarea class="form-control w-50" rows="3" placeholder="Write answer" disabled> </textarea>
                              </div>
                              <div class="row w-50" *ngIf="requestService.isEmissionFactorQues(question)">
                                <div class="w-50 ms-0 me-4">
                                  <emission-factor isDisabled="true"></emission-factor>
                                </div>
                              </div>
                              <div class="row w-50" *ngIf="requestService.isDateQues(question)">
                                <div class="col-12">
                                  <input type="date" class="form-control" placeholder="12/12/2022" disabled />
                                </div>
                                <ng-container *ngIf="question['range']">
                                  <div class="col-12 ml-1">
                                    <p class="mb-2">{{ locale("locale_key.pages.task_portal.answer_wizard.date.options.range.to") }}</p>
                                  </div>
                                  <div class="col-12">
                                    <input type="date" class="form-control" placeholder="12/12/2022" disabled />
                                  </div>
                                </ng-container>
                              </div>
                              <div class="row w-50" *ngIf="requestService.isNumberQues(question)">
                                <div class="col-6 ms-0 ps-3">
                                  <input type="text" class="form-control" [placeholder]="locale('locale_key.pages.data_request.create.question.value.placeholder')" disabled />
                                </div>
                                <div class="col-6 ms-0 ps-3">
                                  <p>{{question.unit}}</p>
                                </div>
                              </div>
                              <div class="row w-50" *ngIf="requestService.isAttachmentQues(question)">
                                <div class="col-12 m-1 ps-3">
                                  <button class="btn primary-button" disabled>{{ locale("locale_key.pages.data_request.review_answer_wizard.file.button.select_file") }}</button>
                                </div>
                              </div>
                              <div class="row" *ngIf="requestService.isDataTableQues(question)">
                                <div class="col-12 ms-0 ps-3">
                                  <datagrid-table-editor [dataGrid]="question.requestItemDataGridService"></datagrid-table-editor>
                                </div>
                                <div class="col-12 m-1 ps-3">
                                  <button class="btn primary-button" disabled>{{ locale("locale_key.general.buttons.extract_from_file") }}</button>
                                </div>
                              </div>
                              <div *ngIf="requestService.isMultipleChoiceQues(question)">
                                <div class="row me-auto mx-1" *ngFor="let option of question['options']">
                                  <a id="btnMenu-11" class="dropdown" (click)="option.showDesc = !option.showDesc">
                                    <input type="checkbox" class="me-3 d-inline mp-50" style="position: relative; bottom: 1px" disabled />
                                    <p class="d-inline">{{ option.value }}</p>
                                    <i class="ms-2 la la-angle-down d-inline" id="chev-1" *ngIf="option.desc && !option.showDesc"></i>
                                    <i class="ms-2 la la-angle-up d-inline" id="chev-1" *ngIf="option.desc && option.showDesc"></i>
                                  </a>
                                  <div id="menu-1" class="dropdown-menu position-relative dropdown-demo px-1" [ngClass]="{ 'show mb-2': option.showDesc }">
                                    {{ option.desc }}
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="requestService.isSingleChoiceQues(question)">
                                <div class="row me-auto mx-1" *ngFor="let option of question['options']">
                                  <a id="btnMenu-11" class="dropdown" (click)="option.showDesc = !option.showDesc">
                                    <input type="radio" class="me-3 d-inline mp-50" style="position: relative; bottom: 1px" [value]="option.id" disabled />
                                    <p class="d-inline">{{ option.value }}</p>
                                    <i class="ms-2 la la-angle-down d-inline" id="chev-1" *ngIf="option.desc && !option.showDesc"></i>
                                    <i class="ms-2 la la-angle-up d-inline" id="chev-1" *ngIf="option.desc && option.showDesc"></i>
                                  </a>
                                  <div id="menu-1" class="dropdown-menu position-relative dropdown-demo px-1" [ngClass]="{ 'show mb-2': option.showDesc }">
                                    {{ option.desc }}
                                  </div>
                                </div>
                              </div>
                              <div class="col mt-1 ms-0 ps-0" *ngIf="question.comments">
                                <label class="mt-1">{{ locale("locale_key.modal.questionnaire_preview.comments") }}</label>
                                <textarea class="form-control w-50" rows="1" disabled></textarea>
                              </div>
                            </div>
                            <hr class="mx-0 px-0 w-100"  style="position: relative;left:-6px;"/>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isCSRDProjectPage">
                      <div class="d-flex w-100 flex-column bg-white" >
                        <div class="row-design mx-2 border p-2" *ngIf="section.editTaxonomy">
                          <h6>
                            {{ locale("locale_key.modal.questionnaire_preview.store_answers_with_data_categories") }}?
                            <i class="la la-info-circle dropdown-toggle ms-2 info-icon" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                            <div class="dropdown-menu arrow p-2 info-popover">
                              <p>{{ locale("locale_key.modal.questionnaire_preview.create_table_confirmation") }}</p>
                            </div>
                          </h6>
                          <div class="d-flex flex-row px-1 mt-1">
                            <button class="btn tertiary-button custom-button me-2" (click)="section.initializeTaxonomy()" [ngClass]="{ 'btn-pink': section.isTaxonomyConnected }">{{ locale("locale_key.general.buttons.yes") }}</button>
                            <button class="btn tertiary-button custom-button" (click)="section.intializeNameControl()" [ngClass]="{ 'btn-pink': !section.isTaxonomyConnected }">{{ locale("locale_key.general.buttons.no") }}</button>
                          </div>
                          <ng-container *ngIf="section.isTaxonomyConnected">
                            <p class="pt-3 pb-0 mb-0">{{ locale("locale_key.modal.questionnaire_preview.related_category_question") }}</p>
                            <div class="w-100" [ngClass]="{ 'pe-3': screenSize.isXLargeSize() || screenSize.isLargeSize() }">
                              <category-selection [entityKey]="section.taxonomyKey" [editedTaxonomy]="true" (entitySelected)="section.setTaxonomy($event, taxonomyInfo)"></category-selection>
                            </div>
                            <div class="d-flex text-end">
                              <button class="btn primary-button custom-button me-2 ms-auto" (click)="section.editTaxonomy = false">
                                {{ locale("locale_key.general.buttons.confirm") }}
                              </button>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="!section.isTaxonomyConnected">
                            <p class="pt-3 pb-0 mb-0">{{ locale("locale_key.modal.questionnaire_preview.section_name_instruction") }}</p>
                            <input class="w-50" [formControl]="section.nameControl" />
                            <div class="d-flex text-end">
                              <button class="btn primary-button custom-button me-2 ms-auto" (click)="section.name = section.nameControl.value; section.editTaxonomy = false">
                                {{ locale("locale_key.general.buttons.confirm") }}
                              </button>
                            </div>
                          </ng-container>
                        </div>
                        <div class="accordion" id="section{{sectionIndex}}Questions">
                          <div class="accordion-item" *ngFor="let question of getFilteredQuestionsForSection(section); let i = index">
                            <h2 class="accordion-header">
                              <button
                                class="accordion-button px-0 pe-3 bg-white"
                                type="button"
                                data-bs-toggle="collapse"
                                [attr.data-bs-target]="'#questionAccordion' + i"
                                aria-expanded="false"
                                [attr.aria-controls]="'questionAccordion' + i"
                                [id]="'question' + i"
                              >
                                <div class="row g-0 w-100">
                                  <div class="col-lg-9 col-md-12 mb-2 mb-lg-0">
                                    <div class="d-flex align-items-center h-100">
                                      <h5 class="mb-0 ms-3">{{ questionsRefNumber.get(question.id) }}. {{ question.question }}</h5>
                                      <span class="d-flex flex-column flex-lg-row">
                                        <span
                                          class="badge badge-black-border w-auto py-auto my-auto ms-2 non-collapsing text-nowrap"
                                          type="button"
                                          role="button"
                                          (click)="toggleCSRDInfo(question);"
                                          data-bs-toggle="collapse" data-bs-target
                                        >
                                          {{ csrdDefintionService.getCsrdDatapointById(question.csrdDatapointId).LegislationLocation }}
                                        </span>
                                        <span
                                          class=" w-auto py-auto my-auto ms-2 non-collapsing text-nowrap text-center my-md-1 my-sm-1"
                                          [ngClass]="csrdDefintionService.getCsrdDatapointSettings(question.csrdDatapointId)?.isMaterial ? 'badge-material material' : 'badge-not-material'"                                  
                                          data-bs-toggle="collapse" data-bs-target
                                          *ngIf="csrdDefintionService.getCsrdDatapointSettings(question.csrdDatapointId)?.isMaterial || !csrdDefintionService.getCsrdDatapointSettings(question.csrdDatapointId)?.isMaterial"
                                        >
                                        {{csrdDefintionService.getCsrdDatapointSettings(question.csrdDatapointId)?.isMaterial ? 'Material' : 'Not Material'}}
                                        </span>
                                        <span
                                          class="w-auto py-auto my-auto ms-2 non-collapsing text-nowrap badge-material material text-center my-md-1 my-sm-1 my-xs-1"
                                          *ngIf="csrdDefintionService.getCsrdDatapointSettings(question.csrdDatapointId)?.collectVoluntarily"
                                        >
                                        Voluntary
                                        </span> 
                                        <span
                                        class="w-auto py-auto my-auto ms-2 non-collapsing text-nowrap badge-not-material text-center my-md-1 my-sm-1"  
                                        *ngIf="csrdDefintionService.getCsrdDatapointSettings(question.csrdDatapointId)?.isOmitted"                                
                                      >
                                        Omitted
                                        </span> 
                                      </span>                                   
                                    </div>
                                  </div>
                            
                                  <div class="col-lg-3 col-md-12 d-flex flex-column justify-content-between align-items-center h-100">
                                    <a
                                      class="btn my-2 me-2 px-2 py-1 tertiary-button mb-auto"
                                      *ngIf="question.hasFollowUpQuestion"
                                      (click)="selectMainQuestion(section, question); $event.stopPropagation()"
                                    >
                                      {{ slocale("Go to follow up questions") }}
                                    </a>
                            
                                    <!-- <div class="form-check text-nowrap" (click)="$event.stopPropagation()"> 
                                      <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="proofDocCheck{{i}}"
                                        aria-label="Require proof document"
                                        (click)="$event.stopPropagation()"
                                        style="position: relative; top: -1px"
                                      />
                                      <label class="form-check-label dark-text text-wrap ms-1" for="proofDocCheck{{i}}">
                                        {{ slocale("Require proof document") }}
                                      </label>
                            
                                      <i
                                        class="la la-info-circle dropdown-toggle info-popover-arrow fs-5 ms-2"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false" 
                                        role="button"
                                        style="position: relative; top: 2px"
                                        (click)="$event.stopPropagation()"
                                      ></i>
                            
                                      <div class="dropdown-menu arrow p-2 info-popover text-wrap" style="max-width: 250px !important;">
                                        <p>{{ slocale("Enabling this option will prompt data reporters to provide additional documents to validate and ensure the accuracy of reported data.") }}</p>
                                      </div>
                                    </div> -->
                            
                                    <!-- <div class="form-group text-end col" (click)="$event.stopPropagation()">
                                      <label class="mini-switch text-end" style="position: relative; top: 2px; left: -5px">
                                        <input
                                          type="checkbox"
                                          [(ngModel)]="question.comments"
                                          (click)="$event.stopPropagation()"
                                        />
                                        <span class="mini-slider round"></span>
                                      </label>
                                      <label class="text-end me-2 dark-text">{{ slocale("Additional explanation") }}</label>
                            
                                      <i
                                        class="la la-info-circle dropdown-toggle info-popover-arrow fs-5"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        role="button"
                                        style="position: relative; top: 2px"
                                        (click)="$event.stopPropagation()"
                                      ></i>
                            
                                      <div class="dropdown-menu arrow p-2 info-popover text-wrap" style="max-width: 250px !important;">
                                        <p>{{ slocale("In addition to legislative texts, you can provide your own guidance to help data reporters in submitting accurate data for ESRS data point.") }}</p>
                                      </div>
                                    </div> -->
                                  </div>
                                </div>
                              </button>
                            </h2>
                            
                            <div [id]="'questionAccordion' + i" class="accordion-collapse collapse show" [attr.data-bs-parent]="'#section' + i + 'Questions'">
                              <div class="accordion-body p-3">
                                <div class="d-flex w-100 flex-column bg-white">
                                  <p>
                                    <a class="italic dark-text text-muted arrow ms-3 " type="button" data-bs-toggle="collapse" data-bs-target="#collapseWidthExample" aria-expanded="false" aria-controls="collapseWidthExample">
                                    {{slocale("Description")}} <i class="la la-angle-down"></i>
                                    </a>
                                  </p>
                                  <div >
                                    <div class="collapse collapse-horizontal ms-3 mb-3" id="collapseWidthExample">
                                      <div class="card card-body" style="width: fit-content;min-width: 300px;">
                                        <small class="text-muted multiline">{{ question.desc }}</small>
                                      </div>
                                    </div>
                                  </div>
                                
                                  <ng-container *ngIf="question.editMapping">
                                    <div class="row-design border p-2" *ngIf="section.taxonomyKey && !requestService.isDataTableQues(question)">
                                      <h6>
                                        {{ locale("locale_key.modal.questionnaire_preview.which_data_points_in") }}
                                        <span class="badge badge-info w-auto py-auto my-auto"> {{ section.entity.getTaxonomyTitle(taxonomyInfo) }}</span>
                                        {{ locale("locale_key.modal.questionnaire_preview.collect_with_this_question") }}
                                      </h6>
                                      <div class="d-flex flex-row mt-1">
                                        <div class="flex-grow-1 d-flex">
                                          <div class="form-check">
                                            <input
                                              class="form-check-input"
                                              type="checkbox"
                                              [checked]="!question.isMapped"
                                              (click)="requestService.toggleQuesMapping($event, question)"
                                              id="sec{{ sectionIndex }}ques{{ i }}"
                                              style="position: relative; top: 1px"
                                            />
                                            <label class="form-check-label" for="sec{{ sectionIndex }}ques{{ i }}">
                                              {{ locale("locale_key.modal.questionnaire_preview.exclude_from_mapping") }}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="mt-2" *ngIf="question.isMapped">
                                        <div class="card columnCard me-3 mb-2" *ngFor="let c of requestService.findColumnsByType(question, section)" [ngClass]="{ colSelected: question.isFieldSelected(c) }" (click)="question.toggleField(c)">
                                          <div class="row px-3">
                                            <span class="p-0 col-10 label" [tooltip]="c.getLabel()" placement="right" container="body" delay="500">{{ c.getLabel() }}</span>
                                            <span class="col-2 p-0" *ngIf="question.isFieldSelected(c)">
                                              <i class="la la-check fieldSelected"></i>
                                            </span>
                                          </div>
                                          <div class="desc" [tooltip]="c.getDescription()" placement="right" container="body" delay="500">{{ c.getDescription() }}</div>
                                          <span>{{ locale("locale_key.pages.kpi_library.data_type") }}: <b>{{ c.datatype | titlecase }}</b></span>
                                          <div class="newTag" *ngIf="c.isNew">{{ locale("locale_key.modal.questionnaire_preview.new") }}</div>
                                        </div>
                                        <ng-container *ngIf="question.range">
                                          <div>{{ locale("locale_key.wizard.create_questionnaire.map_second_data_input") }}</div>
                                          <div class="card columnCard me-3 mb-2" *ngFor="let c of requestService.findColumnsByType(question, section)" [ngClass]="{ colSelected: question.isRangeFieldSelected(c) }" (click)="question.toggleRangeField(c)">
                                            <div class="row px-3">
                                              <span class="p-0 col-10 label" [tooltip]="c.getLabel()" placement="right" container="body" delay="500">{{ c.getLabel() }}</span>
                                              <span class="col-2 p-0" *ngIf="question.isRangeFieldSelected(c)">
                                                <i class="la la-check fieldSelected"></i>
                                              </span>
                                            </div>
                                            <div class="desc" [tooltip]="c.getDescription()" placement="right" container="body" delay="500">{{ c.getDescription() }}</div>
                                            <span>{{ locale("locale_key.pages.kpi_library.data_type") }}: <b>{{ c.datatype | titlecase }}</b></span>
                                            <div class="newTag" *ngIf="c.isNew">{{ locale("locale_key.wizard.create_questionnaire.new") }}</div>
                                          </div>
                                        </ng-container>
                                      </div>
                                      <div class="d-flex justify-content-end">
                                        <button
                                          class="btn primary-button custom-button me-2"
                                          [disabled]="question.isMapped ? question.mappedToColKeys.length == 0 && (question.range ? question.mappedToColKeysRange.length == 0 : true) : false"
                                          (click)="question.confirmMapping()"
                                        >
                                          {{ locale("locale_key.general.buttons.confirm") }}
                                        </button>
                                      </div>
                                    </div>
                                  </ng-container>
                                  <div class="d-flex flex-column justify-content-start ps-3 mb-2">
                                    <div class="col mt-1 mx-0 px-0" *ngIf="requestService.isTextQues(question)">
                                      <textarea class="form-control w-50" rows="3" placeholder="Write answer" disabled> </textarea>
                                    </div>
                                    <div class="row w-50" *ngIf="requestService.isEmissionFactorQues(question)">
                                      <div class="w-50 ms-0 me-4">
                                        <emission-factor isDisabled="true"></emission-factor>
                                      </div>
                                    </div>
                                    <div class="row w-50" *ngIf="requestService.isDateQues(question)">
                                      <div class="col-12">
                                        <input type="date" class="form-control" placeholder="12/12/2022" disabled />
                                      </div>
                                      <ng-container *ngIf="question['range']">
                                        <div class="col-12 ml-1">
                                          <p class="mb-2">{{ locale("locale_key.pages.task_portal.answer_wizard.date.options.range.to") }}</p>
                                        </div>
                                        <div class="col-12">
                                          <input type="date" class="form-control" placeholder="12/12/2022" disabled />
                                        </div>
                                      </ng-container>
                                    </div>
                                    <div class="row w-50" *ngIf="requestService.isNumberQues(question)">
                                      <div class="col-6 ms-0 ps-3">
                                        <input type="text" class="form-control" [placeholder]="locale('locale_key.pages.data_request.create.question.value.placeholder')" disabled />
                                      </div>
                                      <div class="col-6 ms-0 ps-3">
                                        <p>{{question.unit}}</p>
                                      </div>
                                    </div>
                                    <div class="row w-50" *ngIf="requestService.isAttachmentQues(question)">
                                      <div class="col-12 m-1 ps-3">
                                        <button class="btn primary-button" disabled>{{ locale("locale_key.pages.data_request.review_answer_wizard.file.button.select_file") }}</button>
                                      </div>
                                    </div>
                                    <div class="row" *ngIf="requestService.isDataTableQues(question)">
                                    </div>
                                    <div *ngIf="requestService.isMultipleChoiceQues(question)">
                                      <div class="row me-auto mx-1" *ngFor="let option of question['options']">
                                        <a id="btnMenu-11" class="dropdown" (click)="option.showDesc = !option.showDesc">
                                          <input type="checkbox" class="me-3 d-inline mp-50" style="position: relative; bottom: 1px" disabled />
                                          <p class="d-inline">{{ option.value }}</p>
                                          <i class="ms-2 la la-angle-down d-inline" id="chev-1" *ngIf="option.desc && !option.showDesc"></i>
                                          <i class="ms-2 la la-angle-up d-inline" id="chev-1" *ngIf="option.desc && option.showDesc"></i>
                                        </a>
                                        <div id="menu-1" class="dropdown-menu position-relative dropdown-demo px-1" [ngClass]="{ 'show mb-2': option.showDesc }">
                                          {{ option.desc }}
                                        </div>
                                      </div>
                                    </div>
                                    <div *ngIf="requestService.isSingleChoiceQues(question)">
                                      <div class="row me-auto mx-1" *ngFor="let option of question['options']">
                                        <a id="btnMenu-11" class="dropdown" (click)="option.showDesc = !option.showDesc">
                                          <input type="radio" class="me-3 d-inline mp-50" style="position: relative; bottom: 1px" [value]="option.id" disabled />
                                          <p class="d-inline">{{ option.value }}</p>
                                          <i class="ms-2 la la-angle-down d-inline" id="chev-1" *ngIf="option.desc && !option.showDesc"></i>
                                          <i class="ms-2 la la-angle-up d-inline" id="chev-1" *ngIf="option.desc && option.showDesc"></i>
                                        </a>
                                        <div id="menu-1" class="dropdown-menu position-relative dropdown-demo px-1" [ngClass]="{ 'show mb-2': option.showDesc }">
                                          {{ option.desc }}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col mt-1 ms-0 ps-0" *ngIf="question.comments">
                                      <label class="mt-1">{{ locale("locale_key.modal.questionnaire_preview.comments") }}</label>
                                      <textarea class="form-control w-50" rows="1" disabled></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <!-- Second page showing sub-tree of a selected main question  -->
            <div class="py-3 scroll-y" *ngIf="selectedMainQuestion">
              <div class="accordion" id="followUpAccordion">
                <ng-container>
                  
                  <ng-container *ngTemplateOutlet="accordionTemplate; context: { question: selectedMainQuestion, level: 0 }"></ng-container>
                  <ng-container *ngIf="subtreeExpanded[selectedMainQuestion.id]" > 
                    <ng-container *ngTemplateOutlet="undoIconTemplate; context: { level: 0 }" ></ng-container>
                  </ng-container>
            
                  
                  <ng-container *ngFor="let childOfMainQuestion of selectedSection.getChildrenOfParent(selectedMainQuestion); let i = index"  >
                    
                    <ng-container *ngIf="shown[childOfMainQuestion.id]">
                      <ng-container *ngTemplateOutlet="accordionTemplate; context: { question: childOfMainQuestion, level: 1 }" ></ng-container>
                    </ng-container>
                    
                    
                    <ng-container *ngIf="shown[childOfMainQuestion.id] && subtreeExpanded[childOfMainQuestion.id]">
                      <ng-container *ngTemplateOutlet="followUpQuestionTemplate; context: { parentQuestion: childOfMainQuestion, level: 1 }" ></ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
            
                
                <ng-template #followUpQuestionTemplate let-parentQuestion="parentQuestion" let-level="level">
                  <ng-container *ngTemplateOutlet="undoIconTemplate; context: { level:  level === 1? 1 : level  }"></ng-container>
                  <ng-container *ngFor="let childFollowUp of selectedSection.getChildrenOfParent(parentQuestion); let i = index">
                    
                    <ng-container *ngIf="shown[childFollowUp.id]">
                        <ng-container *ngTemplateOutlet="accordionTemplate; context: { question: childFollowUp, level: level + 1 }"></ng-container>
                    </ng-container>
                    
            
                    
                    <ng-container *ngIf="shown[childFollowUp.id] && subtreeExpanded[childFollowUp.id]" >
                      <ng-container *ngTemplateOutlet="followUpQuestionTemplate; context: { parentQuestion: childFollowUp, level: level + 1 }"></ng-container>
                    </ng-container>
                  </ng-container>
                </ng-template>
            
                
                <ng-template #accordionTemplate let-question="question" let-level="level">
                 
                  <div class="accordion-item mb-0 thin-border-t rounded" style="position:relative" [ngStyle]="{ 'margin-left': getIndentation(level) }" [id]="question.id" [ngClass]="level> 1 && hasFollowUpQuestions(question)  ? 'my-0':!hasFollowUpQuestions(question) && level <4? 'mt-2':'my-0'">
                    <h2 class="accordion-header" [id]="'headingFollowUp' + question.id">
                      <button
                        class="accordion-button collapsed p-2 bg-white"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#collapseFollowUp' + question.id"
                        [attr.aria-expanded]="detailExpanded[question.id]"
                        [attr.aria-controls]="'collapseFollowUp' + question.id"
                        (click)="toggleDetailExpansion(question)"
                      >
                        <div class="w-100">
                          <div class="d-flex justify-content-between">
                            <div class="text-wrap px-2 w-75">
                              <h5 class="mt-2 bold" *ngIf="question.followUpCondition">If the answer is ({{ selectedSection.ifAnswerIsStringForFollowup(question) }})</h5>
                              <h5 class="mt-2">{{ questionsRefNumber.get(question.id) }} {{ question.question }}</h5>
                            </div>
                            <div class="d-flex flex-row justify-content-start justify-content-lg-end px-2">
                              <button *ngIf="hasFollowUpQuestions(question)" class="btn my-auto me-2 p-2 tertiary-button mb-auto" (click)="toggleFollowUpQuestions(question)">
                                {{ subtreeExpanded[question.id] ? slocale('Hide follow up questions') : slocale('Show follow up questions') }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div [id]="'collapseFollowUp' + question.id" class="accordion-collapse collapse" [ngClass]="{ show: detailExpanded[question.id] }" [attr.aria-labelledby]="'headingFollowUp' + question.id" data-bs-parent="#followUpAccordion">
                      <div class="accordion-body p-3 mb-2">
                        <p class="text-muted">{{ question.desc }}</p>
                        <div [innerHTML]="renderQuestionOptions(question)"></div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                
                <ng-template #undoIconTemplate let-level="level">
                  <div [ngStyle]="{ 
                    'margin-left': level === 0 ? '0px' : getIndentation(level), 
                    'margin-top': '-5px', 
                    'margin-bottom': '-10px', 
                    'position': 'relative' 
                  }">
                    <i class="la la-undo" style="transform: rotate(180deg);"></i>
                  </div>
                </ng-template>
              </div>
            </div>             
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" *ngIf="showFooter">
    <p *ngIf="toConfirmUpdate">{{ locale("locale_key.modal.questionnaire_preview.confirm_edit_mappings") }}</p>
    <button class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
    <button *ngIf="!toConfirmUpdate && mappingEdited" class="btn primary-button custom-button" (click)="toConfirmUpdate = true">{{ locale("locale_key.modal.questionnaire_preview.save_mapping") }}</button>
    <button *ngIf="toConfirmUpdate" class="btn primary-button custom-button" (click)="updateMapping()">{{ locale("locale_key.general.buttons.confirm") }}</button>
  </div>
</div>
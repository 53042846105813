<div class="d-flex flex-column h-100">
    <!-- empty toolbar + page-header and 3dots menu columns-->
    <div class="d-flex flex-row">
        <!-- empty-->
        <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
        <!-- header + 3 dots-->
        <div class="flex-fill">
            <div class="d-flex flex-row justify-content-between">
                <nav>
                    <ul class="nav">
                        <li class="navbar-item">
                            <a>
                                <h3 class="mt-2">{{ slocale("PROJECTS") }}</h3>
                            </a>
                        </li>
                        <li class="navbar-item">
                            <h3 class="mt-2 mx-1"><i class="la la-angle-right font-medium-1"></i></h3>
                        </li>
                        <li class="navbar-item">
                            <a>
                                <h3 class="mt-2"> {{ activeCsrdProject.name }} </h3>
                            </a>
                        </li>
                        <li class="navbar-item">
                            <h3 class="mt-2 mx-1"><i class="la la-angle-right font-medium-1"></i></h3>
                        </li>
                        <li class="navbar-item">
                            <a>
                                <h3 class="mt-2"> {{ activeEsrs.code }} {{ activeEsrs.name }} </h3>
                            </a>
                        </li>
                    </ul>
                </nav>
                <!-- dropdown menu instead of toolbar for smaller pages-->
                <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
                    <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle ml-4 mt-" aria-controls="dropdown-alignment">
                        <i class="la la-ellipsis-h fs-2" style="rotate: 90deg"></i>
                    </button>
                    <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
                        <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
                            <div>
                                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                                    <li role="menuitem">
                                        <button type="button" class="btn" (click)="handleToolbarAction(toolbarButton.actionName)" *ngIf="toolbarButton.visible()">
                                            <i [class]="toolbarButton.icon"></i>
                                            <span>{{ toolbarButton.longLabel }}</span>
                                        </button>
                                    </li>
                                </ng-container>
                                <!-- <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li> -->
                            </div>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- toolbar + content columns -->
    <div class="flex-fill">
        <div class="d-flex flex-row h-100">
            <!-- toolbar column-->
            <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
                <ng-container *ngFor="let toolbarGroup of pageToolbar">
                    <div class="page-toolbar-button-group">
                        <ng-container *ngFor="let toolbarButton of toolbarGroup">
                            <button *ngIf="toolbarButton.visible()" type="button" class="btn page-toolbar-button" (click)="handleToolbarAction(toolbarButton.actionName)" data-bs-toggle="tooltip" data-placement="top"
                                [title]="toolbarButton.tooltip" show-delay="3000">
                                <i [class]="toolbarButton.icon" class="fs-4"></i>
                                <span>{{ toolbarButton.shortLabel }}</span>
                            </button>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
            <!-- content column-->
            <div class="flex-fill">
                <div class="d-flex h-100 flex-column drComponent">
                    <!-- HEADER -->
                    <div class="drTitle">
                        <div class="location">
                            {{ slocale("Disclosure Requirement") }}
                        </div>
                        <div class="label">
                            {{ activeDr.code }} - {{ activeDr.name }}
                        </div>
                    </div>

                    <!-- TABS -->
                    <div class="drTablist">
                        <div class="tab overviewTab" [class.active]="activeDrTab == DR_TABS.OVERVIEW" (click)="switchActiveDrTab({tab: DR_TABS.OVERVIEW})">
                            {{ slocale("Overview") }}
                        </div>
                        <div class="tab datapointsTab" [class.active]="activeDrTab == DR_TABS.DATAPOINTS" (click)="switchActiveDrTab({tab: DR_TABS.DATAPOINTS})">
                            {{ slocale("Data points") }}
                            <span class="text-muted" *ngIf="activeDr.hasDraft">{{slocale('(draft)')}}</span>
                        </div>
                        <!--[class.disabled]="drStatus == DR_STATUSES.NOT_STARTED || drStatus == DR_STATUSES.ASSESSING"-->
                        <div class="tab requestsTab" [class.active]="activeDrTab == DR_TABS.REQUESTS"  (click)="switchActiveDrTab({tab: DR_TABS.REQUESTS})" *ngIf="drService.hasQuestionnaire(activeDr.id)">
                            {{ slocale("Requests") }}
                        </div>
                        <!-- <div class="tab collectedDataTab disabled" [class.active]="activeDrTab == DR_TABS.COLLECTED_DATA" (click)="switchActiveDrTab({tab: DR_TABS.COLLECTED_DATA})">
                            {{ slocale("Collected data") }}
                        </div> -->
                    </div>

                    <!-- CONTENT -->
                    <div class="drTabContent" *ngIf="activeDrTab != DR_TABS.REQUESTS">
                        <!-- OVERVIEW -->
                        <csrd-dr-overview *ngIf="activeDrTab == DR_TABS.OVERVIEW" [activeTopic]="activeTopic" [activeDr]="activeDr" [activeEsrs]="activeEsrs" [activeCsrdProject]="activeCsrdProject" (startAssessment)="startAssessment()" (startDataRequest)="startDataRequest()"></csrd-dr-overview>
                        <!-- DATAPOINTS -->
                        <csrd-dr-datapoints #drDatapointsComponent *ngIf="activeDrTab == DR_TABS.DATAPOINTS" [activeTopic]="activeTopic" [activeDr]="activeDr" [activeEsrs]="activeEsrs" [activeCsrdProject]="activeCsrdProject"
                            (switchActiveDrTab)="switchActiveDrTab($event)" (switchProjectsPage)="goSwitchProjectsPage()" (switchCsrdProjectPage)="goSwitchCsrdProjectPage()"></csrd-dr-datapoints>

                       
                        <!-- COLLECTED DATA -->
                        <csrd-dr-collected-data *ngIf="activeDrTab == DR_TABS.COLLECTED_DATA" [activeTopic]="activeTopic" [activeDr]="activeDr" [activeEsrs]="activeEsrs" [activeCsrdProject]="activeCsrdProject"></csrd-dr-collected-data>
                    </div>
                    <div class="flex-fill" *ngIf="activeDrTab == DR_TABS.REQUESTS">
                         <!-- REQUESTS -->
                        <div class="d-flex h-100 flex-column">
                            <div class="d-flex flex-row align-items-center p-3 btn-add" style="height: var(--app-header-footer-height);">
                                <h5 class="m-0 text-white">{{ slocale("Preparing the data request") }}</h5>
                            </div>
                            <div class="w-100 h-100" *ngIf="drService.hasQuestionnaire(activeDr.id)">
                                <questionaire-creator-main-form [seedingQuestionnaire]="drService.getQuestionnaire(activeDr.id)"></questionaire-creator-main-form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
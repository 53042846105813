<div class="csrdInfoTab">
    <div class="datapointName">
        <div class="status phaseIn" *ngIf="datapoint.phaseIn">
            <i class="la la-clock icon"></i>
        </div>
        <div class="status mayDisclose" *ngIf="datapoint.isVoluntary">
            <i class="la la-question icon"></i>
        </div>
        <div class="status conditional" *ngIf="datapoint.conditional">
            <i class="las la-code-branch icon"></i>
        </div>
        <div class="label">{{ datapoint.id }} {{ datapoint.name }}</div>
    </div>
    <div class="accordion">
        <div class="accordion-item phaseIn">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                    <i class="la la-clock icon"></i> {{ slocale("Phase-in or effective date") }}
                </button>
            </h2>
            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                <div class="accordion-body">
                    <p *ngIf="datapoint.phaseIn; else noData" [innerHTML]="datapoint.phaseIn"></p>
                </div>
            </div>
        </div>
        <div class="accordion-item conditional">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                    <i class="las la-code-branch icon"></i> {{ slocale("Conditional") }}
                </button>
            </h2>
            <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                <div class="accordion-body">
                    <p *ngIf="datapoint.conditional; else noData" [innerHTML]="datapoint.conditional"></p>
                </div>
            </div>
        </div>
        <div class="accordion-item paragraphInformation">
            <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                    {{ slocale("Paragraph information") }}
                </button>
            </h2>
            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                <div class="accordion-body">
                    <p *ngIf="datapoint.description; else noData" [innerHTML]="datapoint.description"></p>
                </div>
            </div>
        </div>
        <div class="accordion-item applicationRequirements">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#applicationRequirementsBody" aria-expanded="false" aria-controls="applicationRequirementsBody">
                    {{ slocale("Application Requirements") }}
                </button>
            </h2>
            <div id="applicationRequirementsBody" class="accordion-collapse collapse" aria-labelledby="applicationRequirementsBody">
                <div class="accordion-body">
                    <ng-container *ngIf="datapoint.applicationRequirements; else noData">
                        <ng-container *ngFor="let applicationRequirement of datapoint.applicationRequirements">
                            <p [innerHTML]="applicationRequirement" *ngIf="!applicationRequirement.includes('N/A')"></p>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="accordion-item additionalReferences">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#additionalReferencesBody" aria-expanded="false" aria-controls="additionalReferencesBody">
                    {{ slocale("Additional References") }}
                </button>
            </h2>
            <div id="additionalReferencesBody" class="accordion-collapse collapse" aria-labelledby="additionalReferencesBody">
                <div class="accordion-body">
                    <ng-container *ngIf="!(!datapoint.supplementalInfo && !datapoint.supplementalReference); else noData">
                        <p *ngIf="datapoint.supplementalInfo && !datapoint.supplementalInfo.includes('N/A')" [innerHTML]="datapoint.supplementalInfo"></p>
                        <p *ngIf="datapoint.supplementalReference && !datapoint.supplementalReference.includes('N/A')" [innerHTML]="datapoint.supplementalReference"></p>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="accordion-item footnotes">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#footnotesBody" aria-expanded="false" aria-controls="footnotesBody">
                    {{ slocale("Footnotes") }}
                </button>
            </h2>
            <div id="footnotesBody" class="accordion-collapse collapse" aria-labelledby="footnotesBody">
                <div class="accordion-body">
                    <ng-container *ngIf="datapoint.applicationFootnotes; else noData">
                        <ng-container *ngFor="let applicationFootnote of datapoint.applicationFootnotes">
                            <p [innerHTML]="applicationFootnote" *ngIf="!applicationFootnote.includes('N/A')"></p>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="accordion-item relationToOtherStandards">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#relationToOtherStandardsBody" aria-expanded="false" aria-controls="relationToOtherStandardsBody">
                    {{ slocale("Relation to other standards") }}
                </button>
            </h2>
            <div id="relationToOtherStandardsBody" class="accordion-collapse collapse" aria-labelledby="relationToOtherStandardsBody">
                <div class="accordion-body">
                    <p *ngIf="datapoint.relationToOtherStandards; else noData" [innerHTML]="datapoint.relationToOtherStandards"></p>
                </div>
            </div>
        </div>
        <div class="accordion-item internalComment">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#internalComment" aria-expanded="false" aria-controls="internalComment">
                    {{ slocale("Internal comment") }}
                </button>
            </h2>
            <div id="internalComment" class="accordion-collapse collapse" aria-labelledby="internalComment">
                <div class="accordion-body">
                    <p *ngIf="datapointSettings.comment; else noData" [innerHTML]="datapointSettings.comment"></p>
                </div>
            </div>
        </div>
    </div>
    <ng-template #noData>
        <p>{{ slocale("N/A") }}</p>
    </ng-template>
</div>
import { Component, Input, Output, OnInit, TemplateRef, ViewChild, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subject, Subscription } from "rxjs";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { ContactFe } from "src/app/model/user/ContactFe";
import { Request_AddFe } from "src/app/model/data-suppliers/request/add/Request_AddFe";
import { RequestGroup_AddFe } from "src/app/model/data-suppliers/request/add/RequestGroup_AddFe";
import { RequestGroupFe } from "src/app/model/data-suppliers/request/RequestGroupFe";
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe";
import { IdUtil } from "src/app/utils/IdUtil";
import { RequestFe } from "src/app/model/data-suppliers/request/RequestFe";
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe";
import { QuestionDataTableFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionDataTableFe";
import { QuestionSingleChoiceFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionSingleChoiceFe";
import { QuestionMultipleChoiceFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionMultipleChoiceFe";
import { QuestionDateFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionDateFe";
import { QuestionNumberFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionNumberFe";
import { QuestionnaireFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionnaireFe";
import { QuestionTypeConversion } from "src/app/model/data-suppliers/request/questionnaire/QuestionTypeConversionFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { ResponsiveService } from "src/app/services/ResponsiveService";
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize";
import moment from "moment";
import { LoginServiceFe } from "src/app/services/LoginServiceFe";
import { SectionFe } from "src/app/model/data-suppliers/request/questionnaire/SectionFe";
import { DataCategoryServiceFe } from "src/app/services/DataCategoryServiceFe";
import { RequestServiceFe } from "src/app/services/RequestServiceFe";
import { BsDropdownDirective } from "ngx-bootstrap/dropdown";
import { SubmissionDetailsRequestComponent } from "./submission-details-questionnaire.component";
import { CsrdDefintionService } from "../../projects/csrd/CsrdDefintionService";
import { CsrdQuestionnaireFe } from "src/app/model/data-suppliers/request/csrd/CSRDQuestionnaireFe";

@Component({
  selector: "questionaire-creator-main-form",
  templateUrl: "./questionaire-creator-main-form.component.html",
  styleUrls: ["./questionaire-creator-full-page.component.scss", "../data-suppliers.component.scss"],
  providers: [{ provide: BsDropdownDirective }],
  changeDetection: ChangeDetectionStrategy.Default
})
export class QuestionaireCreatorMainFormComponent extends AbstractLanguageComponent implements OnInit {
  activeDR: { code: string, name: string } | null = null;
  private subscriptionDr: Subscription;
  @Input() reqGroupToBeDuplicated: RequestGroupFe
  @Input() isFromDraft = false;
  @Input() seedingQuestionnaire: QuestionnaireFe
  
  @Output() goBack = new Subject<boolean>()
  @Output() createdRequest = new EventEmitter<RequestGroupFe>()
  @Output() searchTextChange = new EventEmitter<string>();
  @Output() editQuestionnaire = new EventEmitter<void>();
  expandCollapseEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() titleChanged = new EventEmitter<string>();

  @ViewChild("confirmSendRequest") confirmSendRequest: TemplateRef<any>;
  @ViewChild("confirmSaveAsDraft") confirmSaveAsDraft: TemplateRef<any>;
  @ViewChild("confirmDeploy") confirmDeploy: TemplateRef<any>;
  @ViewChild(SubmissionDetailsRequestComponent) submissionDetailsRequestComponent!: SubmissionDetailsRequestComponent;


  taxonomyInfo: TaxonomyInfoFe = undefined;
  allDataOwners: ContactFe[] = []
  filteredDataOwners: ContactFe[] = []

  loadingInProgress = false
  initCacheInProgress: boolean

  activeWizardPageNumber: number = 1

  isQuestionnaireInPreviewMode: boolean = false
  requestForPreview: RequestFe

  isSaveAsDraft = false

  sections: SectionFe[] = []

  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE

  nextDeadline = moment().add(1, "month").startOf("day").toISOString().split("T")[0]

  allExpanded: boolean = true;
  searchText: string = '';

  dataRequestDetailsForm = new FormGroup({
    title: new FormControl(null, [Validators.required]),
    desc: new FormControl(null),
  })

  submissionForm = new FormGroup({
    dataConnection: new FormControl("autoConnect"),
  })

  wizardSteps = [
    { title: this.slocale("Add title & description"), completed: false },
    { title: this.slocale("Create questions"), completed: false },
    { title: this.slocale("Define submission details"), completed: false },
  ]
url = '';
  private subscription: Subscription | undefined;

  isCSRDQuestionnaire: boolean = false

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    public stateService: StateServiceFe,
    public displayService: DisplayServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    private loginService: LoginServiceFe,
    public categoryService: DataCategoryServiceFe,
    public requestService: RequestServiceFe,
    private cd: ChangeDetectorRef,
    private drService: CsrdDefintionService
  ) {
    super(languageService)
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })

    this.screenSize = responsive.currentScreenWidthSize

  }

  async ngOnInit(): Promise<void> {
    this.loadingInProgress = true
    this.taxonomyInfo = await this.categoryService.getTaxonomy();
    this.allDataOwners = await this.stateService.getActiveContacts()
    this.filteredDataOwners = this.allDataOwners
    this.filteredDataOwners.forEach((d) => (d.isSelected = false))
    if (this.seedingQuestionnaire) {
      this.populatedQuestionnnaire(this.seedingQuestionnaire)
      this.seedingQuestionnaire.setParentIds()
      this.isQuestionnaireInValid()
      if (this.seedingQuestionnaire instanceof CsrdQuestionnaireFe) {
        this.isCSRDQuestionnaire = true
        this.enterQuestionnairePreviewMode()
      }
    } else if (this.reqGroupToBeDuplicated) {
      this.populateDataRequest(this.reqGroupToBeDuplicated)
      this.reqGroupToBeDuplicated.questionnaire?.setParentIds()
      this.isQuestionnaireInValid()
    } else {
      this.dataRequestDetailsForm.reset()
      this.submissionForm.reset()
      this.submissionForm.patchValue({ dataConnection: "autoConnect" })
      this.sections = []
    }
    this.displayService.closeTips()
    this.displayService.closeDetails()
    this.loadingInProgress = false

    this.url = window.location.href;

    this.subscriptionDr = this.drService.activeDR$.subscribe(dr => {
      this.activeDR = dr;
      this.updateTitle();
    });

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscriptionDr) {
      this.subscriptionDr.unsubscribe();
    }
  }

  updateTitle() {
    const title = this.getTitle();
    if (title) {
      this.dataRequestDetailsForm.get('title').setValue(title, { emitEvent: false });
    }
  }

  getTitle(): string {
    if (this.isCSRDQuestionnaire && this.activeDR) {
      return `${this.activeDR.code} ${this.activeDR.name}`;
    }
    return '';
  }

  public canSwitchToPreviewMode(): boolean {
    return this.activeWizardPageNumber == 2 && !this.isQuestionnaireInPreviewMode
  }

  public canSwitchToEditMode(): boolean {
    return this.activeWizardPageNumber == 2 && this.isQuestionnaireInPreviewMode
  }

  public handleBackButton() {
    this.activeWizardPageNumber == 1 ? this.closeQuestionnaire() : this.previousWizardPage()
  }

  onTitleChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.titleChanged.emit(input.value); 
  }

  nextWizardPage() {
    if (this.activeWizardPageNumber == 1) {
      this.dataRequestDetailsForm.markAllAsTouched()
      // if (!this.dataRequestDetailsForm.invalid) {
        this.activeWizardPageNumber = 2
        return
      // }
    } else if (this.activeWizardPageNumber == 2 ) {
      // && !this.isQuestionnaireInValid()
      this.activeWizardPageNumber = 3
      return
    } else {
      return
    }
  }

  previousWizardPage(): void {
    this.activeWizardPageNumber--
    if (this.activeWizardPageNumber < 1) {
      this.activeWizardPageNumber = 1
    }
  }

  isQuestionnaireInValid(): boolean {
    if (this.sections.length === 0) {
      return true;
    }
  
    return this.sections.some((section) => 
      section.questions.some((q) => {
        q.titleControl.markAsTouched();
        
        if (q.titleControl.invalid) {
          return true;
        }
  
        if (q instanceof QuestionSingleChoiceFe || q instanceof QuestionMultipleChoiceFe) {
          if (q.options.length < 2 || q.options.some((o) => {
            o.valueControl.markAsTouched();
            if (o.valueControl.invalid) {
              return true;
            }
            if (o.showDesc) {
              o.descControl.markAsTouched();
              return o.descControl.invalid;
            }
            return false;
          })) {
            return true;
          }
        }
  
        if (q instanceof QuestionDataTableFe && q.fields.length === 0) {
          return true;
        }
  
        if (q.isFollowUpQues && !q.followUpCondition) {
          return true;
        }
  
        if (q instanceof QuestionNumberFe && q.isUnitRequiredFromSM() && !q.unit) {
          return true;
        }
  
        return false;
      })
    );
  }
  

  isSubmissionFormValid(): boolean {
    this.submissionForm.markAllAsTouched()

    if (this.submissionForm.invalid) {
      return false
    }
    if (this.anyDOSelected() == false) {
      return false
    }
    return true
  }

  openModal(template: TemplateRef<any>, size: string) {
    this.modalRef = this.modalService.show(template, {
      class: size,
      backdrop: "static",
      keyboard: true,
    })
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id)
    document.body.classList.remove("modal-open")
  }

  async sendRequest(deleteDraft: boolean, keepDraft: boolean = false): Promise<void> {
    const requestGroup = await this.buildAndSaveRequestGroup(false, true, deleteDraft, keepDraft)
    this.createdRequest.emit(requestGroup)
  }

  async saveDraft(saveAsNew: boolean): Promise<void> {
    const rg = await this.buildAndSaveRequestGroup(saveAsNew, false, !saveAsNew)
  }


  async findUniqueTitle(rg: RequestGroup_AddFe): Promise<void> {
    let counter = 0;
    const originalTitle = rg.title;
    let allRequestGroups = await this.stateService.getRequestGroups()
    while (allRequestGroups.some((r) => r.draft && r.title === rg.title)) {
      counter++;
      rg.title = `${originalTitle} (${counter})`;
    }
  }

  async buildAndSaveRequestGroup(saveDraftAsNew: boolean, isSendRequest: boolean, deleteDraft: boolean, keepDraft: boolean = false): Promise<RequestGroupFe> {
    this.loadingInProgress = true;
    const requests: Request_AddFe[] = [];
    let autoConnect = this.submissionForm.controls.dataConnection.value == "autoConnect" ? true : false;

    let questionnaire = new QuestionnaireFe(this.sections, autoConnect, IdUtil.next());
    questionnaire.sections.forEach((section) => {
      section.questions.forEach((q) => {
        if (q instanceof QuestionDataTableFe) {
          q.setPredefinedOptionsAndQuestionInFields();
          q.taxonomyInfo = null;
          q.requestItemDataGridService = null;
          q.newAnswer = null;
        }
      });
    });

    this.allDataOwners.forEach((dataOwner) => {
      if (dataOwner.isSelected) {
        const newReq = new Request_AddFe(IdUtil.next(), dataOwner.affiliationCompanyId, dataOwner.affiliationId);
        requests.push(newReq);
      }
    });

    let deadline = moment(this.nextDeadline).utc(true).toDate();

    const requestGroup_Add = new RequestGroup_AddFe(
      IdUtil.next(),
      this.dataRequestDetailsForm.controls.title.value,
      this.dataRequestDetailsForm.controls.desc.value,
      questionnaire,
      requests,
      deadline,
      this.isSaveAsDraft,
      this.submissionDetailsRequestComponent.genRecurrence(),
      false,
      null,
      this.stateService.activeWorkspace.affiliationId,
      this.loginService.loginUser.firstName,
      this.loginService.loginUser.lastName,
      this.stateService.activeWorkspace.companyId,
      this.stateService.activeWorkspace.companyName,
      this.loginService.loginUser.userId
    );

    if (saveDraftAsNew) {
      this.findUniqueTitle(requestGroup_Add)
    }

    const shouldDeleteDraft = deleteDraft && (!isSendRequest || (isSendRequest && this.isFromDraft));
    if (shouldDeleteDraft) {
      await this.stateService.deleteDraftRequestGroup(this.reqGroupToBeDuplicated?.id);
    }

    const requestGroup = await this.stateService.addRequestGroup(requestGroup_Add, keepDraft);

    this.closeQuestionnaire();
    this.closeModal();

    this.loadingInProgress = false;

    return requestGroup;
  }

  anyDOSelected() {
    return this.filteredDataOwners.some((d) => d.isSelected)
  }

  closeQuestionnaire() {
    // No need to keep data category when request is closed
    this.categoryService.resetNewEntitiesAndDatapoints()
    this.goBack.next(true)
  }

  async populateDataRequest(initialDataRequest: RequestGroupFe) {
    this.dataRequestDetailsForm.patchValue({
      title: initialDataRequest.title,
      desc: initialDataRequest.description,
    })

    let initialQuestionnaire = initialDataRequest.draft ? initialDataRequest.questionnaire : initialDataRequest.requests[0].questionnaire
    this.populatedQuestionnnaire(initialQuestionnaire)
    let requests = initialDataRequest.draft ? initialDataRequest.draftRequests : initialDataRequest.requests
    for (let request of requests) {
      let dataOwner = this.allDataOwners.find((d) => d.affiliationId == request.assigneeAffId)
      dataOwner.isSelected = true
    }

    this.submissionForm.patchValue({
      dataConnection: initialQuestionnaire.autoConnect ? "autoConnect" : "manualConnect",
    })

    // copy deadline
    this.nextDeadline = moment(initialDataRequest.dueDate).toISOString().split("T")[0]

    // copy reminders
    // this.reminder = initialDataRequest.recurrence.sendScheduledReminders
    // this.sendReminderToCreator = initialDataRequest.recurrence.sendReminderToCreator
    // this.reminders = initialDataRequest.recurrence.reminders
  }

  populatedQuestionnnaire(initialQuestionnaire: QuestionnaireFe) {
    initialQuestionnaire.sections.forEach((section) => {
      let newSection = new SectionFe(section.id, section.name, section.taxonomyKey, section.taxonomyVersion, [], this.taxonomyInfo)
      for (let question of section.questions) {
        let newQuestion = QuestionTypeConversion.setQuestion(question)
        if (newQuestion.isFollowUpQues) {
          let formulaString = newQuestion.followUpCondition.toFormulaString()
          let index1 = formulaString.indexOf("$"),
            index2 = formulaString.indexOf(".")
          let parentQuesId = formulaString.substring(index1 + 1, index2)
          formulaString = formulaString.substring(index2)
          newQuestion.parentQuestionId = parentQuesId
          let parentQues = newSection.questions.find((q) => q.id == parentQuesId)
          parentQues.hasFollowUpQuestion = true
          parentQues.childrenNumber += 1
          if (parentQues instanceof QuestionSingleChoiceFe) {
            let optionStartIndex = formulaString.indexOf("."),
              optionEndIndex = formulaString.indexOf(" ", optionStartIndex)
            let optionId = formulaString.substring(optionStartIndex + 1, optionEndIndex)
            let option = parentQues.options.find((o) => o.id == optionId)
            newQuestion.followUpOptions = option
          } else if (parentQues instanceof QuestionMultipleChoiceFe) {
            let optionStartIndex = formulaString.indexOf(".")
            while (optionStartIndex != -1) {
              optionStartIndex = formulaString.indexOf(".")
              let optionEndIndex = formulaString.indexOf(" ", optionStartIndex)
              let optionId = formulaString.substring(optionStartIndex + 1, optionEndIndex)
              formulaString = formulaString.substring(optionEndIndex + 1)
              let option = parentQues.options.find((o) => o.id == optionId)
              if (option) {
                if (newQuestion.followUpOptions instanceof Array) {
                  newQuestion.followUpOptions.push(option)
                } else {
                  newQuestion.followUpOptions = [option]
                }
              }
            }
          }
        }
        if (newQuestion instanceof QuestionDataTableFe && question instanceof QuestionDataTableFe) {
          newQuestion.taxonomyInfo = this.taxonomyInfo
          newQuestion.setQuestionFromOld(question, section)
        }
        if (newQuestion.mappedToColKeys && newSection.entity) {
          newQuestion.setMappedToCols(newSection.entity)
        }
        if (newQuestion instanceof QuestionDateFe && newQuestion.mappedToColKeysRange) {
          newQuestion.setMappedToColsRange(newSection.entity)
        }
        newQuestion.confirmMapping()
        newSection.questions.push(newQuestion)
      }
      newSection.editTaxonomy = false
      this.sections.push(newSection)
    })
  }

  openPreview() {
    let questionnaire = new QuestionnaireFe(this.sections, false, IdUtil.next())
    this.requestForPreview = new RequestFe(IdUtil.next(), null, null, null, null, null, null, null, null, questionnaire)
    this.isQuestionnaireInPreviewMode = true;
    this.cd.detectChanges();
  }

  closePreview() {
    this.isQuestionnaireInPreviewMode = false;
    this.cd.detectChanges();
  }

  editQuestionnaireFromPreview() {
    this.isQuestionnaireInPreviewMode = false;
  }

  autoGrowDescriptionTextArea(e) {
    e.target.style.height = "0px"
    e.target.style.height = (e.target.scrollHeight < 575 ? e.target.scrollHeight + 25 : 600) + "px"
    e.target.maxHeight = "600px"
  }

  selectAllDataOwners(event: Event) {
    if ((event.target as HTMLInputElement).checked) this.filteredDataOwners.forEach((d) => (d.isSelected = true))
    else this.filteredDataOwners.forEach((d) => (d.isSelected = false))
  }

  async openConnectedActionsModal() {
    if (!this.isSaveAsDraft) {
      let isValid = this.isSubmissionFormValid()
      if (!isValid) {
        return
      }
    }
    if (this.categoryService.totalChanges() > 0) {
      this.openModal(this.confirmDeploy, "modal-md")
    } else {
      this.handleRequest()
    }
  }

  handleRequest() {
    if (this.isSaveAsDraft) {
      if (this.isFromDraft) {
        this.openModal(this.confirmSaveAsDraft, "modal-md")
      } else {
        this.saveDraft(true)
      }
    } else {
      this.openModal(this.confirmSendRequest, "modal-md")
    }
  }

  async deployTaxonomy() {
    this.loadingInProgress = true
    await this.categoryService.deploy()
    this.closeModal()
    this.handleRequest()
    this.loadingInProgress = false
  }

  isConnectedAction() {
    return this.displayService.openTab != "CONNECTED_ACTIONS" && this.categoryService.totalChanges() > 0
  }

  isDraftExists(): boolean {
    return !!this.reqGroupToBeDuplicated
  }

  expandCollapseAll() {
    // Check the current state of accordions
    const anyCollapsed = this.sections.some((section, index) => {
      const accordionContent = document.getElementById(`sectionAccordion${index}${this.isQuestionnaireInPreviewMode ? 'Preview' : ''}`);
      return !accordionContent?.classList.contains('show');
    });

    this.allExpanded = anyCollapsed;
    this.expandCollapseEvent.emit(this.allExpanded);
  }

  onAccordionStateChange(expanded: boolean) {
    this.allExpanded = expanded;
  }

  enterQuestionnairePreviewMode() {
    this.isQuestionnaireInPreviewMode = true
    this.openPreview();
  }

  enterQuestionnaireEditMode() {
    this.isQuestionnaireInPreviewMode = false
    this.editQuestionnaireFromPreview();
  }

  filterQuestions() {
    this.searchTextChange.emit(this.searchText);
  }

  isOpen1: boolean = false; // State for the first accordion

  toggleAccordion(textId: string, collapseId: string) {
    this.isOpen1 = !this.isOpen1; // Toggle the state
  }
}

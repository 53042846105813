<div class="d-flex flex-column h-100">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between">
        <nav>
          <ul class="nav">
            <li class="navbar-item">
              <a>
                <h3 class="mt-2">{{ slocale("PROJECTS") }}</h3>
              </a>
            </li>
            <li class="navbar-item">
              <h3 class="mt-2 mx-1"><i class="la la-angle-right font-medium-1"></i></h3>
            </li>
            <li class="navbar-item">
              <a>
                <h3 class="mt-2">{{ activeCsrdProject.name }}</h3>
              </a>
            </li>
            <li class="navbar-item">
              <h3 class="mt-2 mx-1"><i class="la la-angle-right font-medium-1"></i></h3>
            </li>
            <li class="navbar-item">
              <a>
                <h3 class="mt-2">{{ activeEsrs.code + ' ' + activeEsrs.name }}</h3>
              </a>
            </li>
          </ul>
        </nav>
        <!-- dropdown menu instead of toolbar for smaller pages-->
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle ml-4 mt-" aria-controls="dropdown-alignment">
            <i class="la la-ellipsis-h fs-2" style="rotate: 90deg"></i>
          </button>
          <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                    <button type="button" class="btn" (click)="handleToolbarAction(toolbarButton.actionName)" *ngIf="toolbarButton.visible()">
                      <i [class]="toolbarButton.icon"></i>
                      <span>{{ toolbarButton.longLabel }}</span>
                    </button>
                  </li>
                </ng-container>
                <!-- <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li> -->
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button *ngIf="toolbarButton.visible()" type="button" class="btn page-toolbar-button" (click)="handleToolbarAction(toolbarButton.actionName)" data-bs-toggle="tooltip" data-placement="top" [title]="toolbarButton.tooltip"
                show-delay="3000">
                <i [class]="toolbarButton.icon" class="fs-4"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <!-- content column-->
      <div class="flex-fill">
        <div class="d-flex h-100 flex-column">
          <div class="card me-2" style="height: 84vh; max-width: 100vw !important" [ngStyle]="!cardHeader ? { border: 'none' } : {}">
            <ng-container *ngIf="materiality">
              <div class="card-body mt-0 pt-0 px-0" style="overflow-y: scroll !important; border-bottom: none; max-width: 100vw !important">
                <div class="card-header bg-white">
                  <h5 class="mb-0 pb-0 py-2">{{ slocale("Materiality of") + ' ' + activeEsrs.code + ' ' + activeEsrs.name }}</h5>
                </div>
                <div class="p-3">
                  <h6 class="text-justify bold">{{ slocale('Is the topic') + ' "' + activeEsrs.code + ' ' + activeEsrs.name + '" ' + slocale('fully/partially or not material to your organization based on the results of your Double
                    Materiality Assessment?') }}</h6>
                  <p class="text-justify">
                    {{ slocale("A sustainability matter is “material” when it meets the criteria defined for impact materiality (see section 3.4 of the Standard) or financial materiality (see section 3.5 of the Standard), or both (§28).")
                    }}
                  </p>
                  <p class="text-justify py-2">
                    <span class="cursor-pointer me-2 border rounded px-2" [ngClass]="{'bg-pink text-white': isAssessed && isMaterial}" (click)="setMateriality(true)">{{ slocale("Topic is fully / partially material") }}</span>
                    <span class="cursor-pointer me-2 border rounded px-2" [ngClass]="{'bg-pink text-white': isAssessed && !isMaterial}" (click)="setMateriality(false)">{{ slocale("Topic is not material") }}</span>
                  </p>
                  <p *ngIf="isAssessed && isMaterial && (subtopics.length > 0 || subsubtopics.length > 0)" class="bold mb-0 pb-0">{{ slocale("Select the sub- and sub-sub-topics that are material to your organization.") }}</p>
                  <div *ngIf="isAssessed && isMaterial && (subtopics.length > 0 || subsubtopics.length > 0)" class="d-flex flex-row flex-wrap justify-content-between align-items-start">

                    <!-- SUBTOPICS LIST -->
                    <ng-container *ngIf="subtopics.length > 0">
                      <div class="d-flex flex-column justify-content-start w-auto flex-fill me-1 mt-1">
                        <div class="p-3  d-flex justify-content-between align-items-center bg-light-gray">
                          <div class="d-flex flex-row justify-content-between align-items-center">
                            <h6 class="bold mb-0 pb-0">{{ slocale("Sub-topics") }}</h6>
                          </div>
                          <div class="">
                            <a class="underline mx-1" (click)="selectAllSubtopics()">{{ slocale("Select all") }}</a>
                            <a class="underline mx-1" (click)="deselectAllSubtopics()">{{ slocale("Deselect all") }}</a>
                          </div>
                        </div>
                        <ng-container *ngFor="let subtopic of subtopics">
                          <ng-container *ngIf="subtopic.isSelected">
                            <div class="cursor-pointer p-3 d-flex flex-row align-items-center thick-bt-selected w-100 border mt-1" (click)="deselectSubtopic(subtopic)">
                              <div class="d-flex flex-row justify-content-between align-items-center w-100">
                                <span>
                                  <h6 class="mb-0 pb-0 d-inline">{{ subtopic.name }}</h6>
                                  <!-- <i class="la la-info-circle ms-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                  <div class="dropdown-menu arrow p-2 info-popover">
                                    <p>{{ slocale("Text to be added!") }}</p>
                                  </div> -->
                                </span>
                                <i class="las la-check bold pink"></i>
                              </div>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="!subtopic.isSelected">
                            <div class="cursor-pointer p-3 d-flex flex-row align-items-center thick-bt-gray flex-fill border mt-1" (click)="selectSubtopic(subtopic)">
                              <div class="d-flex flex-row justify-content-between align-items-center w-100">
                                <span>
                                  <h6 class="mb-0 pb-0  d-inline">{{ subtopic.name }}</h6>
                                  <!-- <i class="la la-info-circle ms-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                  <div class="dropdown-menu arrow p-2 info-popover">
                                    <p>{{ slocale("Text to be added!") }}</p>
                                  </div> -->
                                </span>
                                <i class="las la-check bold pink d-none"></i>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>



                        <!-- NEEDED FOR VERSION 2 OF CSRD PROJECT -->
                        <!-- <button class="btn tertiary-button w-100 mt-2" (click)="openModal(createSubTopicModal, 'modal-md')"><i class="la la-plus"></i> {{ slocale("Create a sub-topic") }}</button> -->
                      </div>
                    </ng-container>

                    <!-- SUB-SUBTOPICS LIST -->
                    <ng-container *ngIf="subsubtopics.length > 0">
                      <div class="d-flex flex-column justify-content-start w-auto flex-fill me-1 mt-1">
                        <div class="p-3  d-flex justify-content-between align-items-center bg-light-gray">
                          <div class="">
                            <h6 class="bold mb-0 pb-0">{{ slocale("Sub-sub-topics") }}</h6>
                          </div>
                          <div class="">
                            <a class="underline mx-1" (click)="selectAllSubSubtopics()">{{ slocale("Select all") }}</a>
                            <a class="underline mx-1" (click)="deselectAllSubSubtopics()">{{ slocale("Deselect all") }}</a>
                          </div>
                        </div>
                        <ng-container *ngFor="let subsubtopic of subsubtopics">
                          <ng-container *ngIf="subsubtopic.isSelected">
                            <div class="cursor-pointer p-3 d-flex flex-row align-items-center thick-bt-selected w-100 border mt-1" (click)="deselectSubSubtopic(subsubtopic)">
                              <div class="d-flex flex-row justify-content-between align-items-center w-100">
                                <span>
                                  <h6 class="mb-0 pb-0  d-inline">{{ subsubtopic.name }}</h6>
                                  <!-- <i class="la la-info-circle ms-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                  <div class="dropdown-menu arrow p-2 info-popover">
                                    <p>{{ slocale("Text to be added!") }}</p>
                                  </div> -->
                                </span>
                                <i class="las la-check bold pink"></i>
                              </div>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="!subsubtopic.isSelected">
                            <div class="cursor-pointer p-3 d-flex flex-row align-items-center thick-bt-gray w-100 border mt-1" (click)="selectSubSubtopic(subsubtopic)">
                              <div class="d-flex flex-row justify-content-between align-items-center w-100">
                                <span>
                                  <h6 class="mb-0 pb-0  d-inline">{{ subsubtopic.name }}</h6>
                                  <!-- <i class="la la-info-circle ms-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                  <div class="dropdown-menu arrow p-2 info-popover">
                                    <p>{{ slocale("Text to be added!") }}</p>
                                  </div> -->
                                </span>
                                <i class="las la-check bold pink d-none"></i>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>


                        <!-- NEEDED FOR VERSION 2 OF CSRD PROJECT -->
                        <!-- <button class="btn tertiary-button w-100 mt-2" (click)="openModal(createSubTopicModal, 'modal-md')"><i class="la la-plus"></i> {{ slocale("Create a sub-subtopic") }}</button> -->
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
            <div id="card-footer" class="card-footer float-end mt-auto text-end quest-footer py-2 px-1 mx-0" style="max-width: 100vw; background-color: transparent" *ngIf="cardFooter">

              <ng-container *ngIf="confirmingMateriality">
                <div class="ball-pulse loader-primary text-center m-auto">
                  <div style="background-color: var(--main-dark-color1)"></div>
                  <div style="background-color: var(--main-dark-color1)"></div>
                  <div style="background-color: var(--main-dark-color1)"></div>
                </div>
              </ng-container>

              <ng-container *ngIf="!confirmingMateriality">
                <button type="button" class="btn m-1 tertiary-button custom-button" (click)="startCancelDefineMateriality()">{{ locale("locale_key.general.buttons.cancel") }}</button>
                <button type="button" class="btn m-1 primary-button custom-button mt-sm-1" (click)="startConfirmMateriality()" [disabled]="!isAssessed">{{ slocale("Confirm") }}</button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #cancelDefineMaterialityModal>
    <div class="modal-header alert-modal-header">
      <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Cancel process") }}</h4>
      <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <p class="ps-2" *ngIf="materiality">{{ slocale("Are you sure you want to cancel? Your changes in this session will be undone and not saved. To keep your progress, return to the page and use the 'back' button to exit the process.") }}
      </p>
    </div>
    <div class="modal-footer" style="border-top: none">
      <!--Enable when loading-->
      <!-- <div class="ball-pulse loader-primary text-center m-auto" >
            <div style="background-color: var(--main-dark-color1)"></div>
            <div style="background-color: var(--main-dark-color1)"></div>
            <div style="background-color: var(--main-dark-color1)"></div>
          </div> -->
      <ng-container>
        <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
        <button type="button" class="btn primary-button custom-button text-white" (click)="confirmCancelDefineMateriality()">{{ slocale("Confirm") }}</button>
      </ng-container>
    </div>
  </ng-template>
  <ng-template #saveDraft>
    <div class="modal-header alert-modal-header">
      <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14" *ngIf="materiality">{{ slocale("Cancel process") }}</h4>
      <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <p class="ps-2" *ngIf="materiality">{{ slocale("Are you sure you want to cancel? Your changes in this session will be undone and not saved. To keep your progress, return to the page and use the 'back' button to exit the process.") }}
      </p>
    </div>
    <div class="modal-footer" style="border-top: none">
      <!--Enable when loading-->
      <!-- <div class="ball-pulse loader-primary text-center m-auto" >
            <div style="background-color: var(--main-dark-color1)"></div>
            <div style="background-color: var(--main-dark-color1)"></div>
            <div style="background-color: var(--main-dark-color1)"></div>
          </div> -->
      <ng-container>
        <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
        <button type="button" class="btn primary-button custom-button text-white" (click)="saveProject()">{{ slocale("Confirm") }}</button>
      </ng-container>
    </div>
  </ng-template>
</div>

<!-- Create sub-topic modal-->
<ng-template #createSubTopicModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Create a sub-topic") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3 " style="border-top: none">
    <div class="d-flex flex-column">
      <p class="pt-3 bold mb-0 pb-0">{{ slocale("Sub-topic name") }}</p>
      <input type="text" class="form-control w-auto" placeholder="{{ slocale('Enter sub-topic name') }}" />
      <p class="pt-3 bold mb-0 pb-0">{{ slocale("Sub-topic description") }}</p>
      <textarea class="form-control" rows="3" placeholder="{{ slocale('Enter description') }}"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button" data-test="request-create-button">{{ locale("locale_key.general.buttons.save") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
  </div>
</ng-template>

<!-- Logout due to inactivity Modal -->
<ng-template #logoutInactivityModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Logout due to inactivity") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{ slocale("You are about to be logged out due to being inactive for a long time. Your work will be automatically saved and you can continue from where you left of after logging in again.") }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
    <ng-container>
      <button type="button" class="btn primary-button custom-button text-white" (click)="saveProject()">{{ slocale("OK") }}</button>
    </ng-container>
  </div>
</ng-template>

<ng-template #confirmMaterialityModal>
  <div class="modal-header alert-modal-header">
    <ng-container *ngIf="!isMaterial">
      <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Confirm 'Not Material' status for ") + activeEsrs.code + slocale(" (no data collected yet)") }}</h4>
    </ng-container>
    <ng-container *ngIf="isMaterial">
      <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Confirm 'Material' status for ") + activeEsrs.code + slocale(" (no data collected yet)") }}</h4>
    </ng-container>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
      slocale(
      "Data collection for 'ESRS E3 Water and Marine resources' has already started, but no data has been submitted yet. If you change the materiality to 'not material,' all ongoing data requests and superuser tasks will be closed,
      preventing further data submission.Would you like to proceed?"
      )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="confirmingMateriality">
      <div class="ball-pulse loader-primary text-center m-auto">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div>
    </ng-container>

    <ng-container *ngIf="!confirmingMateriality">
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn primary-button custom-button text-white" (click)="continueConfirmMateriality()">{{ slocale("Confirm") }}</button>
    </ng-container>
  </div>
</ng-template>

<ng-template #saveBeforeLeavingModal>
  <div class="modal-header alert-modal-header">
      <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Save before leaving") }}</h4>
      <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="dontLeavePage()">
          <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
      </button>
  </div>

  <div class="modal-body">
      <!-- <p class="ps-2">
      {{
      slocale(
      "You have switched on the General phase in information toggle for all phase in data points. Turning off for this data point will keep the state of other currently omitted data points and show “Customized” text next to the General
      phase in information toggle."
      )
      }}
    </p> -->
      <p class="ps-2">
          {{ slocale("If you leave this page your changes won't be saved. To save changes return to the page and select the save option you want.") }}
      </p>
  </div>

  <div class="modal-footer" style="border-top: none">
      <!--Enable when loading-->
      <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
      <ng-container>
          <button type="button" class="btn secondary-button custom-button text-white" (click)="leavePage()">{{ slocale("Don't save") }}</button>
          <button type="button" class="btn primary-button text-white" (click)="dontLeavePage()">{{ slocale("Return to page") }}</button>
      </ng-container>
  </div>
</ng-template>

import { AbstractProjectInfoFe } from "./AbstractProjectInfoFe";
import { PROJECT_TYPE } from "./ProjectTypeFe";

export type CSRDDataPointSettings = {
  id: string;
  isMaterial: boolean;
  isMandatory: boolean;
  isOmitted: boolean;
  collectVoluntarily: boolean;
  comment?: string;
};

export enum CSRDDisclosureSettingsStatus {
  "NOT_STARTED" = "NOT_STARTED",
  "ASSESSING" = "ASSESSING",
  "ASSESSED" = "ASSESSED",
  "DRAFTING" = "DRAFTING",
  "COLLECTING" = "COLLECTING",
  "COMPLETED" = "COMPLETED"
}

export type CSRDDisclosureSettings = {
  id: string;
  status: CSRDDisclosureSettingsStatus;
  questionnaire: string[];
  hasDraft: boolean;
  isMandatory: boolean;
  draft: {
    csrdDatapoints: CSRDDataPointSettings[];
  };
  csrdDatapoints: CSRDDataPointSettings[];
};

export type CSRDEsrsSettings = {
  id: string;
  selectedSubTopics: string[];
  selectedSubSubTopics: string[];
  isAssessed: boolean;
  isMaterial: boolean;
  isMandatory: boolean;
  disclosures: CSRDDisclosureSettings[];
};

export type CSRDTopicSettings = {
  id: string;
  esrs: CSRDEsrsSettings[];
};

export type CSRDSettings = CSRDTopicSettings[];

export type CSRDProjectDefinition = {
  isStarted: boolean;
  reportingYear: string;
  firstReportingYear: string;
  company: {
    name: string;
    hq: {
      country: string;
      town: string;
    };
    noEmployees: string;
  };
  csrdSettings: CSRDSettings;
};

type CSRDProjectConstructorOpts = {
  id: string;
  name: string;
  draft: boolean;
  definition: CSRDProjectDefinition;
  createdAt: string;
};

export class CSRDProjectInfoFe extends AbstractProjectInfoFe {
  public definition: CSRDProjectDefinition;

  constructor(opts: CSRDProjectConstructorOpts) {
    super(opts.id, PROJECT_TYPE.CSRD, opts.name, opts.draft, opts.createdAt);
    this.definition = opts.definition;
  }

  public static fromTransfer(transfer: any): CSRDProjectInfoFe {
    return new CSRDProjectInfoFe({ ...transfer });
  }
}

import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import _ from "lodash";
import { Calculation, ControlOperator, DataPointOperand, EmissionFactorOperand, FixedNumberOperand, Operand, Operator } from "../../model/CalculationBuilderModels";
import { TaxonomyAttributeFe } from "src/app/model/taxonomy/TaxonomyAttributeFe";
import { AddDatapointDialogComponent } from "../../../add-datapoint-dialog/add-datapoint-dialog.component";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { UnitFe } from "src/app/components/unit-systems/model/UnitFe";
import { UnitsByMeasurementType } from "src/app/components/unit-systems/model/UnitsByMeasurementType";
import { groupUnitsByMeasurementTypeAndSystem } from "src/app/components/unit-systems/model/utils";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe";
import { DatapointDatatype } from "src/app/model/taxonomy/DatapointDatatypeFe";
import { AbstractEmissionFactorFe } from "src/app/model/emissions/AbstractEmissionFactorFe";

@Component({
  selector: "formula-operand-dropdown",
  templateUrl: "./formula-operand-dropdown.component.html",
  styleUrls: ["./formula-operand-dropdown.component.scss"]
})
export class FormulaOperandDropdownComponent extends AbstractLanguageComponent implements OnInit {
  @Input() showDatapoints: boolean = true;
  @Input() selectedDatapoint: TaxonomyAttributeFe;
  @Input() selectedDataCategory;
  @Input() tokenIdx: String;
  @Input() token: any;
  @Input() calculation: Calculation;
  @Input() showAddFixedNumberInput: Boolean = false;
  @Input() addFixedNumberValue: String;
  @Input() addFixedNumberUnit: UnitFe;
  @Input() showAddNewDataPointBtn = true;
  @Input() includeMeasurementTypes: Set<any> = new Set();
  unitInput = { selectedUnit: {} };

  @ViewChild(`addDatapointDialog`, { static: false })
  addDatapointDialog: AddDatapointDialogComponent;
  units: UnitFe[] = [];
  customUnits: UnitFe[] = [];
  unitsByMeasurementType: UnitsByMeasurementType[] = [];
  otherDatapointsFilter: string;

  taxonomies: {
    depTaxonomy: TaxonomyInfoFe;
    newTaxonomy: TaxonomyInfoFe;
  };

  constructor(private stateService: StateServiceFe, public languageService: LanguageService, private modalRef: BsModalRef, private modalService: BsModalService) {
    super(languageService);
    this.stateService.unitsUpdated.subscribe(async (units) => {
      await this.loadUnits();
    });
  }

  async ngOnInit() {
    await this.loadUnits();
    this.taxonomies = await this.stateService.getTaxonomyInfos();
  }

  async loadUnits() {
    this.units = await this.stateService.getUnits();
    const unitsByMeasurementType = groupUnitsByMeasurementTypeAndSystem(this.units.filter((unit) => unit.shouldDisplay));
    this.unitsByMeasurementType = unitsByMeasurementType;
    this.unitInput.selectedUnit = this.addFixedNumberUnit ?? {};
    this.customUnits = this.units.filter((unit) => unit.isCustom && !unit.isStandard && unit.shouldDisplay);
  }

  openModal(modalTemplateRef: TemplateRef<any> | string, className: string = "modal-xl") {
    let config = {
      backdrop: true,
      ignoreBackdropClick: false,
      class: className
    };
    this.modalRef = this.modalService.show(modalTemplateRef, config);
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id);
    document.body.classList.remove("modal-open");
  }

  getOtherDatapoints(datapoint: TaxonomyAttributeFe) {
    const includeDatatypes = [ "NUMERIC", "EMISSION_FACTOR" ]
    let otherDatapoints = this.selectedDataCategory.level_3.columns.filter((column) => column.key != datapoint.key && includeDatatypes.includes(column.datatype));

    const otherDatapointsFilter = this.otherDatapointsFilter;
    if (!_.isEmpty(otherDatapointsFilter)) {
      otherDatapoints = otherDatapoints.filter((datapoint) => {
        let labelFound = false;
        Object.keys(datapoint.label).forEach((key) => {
          const label = datapoint.label[key];
          if (label.toLowerCase().includes(otherDatapointsFilter.toLowerCase())) {
            labelFound = true;
          }
        });
        return labelFound;
      });
    }

    return otherDatapoints;
  }

  setDatapointOperand({ tokenIdx, datapoint }) {
    const token: Operand = new DataPointOperand(datapoint);
    this.calculation.formula[tokenIdx] = token;
    const nextToken = this.calculation.formula[tokenIdx + 1];
    if (_.isEmpty(nextToken)) {
      const controlToken = new ControlOperator();
      if (tokenIdx == 0 || tokenIdx == this.calculation.formula.length - 1) {
        this.calculation.formula.push(controlToken);
        return;
      }
    }
  }

  startAddNewDatapoint({ tokenIdx }) {
    this.addDatapointDialog.open();
  }

  addControlBefore({ tokenIdx }) {
    const token: Operator = new ControlOperator();
    if (tokenIdx == 0) {
      this.calculation.formula.unshift(token);
      return;
    }
    this.calculation.formula.splice(tokenIdx, 0, token);
  }

  addControlAfter({ tokenIdx }) {
    const token: Operator = new ControlOperator();
    if (tokenIdx == this.calculation.formula.length - 1) {
      this.calculation.formula.push(token);
      return;
    }
    this.calculation.formula.splice(tokenIdx + 1, 0, token);
  }

  removeToken({ tokenIdx }) {
    this.calculation.formula.splice(tokenIdx, 1);
  }

  addFixedNumber({ tokenIdx }) {
    const value = this.addFixedNumberValue;
    const unit = this.unitInput.selectedUnit;

    if (!_.isUndefined(value) && !_.isNull(value)) {
      const token: Operand = new FixedNumberOperand({ value, unit });
      this.calculation.formula[tokenIdx] = token;

      const nextToken = this.calculation.formula[tokenIdx + 1];
      if (_.isEmpty(nextToken)) {
        const controlToken = new ControlOperator();
        if (tokenIdx == 0 || tokenIdx == this.calculation.formula.length - 1) {
          this.calculation.formula.push(controlToken);
          return;
        }
      }
    }
  }

  startAddFixedNumber() {
    this.showAddFixedNumberInput = true;
  }

  getLabel({ datapoint }): string {
    const langCode = this.languageService.getDisplayActiveLanguage();
    const unit = datapoint.unit && datapoint.unit.symbol ? ` (${datapoint.unit.symbol})` : "";
    const label = datapoint.label[langCode] || datapoint.label["en"];
    return `${label} ${unit}`;
  }
  
  clearOtherDatapointsFilter() {
    this.otherDatapointsFilter = "";
  }

  addEmissionFactor({ emissionFactor, tokenIdx }) {
    const token: Operand = new EmissionFactorOperand({ emissionFactor });
    this.calculation.formula[tokenIdx] = token;
    const nextToken = this.calculation.formula[tokenIdx + 1];
    if (_.isEmpty(nextToken)) {
      const controlToken = new ControlOperator();
      if (tokenIdx == 0 || tokenIdx == this.calculation.formula.length - 1) {
        this.calculation.formula.push(controlToken);
        return;
      }
    }
  }

  resolveLabel({ token, deployed }) {
    let datapoint = token.datapoint;

    if (deployed) {
      this.taxonomies?.depTaxonomy?.entities?.forEach((entity) => {
        if (entity.key == this.selectedDataCategory?.level_3?.key) {
          entity.columns.forEach((column) => {
            if (column.key == token.datapoint?.key) {
              datapoint = column;
            }
          });
        }
      });
    } else {
      this.taxonomies?.newTaxonomy?.entities?.forEach((entity) => {
        if (entity.key == this.selectedDataCategory?.level_3?.key) {
          entity.columns.forEach((column) => {
            if (column.key == token.datapoint?.key) {
              datapoint = column;
            }
          });
        }
      });
    }

    let result = "";

    if (datapoint && datapoint.label && datapoint.label.hasOwnProperty(this.languageService.getDisplayActiveLanguage())) {
      result = datapoint.label[this.languageService.getDisplayActiveLanguage()];
    }

    if (result) {
      return result;
    }

    return datapoint?.label["en"] ?? "";
  }

  resolveUnit({ token, deployed }) {
    let datapoint = token.datapoint;

    if (deployed) {
      this.taxonomies?.depTaxonomy?.entities?.forEach((entity) => {
        if (entity.key == this.selectedDataCategory?.level_3?.key) {
          entity.columns.forEach((column) => {
            if (column.key == token.datapoint?.key) {
              datapoint = column;
            }
          });
        }
      });
    } else {
      this.taxonomies?.newTaxonomy?.entities?.forEach((entity) => {
        if (entity.key == this.selectedDataCategory?.level_3?.key) {
          entity.columns.forEach((column) => {
            if (column.key == token.datapoint?.key) {
              datapoint = column;
            }
          });
        }
      });
    }

    let unit = "";
    if(datapoint.datatype == DatapointDatatype.EMISSION_FACTOR){
      const ef = (datapoint.emissionFactors[0] || {value:datapoint.emissionFactor} || { value:{} }).value as AbstractEmissionFactorFe;
      
      let sourceUnit = `${ef?.sourceUnit}`;
      if(sourceUnit.includes("/")){
        sourceUnit = `(${sourceUnit})`
      }

      let conversionUnit = `${ef?.conversionUnit}`
      if(conversionUnit.includes("/")){
        conversionUnit = `(${conversionUnit})`
      }
      
      unit = `${conversionUnit}/${sourceUnit}`;
    }
    
    if(datapoint.datatype != DatapointDatatype.EMISSION_FACTOR){
      let symbol = `${datapoint?.unit?.symbol ?? ""}`;
      if(symbol.includes("/")){
        symbol = `(${symbol})`;
      }
      
      unit = symbol;
    }

    return unit;
  }

}

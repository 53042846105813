import { Component, OnInit, Input, Output, HostListener, ChangeDetectorRef, EventEmitter, ViewChild, TemplateRef } from "@angular/core"
import { StateServiceFe } from "src/app/services/StateServiceFe"
import { DateUtil } from "src/app/utils/DateUtil"
import { QuestionFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionFe"
import { Subject, Subscription } from "rxjs"
import { QuestionDataTableFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionDataTableFe"
import { RequestFe } from "src/app/model/data-suppliers/request/RequestFe"
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe"
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent"
import { LanguageService } from "src/app/services/LanguageServiceFe"
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize"
import { ResponsiveService } from "src/app/services/ResponsiveService"
import { RequestServiceFe } from "src/app/services/RequestServiceFe"
import { SectionFe } from "src/app/model/data-suppliers/request/questionnaire/SectionFe"
import _ from "lodash"
import { RequestGroup_UpdateFe } from "src/app/model/data-suppliers/request/RequestGroup_UpdateFe"
import { QuestionnaireFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionnaireFe"
import { QuestionDateFe } from "src/app/model/data-suppliers/request/questionnaire/QuestionDateFe"
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe"
import { CsrdDefintionService } from "../../projects/csrd/CsrdDefintionService"
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal"

@Component({
  selector: "questionnaire-preview",
  templateUrl: "./questionnaire-preview.component.html",
  styleUrls: ["./questionaire-creator-full-page.component.scss", "../data-suppliers.component.scss"],
})
export class QuestionnairePreviewComponent extends AbstractLanguageComponent implements OnInit {
  loadingInfo = false
  loadingInProgress = false

  @Input() requestTitle: string
  @Input() request: RequestFe
  @Input() questionnaire: QuestionnaireFe
  @Input() showMapping: boolean = true
  @Input() showHeader = true
  @Input() showFooter = true
  @Input() allExpanded: boolean;
  @Input() searchText: string;
  @Input() expandCollapseEvent: EventEmitter<boolean>;

  @Output() closeEvent: Subject<boolean> = new Subject()
  @Output() accordionStateChange = new EventEmitter<boolean>();

  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  editMapping: boolean = false
  mappingEdited = false
  selectedMainQuestion: QuestionFe
  taxonomyInfo: TaxonomyInfoFe
  toConfirmUpdate = false
  sections: SectionFe[]
  selectedSection: SectionFe | null = null
  shown: { [key: string]: boolean } = {}
  detailExpanded: { [key: string]: boolean } = {}
  subtreeExpanded: { [key: string]: boolean } = {}
  subscription: Subscription = new Subscription();
  private accordionSubscription: Subscription = new Subscription();
  questionsRefNumber: Map<string, string> = new Map()
  csrdSidePanel=false;

  subscriptionDr: Subscription;
  activeDr: any;
  isCSRDInfoTabOpen=false;
  isEditMode: boolean[] = [];   
  isCSRDProjectPage: boolean = true

  constructor(
    private modalRef: BsModalRef,
    private modalService: BsModalService,    
    public stateService: StateServiceFe,
    private responsive: ResponsiveService,
    public requestService: RequestServiceFe,
    private displayService: DisplayServiceFe,
    languageService: LanguageService,
    public csrdDefintionService: CsrdDefintionService,
    private cd: ChangeDetectorRef
  ) {
    super(languageService)
    stateService.workspaceSubject.subscribe((activeCompany) => {
      this.loadCompaniesTaxonomy()
    })
    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
  }

  async ngOnInit(): Promise<void> {
    this.renderNewData()
    this.loadCompaniesTaxonomy()
    this.expandCollapseEvent.subscribe((expanded: boolean) => {
      this.expandCollapseAll(expanded);
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.accordionSubscription.unsubscribe();
  }

  async renderNewData() {
    this.loadingInfo = true
    let questionnaire = this.questionnaire ? this.questionnaire : this.request.questionnaire
    this.questionsRefNumber = questionnaire.getQuestionReferenceNumbers()
    this.sections = _.cloneDeep(questionnaire.sections)
    this.sections.forEach((section) => {
      section.questions.forEach((q) => {
        if (q instanceof QuestionDataTableFe) {
          q.setRequestDataGridService()
        }
        if (q.mappedToColKeys && section.entity) {
          q.setMappedToCols(section.entity)
        }
        if (q instanceof QuestionDateFe && q.mappedToColKeysRange) {
          q.setMappedToColsRange(section.entity)
        }
        q.confirmMapping()
      })
      section.editTaxonomy = false
    })
    this.loadingInfo = false
  }

  async loadCompaniesTaxonomy() {
    let { depTaxonomy, newTaxonomy } = await this.stateService.getTaxonomyInfos()
    this.taxonomyInfo = depTaxonomy
  }

  close() {
    this.closeEvent.next(true)
  }

  // Questionnaire functionality
  showMenu(event: any) {
    let id = event.currentTarget.id
    let idNumber = id.split("-")[1]
    let menuBtn = document.getElementById(id)
    let menu = menuBtn.nextElementSibling
    let chevron = menuBtn.children[2]
    menu.classList.toggle("show")
    if (chevron.classList.contains("la-angle-down")) {
      chevron.classList.remove("la-angle-down")
      chevron.classList.add("la-angle-up")
    } else {
      chevron.classList.remove("la-angle-up")
      chevron.classList.add("la-angle-down")
    }
  }

  dateToString(date: any) {
    date = new Date(date)
    return DateUtil.toString3(date)
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id)
    document.body.classList.remove("modal-open")
  }  

  async updateMapping() {
    let questionnaire = new QuestionnaireFe(this.sections, this.request.questionnaire.autoConnect, this.request.id)
    let update = new RequestGroup_UpdateFe(this.request.requestGroupId, questionnaire)
    await this.stateService.updateRequestGroupQuestionnaire(update)
  }

  selectMainQuestion(section: SectionFe, mainQuestion: QuestionFe) {
    this.selectedSection = section
    this.selectedMainQuestion = mainQuestion

    // Expand the selected question to show its children
    this.shown[mainQuestion.id] = true
    this.detailExpanded[mainQuestion.id] = false
    this.subtreeExpanded[mainQuestion.id] = true

    this.collapseAndHideSiblings(mainQuestion)
    this.showChildrenCollapsed(mainQuestion)
  }

  backToMainQuestions() {
    this.selectedSection = null
    this.selectedMainQuestion = null
  }

  findQuestionById(id: string): QuestionFe | null {
    for (const section of this.sections) {
      for (const question of section.questions) {
        if (question.id === id) {
          return question
        }
        const followUp = this.findInFollowUps(question, id)
        if (followUp) {
          return followUp
        }
      }
    }
    return null
  }

  findInFollowUps(question: QuestionFe, id: string): QuestionFe | null {
    if (question.id === id) {
      return question
    }
    const children = this.selectedSection.getChildrenOfParent(question)
    for (const followUp of children) {
      const result = this.findInFollowUps(followUp, id)
      if (result) {
        return result
      }
    }
    return null
  }
  
  toggleDetailExpansion(question: QuestionFe) {
    this.detailExpanded[question.id] = !this.detailExpanded[question.id]
  }

  toggleFollowUpQuestions(question: QuestionFe) {
    if (this.subtreeExpanded[question.id]) {
      this.hideFollowupQuestions(question)
    } else {
      this.showFollowupQuestions(question)
    }
  }

  hideFollowupQuestions(question: QuestionFe) {
    this.subtreeExpanded[question.id] = false
    this.collapseAndHideChildrenSbtrees(question)
    this.showSiblingsCollapsed(question)
  }

  showFollowupQuestions(question: QuestionFe) {
    this.subtreeExpanded[question.id] = true    
    this.collapseAndHideSiblings(question)
    this.showChildrenCollapsed(question)
  }

  getAllFollowUpQuestions(question: QuestionFe): QuestionFe[] {
    let allQuestions: QuestionFe[] = []
    const directChildren = this.selectedSection.getChildrenOfParent(question)

    directChildren.forEach((child) => {
      allQuestions.push(child)
      allQuestions = allQuestions.concat(this.getAllFollowUpQuestions(child))
    })

    return allQuestions
  }

  collapseAndHideSiblings(question: QuestionFe) {
    const siblings = this.selectedSection.getSiblings(question)
    siblings.forEach(sibling => {
      this.collapseAndHideSubtree(sibling)
    })
  }

  showSiblingsCollapsed(question: QuestionFe) {
    const siblings = this.selectedSection.getSiblings(question)
    siblings.forEach(sibling => {
      this.shown[sibling.id] = true 
      this.subtreeExpanded[sibling.id] = false
    })    
  }

  collapseAndHideChildrenSbtrees(question: QuestionFe) {
    const children = this.selectedSection.getChildrenOfParent(question)
    children.forEach(child => {
      this.collapseAndHideSubtree(child)
    })        
  }

  showChildrenCollapsed(question: QuestionFe) {
    const children = this.selectedSection.getChildrenOfParent(question)
    children.forEach(child => {
      this.shown[child.id] = true 
      this.subtreeExpanded[child.id] = false
    })    
  }

  collapseAndHideSubtree(question: QuestionFe) {
    const children = this.selectedSection.getChildrenOfParent(question)
    children.forEach(child => {
      this.shown[child.id] = false
      this.subtreeExpanded[child.id] = false
      this.collapseAndHideSubtree(child)
    })
    this.shown[question.id] = false
    this.subtreeExpanded[question.id] = false
  }

  hasFollowUpQuestions(question: QuestionFe): boolean {
    return this.selectedSection.getChildrenOfParent(question).length > 0
  }

  getQuestionLevel(question: QuestionFe): number {
    let level = 0
    let currentQuestion = question

    while (currentQuestion.parentQuestionId) {
      level++
      currentQuestion = this.findQuestionById(currentQuestion.parentQuestionId)
    }

    return level
  }

  getIndentation(level: number): string {
    const baseIndent = 20
    const maxLevel = 5
    return `${Math.min(level, maxLevel) * baseIndent}px`
  }

  renderQuestionOptions(question: QuestionFe): string {
    let html = ""

    if (this.requestService.isTextQues(question)) {
      html += `
        <div class="col mt-1 mx-0 px-0">
          <textarea class="form-control w-50" rows="3" placeholder="Write answer" disabled></textarea>
        </div>
      `
    } else if (this.requestService.isEmissionFactorQues(question)) {
      html += `
        <div class="row w-50">
          <div class="w-50 ms-0 me-4">
            <emission-factor isDisabled="true"></emission-factor>
          </div>
        </div>
      `
    } else if (this.requestService.isDateQues(question)) {
      html += `
        <div class="row w-50">
          <div class="col-12">
            <input type="date" class="form-control" placeholder="12/12/2022" disabled />
          </div>
          ${
            question["range"]
              ? `
            <div class="col-12 ml-1">
              <p class="mb-2">${this.locale("locale_key.pages.task_portal.answer_wizard.date.options.range.to")}</p>
            </div>
            <div class="col-12">
              <input type="date" class="form-control" placeholder="12/12/2022" disabled />
            </div>
          `
              : ""
          }
        </div>
      `
    } else if (this.requestService.isNumberQues(question)) {
      html += `
        <div class="row w-50">
          <div class="col-6 ms-0 ps-3">
            <input type="text" class="form-control" placeholder="value" disabled />
          </div>
          <div class="col-6 ms-0 ps-3">
            <p>${question.answer}</p>
          </div>
        </div>
      `
    } else if (this.requestService.isAttachmentQues(question)) {
      html += `
        <div class="row w-50">
          <div class="col-12 m-1 ps-3">
            <button class="btn primary-button" disabled>${this.locale("locale_key.pages.data_request.review_answer_wizard.file.button.select_file")}</button>
          </div>
        </div>
      `
    } else if (this.requestService.isDataTableQues(question)) {
      html += `
        <div class="row">
          <div class="col-12 ms-0 ps-3">
            <datagrid-table-editor [dataGrid]="question.requestItemDataGridService"></datagrid-table-editor>
          </div>
          <div class="col-12 m-1 ps-3">
            <button class="btn primary-button" disabled>${this.locale("locale_key.general.buttons.extract_from_file")}</button>
          </div>
        </div>
      `
    } else if (this.requestService.isMultipleChoiceQues(question) || this.requestService.isSingleChoiceQues(question)) {
      const inputType = this.requestService.isMultipleChoiceQues(question) ? "checkbox" : "radio"
      html += `
        <div>
          ${question["options"]
            .map(
              (option, index) => `
            <div class="row me-auto mx-1">
              <a id="btnMenu-${index}" class="dropdown" (click)="this.querySelector('.dropdown-menu').classList.toggle('show')">
                <input type="${inputType}" class="me-3 d-inline mp-50" style="position: relative; bottom: 1px" ${inputType === "radio" ? `value="${option.id}"` : ""} disabled />
                <p class="d-inline">${option.value}</p>
                ${
                  option.desc
                    ? `
                  <i class="ms-2 la la-angle-down d-inline" id="chev-${index}"></i>
                  <div class="dropdown-menu position-relative dropdown-demo px-1">
                    ${option.desc}
                  </div>
                `
                    : ""
                }
              </a>
            </div>
          `
            )
            .join("")}
        </div>
      `
    }

    if (question.comments) {
      html += `
        <div class="col mt-1 ms-0 ps-0">
          <label class="mt-1">${this.locale("locale_key.modal.questionnaire_preview.comments")}</label>
          <textarea class="form-control w-50" rows="1" disabled></textarea>
        </div>
      `
    }

    return html
  }

  expandCollapseAll(expanded: boolean) {
    this.allExpanded = expanded;
    this.sections.forEach((section, index) => {
        const accordionButton = document.getElementById(`section${index}Preview`);
        const accordionContent = document.getElementById(`sectionAccordion${index}Preview`);
        if (accordionButton && accordionContent) {
          if (this.allExpanded) {
            accordionButton.classList.remove('collapsed');
            accordionButton.setAttribute('aria-expanded', 'true');
            accordionContent.classList.add('show');
          } else {
            accordionButton.classList.add('collapsed');
            accordionButton.setAttribute('aria-expanded', 'false');
            accordionContent.classList.remove('show');
          }
        }
      });
    
      this.accordionStateChange.emit(this.allExpanded);
      this.cd.detectChanges();
    }

  get filteredQuestions(): QuestionFe[] {
    if (this.isCSRDProjectPage) {
      return this.getFilteredCSRDQuestions();
    } else {
      return this.getFilteredNonCSRDQuestions();
    }
  }

  private getFilteredCSRDQuestions(): QuestionFe[] {
    if (!this.searchText) {
      // If no search text, return all questions from all sections
      return this.sections.flatMap(section => section.questions);
    }

    const lowerCaseSearchText = this.searchText.toLowerCase().trim();

    return this.sections.flatMap(section => 
      section.questions.filter(question => 
        question.question.toLowerCase().includes(lowerCaseSearchText) ||
        (question.titleNumber && question.titleNumber.toLowerCase().includes(lowerCaseSearchText))
      )
    );
  }

  private getFilteredNonCSRDQuestions(): QuestionFe[] {
    if (!this.searchText) {
      // If no search text, return all questions from all sections
      return this.sections.flatMap(section => section.getQuestionsWithoutParent());
    }

    const lowerCaseSearchText = this.searchText.toLowerCase().trim();

    const matchingQuestions: QuestionFe[] = [];

    this.sections.forEach(section => {
      const questions = section.getQuestionsWithoutParent();
      const filteredQuestions = questions.filter(question => 
        question.question.toLowerCase().includes(lowerCaseSearchText) ||
        (question.titleNumber && question.titleNumber.toLowerCase().includes(lowerCaseSearchText))
      );
      matchingQuestions.push(...filteredQuestions);
    });

    return matchingQuestions;
  }
  
  getFilteredQuestionsForSection(section: any): QuestionFe[] {
    if (!this.searchText) {
      return this.isCSRDProjectPage ? section.questions : section.getQuestionsWithoutParent();
    }

    const lowerCaseSearchText = this.searchText.toLowerCase().trim();

    const questionsToFilter = this.isCSRDProjectPage ? section.questions : section.getQuestionsWithoutParent();

    return questionsToFilter.filter(question => 
      question.question.toLowerCase().includes(lowerCaseSearchText) ||
      (question.titleNumber && question.titleNumber.toLowerCase().includes(lowerCaseSearchText))
    );
  }

  toggleEditMode(index: number) {
    this.isEditMode[index] = !this.isEditMode[index];

  }

  saveTitle(index: number) {
    this.isEditMode[index] = false;
  }

  cancelEditTitle(index: number) {
    this.isEditMode[index] = false;
  }
  updateInputWidth(event: any) {
    const input = event.target;
    input.style.width = `${input.value.length + 1}ch`;
  }
  
  stopImmediatePropagation(event:Event) {
    event.stopPropagation();
    event.preventDefault();
  }

  toggleCSRDInfo(question: QuestionFe) {
    this.csrdSidePanel = !this.csrdSidePanel;
    if(this.csrdSidePanel){
    const csrdDatapoint = this.csrdDefintionService.getCsrdDatapointById(question.csrdDatapointId)
    const csrdDatapointSettings = this.csrdDefintionService.getCsrdDatapointSettings(question.csrdDatapointId)
    this.displayService.openCSRDInfoTab(csrdDatapoint, csrdDatapointSettings);
  }else {
    this.displayService.closeCSRDInfoTab()
  }
}

}

import { Injectable } from "@angular/core";
import e1_1 from './questionnaire/e1_1.json';
import e1_2 from './questionnaire/e1_2.json';
import e1_3 from './questionnaire/e1_3.json';
import e1_4 from './questionnaire/e1_4.json';
import e1_5 from './questionnaire/e1_5.json';
import e1_6 from './questionnaire/e1_6.json';
import e1_7 from './questionnaire/e1_7.json';
import e1_8 from './questionnaire/e1_8.json';
import g1_1 from './questionnaire/g1_1.json';
import g1_2 from './questionnaire/g1_2.json';
import g1_3 from './questionnaire/g1_3.json';
import g1_4 from './questionnaire/g1_4.json';
import g1_5 from './questionnaire/g1_5.json';
import g1_6 from './questionnaire/g1_6.json';
import gov_1___g1 from './questionnaire/gov_1___g1.json';
import gov_3___e1 from './questionnaire/gov_3___e1.json';
import iro_1___e1 from './questionnaire/iro_1___e1.json';
import iro_2___e1 from './questionnaire/iro_2___e1.json';
import iro_2___g1 from './questionnaire/iro_2___g1.json';
import sbm_3___e1 from './questionnaire/sbm_3___e1.json';
import csrd_topics from './csrd_definition.json';
import { BehaviorSubject, Observable } from "rxjs";
import { CsrdTopicFe } from "./model/CsrdTopicFe";
import { CsrdDatapointFe } from "./model/CsrdDatapointFe";
import { CSRDDataPointSettings, CSRDProjectInfoFe } from "src/app/model/project/CSRDProjectInfoFe";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { AbstractProjectInfoFe } from "src/app/model/project/AbstractProjectInfoFe";
import { CsrdQuestionnaireFe } from "src/app/model/data-suppliers/request/csrd/CSRDQuestionnaireFe";

interface ActiveDR {
  code: string;
  name: string;
}

@Injectable({
  providedIn: "root"
})
export class CsrdDefintionService {
  private activeDRSubject: BehaviorSubject<ActiveDR | null> = new BehaviorSubject<ActiveDR | null>(null);
  public activeDR$: Observable<ActiveDR | null> = this.activeDRSubject.asObservable();
  drCache: Map<string, CsrdQuestionnaireFe> = new Map()
  csrdTopics: CsrdTopicFe[]
  csrdDatapointCache: Map<string, CsrdDatapointFe> = new Map()
  csrdDatapointSettingsCache: Map<string, CSRDDataPointSettings> = new Map()

  constructor(private stateService: StateServiceFe) {
    this.drCache.set('e1_1', CsrdQuestionnaireFe.fromCsrdDefinitionJson(e1_1));
    this.drCache.set('e1_2', CsrdQuestionnaireFe.fromCsrdDefinitionJson(e1_2));
    this.drCache.set('e1_3', CsrdQuestionnaireFe.fromCsrdDefinitionJson(e1_3));
    this.drCache.set('e1_4', CsrdQuestionnaireFe.fromCsrdDefinitionJson(e1_4));
    this.drCache.set('e1_5', CsrdQuestionnaireFe.fromCsrdDefinitionJson(e1_5));
    this.drCache.set('e1_6', CsrdQuestionnaireFe.fromCsrdDefinitionJson(e1_6));
    this.drCache.set('e1_7', CsrdQuestionnaireFe.fromCsrdDefinitionJson(e1_7));
    this.drCache.set('e1_8', CsrdQuestionnaireFe.fromCsrdDefinitionJson(e1_8));
    this.drCache.set('g1_1', CsrdQuestionnaireFe.fromCsrdDefinitionJson(g1_1));
    this.drCache.set('g1_2', CsrdQuestionnaireFe.fromCsrdDefinitionJson(g1_2));
    this.drCache.set('g1_3', CsrdQuestionnaireFe.fromCsrdDefinitionJson(g1_3));
    this.drCache.set('g1_4', CsrdQuestionnaireFe.fromCsrdDefinitionJson(g1_4));
    this.drCache.set('g1_5', CsrdQuestionnaireFe.fromCsrdDefinitionJson(g1_5));
    this.drCache.set('g1_6', CsrdQuestionnaireFe.fromCsrdDefinitionJson(g1_6));
    this.drCache.set('gov_1___g1', CsrdQuestionnaireFe.fromCsrdDefinitionJson(gov_1___g1));
    this.drCache.set('gov_3___e1', CsrdQuestionnaireFe.fromCsrdDefinitionJson(gov_3___e1));
    this.drCache.set('iro_1___e1', CsrdQuestionnaireFe.fromCsrdDefinitionJson(iro_1___e1));
    this.drCache.set('iro_2___e1', CsrdQuestionnaireFe.fromCsrdDefinitionJson(iro_2___e1));
    this.drCache.set('iro_2___g1', CsrdQuestionnaireFe.fromCsrdDefinitionJson(iro_2___g1));
    this.drCache.set('sbm_3___e1', CsrdQuestionnaireFe.fromCsrdDefinitionJson(sbm_3___e1));
    
    this.csrdTopics = csrd_topics.map(topic => CsrdTopicFe.fromJson(topic))
    
    const csrdDatapoints = this.csrdTopics.forEach(topic => 
      topic.esrs.forEach(e => 
        e.disclosures.forEach(dps => dps.csrdDatapoints.forEach(dp => this.csrdDatapointCache.set(dp.id, dp)))
      )
    );
    this.updateDatapointSettings(stateService.activeProject)

    stateService.activeProjectSubject.subscribe((activeProject: AbstractProjectInfoFe) => {
      this.updateDatapointSettings(activeProject)
    })
  }

  updateDatapointSettings(activeProject: AbstractProjectInfoFe) {
    if (activeProject && activeProject instanceof CSRDProjectInfoFe) {
      let csrdProject: CSRDProjectInfoFe = activeProject as CSRDProjectInfoFe;
      
      for (let topic of csrdProject.definition.csrdSettings) {
        for (let esrs of topic.esrs) {
          for (let disclosure of esrs.disclosures) {
            for (let datapoint of disclosure.csrdDatapoints) {
              this.csrdDatapointSettingsCache.set(datapoint.id, datapoint)
            }
          }
        } 
      }
    } else {
      this.csrdDatapointSettingsCache.clear()
    }
  }

  hasQuestionnaire(drId: string): boolean {
    return this.drCache.get(drId) ? true : false
  }

  getQuestionnaire(drId: string): CsrdQuestionnaireFe | null {
    return this.drCache.get(drId)
  }

  setActiveDR(code: string, name: string) {
    this.activeDRSubject.next({ code, name });
  }

  getActiveDR(): ActiveDR | null {
    return this.activeDRSubject.getValue();
  }

  getCsrdDatapointById(csrdDatapointId: string): CsrdDatapointFe | null {
    return this.csrdDatapointCache.get(csrdDatapointId)
  }     

  getCsrdDatapointSettings(csrdDatapointId: string): CSRDDataPointSettings | null {
    let setting = this.csrdDatapointSettingsCache.get(csrdDatapointId)
    return setting
  }
  
}

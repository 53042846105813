import { DatePipe, DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, QueryList, Renderer2, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import _ from 'lodash';
import { parser } from 'mathjs';
import moment from 'moment';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { TwoOperandStatementFe } from "src/app/model/data-suppliers/request/questionnaire/condition/TwoOperandStatementFe";
import { VariableStatementFe } from "src/app/model/data-suppliers/request/questionnaire/condition/VariableStatementFe";
import { TwoOperandOperatorFe } from "src/app/model/data-suppliers/request/questionnaire/condition/TwoOperandOperatorFe";
import { BooleanValueStatementFe } from "src/app/model/data-suppliers/request/questionnaire/condition/BooleanValueStatementFe";
import { RequestFe } from 'src/app/model/data-suppliers/request/RequestFe';
import { RequestGroupFe } from 'src/app/model/data-suppliers/request/RequestGroupFe';
import { RequestGroup_AddFe } from 'src/app/model/data-suppliers/request/add/RequestGroup_AddFe';
import { Request_AddFe } from 'src/app/model/data-suppliers/request/add/Request_AddFe';
import { QuestionAttachmentFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionAttachmentFe';
import { QuestionChoiceOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionChoiceOptionFe';
import { QuestionDataTableFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionDataTableFe';
import { QuestionDateFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionDateFe';
import { QuestionEmissionFactorFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionEmissionFactorFe';
import { QuestionFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionFe';
import { QuestionMultipleChoiceFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionMultipleChoiceFe';
import { QuestionNumberFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionNumberFe';
import { QuestionSingleChoiceFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionSingleChoiceFe';
import { QuestionTextFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionTextFe';
import { QuestionTypeConversion } from 'src/app/model/data-suppliers/request/questionnaire/QuestionTypeConversionFe';
import { QUESTION_TYPE } from 'src/app/model/data-suppliers/request/questionnaire/QuestionTypeFe';
import { QuestionnaireFe } from 'src/app/model/data-suppliers/request/questionnaire/QuestionnaireFe';
import { SectionFe } from 'src/app/model/data-suppliers/request/questionnaire/SectionFe';
import { BooleanStatementFe } from 'src/app/model/data-suppliers/request/questionnaire/condition/BooleanStatementFe';
import { CustomRepeatScheduleFe } from 'src/app/model/data-suppliers/request/recurrence/CustomRepeatScheduleFe';
import { IntervalFe } from 'src/app/model/data-suppliers/request/recurrence/IntervalFe';
import { NamedOccurenceFe } from 'src/app/model/data-suppliers/request/recurrence/NamedOccurenceFe';
import { RecurrenceFe } from 'src/app/model/data-suppliers/request/recurrence/RecurrenceFe';
import { ReminderScheduleFe } from 'src/app/model/data-suppliers/request/recurrence/ReminderScheduleFe';
import { RepeatScheduleFe } from 'src/app/model/data-suppliers/request/recurrence/RepeatScheduleFe';
import { SchedulingOccurenceGeneratorFe, SchedulingOccurenceGeneratorFromTypes } from 'src/app/model/scheduling/SchedulingOccurenceGeneratorFe';
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize';
import { EntityFe } from 'src/app/model/taxonomy/EntityFe';
import { TaxonomyAttributeFe } from 'src/app/model/taxonomy/TaxonomyAttributeFe';
import { TaxonomyInfoFe } from 'src/app/model/taxonomy/TaxonomyInfoFe';
import { ContactFe } from 'src/app/model/user/ContactFe';
import { DataCategoryServiceFe } from 'src/app/services/DataCategoryServiceFe';
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe';
import { LanguageService } from 'src/app/services/LanguageServiceFe';
import { LoginServiceFe } from 'src/app/services/LoginServiceFe';
import { RequestServiceFe } from 'src/app/services/RequestServiceFe';
import { ResponsiveService } from 'src/app/services/ResponsiveService';
import { StateServiceFe } from 'src/app/services/StateServiceFe';
import { DateUtil } from 'src/app/utils/DateUtil';
import { IdUtil } from 'src/app/utils/IdUtil';
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent';
import { UnitFe } from '../../unit-systems/model/UnitFe';
import { groupUnitsByMeasurementTypeAndSystem } from '../../unit-systems/model/utils';
import { PredefinedDateOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/predefinedoption/PredefinedDateOptionFe';
import { PredefinedNumberOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/predefinedoption/PredefinedNumberOptionFe';
import { NoUnit } from '../../unit-systems/unit-selector/unit-selector.component';
import { PredefinedEmissionFactorOptionFe } from 'src/app/model/data-suppliers/request/questionnaire/predefinedoption/PredefinedEmissionFactorOptionFe';
import { DataGridTableComponent } from 'src/app/components/projects/data-grid-ui/table.component';

enum QuestionType {
  Text = 'text',
  Number = 'number',
  Date = 'date',
  DateRange = 'dateRange',
  EmissionFactor = 'emissionFactor'
}
@Component({
  selector: 'edit-questionnaire',
  templateUrl: './edit-questionnaire.component.html',
  styleUrls: [
    "./questionaire-creator-full-page.component.scss",
    "../data-suppliers.component.scss"
  ],
  providers: [{ provide: BsDropdownDirective }]
})

export class EditQuestionnaireRequestComponent extends AbstractLanguageComponent implements OnInit, AfterViewInit {
  @Input() mode: string = "add";
  @Input() reqGroupToBeDuplicated: RequestGroupFe;
  @Output() close = new Subject<boolean>();
  @Output() createdRequest = new EventEmitter<RequestGroupFe>();
  @Output() deletedSections = new EventEmitter<SectionFe[]>();
  @ViewChild("questionnairePreview", { static: true })
  questionnairePreview: TemplateRef<any>;
  @Input() isFromDraft = false;
  @Input () addQuestionAddSection = true;
  @ViewChild("confirmSendRequest") confirmSendRequest: TemplateRef<any>;
  @ViewChild("confirmSaveAsDraft") confirmSaveAsDraft: TemplateRef<any>;
  @ViewChild("confirmDeploy") confirmDeploy: TemplateRef<any>;
  @ViewChild("confirmDeleteQues") confirmDeleteQues: TemplateRef<any>;
  @ViewChildren('dataGrid') dataGrids: QueryList<DataGridTableComponent>;

  isFormView: boolean = false; // Default to table view
  placeholder: boolean = false;
  datePlaceholder: string = 'dd MMM, yyyy';

  form3 = new FormGroup({
    // deadline: new FormControl(null, [Validators.required]),
    dataConnection: new FormControl("autoConnect")
  });

  reqDesc: string = "";
  allDataOwners: ContactFe[] = [];
  filteredDataOwners: ContactFe[] = [];
  filterText: string = "";

  isCustomOptionAdded: boolean = false
  
  inProgress = true;
  taxonomyInfo: TaxonomyInfoFe = undefined;

  dataScopeMessage = this.locale(
    "locale_key.general.validation_message.data_category_required"
  );
  mappingMessage = this.locale(
    "locale_key.general.validation_message.mapping_required"
  );
  dataOwnerMessage = this.locale(
    "locale_key.general.validation_message.dataowner_required"
  );
  requestTitleMessage = this.locale(
    "locale_key.general.validation_message.request_title_required"
  );
  requestDeadlineMessage = this.locale(
    "locale_key.general.validation_message.request_deadline_required"
  );
  questionMessage = this.locale(
    "locale_key.general.validation_message.title_for_question_required"
  );
  choiceTitleMessage = this.locale(
    "locale_key.general.validation_message.title_for_option_required"
  );
  choiceDescMessage = this.locale(
    "locale_key.general.validation_message.description_required"
  );
  choiceLengthMessage = this.locale(
    "locale_key.general.validation_message.two_options_required"
  );
  unitMessage = this.locale(
    "locale_key.general.validation_message.unit_for_number_question_required"
  );
  dataFieldMessage = this.locale(
    "locale_key.general.validation_message.one_data_field_required"
  );
  followUpConditionMessage = this.locale(
    "locale_key.general.validation_message.follow_up_condition_required"
  );
  questionnaireMessage = this.locale(
    "locale_key.general.validation_message.incorrect_questionnaire"
  );
  questionnaireLengthMessage = this.locale(
    "locale_key.general.validation_message.one_question_required"
  );
  isDueDatePassedConfirmed = false;
  loadingInProgress = false;
  loadingInfo: any;

  @Input() showHeader = true
  @Input() sections: SectionFe[] = [];
  selectedDataOwner: ContactFe;
  editDOTable = true;
  selectedQuestion: any;
  selectedChildQuestion: any;
  questionHasFollowUp: any;
  parentQuestionIndex: number = -1;
  children: QuestionFe[] = [];

  initCacheInProgress: boolean;
  menuCollapsed: boolean;
  url: string = "";
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE;
  inviteMode;

  
  mapped: boolean = false;
  mergeSubmission: boolean = false;
  selectedEntityKey: EntityFe;

  units = [];
  customUnits = [];
  unitsByMeasurementType = [];
  selectedQuestionIndex: number;
  selectedSection: SectionFe;
  isQuestionnaireInvalid = false;
  isCSRDProjectPage: boolean = false
  subscription: Subscription | undefined;

  constructor(
    private fb: FormBuilder,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    @Inject(DOCUMENT) private _document: Document,
    public stateService: StateServiceFe,
    public displayService: DisplayServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    private loginService: LoginServiceFe,
    public categoryService: DataCategoryServiceFe,
    public requestService: RequestServiceFe,
    private cd: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {
    super(languageService);
    this.url = window.location.href;
    this.initCacheInProgress = this.stateService.initCacheInProgress;
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress;
    });

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed;
    });

    this.responsive.screenWidthSizeSubject.subscribe(
      (screenSize: ScreenWidthSizeFe) => {
        this.screenSize = screenSize;
      }
    );

    this.screenSize = responsive.currentScreenWidthSize;

    this.stateService.unitsUpdated.subscribe(async (units) => {
      await this.loadUnits();
    });
  }

  async ngOnInit(): Promise<void> {
    this.taxonomyInfo = await this.categoryService.getTaxonomy();
    this.setDatePlaceholder();
  }

  ngAfterViewInit(): void {
    this.setupDropdownScroll();
    if (this.expandCollapseEvent) {
      this.expandCollapseEvent.subscribe((expanded: boolean) => {
        this.expandCollapseAll(expanded);
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async loadUnits() {
    this.units = await this.stateService.getUnits();
    const unitsByMeasurementType = groupUnitsByMeasurementTypeAndSystem(
      this.units
    );
    this.unitsByMeasurementType = unitsByMeasurementType;
    this.customUnits = this.units.filter(
      (unit) => unit.isCustom && !unit.isStandard
    );
  }

  openModal(template: TemplateRef<any>, size: string) {
    this.modalRef = this.modalService.show(template, {
      class: size,
      backdrop: "static",
      keyboard: true
    });
  }

  closeModal() {
    this.modalService.hide(this.modalRef.id);
    document.body.classList.remove("modal-open");
  }

  setDescContent($event) {
    let newValue = $event.target.value;
    this.reqDesc = newValue;
  }

  getTaxonomyChildren(value: string) {
    return this.taxonomyInfo?.childrenSortedByOrdinal(value);
  }

  compareFn(item, selected) {
    return item.value === selected.value;
  }

  showMenu(id: string) {
    let menuBtn = document.getElementById(id);
    let menu = menuBtn.nextElementSibling;
    menu.classList.toggle("show");
    if (menuBtn.id === "btn-arrange") {
      menu.classList.toggle("hide");
    }
  }

  deleteDescription(option: QuestionChoiceOptionFe) {
    option.showDesc = false;
    option.desc = null;
  }

  deleteOption(options: QuestionChoiceOptionFe[], index: number) {
    options.splice(index, 1);
  }

  addOption(options: QuestionChoiceOptionFe[]) {
    let option = new QuestionChoiceOptionFe(IdUtil.next(), null, null);
    options.push(option);
  }

  changeDateRange(question: QuestionDateFe, isRange: boolean) {
    question.range = isRange;
  }

  //NEW CODE
  isParentMultipleChoiceQues(quesId: string, section: SectionFe) {
    let ques = section.questions.find((q) => q.id == quesId);
    return this.requestService.isMultipleChoiceQues(ques);
  }

  addNewQuestion(
    section: SectionFe,
    type: string,
    index: number = null,
    isFollowUpQues = false,
    parentQues: QuestionFe = null,
    isRangeQuestion?: boolean
  ) {
    if (index == null || index == undefined) {
      index = section.questions.length;
    }
    let question: QuestionFe = this.getNewQuestion(
      type,
      section,
      isRangeQuestion
    );
    question.isFollowUpQues = isFollowUpQues;
    question.isEditMode = true;
    if (isFollowUpQues) {
      question.followUpCondition = new BooleanStatementFe();
      question.parentQuestionId = parentQues.id;
      if (parentQues != null) {
        let parentQuestion = section.questions.find(
          (q) => q.id == parentQues.id
        );
        parentQuestion.hasFollowUpQuestion = true;
        parentQuestion.childrenNumber += 1;
        parentQues.isEditMode = false;
        const totalChildren = this.getTotalChildren(parentQues, section)
        index += totalChildren + 1;
      } else  {
        index += 1;
      }
      
    }
    section.questions.splice(index, 0, question);
    let menu = document.getElementById("addNewQuesMenu") as HTMLElement;
    let menuFollow = document.getElementById(
      "addNewQuesMenu-follow"
    ) as HTMLElement;
    menu?.classList.remove("show");
    menuFollow?.classList.remove("show");
    setTimeout(()=>{
      document.getElementById(`question${question.id}`)?.scrollIntoView({block: 'end'});
      this.enableEditMode(question, section)
    })
  }

  getTotalChildren(parentQuest: QuestionFe, section: SectionFe) {
    let total = 0;
    let childQuestions = section.questions.filter(q => q.parentQuestionId == parentQuest.id)
    total += childQuestions.length;
    childQuestions.forEach(ques => {
      total += this.getTotalChildren(ques, section)
    })
    return total;
  }

  getNewQuestion(
    type: string,
    section: SectionFe,
    isRangeQuestion?: boolean
  ): QuestionFe {
    let question: QuestionFe;
    switch (type) {
      case QUESTION_TYPE.ATTACHMENT:
        question = new QuestionAttachmentFe(
          IdUtil.next(),
          section.id,
          null,
          null,
          null,
          null,
          null,
          null,
          undefined
        );
        break;
      case QUESTION_TYPE.DATA_TABLE:
        question = new QuestionDataTableFe(
          IdUtil.next(),
          section.id,
          null,
          null,
          null,
          null,
          null,
          null,
          [],
          [],
          null,
          null,
          undefined
        );
        (question as QuestionDataTableFe).taxonomyInfo = this.taxonomyInfo;
        break;
      case QUESTION_TYPE.SINGLE_CHOICE:
        question = new QuestionSingleChoiceFe(
          IdUtil.next(),
          section.id,
          null,
          null,
          null,
          null,
          null,
          null,
          [],
          null,
          undefined
        );
        (question as QuestionSingleChoiceFe).adjustNewQuestion();
        break;
      case QUESTION_TYPE.MULTIPLE_CHOICE:
        question = new QuestionMultipleChoiceFe(
          IdUtil.next(),
          section.id,
          null,
          null,
          null,
          null,
          null,
          null,
          [],
          null,
          undefined
        );
        (question as QuestionMultipleChoiceFe).adjustNewQuestion();
        break;
      case QUESTION_TYPE.DATE:
        question = new QuestionDateFe(
          IdUtil.next(),
          section.id,
          null,
          null,
          null,
          null,
          null,
          null,
          [],
          isRangeQuestion,
          isRangeQuestion ? [] : null,
          [],
          false,
          undefined
        );
        break;
      case QUESTION_TYPE.TEXT:
        question = new QuestionTextFe(
          IdUtil.next(),
          section.id,
          null,
          null,
          null,
          null,
          null,
          null,
          [],
          [],
          false,
          undefined
        );
        break;
      case QUESTION_TYPE.NUMBER:
        question = new QuestionNumberFe(IdUtil.next(), section.id, null, null, null, null, null, null, [], null, null, true, [], false, undefined)
        break
      case QUESTION_TYPE.EMISSION_FACTOR:
        question = new QuestionEmissionFactorFe(IdUtil.next(), section.id, null, null, null, null, null, null, [], null, [], false, undefined)
        break
    }
    question.isMapped = section.isTaxonomyConnected;
    return question;
  }

  openDeleteQuesModal(index: number, section: SectionFe, template: TemplateRef<any>) {
    this.selectedQuestionIndex = index;
    this.selectedSection = section;
    let ques = section.questions[index];
    if (ques.hasFollowUpQuestion) {
      this.openModal(template, 'modal-md')
    } else {
      this.deleteQues(index, section)
    }
  }

  deleteQues(index: number, section: SectionFe) {
    let ques = section.questions[index];
    while (ques.hasFollowUpQuestion) {
      section.questions.filter((q, index) => {
        if (q.parentQuestionId == ques.id) {
          this.deleteQues(index, section)    
        }
      })
      
      if (index == this.selectedQuestionIndex) {
        this.closeModal()
      }
    }
    if (ques.isFollowUpQues) {
      let parentQues = section.questions.find(
        (q) => q.id == ques.parentQuestionId
      );
      parentQues.childrenNumber -= 1;
      if (parentQues.childrenNumber == 0) {
        parentQues.hasFollowUpQuestion = false;
      }
    }
    section.questions.splice(index, 1);
  }

  getParentQuesOptions(quesId: string, section: SectionFe) {
    let question = section.questions.find((q) => q.id == quesId);
    if (
      question instanceof QuestionSingleChoiceFe ||
      question instanceof QuestionMultipleChoiceFe
    ) {
      return question.options;
    }
  }

  handleCondition(
    options: QuestionChoiceOptionFe | QuestionChoiceOptionFe[] | Event,
    question: QuestionFe
  ) {
    if (options instanceof Event) return;
    let ls = new BooleanValueStatementFe(true);
    let o = TwoOperandOperatorFe.AND;
    let condition = new TwoOperandStatementFe(ls, o, null);
    if (options instanceof QuestionChoiceOptionFe) {
      let reference = `${question.parentQuestionId}.${options.id}`;
      condition.rightStatement = new VariableStatementFe(reference);
    } else if (Array.isArray(options)) {
      let lastStatement: TwoOperandStatementFe = condition;
      options.forEach((option, i) => {
        let reference = `${question.parentQuestionId}.${option.id}`;
        if (lastStatement.rightStatement instanceof VariableStatementFe) {
          let leftStatement = new VariableStatementFe(
            lastStatement.rightStatement.reference
          );
          let operator = TwoOperandOperatorFe.AND;
          let rightStatement = new VariableStatementFe(reference);
          let statement = new TwoOperandStatementFe(
            leftStatement,
            operator,
            rightStatement
          );
          lastStatement.rightStatement = statement;
          lastStatement = statement;
        } else {
          lastStatement.rightStatement = new VariableStatementFe(reference);
        }
      });
    }
    question.followUpCondition = condition;
  }

  hasCondition(question) {
    if (question.followUpCondition instanceof BooleanStatementFe) {
      return !!question.followUpCondition.rightStatement;
    }
  }

  enableEditMode(question, section: SectionFe) {
    this.sections.forEach((section) =>
      section.questions.forEach((q) => (q.isEditMode = false))
    );
    question.isEditMode = true;
    if (question.isFollowUpQues) {
      let parentQues = section.questions.find(
        (q) => q.id == question.parentQuestionId
      );
      this.parentQuestionIndex = section.questions.findIndex(
        (q) => q.id == question.parentQuestionId
      );
      this.children = section.questions.slice(
        this.parentQuestionIndex + 1,
        this.parentQuestionIndex + 1 + parentQues.childrenNumber
      );
    }
  }

  reorderParentQuestions(index: number, section: SectionFe) {
    let questionId = this.selectedQuestion;
    let menuBtn = document.getElementById("btn-arrange") as HTMLElement;
    let dropdown = menuBtn.nextElementSibling as HTMLElement;
    let selectedQuestionIndex = section.questions.findIndex(
      (q) => q.id == questionId
    );
    let question = section.questions[index];

    if (index !== selectedQuestionIndex && index !== null && index >= 0) {
      //case 1: If question is a parent but not a child, it moves its children as well move question with children
      if (question.hasFollowUpQuestion && !question.isFollowUpQues) {
        this.questionHasFollowUp = section.questions.splice(
          index,
          question.childrenNumber + 1
        );
        section.questions.splice(
          selectedQuestionIndex,
          0,
          ...this.questionHasFollowUp
        );
        //case 3: If it's single question, it moves only the question
      } else {
        section.questions.splice(index, 1);
        section.questions.splice(selectedQuestionIndex, 0, question);
      }
    }
    dropdown.classList.toggle("hide");
    dropdown.classList.toggle("show");
  }

  reorderChildQuestions(section: SectionFe, index: number) {
    let menuBtn = document.getElementById("btn-arrange") as HTMLElement;
    let dropdown = menuBtn.nextElementSibling as HTMLElement;
    let questionId = this.selectedChildQuestion;
    let selectedQuestionIndex = section.questions.findIndex(
      (q) => q.id == questionId
    );
    let question = section.questions[index];
    let parentQues = section.questions.find(
      (q) => q.id == section.questions[index].parentQuestionId
    );
    this.parentQuestionIndex = section.questions.findIndex(
      (q) => q.id == section.questions[index].parentQuestionId
    );
    if (index !== selectedQuestionIndex && index !== null && index >= 0) {
      //case 3: If it's single question, it moves only the question
      if (question.isFollowUpQues) {
        let questionToMove = section.questions.splice(index, 1);
        section.questions.splice(selectedQuestionIndex, 0, ...questionToMove);
      }
    }
    dropdown.classList.toggle("hide");
    dropdown.classList.toggle("show");
  }

  duplicateQues(question: QuestionFe, i: number, section) {
    let newQuestion = QuestionTypeConversion.fromTransfer(question, section.id);
    if (
      newQuestion instanceof QuestionDataTableFe &&
      question instanceof QuestionDataTableFe
    ) {
      newQuestion.taxonomyInfo = this.taxonomyInfo;
      newQuestion.setQuestionFromOld(question, section);
    }
    if (section.entity) {
      newQuestion.mappedToCols = [];
    }

    newQuestion.id = IdUtil.next();

    if (
      newQuestion instanceof QuestionSingleChoiceFe ||
      newQuestion instanceof QuestionMultipleChoiceFe
    ) {
      newQuestion.options.forEach((o) => (o.id = IdUtil.next()));
    }

    section.questions.splice(i, 0, newQuestion);
  }

  isColumnSelected(column: TaxonomyAttributeFe, question: QuestionDataTableFe) {
    let isSelected = question.fields.some((f) => f.key == column.key);
    return isSelected;
  }

  addSection() {
    let section = new SectionFe(
      IdUtil.next(),
      null,
      null,
      null,
      [],
      this.taxonomyInfo
    );
    section.isTaxonomyConnected = true;
    this.sections.push(section);
  }

  deleteSection(section: SectionFe, event) {
    this.stopImmediatePropagation(event);
    this.sections = this.sections.filter((sec) => sec.id != section.id);
    this.deletedSections.emit(this.sections)
  }

  duplicateSection(section: SectionFe, event) {
    this.stopImmediatePropagation(event);
    let newSection = new SectionFe(
      IdUtil.next(),
      section.name,
      section.taxonomyKey,
      section.taxonomyVersion,
      [],
      this.taxonomyInfo
    );
    if (section.taxonomyKey) {
      newSection.entity = this.taxonomyInfo.entityByKey(section.taxonomyKey);
    }
    for (let question of section.questions) {
      let newQuestion = QuestionTypeConversion.setQuestion(question);
      if (newQuestion.isFollowUpQues) {
        let formulaString = newQuestion.followUpCondition.toFormulaString();
        let index1 = formulaString.indexOf("$"),
          index2 = formulaString.indexOf(".");
        let parentQuesId = formulaString.substring(index1 + 1, index2);
        formulaString = formulaString.substring(index2);
        newQuestion.parentQuestionId = parentQuesId;
        let parentQues = section.questions.find((q) => q.id == parentQuesId);
        parentQues.childrenNumber += 1;
        if (parentQues instanceof QuestionSingleChoiceFe) {
          let optionStartIndex = formulaString.indexOf("."),
            optionEndIndex = formulaString.indexOf(" ", optionStartIndex);
          let optionId = formulaString.substring(
            optionStartIndex + 1,
            optionEndIndex
          );
          let option = parentQues.options.find((o) => o.id == optionId);
          newQuestion.followUpOptions = option;
        } else if (parentQues instanceof QuestionMultipleChoiceFe) {
          let optionStartIndex = formulaString.indexOf(".");
          while (optionStartIndex != -1) {
            optionStartIndex = formulaString.indexOf(".");
            let optionEndIndex = formulaString.indexOf(" ", optionStartIndex);
            let optionId = formulaString.substring(
              optionStartIndex + 1,
              optionEndIndex
            );
            formulaString = formulaString.substring(optionEndIndex + 1);
            let option = parentQues.options.find((o) => o.id == optionId);
            if (option) {
              if (newQuestion.followUpOptions instanceof Array) {
                newQuestion.followUpOptions.push(option);
              } else {
                newQuestion.followUpOptions = [option];
              }
            }
          }
        }
      }
      if (
        newQuestion instanceof QuestionDataTableFe &&
        question instanceof QuestionDataTableFe
      ) {
        newQuestion.taxonomyInfo = this.taxonomyInfo;
        newQuestion.setQuestionFromOld(question, section);
      }
      if (newQuestion.mappedToColKeys && newSection.entity) {
        newQuestion.setMappedToCols(newSection.entity);
      }
      if (
        newQuestion instanceof QuestionDateFe &&
        newQuestion.mappedToColKeysRange
      ) {
        newQuestion.setMappedToColsRange(newSection.entity);
      }
      newQuestion.sectionId = newSection.id;
      newQuestion.confirmMapping();
      newSection.questions.push(newQuestion);
    }
    newSection.editTaxonomy = false;
    this.sections.push(newSection);
  }

  hasDataTableQues(section: SectionFe) {
    let ques = section.questions.find((q) =>
      this.requestService.isDataTableQues(q)
    );
    return ques;
  }

  stopImmediatePropagation(event) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
  }

  getUnitsByMeasurementType() {
    // If any logic is needed to filter the unit list, we put it here
    return this.unitsByMeasurementType;
  }

  getCustomUnits() {
    // If any logic is needed to filter the unit list, we put it here
    return this.customUnits;
  }

  getMeasurementType(question: QuestionFe) {
    const includeMeasurementTypes = new Set();
    if (question.mappedToCols) {
      question.mappedToCols.forEach((col) => {
        if (col.unit) includeMeasurementTypes.add(col.unit.measurementType);
      });
    }
    return includeMeasurementTypes;
  }

  setUnit(question: QuestionNumberFe, event: UnitFe) {
    question.unit = event.symbol;
    question.measurementKey = event.measurementType;
  }

  setUnitForPredefinedAnswer(question: QuestionNumberFe, event: UnitFe) {
    question.tempPredefinedOption = new PredefinedNumberOptionFe();
    question.tempPredefinedOption.unit = event.symbol;
    question.tempPredefinedOption.measurementKey = event.measurementType;
  }

  isConnectedAction() {
    return this.displayService.openTab != 'CONNECTED_ACTIONS' && this.categoryService.totalChanges() > 0;
  }
  
  sameMeasurementType(col: TaxonomyAttributeFe, question: QuestionFe) {
    if (question instanceof QuestionEmissionFactorFe) {
      if (question.mappedToCols && question.mappedToCols[0]) {
        if (col.emissionFactors[0].value && question.mappedToCols[0].emissionFactors[0].value) {
          const colSourceUnit = _.find(this.units, { 'symbol': col.emissionFactors[0].value['sourceUnit'] })
          const colConversionUnit = _.find(this.units, { 'symbol': col.emissionFactors[0].value['conversionUnit'] })
          const mapppedToColSourceUnit = _.find(this.units, { 'symbol': question.mappedToCols[0].emissionFactors[0].value['sourceUnit'] })
          const mapppedToColConversionUnit = _.find(this.units, { 'symbol': question.mappedToCols[0].emissionFactors[0].value['conversionUnit'] })
          return colSourceUnit?.measurementType == mapppedToColSourceUnit?.measurementType && colConversionUnit?.measurementType == mapppedToColConversionUnit?.measurementType
        }
      }
    } else if (question instanceof QuestionNumberFe) {
      if (question.mappedToCols && question.mappedToCols[0]) {
        return col.unit?.measurementType == question.mappedToCols[0].unit?.measurementType
      }
    }
    return true
  }

  autoGrowTextZone(e) {
    e.target.style.height = "0px";
    e.target.style.height =
      (e.target.scrollHeight < 575 ? e.target.scrollHeight + 25 : 600) + "px";
    e.target.maxHeight = "600px";
  }

  handlePrefineModal(question, modal) {
    this.selectedQuestion = question
    this.openModal(modal, 'modal-lg')
  }

  containsPredefinedOptions(question: QuestionFe): boolean {
    return (question as any).predefinedOptions?.length > 0
  }

  addCustomPredefinedDateOption(question: QuestionFe, isDateRange: boolean, dateInputElementFrom: HTMLInputElement, dateInputElementTo: HTMLInputElement = undefined) {
    if (isDateRange && this.isInvalidDate(dateInputElementFrom, dateInputElementTo)) {
      return
    }

    const fromDate = dateInputElementFrom.valueAsDate;
    const toDate = dateInputElementTo?.valueAsDate;
    const questionDate = question as QuestionDateFe;

    if (!isDateRange && fromDate) {
      questionDate.predefinedOptions.push(new PredefinedDateOptionFe(fromDate));
    } else if (isDateRange && toDate) {
      questionDate.predefinedOptions.push(new PredefinedDateOptionFe(fromDate, toDate));
    }

    dateInputElementFrom.value = '';
    this.isCustomOptionAdded = false;
  }

  getMinDate(dateInputElementFrom: HTMLInputElement): string {
    return dateInputElementFrom.value || '';
  }

  isInvalidDate(dateInputElementFrom: HTMLInputElement, dateInputElementTo: HTMLInputElement): boolean {
    const fromDate = dateInputElementFrom?.valueAsDate;
    const toDate = dateInputElementTo?.valueAsDate;
    return fromDate && toDate && fromDate > toDate;
  }

  addCustomPredefineNumberOption(question: QuestionFe, numberElementFrom: HTMLInputElement): void {
    const value = numberElementFrom.valueAsNumber;
    
    if (!isNaN(value)) {
      if (question instanceof QuestionNumberFe) {
        const option = new PredefinedNumberOptionFe(question.tempPredefinedOption.unit, question.tempPredefinedOption.measurementKey, value);
        question.predefinedOptions.push(option);
      }
    }
  
    numberElementFrom.value = '';
    this.isCustomOptionAdded = false;
  }
  
  lastPredefinedOption(question: QuestionFe): PredefinedNumberOptionFe | undefined {
    if (question instanceof QuestionNumberFe && question.predefinedOptions.length > 0) {
      return question.predefinedOptions[question.predefinedOptions.length - 1];
    }
    return undefined;
  }

  setupDropdownScroll() {
    setTimeout(() => {
      document.querySelectorAll('.dropdown-toggle').forEach(button => {
        button.addEventListener('click', (event) => {
          const container = (event.target as HTMLElement).closest('.scroll-container') as HTMLElement;
          if (container) {
            setTimeout(() => {
              if (container) {
                container.scrollTop = container.scrollHeight;
              }
            });
          }
        });
      });
    });
  }

  getSortedPredfinedNumberOptions(question: QuestionFe): PredefinedNumberOptionFe[] {
    const questionNumber = (question as QuestionNumberFe);
    return questionNumber.getSortedPredfinedNumberOptions();
  }

  getSortedPredfinedDateOptions(question: QuestionFe): PredefinedDateOptionFe[] {
    const questionDate = (question as QuestionDateFe);
    return questionDate.getSortedPredfinedDateOptions();
  }

  getQuestionTitleNumber(question: QuestionFe, i: number) {
    const titleNumber = this.locale("locale_key.pages.data_request.question.title", { question_number: i + 1 })
    question.titleNumber = titleNumber
    return titleNumber
  }

  isNoUnit(option) {
    return option.measurementKey == NoUnit.MEASUREMENT_KEY
  }

  getUnit(option) {
    return option.unit || NoUnit.SYMBOL
  }
  
  selectedUnitContainer: { selectedUnit?: UnitFe} = {}

  skipInitNoUnit(question: QuestionFe) {
    const questionNumber = (question as QuestionNumberFe);
    return questionNumber.measurementKey !== NoUnit.MEASUREMENT_KEY || (questionNumber.measurementKey === NoUnit.MEASUREMENT_KEY && !questionNumber.newAnswer.isSubmitted)
  }


  //Questionnaire Preview 
  @Input() searchText: string;
  @Input() allExpanded: boolean;
  @Input() expandCollapseEvent: EventEmitter<boolean>;
  @Output() accordionStateChange = new EventEmitter<boolean>();

  expandCollapseAll(expanded: boolean) {
    this.allExpanded = expanded;
    this.sections.forEach((section, index) => {
      const accordionButton = document.getElementById(`section${index}`);
      const accordionContent = document.getElementById(`sectionAccordion${index}`);
      if (accordionButton && accordionContent) {
        if (this.allExpanded) {
          accordionButton.classList.remove('collapsed');
          accordionButton.setAttribute('aria-expanded', 'true');
          accordionContent.classList.add('show');
        } else {
          accordionButton.classList.add('collapsed');
          accordionButton.setAttribute('aria-expanded', 'false');
          accordionContent.classList.remove('show');
        }
      }
    });
    this.cd.detectChanges();
  }

  // Add a method to handle individual accordion toggles
  toggleAccordion(index: number) {
    const accordionButton = document.getElementById(`section${index}`);
    const accordionContent = document.getElementById(`sectionAccordion${index}`);
    if (accordionButton && accordionContent) {
      accordionButton.classList.toggle('collapsed');
      accordionContent.classList.toggle('show');
      const isExpanded = accordionContent.classList.contains('show');
      accordionButton.setAttribute('aria-expanded', isExpanded.toString());
    }
    // Notify parent component to update button text
    this.checkAccordionState();
  }

  // Add this method to check the state of accordions
  checkAccordionState() {
    const anyCollapsed = this.sections.some((section, index) => {
      const accordionContent = document.getElementById(`sectionAccordion${index}`);
      return !accordionContent?.classList.contains('show');
    });
    this.allExpanded = !anyCollapsed;
    // Emit an event to notify the parent component
    // You'll need to add an @Output() for this
    this.accordionStateChange.emit(this.allExpanded);
  }


  filteredQuestions(section: SectionFe): QuestionFe[] {
    // Trim the search text and convert to lowercase
    const trimmedSearchText = this.searchText?.trim().toLowerCase();
  
    // If search text is empty after trimming, return all questions
    if (!trimmedSearchText) {
      return section.questions;
    }
  
    // Split the search text into words
    const searchWords = trimmedSearchText.split(/\s+/);
  
    return section.questions.filter(question => {
      const questionText = question.question.toLowerCase();
      const titleNumber = question.titleNumber?.toLowerCase() || '';
  
      // Check if all search words are included in either the question or title number
      return searchWords.every(word => 
        questionText.includes(word) || titleNumber.includes(word)
      );
    });
  }

    setFormView() {
      this.isFormView = true;
    }

    setTableView() {
      this.isFormView = false;
    }
  
   selectedEntryIndex: number = 0;
    // rowCount: number = 1; 
  
    updateSelectedEntry(event: Event) {
      const selectElement = event.target as HTMLSelectElement;
      this.selectedEntryIndex = parseInt(selectElement.value, 10);
    }
  
    // updateRowCount(count: number) {
    //   this.rowCount = count;
    // }

    openEfDetailTab(selectedEF){
      this.displayService.openEfDetailsTab(selectedEF)
    }

    setDatePlaceholder(): void {
      const userLocale = navigator.language || 'en-US';
      const exampleDate = new Date(2023, 8, 28);
  
      const localeDateString = this.datePipe.transform(exampleDate, 'shortDate', undefined, userLocale);
  
      if (localeDateString) {
        this.datePlaceholder = localeDateString
          .replace(/\d{2}/, 'DD')
          .replace(/\d{2}/, 'MM')
          .replace(/\d{4}/, 'YYYY');
      }
    }
}
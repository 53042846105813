<div class="row pt-1 mt-0 m-0 px-0 w-100" *ngIf="showHeader">
  <div class="col-12">
    <div class="d-flex flex-row">
      <div class="d-flex flex-row justify-content-center align-items-center border-r">
        <i class="la la-envelope-o fs-1 me-3"></i>
        <h5 class="my-auto me-3">{{ requestGroup.title }}</h5>
      </div>
      <div class="d-flex flex-row justify-content-center ms-3 align-items-center">
        <div class="text-start ps-0 mt-1">
          <div class="circle-icon-big me-auto"><i class="las la-user fs-1 text-white"></i></div>
        </div>
        <span class="ps-3">
          <h5 class="m-0 mt-3">{{ selectedRequest.assigneeFirstName }} {{ selectedRequest.assigneeLastName }}</h5>
          <small class="m-0 text-muted">{{ selectedRequest.supplierCompanyName }}</small>
          <p class="text-start mt-1">
            <span class="badge" [ngClass]="selectedRequest.getStatusClass()">
              {{ locale(selectedRequest.getStatusTextKeyword()) | titlecase }}
            </span>
          </p>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="card-body pt-0 ps-0 mb-0 pb-0 me-0 pe-0" style="height: 70vh">
  <div class="d-flex flex-md-row flex-column h-100">
    <div class="col-md-2 col-sm-12 bg-light-main">
      <div class="d-flex flex-md-column flex-sm-row">
        <ul class="nav flex-md-column flex-sm-row  nav-tabs no-hover-bg nav-justified w-100" [ngClass]="screenSize.isXLargeSize() || screenSize.isLargeSize() ? 'nav-underline-right':'nav-underline'">
          <li class="nav-item" (click)="viewType = 'dataReporter'">
            <a class="nav-link p-1 text-start active bg-transparent" id="baseIcon-tab1" data-bs-toggle="tab" aria-controls="tabIcon1" href="#tabIcon1" aria-expanded="false">{{
              locale("locale_key.pages.inventory.results_per_data_reporter") }}</a>
          </li>
          <li class="nav-item" (click)="viewType = 'question'">
            <a class="nav-link p-1 text-start bg-transparent" id="baseIcon-tab2" data-bs-toggle="tab" aria-controls="tabIcon2" href="#tabIcon2" aria-expanded="true">{{
              locale("locale_key.pages.inventory.results_per_question") }}</a>
          </li>
          <li class="nav-item" (click)="viewType = 'table'">
            <a class="nav-link p-1 text-start bg-transparent" id="baseIcon-tab3" data-bs-toggle="tab" aria-controls="tabIcon3" href="#tabIcon3" aria-expanded="true">{{
              locale("locale_key.pages.inventory.results_summary_table") }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-10 col-sm-12 h-100">
      <div class="tab-content px-3 h-100" style="min-height: 65vh">
        <div class="tab-pane h-100 active" id="tabIcon1" aria-expanded="true" aria-labelledby="baseIcon-tab1">
          <div class="h-100">
            <div class="d-flex flex-xl-row flex-lg-row flex-md-column flex-sm-column flex-column py-3 w-100">
              <div class="col-xl-9 col-lg-9 col-md-12 my-auto" style="height: fit-content !important">
                <div class="dropdown">
                  <div id="dataReporterDrpdwn" style="max-width: 40rem;" data-bs-toggle="dropdown" aria-expanded="false">
                    <a class="dropdown-toggle align-items-center thin-border py-1 d-flex text-truncate p-2 w-100">
                      <div class="col-8 pt-1">
                        <div class="d-flex flex-row m-0 p-0">
                          <div class="circle-icon-dark me-1 mt-1" style="position: relative; bottom: 5px; left: 0"><i class="las la-user size-15 text-white"></i></div>
                          <small class="text-truncate">{{ selectedRequest.getAssigneeName()  }} <span class="text-muted">({{selectedRequest.supplierCompanyName}})</span></small>
                        </div>
                      </div>
                      <div class="my-auto mx-0 pe-0 col-xl-3 col-lg-3 d-none d-xl-block d-lg-block">
                        <span class="badge my-auto me-1 ms-auto" [ngClass]="selectedRequest.getStatusClass()">
                          {{ locale(selectedRequest.getStatusTextKeyword()) | titlecase }}
                        </span>
                      </div>
                    </a>
                  </div>
                  <ul class="dropdown-menu" aria-labelledby="dataReporterDrpdwn" style="max-width: 40rem;">
                    <ng-container *ngFor="let req of requestGroup.requests">
                      <div class="thin-border d-flex flex-row justify-content-betwen text-truncate p-2 w-100" (click)="selectedRequest = req" *ngIf="selectedRequest!=req">
                        <div class="col-8 pt-1">
                          <div class="d-flex flex-row m-0 p-0">
                            <div class="circle-icon-dark me-1" style="position: relative; bottom: 5px; left: 0"><i class="las la-user size-15 text-white"></i></div>
                            <small class="text-truncate">{{ req.getAssigneeName() }} <span class="text-muted">({{selectedRequest.supplierCompanyName}})</span></small>
                          </div>
                        </div>
                        <div class="my-auto mx-0 pe-0 col-xl-3 col-lg-3 d-none d-xl-block d-lg-block">
                          <span class="badge my-auto me-1 ms-auto" [ngClass]="req.getStatusClass()">
                            {{ locale(req.getStatusTextKeyword()) | titlecase }}
                          </span>
                        </div>
                      </div>
                    </ng-container>
                  </ul>
                </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-12 d-flex flex-row h-100">
                <div class="d-flex flex-row justify-content-end w-100 mx-2">
                  <a class="btn tertiary-button position-relative mx-auto" title="{{ locale('locale_key.general.button.open_conversation')}}" (click)="markAsRead(); isChatOpen = true">
                    <i class="la la-comment-alt fs-4" style="position: relative; top: 3px"></i>
                    <span class="position-absolute top-0 start-100 translate-middle rounded-pill unread-dot text-white" *ngIf="getUnreadActivities().length > 0"
                      style="max-width: fit-content !important; border-radius: 50%">
                      {{getUnreadActivities().length > 100 ? '99+' : getUnreadActivities().length}}
                    </span>
                  </a>
                </div>
                <div class="btn-group">
                  <div class="description-btn text-end" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="la la-ellipsis-v ms-auto my-auto cursor size-15" [ngClass]="{ 'pe-3': screenSize.isSmallerSize() || screenSize.isXSmallSize() }"></i>
                  </div>
                  <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                    <button class="dropdown-item underline ms-0 px-1 text-dark" type="button" *ngIf="selectedRequest.questionnaire.status == 'APPROVED'" (click)="openModal(confirmConnect, 'modal-lg')">
                      {{locale('locale_key.pages.inbox.connect_all_sections')}}
                    </button>
                    <button class="dropdown-item underline ms-0 px-1 text-dark" type="button" *ngIf="selectedRequest.questionnaire.status == 'APPROVED'" (click)="openModal(confirmDisconnect, 'modal-md')">
                      {{locale('locale_key.pages.inbox.disconnect_all_sections')}}
                    </button>
                    <button class="dropdown-item underline ms-0 px-1 text-dark" type="button" (click)="openModal(confirmClose, 'modal-md')">
                      {{ locale('locale_key.pages.inventory.close_request') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div style="height: 80%">
              <request-answer [requestGroup]="requestGroup" [request]="selectedRequest"></request-answer>
            </div>
          </div>
        </div>
        <div class="tab-pane h-100" id="tabIcon2" aria-labelledby="baseIcon-tab2">
          <div class="p-1 h-100 scroll" #questionView *ngIf="submittedAnswers>0; else noAnswer">
            <ng-container *ngFor="let section of requestGroup.requests[0].questionnaire.sections; let sectionIndex = index">
              <div *ngFor="let question of section.questions; let questionIndex = index" id="question{{ questionIndex }}">
                <div class="row mb-2 mt-3">
                  <div class="col-8">
                    <span class="my-auto dark-text fs-5 ms-1 bold">{{questionIndex+1}}. {{ question.question }}</span>
                  </div>
                  <div class="col-4 text-end">
                    <p class="badge gray-bg mb-0">{{ submittedAnswers }}/{{ requestGroup.requestCount }} {{ locale("locale_key.pages.data_request_answers.low_cap_response") }}</p>
                  </div>
                </div>
                <div class="row mb-2" *ngIf="requestService.isMultipleChoiceQues(question) || requestService.isSingleChoiceQues(question)">
                  <div class="col-6">
                    <div class="row ms-3 mb-0" *ngFor="let wrapper of chartWrappers; let i = index">
                      <div id="item-{{ i }}" class="col-12 px-0 pe-3" *ngIf="!wrapper.hasError && i == section.questions[questionIndex]['chartWrapperIndex']">
                        <div id="accordionWrapa{{ i }}" role="tablist" aria-multiselectable="true">
                          <div class="card hoverable p-2 m-0 mb-2">
                            <div class="card-head">
                              <div class="card-header p-0">
                                <div class="d-flex flex-row">
                                  <div class="list-inline-item d-flex flex-column px-0 me-0">
                                    <ul class="list-inline m-0" *ngIf="wrapper.options.chartTypes && wrapper.options.chartTypes.length > 0">
                                      <li class="list-inline-item d-flex flex-column me-0">
                                        <ng-container *ngFor="let type of wrapper.options.chartTypes">
                                          <button type="button" class="btn btn-icon action-icon" data-bs-toggle="tooltip" data-placement="bottom"
                                            title="{{ locale('locale_key.pages.kpi_library.graph_type.tooltip.bar') }}" show-delay="300" *ngIf="type.id.startsWith('bar')"
                                            (click)="wrapper.setting.setChartType(type); onChangedSetting(i)">
                                            <i class="las la-chart-bar"></i>
                                          </button>
                                          <button type="button" class="btn btn-icon action-icon" data-bs-toggle="tooltip" data-placement="bottom"
                                            title="{{ locale('locale_key.pages.kpi_library.graph_type.tooltip.stack') }}" show-delay="300" *ngIf="type.id.startsWith('stack')"
                                            (click)="wrapper.setting.setChartType(type); onChangedSetting(i)">
                                            <i class="las la-layer-group"></i>
                                          </button>
                                          <button type="button" class="btn btn-icon action-icon" data-bs-toggle="tooltip" data-placement="bottom"
                                            title="{{ locale('locale_key.pages.kpi_library.graph_type.tooltip.line') }}" show-delay="300" *ngIf="type.id.startsWith('line')"
                                            (click)="wrapper.setting.setChartType(type); onChangedSetting(i)">
                                            <i class="las la-chart-line"></i>
                                          </button>
                                          <button type="button" class="btn btn-icon action-icon" data-bs-toggle="tooltip" data-placement="bottom"
                                            title="{{ locale('locale_key.pages.kpi_library.graph_type.tooltip.pie') }}" show-delay="300" *ngIf="type.id.startsWith('pie')"
                                            (click)="wrapper.setting.setChartType(type); onChangedSetting(i)">
                                            <i class="las la-chart-pie"></i>
                                          </button>
                                          <button type="button" class="btn btn-icon action-icon" data-bs-toggle="tooltip" data-placement="bottom"
                                            title="{{ locale('locale_key.pages.kpi_library.graph_type.tooltip.doughnut') }}" show-delay="300" *ngIf="type.id.startsWith('doughnut')"
                                            (click)="wrapper.setting.setChartType(type); onChangedSetting(i)">
                                            <i class="lab la-chrome"></i>
                                          </button>
                                        </ng-container>
                                      </li>

                                      <div class="p-0 mt-3">
                                        <li class="list-inline-item d-flex flex-column">
                                          <button type="button" class="btn btn-icon action-icon" data-bs-toggle="tooltip" data-placement="bottom"
                                            title="{{ locale('locale_key.pages.kpi_library.tooltip.download_img') }}" show-delay="300" (click)="downloadGraphImage(i)">
                                            <i class="las la-image"></i>
                                          </button>
                                          <button type="button" class="btn btn-icon action-icon" data-bs-toggle="tooltip" data-placement="bottom"
                                            title="{{ locale('locale_key.pages.kpi_library.tooltip.download_csv') }}" show-delay="300" (click)="downloadGraphCSV(i)">
                                            <i class="las la-file-alt"></i>
                                          </button>
                                          <button type="button" class="btn btn-icon action-icon" data-bs-toggle="tooltip" data-placement="bottom"
                                            title="{{ locale('locale_key.pages.kpi_library.tooltip.download_pdf') }}" show-delay="300" (click)="downloadGraphPDF(i)">
                                            <i class="la la-file-pdf-o"></i>
                                          </button>
                                        </li>
                                      </div>
                                    </ul>
                                  </div>
                                  <div id="parent-canvas{{ i }}" class="card-body p-0" #canvascontainer>
                                    <canvas id="canvas{{ i }}" #canvas></canvas>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="me-3 ms-3 mb-2" *ngIf="requestService.isTextQues(question) || requestService.isNumberQues(question) || 
                requestService.isDataTableQues(question) || requestService.isAttachmentQues(question) || requestService.isDateQues(question) ||
                requestService.isEmissionFactorQues(question)">
                  <div class="row mx-auto mb-0">
                    <div class="col-3 py-3 bg-dark border-r">
                      <div class="d-flex flex-column justify-content-center h-100">
                        <small class="text-white">{{ locale("locale_key.general.user_role.data_reporter") }}</small>
                      </div>
                    </div>
                    <div class="col-6 py-3 bg-dark border-r">
                      <div class="d-flex flex-column justify-content-center h-100">
                        <small class="text-white">{{ locale("locale_key.pages.data_request_answers.camel_case_response") }}</small>
                      </div>
                    </div>
                    <div class="col-2 py-3 bg-dark border-r" *ngIf="question.comments">
                      <div class="d-flex flex-column justify-content-center h-100">
                        <small class="text-white">{{ locale("locale_key.pages.data_request.comments.placeholder") }}</small>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngFor="let request of requestGroup.requests">
                    <div class="row w-100 mx-auto mt-0" *ngIf="request.questionnaire.status != 'CREATED'">
                      <div class="col-3 thin-border">
                        <div class="d-flex flex-column justify-content-center h-100">
                          <small class="text-muted"> {{ request.getAssigneeName() }} </small>
                          <small class="text-muted"> ({{ request.supplierCompanyName }}) </small>
                          <span class="badge mt-1 me-1" style="width: 80px;" [ngClass]="request.getStatusClass()">
                            {{ locale(request.getStatusTextKeyword()) | titlecase }}
                          </span>
                        </div>
                      </div>
                      <div class="col-6 thin-border">
                        <ng-container *ngIf="request.questionnaire.sections[sectionIndex].questions[questionIndex].answer">
                          <div class="d-flex flex-column justify-content-center h-100 multiline" *ngIf="requestService.isTextQues(question)">
                            <small class="text-muted"> {{ request.questionnaire.sections[sectionIndex].questions[questionIndex].answer.text }} </small>
                          </div>
                          <label class="text-muted my-1 mx-0 d-flex align-items-center" *ngIf="requestService.isEmissionFactorQues(question)">
                            <emission-factor [selectedEF]="request.questionnaire.sections[sectionIndex].questions[questionIndex].answer.selectedEmissionFactor"></emission-factor>
                            <div class="btn-group col-1" *ngIf="section.taxonomyKey && question.mappedToColKeys && requestService.isSectionConnected(section)">
                              <div class="description-btn text-end" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="la la-ellipsis-v ms-auto my-auto cursor size-15" [ngClass]="{ 'pe-3': screenSize.isSmallerSize() || screenSize.isXSmallSize() }"></i>
                              </div>
                              <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                                <button class="dropdown-item underline ms-0 px-1 text-dark" type="button" (click)="openMasterTable(section)">
                                  {{ locale('locale_key.pages.emission.edit_emission_factor_in_master_table') }}
                                </button>
                              </div>
                            </div>
                          </label>        
                          <div class="d-flex flex-column justify-content-center h-100" *ngIf="requestService.isNumberQues(question)">
                            <small class="text-muted"> {{ request.questionnaire.sections[sectionIndex].questions[questionIndex].answer.value + " " + getUnitString(request.questionnaire.sections[sectionIndex].questions[questionIndex].answer.unit) }}</small>
                          </div>
                          <div *ngIf="requestService.isDateQues(question)">
                            <small class="text-muted d-inline"> {{ request.questionnaire.sections[sectionIndex].questions[questionIndex].answer.getDateFromString() }} </small>
                            <small class="mx-3" *ngIf="question.range"> -</small>
                            <small class="text-muted d-inline" *ngIf="question.range">{{ request.questionnaire.sections[sectionIndex].questions[questionIndex].answer.getDateToString() }}</small>
                          </div>
                          <div class="d-flex flex-row justify-content-center py-3 open-bx" [ngClass]="{ 'xSmallSize': screenSize.isXSmallSize() }"
                            *ngIf="(requestService.isAttachmentQues(question) && request.questionnaire.sections[sectionIndex].questions[questionIndex].answer.attachmentStorageId) || requestService.isDataTableQues(question)">
                            <div class="ball-pulse loader-primary" *ngIf="request.questionnaire.sections[sectionIndex].questions[questionIndex].answer.isLoading">
                              <div style="background-color: var(--main-dark-color1)"></div>
                              <div style="background-color: var(--main-dark-color1)"></div>
                              <div style="background-color: var(--main-dark-color1)"></div>
                            </div>
                            <ng-container *ngIf="!request.questionnaire.sections[sectionIndex].questions[questionIndex].answer.isLoading">
                              <div class="col-2 text-start mx-0 px-0">
                                <i class="la la-file-text-o fs-1 me-auto ms-0 ps-0" *ngIf="requestService.isAttachmentQues(question)"></i>
                                <i class="la la-table fs-1 me-auto ms-0 ps-0" *ngIf="requestService.isDataTableQues(question)"></i>
                              </div>
                              <div class="col-6 d-flex justify-content-center text-start mx-0 ps-0">
                                <p class="text-muted my-auto me-auto text-truncate" data-bs-toggle="tooltip" data-placement="top"
                                  title="{{ requestService.isDataTableQues(question) ? locale('locale_key.pages.data_request.question_type.data_table') : getAttachmentTitle(request.questionnaire.sections[sectionIndex].questions[questionIndex]) }}">
                                  {{ requestService.isDataTableQues(question) ? locale("locale_key.pages.data_request.question_type.data_table") :
                                  getAttachmentTitle(request.questionnaire.sections[sectionIndex].questions[questionIndex]) }}
                                </p>
                              </div>
                              <div class="col-4 d-flex flex-row justify-content-center">
                                <span class="text-muted m-auto underline cursor"
                                  (click)="requestService.isDataTableQues(question) ? openTableFile(request.questionnaire.sections[sectionIndex].questions[questionIndex]) : openFile(request.questionnaire.sections[sectionIndex].questions[questionIndex])">{{
                                  locale("locale_key.general.buttons.open")
                                  }}</span>
                                <div class="dropdown">
                                  <button type="button" class="text-muted m-auto underline cursor border-0 bg-transparent" data-bs-toggle="dropdown" data-placement="top" show-delay="300">
                                    <span>{{ locale('locale_key.general.toolbar.button.export') }}</span>
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" (click)="exportFile(request.questionnaire.sections[sectionIndex].questions[questionIndex], request, 'xlsx')">xlsx</a></li>
                                    <li><a class="dropdown-item" (click)="exportFile(request.questionnaire.sections[sectionIndex].questions[questionIndex], request, 'csv')">csv</a></li>
                                  </ul>
                              </div>
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>
                      <div class="col-2 thin-border" *ngIf="question.comments">
                        <div class="d-flex flex-column justify-content-center h-100" *ngIf="request.questionnaire.sections[sectionIndex].questions[questionIndex].answer">
                          <small class="text-muted">{{ request.questionnaire.sections[sectionIndex].questions[questionIndex].answer.comments }}</small>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
          <ng-template #noAnswer>
            <div class="d-flex align-items-center justify-content-center" style="height: 80%;">
              {{ locale("locale_key.pages.sm_requests.no_submitted_data") }}
            </div>
          </ng-template>
        </div>
        <div class="tab-pane h-100" id="tabIcon3" aria-labelledby="baseIcon-tab3">
          <table class="d-block h-100 scroll" #tableView>
            <thead>
              <tr class="bg-dark mx-auto mb-0">
                <th class="py-3 border-r">
                  <div class="text-start ps-2">
                    <small class="text-white">{{ locale("locale_key.general.user_role.data_reporters") }}</small>
                  </div>
                </th>
                <th class="py-3" [ngClass]="{ 'border-r': !isLast }" *ngFor="let question of requestService.getQuestions(requestGroup.requests[0]); let i = index; let isLast = last">
                  <div class="text-start ps-2">
                    <small class="text-white">{{ i + 1 }}. {{ question.question }}
                    </small>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let request of requestGroup.requests">
                
                <tr class="border-b mt-0 table-row" *ngIf="request.questionnaire.status != 'CREATED'">
                  <td class="py-3 border-r bg-light">
                    <div class="text-start ps-2">
                      <div>
                        <small class="text-muted"> {{ request.getAssigneeName() }} </small>
                      </div>
                      <div>
                        <small class="text-muted"> {{ request.supplierCompanyName }} </small>
                      </div>
                    </div>
                  </td>
                  <ng-container *ngFor="let section of request.questionnaire.sections">
                    <td class="py-3 border-r" *ngFor="let question of section.questions">
                      <div class="text-start ps-2" *ngIf="question.answer">
                        <ng-container *ngIf="requestService.isMultipleChoiceQues(question)">
                          <small class="text-muted" *ngFor="let optionId of question.answer.selectedOptionIds">
                            <input type="checkbox" checked disabled />
                            {{ question.getOption(optionId).value }}
                          </small>
                        </ng-container>
                        <ng-container *ngIf="requestService.isSingleChoiceQues(question)">
                          <small class="text-muted" *ngIf="question.answer.selectedOptionId">
                            <input type="radio" checked disabled />
                            {{ question.getOption(question.answer.selectedOptionId).value }}
                          </small>
                        </ng-container>
                        <small class="text-muted" *ngIf="requestService.isNumberQues(question)">{{ question.answer.value + " " + getUnitString(question.answer.unit) }}</small>
                        <div *ngIf="requestService.isDateQues(question)">
                          <small class="text-muted d-inline"> {{ question.answer.getDateFromString() }} </small>
                          <small class="mx-3" *ngIf="question.range"> -</small>
                          <small class="text-muted d-inline" *ngIf="question.range">{{ question.answer.getDateToString() }}</small>
                        </div>
                        <small class="text-muted d-inline multiline" *ngIf="requestService.isTextQues(question)">{{ question.answer.text }}</small>
                        <label class="text-muted m-0 d-flex align-items-center" *ngIf="requestService.isEmissionFactorQues(question)">
                          <emission-factor [selectedEF]="question.answer.selectedEmissionFactor"></emission-factor>
                          <div class="btn-group col-1" *ngIf="section.taxonomyKey && question.mappedToColKeys && requestService.isSectionConnected(section)">
                            <div class="description-btn text-end" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="la la-ellipsis-v ms-auto my-auto cursor size-15" [ngClass]="{ 'pe-3': screenSize.isSmallerSize() || screenSize.isXSmallSize() }"></i>
                            </div>
                            <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                              <button class="dropdown-item underline ms-0 px-1 text-dark" type="button" (click)="openMasterTable(section)">
                                {{ locale('locale_key.pages.emission.edit_emission_factor_in_master_table') }}
                              </button>
                            </div>
                          </div>
                        </label>        
                        <small class="text-muted d-inline multiline" *ngIf="(requestService.isDataTableQues(question) || requestService.isAttachmentQues(question))">
                          {{ requestService.isDataTableQues(question) ? locale("locale_key.pages.data_request.question_type.data_table") : getAttachmentTitle(question) }}
                        </small>
                        <hr class="p-1" *ngIf="question.answer.comments" />
                        <small class="text-muted" *ngIf="question.answer.comments"> {{ question.answer.comments }}</small>

                        <div *ngIf="(requestService.isDataTableQues(question) || requestService.isAttachmentQues(question))" class="d-flex flex-row justify-content-center">
                          <span class="text-muted m-auto underline cursor col-3"
                            (click)="requestService.isDataTableQues(question) ? openTableFile(question) : openFile(question)">{{
                            locale("locale_key.general.buttons.open")
                            }}</span>
                          <div class="dropdown col">
                            <button type="button" class="text-muted underline cursor border-0 bg-transparent" data-bs-toggle="dropdown" data-placement="top" show-delay="300">
                              <span>{{ locale('locale_key.general.toolbar.button.export') }}</span>
                            </button>
                            <ul class="dropdown-menu">
                              <li><a class="dropdown-item" (click)="exportFile(question, request, 'xlsx')">xlsx</a></li>
                              <li><a class="dropdown-item" (click)="exportFile(question, request, 'csv')">csv</a></li>
                            </ul>
                        </div>
                        </div>

                      </div>
                    </td>
                  </ng-container>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<app-chat-box *ngIf="isChatOpen" [requestTask]="selectedRequest" [rg]="requestGroup" (chatClosed)="isChatOpen = false"></app-chat-box>

<ng-template #export>
  <div class="main-modal-header modal-header">
    <h4 class="modal-title text-white">{{ locale("locale_key.general.toolbar.button.export") }}</h4>
    <button type="button" class="btn close" (click)="closeModal(true)">
      <span aria-hidden="true" style="font-size: 2rem; font-weight: 300" class="text-white" *ngIf="!loading">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div id="accordionMessage" class="border-shade">
      <h5>{{ locale("locale_key.pages.inventory.export_modal.chose_file_type") }}:</h5>
      <div class="row mt-2" *ngIf="viewType == 'table'">
        <div class="custom-radio col-6 mb-2 custom-control wrapper">
          <div class="d-inline-block thumbnail">
            <i class="la la-file-pdf-o"></i>
            <i class="las la-download"></i>
            <i class="ft-grid"></i>
          </div>
          <span>
            <input class="custom-control-input" type="radio" name="typeOfExport" id="pdf" value="pdf" [(ngModel)]="typeOfExport" />
            <label class="custom-control-label" for="pdf">{{ locale("locale_key.pages.inventory.export_modal.pdf_table_view") }}</label>
          </span>
        </div>
        <div class="custom-radio col-6 mb-2 custom-control wrapper">
          <div class="d-inline-block thumbnail">
            <i class="la la-file-image-o"></i>
            <i class="las la-download"></i>
            <i class="ft-grid"></i>
          </div>
          <span>
            <input class="custom-control-input" type="radio" name="typeOfExport" id="image" value="image" [(ngModel)]="typeOfExport" />
            <label class="custom-control-label" for="image">{{ locale("locale_key.pages.inventory.export_modal.img_table_view") }}</label>
          </span>
        </div>
      </div>
      <div class="row mt-2" *ngIf="viewType == 'question'">
        <div class="custom-radio col-6 mb-2 custom-control wrapper">
          <div class="d-inline-block thumbnail">
            <i class="la la-file-pdf-o"></i>
            <i class="las la-download"></i>
            <i class="ft-server"></i>
          </div>
          <span>
            <input class="custom-control-input" type="radio" name="typeOfExport" id="pdf" value="pdf" [(ngModel)]="typeOfExport" />
            <label class="custom-control-label" for="pdf">{{ locale("locale_key.pages.inventory.export_modal.pdf_question_view") }}</label>
          </span>
        </div>
        <div class="custom-radio col-6 mb-2 custom-control wrapper">
          <div class="d-inline-block thumbnail">
            <i class="la la-file-image-o"></i>
            <i class="las la-download"></i>
            <i class="ft-server"></i>
          </div>
          <span>
            <input class="custom-control-input" type="radio" name="typeOfExport" id="image" value="image" [(ngModel)]="typeOfExport" />
            <label class="custom-control-label" for="image">{{ locale("locale_key.pages.inventory.export_modal.img_question_view") }}</label>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="ball-pulse loader-primary" *ngIf="loading">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
    <button type="button" class="btn secondary-button custom-button" (click)="closeModal(true)" *ngIf="!loading">{{ locale("locale_key.general.buttons.cancel") }}</button>
    <button type="button" class="btn primary-button custom-button" (click)="exportDashobard()" *ngIf="!loading">{{ locale("locale_key.general.toolbar.button.export") }}</button>
  </div>
</ng-template>

<ng-template #confirmDisconnect>
  <div class="modal-header btn-add">
    <div class="icon-background d-inline mt-0" style="height:18px;width:18px; background-color:var(--orange);border-radius: 50%; border:2px solidtext-white;">
      <i class="las la-exclamation-circle text-white p-0 " style="font-size: 1.5rem; background-color:transparent;border-radius: 50%;position: relative;left:-2px;top:-1px"></i>
    </div>
    <h5 class="modal-title text-white" id="myModalLabel12">{{locale('locale_key.general.buttons.remove_from_maser_table')}}</h5>
    <button type="button" class="btn close py-0" [disabled]="loadingInProgress" (click)="closeModal();">
      <span aria-hidden="true" class="text-white" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5>
      {{locale('locale_key.pages.data_request.review_answer_wizard.disconnect_confirmation_modal.title')}}
    </h5>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn secondary-button custom-button" data-dismiss="modal" aria-label="Close" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button type="button" class="btn primary-button custom-button" (click)="disconnectAllSections() ">{{locale('locale_key.general.buttons.remove_from_maser_table')}}</button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>

<ng-template #confirmConnect>
  <div class="modal-header btn-add">
    <h5 class="modal-title text-white" id="myModalLabel12">{{locale('locale_key.pages.data_request.review_answer_wizard.add_to_master_table_confirmation_modal.title')}}</h5>
    <button type="button" class="btn close py-0" [disabled]="loadingInProgress" (click)="closeModal();">
      <span aria-hidden="true" class="text-white" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{locale('locale_key.pages.data_request.review_answer_wizard.add_to_master_table_confirmation_modal.desc')}}</p>
    <p>{{locale('locale_key.pages.data_request.review_answer_wizard.connect_confirmation_modal.desc2')}}</p>
  </div>
  <div class="modal-footer">
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn secondary-button custom-button"
        (click)="closeModal()">{{locale('locale_key.pages.data_request.review_answer_wizard.connect_confirmation_modal.button.cancel')}}</button>
      <button type="button" class="btn primary-button custom-button"
        (click)="connectAllSections()">{{locale('locale_key.pages.data_request.review_answer_wizard.connect_confirmation_modal.button.add_to_master_table')}}</button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>

<ng-template #confirmClose>
  <div class="main-modal-header modal-header">
    <h4 class="modal-title text-white" id="myModalLabel12">
      {{ locale("locale_key.pages.data_request.close_request_confirmation_modal.title", { title: requestGroup.title }) }}
    </h4>
    <button type="button" class="btn close" *ngIf="!loadingInProgress" (click)="closeModal(); reviewMessage = ''">
      <span aria-hidden="true" class="text-white" style="font-size: 2rem; font-weight: 300">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <h5 class="p-1 text-justify">
        {{ locale("locale_key.pages.data_request.close_request_confirmation_modal.desc", { title: requestGroup.title }) }}
      </h5>
    </div>
    <div id="accordionMessage" class="border-shade">
      <h4>{{ locale("locale_key.pages.data_request.close_request_confirmation_modal.message.desc") }}</h4>
      <textarea class="form-control" rows="7" [(ngModel)]="reviewMessage"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal(); reviewMessage = ''">{{
        locale("locale_key.pages.data_request.close_request_confirmation_modal.button.cancel") }}</button>
      <button type="button" class="btn primary-button custom-button" (click)="closeRequest()">{{ locale("locale_key.pages.data_request.close_request_confirmation_modal.button.close") }}</button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>

<div *ngIf="inProgress">
  <div class="loader-wrapper mt-5">
    <div class="loader-container">
      <div class="ball-grid-pulse loader-primary">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>
<div #tableComponent [hidden]="inProgress">
  <!--FOR TABLE DROPDOWN-->
  <div *ngIf="isModeSelectRefTableData() && table.rows.length > 0">
    <form class="row">
      <input class="filter form-input" [ngClass]="{ col: isInsideTableDropdown }" id="filter" (input)="filterData()" type="text" [formControl]="filterText" placeholder="Search" />
      <span *ngIf="isInsideTableDropdown" class="me-3">
        <button (click)="handleRefRow('add')" class="btn btn-outline-info btn-block custom-button float-end d-inline me-1 m-0 text-truncate">
          {{ slocale(locale("locale_key.datagrid.table.add_new"), mainTableData.name) }}
        </button>
        <button (click)="handleRefRow('edit')" class="btn btn-outline-info btn-block custom-button float-end d-inline m-0 text-truncate" *ngIf="!isRefRowInMainTableEmpty">
          {{ slocale(locale("locale_key.datagrid.table.edit"), mainTableData.name) }}
        </button>
      </span>
    </form>
  </div>

  <!--PAGINATION-->
  <div *ngIf="!isModeExportExcelTableData() && dataGrid.hasMainTablePagination() && addedRows.length != 0 && deletedRows.length == 0" class="pagination">
    <div class="pageLimit d-inline">
      {{ locale("locale_key.datagrid.table.show") }}
      <select class="form-input" [(ngModel)]="table.pageSize" (change)="pagination('pageLimitChanged')" [disabled]="!isSaved">
        <option *ngFor="let number of [2, 10, 25, 50, 100]" [disabled]="number > table.rowCount! && table.pageSize >= table.rowCount!">{{ number }}</option>
      </select>
      {{ locale("locale_key.datagrid.table.rows") }}
    </div>
    <div class="d-inline ms-3">
      {{ slocale(locale("locale_key.datagrid.table.rows_info.pagination"), { start: currentItems + 1, end: currentItems + addedRows.length, total: table.rowCount }) }}
    </div>
  </div>

  <div *ngIf="isModeSelectRefTableData() && table.rows.length == 0; else showTable" class="ms-4 me-4">
    <h5 class="d-inline">{{ slocale(locale("locale_key.datagrid.table.no_table_name"), { table: table.name }) }}</h5>
    <button (click)="handleRefRow('add')" class="btn btn-outline-info btn-block custom-button float-end d-inline mb-1 m-0 text-truncate">
      {{ slocale(locale("locale_key.datagrid.table.add_new"), mainTableData.name) }}
    </button>
  </div>
  <!--TABLE-->


  <!--TABLE-->
<ng-container *ngIf="!isFormView;" >
  <ng-template #showTable>
    <div class="mainTable mb-0" [ngClass]="{ scrollTable: isModeExportExcelTableData() || (!isModeExportExcelTableData() && table.rows.length > 15), multiEntity: this.refTables.length > 0 && !isModeSelectRefTableData() }">
      <div
        class="tableContainer"
        (scrolled)="onScroll()"
        [ngClass]="{ scrollTable: isModeExportExcelTableData() || (!isModeExportExcelTableData() && table.rows.length > 15), isMapExtractedDataMode: isModeMapExtractedData() }"
        infinite-scroll
        [infiniteScrollDistance]="4"
        [infiniteScrollThrottle]="0"
        [infiniteScrollDisabled]="isModeEditTableData()"
        [scrollWindow]="false"
      >
        <table [attr.mode]="dataGrid.getMode()">
          <thead formArray="colAray">
            <tr class="header" [ngClass]="{ excel: isModeExportExcelTableData() || isModeMapExtractedData() }">
              <th class="stickySides b-shadow">
                <img style="height: 1.5rem" class="ms-1" src="/assets/img/logos/Logo-Sustainlab.png" alt="" *ngIf="!(isModeExportExcelTableData() || isModeMapExtractedData())" />
                <i class="la la-file-excel-o text-white" *ngIf="isModeExportExcelTableData() || isModeMapExtractedData()"></i>
              </th>
              <th *ngIf="table.dataSchema.length > 0" [ngClass]="{ white: isModeExportExcelTableData() || isModeMapExtractedData() }" [attr.colspan]="totalLength() - hiddenColumns.length" class="pl-1 b-shadow">
                {{ table.name ? table.name : "SustainLab" }}
                <p class="d-inline ps-2" *ngIf="!this.isSaved && (isModeEditTableData() || isModeEditRefTableData())">
                  <span>
                    <i class="la la-save font-medium-3"></i>
                    <i class="ft-alert-circle text-white p-0 font-small-3" style="background-color: var(--orange); border-radius: 50%; position: relative; top: 3px; right: 10px"></i>
                  </span>
                  {{ locale("locale_key.datagrid.table.not_saved_yet") }}
                </p>
                <button class="button selectEntireSheet position-relative text-white-button py-0" *ngIf="isModeExportExcelTableData()" (click)="selectFullSheet()">
                  <ng-container *ngIf="!isLoading"> <i class="la la-mouse-pointer"></i>{{ locale("locale_key.datagrid.mapping.option.select_entire_sheet") }} </ng-container>
                  <div class="ball-pulse loader-primary" *ngIf="isLoading">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </button>
              </th>
              <th *ngFor="let refTable of refTables" [attr.colspan]="refTable.dataSchema.length" class="bl-shadow">{{ refTable.name }}</th>
              <th class="stickySides" [ngClass]="refTables.length > 0 ? 'bl-shadow' : 'b-shadow'"></th>
            </tr>

            <tr class="colNames">
              <ng-container *ngIf="!(isModeExportExcelTableData() || isModeMapExtractedData()); else exportExcel">
                <th class="stickySides">
                  <!-- <i class="las la-angle-down ps-2" (click)="toggleDataTypeCollapse(dataTypeHeader)" aria-controls="dataTypeHeaderCollapse" [attr.aria-expanded]="!isDataTypeHeaderCollapsed"></i> -->

                  <div *ngIf="hiddenColumns.length > 0" dropdown [autoClose]="true" container="body">
                    <i class="las la-caret-square-right ps-2 unhideBtn" dropdownToggle></i>
                    <div *dropdownMenu class="card dropdown-menu" role="menu" style="width: 12rem; text-align: justify">
                      <div class="card-body p-0">
                        <p class="font-small-2 text-center m-0 border-b" *ngFor="let cols of hiddenColumns" (click)="unhideCol(cols)">{{ getColumnName(cols) }}</p>
                      </div>
                    </div>
                  </div>
                </th>
                <th
                  scope="col"
                  *ngFor="let column of colAray.controls; let i = index; let isLast = last"
                  class="tableData colData l-shadow"
                  [ngClass]="{ isSchemaMode: isModeEditTableSchema(), selectedCol: selectedColumn == i, newColBg: mainSchema[i].status == 'ADDED', 'd-none': hiddenColumns.includes(i) }"
                  [resizeColumn]="true"
                  [index]="i"
                  [width]="mainSchema[i].width"
                  (resized)="setPaginationWidth()"
                  [formGroup]="getElement(column)"
                  (dblclick)="isModeEditTableData() || isModeEditRefTableData() || i < sourceColsLength() ? selectColumn(i) : null"
                  (contextmenu)="sourceColsLength() > 0 && hiddenColumns.length < mainSchema.length - 1 ? openRightClickMenu(hideColMenu, $event) : null"
                  [attr.colSpan]="((isMeasurementType(i) && colDataTypes[i].showUnitCol) || isEFType(i)) && !colDataTypes[i].isCalculated ? 2 : 1"
                >
                  <i class="la la-fire-alt" *ngIf="isEFType(i)"></i>
                  <input
                    class="wt-700"
                    arrowDirective
                    [value]="getColumnName(i)"
                    readOnly
                    [id]="'col' + i + 'value'"
                    [ngClass]="{ removeBorder: !isModeEditTableSchema() }"
                    (click)="isModeEditTableSchema() && i < mainTableData.dataSchema.length ? editColumn(i) : ''"
                  />
                  <emission-factor
                    *ngIf="(isModeEditTableData() || isModeEditRefTableData()) && isEFType(i) && !colDataTypes[i].isPreDetermined"
                    showAsEditBtn="true"
                    isEditable="true"
                    insideTable="true"
                    (emissionFactorSelected)="setAllEmissionFactor(i, $event)"
                    [datapointEFUnit]="{ sourceUnit: colDataTypes[i].emissionFactor?.sourceUnit, conversionUnit: colDataTypes[i].emissionFactor?.conversionUnit }"
                  >
                  </emission-factor>
                  <ng-container *ngIf="isModeEditTableSchema() && i < mainTableData.dataSchema.length">
                    <a>
                      <i class="dot float-end" [ngClass]="mainSchema[i].deployed && !mainSchema[i].modified ? 'green' : !mainSchema[i].deployed ? 'yellow' : 'orange'"> </i>
                    </a>
                  </ng-container>

                  <div
                    class="triangle"
                    *ngIf="(isModeEditTableData() || isModeEditRefTableData()) && isColumnHasValidValues[i] == false"
                    dropdown
                    [autoClose]="true"
                    container="body"
                    dropdownToggle
                    (onShown)="onOpeningErrorMsg('columnInvalidDropdown')"
                  >
                    <div *dropdownMenu class="columnInvalidDropdown card dropdown-menu" role="menu" [id]="'col' + i + 'errorMsg'" style="width: 18rem; text-align: justify">
                      <div class="card-body p-1" *ngIf="colDataTypes[i].generic == 'number'">
                        <h5 class="card-title mb-1">
                          <i class="ft-alert-circle text-white p-0" style="font-size: 1.5rem; background-color: var(--orange); border-radius: 50%"></i>
                          {{ locale("locale_key.datagrid.table.invalid_numeric.value") }}
                        </h5>
                        <p class="card-text font-small-2">{{ locale("locale_key.datagrid.table.number.invalid.message") }}</p>
                      </div>
                      <div class="card-body p-1" *ngIf="colDataTypes[i].generic == 'date'">
                        <h5 class="card-title mb-1">
                          <i class="ft-alert-circle text-white p-0" style="font-size: 1.5rem; background-color: var(--orange); border-radius: 50%"></i>
                          {{ locale("locale_key.datagrid.table.invalid_date.value") }}
                        </h5>
                        <p class="card-text font-small-2">{{ locale("locale_key.datagrid.table.data.invalid.message") }}</p>
                      </div>
                    </div>
                  </div>
                  <div dropdown container="body" #hideColMenu="bs-dropdown" dropdownToggle [autoClose]="true" [insideClick]="false" (onHidden)="onClosingRightClickDropdown()" (onShown)="onOpeningRightClickDropdown(hideColMenu)">
                    <div *dropdownMenu class="dropdown-menu pb-0" role="menu">
                      <div class="card-body p-0" (click)="hideColumn(i)">
                        <p class="font-small-2 text-center"><i class="la-eye-slash font-small-1"></i> {{ locale("locale_key.datagrid.table.hide.column") }}<i></i></p>
                      </div>
                    </div>
                  </div>
                  <span class="headerDropDown" *ngIf="isViewWithPredefinedOptions() && colDataTypes[i].generic !== 'checkbox'">
                    <div class="btn-group" >
                      <div class="description-btn d-flex justify-content-end remove-arrow-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="la la-ellipsis-v size-15 mt-auto my-auto" ></i>
                      </div>
                      <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                        <button class="dropdown-item ms-0 ps-2 pe-2" type="button" (click)="onChangeHeaderInputSetting(i, editTitleDescriptionModal)">
                          <i class="la la-edit"></i>
                          <span class="mx-1">{{slocale("Edit column title and description")}} </span>
                        </button>
                        <button class="dropdown-item ms-0 ps-2 pe-2" type="button" (click)="onChangeHeaderInputSetting(i, predefinedOptionEditor)">
                          <i class="la la-cog"></i>
                          <span class="mx-1">{{slocale("Define input settings")}} </span>
                        </button>

                      </div>
                    </div>
                  </span>
                </th>
              </ng-container>
              <ng-template #exportExcel>
                <th class="stickySides"></th>
                <th
                  scope="col"
                  *ngFor="let column of colAray.controls; let i = index; let isLast = last"
                  class="tableData colData l-shadow"
                  [ngClass]="{ selectedCol: selectedColumn == i, newColBg: mainSchema[i].status == 'ADDED' }"
                  [resizeColumn]="true"
                  [index]="i"
                  [width]="mainSchema[i].width"
                  (resized)="setPaginationWidth()"
                  (dblclick)="isModeExportExcelTableData() ? selectDataFromColumn(i) : null"
                >
                  <input class="removeBorder" arrowDirective readonly [id]="'col' + i + 'value'" [value]="getColumnHeader(i)" />
                  <div class="mappedLabels truncate" [id]="'mappedLabels' + i" *ngIf="isColumnExtracted(i)" [ngClass]="{ hidden: !(mappedToColumn(i) && isModeMapExtractedData()) }">
                    To: <b>{{ mappedToColumn(i)?.label }}</b>
                  </div>
                </th>
              </ng-template>
              <th class="stickySides l-shadow">
                <a *ngIf="isModeEditTableSchema()" (click)="addColBelow(colSource.length - 1)" tooltip="Add new column" container="body" placement="bottom" containerClass="tooltipClass">
                  <i class="las la-plus-circle font-medium-5 p-absolute" style="top: 3px"></i>
                </a>
                <button *ngIf="isModeGetSelectedTableRows()" class="btn btn-info float-end selectRowBtn p-0" (click)="dataGrid.exportSelectedRows(addedRows)">{{ locale("locale_key.datagrid.table.select_all") }}</button>
              </th>
            </tr>
          </thead>
          <!-- <tbody class="dataTypesColumn" id="dataTypeHeaderCollapse" #dataTypeHeader="bs-collapse" [collapse]="false">
            <tr>
              <th class="stickySides">
                <a class="" tooltip='Data Type' placement='right' container="body">
                  <i class="la la-bars"></i>
                </a>
              </th>

              <ng-container  *ngFor="let column of colAray.controls;let i=index;let isLast=last">
                <th scope='col' class="tableData colData l-shadow"
                  [ngClass]='{"selectedCol": selectedColumn == i, "d-none": hiddenColumns.includes(i)}'>
                  <input class='wt-700' arrowDirective value="{{colDataTypes[i].getDataType()}}" readOnly>
                </th>
                <th scope='col' class="tableData colData l-shadow" *ngIf="isMeasurementType(i) && !colDataTypes[i].isCalculated"
                [ngClass]='{"selectedCol": selectedColumn == i, "d-none": hiddenColumns.includes(i)}'>
                  <input *ngIf="!(isModeEditTableData() || isModeEditRefTableData())"  class='wt-700' arrowDirective value="{{colDataTypes[i].getDataType()}}" readOnly>
                  <unit-selector *ngIf="isModeEditTableData() || isModeEditRefTableData()" [mode]="'select'" [selectBy]="'symbol'"  (selectedUnitChanged)="setAllUnit(i, $event?.symbol)"
                      [isHeader]="true" [includeMeasurementTypes]="getMeasurementType(i)" [customUnits]="getCustomUnits()" 
                      [unitsByMeasurementType]="getUnitsByMeasurementType()" [source]="'inside-table'">
                  </unit-selector>
                </th>
              </ng-container>

              <th class="stickySides l-shadow">
              </th>
            </tr>
          </tbody> -->
          <tbody class="dataTypesColumn">
            <tr>
              <th class="stickySides">
                <a class="" tooltip="Data Type" placement="right" container="body">
                  <i class="la la-bars"></i>
                </a>
              </th>

              <ng-container *ngFor="let column of colAray.controls; let i = index; let isLast = last">
                <th scope="col" class="tableData colData l-shadow" *ngIf="!isEFType(i)" [ngClass]="{ selectedCol: selectedColumn == i, 'd-none': hiddenColumns.includes(i) }">
                  <input class="wt-700" arrowDirective value="{{ getDatatype(colDataTypes[i].original) }}" readOnly />
                </th>
                <th scope="col" class="tableData colData l-shadow" *ngIf="isMeasurementType(i) && colDataTypes[i].showUnitCol && !colDataTypes[i].isCalculated" [ngClass]="{ selectedCol: selectedColumn == i, 'd-none': hiddenColumns.includes(i) }">
                  <input *ngIf="!(isModeEditTableData() || isModeEditRefTableData())" class="wt-700" arrowDirective value="{{ locale('locale_key.pages.data_category.unit') }}" readOnly />
                  <span *ngIf="containsPredefinedOptions(colDataTypes[i]) &&  (isModeEditTableData() || isModeEditRefTableData())">Unit</span>
                  <unit-selector
                    *ngIf="!containsPredefinedOptions(colDataTypes[i]) &&  (isModeEditTableData() || isModeEditRefTableData())"
                    [mode]="'select'"
                    [selectBy]="'symbol'"
                    (selectedUnitChanged)="setAllUnit(i, $event?.symbol)"
                    [isHeader]="true"
                    [includeMeasurementTypes]="getMeasurementType(i)"
                    [customUnits]="getCustomUnits()"
                    [unitsByMeasurementType]="getUnitsByMeasurementType()"
                    [source]="'inside-table'"
                  >
                  </unit-selector>
                </th>
                <ng-container *ngIf="isEFType(i)">
                  <th scope="col" class="tableData colData l-shadow" [ngClass]="{ selectedCol: selectedColumn == i, 'd-none': hiddenColumns.includes(i) }">
                    <input class="wt-700" arrowDirective value="Conversion factor" readOnly />
                  </th>
                  <th scope="col" class="tableData colData l-shadow" [ngClass]="{ selectedCol: selectedColumn == i, 'd-none': hiddenColumns.includes(i) }">
                    <input class="wt-700" arrowDirective value="{{ locale('locale_key.pages.insight_detail.unit') }}" readOnly />
                  </th>
                </ng-container>
              </ng-container>

              <th class="stickySides l-shadow"></th>
            </tr>
          </tbody>
          <tbody
            dragSelect
            (click)="wasInside = true"
            formArray="rowArray"
            [mode]="dataGrid.getMode()"
            (selectedData)="markSelectedData($event)"
            [startSelectionForCopying]="startSelectionForCopying"
            (pasteComplete)="pasteCompleted($event)"
            [addedRows]="addedRows"
            [exportIt]="exportIt"
            [rowArray]="rowArray"
            [tableComponent]="tableComponent"
          >
            <ng-container *ngFor="let element of rowArray.controls; let rowNo = index; let even = even; let isFirst = first; let isLast = last">
              <tr id="{{ 'row' + rowNo }}" [formGroup]="getElement(element)" class="table-row" [ngClass]="{ pointer: isModeSelectRefTableData() && !dataGrid.hasDataModifiedElseWhere(), evenBg: even, lastCellOfCol: isLast }">
                <th
                  class="stickySides rowNumber"
                  all
                  (dblclick)="isModeEditTableData() || isModeEditRefTableData() ? selectRow(rowNo) : isModeExportExcelTableData() ? selectDataFromRow(rowNo) : null"
                  [ngClass]="{ newRowBg: addedRows[rowNo].status == 'ADDED', selectedRow: selectedRow == rowNo }"
                >
                  <span style="font-size: 0.8rem" class="rowNumber">{{ currentItems + rowNo + 1 }}</span>
                </th>

                <ng-container *ngFor="let col of colSource; let i = index">
                  <!--row values of cols from dataSchema-->
                  <td scope='row' *ngIf='i<mainTableData.dataSchema.length' [ngClass]='getClasses(i,rowNo,even)' [class]="'col'+i" class="tableData" id='{{"col"+i+"row"+rowNo}}'
                    (contextmenu)="isModeEditTableData() || isModeEditRefTableData() || isModeEditTableSchema() || isModeExportExcelTableData() ? openRightClickMenu(rightClickMenu, $event) : (isModeMapExtractedData() && isColumnExtracted(i) ? openRightClickMenu(mappingMenu, $event) : null)"
                    (click)='(isModeSelectTableRow() ? openTraceSource(rowNo, traceToSourceMenu, $event) : null)'
                    [attr.colspan]="!getEmissionFactor(i, addedRows[rowNo].rowId) && isEFType(i) && (isModeEditTableData() || isModeEditRefTableData()) || (containsPredefinedOptions(colDataTypes[i]) && (colDataTypes[i].generic =='EMISSION_FACTOR' ||  (colDataTypes[i].generic =='number' && colDataTypes[i]?.predefinedOptions?.[0]?.value != null))) ? 2 : 1">
                    <div class="d-flex justify-content-center">
                      <div dropdown container="body" #rightClickMenu="bs-dropdown" dropdownToggle (onHidden)="onClosingRightClickDropdown()" (onShown)="onOpeningRightClickDropdown(rightClickMenu)">
                        <ul *dropdownMenu class="dropdown-menu rightClickMenu" role="menu" id="{{ 'col' + i + 'row' + rowNo + 'rightClickMenu' }}" [ngClass]="{ isSchemaMode: isModeEditTableSchema() }">
                          <ng-container *ngIf="isModeEditTableSchema()">
                            <li class="px-2" role="menuitem" (click)="addColBelow(i - 1)"><i class="la la-chevron-left"></i>{{ locale("locale_key.datagrid.table.insert_column.before") }}</li>
                            <li class="px-2" role="menuitem" (click)="addColBelow(i)"><i class="la la-chevron-right"></i>{{ locale("locale_key.datagrid.table.insert_column.after") }}</li>
                            <li class="px-2" role="menuitem" (click)="del('col', i)"><i class="la la-trash"></i>{{ locale("locale_key.datagrid.table.delete.column") }}</li>
                          </ng-container>
                          <ng-container *ngIf="isModeEditTableData() || isModeEditRefTableData()">
                            <li class="px-2" role="menuitem" (click)="addRowAfter(rowNo - 1)"><i class="la la-chevron-up"></i>{{ locale("locale_key.datagrid.table.insert_row.above") }}</li>
                            <li class="px-2" role="menuitem" (click)="addRowAfter(rowNo)"><i class="la la-chevron-down"></i>{{ locale("locale_key.datagrid.table.insert_row.below") }}</li>
                            <li class="px-2" role="menuitem" (click)="del('row', rowNo, addedRows[rowNo].rowId)"><i class="la la-trash"></i>{{ locale("locale_key.datagrid.table.delete.row") }}</li>
                            <div class="dropdown-divider mx-1"></div>
                            <li class="px-2" role="menuitem" (click)="copyData(rowNo, i)"><i class="la la-copy"></i>{{ locale("locale_key.datagrid.table.copy") }}</li>
                            <li class="px-2" role="menuitem" (click)="pasteData(rowNo, i)"><i class="la la-paste"></i>{{ locale("locale_key.datagrid.table.paste") }}</li>
                            <li class="px-2" role="menuitem" (click)="copyData(rowNo, i, true)"><i class="la la-cut"></i>{{ locale("locale_key.datagrid.table.cut") }}</li>
                            <div class="dropdown-divider mx-1"></div>
                            <li class="px-2" role="menuitem" (click)="deleteAllRows()"><i class="la la-close"></i>{{ locale("locale_key.datagrid.table.delete_all.rows") }}</li>
                          </ng-container>
                          <ng-container *ngIf="isModeExportExcelTableData()">
                            <ng-container *ngIf="isDataSelected(); else headline">
                              <li class="px-2" role="menuitem" (click)="deselectColumn(i)" *ngIf="!isDeselectedColumn(i)"><i class="la la-ban"></i>{{ locale("locale_key.datagrid.table.deselect.column") }}</li>
                              <li class="px-2" role="menuitem" (click)="reselectColumn(i)" *ngIf="isDeselectedColumn(i)"><i class="la la-clone"></i>{{ locale("locale_key.datagrid.table.reselect.column") }}</li>
                              <li class="px-2" role="menuitem" (click)="deselectRow(rowNo)" *ngIf="!isDeselectedRow(rowNo)"><i class="la la-ban"></i>{{ locale("locale_key.datagrid.table.deselect.row") }}</li>
                              <li class="px-2" role="menuitem" (click)="reselectRow(rowNo)" *ngIf="isDeselectedRow(rowNo)"><i class="la la-clone"></i>{{ locale("locale_key.datagrid.table.reselect.row") }}</li>
                            </ng-container>
                            <ng-template #headline>
                              <li class="px-2" role="menuitem" (click)="markAsHeadline(rowNo)" *ngIf="!isHeadlineRow(rowNo)"><i class="la la-header"></i>{{ locale("locale_key.datagrid.table.mark_as.headline") }}</li>
                              <li class="px-2" role="menuitem" (click)="removeHeadline(rowNo)" *ngIf="isHeadlineRow(rowNo)"><i class="la la-ban"></i>{{ locale("locale_key.datagrid.table.remove_as.headline") }}</li>
                            </ng-template>
                          </ng-container>
                        </ul>
                      </div>
                      <ng-container *ngIf="i == 0 && sourceColsLength() > 0">
                        <div class="icon-circle-dark me-2 my-auto" style="position: absolute; left: 60px; top: 2px" *ngIf="isSourceDataRequest(addedRows[rowNo].values[i])" title="{{ locale('locale_key.datagrid.table.data_reporter.submission') }}">
                          <span class="text-white"> <i class="la la-user fs-4 bold"></i> </span>
                        </div>
                        <i
                          class="la la-keyboard-o my-auto mx-2"
                          style="font-size: 2rem; position: absolute; left: 50px; top: -1px"
                          title="{{ locale('locale_key.datagrid.table.direct.entry') }}"
                          *ngIf="isSourceDirectEntry(addedRows[rowNo].values[i])"
                        ></i>
                        <i
                          class="la la-desktop my-auto mx-2"
                          style="font-size: 2rem; position: absolute; left: 50px; top: -1px"
                          title="{{ locale('locale_key.datagrid.table.automated.data') }}"
                          *ngIf="isSourceAutomatedFeed(addedRows[rowNo].values[i])"
                        ></i>
                      </ng-container>
                      <ng-container *ngIf="!(i == 0 && sourceColsLength() > 0)">
                        <span *ngIf="(isModeEditTableData() || isModeEditRefTableData()) && colDataTypes[i].isCalculated" class="calculatedDataInfo_message">
                          {{ locale("locale_key.master_table.available_in_master_table.message") }}
                        </span>
                        <div *ngIf="(isModeEditTableData() || isModeEditRefTableData()) && colDataTypes[i].isCalculated" class="info calculatedDataInfo_info" style="display: flex; align-items: center">
                          <i class="la la-info-circle dropdown-toggle info-popover-arrow fs-5" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                          <div class="dropdown-menu arrow p-2 info-popover" style="width: 18rem">
                            <p>{{ locale("locale_key.master_table.once_you_saved_direct_entry.message") }}</p>
                          </div>
                        </div>

                        <!-- Predefined Options functionality  -->
                        <ng-container *ngIf="containsPredefinedOptions(colDataTypes[i])" class="form-group position-relative ms-0 m-0 p-0">
                          <div class="dropdown d-grid w-100  predefined-list-container" container="body" placement="top right"  *ngIf="colDataTypes[i].generic !='number' || (colDataTypes[i].generic =='number' && colDataTypes[i]?.predefinedOptions?.[0]?.value != null)">
                              <button class="btn btn-block dropdown-toggle  rounded-0 dropdown-toggle" type="button" data-bs-auto-close="true" data-bs-toggle="dropdown" aria-expanded="true"  (click)="isCustomOptionAdded = false">
                                <div class="d-flex justify-content-between">
                                  <div class="predefined-option-value">
                                    {{
                                      emissionFactorArray[addedRows[rowNo].rowId]["col" + i] ?  emissionFactorArray[addedRows[rowNo].rowId]["col" + i].conversionFactor + " " + emissionFactorArray[addedRows[rowNo].rowId]["col" + i].conversionUnit + " " + slocale('of') + " " + emissionFactorArray[addedRows[rowNo].rowId]["col" + i].sourceName
                                      :
                                      getControl(element,'col' + i).value
                                        ? getControl(element,'col' + i).value
                                        : slocale("Select Options")
                                    }}
                                  </div>
                                  <i class="las la-angle-down"></i>
                                </div>
                              </button>

                            <ul class="dropdown-menu p-0" aria-labelledby="defaultDropdown" *ngIf="colDataTypes[i].generic =='date' || colDataTypes[i].generic =='range'">
                              <li *ngFor="let option of colDataTypes[i].predefinedOptions; let predefinedOptionsIndex = index" class="dropdown-item border-bottom"  
                              (click)="setPredefinedDateColValue(rowNo, i, option)"
                              >
                              <span *ngIf="colDataTypes[i]?.generic=='date'" class="dropdown-item user-select-none p-0">
                                {{ option.from | date: 'dd MMM, yyyy' }}
                              </span>
                              <span *ngIf="colDataTypes[i]?.generic=='range'" class="dropdown-item user-select-none p-0">
                                {{ option.from | date: 'dd MMM, yyyy' }} {{ slocale('to') }} {{ option.to | date: 'dd MMM, yyyy' }}
                              </span>
                              </li>
                              <!-- Add new option-->
                              <li (click)="$event.stopPropagation()" *ngIf="mainSchema[i]?.customValuesAllowedWhenPredefinedOptionsSet">
                                <div *ngIf="!isCustomOptionAdded" class="dropdown-item user-select-none" (click)="isCustomOptionAdded = true; tempPredefinedOptions[i] = null">
                                  <i class="icon las la-plus p-2"></i>
                                  <span class="add_label">{{ slocale("Add New") }}</span>
                                </div>
                                <div *ngIf="isCustomOptionAdded" class="dropdown-item p-0">
                                  <input type="date" [(ngModel)]="tempPredefinedOptions[i]"  class="form-control input-m" placeholder="dd MMM, yyyy" [ngModelOptions]="{standalone: true}" />
                                  <div class="user-select-none d-flex align-items-center ms-auto" (click)="addNewPredefinedOptions(i)">
                                    <i class="icon las la-plus" style="font-size: 1em; -webkit-text-stroke: 1px;"></i>
                                    <span class="add_label ms-2">{{ slocale("Add") }}</span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <!-- Number predefined options-->
                            <ul class="dropdown-menu sticky-top wider-dropdown-menu p-0" aria-labelledby="dropdownMenuButton" *ngIf="colDataTypes[i].generic =='number'">
                              <li *ngFor="let option of colDataTypes[i]?.predefinedOptions; let predefinedOptionsIndex = index" class="border-bottom" >
                                <span
                                  *ngIf="option.value == null"
                                  class="dropdown-item user-select-none"
                                  (click)="setPredefinedNumberColValue(rowNo, i, 'unit', option)"
                                >
                                  {{ option.unit || noUnitEnum.SYMBOL}}
                                </span>
                                <span
                                  *ngIf="option.value != null"
                                  class="dropdown-item user-select-none"
                                  (click)="setPredefinedNumberColValue(rowNo, i, 'unit-value', option)"
                                >
                                  {{ option.value }} <strong>{{ option.unit || noUnitEnum.SYMBOL}}</strong>
                                </span>
                              </li>
                               <!-- Add new option-->
                               <li (click)="$event.stopPropagation()" *ngIf="mainSchema[i]?.customValuesAllowedWhenPredefinedOptionsSet">
                                <div *ngIf="!isCustomOptionAdded" class="dropdown-item user-select-none" (click)="isCustomOptionAdded = true; tempPredefinedOptions[i] = null">
                                  <i class="icon las la-plus p-2"></i>
                                  <span class="add_label">{{ slocale("Add New") }}</span>
                                </div>
                                <div *ngIf="isCustomOptionAdded" class="dropdown-item p-0">
                                  <input type="number" class="form-control input-m" placeholder="Number"
                                  (input)="addNewPredefinedOptionsWithUnitNumber(i, $event)" 
                                  />
                                  <unit-selector [mode]="'select'" [selectBy]="'symbol'" (selectedUnitChanged)="addNewPredefinedOptionsWithUnit(i, $event.symbol)"
                                  [source]="'add_datapoint_modal'" 
                                  [includeMeasurementTypes]="getMeasurementType(i)" [customUnits]="getCustomUnits()"
                                  [unitsByMeasurementType]="getUnitsByMeasurementType()" 
                                  [selectedUnitSymbol]="colDataTypes[i]?.predefinedOptions[0]?.unit"
                                  [source]="'inside-table'"
                                  >
                                </unit-selector>



                                  <div class="user-select-none d-flex align-items-center ms-auto" (click)="addNewPredefinedOptions(i)">
                                    <i class="icon las la-plus" style="font-size: 1em; -webkit-text-stroke: 1px;"></i>
                                    <span class="add_label ms-2">{{ slocale("Add") }}</span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <!-- Emission Factor predefined options-->
                            <ul class="dropdown-menu sticky-top wider-dropdown-menu p-0" aria-labelledby="dropdownMenuButton" *ngIf="colDataTypes[i].generic =='EMISSION_FACTOR'">
                              <li *ngFor="let option of colDataTypes[i].predefinedOptions; let emissionFactorIndex = index" class="border-bottom">
                                <span class="dropdown-item user-select-none" (click)="setPredefinedEmissionFactorColValue(rowNo, addedRows[rowNo].rowId, i, option)">
                                  <span> {{option.selectedEmissionFactor?.conversionFactor}} {{option.selectedEmissionFactor?.conversionUnit}} {{slocale('of')}} {{option.selectedEmissionFactor?.sourceName}}</span>
                                </span>
                              </li>
                               <!-- Add new option-->
                               <li (click)="$event.stopPropagation()" *ngIf="mainSchema[i]?.customValuesAllowedWhenPredefinedOptionsSet">
                                <div *ngIf="!isCustomOptionAdded" class="dropdown-item user-select-none" (click)="isCustomOptionAdded = true; tempPredefinedOptions[i] = null">
                                  <i class="icon las la-plus p-2"></i>
                                  <span class="add_label">{{ slocale("Add New") }}</span>
                                </div>

                                <div *ngIf="isCustomOptionAdded" class="dropdown-item user-select-none" >
                                  <emission-factor isEditable=true  (emissionFactorSelected)="addNewPredefinedOptions(i, $event, rowNo)"
                                  [datapointEFUnit]="{sourceUnit : colDataTypes[i].emissionFactor?.sourceUnit, conversionUnit : colDataTypes[i].emissionFactor?.conversionUnit}">
                                  </emission-factor>
                                </div>

                              </li>
                            </ul>

                            <!-- Text predefined options-->
                            <ul class="dropdown-menu p-0" aria-labelledby="defaultDropdown" *ngIf="colDataTypes[i].generic =='text'">
                              <li *ngFor="let option of colDataTypes[i].predefinedOptions; let predefinedOptionsIndex = index" class="dropdown-item border-bottom"  
                              (click)="setPredefinedTextColValue(rowNo, i, option)"
                              >
                              <div class="dropdown-item user-select-none dropdown-text-item">
                                {{ option.text }}
                              </div>
                              </li>
                                <!-- Add new option-->
                                <li (click)="$event.stopPropagation()" *ngIf="mainSchema[i]?.customValuesAllowedWhenPredefinedOptionsSet">
                                  <div *ngIf="!isCustomOptionAdded" class="dropdown-item user-select-none" (click)="isCustomOptionAdded = true; tempPredefinedOptions[i] = null">
                                    <i class="icon las la-plus p-2"></i>
                                    <span class="add_label">{{ slocale("Add New") }}</span>
                                  </div>
                                  <div *ngIf="isCustomOptionAdded" class="dropdown-item">
                                    <input type="text" [(ngModel)]="tempPredefinedOptions[i]"  class="form-control input-m" placeholder="text" [ngModelOptions]="{standalone: true}" />
                                    <div class="user-select-none d-flex align-items-center ms-auto" (click)="addNewPredefinedOptions(i)">
                                      <i class="icon las la-plus" style="font-size: 1em; -webkit-text-stroke: 1px;"></i>
                                      <span class="add_label ms-2">{{ slocale("Add") }}</span>
                                    </div>
                                  </div>
                                </li>
                            </ul>
                          </div>
                        </ng-container>
                        <!-- Predefined option dropdown ended.-->

                        <input *ngIf='colDataTypes[i].generic!="checkbox" && colDataTypes[i].generic!="date" && !((isModeEditTableData() || isModeEditRefTableData()) && colDataTypes[i].isCalculated) && !isEFType(i) && !containsPredefinedOptions(colDataTypes[i]) || (containsPredefinedOptions(colDataTypes[i]) &&  colDataTypes[i].generic=="number" && colDataTypes[i]?.predefinedOptions?.[0]?.value == null)' (change)='changeColor(rowNo,i);' class="rowData text-truncate cursor long-text-input" arrowDirective [formControlName]="'col'+i"
                          id='{{"col"+i+"row"+rowNo+"value"}}' readOnly type="{{colDataTypes[i].generic=='number'?'text':colDataTypes[i].generic}}" [attr.colType]='colDataTypes[i].generic'
                          [attr.rowNo]="rowNo" [attr.colNo]="i" [ngClass]='{"removeBorder":!(isModeEditTableData() || isModeEditRefTableData()), "valueInput": isMeasurementType(i),
                        "headline": this.isModeExportExcelTableData() && this.isHeadlineRow(rowNo)}'
                          (input)='handleInputChange($event, rowNo, i)'
                          (paste)="handleInputChange($event, rowNo, i)"
                          (click)='handleLongTextClick($event, rowNo, i)' [colType]='colDataTypes[i].generic'
                          (keypress)="handleKeyPress($event, rowNo, i)"
                          (dragstart)="handleDragStart($event, rowNo, i)"
                          (drop)="handleDrop($event, rowNo, i)"
                          (keydown)="handleKeyDown($event, rowNo, i)"
                          (dragover)="$event.preventDefault()"
                          validateData
                          (dblclick)="!(isModeEditTableData() || isModeEditRefTableData()) && colDataTypes[i].generic === 'text' ? openTextModalNonEditable($event, rowNo, i): expandTextInput($event, rowNo, i);">  

                          <input *ngIf='colDataTypes[i].generic=="date" && !containsPredefinedOptions(colDataTypes[i])' (change)='changeColor(rowNo,i)' class="rowData" arrowDirective [formControlName]="'col'+i" id='{{"col"+i+"row"+rowNo+"value"}}'
                          readOnly type="{{isDateValue(rowNo, i) ||isCellEditable( 'col'+i+'row'+rowNo ) ?'date':'text' }}" [attr.colType]='colDataTypes[i].generic' [attr.rowNo]="rowNo"
                          [attr.colNo]="i" [ngClass]='{"removeBorder":!(isModeEditTableData() || isModeEditRefTableData()), "wt-700": this.isModeExportExcelTableData() && this.isHeadlineRow(rowNo)}'
                          (click)='(isModeEditTableData() || isModeEditRefTableData())?removeReadOnly($event.target,"row"):""' [colType]='colDataTypes[i].generic' validateData>

                        <input *ngIf='colDataTypes[i].generic=="checkbox"' (change)='changeColor(rowNo,i)' class="rowData form-input" arrowDirective [formControlName]="'col'+i"
                          id='{{"col"+i+"row"+rowNo+"value"}}' readOnly type="checkbox" [attr.colType]='colDataTypes[i].generic' [attr.rowNo]="rowNo" [attr.colNo]="i"
                          [attr.disabled]="(isModeEditTableData() || isModeEditRefTableData())? null : true"
                          [ngClass]='{"removeBorder":!(isModeEditTableData() || isModeEditRefTableData()), "wt-700": this.isModeExportExcelTableData() && this.isHeadlineRow(rowNo)}'
                          (click)='(isModeEditTableData() || isModeEditRefTableData())?removeReadOnly($event.target,"row"):""' [checked]="getControl(element,'col'+i).value"
                          [colType]='colDataTypes[i].generic' validateData>

                        <!-- BEGIN :: PRE_DETERMINED EMISSION FACTOR CONVERSION FACTOR OUTPUT -->
                        <ng-container *ngIf="isEFType(i) && colDataTypes[i].isPreDetermined && isMultipleEFType(i)">
                          <ng-container *ngIf="isModeEditTableData() || isModeEditRefTableData()">
                            <span class="calculatedDataInfo_message">
                              {{ locale("locale_key.master_table.available_in_master_table.message") }}
                            </span>
                            <div class="info calculatedDataInfo_info" style="display: flex; align-items: center">
                              <i class="la la-info-circle dropdown-toggle info-popover-arrow fs-5" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                              <div class="dropdown-menu arrow p-2 info-popover" style="width: 18rem">
                                <p>{{ locale("locale_key.master_table.once_you_saved_direct_entry.message") }}</p>
                              </div>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="!(isModeEditTableData() || isModeEditRefTableData())">
                            <span>
                              <input
                                class="rowData"
                                arrowDirective
                                [value]="getEmissionFactor(i, addedRows[rowNo].rowId)?.conversionFactor"
                                id="{{ 'col' + i + 'row' + rowNo + 'value' }}"
                                readOnly
                                [attr.rowNo]="rowNo"
                                [attr.colNo]="i"
                                [ngClass]="{ removeBorder: !(isModeEditTableData() || isModeEditRefTableData()), headline: this.isModeExportExcelTableData() && this.isHeadlineRow(rowNo) }"
                                [colType]="colDataTypes[i].generic"
                                validateData
                              />
                            </span>
                          </ng-container>
                        </ng-container>

                        <ng-container *ngIf="isEFType(i) && colDataTypes[i].isPreDetermined && !isMultipleEFType(i)">
                          <span>
                            <input
                              class="rowData"
                              arrowDirective
                              [value]="getEmissionFactor(i, addedRows[rowNo].rowId)?.conversionFactor"
                              id="{{ 'col' + i + 'row' + rowNo + 'value' }}"
                              readOnly
                              [attr.rowNo]="rowNo"
                              [attr.colNo]="i"
                              [ngClass]="{ removeBorder: !(isModeEditTableData() || isModeEditRefTableData()), headline: this.isModeExportExcelTableData() && this.isHeadlineRow(rowNo) }"
                              [colType]="colDataTypes[i].generic"
                              validateData
                            />
                          </span>
                        </ng-container>
                        <!-- END :: PRE_DETERMINED EMISSION FACTOR CONVERSION FACTOR OUTPUT -->

                        <ng-container *ngIf="isEFType(i) && !colDataTypes[i].isPreDetermined && !colDataTypes[i].predefinedOptions.length">
                          <ng-container *ngIf="getEmissionFactor(i, addedRows[rowNo].rowId); else noEf">
                            <input
                              class="rowData"
                              arrowDirective
                              [value]="getEmissionFactor(i, addedRows[rowNo].rowId)?.conversionFactor"
                              id="{{ 'col' + i + 'row' + rowNo + 'value' }}"
                              readOnly
                              [attr.rowNo]="rowNo"
                              [attr.colNo]="i"
                              [ngClass]="{ removeBorder: !(isModeEditTableData() || isModeEditRefTableData()), headline: this.isModeExportExcelTableData() && this.isHeadlineRow(rowNo) }"
                              [colType]="colDataTypes[i].generic"
                              validateData
                            />
                            <emission-factor
                              *ngIf="isModeEditTableData() || isModeEditRefTableData()"
                              showAsEditBtn="true"
                              isEditable="true"
                              insideTable="true"
                              (emissionFactorSelected)="setEmissionFactor(i, rowNo, addedRows[rowNo].rowId, $event)"
                              [datapointEFUnit]="{ sourceUnit: colDataTypes[i].emissionFactor?.sourceUnit, conversionUnit: colDataTypes[i].emissionFactor?.conversionUnit }"
                            >
                            </emission-factor>
                          </ng-container>
                          <ng-template #noEf>
                            <emission-factor
                              *ngIf="isModeEditTableData() || isModeEditRefTableData()"
                              isEditable="true"
                              insideTable="true"
                              (emissionFactorSelected)="setEmissionFactor(i, rowNo, addedRows[rowNo].rowId, $event)"
                              [datapointEFUnit]="{ sourceUnit: colDataTypes[i].emissionFactor?.sourceUnit, conversionUnit: colDataTypes[i].emissionFactor?.conversionUnit }"
                            >
                            </emission-factor>
                          </ng-template>
                        </ng-container>
                      </ng-container>

                      <div class="box" *ngIf="(isModeEditTableData() || isModeEditRefTableData()) && getControl(element,'col'+i).invalid" dropdown [autoClose]="true" container="body" dropdownToggle
                        (onShown)="onOpeningErrorMsg('errDropdown')">
                        <div class="triangle">
                          <div *dropdownMenu class="errDropdown card dropdown-menu" role="menu" [id]="'col' + i + 'row' + rowNo + 'errorMsg'" style="width: 18rem; text-align: justify">
                            <div class="card-body p-1" *ngIf="colDataTypes[i].generic == 'number'">
                              <h5 class="card-title mb-1">
                                <i class="ft-alert-circle white p-0" style="font-size: 1.5rem; background-color: var(--orange); border-radius: 50%"></i>
                                {{ locale("locale_key.datagrid.table.invalid_numeric.value") }}
                              </h5>
                              <p class="card-text font-small-2">{{ locale("locale_key.datagrid.table.number.invalid.message") }}</p>
                            </div>
                            <div class="card-body p-1" *ngIf="colDataTypes[i].generic == 'date'">
                              <h5 class="card-title mb-1">
                                <i class="ft-alert-circle white p-0" style="font-size: 1.5rem; background-color: var(--orange); border-radius: 50%"></i>
                                {{ locale("locale_key.datagrid.table.invalid_date.value") }}
                              </h5>
                              <p class="card-text font-small-2">{{ locale("locale_key.datagrid.table.data.invalid.message") }}</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="corner-box" *ngIf="isCellEditable('col' + i + 'row' + rowNo)" (mousedown)="startSelection()"></div>

                      <div dropdown container="body" #mappingMenu="bs-dropdown" dropdownToggle [autoClose]="true" [insideClick]="true" (onHidden)="onClosingRightClickDropdown($event)" (onShown)="onOpeningRightClickDropdown(mappingMenu)">
                        <div *dropdownMenu class="dropdown-menu mappingModal" role="menu">
                          <div class="card-body p-1">
                            <p class="font-small-3" [innerHTML]="locale('locale_key.datagrid.mapping.dropdown.desc', { columnHeader: getColumnHeader(i) })"></p>
                            <select class="form-control" (change)="mappedToColSelected($event.target, i)" [value]="mappedToColumnIndex(i)">
                              <ng-container *ngFor="let y of mappedToCols; let x = index">
                                <option [value]="x" [selected]="x == mappedToColumnIndex(i)">
                                  {{ y.col.label }}
                                </option>
                              </ng-container>
                              <option value="-1">{{ locale("locale_key.datagrid.mapping.option.do_not_insert") }}</option>
                            </select>
                            <p class="font-small-2 danger" *ngIf="mappedToColumn(i) && isModeMapExtractedData() && !areValuesValid(i)">
                              {{ locale("locale_key.datagrid.table.selected_column_requires", { type: mappedToColumn(i)?.type == "DATE" ? "date" : "number" }) }}
                            </p>
                            <ng-conatiner *ngIf="mappedToColumn(i)?.type == 'NUMERIC' || mappedToColumn(i)?.type == 'FLOAT'">
                              <p>{{ locale("locale_key.datagrid.table.what_units_this.column") }}</p>
                              <span>{{ locale("locale_key.datagrid.table.make_sure_all.data") }}</span>
                              <unit-selector
                                [mode]="'select'"
                                [selectBy]="'symbol'"
                                [shouldBeConvertible]="true"
                                (createCustomUnitModalOpened)="hideRightClickDropdown()"
                                (selectedUnitChanged)="setUnitForMappedToCol(i, $event?.symbol)"
                                [selectedUnitSymbol]="getUnitForMappedToCol(i)"
                                [includeMeasurementTypes]="getMeasurementTypeForMappedToCol(i)"
                                [customUnits]="getCustomUnits()"
                                [unitsByMeasurementType]="getUnitsByMeasurementType()"
                                [source]="'add_datapoint_modal'"
                              >
                              </unit-selector>
                            </ng-conatiner>
                          </div>
                        </div>
                      </div>

                      <div dropdown container="body" #traceToSourceMenu="bs-dropdown" dropdownToggle [autoClose]="true" [insideClick]="false" (onHidden)="onClosingRightClickDropdown()" (onShown)="onOpeningRightClickDropdown(traceToSourceMenu)" >
                        <div *dropdownMenu class="dropdown-menu pb-0" role="menu" id="dropdownMenu">
                          <div class="card-body p-0"  >
                            <span>
                              <p class="fs-11px mb-0 text-center cursor" (click)="dataGrid.exportSelectedRows([this.addedRows[rowNo]])"
                             
                              >{{ locale("locale_key.pages.trace_data_modal.table.button.trace_source") }}</p>
                              <p class="fs-11px border-t my-1 text-center cursor" (click)="dataGrid.overWriteEf(this.addedRows[rowNo], i)" *ngIf="isEFType(i) && !colDataTypes[i].isCalculated && isSourceDataRequest(addedRows[rowNo].values[0])">
                                {{ locale("locale_key.master_table.overwrite.emission_factor") }}
                              </p>
                              <p class="fs-11px border-t my-1 text-center cursor" (click)="dataGrid.showEfDetails(this.addedRows[rowNo], i)" *ngIf="isEFType(i)">{{ locale("locale_key.pages.data_categories_formula.show_emission_factor_info") }}</p>
                              <p class="fs-11px border-t my-1 text-center cursor" (click)="openRightClickMenu(calculation, $event)" *ngIf="colDataTypes[i].isCalculated">{{ locale("locale_key.pages.data_categories.show_calculation") }}</p>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        dropdown
                        container="body"
                        #calculation="bs-dropdown"
                        dropdownToggle
                        [autoClose]="true"
                        [insideClick]="true"
                        (onHidden)="showCalculation = false; onClosingRightClickDropdown()"
                        (onShown)="onOpeningRightClickDropdown(calculation)"
                      >
                        <div *dropdownMenu class="dropdown-menu p-2 scroll" role="menu" style="max-width: 20rem">
                          <div class="mb-1">
                            <span class="bold">{{ colAray.value[i]["col" + i] }}</span>
                          </div>
                          <calculation-builder [showEditMode]="false" [showFormulaOnly]="true" [selectedDatapoint]="getEntityCol(i)" [showCalculationErrors]="false"> </calculation-builder>
                        </div>
                      </div>
                    </div>
                  </td>

                                    <!-- UNITS  -->
                  <td scope='row' *ngIf="isMeasurementType(i) && colDataTypes[i].showUnitCol && !colDataTypes[i].isCalculated && colDataTypes[i]?.predefinedOptions?.[0]?.value == null" [ngClass]='getClasses(i,rowNo,even)' [class]="'col'+i" class="tableData unitDropdown"  id='{{"col"+i+"row"+rowNo+"unit"}}'
                  (click)="setUnitSelectorPos($event)"
                   [ngClass]="{'l-shadow': isModeEditTableData() || isModeEditRefTableData()}">
                   <ng-container *ngIf="containsPredefinedOptions(colDataTypes[i]) && isModeEditTableData()"> {{getUnit(i,addedRows[rowNo].rowId) || noUnitEnum.SYMBOL}}</ng-container>


                         <!-- Predefined Options functionality  -->
                         <ng-container *ngIf="containsPredefinedOptions(colDataTypes[i])" class="form-group position-relative ms-0 m-0 p-0">
                          <div class="dropdown d-grid w-100"  *ngIf="colDataTypes[i].generic =='number' && colDataTypes[i]?.predefinedOptions?.[0]?.value != null">
                              <button class="btn btn-block dropdown-toggle  rounded-0 dropdown-toggle" type="button" data-bs-auto-close="true" data-bs-toggle="dropdown" aria-expanded="false"  (click)="isCustomOptionAdded = false">
                                <div class="d-flex justify-content-between">
                                  {{
                                    unitArray[addedRows[rowNo].rowId]["col" + i] ? unitArray[addedRows[rowNo].rowId]["col" + i] || noUnitEnum.SYMBOL : slocale("Select Options")
                                  }}
                                  <i class="las la-angle-down"></i>
                                </div>
                              </button>

                            <!-- Number predefined options-->
                            <ul class="dropdown-menu sticky-top wider-dropdown-menu p-0" aria-labelledby="dropdownMenuButton" *ngIf="colDataTypes[i].generic =='number'">
                              <li *ngFor="let option of colDataTypes[i]?.predefinedOptions; let predefinedOptionsIndex = index" class="border-bottom" >
                                <span
                                  *ngIf="option.value == null"
                                  class="dropdown-item user-select-none p-0"
                                  (click)="setUnit(i, rowNo,addedRows[rowNo].rowId, option.unit)"
                                >
                                  {{ option.unit || noUnitEnum.SYMBOL }}
                                </span>
                                <span
                                  *ngIf="option.value != null"
                                  class="dropdown-item user-select-none p-0"
                                  (click)="setUnit(i, rowNo,addedRows[rowNo].rowId, option.unit)"
                                >
                                  {{ option.value }} <strong>{{ option.unit || noUnitEnum.SYMBOL }}</strong>
                                </span>
                              </li>
                            </ul>
                         
                          </div>
                        </ng-container>
                        <!-- Predefined option dropdown ended.-->

                    <unit-selector *ngIf="(isModeEditTableData() || isModeEditRefTableData()) && !containsPredefinedOptions(colDataTypes[i])" [mode]="'select'" [selectBy]="'symbol'"  (selectedUnitChanged)="setUnit(i, rowNo,addedRows[rowNo].rowId, $event?.symbol)"
                      [selectedUnitSymbol]='getUnit(i,addedRows[rowNo].rowId)'  
                      [includeMeasurementTypes]="getMeasurementType(i)"
                      [customUnits]="getCustomUnits()"
                      [unitsByMeasurementType]="getUnitsByMeasurementType()" [source]="'inside-table'">
                    </unit-selector>
                    <ng-container *ngIf="!isModeEditTableData() && !isModeEditRefTableData()"> {{getUnit(i,addedRows[rowNo].rowId) || noUnitEnum.SYMBOL}}</ng-container>
                  </td>
                  
                  <td scope='row' *ngIf="!containsPredefinedOptions(colDataTypes[i]) &&isEFType(i) && !colDataTypes[i].isCalculated && ((isModeEditTableData() || isModeEditRefTableData()) ? getEmissionFactor(i, addedRows[rowNo].rowId) : true)" [ngClass]='getClasses(i,rowNo,even)' [class]="'col'+i" class="tableData" id='{{"col"+i+"row"+rowNo+"unit"}}'>
                    <input class="rowData" arrowDirective *ngIf="getEmissionFactor(i, addedRows[rowNo].rowId)" [value]="getEmissionFactor(i, addedRows[rowNo].rowId)?.getUnit && getEmissionFactor(i, addedRows[rowNo].rowId)?.getUnit()" id='{{"col"+i+"row"+rowNo+"valueUnit"}}' readOnly [attr.rowNo]="rowNo" [attr.colNo]="i" 
                    [ngClass]='{"removeBorder":!(isModeEditTableData() || isModeEditRefTableData()), "headline": this.isModeExportExcelTableData() && this.isHeadlineRow(rowNo)}'
                    [colType]='colDataTypes[i].generic' validateData>
                  </td>
                  <!-- /UNITS  -->

                  <!--row values of cols from refSchema-->
                  <td
                    scope="row"
                    *ngIf="i >= mainTableData.dataSchema.length"
                    [ngClass]="getClasses(i, rowNo, even)"
                    [class]="'col' + i"
                    class="tableData"
                    id="{{ 'col' + i + 'row' + rowNo }}"
                    dropdown
                    [insideClick]="true"
                    #newdropdown="bs-dropdown"
                    [autoClose]="true"
                    container="body"
                    (onHidden)="isInsideTableDropdown ? null : onClosingTableDropdown(rowNo, i, newdropdown)"
                    (onShown)="isInsideTableDropdown ? null : onOpeningTableDropdown(rowNo, i, newdropdown)"
                    dropdownToggle
                    (click)="isANestedParent(i) && (isModeEditTableData() || isModeEditRefTableData()) && rowRefIds[rowNo][addedRows[rowNo].rowId!][i - mainTableData.dataSchema.length] ? openNestedParentHandlerModal(rowNo, i) : null"
                  >
                    <input
                      *ngIf="colDataTypes[i].generic != 'checkbox' && colDataTypes[i].generic != 'date'"
                      (change)="changeColor(rowNo, i)"
                      class="rowData"
                      arrowDirective
                      [formControlName]="'col' + i"
                      id="{{ 'col' + i + 'row' + rowNo + 'value' }}"
                      readOnly
                      type="{{ colDataTypes[i].generic == 'number' ? 'text' : colDataTypes[i].generic }}"
                      [attr.colType]="colDataTypes[i].generic"
                      [attr.rowNo]="rowNo"
                      [attr.colNo]="i"
                      [ngClass]="{ removeBorder: !(isModeEditTableData() || isModeEditRefTableData()) }"
                      [colType]="colDataTypes[i].generic"
                      validateData
                    />

                    <input
                      *ngIf="colDataTypes[i].generic == 'date'"
                      (change)="changeColor(rowNo, i)"
                      class="rowData"
                      arrowDirective
                      [formControlName]="'col' + i"
                      id="{{ 'col' + i + 'row' + rowNo + 'value' }}"
                      readOnly
                      type="{{ addedRows[rowNo].values[i] || isCellEditable('col' + i + 'row' + rowNo) ? 'date' : 'text' }}"
                      [attr.colType]="colDataTypes[i].generic"
                      [attr.rowNo]="rowNo"
                      [attr.colNo]="i"
                      [ngClass]="{ removeBorder: !(isModeEditTableData() || isModeEditRefTableData()) }"
                      [colType]="colDataTypes[i].generic"
                      validateData
                    />

                    <input
                      *ngIf="colDataTypes[i].generic == 'checkbox'"
                      (change)="changeColor(rowNo, i)"
                      class="rowData form-input"
                      arrowDirective
                      [formControlName]="'col' + i"
                      id="{{ 'col' + i + 'row' + rowNo + 'value' }}"
                      readOnly
                      type="checkbox"
                      [attr.colType]="colDataTypes[i].generic"
                      [attr.rowNo]="rowNo"
                      [attr.colNo]="i"
                      [attr.disabled]="isModeEditTableData() || isModeEditRefTableData() ? null : true"
                      [ngClass]="{ removeBorder: !(isModeEditTableData() || isModeEditRefTableData()) }"
                      [checked]="getControl(element, 'col' + i).value"
                      [colType]="colDataTypes[i].generic"
                      validateData
                    />

                    <!-- <div *ngIf='isMeasurementType(i) && addedRows[rowNo].values[i]' class="numberData" [id]='"col"+i+"row"+rowNo+"number"'>
                      {{((isModeEditTableData() || isModeEditRefTableData()) && !isSaved)?getValue(null,"col"+i+"row"+rowNo+"value"):addedRows[rowNo].values[i].quantity}}
                      <sup>{{isNewTable ?getNumberValue(i,rowNo): getNumberValue(i,addedRows[rowNo].ordinal)}}</sup>
                    </div> -->

                    <!-- FOR DROPDOWN -->
                    <ng-container *ngIf="!isInsideTableDropdown && !isANestedParent(i)">
                      <div *dropdownMenu class="dropdown-menu tableDropdown-right" role="menu" [id]="'col' + i + 'row' + rowNo + 'tableDropdown'" style="min-width: 35rem">
                        <datagrid-table-editor *ngIf="isTableDropdownOpen" [isInsideTableDropdown]="true" class="col-12 pr-0" [dataGrid]="refService"></datagrid-table-editor>
                      </div>
                    </ng-container>

                    <div class="emptyDiv" id="{{ 'col' + i + 'row' + rowNo + 'div' }}"></div>
                    <a style="display: none" id="{{ 'col' + i + 'row' + rowNo + 'caret' }}" class="arrowDown"></a>
                  </td>
                </ng-container>

                <th class="stickySides l-shadow" [ngClass]="{ selectedRow: selectedRow == rowNo }">
                  <button *ngIf="isModeSelectRefTableData()" class="btn btn-info float-end selectRowBtn p-0" (click)="selectRefRow(rowNo)">select</button>
                  <button *ngIf="isModeGetSelectedTableRows()" class="btn btn-info float-end selectRowBtn p-0" (click)="dataGrid.exportSelectedRows([addedRows[rowNo]])">select</button>
                </th>
              </tr>
            </ng-container>
            <!--DUMMY ROWS-->
            <ng-container *ngIf='(!isInsideTableDropdown && addedRows.length <noOfMinRows);'>
              <tr *ngFor='let x of getRestArray();'>
                <th class='stickySides'></th>
                <ng-container *ngIf="isModeEditTableSchema();">
                  <ng-container *ngFor='let y of colDataTypes;let i = index;' >
                    <td class="l-shadow" style="background-color: #ECEFF1;" (contextmenu)="openRightClickMenu(rightClickMenu, $event)">
                      <div dropdown container="body" #rightClickMenu="bs-dropdown" dropdownToggle (onHidden)="onClosingRightClickDropdown()" (onShown)="onOpeningRightClickDropdown(rightClickMenu)">
                        <ul *dropdownMenu class="dropdown-menu rightClickMenu" role="menu" id='{{"col"+i+"row"+rowNo+"rightClickMenu"}}' [ngClass]="{isSchemaMode: isModeEditTableSchema()}">
                          <li class="px-2" role="menuitem" (click)='addColBelow(i-1)'><i class="la la-chevron-left"></i>{{ locale("locale_key.datagrid.table.insert_column.before") }}</li>
                          <li class="px-2" role="menuitem" (click)='addColBelow(i)'><i class="la la-chevron-right"></i>{{ locale("locale_key.datagrid.table.insert_column.after") }}</li>
                          <li class="px-2" role="menuitem" (click)='del("col",i);'><i class="la la-trash"></i>{{ locale("locale_key.datagrid.table.delete.column") }}</li>
                        </ul>
                      </div>
                      <input readonly>
                    </td>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!isModeEditTableSchema();">
                  <ng-container *ngFor='let y of colDataTypes;let i = index;' >
                    <td
                    [ngClass]='{"l-shadow": isModeEditTableData() || isModeEditRefTableData() || isModeEditTableSchema() || isModeExportExcelTableData()}' style="background-color: #ECEFF1;"
                    [attr.colspan]=" (colDataTypes[i].generic =='EMISSION_FACTOR' || (colDataTypes[i].generic =='number' && mainSchema[i]?.predefinedOptions?.[0]?.value != null )  ) ? 2 : 1"
                    >
                    <ng-container *ngIf="containsPredefinedOptions(mainSchema[i]) && isViewWithPredefinedOptions()  || (containsPredefinedOptions(mainSchema[i]) && isViewWithPredefinedOptions())" class="form-group position-relative ms-0 m-0 p-0" >
                      <div class="dropdown d-grid w-100" *ngIf="colDataTypes[i].generic !='number' || (colDataTypes[i].generic =='number' && mainSchema[i]?.predefinedOptions[0]?.value != null)"
                      [ngClass]="{'dummy-row-with-predefined-ef': containsPredefinedOptions(mainSchema[i]) && colDataTypes[i].generic =='EMISSION_FACTOR'}"
                      >
                          <button class="btn btn-block dropdown-toggle  rounded-0 dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                            <div class="d-flex justify-content-between">
                             {{slocale("Select Options")}}
                              <i class="las la-angle-down"></i>
                            </div>
                          </button>

                        <ul class="dropdown-menu sticky-top wider-dropdown-menu  p-0" aria-labelledby="dropdownMenuButton" *ngIf="colDataTypes[i].generic =='date' || colDataTypes[i].generic =='range'">
                          <li *ngFor="let option of mainSchema[i].predefinedOptions;" class="dropdown-item border-bottom"
                          
                          >
                          <span  class="dropdown-item user-select-none p-0">
                            {{ option.from | date: 'dd MMM, yyyy' }}
                          </span>
                          </li>
                          <li class="dropdown-item" (click)="onChangeHeaderInputSetting(i, predefinedOptionEditor)">
                            <i class="icon las la-plus p-2"></i>
                            <span class="add_label">{{ slocale("Add custom option") }}</span>
                          </li>
                        </ul>
                        <!-- Number predefined options-->
                        <ul class="dropdown-menu sticky-top wider-dropdown-menu p-0" aria-labelledby="dropdownMenuButton" *ngIf="colDataTypes[i].generic =='number' && mainSchema[i]?.predefinedOptions[0]?.value != null ">
                          <li *ngFor="let option of mainSchema[i]?.predefinedOptions; let predefinedOptionsIndex = index" class="border-bottom" >
                            <span
                              *ngIf="option.value == null"
                              class="dropdown-item"
                            >
                              {{ option.unit || noUnitEnum.SYMBOL }}
                            </span>
                            <span
                              *ngIf="option.value != null"
                              class="dropdown-item"
                            >
                              {{ option.value }} <strong>{{ option.unit || noUnitEnum.SYMBOL}}</strong>
                            </span>
                          </li>
                          <li class="dropdown-item" (click)="onChangeHeaderInputSetting(i, predefinedOptionEditor)">
                            <i class="icon las la-plus p-2"></i>
                            <span class="add_label">{{ slocale("Add custom option") }}</span>
                          </li>

                          
                        </ul>
                        <!-- Emission Factor predefined options-->
                        <ul class="dropdown-menu sticky-top wider-dropdown-menu p-0" aria-labelledby="dropdownMenuButton" *ngIf="colDataTypes[i].generic =='EMISSION_FACTOR'">
                          <li *ngFor="let option of mainSchema[i].predefinedOptions; let emissionFactorIndex = index" class="border-bottom">
                            <span class="dropdown-item user-select-none" (click)="setPredefinedEmissionFactorColValue(rowNo, addedRows[rowNo].rowId,  i, option)">
                                <span> <i class="las la-fire-alt p-2"></i>
                                  {{option.selectedEmissionFactor?.conversionFactor}} {{option.selectedEmissionFactor?.conversionUnit}} {{slocale('of')}} {{option.selectedEmissionFactor?.sourceName}} <i class="las la-info-circle p-2 pointer" (click)="$event.stopPropagation();openEfDetailTab(option.selectedEmissionFactor)"></i>
                                </span>
                            </span>
                          </li>
                          <li class="dropdown-item" (click)="onChangeHeaderInputSetting(i, predefinedOptionEditor)">
                            <i class="icon las la-plus p-2"></i>
                            <span class="add_label">{{ slocale("Add custom option") }}</span>
                          </li>
                        </ul>

                        <!-- Text predefined options-->
                        <ul class="dropdown-menu p-0" aria-labelledby="defaultDropdown" *ngIf="colDataTypes[i].generic =='text'">
                          <li *ngFor="let option of mainSchema[i].predefinedOptions; let predefinedOptionsIndex = index" class="dropdown-item border-bottom"  
                          >
                          <div class="dropdown-item user-select-none p-0 dropdown-text-item">
                            {{ option.text }}
                          </div>
                          </li>
                          <li class="dropdown-item" (click)="onChangeHeaderInputSetting(i, predefinedOptionEditor)">
                            <i class="icon las la-plus p-2"></i>
                            <span class="add_label">{{ slocale("Add custom option") }}</span>
                          </li>
                        </ul>
                      </div>
                    </ng-container>
                      <input readonly *ngIf="!containsPredefinedOptions(mainSchema[i])">
                    </td>
                    <td *ngIf="((isMeasurementType(i) && colDataTypes[i].showUnitCol) || isEFType(i)) && !colDataTypes[i].isCalculated && ((colDataTypes[i].generic == 'number' && mainSchema[i]?.predefinedOptions[0]?.value == null) || (containsPredefinedOptions(mainSchema[i]) && colDataTypes[i].generic == 'EMISSION_FACTOR' &&  !mainSchema[i]?.predefinedOptions[0]?.selectedEmissionFactor))" 
                      [ngClass]='{"l-shadow": isModeEditTableData() || isModeEditRefTableData() || isModeEditTableSchema() || isModeExportExcelTableData()}' style="background-color: #ECEFF1;">
                      <input readonly *ngIf="colDataTypes[i].generic != 'number'">
                      <ng-container *ngIf="containsPredefinedOptions(mainSchema[i]) && isViewWithPredefinedOptions()">
                        {{colDataTypes[i].generic == 'number' && (mainSchema[i]?.predefinedOptions[0]?.unit || noUnitEnum.SYMBOL)}}
                      </ng-container>
                    </td>
                  </ng-container>
                </ng-container>
                <th class="l-shadow stickySides"></th>
              </tr>
            </ng-container>
          </tbody>

          <tfoot>
            <tr *ngIf="isModeEditTableData() || isModeEditRefTableData()">
              <th class="r-shadow stickySides"></th>
              <th [colSpan]="totalLength()" class="btlr-shadow" style="background: white" (click)="addRowAfter(dataSource.length - 1)">
                <a style="position: relative; top: 3px"><i class="las la-plus-circle fs-5"></i></a>
                <span class="p-absolute ps-3">{{ locale("locale_key.datagrid.mapping.option.add_new_row") }}</span>
              </th>
              <th class="l-shadow stickySides"></th>
            </tr>
            <tr>
              <th class="stickySides" [colSpan]="totalLength() + 2"></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </ng-template>
  <ng-container *ngTemplateOutlet="showTable"></ng-container>
</ng-container>
  <!-- PreDefined options modal-->
  <ng-template #predefinedOptionEditor>
    <questionnaire-predefined-option-editor (closeEvent)="closeModal()" [question]="selectedColumnForPredefinedOption" [questionType]="selectedColumnTypeForPredefinedOption" [alreadyPreDefinedOptionList]="selectedColumnForPredefinedOption.predefinedOptions">
    </questionnaire-predefined-option-editor>
  </ng-template>


   <!--Form View Starts-->

    <ng-container *ngIf="isFormView">
      <ng-template #formView>
         <!-- Dropdown and options menu -->
      <div class="d-flex flex-row w-100">
        <div class="accordion flex-grow-1">
          <div class="accordion-item">
            <h2 class="accordion-header bg-white w-100">
              <select class="form-select bg-white w-100" (change)="updateSelectedEntry($event)">
                <option *ngFor="let i of [].constructor(addedRows.length); let rowNo = index" [value]="rowNo" [selected]="rowNo === selectedEntryIndex">
                  Entry {{rowNo + 1}}
                </option>
              </select>                     
            </h2>
          </div>
        </div>            
        <div class="btn-group" dropdown placement="top right">
          <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle mt-0 pt-0 mb-auto px-0" aria-controls="dropdown-alignment">
            <i class="la la-ellipsis-h fs-2 pt-0 mb-auto mt-1" style="rotate: 90deg;"></i>
          </button>
          <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
            <div>
              <li role="menuitem">
                <i class="las la-reply fs-5 mx-2 cursor"></i> {{ slocale("Clear this answer") }}
              </li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem">
                <i class="las la-reply-all fs-5 mx-2 cursor"></i> {{ slocale("Clear all answers") }}
              </li>
              <li class="divider dropdown-divider"></li>
              <li role="menuitem">
                <a class="text-dark"><i class="las la-trash-alt fs-5 mx-2 danger cursor"></i> {{ slocale("Remove answer entry") }}</a>
              </li>
            </div>
          </ul>
        </div>
      </div>
        <ng-container *ngIf="addedRows.length">
          <div class="d-flex flex-column thin-border-b p-3 bg-light" *ngFor="let column of colAray.controls; let i = index; let isLast=last" >
            <div class="col-lg-6 col-md-12 col-sm-12 mb-2"   [formGroup]="getElement(getElement(rowArray.controls[selectedEntryIndex]))">
              <h6  class="my-auto">Question for <span class="badge badge-info my-auto">{{ getColumnName(i) == null ? 'Entry 1': getColumnName(i) }}</span> <i class="la la-edit size-15 mx-2 my-auto" style="position:relative; top:3px;" (click)="startEdit(i)" *ngIf="editingColumnIndex !==i"></i></h6>
              <h6 class="bold my-2">{{ getUserQuestion(i) || 'Entry 1' }}</h6>
              <p class="italic mb-2">{{getUserDescription(i)}}</p>
            <ng-container *ngIf="isSMView ">
              <div *ngIf="editingColumnIndex === i " class="edit-section thin-border p-3 mb-3 bg-white">
                <div class="form-group mb-3">
                  <label for="questionText">{{slocale('Question Text')}}</label>
                  <textarea id="questionText" class="form-control" rows="3" [value]="getUserQuestion(i)" (input)="setUserQuestion(i, $event.target.value)"></textarea>
                </div>
                <div class="form-group mb-3">
                  <label for="description">{{slocale('Description')}}</label>
                  <textarea id="description" class="form-control" rows="3" [value]="getUserDescription(i)" (input)="setUserDescription(i, $event.target.value)"></textarea>
                </div>
                <div class="d-flex justify-content-end">
                  <button class="btn secondary-button custom-button me-2" (click)="cancelEdit()">
                    {{locale('locale_key.pages.clients.add_customer_success_modal.button.cancel')}}
                  </button>
                  <button type="button" class="btn primary-button custom-button" (click)="saveEdit(i)">
                    {{ locale('locale_key.confirmation_modal.button.confirm') }}
                  </button>
                </div>
              </div>
            </ng-container>        
            <div class="row align-items-center">
              <div class="" [ngClass]="isSMView ? 'col-md-7':'col-12'">
                <ng-container [ngSwitch]="colDataTypes[i]?.generic">
                  <textarea *ngSwitchCase="'text'" class="form-control my-2 text-black" [disabled]="isSMView" (change)="changeColor(selectedEntryIndex, i)" [formControlName]="'col' + i">{{ isSMView? getDatatype(colDataTypes[i].original): "" }}</textarea>
                  <input *ngSwitchCase="'date'" type="date" class="form-input thin-border rounded px-2 my-2" [disabled]="isSMView" [ngClass]="{'bg-light-gray': isSMView}" [formControlName]="'col'+i">
                  <ng-container *ngSwitchCase="'number'">
                    <input type="number" class="form-input thin-border rounded px-2 my-2 input-small me-2" [value]="getDatatype(colDataTypes[i].original)" [disabled]="isSMView" [ngClass]="{'bg-light-gray': isSMView}" [formControlName]="'col'+i">
                    <input *ngIf="isMeasurementType(i) && colDataTypes[i]?.showUnitCol && !colDataTypes[i]?.isCalculated" type="text" class="form-input thin-border rounded px-2 my-2 input-small" value="Select unit" [disabled]="isSMView" [ngClass]="{'bg-light-gray': isSMView}">
                    <unit-selector *ngIf="(isModeEditTableData() || isModeEditRefTableData()) && !containsPredefinedOptions(colDataTypes[i])" [mode]="'select'" [selectBy]="'symbol'"  (selectedUnitChanged)="setUnit(i, selectedEntryIndex,addedRows[selectedEntryIndex].rowId, $event?.symbol)"
                        [selectedUnitSymbol]='getUnit(i,addedRows[selectedEntryIndex].rowId)'  
                        [includeMeasurementTypes]="getMeasurementType(i)"
                        [customUnits]="getCustomUnits()"
                        [unitsByMeasurementType]="getUnitsByMeasurementType()" [source]="'inside-table'">
                      </unit-selector>
                      
                  </ng-container>
                  <input *ngSwitchCase="'checkbox'" type="checkbox" class="form-input thin-border rounded px-2 my-2 input-small" [checked]="'Yes/No'" [value]="getDatatype(colDataTypes[i].original)" [disabled]="isSMView" [ngClass]="{'bg-light-gray': isSMView}" [formControlName]="'col'+i">
            
                  <!-- EF Type Logic -->
                  <ng-container *ngIf="isEFType(i)">
                    <div class="row align-items-center">
                      <div class="col-12">
                        <ng-container *ngIf="isEFType(i) && !colDataTypes[i].isPreDetermined && !colDataTypes[i].predefinedOptions.length">
                          <ng-container *ngIf="getEmissionFactor(i, addedRows[selectedEntryIndex].rowId); else noEf">
                            <input
                            class="form-input thin-border rounded px-2 my-2 input-small me-2"
                              arrowDirective
                              [value]="getEmissionFactor(i, addedRows[selectedEntryIndex].rowId)?.conversionFactor"
                              id="{{ 'col' + i + 'row' + selectedEntryIndex + 'value' }}"
                              readOnly
                              [attr.rowNo]="selectedEntryIndex"
                              [attr.colNo]="i"
                              [ngClass]="{ removeBorder: !(isModeEditTableData() || isModeEditRefTableData()), headline: this.isModeExportExcelTableData() && this.isHeadlineRow(selectedEntryIndex) }"
                              [colType]="colDataTypes[i].generic"
                              validateData
                            />
                            <emission-factor
                              *ngIf="isModeEditTableData() || isModeEditRefTableData()"
                              showAsEditBtn="true"
                              isEditable="true"
                              insideTable="true"
                              (emissionFactorSelected)="setEmissionFactor(i, selectedEntryIndex, addedRows[selectedEntryIndex].rowId, $event)"
                              [datapointEFUnit]="{ sourceUnit: colDataTypes[i].emissionFactor?.sourceUnit, conversionUnit: colDataTypes[i].emissionFactor?.conversionUnit }"
                            >
                            </emission-factor>
                          </ng-container>
                          <ng-template #noEf>
                            <emission-factor
                              *ngIf="isModeEditTableData() || isModeEditRefTableData()"
                              isEditable="true"
                              insideTable="true"
                              (emissionFactorSelected)="setEmissionFactor(i, selectedEntryIndex, addedRows[selectedEntryIndex].rowId, $event)"
                              [datapointEFUnit]="{ sourceUnit: colDataTypes[i].emissionFactor?.sourceUnit, conversionUnit: colDataTypes[i].emissionFactor?.conversionUnit }"
                            >
                            </emission-factor>
                          </ng-template>
                        </ng-container>
  
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
              <div class="col-md-5 text-end" *ngIf="isSMView">
                <small class="text-muted italic my-auto">"Will be filled in by data reporter"</small>
                <i class="la la-cog size-15 ms-2 my-2" style="position:relative; top:3px;"></i>
              </div>
            </div>
            </div>
          </div>
        </ng-container>
        <div class="row me-auto mx-1 w-100"> 
          <a class="w-auto me-2 my-2 cursor" (click)="addNewFormRow()">
            <i class="la la-plus fs-5 ms-2"></i>
            {{isSMView ? slocale('Add new round/row'): slocale('Add new entry')}}
          </a>
        </div>
      </ng-template>
      <ng-container *ngTemplateOutlet="formView"></ng-container>
    </ng-container>
    <!--Form View Ends-->



  <!--PAGINATION-->
  <div *ngIf="!isModeExportExcelTableData() && dataGrid.hasMainTablePagination() && addedRows.length != 0 && deletedRows.length == 0" class="pagination">
    <div>
      <button (click)="pagination('navigationChanged', 'first')" [disabled]="table.pageNumber == 1 || !isSaved || hasAnyNewRows()">&laquo;</button>
      <button (click)="pagination('navigationChanged', 'previous')" [disabled]="table.pageNumber == 1 || !isSaved || hasAnyNewRows()">{{ locale("locale_key.datagrid.pagination.previous") }}</button>
      <button *ngFor="let pageNo of pageNumbers; let i = index; let last = last" [disabled]="!isSaved || hasAnyNewRows()" (click)="pagination('pageNoChanged', i + '')" [ngClass]="{ activePage: pageNo == table.pageNumber }">
        {{ pageNo }}
      </button>
      <button (click)="pagination('navigationChanged', 'next')" [disabled]="table.pageNumber == pageCount || !isSaved || hasAnyNewRows()">{{ locale("locale_key.datagrid.pagination.next") }}</button>
      <button (click)="pagination('navigationChanged', 'last')" [disabled]="table.pageNumber == pageCount || !isSaved || hasAnyNewRows()">&raquo;</button>
    </div>
  </div>

  <!--DELETED ROWS-->
  <div class="mainTable mb-0" [ngClass]="{ scrollTable: table.rows.length > 15 }" *ngIf="deletedRows.length > 0 && isModeEditTableData()">
    <div class="tableContainer" [ngClass]="{ scrollTable: table.rows.length > 15 }">
      <table class="deletedRowsTable">
        <thead formArray="colAray">
          <tr class="header">
            <th class="stickySides b-shadow danger-border">
              <i class="ft-alert-circle white p-0 font-medium-1" style="background-color: var(--orange); border-radius: 50%"></i>
            </th>
            <th *ngIf="table.dataSchema.length > 0" [attr.colspan]="totalLength()" class="pl-1 b-shadow danger-border">
              {{ table.name ? table.name : "SustainLab" }} <small>{{ locale("locale_key.datagrid.table.delete.rows") }}</small>
              <p (click)="revokeAllRows()" class="d-flex align-items-center float-end m-0 revokeAll"><i class="ft-rotate-ccw font-medium-1"></i>{{ locale("locale_key.datagrid.table.put_back_all.rows") }}</p>
            </th>
            <th class="stickySides danger-border" [ngClass]="refTables.length > 0 ? 'bl-shadow' : 'b-shadow'"></th>
          </tr>

          <tr class="colNames">
            <th class="stickySides"></th>
            <th
              scope="col"
              *ngFor="let column of colAray.controls; let i = index; let isLast = last"
              class="tableData colData l-shadow"
              [resizeColumn]="true"
              [index]="i"
              [width]="mainSchema[i].width"
              (resized)="setPaginationWidth()"
              [attr.colSpan]="((isMeasurementType(i) && colDataTypes[i].showUnitCol) || isEFType(i)) && !colDataTypes[i].isCalculated ? 2 : 1"
            >
              <input class="wt-700" arrowDirective [value]="getColumnName(i)" readOnly />
            </th>
            <th class="stickySides l-shadow"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of deletedRows; let rowNo = index; let even = even; let isFirst = first; let isLast = last" id="{{ 'row' + rowNo + 'Del' }}">
            <th class="">
              <span style="font-size: 0.8rem" class="rowNumber">{{ currentItems + rowNo + 1 }}</span>
            </th>

            <ng-container *ngFor="let col of colSource; let i = index">
              <td *ngIf="colDataTypes[i].isCalculated; else notCalculated" class="calculated calculatedDataInfo_message">
                {{ locale("locale_key.master_table.available_in_master_table.message") }}
              </td>
              <ng-template #notCalculated>
                <ng-container *ngIf="isEFType(i)">
                  <td scope="row" class="tableData" id="{{ 'col' + i + 'row' + rowNo + 'Del' }}">
                    <input class="rowData" [value]="getEmissionFactor(i, row.rowDetails.rowId)?.conversionFactor" id="{{ 'col' + i + 'row' + rowNo + 'valueDel' }}" readOnly [attr.colType]="colDataTypes[i].generic" />
                  </td>
                  <td scope="row" class="tableData" id="{{ 'col' + i + 'row' + rowNo + 'DelUnit' }}">
                    <input class="rowData" [value]="getEmissionFactor(i, row.rowDetails.rowId)?.getUnit()" id="{{ 'col' + i + 'row' + rowNo + 'valueDel' }}" readOnly [attr.colType]="colDataTypes[i].generic" />
                  </td>
                </ng-container>
                <ng-container *ngIf="isMeasurementType(i)">
                  <td scope="row" class="tableData" id="{{ 'col' + i + 'row' + rowNo + 'Del' }}">
                    <input class="rowData" [value]="row.rowDetails.values[i].quantity" id="{{ 'col' + i + 'row' + rowNo + 'valueDel' }}" readOnly [attr.colType]="colDataTypes[i].generic" />
                  </td>
                  <td scope="row" class="tableData" id="{{ 'col' + i + 'row' + rowNo + 'DelUnit' }}">
                    <input class="rowData" [value]="row.rowDetails.values[i].unit || noUnitEnum.SYMBOL" id="{{ 'col' + i + 'row' + rowNo + 'valueDel' }}" readOnly [attr.colType]="colDataTypes[i].generic" />
                  </td>
                </ng-container>
                <ng-container *ngIf="!isMeasurementType(i) && !isEFType(i)">
                  <td scope="row" class="tableData" id="{{ 'col' + i + 'row' + rowNo + 'Del' }}">
                    <input
                      *ngIf="colDataTypes[i].generic != 'checkbox'"
                      class="rowData"
                      [value]="row.rowDetails.values[i]"
                      id="{{ 'col' + i + 'row' + rowNo + 'valueDel' }}"
                      readOnly
                      type="{{ colDataTypes[i].generic == 'date' ? (deletedRows[rowNo].rowDetails.values[i] ? 'date' : 'text') : colDataTypes[i].generic }}"
                      [attr.colType]="colDataTypes[i].generic"
                    />
                    <input
                      class="rowData form-input"
                      id="{{ 'col' + i + 'row' + rowNo + 'valueDel' }}"
                      type="checkbox"
                      *ngIf="colDataTypes[i].generic == 'checkbox'"
                      [checked]="row.rowDetails.values[i]"
                      [attr.colType]="colDataTypes[i].generic"
                      disabled="true"
                    />
                  </td>
                </ng-container>
              </ng-template>
            </ng-container>

            <th class="l-shadow stickySides editRowBtn">
              <a class="revokeBtn hide" (click)="revokeRows(rowNo, row.rowDetails)" tooltip="Undo row deletion" container="body" placement="left">
                <i class="ft-rotate-ccw font-medium-1"></i>
              </a>
            </th>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th class="stickySides" [colSpan]="totalLength() + 2"></th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
  <div class="d-flex justify-content-center" *ngIf="isLoading">
    <div class="spinner-border spinner-border-sm text-info"></div>
  </div>
</div>

<ng-template #textModal>
  <div class="">
    <div class="modal-body scroll-y">
      <textarea id="editableModalText" class="form-control preserve-whitespace" rows="10" [(ngModel)]="currentText"></textarea>
    </div>
    <div class="modal-footer">
      <button class="btn secondary-button custom-button" (click)="hideModal()">{{ slocale("Cancel") }}</button>
      <button class="btn tertiary-button custom-button" (click)="clearText()">{{ slocale("Clear") }}</button>
      <button class="btn btn-add text-white custom-button" (click)="saveModalText()">{{ slocale("Save") }}</button>
    </div>
  </div>
</ng-template>

<ng-template #textModalNonEditable>
  <div class="pink-border rounded">
    <div class="modal-header bg-gray d-flex justify-content-end align-items-center">
      <button type="button" class="btn close m-0 p-0" *ngIf="!inProgress" aria-label="Close" (click)="hideModal()">
        <span aria-hidden="true"><i class="las la-times dark-text fs-6"></i></span>
      </button>
    </div>
    <div class="modal-body bg-gray scroll-y pt-0 p-0">
      <textarea id="modalTextarea" class="form-control bg-gray px-2 pt-0 m-0" rows="10" disabled>{{ currentText }}</textarea>
    </div>
  </div>
</ng-template>

<ng-template #editTitleDescriptionModal>
  <div>
    <div class="modal-header" style="border-bottom: none;background-color: #E6E6E6;">
      <h4 class="modal-title fs-6">{{ getTitleDescriptionModelTitle() }}</h4>
    </div>
  
    <div class="m-2 d-flex flex-column">
      <p class="m-0 p-2 fs-5 fw-normal">
        {{ slocale("Title / Question") }}
      </p>
      <textarea class="form-control px-2 pt-0 m-0" row="20" style="height:100px;" [(ngModel)]="tempUserQuestion"></textarea>
    </div>

    <div class="m-2 d-flex flex-column">
      <p class="m-0 p-2 fs-5 fw-normal">
        {{ slocale("Description") }}
      </p>
      <textarea class="form-control px-2 pt-0 m-0" row="20" style="height:100px;" [(ngModel)]="tempUserQuestionDesccription"></textarea>
    </div>
  
    <div class="modal-footer" style="border-top: 1px solid lightgray !important">
      <ng-container>
        <button type="button" class="btn tertiary-button custom-button" (click)="closeModal()">
          {{locale("locale_key.general.buttons.cancel")}}</button>
        <button type="button" class="btn primary-button px-0 custom-button" (click)="applyUserTitleQuestionChanges()">
          {{locale("locale_key.general.apply")}}</button>
      </ng-container>
    </div>
  </div>
</ng-template>
import { Component, Input, OnInit } from "@angular/core";
import { CSRDDataPointSettings } from "src/app/model/project/CSRDProjectInfoFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { CsrdDatapointFe } from "../../projects/csrd/model/CsrdDatapointFe";

@Component({
  selector: "csrd-info-tab",
  templateUrl: "./csrd-info-tab.component.html",
  styleUrls: ["./csrd-info-tab.component.scss"]
})
export class CsrdInfoTabComponent extends AbstractLanguageComponent implements OnInit {
  @Input() datapoint: CsrdDatapointFe;
  @Input() datapointSettings: CSRDDataPointSettings;
  
  constructor(languageService: LanguageService) {
    super(languageService);
  }

  ngOnInit(): void {}
}

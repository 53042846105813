import { QUESTION_TYPE } from "../../request/questionnaire/QuestionTypeFe";
import { AnswerFe } from "./AnswerFe";

export class AnswerNumberFe extends AnswerFe{
  value: number
  unit: string

  constructor(
     id: string,
    sectionId: string,
    questionId: string,
    comments: string | null,
    value: number,
    unit: string
  ) {
    super(
      id,
      sectionId,
      questionId,
      QUESTION_TYPE.NUMBER,
      comments
    )
    this.value = value
    this.unit = unit
  }

  public static fromTransfer(transfer: any): AnswerNumberFe {
    let answer = new AnswerNumberFe(
      transfer.id,
      transfer.sectionId,
      transfer.questionId,
      transfer.comments,
      transfer.value, 
      transfer.unit
    )
    return answer;
  }

  public hasAnyValue(): boolean {
    return this.value != null
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { DR_DATAPOINTS_PAGES } from "../dr-datapoints.component";
import {
  CSRDDataPointSettings,
  CSRDDisclosureSettings,
  CSRDDisclosureSettingsStatus,
  CSRDEsrsSettings,
  CSRDProjectInfoFe,
  CSRDTopicSettings
} from "src/app/model/project/CSRDProjectInfoFe";
import _ from "lodash";
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe";

@Component({
  selector: "csrd-dr-datapoints-view",
  templateUrl: "./dr-datapoints-view.component.html",
  styleUrls: ["./dr-datapoints-view.component.scss"]
})
export class DrDatapointsViewComponent extends AbstractLanguageComponent implements OnInit {
  showAllInfo: boolean = false;
  showAllComments: boolean = false;

  @Output() switchDrDatapointsPage = new EventEmitter<DR_DATAPOINTS_PAGES>();

  @Input() activeCsrdProject: CSRDProjectInfoFe;
  @Input() activeTopic: any;
  @Input() activeEsrs: any;
  @Input() activeDr: any;

  stats = {
    totalDatapoints: 0,
    materialDatapoints: 0,
    materialOmittedDatapoints: 0,
    nonMaterialDatapoints: 0,
    nonMaterialVoluntaryDatapoints: 0
  };

  csrdDatapoints;

  constructor(languageService: LanguageService, private displayService: DisplayServiceFe) {
    super(languageService);
  }

  ngOnInit(): void {
    this.csrdDatapoints = this.activeDr.csrdDatapoints.map((datapoint) => {
      //Replace newline with <br> in description properties
      datapoint.phaseIn = datapoint.phaseIn?.split("\n").join("</br>");
      datapoint.conditional = datapoint.conditional?.split("\n").join("</br>");
      datapoint.description = datapoint.description?.split("\n").join("</br>");
      //If application requirements is a string, make it an array
      if(typeof datapoint.applicationRequirements == "string"){
        datapoint.applicationRequirements = [datapoint.applicationRequirements];
      }
      datapoint.applicationRequirements = datapoint.applicationRequirements?.map((applicationRequirement) => applicationRequirement?.split("\n").join("</br>"));
      datapoint.supplementalInfo = datapoint.supplementalInfo?.split("\n").join("</br>");
      datapoint.supplementalReference = datapoint.supplementalReference?.split("\n").join("</br>");
      //If application footnotes is a string, make it an array
      if(typeof datapoint.applicationFootnotes == "string"){
        datapoint.applicationFootnotes = [datapoint.applicationFootnotes]  
      }
      datapoint.applicationFootnotes = datapoint.applicationFootnotes?.map((applicationFootnote) => applicationFootnote?.split("\n").join("</br>"));
      datapoint.relationToOtherStandards = datapoint.relationToOtherStandards?.split("\n").join("</br>");
      
      //Check if datapoint is mandatory
      if (datapoint.isMandatory) {
        datapoint.isMaterial = true;
      }

      datapoint.showMoreInfo = false;
      datapoint.isOmitted = false;
      datapoint.canOmit = false;
      datapoint.hasPhaseIn = false;
      datapoint.collectVoluntarily = false;
      datapoint.showComment = false;

      const { newEsrsSettings, newDisclosureSettings } = this.getNewTopicEsrsAndDisclosureSettings();

      // Get from Esrs Settings first, then get from csrdProject Settings
      if (!datapoint.isMandatory) {
        datapoint.isMaterial = newEsrsSettings.isMaterial;
      }

      let newDatapointSettings: CSRDDataPointSettings;
      newDisclosureSettings.csrdDatapoints.forEach((datapointSettings) => {
        if (datapoint.id == datapointSettings.id) {
          newDatapointSettings = _.cloneDeep(datapointSettings);
        }
      });

      if (!_.isEmpty(newDatapointSettings)) {
        datapoint.isOmitted = newDatapointSettings.isOmitted;
        datapoint.isMaterial = newDatapointSettings.isMaterial;
        datapoint.collectVoluntarily = newDatapointSettings.collectVoluntarily;
        datapoint.comment = newDatapointSettings.comment;
      }

      //Calculate stats
      this.tickStats(datapoint);

      return datapoint;
    });
  }

  tickStats(datapoint) {
    if ((datapoint.isMaterial && !datapoint.isOmitted) || (!datapoint.isMaterial && datapoint.collectVoluntarily)) {
      this.stats.totalDatapoints += 1;
    }

    if (datapoint.isMaterial) {
      this.stats.materialDatapoints += 1;
    } else {
      this.stats.nonMaterialDatapoints += 1;
    }

    if (datapoint.isMaterial && datapoint.isOmitted) {
      this.stats.materialOmittedDatapoints += 1;
    }

    if (!datapoint.isMaterial && datapoint.collectVoluntarily) {
      this.stats.nonMaterialVoluntaryDatapoints += 1;
    }
  }

  toggleSwitch(switchName) {
    switch (switchName) {
      case "showAllInfo":
        this.showAllInfo = !this.showAllInfo;
        this.csrdDatapoints = this.csrdDatapoints.map((datapoint) => {
          datapoint.showMoreInfo = this.showAllInfo;
          return datapoint;
        });
        break;
      case "showAllComments":
        this.showAllComments = !this.showAllComments;
        this.csrdDatapoints = this.csrdDatapoints.map((datapoint) => {
          datapoint.showComment = this.showAllComments;
          return datapoint;
        });
        break;
    }
  }

  toggleShowMoreInfo(datapoint) {
    datapoint.showMoreInfo = !datapoint.showMoreInfo;
  }

  editAssessment() {
    this.switchDrDatapointsPage.emit(DR_DATAPOINTS_PAGES.EDIT);
  }

  getNewTopicEsrsAndDisclosureSettings(): {
    newTopicSettings: CSRDTopicSettings;
    newEsrsSettings: CSRDEsrsSettings;
    newDisclosureSettings: CSRDDisclosureSettings;
  } {
    let newTopicSettings: CSRDTopicSettings;
    this.activeCsrdProject.definition.csrdSettings.forEach((topicSettings) => {
      if (topicSettings.id == this.activeTopic.id) {
        newTopicSettings = _.cloneDeep(topicSettings);
      }
    });

    if (_.isEmpty(newTopicSettings)) {
      newTopicSettings = {
        id: this.activeTopic.id,
        esrs: []
      };
    }

    let newEsrsSettings: CSRDEsrsSettings;
    newTopicSettings.esrs.forEach((esrsSettings) => {
      if (esrsSettings.id == this.activeEsrs.id) {
        newEsrsSettings = _.cloneDeep(esrsSettings);
      }
    });

    if (_.isEmpty(newEsrsSettings)) {
      newEsrsSettings = {
        id: this.activeEsrs.id,
        isAssessed: false,
        isMaterial: false,
        isMandatory: !!this.activeEsrs.isMandatory,
        disclosures: [],
        selectedSubSubTopics: [],
        selectedSubTopics: []
      };
    }

    let newDisclosureSettings: CSRDDisclosureSettings;
    newEsrsSettings.disclosures.forEach((disclosureSettings) => {
      if (disclosureSettings.id == this.activeDr.id) {
        newDisclosureSettings = _.cloneDeep(disclosureSettings);
      }
    });

    if (_.isEmpty(newDisclosureSettings)) {
      newDisclosureSettings = {
        id: this.activeDr.id,
        status: CSRDDisclosureSettingsStatus.NOT_STARTED,
        hasDraft: false,
        isMandatory: !!this.activeDr.isMandatory,
        draft: {
          csrdDatapoints: []
        },
        questionnaire: [],
        csrdDatapoints: []
      };
    }

    return { newTopicSettings, newEsrsSettings, newDisclosureSettings };
  }

  toggleShowComment(datapoint) {
    datapoint.showComment = !datapoint.showComment;
  }

  openCSRDInfoTab(datapoint) {
    this.displayService.openCSRDInfoTab(datapoint, null);
  }
}

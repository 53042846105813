import { UnitFe } from "src/app/components/unit-systems/model/UnitFe"
import { AbstractEmissionFactorFe } from "src/app/model/emissions/AbstractEmissionFactorFe"
import { PredefinedNumberOptionFe } from "./predefinedoption/PredefinedNumberOptionFe"
import { AbstractPredefinedOptionFe } from "./predefinedoption/AbstractPredefinedOptionFe"

export class FiledType {
  public static BOOLEAN = 'BOOLEAN'
  public static INTEGER = 'INTEGER'
  public static DATE = 'DATE'
  public static STRING = 'STRING'
}

export class DataTableRequestFieldFe {
  key: string
  label: string
  description: string
  datatype: string
  fixedValues: boolean = false
  preDefinedValues: any[] = [] 
  predefinedOptions: AbstractPredefinedOptionFe[] = []
  customValuesAllowedWhenPredefinedOptionsSet: boolean = false
  isCustomField = false
  unitSymbol: string | undefined;
  measurementKey: string | undefined
  userQuestion: string | undefined;
  userQuestionDescription: string | undefined

  isSelected = false

  
  constructor(key: string, label: string, description: string, datatype: string, fixedValues: boolean, preDefinedValues: any[], predefinedOptions: AbstractPredefinedOptionFe[],
    isCustomField: boolean, unitSymbol: string | undefined, measurementKey: string | undefined,
    public datapointEf: AbstractEmissionFactorFe | null | {}, customValuesAllowedWhenPredefinedOptionsSet: boolean, userQuestion: string | undefined, userQuestionDescription: string | undefined) {
    this.key = key
    this.label = label
    this.description = description
    this.datatype = datatype
    this.fixedValues = fixedValues
    this.preDefinedValues = preDefinedValues
    this.predefinedOptions = predefinedOptions
    this.isCustomField = isCustomField
    this.unitSymbol = unitSymbol
    this.measurementKey = measurementKey
    this.customValuesAllowedWhenPredefinedOptionsSet = customValuesAllowedWhenPredefinedOptionsSet
    this.userQuestion = userQuestion
    this.userQuestionDescription = userQuestionDescription
  }


  public static fromTransfer(transfer: any): DataTableRequestFieldFe{
    let field = new DataTableRequestFieldFe(
      transfer.key,
      transfer.label,
      transfer.description,
      transfer.datatype,
      transfer.fixedValues,
      transfer.preDefinedValues,
      transfer.predefinedOptions,
      transfer.isCustomField,
      transfer.unitSymbol,
      transfer.measurementKey,
      transfer.datapointEf,
      transfer.customValuesAllowedWhenPredefinedOptionsSet,
      transfer.userQuestion,
      transfer.userQuestionDescription,
    )
    return field;
  }

  setUnit(unit: UnitFe) {
    this.unitSymbol = unit?.symbol;
    this.measurementKey = unit?.measurementType;
  }
}
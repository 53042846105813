<div class="d-flex flex-column h-100" *ngIf="!startRequestCreation">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between" data-test="request-page-header">
        <h3 class="">{{ locale("locale_key.pages.data_request.create_wizard.title") }}
          <span *ngIf="viewNo == 2"> > {{ selectedRequestGroup.title }}</span>
        </h3>
        <!-- dropdown menu instead of toolbar for smaller pages-->
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle ms-4 mt-0 pt-0" aria-controls="dropdown-alignment">
            <i class="la la-ellipsis-h fs-2 mt-0 pt-0" style="rotate: 90deg"></i>
          </button>
          <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                    <button type="button" class="btn" (click)="handleToolbarAction(toolbarButton.actionName)" *ngIf="toolbarButton.visible()">
                      <i [class]="toolbarButton.icon"></i>
                      <span>{{ toolbarButton.longLabel }}</span>
                    </button>
                  </li>
                </ng-container>
                <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li>
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button *ngIf="toolbarButton.visible()" type="button" class="btn page-toolbar-button" (click)="handleToolbarAction(toolbarButton.actionName)" data-bs-toggle="tooltip"
                data-placement="top" [title]="toolbarButton.tooltip" show-delay="3000" [attr.data-test]="toolbarButton.tooltip">
                <i class="fs-5" [class]="toolbarButton.icon"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <!-- content column-->
      <div class="flex-fill">
        <div class="d-flex h-100 flex-row me-1">
          <div class="tab-content w-100" style="overflow-x: hidden">
            <div class="w-100 pt-1" style="overflow: hidden">
              <div class="card mb-1 pb-0">
                <div class="card-body px-0 pt-0" *ngIf="viewNo == 1 && !isReviewMode">
                  <div class="tab-content px-0 h-100">
                    <div class="mx-auto w-100 my-3 px-3 pt-1">
                      <div class="row">
                        <div class="col-md-6 mb-3 col-lg-3">
                          <input type="text" class="form-control" placeholder="{{locale('locale_key.general.search')}}" #search (input)="applySearchFilter(search.value)" />
                        </div>
                        <div class="col-md-12 mb-3 btn-group  col-lg-6" dropdown container="body" [insideClick]="true">
                          <button type="button" class="form-control filter-button" id="filter-button" dropdownToggle aria-controls="filter-dropdown-menu" style="text-align: left;">
                            <p class="d-inline m-0"><i class="la la-filter"></i> {{ locale("locale_key.pages.datahub.filter_dropdown") }}</p>
                            <p class="text-muted text-truncate col-7  m-0" [title]="getCustomFilterText()"
                            *ngIf="customMultipleFilters.length > 0 || selectedStatus"> :{{getCustomFilterText()}} </p>
                          </button>
                          <div class="ms-3 dropdown-menu" id="filter-dropdown-menu" *dropdownMenu role="menu" aria-labelledby="filter-button" [ngClass]="{'width-dropdown': screenSize.isXSmallSize()}">
                            <div class="accordion-body px-0 pt-0">
                              <div class="bg-light-gray col pt-3">
                                <div class="d-flex flex-row justify-content-between px-2">
                                  <span class="bold">{{ locale("locale_key.pages.datahub.applied_filters") }}</span>
                                  <a class=" underline" (click)="clearAllFilters()">{{ locale("locale_key.pages.datahub.button.clear_all_filters") }}</a>
                                </div>
                                <div class="row px-2">
                                  <i class="text-muted" *ngIf="customMultipleFilters.length == 0 && !selectedStatus">{{ locale('locale_key.pages.datahub.filter_none') }}</i>
                                  <p class="text-muted text-truncate" *ngIf="customMultipleFilters.length > 0 || selectedStatus">
                                    {{getCustomFilterText()}}
                                  </p>
                                </div>
                              </div>
                              <div class="d-flex flex-row justify-content-between px-2 py-3 thin-border-t">
                                <div class="d-flex " [ngClass]="screenSize.isXSmallSize() ? 'flex-column' : 'flex-row'">
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" [(ngModel)]="selectedStatus" (change)="applyFilters()" name="status" value="CREATED" id="sent" />
                                    <label class="form-check-label" for="sent">
                                      {{ locale("locale_key.pages.datahub.do_submissions.filter.show_requests_sent") }}
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" [(ngModel)]="selectedStatus" (change)="applyFilters()" name="status" value="APPROVED" id="completed" />
                                    <label class="form-check-label" for="completed">
                                      {{ locale("locale_key.pages.datahub.do_submissions.filter.show_completed_requests") }}
                                    </label>
                                  </div>
                                  <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" [(ngModel)]="selectedStatus" (change)="applyFilters()" name="status" value="IN_PROGRESS" id="inProgress" />
                                    <label class="form-check-label" for="inProgress">
                                      {{ locale("locale_key.pages.datahub.do_submissions.filter.show_in_progress_requests") }}
                                    </label>
                                  </div>
                                </div>
                                <div class="d-flex flex-row">
                                  <a class=" underline" (click)="selectedStatus = null; applyFilters()">{{ locale('locale_key.pages.datahub.button.clear_filter') }}</a>
                                </div>
                              </div>
                              <div class="row mt-2">
                                <div class="accordion" id="accordionExample1">
                                  <div class="accordion-item">
                                    <h2 class="accordion-header">
                                      <button class="accordion-button collapsed" 
                                      type="button" data-bs-toggle="collapse" data-bs-target="#companyCollapse" aria-expanded="false" aria-controls="companyCollapse">
                                        <span class="cursor" data-bs-toggle="tooltip" data-placement="top"  >{{ locale('locale_key.pages.data_hub.master_table.data_categories.title') }}</span>
                                      </button>
                                    </h2>
                                    <div id="companyCollapse" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                      <div class="accordion-body scroll" style="max-height:20rem">
                                        <div class="row">
                                          <div class="col-md-6 col-lg-6 mb-3">
                                            <input type="text" class="form-control" placeholder="{{locale('locale_key.general.search')}}"  (input)="filterEntities(entitySearch.value)" #entitySearch>
                                          </div>
                                          <div class="col-md-6 col-lg-6 mb-3 text-end">
                                            <a class=" underline" (click)="selectAll('entityCheckbox')">{{ locale("locale_key.general.action.select_all") }}</a>
                                            <a class=" underline" (click)="deselectAll('entityCheckbox')">{{ locale("locale_key.general.action.deselect_all") }}</a>
                                          </div>
                                        </div>
                                        <div *ngFor="let item0 of filteredEntities; let i = index">
                                          <input type="checkbox" class="form-check-input" class="entityCheckbox" id='{{item0.key}}' [checked]="isCustomFilterSelected(item0)" (change)="changeCustomFilters($event, item0, 0)">
                                          <i class="{{ taxonomyInfo.icon(item0)}} me-2 fs-5" [ngStyle]="{ color: taxonomyInfo.color(item0) }"></i>
                                          <label>{{item0.getLabel()}}</label>
                                          <div class="ms-3">
                                            <div *ngFor="let item1 of taxonomyInfo.childrenSortedByOrdinal(item0.key); let i = index">
                                              <input type="checkbox" class="form-check-input" class="entityCheckbox" id='{{item1.key}}' [checked]="isCustomFilterSelected(item1)" (change)="changeCustomFilters($event, item1, 1)">
                                              <label>{{item1.getLabel()}}</label>
                                              <div class="ms-3">
                                                <div *ngFor="let item2 of taxonomyInfo.childrenSortedByOrdinal(item1.key); let i = index">
                                                  <input type="checkbox" class="form-check-input" class="entityCheckbox" id='{{item2.key}}' [checked]="isCustomFilterSelected(item2)" (change)="changeCustomFilters($event, item2, 2)">
                                                  <label>{{item2.getLabel()}}</label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header">
                                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <span class="cursor" data-bs-toggle="tooltip" data-placement="top"  >{{ locale('locale_key.general.user_role.data_reporters') }}</span>
                                      </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                      <div class="accordion-body scroll" style="max-height:20rem">
                                        <div class="row">
                                          <div class="col-md-6 col-lg-6 mb-3">
                                            <input type="text" class="form-control" placeholder="{{locale('locale_key.general.search')}}"  (input)="filterDataReporters(contactSearch.value)" #contactSearch>
                                          </div>
                                          <div class="col-md-6 col-lg-6 mb-3 text-end">
                                            <a class=" underline" (click)="selectAll('contactCheckbox')">{{ locale("locale_key.general.action.select_all") }}</a>
                                            <a class=" underline" (click)="deselectAll('contactCheckbox')">{{ locale("locale_key.general.action.deselect_all") }}</a>
                                          </div>
                                        </div>
                                        <div *ngFor="let contact of filteredDataReporters; let i = index">
                                          <input type="checkbox" class="form-check-input" class="contactCheckbox" id='company{{i}}' [checked]="isCustomFilterSelected(contact)" (change)="changeCustomFilters($event, contact)">
                                          <label class="col-4">{{contact.getName()}}</label>
                                          <label class="col-4">{{contact.getCompanyName(stateService.activeWorkspace)}}</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="accordion-item">
                                    <h2 class="accordion-header">
                                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        <span class="cursor" data-bs-toggle="tooltip" data-placement="top"  >{{ locale('locale_key.pages.datahub.do_submissions.button.companies') }}</span>
                                      </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                      <div class="accordion-body scroll" style="max-height:20rem">
                                        <div class="row">
                                          <div class="col-md-6 col-lg-6 mb-3">
                                            <input type="text" class="form-control" placeholder="{{locale('locale_key.general.search')}}"  (input)="filterCompanies(companySearch.value)" #companySearch>
                                          </div>
                                          <div class="col-md-6 col-lg-6 mb-3 text-end">
                                            <a class=" underline" (click)="selectAll('companyCheckbox')">{{ locale("locale_key.general.action.select_all") }}</a>
                                            <a class=" underline" (click)="deselectAll('companyCheckbox')">{{ locale("locale_key.general.action.deselect_all") }}</a>
                                          </div>
                                        </div>
                                        <div *ngFor="let company of filteredCompanies; let i = index">
                                          <input type="checkbox" class="form-check-input" class="companyCheckbox" id='company{{i}}' [checked]="isCustomFilterSelected(company)" (change)="changeCustomFilters($event, company)">
                                          <label>{{company.supplierCompanyname}}</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-3 mb-3 btn-group" dropdown container="body">
                          <button type="button" class="form-control" id="group-button" dropdownToggle aria-controls="group-dropdown-menu" style="text-align: left;">
                            {{ locale('locale_key.pages.datahub.filter_sort_by') }} {{ sortedBy ? sortedBy.name : locale('locale_key.pages.datahub.filter_none')}}
                          </button>
                          <div class="ms-3 dropdown-menu" id="group-dropdown-menu" *dropdownMenu role="menu" aria-labelledby="group-button">
                            <ng-container *ngFor='let option of sortedByOptions' >
                              <div class="dropdown-divider" *ngIf="option.divider"></div>
                              <a class="dropdown-item" (click)="sort(option)">{{ option.name }}</a>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div class="row ps-0 ms-0 mt-1">
                        <div class="col-12 ps-0 d-flex flex-row justtify-content-start align-items-center" [ngClass]="{ 'pe-3': !screenSize.isSmallerSize() } || !screenSize.isXSmallSize()">
                          <div class="dropdown">
                            <button class="form-select form-control text-start" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="la la-folder size-15" style="transform: rotate(-90deg); position: relative; top: 3px"></i>
                              <a class="text-truncate px-1 w-100" title="{{ locale('locale_key.pages.data_request.portal.option.see_all_requests') }}">
                                <span class="px-1">{{ requestsFilterName }}</span>
                              </a>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <div class="w-100 truncate text-wrap p-2" *ngIf="requestFolders.length > 0">
                                <a class="w-100 scroll mh-300 ps-0">
                                  <p class="text-dark ms-0 mb-0 ps-0 text-truncate" data-bs-toggle="collapse" href="#requestFolderCollapse" role="button" aria-expanded="false"
                                    aria-controls="requestFolderCollapse" (click)="setRequestsFilter({ type: 'allRequests' })" *ngIf="requestsFilterType != 'allRequests'">
                                    <i class="la la-folder-o me-2 fs-4" style="transform: rotate(-90deg); position: relative; top: 3px"></i>
                                    {{ locale("locale_key.pages.data_request.portal.option.see_all_requests") }}
                                  </p>

                                  <ng-container *ngFor="let requestFolder of requestFolders; let i = index">
                                    <a class="d-flex flex-row my-1 text-dark cursor folders truncate text-wrap" data-backdrop="false" *ngIf="requestFolder != requestsFilterFolder"
                                      (click)="setRequestsFilter({ type: 'folder', folder: requestFolder })" data-bs-toggle="collapse" href="#requestFolderCollapse" role="button"
                                      aria-expanded="false" aria-controls="requestFolderCollapse">
                                      <i class="la la-folder fs-4 me-2 text-dark" style="transform: rotate(-90deg); position: relative; top: 1px"></i>
                                      <span class="text-truncate texr-wrap" data-bs-toggle="tooltip" title="{{ requestFolder.name }}"> {{ requestFolder.name }} </span>
                                    </a>
                                  </ng-container>
                                </a>
                              </div>
                            </div>
                          </div>
                          <span class="ms-2">
                            <a class="dark-text" [ngClass]="screenSize.isSmallerSize() ? 'underline' : 'btn tertiary-button'" (click)="openRequestFoldersList()"><i
                                class="la la-edit me-2 me-auto fs-4" *ngIf="!screenSize.isSmallerSize()"></i> {{ locale("locale_key.pages.data_request.button.manage_groups") }}</a>
                          </span>
                          <!-- <a class="py-1 d-flex flex-row justify-content-betwen dark-text dd-dash bg-text-white text-truncate px-1 w-100"
                          title="{{locale('locale_key.pages.data_request.portal.option.see_all_requests')}}" data-bs-toggle="collapse"
                          href="#requestFolderCollapse" role="button" aria-expanded="false"
                          aria-controls="requestFolderCollapse">
                          <i class="la la-folder-o me-2 size-15" style="transform:rotate(-90deg);"></i>
                          <span class="px-1">{{requestsFilterName}}</span>
                          <i class="las la-angle-down my-auto ms-2" *ngIf="requestFolders.length>0"></i>
                        </a> -->
                          <!-- <div class="collapse multi-collapse dd-dash w-100 " id="requestFolderCollapse" *ngIf="requestFolders.length>0">
                          <a class="card card-body w-100 scroll mh-300 ps-0">
                            <p class="text-dark ms-0 ps-0" 
                            data-bs-toggle="collapse" href="#requestFolderCollapse" role="button" aria-expanded="false" aria-controls="requestFolderCollapse"
                            (click)="setRequestsFilter({'type':'allRequests'})"  *ngIf='requestsFilterType != "allRequests"'>
                            <i class="la la-folder-o me-2 size-15" style="transform:rotate(-90deg);position:relative;top:3px"></i>
                              {{ locale("locale_key.pages.data_request.portal.option.see_all_requests") }}</p>

                            <ng-container *ngFor="let requestFolder of requestFolders; let i = index">
                              <a class="d-flex flex-row my-50 text-dark cursor folders" data-backdrop="false" *ngIf="requestFolder!=requestsFilterFolder"
                                (click)="setRequestsFilter({'type':'folder',folder:requestFolder})" data-bs-toggle="collapse" href="#requestFolderCollapse" role="button" aria-expanded="false" aria-controls="requestFolderCollapse">
                                <i class="la la-folder size-15 me-2 text-dark" style="transform:rotate(-90deg);flex: 1;align-self: flex-start;max-width: 21px;max-height: 21px;"></i>
                                <span> {{requestFolder.name}} </span>
                              </a>
                            </ng-container>
                          </a>
                        </div> -->
                        </div>
                      </div>
                    </div>
                    <div id="requestGroupHeader" class="card scroll-y px-3 pt-1 border-0" style="height: 70vh;">
                      <ng-container *ngFor="let requestGroup of filteredRequestGroups; let i = index">
                        <ng-container *ngIf="!requestGroup.draft">
                          <div class="accordion-item mb-2" (click)="selectRg(requestGroup)" [ngClass]="{ selected: requestGroup == selectedRequestGroup }">
                            <h2 class="accordion-header" id="heading{{i}}">
                              <button class="py-2 px-0 collapsed accordion-button row-design collapsed" type="button" data-bs-toggle="collapse" attr.data-bs-target="#requestAcc{{i}}" aria-expanded="false" attr.aria-controls="requestAcc{{i}}"
                              [ngClass]="{ selected: requestGroup == selectedRequestGroup, unreadBorder: requestGroup.readStatus == 'UNREAD' }">
                                <div class="row mx-auto w-100 my-1 py-1" style="max-width: 100%; box-sizing: border-box">
                                  <ng-container *ngIf="isRecurringRequest(requestGroup)">
                                    <div class="col-xl-4 col-lg-4 col-11">
                                      <div class="d-flex flex-column justify-content-center h-100">
                                        <span class="bold mb-1"> <i class="la la-calendar-o" style="font-size: 1.5rem"></i> <i class="la la-refresh"
                                            style="position: relative; bottom: 10px; left: -5px"></i>{{
                                          requestGroup.title }} </span>
                                        <div class="d-flex flex-row justify-content-start align-items-center">
                                          <div class="icon-circle-dark d-flex align-items-center " style="max-width: 40px !important">
                                            <span class="text-white">{{ requestGroup.requestCount }} </span>
                                          </div>
                                          <span class="dark-text ms-2 w-auto" style="max-width: fit-content !important"> {{ locale("locale_key.general.user_role.data_reporters") }} </span>
                                          <span class="text-muted ms-2 me-auto">{{ requestGroup.noOfCompletedRequests }}/{{ requestGroup.requestCount }} {{ locale("locale_key.general.state.completed")
                                            }}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-xl-5 col-lg-5 d-none d-xl-block d-lg-block accordion my-auto scoll h-auto" id="accordion-group" style="height: fit-content !important">
                                      <a class="thin-border py-1 d-flex flex-row justify-content-betwen dark-text bg-white text-truncate p-2 w-100 collapsed accordion-button"
                                        title="{{ requestGroup.title }}" data-bs-toggle="collapse" [href]="'#dashboardCollapse' + i" role="button" aria-expanded="false"
                                        [attr.aria-controls]="'dashboardCollapse' + i" style="border-radius: 5px">
                                        <ng-container *ngIf="!requestGroup.selectedRequestPeriod">
                                          <div class="col-8 pt-1">
                                            <p class="m-0 p-0 text-dark text-truncate" style="line-height: 1">{{ requestGroup.title }}</p>
                                            <small class="text-truncate"><b>{{ getStart(requestGroup) }} - {{ getEnd(requestGroup) }}</b></small>
                                          </div>
                                          <div class="my-auto mx-0 pe-0 col-xl-3 col-lg-3 d-none d-xl-block d-lg-block">
                                            <span class="badge my-auto me-1 ms-auto" [ngClass]="requestGroup.getStatusClass()">
                                              {{ locale(requestGroup.getStatusTextKeyword()) | titlecase }}
                                            </span>
                                          </div>
                                        </ng-container>
                                        <ng-container *ngIf="requestGroup.selectedRequestPeriod">
                                          <div class="col-8 pt-1">
                                            <p class="m-0 p-0 text-dark text-truncate" style="line-height: 1">{{ requestGroup.selectedRequestPeriod.title }}</p>
                                            <small class="text-truncate"><b>{{ requestGroup.selectedRequestPeriod.start }} - {{ requestGroup.selectedRequestPeriod.end }}</b></small>
                                          </div>
                                          <div class="my-auto col-3 mx-0 pe-0">
                                            <span class="badge my-auto me-1 ms-auto" [ngClass]="getRequestGroup(requestGroup.selectedRequestPeriod.requestGroupId)?.getStatusClass()">
                                              {{ locale(getRequestGroup(requestGroup.selectedRequestPeriod.requestGroupId)?.getStatusTextKeyword()) | titlecase }}
                                            </span>
                                          </div>
                                        </ng-container>
                                      </a>
                                      <div class="collapse multi-collapse scroll w-100" [id]="'dashboardCollapse' + i" data-bs-parent="#accordion-group" style="max-height: 10rem;">
                                        <div class="card card-body p-3 w-100 scroll mh-300 m-0">
                                          <div class="pb-2" *ngIf="requestGroup.previousRequestPeriods.length > 0">
                                            <small class="text-dark ms-0 ps-0">{{ locale("locale_key.pages.data_request.title.previous_request_periods") }}</small>
                                            <ng-container *ngFor="let previousPeriod of requestGroup.previousRequestPeriods">
                                              <div class="d-flex mt-2 flex-row justify-content-between hover requestPeriod" (click)="selectRequestPeriod(previousPeriod, requestGroup)">
                                                <div class="col-8">
                                                  <p class="m-0 p-0 text-dark text-truncate" style="line-height: 1">{{ previousPeriod.title }}</p>
                                                  <small class="text-dark"><b>{{ previousPeriod.start }} - {{ previousPeriod.end }}</b></small>
                                                </div>
                                                <div class="col-4 ms-2 my-auto">
                                                  <span class="badge my-auto" [ngClass]="getRequestGroup(previousPeriod.requestGroupId)?.getStatusClass()">
                                                    {{ locale(getRequestGroup(previousPeriod.requestGroupId)?.getStatusTextKeyword()) | titlecase }}
                                                  </span>
                                                </div>
                                              </div>
                                            </ng-container>
                                          </div>
                                          <div class="pb-2" *ngIf="requestGroup.currentRequestPeriods.length > 0">
                                            <small class="text-dark ms-0 ps-0">{{ locale("locale_key.general.title.current_request_period") }}</small>
                                            <ng-container *ngFor="let currentPeriod of requestGroup.currentRequestPeriods">
                                              <div class="d-flex mt-2 flex-row justify-content-between hover requestPeriod" (click)="selectRequestPeriod(currentPeriod, requestGroup)">
                                                <div class="col-8">
                                                  <p class="m-0 p-0 text-dark text-truncate" style="line-height: 1">{{ currentPeriod.title }}</p>
                                                  <small class="text-dark"><b>{{ currentPeriod.start }} - {{ currentPeriod.end }}</b></small>
                                                </div>
                                                <div class="col-4 ms-2 my-auto">
                                                  <span class="badge my-auto" [ngClass]="getRequestGroup(currentPeriod.requestGroupId)?.getStatusClass()">
                                                    {{ locale(getRequestGroup(currentPeriod.requestGroupId)?.getStatusTextKeyword()) | titlecase }}
                                                  </span>
                                                </div>
                                              </div>
                                            </ng-container>
                                          </div>
                                          <small class="text-dark ms-0 ps-0 bold">{{ requestGroup.upcomingRequestPeriodsMsg }}</small>
                                        </div>
                                      </div>
                                    </div>
                                  </ng-container>
                                  <ng-container *ngIf="!isRecurringRequest(requestGroup)">
                                    <div class="col-lg-4 col-xl-4 col-11">
                                      <div class="d-flex flex-column justify-content-center h-100">
                                        <span class="bold mb-1">{{ requestGroup.title }}</span>
                                        <div class="d-flex flex-row justify-content-start align-items-center">
                                          <div class="icon-circle-dark d-flex align-items-center" style="max-width: 40px !important">
                                            <span class="text-white">{{ requestGroup.requestCount }} </span>
                                          </div>
                                          <span class="dark-text ms-2 w-auto" style="max-width: fit-content !important"> {{ locale("locale_key.general.user_role.data_reporters") }} </span>
                                          <span class="text-muted ms-2 me-auto">{{ requestGroup.noOfCompletedRequests }}/{{ requestGroup.requestCount }} 
                                            {{ locale("locale_key.general.state.completed")}}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-xl-5 col-lg-5 d-none d-xl-block d-lg-block">
                                      <div class="d-flex flex-row justify-content-between align-content-center mx-0 px-0">
                                        <div class="col-4 d-flex flex-column justify-content-center h-100 align-content-center">
                                          <small class="text-muted">{{ locale("locale_key.pages.data_request.title.created") }}</small>
                                          <span class="badge badge-gray me-auto">{{ getCreated(requestGroup) }}</span>
                                        </div>
                                        <div class="col-4 d-flex flex-column justify-content-center h-100 align-content-center">
                                          <small class="text-muted">{{ locale("locale_key.pages.data_request.title.deadline") }}</small>
                                          <span class="badge me-auto {{requestGroup?.getDeadlineClass()}} dark-text">{{requestGroup?.getDeadline()}}</span>
                                        </div>
                                        <div class="col-4 my-auto">
                                          <span class="badge my-auto" [ngClass]="requestGroup.getStatusClass()">
                                            {{ locale(requestGroup.getStatusTextKeyword()) | titlecase }}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </ng-container>
                                  <div class="col-xl-2 col-lg-2 d-none d-xl-block d-lg-block">
                                    <div class="d-flex flex-column justify-content-center h-100 align-content-center">
                                      <span class="my-auto ms-auto cursor" (click)="selectRg(requestGroup); openActivityPortal()"><i class="la la-arrow-right fs-3 thin-border bg-white w-auto p-2" style="border-radius: 5px"></i></span>
                                    </div>
                                  </div>
                                  <div class="col-1 my-auto d-flex justify-content-end">
                                    <div class="btn-group">
                                      <div class="description-btn text-end" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="la la-ellipsis-v ms-auto my-auto cursor size-15" [ngClass]="{ 'pe-3': screenSize.isSmallerSize() || screenSize.isXSmallSize() }"></i>
                                      </div>
                                      <div class="dropdown-menu ms-0 ps-0" style="min-width: fit-content !important">
                                        <button class="dropdown-item underline ms-0 px-1 text-dark" type="button"
                                          (click)="selectRg(requestGroup); openModal(questionnairePreview, 'modal-lg')">
                                          <i class="las la-file-alt px-1"></i>{{ locale("locale_key.pages.data_request.data_requests_section.button.show_questionnaire") }}
                                        </button>
                                        <button class="dropdown-item underline ms-0 px-1 text-dark" type="button" (click)="updateDetailsData(requestGroup)">
                                          <i class="las la-info px-1"></i>{{ locale("locale_key.pages.data_request.data_requests_section.button.show_request_info") }}
                                        </button>
                                        <button class="dropdown-item underline ms-0 ps-1 text-dark" type="button" (click)="createNewRequest(requestGroup)">
                                          <i class="las la-copy-alt px-1"></i>{{ locale("locale_key.pages.data_request.data_requests_section.button.duplicate") }}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </button>
                            </h2>
                            <div id="requestAcc{{i}}" class="accordion-collapse collapse" attr.aria-labelledby="heading{{i}}" data-bs-parent="#requestGroupHeader">
                              <div class="card-content" *ngIf="selectedRequestGroup">
                                <div class="card-body p-2">
                                  <div class="d-flex flex-row py-3 px-1 btn-add text-white m-1 m-auto">
                                    <div class="col-4 ps-0">
                                      <h5 class="text-white">{{ locale("locale_key.general.user_role.data_reporter") }}</h5>
                                    </div>
                                    <div class="col-xl-5 col-lg-5 d-none d-xl-block d-lg-block d-flex flex-row">
                                      <div class="d-flex flex-row justify-content-between align-content-center mx-0 px-0">
                                        <div class="col-8">
                                          <h5 class="text-white">{{ locale("locale_key.pages.data_request.activity.table") }}</h5>
                                        </div>
                                        <div class="col-4">
                                          <h5 class="text-white">{{ locale("locale_key.general.user_information.user_status") }}</h5>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-3"></div>
                                  </div>
                                  <div class="row border-shade thin-border-b py-2 align-items-center px-1 m-1 bg-text-white my-0" *ngFor="let request of getRequests(); let i = index"
                                    (click)="selectedRequest = request" [ngClass]="{ unread: request.readStatus == 'UNREAD' }">
                                    <div class="col-xl-4 col-lg-4 col-9 ps-0 d-flex align-items-center">
                                      <div class="circle-icon me-1" style="position: relative; bottom: 5px; left: 0">
                                        <i class="las la-user size-15 text-white"></i>
                                      </div>
                                      <div class="d-flex flex-column justify-content-center align-content-center">
                                        <small class="text-truncate">{{ request.getAssigneeName() }}</small>
                                        <small class="text-muted">({{ request.supplierCompanyName ? request.supplierCompanyName : stateService.activeWorkspace.companyName }})</small>
                                      </div>
                                    </div>
                                    <div class="col-xl-5 col-lg-5 d-none d-xl-block d-lg-block">
                                      <div class="d-flex flex-row justify-content-between align-content-center mx-0 px-0">
                                        <div class="col-8 d-flex flex-column justify-content-center h-100 align-content-center">
                                          <div [ngClass]="{ 'text-white numberCircle': request.noOfUnreadActivities > 0 }">{{ request.noOfUnreadActivities }}</div>
                                        </div>
                                        <div class="col-4">
                                          <div class="d-flex justify-content-start align-items-center">
                                            <p class="badge my-auto" [ngClass]="request.getStatusClass()">
                                              {{ locale(request.getStatusTextKeyword()) | titlecase }}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-2 d-flex p-0 flex-column justify-content-center align-content-center">
                                      <span class="mb-1 ms-auto cursor" (click)="openActivityPortal(request)">
                                        <i class="la la-arrow-right fs-3 thin-border bg-white w-auto p-2" style="border-radius: 5px"></i>
                                      </span>
                                    </div>
                                    <div class="col-1"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="row mt-0 m-0 px-0 w-100" *ngIf="viewNo == 2 || isReviewMode">
                  <div class="d-flex flex-row gray-bg py-3 px-3 justify-content-between thin-border my-auto">
                    <span class="fs-5 bold my-auto">{{ selectedRequestGroup.title }}</span>
                  </div>
                  <div class="d-flex flex-row py-3 px-3 thin-border-b thin-border-l thin-border-r">
                    <div class="my-auto" [ngClass]="screenSize.isLargeSize() || screenSize.isXLargeSize() || screenSize.isMediumSize() ? 'col-4' : 'col-8'" >
                      <div class="d-flex flex-xl-row flex-lg-row flex-md-column flex-column justify-content-xl-start justify-content-lg-start
                       align-items-xl-center align-items-lg-center align-items-start">
                        <div class="icon-circle-dark d-flex align-items-center " style="max-width: 40px !important">
                          <span class="text-white">{{ selectedRequestGroup.requestCount }} </span>
                        </div>
                        <span class="dark-text ms-2 w-auto" style="max-width: fit-content !important"> {{ locale("locale_key.general.user_role.data_reporters") }} </span>
                        <span class="text-muted ms-2 me-auto">{{ selectedRequestGroup.noOfCompletedRequests }}/{{ selectedRequestGroup.requestCount }} {{ locale("locale_key.general.state.completed") }}</span>
                      </div>
                    </div>
                    <div class="col-4 border-r px-3 my-auto border-l" *ngIf="screenSize.isLargeSize() || screenSize.isXLargeSize() || screenSize.isMediumSize()">
                      <ng-container *ngIf="isRecurringRequest(selectedRequestGroup)">
                        <div class="accordion my-auto scoll h-auto" id="accordion-group" style="height: fit-content !important">
                          <a class="thin-border py-1 d-flex flex-row justify-content-betwen dark-text bg-white text-truncate p-2 w-100 collapsed accordion-button"
                            title="{{ selectedRequestGroup.title }}" data-bs-toggle="collapse" [href]="'#selectedRequestGroupCollapse'" role="button" aria-expanded="false"
                            [attr.aria-controls]="'selectedRequestGroupCollapse'" style="border-radius: 5px">
                            <ng-container *ngIf="!selectedRequestGroup.selectedRequestPeriod">
                              <div class="col-8 pt-1">
                                <p class="m-0 p-0 text-dark text-truncate" style="line-height: 1">{{ selectedRequestGroup.title }}</p>
                                <small class="text-truncate"><b>{{ getStart(selectedRequestGroup) }} - {{ getEnd(selectedRequestGroup) }}</b></small>
                              </div>
                              <div class="my-auto mx-0 pe-0 col-xl-3 col-lg-3 d-none d-xl-block d-lg-block">
                                <span class="badge my-auto me-1 ms-auto" [ngClass]="selectedRequestGroup.getStatusClass()">
                                  {{ locale(selectedRequestGroup.getStatusTextKeyword()) | titlecase }}
                                </span>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="selectedRequestGroup.selectedRequestPeriod">
                              <div class="col-8 pt-1">
                                <p class="m-0 p-0 text-dark text-truncate" style="line-height: 1">{{ selectedRequestGroup.selectedRequestPeriod.title }}</p>
                                <small class="text-truncate"><b>{{ selectedRequestGroup.selectedRequestPeriod.start }} - {{ selectedRequestGroup.selectedRequestPeriod.end }}</b></small>
                              </div>
                              <div class="my-auto col-3 mx-0 pe-0">
                                <span class="badge my-auto me-1 ms-auto" [ngClass]="getRequestGroup(selectedRequestGroup.selectedRequestPeriod.requestGroupId)?.getStatusClass()">
                                  {{ locale(getRequestGroup(selectedRequestGroup.selectedRequestPeriod.requestGroupId)?.getStatusTextKeyword()) | titlecase }}
                                </span>
                              </div>
                            </ng-container>
                          </a>
                          <div class="collapse multi-collapse scroll col-4 position-absolute"  [id]="'selectedRequestGroupCollapse'" data-bs-parent="#accordion-group" style="max-height: 10rem;z-index:101">
                            <div class="card card-body p-3 w-100 scroll mh-300 m-0">
                              <div class="pb-2" *ngIf="selectedRequestGroup.previousRequestPeriods.length > 0">
                                <small class="text-dark ms-0 ps-0">{{ locale("locale_key.pages.data_request.title.previous_request_periods") }}</small>
                                <ng-container *ngFor="let previousPeriod of selectedRequestGroup.previousRequestPeriods">
                                  <div class="d-flex mt-2 flex-row justify-content-between hover requestPeriod" (click)="selectRequestPeriod(previousPeriod, selectedRequestGroup)">
                                    <div class="col-8">
                                      <p class="m-0 p-0 text-dark text-truncate" style="line-height: 1">{{ previousPeriod.title }}</p>
                                      <small class="text-dark"><b>{{ previousPeriod.start }} - {{ previousPeriod.end }}</b></small>
                                    </div>
                                    <div class="col-4 ms-2 my-auto">
                                      <span class="badge my-auto" [ngClass]="getRequestGroup(previousPeriod.requestGroupId)?.getStatusClass()">
                                        {{ locale(getRequestGroup(previousPeriod.requestGroupId)?.getStatusTextKeyword()) | titlecase }}
                                      </span>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                              <div class="pb-2" *ngIf="selectedRequestGroup.currentRequestPeriods.length > 0">
                                <small class="text-dark ms-0 ps-0">{{ locale("locale_key.general.title.current_request_period") }}</small>
                                <ng-container *ngFor="let currentPeriod of selectedRequestGroup.currentRequestPeriods">
                                  <div class="d-flex mt-2 flex-row justify-content-between hover requestPeriod" (click)="selectRequestPeriod(currentPeriod, selectedRequestGroup)">
                                    <div class="col-8">
                                      <p class="m-0 p-0 text-dark text-truncate" style="line-height: 1">{{ currentPeriod.title }}</p>
                                      <small class="text-dark"><b>{{ currentPeriod.start }} - {{ currentPeriod.end }}</b></small>
                                    </div>
                                    <div class="col-4 ms-2 my-auto">
                                      <span class="badge my-auto" [ngClass]="getRequestGroup(currentPeriod.requestGroupId)?.getStatusClass()">
                                        {{ locale(getRequestGroup(currentPeriod.requestGroupId)?.getStatusTextKeyword()) | titlecase }}
                                      </span>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                              <small class="text-dark ms-0 ps-0 bold">{{ selectedRequestGroup.upcomingRequestPeriodsMsg }}</small>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!isRecurringRequest(selectedRequestGroup)">
                        <div class="d-flex flex-row justify-content-between align-content-center mx-0 px-0">
                          <div class="col-6 d-flex flex-column justify-content-center h-100 align-content-center">
                            <small class="text-muted">{{ locale("locale_key.pages.data_request.title.created") }}</small>
                            <span class="badge me-auto badge-gray">{{ getCreated(selectedRequestGroup) }}</span>
                          </div>
                          <div class="col-6 d-flex flex-column justify-content-center h-100 align-content-center">
                            <small class="text-muted">{{ locale("locale_key.pages.data_request.title.deadline") }}</small>
                            <span class="badge me-auto {{selectedRequestGroup?.getDeadlineClass()}} dark-text">{{selectedRequestGroup?.getDeadline()}}</span>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="col-4 px-2 my-auto d-flex flex-row justify-content-between">
                      <a class="position-relative mx-auto" [ngClass]="screenSize.isXLargeSize() || screenSize.isLargeSize() ? 'btn tertiary-button open-message-box-btn' : 'underline'"
                        (click)="openModal(questionnairePreview, 'modal-lg')">
                        <i class="las la-file-alt fs-4" style="position: relative; top: 3px" *ngIf="screenSize.isXLargeSize() || screenSize.isLargeSize()"></i> {{ locale("locale_key.pages.data_request.table.button.show_request") }}
                      </a>
                      <a class="position-relative mx-auto" [ngClass]="screenSize.isXLargeSize() || screenSize.isLargeSize() ? 'btn tertiary-button open-message-box-btn' : 'underline'"
                        (click)="updateDetailsData(selectedRequestGroup)">
                        <i class="la la-info fs-4" style="position: relative; top: 3px" *ngIf="screenSize.isXLargeSize() || screenSize.isLargeSize()"></i> {{ locale("locale_key.pages.data_request.details.title") }}
                      </a>
                    </div>
                  </div>
                  <data-inventory [requestGroup]="selectedRequestGroup" [requestPeriod] = "selectedRequestPeriod"
                    [exportResult]="exportResult" [selectedRequest]="selectedRequest" (setReviewMode)="isReviewMode = true" *ngIf="viewNo == 2 && !isReviewMode">
                  </data-inventory>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #sendMessageModal>
  <div class="main-modal-header modal-header">
    <h4 class="modal-title text-white" id="myModalLabel8">{{ locale("locale_key.pages.data_request.button.send_message") }}</h4>
    <button type="button" class="btn close text-white" *ngIf="!loadingInProgress" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div id="accordionMessage" class="border-shade">
      <div class="">
        <div class="">
          <h4>{{ locale("locale_key.pages.data_request.title.write_message") }}</h4>
          <textarea class="form-control" rows="10" [(ngModel)]="activityMessage"></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container *ngIf="!loadingInProgress">
      <button class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn btn-add text-white custom-button" (click)="sendMessage()">{{ locale("locale_key.general.buttons.submit") }}</button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>

<ng-template #addQuestionaireModal>
  <request-creation-launcher (selectedReqGroupSubject)="createNewRequest($event)"></request-creation-launcher>
</ng-template>

<questionaire-creator-full-page [reqGroupToBeDuplicated]="requestGroupToBeDuplicated" (close)="requestCreated()" (createdRequest)="addCreatedRequest($event)" *ngIf="startRequestCreation"></questionaire-creator-full-page>

<ng-template #questionnairePreview>
  <questionnaire-preview [request]="selectedRequest" [requestTitle]="selectedRequestGroup.title" (close)="closeModal()"></questionnaire-preview>
</ng-template>

<!-- List of previous answers Modal -->

<ng-template #listRequestFoldersModal>
  <div class="main-modal-header modal-header">
    <h4 class="modal-title text-white">{{ locale("locale_key.pages.data_request.button.manage_groups") }}</h4>
    <a type="button" class="close p-0 m-0" (click)="closeRequestFoldersList()" aria-label="Close" *ngIf="!loadingInProgress">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300; cursor: pointer !important" class="text-white">&times;</span>
    </a>
  </div>
  <div class="modal-body scroll mh-600" style="border-top: none">
    <div class="col-lg-12 mt-0 pt-0">
      <div class="d-flex flex-row px-1 mt-0 pt-0">
        <div class="px-1 w-100">
          <div class="row">
            <div class="col-xl-6 col-4">
              <h3 class="text-start my-2">{{ locale("locale_key.pages.data_request.manage_group_modal.title.list_of_groups") }}</h3>
            </div>
            <div class="col-xl-6 col-8 text-end" [ngClass]="{ 'row': screenSize.isXSmallSize() || screenSize.isSmallerSize()}" >
              <a (click)="addNewFolder()" [ngClass]="{ 'col-12': screenSize.isXSmallSize() || screenSize.isSmallerSize()}" >
                <i class="la la-folder-o me-2 ms-auto size-15" style="transform: rotate(-90deg); position: relative; top: 3px"></i>
                <span><a class="underline text-dark me-3">{{ locale("locale_key.pages.data_request.manage_group_modal.button.create_group") }}</a></span>
              </a>
              <a (click)="editGroups()" [ngClass]="{ 'col-12': screenSize.isXSmallSize() || screenSize.isSmallerSize()}" >
                <i class="la la-edit ms-3 me-2 ms-auto size-15" style="position: relative; top: 3px"></i>
                <span><a class="underline text-dark">{{ locale("locale_key.pages.data_request.manage_group_modal.button.edit_group") }}</a></span>
              </a>
            </div>
          </div>
          <div id="requestFolderWrap" role="tablist" aria-multiselectable="false">
            <ng-container *ngFor="let requestFolder of requestFolders; let i = index">
              <div class="accordion-icon-rotate d-flex flex-row justify-content-between align-items-center px-0 mx-0 w-100">
                <!-- <input type="checkbox" class="form-check-input" id="exampleCheck1" style="position:relative;bottom:3px"
                  *ngIf="editGroup"> -->
                <a class="collapsed w-100" data-bs-toggle="collapse" data-bs-parent="#requestFolderWrap" href="#requestFolder__{{ requestFolder.id }}" role="button" aria-expanded="false"
                  attr.aria-controls="requestFolder__{{ requestFolder.id }}">
                  <div class="row mx-auto my-2 py-3 mb-2 d-flex flex-row justify-content-between border" style="width: 95%">
                    <div class="col-xl-6 col-4">
                      <div class="d-flex flex-row justify-content-center align-content-center">
                        <i class="la la-folder mt-1 size-15 me-2 text-dark" style="transform: rotate(-90deg); flex: 1; align-self: flex-start; max-width: 21px; max-height: 21px"></i>
                        <span class="text-dark me-auto pt-1 text-truncate" style="flex: 1"> {{ requestFolder.name }} </span>
                      </div>
                    </div>
                    <div class="col-xl-6 col-8">
                      <div class="d-flex flex-row justify-content-end align-content-center">
                        <h6 class="pt-1 ms-auto">{{ locale("locale_key.pages.data_request.manage_group_modal.title.no_of_requests", { length: requestFolder.requestGroups.length }) }}</h6>
                        <i class="las la-angle-down my-auto"></i>
                      </div>
                    </div>
                  </div>
                </a>
                <div class="row" *ngIf="editGroup && !(deleteRequestFolderOrRequestSelectedFolder == requestFolder && deleteRequestFolderOrRequestType == 'REQUEST_FOLDER')">
                  <i class="la la-trash cursor my-auto ms-auto text-dark" (click)="deleteRequestFolder({requestFolder})"></i>
                </div>
                <div class="mx-0 px-2 pt-2 col-12 d-flex flex-row justify-content-end align-items-center"
                  *ngIf="editGroup && deleteRequestFolderOrRequestSelectedFolder == requestFolder && deleteRequestFolderOrRequestType == 'REQUEST_FOLDER'">
                  <span style="font-size: 80%; color: red">{{ locale("locale_key.pages.data_request.manage_group_modal.confirm_remove_group") }}</span>
                  <ng-container *ngIf="!deletingRequestGroupOrFolder">
                    <button type="button" class="btn secondary-button custom-button me-2" (click)="cancelDeleteRequestFolderOrRequest()">{{ locale("locale_key.general.buttons.cancel") }}</button>
                    <button type="button" class="btn btn-add text-white custom-button" (click)="confirmDeleteRequestFolderOrRequest()">{{ locale("locale_key.general.buttons.confirm") }}</button>
                  </ng-container>
                  <div class="ball-pulse loader-primary" *ngIf="deletingRequestGroupOrFolder">
                    <div style="background-color: var(--main-dark-color1)"></div>
                    <div style="background-color: var(--main-dark-color1)"></div>
                    <div style="background-color: var(--main-dark-color1)"></div>
                  </div>
                </div>
              </div>
              <div class="collapse multi-collapse w-100" id="requestFolder__{{ requestFolder.id }}">
                <div class="w-100 px-1">
                  <ng-container *ngFor="let requestGroup of getRequestGroupsInFolder(requestFolder); let i = index">
                    <div class="d-flex flex-row justify-content-between align-items-center px-0 mx-0 mx-auto" style="width: 90%">
                      <input type="checkbox" class="form-check-input float-start" id="exampleCheck1" style="position: relative; bottom: 3px" *ngIf="false" />
                      <div class="row mx-auto row-design my-1 py-3" style="width: 95%">
                        <div class="col-4">
                          <span class="my-auto">{{ requestGroup.title }}</span>
                        </div>
                        <div class="col-5" *ngIf="isRecurringRequest(requestGroup)">
                          <span class="my-auto">{{ getStart(requestGroup) }}</span> - <span class="my-auto">{{ getEnd(requestGroup) }}</span>
                        </div>
                        <div class="col-5" *ngIf="!isRecurringRequest(requestGroup)">
                          <span class="my-auto">{{ requestGroup.date | date : 'dd MMM, yyyy (HH:mm)' }}</span> -
                          <span class="my-auto">{{ requestGroup.dueDate | date : 'dd MMM, yyyy (HH:mm)' }}</span>
                        </div>
                        <div class="col-3 p-0">
                          <span class="badge mt-1 m-auto" [ngClass]="requestGroup.getStatusClass()">
                            {{ locale(requestGroup.getStatusTextKeyword()) | titlecase }}
                          </span>
                        </div>
                      </div>
                      <div class="row float-end" *ngIf="editGroup && !(deleteRequestFolderOrRequestSelectedRequestGroup == requestGroup && deleteRequestFolderOrRequestType == 'REQUEST')">
                        <i class="la cursor la-trash my-auto ms-auto" (click)="deleteRequestGroupFromFolder({requestGroup,requestFolder})"></i>
                      </div>
                      <div class="mx-0 px-2 pt-2 col-12 d-flex flex-row justify-content-end align-items-center"
                        *ngIf="editGroup && deleteRequestFolderOrRequestSelectedRequestGroup == requestGroup && deleteRequestFolderOrRequestType == 'REQUEST'">
                        <span style="font-size: 80%; color: red">{{ locale("locale_key.pages.data_request.manage_group_modal.confirm_remove_request") }}</span>
                        <ng-container *ngIf="!deletingRequestGroupOrFolder">
                          <button type="button" class="btn secondary-button custom-button me-2" (click)="cancelDeleteRequestFolderOrRequest()">{{ locale("locale_key.general.buttons.cancel")
                            }}</button>
                          <button type="button" class="btn btn-add text-white custom-button" (click)="confirmDeleteRequestFolderOrRequest()">{{ locale("locale_key.general.buttons.confirm") }}</button>
                        </ng-container>
                        <div class="ball-pulse loader-primary" *ngIf="deletingRequestGroupOrFolder">
                          <div style="background-color: var(--main-dark-color1)"></div>
                          <div style="background-color: var(--main-dark-color1)"></div>
                          <div style="background-color: var(--main-dark-color1)"></div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="border: none">
    <ng-container *ngIf="!loadingInProgress">
      <button type="button" class="btn secondary-button custom-button" (click)="closeRequestFoldersList()">{{ locale("locale_key.general.buttons.close") }}</button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="loadingInProgress">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>

<ng-template #createRequestFolderModal>
  <div class="main-modal-header modal-header">
    <h4 class="modal-title text-white">{{ locale("locale_key.pages.data_request.button.manage_groups") }}</h4>
    <a type="button" class="close" (click)="closeAddFoldersList()" aria-label="Close" *ngIf="!loadingInProgress">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </a>
  </div>
  <div class="modal-body" style="border-top: none">
    <div class="col-lg-12 mt-0 pt-0">
      <div class="row px-1 mt-0 pt-0">
        <div class="w-100">
          <div class="row">
            <div class="col-12">
              <span><a class="underline text-dark me-3 mt-1 mb-2" (click)="goBackToListRequestFolders()"> {{ locale("locale_key.pages.data_request.manage_group_modal.button.back") }}</a></span>
            </div>
            <div class="col-12">
              <h5 class="mt-3">
                <b><i class="la la-folder-o me-2 size-15" style="transform: rotate(-90deg); position: relative; top: 3px"></i>{{
                  locale("locale_key.pages.data_request.create_group_modal.title.create_group") }}</b>
              </h5>
            </div>
            <div class="col-12 d-flex" style="max-height: 30px">
              <span><label for="groupTitle" class="me-2 pt-1">{{ locale("locale_key.pages.data_request.create_group_modal.title.group_title") }}</label></span>
              <input type="text" class="form-control mt-1" [(ngModel)]="addFolderName" [placeholder]="locale('locale_key.pages.data_request.create.title.placeholder')"
                (change)="addFolderNameChanged()" style="max-width: 200px" />
            </div>
            <span class="ms-3 col-12" style="color: red; font-size: 80%" *ngIf="showRequestFolderTitleError">{{ locale("locale_key.validation.validation_message.group_title.required") }}</span>
            <span class="ms-3 col-12" style="color: red; font-size: 80%" *ngIf="showSelectRequestsError">{{ locale("locale_key.validation.validation_message.one_request_required") }}</span>
          </div>
          <div class="w-100 ps-3">
            <div class="d-flex flex-row justify-content-between align-items-center px-0 mx-0 mx-auto" style="width: 90%">
              <input type="checkbox" class="form-check-input float-start" (change)="selectAllRequestGroups($event)" style="position: relative; bottom: 3px" />
              <div class="row mx-auto my-1 py-1" style="width: 95%">
                <input type="text" class="form-control mt-1" [(ngModel)]="requestsFilterText" [placeholder]="locale('locale_key.general.search')" />
              </div>
            </div>
            <div class="scroll mh-300">
              <ng-container *ngFor="let requestGroup of getFilteredRequestGroupsAccToText(); let i = index">
                <ng-container *ngIf="!isRecurringRequest(requestGroup) && !requestGroup.draft">
                  <div class="d-flex flex-row justify-content-between align-items-center px-0 mx-0 mx-auto" style="width: 90%">
                    <input type="checkbox" class="form-check-input float-start" [checked]="requestGroup.isSelected" (change)="selectedIdsForAddFolderChanged(requestGroup, $event)"
                      style="position: relative; bottom: 3px" />
                    <div class="row mx-auto row-design my-2 py-3" style="width: 95%">
                      <div class="col-4">
                        <span class="my-auto">{{ requestGroup.title }}</span>
                      </div>

                      <div class="col-8 accordion-icon-rotate">
                        <a class="py-1 d-flex flex-row justify-content-betwen dark-text text-truncate px-1 w-100" title="{{ requestGroup.title }}">
                          <div class="col-8">
                            <small><b>{{ getCreated(requestGroup) }} - {{ getDeadline(requestGroup) }}</b></small>
                          </div>
                          <div class="col-4">
                            <span class="badge" [ngClass]="requestGroup.getStatusClass()">
                              {{ locale(requestGroup.getStatusTextKeyword()) | titlecase }}
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="isRecurringRequest(requestGroup) && !requestGroup.draft">
                  <div class="d-flex flex-row justify-content-between align-items-center px-0 mx-0 mx-auto" style="width: 90%">
                    <input type="checkbox" class="form-check-input float-start" [checked]="requestGroup.isSelected" (change)="selectedIdsForAddFolderChanged(requestGroup, $event)"
                      style="position: relative; bottom: 3px" />
                    <div class="row mx-auto row-design my-2 py-3" style="width: 95%">
                      <div class="col-4">
                        <span class="my-auto">{{ requestGroup.title }}</span>
                      </div>
                      <div class="col-8 accordion-icon-rotate">
                        <a class="border py-1 d-flex flex-row justify-content-betwen dark-text dd-dash bg-white text-truncate px-1 w-100 collapsed" title="{{ requestGroup.title }}"
                          (click)="slideOpen = !slideOpen" data-bs-toggle="collapse" href="#dashboardCollapse_{{ requestGroup.id }}" role="button" aria-expanded="false"
                          aria-controls="dashboardCollapse">
                          <div class="col-8">
                            <small><b>{{ getStart(requestGroup) }} - {{ getEnd(requestGroup) }}</b></small>
                          </div>
                          <div class="col-4">
                            <span class="badge" [ngClass]="requestGroup.getStatusClass()">
                              {{ locale(requestGroup.getStatusTextKeyword()) | titlecase }}
                            </span>
                            <i class="las la-angle-down ms-2" *ngIf="requestGroup.previousRequestPeriods.length > 0"></i>
                          </div>
                        </a>
                        <div class="collapse multi-collapse w-100" id="dashboardCollapse_{{ requestGroup.id }}" *ngIf="requestGroup.previousRequestPeriods.length > 0">
                          <div class="card card-body w-100 scroll mh-300 p-1 m-0 border">
                            <div class="pb-2">
                              <small class="text-dark ms-0 ps-0">{{ locale("locale_key.pages.data_request.title.previous_request_periods") }}</small>
                              <ng-container *ngFor="let previousPeriod of requestGroup.previousRequestPeriods">
                                <div class="d-flex mt-2 flex-row justify-content-between hover">
                                  <div class="col-7">
                                    <small class="text-dark"><b>{{ previousPeriod.start }} - {{ previousPeriod.end }}</b></small>
                                  </div>
                                  <div class="col-5">
                                    <span class="badge my-auto" [ngClass]="getRequestGroup(previousPeriod.requestGroupId)?.getStatusClass()">
                                      {{ locale(getRequestGroup(previousPeriod.requestGroupId)?.getStatusTextKeyword()) | titlecase }}
                                    </span>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="border: none">
    <ng-container *ngIf="!addingFolder">
      <button class="btn secondary-button custom-button" (click)="closeAddFoldersList()">{{ locale("locale_key.general.buttons.close") }}</button>
      <button class="btn primary-button ms-auto my-auto mb-2" *ngIf="!addingFolder" (click)="addRequestFolder()">{{ locale("locale_key.pages.data_request.create_group_modal.button.add_group")
        }}</button>
    </ng-container>
    <div class="ball-pulse loader-primary" *ngIf="addingFolder">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div>
  </div>
</ng-template>
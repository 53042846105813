<div class="d-flex flex-column h-100">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between">
        <nav>
          <ul class="nav">
            <li class="navbar-item">
              <a>
                <h3 class="mt-2">{{ slocale("PROJECTS") }}</h3>
              </a>
            </li>
            <ng-container>
              <li class="navbar-item">
                <h3 class="mt-2 mx-1"><i class="la la-angle-right font-medium-1"></i></h3>
              </li>
              <li class="navbar-item">
                <a>
                  <h3 class="mt-2">{{ slocale("New CSRD Project") }}</h3>
                </a>
              </li>
            </ng-container>
          </ul>
        </nav>
        <!-- dropdown menu instead of toolbar for smaller pages-->
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle ml-4 mt-" aria-controls="dropdown-alignment">
            <i class="la la-ellipsis-h fs-2" style="rotate: 90deg"></i>
          </button>
          <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                    <button type="button" class="btn" (click)="handleToolbarAction(toolbarButton.actionName)" *ngIf="toolbarButton.visible()">
                      <i [class]="toolbarButton.icon"></i>
                      <span>{{ toolbarButton.longLabel }}</span>
                    </button>
                  </li>
                </ng-container>
                <!-- <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li> -->
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button
                *ngIf="toolbarButton.visible()"
                type="button"
                class="btn page-toolbar-button"
                (click)="handleToolbarAction(toolbarButton.actionName)"
                data-bs-toggle="tooltip"
                data-placement="top"
                [title]="toolbarButton.tooltip"
                show-delay="3000"
              >
                <i [class]="toolbarButton.icon" class="fs-4"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <!-- content column-->
      <div class="flex-fill">
        <div class="d-flex h-100 flex-column">
          <div class="card me-2" style="height: 84vh; max-width: 100vw !important">
            <div class="pb-0">
              <div class="p-sm-0 m-sm-0">
                <ng-container *ngIf="screenSize.isLargeSize() || screenSize.isXLargeSize()">
                  <div class="row text-center pb-3 pt-4">
                    <div class="mt-0 pt-0 mx-0 ps-1 pe-0 col-4">
                      <div class="d-flex flex-row mb-2 mt-0 pt-0 text-start mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
                        <div class="circle1 active-circle1 mx-0 px-0">1</div>
                        <ng-container>
                          <h6 class="my-auto ms-1">{{ slocale("Learn about CSRD module") }}</h6>
                        </ng-container>
                        <span class="ms-1 mt-1" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
                      </div>
                    </div>
                    <div class="text-start mx-0 px-0 col-4">
                      <div class="d-flex flex-row mb-1 mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
                        <div class="circle1 active mx-0 px-0">2</div>
                        <ng-container>
                          <h6 class="my-auto ms-1">{{ slocale("Define project") }}</h6>
                        </ng-container>
                        <span class="ms-1 mt-1" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
                      </div>
                    </div>
                    <div class="text-start mx-0 px-0 col-4">
                      <div class="d-flex flex-row mb-2 mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
                        <div class="circle1 active mx-0 px-0">3</div>
                        <ng-container>
                          <h6 class="my-auto ms-1">{{ slocale("Add company info") }}</h6>
                          <span class="ms-1 mt-1 text-white" style="font-size: 1.5rem" ><i class="las la-angle-right text-white"></i></span>
                        </ng-container>
                      </div>
                    </div>                 
                    <div class="text-start mx-0 px-0 col-3" *ngIf="dissagregationLevelsDisabled">
                      <div class="d-flex flex-row mb-2 mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
                        <div class="circle1 active mx-0 px-0">4</div>
                        <ng-container>
                          <h6 class="my-auto ms-1">{{ slocale("Define disaggregation levels") }}</h6>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="screenSize.isMediumSize() || screenSize.isSmallerSize() || screenSize.isXSmallSize()">
                  <div class="d-flex flex-row justify-content-center mb-1 pt-3">
                    <div class="d-block text-center">
                      <div class="col stepper text-center" *ngFor="let step of stepsCSRD; let i = index" [class.active-stepper]="currentActive === i + 1 || step.completed"></div>
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-center mb-1">
                    <h6 class="text-center m-0">{{ stepsCSRD[currentActive - 1].title }} ({{ currentActive }}/{{ stepsCSRD.length }})</h6>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="pb-0"></div>
            <hr class="m-0 pt-0 px-0" [ngClass]="'show'" />
            <!--CSRD PROJECTS starts-->
            <ng-container>
              <!--NEW CARBONLAB PROJECT PAGE-->
              <div *ngIf="activeFormNo == 1" class="px-3 scroll-y">
                <div class="card-body">
                  <div class="row px-3">
                    <h3>{{ slocale("Welcome to the CSRD module!") }}</h3>
                    <p>
                      {{
                        slocale(
                          "Here, you'll be guided through data collection and aggregation for the EU's Corporate Sustainability Reporting Directive. Our user-friendly interface will lead you through the full set of European Sustainability Reporting Standards (ESRS) including all Disclosure Requirements and related Data Points. You will need the results from your Double Materiality Analysis to finalise the assessment phase of the set up. However, if you don't yet have your result ready, you can start with the mandatory disclosures and continue with your assessment when the Double Materiality Analysis is done."
                        )
                      }}
                    </p>
                  </div>
                  <div class="d-flex flex-column justify-content-center align-items-center mt-5" style="height: 100% !important">
                    <div class="row w-100 justify-content-around mt-1 step-progress flex-wrap px-0 my-auto" style="height: 100% !important">
                      <span class="dotted-line"></span>
                      <div class="card project-card mb-3">
                        <div class="card-body px-0">
                          <div class="d-flex flex-column justify-content-around align-items-center">
                            <i class="la la-cog proj-icon"></i>
                            <small class="card-title col">{{ slocale("Step 1") }}</small>
                            <h5 class="card-title col">{{ slocale("Setup") }}</h5>
                          </div>
                          <div class="d-flex flex-column">
                            <div class="flex-grow-1 d-flex align-items-center justify-content-center">
                              <ul id="cLab-li" class="mt-4">
                                <li>{{ slocale("Define project details: reporting year, project name, first year of reporting") }}</li>
                                <li>{{ slocale("Provide relevant company information: legal company name, headquarters location, average employee count") }}</li>
                                <li *ngIf="dissagregationLevelsDisabled">{{ slocale("Create disaggregation levels of your organization for data collection and reporting") }}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card project-card mb-3">
                        <div class="card-body px-0">
                          <div class="d-flex flex-column justify-content-center align-items-center">
                            <i class="la la-database proj-icon"></i>
                            <small class="card-title col">{{ slocale("Step 2") }}</small>
                            <h5 class="card-title col">{{ slocale("Data collection") }}</h5>
                          </div>
                          <ul id="cLab-li" class="mt-4">
                            <li>{{ slocale("Assess materiality of topical standards and ESRS data points") }}</li>
                            <li>{{ slocale("Gather required data from different parts of your organization, either from data reporters or report it yourself") }}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="card project-card mb-3">
                        <div class="card-body px-0">
                          <div class="d-flex flex-column justify-content-center align-items-center">
                            <i class="la la-book-open proj-icon"></i>
                            <small class="card-title">{{ slocale("Step 3") }}</small>
                            <h5 class="card-title">{{ slocale("Summary") }}</h5>
                          </div>
                          <ul id="cLab-li" class="mt-4">
                            <li>{{ slocale("Aggregate answers per ESRS data point for your entire organization") }}</li>
                            <li>{{ slocale("Numerical data points are auto-calculated with standard formulas") }}</li>
                            <li>{{ slocale("Export collected and aggregated data for use in your report") }}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--DEFINE PROJECT PAGE-->
              <div *ngIf="activeFormNo == 2" class="px-lg-5 px-3 py-3">
                <div class="d-flex flex-row flex-wrap">
                  <h5>{{ slocale("Project Definition") }}</h5>
                </div>
                <div class="d-flex align-items-center">
                  <p class="d-inline">{{ slocale("Enter the reporting year covered in this project") }}</p>
                  <i class="la la-info-circle info-popover-arrow size-15 ms-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="position: relative; top: -7px"> </i>
                  <div class="dropdown-menu arrow p-2 info-popover">
                    <p>{{ slocale("This CSRD project collects data for the specified reporting year. Wherever necessary, it also determines the required previous year (n-1).") }}</p>
                  </div>
                </div>
                <div class="form-group">
                  <input  type="number"
                  min="2000"
                  max="2100"
                  step="1"
                  class="form-control firstReportingYearInput w-auto" [(ngModel)]="reportingYearInput" (input)="reportingYearChanged()"  placeholder="{{ slocale('Enter year') }}" name="reportintYear" 
                  id="reportingYear"
                  />
                  <div class="error_box warning-message" [style.display]="showErrors && hasError['reportingYear'] ? 'block' : 'none'">
                    {{ errorMsg.reportingYear }}
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <p class="d-inline">{{ slocale("Choose a name for your CSRD project.") }}</p>
                </div>
                <div class="form-group">
                  <input [(ngModel)]="nameInput" type="text" class="form-control csrdNameInput" (keyup)="projectNameChanged()" placeholder="{{ slocale('Project name') }}" name="projectName" />
                  <div class="error_box warning-message" [style.display]="showErrors && hasError['name'] ? 'block' : 'none'">
                    {{ errorMsg.name }}
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <p class="d-inline">{{ slocale("Enter the first year of reporting") }}</p>
                  <!-- DISABLE TOOLTIP UNTIL WE ENABLED PHASE IN LOGIC -->
                  <!-- <i class="la la-info-circle info-popover-arrow size-15 ms-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="position: relative; top: -7px"> </i>
                                    <div class="dropdown-menu arrow p-2 info-popover">
                                        <p>{{ slocale("Some disclosure requirements and data points can be omitted in the first reporting year(s), which is why it's important for us to know which year your organization creates its first
                                            mandatory CSRD report. The phase-in options will be displayed based on this input") }}</p>
                                    </div> -->
                </div>
                <div class="form-group">
                  <input
                    [(ngModel)]="firstReportingYearInput"
                    (keypress)="firstReportingYearInputChanged($event)"
                    type="number"
                    min="2000"
                    max="2100"
                    step="1"
                    class="form-control firstReportingYearInput"
                    id="projectName"
                    placeholder="{{ slocale('Enter year') }}"
                    name="projectName"
                  />
                  <div class="error_box warning-message" [style.display]="showErrors && hasError['firstReportingYear'] ? 'block' : 'none'">
                    {{ errorMsg.firstReportingYear }}
                  </div>
                </div>
              </div>
              <!--ADD COMPANY INFO-->
              <div *ngIf="activeFormNo == 3" class="px-lg-5 px-3 py-3">
                <div class="d-flex flex-row flex-wrap">
                  <h5>{{ slocale("Company information") }}</h5>
                </div>
                <div class="d-flex align-items-center">
                  <p class="d-inline">{{ slocale("Please confirm your legal company name") }}</p>
                  <i class="la la-info-circle info-popover-arrow size-15 ms-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="position: relative; top: -7px"> </i>
                  <div class="dropdown-menu arrow p-2 info-popover">
                    <p>{{ slocale("In compliance with CSRD regulations, the legal company name must be included in the final report.") }}</p>
                  </div>
                </div>
                <div class="form-group" [ngClass]="screenSize.isSmallerSize() || screenSize.isXSmallSize() ? 'w-100' : 'w-50'">
                  <input [(ngModel)]="companyNameInput" (keyup)="checkInputs()" type="text" class="form-control" placeholder="{{ slocale('ACME Inc.') }}" />
                  <div class="error_box warning-message" [style.display]="showErrors && hasError['companyName'] ? 'block' : 'none'">
                    {{ errorMsg.companyName }}
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <p class="d-inline">{{ slocale("Where are the company’s headquarter located? (Country + town) ") }}</p>
                  <i class="la la-info-circle info-popover-arrow size-15 ms-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="position: relative; top: -7px"> </i>
                  <div class="dropdown-menu arrow p-2 info-popover">
                    <p>{{ slocale("In compliance with CSRD regulations, the headquarter location must be included in the final report.") }}</p>
                  </div>
                </div>
                <div class="form-group d-flex flex-row justify-content-between" [ngClass]="screenSize.isSmallerSize() || screenSize.isXSmallSize() ? 'w-100' : 'w-50'">
                  <div>
                    <select class="form-select w-100" [(ngModel)]="companyHQCountryInput" (change)="checkInputs()">
                      <option value="" selected>{{ slocale("Choose country") }}</option>
                      <option *ngFor="let country of countries" [value]="country[0]">{{ resolveCountryName(country[1]) }}</option>
                    </select>
                    <div class="error_box warning-message" [style.display]="showErrors && hasError['companyHQCountry'] ? 'block' : 'none'">
                      {{ errorMsg.companyHQCountry }}
                    </div>
                  </div>

                  <div>
                    <input [(ngModel)]="companyHQTownInput" (keyup)="checkInputs()" type="text" class="form-control ms-2 w-100" placeholder="{{ slocale('Enter town') }}" />
                    <div class="error_box warning-message" [style.display]="showErrors && hasError['companyHQTown'] ? 'block' : 'none'">
                      {{ errorMsg.companyHQTown }}
                    </div>
                  </div>
                </div>

                <div class="d-flex align-items-center">
                  <p class="d-inline">{{ slocale("What was the average number of employees during the reporting year (on a consolidated basis where applicable)?") }}</p>
                  <!-- DISABLE TOOLTIP UNTIL WE ENABLED PHASE IN LOGIC -->
                  <!-- <i class="la la-info-circle info-popover-arrow size-15 ms-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="position: relative; top: -7px"> </i>
                                    <div class="dropdown-menu arrow p-2 info-popover">
                                        <p>{{ slocale("The average employee number is relevant for certain phase-in options. In the data collection we'll highlight where this could be relevant for your reporting.") }}</p>
                                    </div> -->
                </div>
                <div class="form-group" [ngClass]="screenSize.isSmallerSize() || screenSize.isXSmallSize() ? 'w-100' : 'w-50'">
                  <select class="form-select" [(ngModel)]="companyNoEmployeesInput" (change)="checkInputs()">
                    <option value="" selected>{{ slocale("Select employee number") }}</option>
                    <option value="0_750">{{ slocale("0 - 750 employees") }}</option>
                    <option value="750+">{{ slocale("above 750 employees") }}</option>
                  </select>
                  <div class="error_box warning-message" [style.display]="showErrors && hasError['companyNoEmployees'] ? 'block' : 'none'">
                    {{ errorMsg.companyNoEmployees }}
                  </div>
                </div>
              </div>
              <!--ADD COMPANY INFO-->
              <div *ngIf="activeFormNo == 4 && dissagregationLevelsDisabled" class="px-lg-5 px-3 py-3 scroll-y" >
                <div class="d-flex flex-row flex-wrap">
                  <h5>{{ slocale("Disaggregation levels") }}</h5>
                </div>
                <div class="d-flex flex-column">
                  <p class="mb-0">
                    {{
                      slocale(
                        "For some cases the legislation requires disaggregated data for proper understanding of material impacts, risks, and opportunities. For more details, see the legislation guidance below. These will also be the organization levels at which you will be able to collect data."
                      )
                    }}
                  </p>
                  <p class="mt-0">
                    <small class="underline">{{ slocale("See guidance") }} </small><i class="ms-1 la la-arrows-alt"></i>
                  </p>
                  <div class="accordion mb-3" id="organizationLevelsAccordion">
                    <div class="accordion-item">
                      <button
                        class="accordion-button d-flex justify-content-between align-items-center bg-comp-grey h-auto"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOrganizationLevels"
                        aria-expanded="true"
                        aria-controls="collapseOrganizationLevels"
                      >
                        <div class="d-flex align-items-center col-9 justify-content-between">
                          <span class="me-2 w-auto text-nowrap">Main structure:</span>
                          <div class="d-flex align-items-center justify-content-between m-0 p-0 w-100" *ngIf="screenSize.isLargeSize() || screenSize.isXLargeSize()">
                            <span class="pink-border-btm me-2"><i class="la la-check-circle-o grad-green me-1" style="position: relative; top: 1px"></i>1: Entire organization</span>
                            <span class="pink-border-btm me-2"><i class="la la-check-circle-o grad-green me-1" style="position: relative; top: 1px"></i>2: Country</span>
                            <span class="pink-border-btm text-muted me-2"><i class="la la-check-circle-o grad-green me-1" style="position: relative; top: 1px"></i>3: Region</span>
                            <span class="pink-border-btm text-muted me-2"><i class="la la-check-circle-o grad-green me-1" style="position: relative; top: 1px"></i>4: City</span>
                            <span class="pink-border-btm text-muted"><i class="la la-check-circle-o grad-green me-1" style="position: relative; top: 1px"></i>5: Factory</span>
                          </div>
                          <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize() || screenSize.isMediumSize()">
                            <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle ms-auto" aria-controls="dropdown-alignment">
                              <i class="la la-ellipsis-h fs-2" style="rotate: 90deg"></i>
                            </button>
                            <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu p-2 z-max" role="menu" aria-labelledby="button-alignment">
                              <li role="menuitem">
                                <span class="pink-border-btm me-2"><i class="la la-check-circle-o grad-green me-1" style="position: relative; top: 1px"></i>1: Entire organization</span>
                              </li>
                              <li role="menuitem">
                                <span class="pink-border-btm me-2"><i class="la la-check-circle-o grad-green me-1" style="position: relative; top: 1px"></i>2: Country</span>
                              </li>
                              <li role="menuitem">
                                <span class="pink-border-btm text-muted me-2"><i class="la la-check-circle-o grad-green me-1" style="position: relative; top: 1px"></i>3: Region</span>
                              </li>
                              <li role="menuitem">
                                <span class="pink-border-btm text-muted me-2"><i class="la la-check-circle-o grad-green me-1" style="position: relative; top: 1px"></i>4: City</span>
                              </li>
                              <li role="menuitem">
                                <span class="pink-border-btm text-muted"><i class="la la-check-circle-o grad-green me-1" style="position: relative; top: 1px"></i>5: Factory</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="col-3 text-end">
                          <button class="btn tertiary-button ms-auto me-1" id="editButton">{{ screenSize.isLargeSize() || screenSize.isXLargeSize() ? slocale("Edit levels") : "Edit" }}</button>
                          <i class="fas fa-chevron-down"></i>
                        </div>
                      </button>
                      <div id="collapseOrganizationLevels" class="accordion-collapse collapse show bg-comp-grey" aria-labelledby="headingOrganizationLevels" data-bs-parent="#organizationLevelsAccordion">
                        <div class="accordion-body">
                          <p class="my-2">{{ slocale("Fill in the details about each organization level.") }}</p>
                          <div class="row">
                            <img src="assets/img/table.jpg" class="img-fluid w-100 h-100 mt-2" alt="dashboard" />
                          </div>
                          <div class="d-flex tex-end w-100 mt-3">
                            <button class="btn secondary-button custom-button m-1 ms-auto" type="button" id="previousButton">Previous</button>
                            <button class="btn primary-button custom-button m-1" type="button" id="nextButton">Next</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion" id="organizationLevelsAccordion1">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOrganizationLevels1">
                        <button
                          class="accordion-button d-flex justify-content-between align-items-center bg-comp-grey h-auto"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOrganizationLevels1"
                          aria-expanded="true"
                          aria-controls="collapseOrganizationLevels1"
                        >
                          <div class="mb-0 row w-100">
                            <label for="structureName" class="col-sm-3 col-form-label">Structure name</label>
                            <div class="col-sm-8">
                              <input type="text" class="form-control" id="structureName" value="Organization Levels" readonly />
                            </div>
                          </div>
                          <i class="la la-trash-alt mx-2 size-15" (click)="openModal(removeLevel, 'modal-md')"></i>
                        </button>
                      </h2>
                      <div id="collapseOrganizationLevels1" class="accordion-collapse collapse show bg-comp-grey" aria-labelledby="headingOrganizationLevels1" data-bs-parent="#organizationLevelsAccordion1">
                        <div class="accordion-body">
                          <form>
                            <div class="mb-3 row">
                              <label for="level1" class="col-sm-3 col-form-label">Level 1 name</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control" id="level1" value="Entire Organization" readonly />
                              </div>
                            </div>
                            <div class="mb-3 row">
                              <label for="level2" class="col-sm-3 col-form-label">Level 2 name</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control" id="level2" placeholder="Enter level name, eg. country" />
                              </div>
                            </div>
                            <div class="mb-3 row">
                              <label for="level3" class="col-sm-3 col-form-label">Level 3 name</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control" id="level3" placeholder="Enter level name, eg. region" />
                              </div>
                            </div>
                            <div class="mb-3 row">
                              <label for="level4" class="col-sm-3 col-form-label">Level 4 name</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control" id="level4" placeholder="Enter level name, eg. site" />
                              </div>
                            </div>
                            <div class="mb-3 row">
                              <label for="level5" class="col-sm-3 col-form-label">Level 5 name</label>
                              <div class="col-sm-9">
                                <input type="text" class="form-control" id="level5" placeholder="Enter level name, eg. department" />
                              </div>
                            </div>
                            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                              <button class="btn primary-button ms-auto custom-button" type="submit">Create</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3 w-100">
                    <button class="btn secondary-button me-auto" type="button">+ Add another level structure</button>
                  </div>
                </div>
              </div>
            </ng-container>
            <div id="card-footer" class="card-footer float-end mt-auto text-end quest-footer py-2 px-1 mx-0" style="max-width: 100vw; background-color: transparent" *ngIf="cardFooter">
              <ng-container *ngIf="!loadingInProgress">
                <button type="button" class="btn m-1 tertiary-button custom-button" id="startCancelNewProject" (click)="startCancelNewProject()">{{ locale("locale_key.general.buttons.cancel") }}</button>
                <button type="button" class="btn m-1 secondary-button custom-button" *ngIf="activeFormNo != 1" id="goToPrevCSRDSetupForm" (click)="goToPrevCSRDSetupForm()">{{ locale("locale_key.general.buttons.previous") }}</button>
                <button type="button" class="btn m-1 tertiary-button custom-button" [hidden]="activeFormNo == 1" (click)="startSaveAsDraft()">{{ locale("locale_key.button.save_as_draft") }}</button>
                <button type="button" class="btn m-1 primary-button custom-button mt-sm-1" *ngIf="activeFormNo != 3" id="goToNextCSRDSetupForm" (click)="goToNextCSRDSetupForm()">{{ locale("locale_key.general.buttons.next") }}</button>
                <button type="button" class="btn m-1 primary-button custom-button mt-sm-1" (click)="finishCSRDSetup(false)" *ngIf="activeFormNo == 3">
                  {{ slocale("Finish setup") }}
                </button>
              </ng-container>
              <ng-container *ngIf="loadingInProgress">
                <div class="ball-pulse loader-primary text-center m-auto">
                  <div style="background-color: var(--main-dark-color1)"></div>
                  <div style="background-color: var(--main-dark-color1)"></div>
                  <div style="background-color: var(--main-dark-color1)"></div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #cancelNewProjectModal>
    <div class="modal-header alert-modal-header">
      <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Cancel new project") }}</h4>
      <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <p class="ps-2">{{ slocale("Are you sure you want to cancel? Your changes will not be saved.") }}</p>
    </div>
    <div class="modal-footer" style="border-top: none">
      <ng-container>
        <button type="button" class="btn secondary-button custom-button" id="cancelNewProjectBtn" (click)="confirmCancelNewProject()">{{ slocale("Leave page") }}</button>
        <button type="button" class="btn primary-button text-white" (click)="closeModal()">{{ slocale("Return to page") }}</button>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #saveAsDraft>
    <div class="modal-header alert-modal-header">
      <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Save as draft") }}</h4>
      <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <p class="ps-2">
        {{ slocale("Your project will be saved in the Project Overview page.") }}
      </p>
    </div>
    <div class="modal-footer" style="border-top: none">
      <!--Enable when loading-->
      <div *ngIf="loadingInProgress" class="ball-pulse loader-primary text-center m-auto">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div>
      <ng-container *ngIf="!loadingInProgress">
        <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
        <button type="button" class="btn primary-button custom-button text-white" (click)="finishCSRDSetup(true)">{{ slocale("Save") }}</button>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #saveDraft>
    <div class="modal-header alert-modal-header">
      <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Save as draft") }}</h4>
      <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <p class="ps-2">
        {{ slocale("Your project will be saved in the Project Overview page.") }}
      </p>
    </div>
    <div class="modal-footer" style="border-top: none">
      <!--Enable when loading-->
      <!-- <div class="ball-pulse loader-primary text-center m-auto" >
            <div style="background-color: var(--main-dark-color1)"></div>
            <div style="background-color: var(--main-dark-color1)"></div>
            <div style="background-color: var(--main-dark-color1)"></div>
          </div> -->
      <ng-container>
        <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
        <button type="button" class="btn primary-button custom-button text-white" (click)="saveProject()">{{ slocale("Save") }}</button>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #removeLevel>
    <div class="modal-header alert-modal-header">
      <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Remove level structure") }}</h4>
      <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <p class="ps-2">{{ slocale("You're about to delete this structure. Once deleted, you won't be able to restore it, so you'll have to re-enter it if needed later.Are you sure you want to remove this structure?") }}</p>
    </div>
    <div class="modal-footer" style="border-top: none">
      <ng-container>
        <button type="button" class="btn secondary-button custom-button" id="cancelNewProjectBtn" (click)="closeModal()">{{ slocale("Cancel") }}</button>
        <button type="button" class="btn btn-danger text-white custom-button">{{ slocale("Remove") }}</button>
      </ng-container>
    </div>
  </ng-template>
</div>

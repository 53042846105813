import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import _ from "lodash";
import { BsModalService } from "ngx-bootstrap/modal";
import { UnitFe } from "../model/UnitFe";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { Calculation, CalculationThenOptions, Formula, Operand, Operator } from "../../data-categories/calculation-builder/model/CalculationBuilderModels";
import { UNIT_EDITOR_STATE } from "../model/UNIT_EDITOR_STATE";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { create, all } from "mathjs";
import { DepGraph } from "dependency-graph";
import { StandardUnitMeasurementTypes, UnitMeasurementTypes, getUnitMeasurementTypeDefaultUnitSymbol, getUnitMeasurementTypeName } from "../model/UnitMeasurementType";
import { UnitsByMeasurementType } from "../model/UnitsByMeasurementType";
import { groupUnitsByMeasurementTypeAndSystem } from "../model/utils";
import { UnitServiceFe } from "src/app/services/UnitServiceFe";

enum ModalNames {
  unitEditor = "unitEditor",
  deleteUnitModal = "deleteUnitModal",
  cancelEditingUnitModal = "cancelEditingUnitModal"
}

@Component({
  selector: "unit-editor",
  templateUrl: "./unit-editor.component.html",
  styleUrls: ["./unit-editor.component.scss"]
})
export class UnitEditorComponent extends AbstractLanguageComponent implements OnInit {
  @Input() units: UnitFe[] = [];
  @Output() unitCreated = new EventEmitter<any>();

  @ViewChild(`${ModalNames.unitEditor}`, { static: true }) unitEditor: TemplateRef<any>;
  modals;

  @ViewChild(`${ModalNames.deleteUnitModal}`, { static: true })
  deleteUnitModal: TemplateRef<any>;

  @ViewChild(`${ModalNames.cancelEditingUnitModal}`, { static: true })
  cancelEditingUnitModal: TemplateRef<any>;

  prevNameInput = "";
  nameInput = "";
  prevSymbolInput = "";
  symbolInput = "";
  leftSymbolInput = "";
  leftSelectedUnitContainer: { selectedUnit?: UnitFe } = {}
  rightSymbolInput = "";
  rightSelectedUnitContainer: { selectedUnit?: UnitFe } = {}
  measurementTypeInput: { selectedMeasurementType: any } = {
    selectedMeasurementType: {}
  };
  isConvertibleInput = "false";
  isCompoundInput = "false";
  descriptionInput = "";
  hasPrefixesInput = "false";

  savingUnit = false;
  deletingUnit = false;

  hasError = {
    name: false,
    symbol: false,
    measurementType: false,
    isConvertible: false,
    isCompound: false,
    description: false
  };

  errorMsg = {
    name: "",
    symbol: "",
    measurementType: "",
    isConvertible: "",
    isCompound: "",
    description: ""
  };

  showEditMode: boolean = true;
  showCalculationErrors: boolean = false;
  showDatapoints: boolean = false;
  showAddNewDataPointBtn: boolean = false;
  unitCalculation: Calculation = {
    isFallback: false,
    formula: [],
    then: CalculationThenOptions.FORMULA
  };
  state: UNIT_EDITOR_STATE = UNIT_EDITOR_STATE.ADD_UNIT;

  unit: UnitFe;
  shouldBeConvertible = false;
  unitsByMeasurementType: UnitsByMeasurementType[] = [];
  customUnits: UnitFe[] = [];
  includeMeasurementTypes: Set<String>;

  constructor(private modalService: BsModalService, private stateService: StateServiceFe, public languageService: LanguageService, private unitService: UnitServiceFe) {
    super(languageService);
  }

  async ngOnInit() {
    this.setupModals();
    this.units = await this.stateService.getUnits();
    const unitsByMeasurementType = groupUnitsByMeasurementTypeAndSystem(this.units);
    this.unitsByMeasurementType = unitsByMeasurementType;
    this.customUnits = this.units.filter((unit) => unit.isCustom && !unit.isStandard);
  }

  private setupModals() {
    this.modals = {
      [`${ModalNames.unitEditor}`]: {
        template: this.unitEditor,
        class: `modal-md ${ModalNames.unitEditor}`
      },
      [`${ModalNames.deleteUnitModal}`]: {
        template: this.deleteUnitModal,
        class: `modal-md ${ModalNames.deleteUnitModal}`
      },
      [`${ModalNames.cancelEditingUnitModal}`]: {
        template: this.cancelEditingUnitModal,
        class: `modal-md ${ModalNames.cancelEditingUnitModal}`
      }
    };
  }

  private openModal(options: { modal: ModalNames; class?: string; ignoreBackdropClick?: boolean }) {
    const modal = options.modal;
    const customClass = options.class ?? this.modals[modal].class;
    const template = this.modals[modal].template;

    this.modals[modal].ref = this.modalService.show(template, {
      keyboard: true,
      class: customClass
    });
  }

  private closeModal({ modal }) {
    const modalRef = this.modals[modal].ref;
    this.modalService.hide(modalRef.id);
  }

  loadUnit(unit: UnitFe) {
    this.prevNameInput = `${unit.name}`;
    this.nameInput = unit.name;
    this.prevSymbolInput = `${unit.symbol}`;
    this.symbolInput = unit.symbol;
    this.leftSymbolInput = unit.leftSymbol;
    
    if(!_.isEmpty(this.leftSymbolInput)){
      this.leftSelectedUnitContainer.selectedUnit = this.unitService.getUnit({ units:this.units, symbol:this.leftSymbolInput })
    }

    this.rightSymbolInput = unit.rightSymbol;
    if(!_.isEmpty(this.rightSymbolInput)){
      this.rightSelectedUnitContainer.selectedUnit = this.unitService.getUnit({ units:this.units, symbol:this.rightSymbolInput })
    }

    const measurementType = {
      key: unit.measurementType,
      name: getUnitMeasurementTypeName(UnitMeasurementTypes[unit.measurementType]),
      defaultUnitSymbol: getUnitMeasurementTypeDefaultUnitSymbol(UnitMeasurementTypes[unit.measurementType])
    };
    this.measurementTypeInput.selectedMeasurementType = measurementType;
    this.isConvertibleInput = `${unit.isConvertible}`;
    const isCompound = unit.isCompound || ( !_.isEmpty(unit.leftSymbol) && !_.isEmpty(unit.rightSymbol) );
    this.isCompoundInput = `${isCompound}`;
    this.hasPrefixesInput = `${unit.hasPrefixes}`;
    this.descriptionInput = unit.description;
    this.unitCalculation.formula = unit.formula;
  }

  clearUnit() {
    this.nameInput = "";
    this.symbolInput = "";
    this.leftSymbolInput = "";
    this.rightSymbolInput = "";
    this.measurementTypeInput.selectedMeasurementType = {};
    this.isConvertibleInput = "false";
    this.isCompoundInput = "false";
    this.hasPrefixesInput = "false";
    this.descriptionInput = "";
    this.unitCalculation = {
      isFallback: false,
      formula: [],
      then: CalculationThenOptions.FORMULA
    };
  }

  open({ state, unit, shouldBeConvertible }: { state: UNIT_EDITOR_STATE; unit?: UnitFe; shouldBeConvertible?: boolean }) {
    this.clearErrors();
    this.state = state;
    this.unit = unit;

    this.shouldBeConvertible = shouldBeConvertible;
    if (state == UNIT_EDITOR_STATE.EDIT_UNIT && !_.isEmpty(unit)) {
      this.loadUnit(unit);
    }
    this.openModal({
      modal: ModalNames.unitEditor,
      ignoreBackdropClick: false
    });
  }

  close() {
    this.closeModal({
      modal: ModalNames.unitEditor
    });
  }

  cancel() {
    this.closeModal({
      modal: ModalNames.unitEditor
    });
  }

  private formulaToDefinition(formula: Formula) {
    let result = "";
    const operatorToString = (operator: Operator) => {
      switch (operator.operatorType) {
        case "plus":
          return "+";
        case "minus":
          return "-";
        case "times":
          return "*";
        case "divide":
          return "/";
        case "openBracket":
          return "(";
        case "closeBracket":
          return ")";
        default:
          return "";
      }
    };
    formula.forEach((token) => {
      switch (true) {
        case token.type == "operand" && token.operandType == "fixedNumber": {
          const operand = token as Operand;
          result += `${operand.value}`;
          if (operand.unit) {
            result += `${operand.unit.symbol}`;
          }
          break;
        }
        case token.type == "operator" && token.operatorType != "control": {
          const operator = token as Operator;
          result += operatorToString(operator);
          break;
        }
      }
    });
    return result;
  }

  clearErrors() {
    this.showCalculationErrors = false;

    this.hasError = {
      name: false,
      symbol: false,
      measurementType: false,
      isConvertible: false,
      isCompound: false,
      description: false
    };

    this.errorMsg = {
      name: "",
      symbol: "",
      measurementType: "",
      isConvertible: "",
      isCompound: "",
      description: ""
    };
  }

  async checkInputs() {
    let hasErrors = false;
    this.clearErrors();
    //check required inputs aren't empty
    [
      { key: "name", name: "Name" },
      { key: "symbol", name: "Symbol" },
      { key: "measurementType", name: "Measurement type" }
    ].forEach((input) => {
      if (_.isEmpty(`${this[`${input.key}Input`]}`.trim())) {
        hasErrors = true;
        this.hasError[`${input.key}`] = true;
        this.errorMsg[`${input.key}`] = this.locale('locale_key.pages.units.validation.input_required', {input: input.name});
      }

      
      if (input.key == "symbol") {
        if(!this.unitService.isValidUnitName(this.symbolInput)){
          hasErrors = true;
          this.hasError[`${input.key}`] = true;
          this.errorMsg[`${input.key}`] = this.slocale(`Invalid symbol name (only alphanumeric characters are allowed) : "${this.symbolInput}"`);
        }
      }

      if (input.key == "measurementType") {
        if (_.isEmpty(this.measurementTypeInput.selectedMeasurementType)) {
          hasErrors = true;
          this.hasError[`${input.key}`] = true;
          this.errorMsg[`${input.key}`] = this.locale('locale_key.pages.units.validation.input_required', {input: input.name});
        }
      }
    });

    if (this.shouldBeConvertible && this.isConvertibleInput == "false") {
      hasErrors = true;
      this.hasError.isConvertible = true;
      this.errorMsg.isConvertible = this.locale("locale_key.pages.units.error.message.you_cannot_extract_data_with");
    }
    if (hasErrors) return hasErrors;

    // If convertible, formula should have atleast one function
    if (this.isConvertibleInput == "true" && this.unitCalculation.formula.length == 0) {
      hasErrors = true;
      this.hasError.isConvertible = true;
      this.errorMsg.isConvertible = this.locale(
        'locale_key.pages.units.validation.in_order_for_the_system_understand'
      , {units: this.measurementTypeInput.selectedMeasurementType?.name, types: this.measurementTypeInput.selectedMeasurementType?.nam});
    }
    if (hasErrors) return hasErrors;

    // load units if not available
    if (_.isEmpty(this.units)) {
      this.units = await this.stateService.getUnits();
    }

    //if symbol has changed, check symbol doesn't exist in symbol or alias of other units
    if (this.prevSymbolInput != this.symbolInput) {
      let found = false;
      let foundUnit;
      this.units.forEach((unit) => {
        if (unit.symbol.toLowerCase() == this.symbolInput.toLowerCase()) {
          found = true;
          foundUnit = unit;
        }

        const aliases = unit.aliases.split(",");
        aliases.forEach((alias) => {
          if (alias.trim().toLowerCase() == this.symbolInput.toLowerCase()) {
            found = true;
            foundUnit = unit;
          }
        });

        if (found) {
          hasErrors = true;
          this.hasError["symbol"] = true;
          this.errorMsg["symbol"] = `${this.locale("locale_key.pages.units.error.message.symbol_already_exists", {
            symbol: this.symbolInput,
            unit: foundUnit.name
          })}`;
        }
      });
    }
    if (hasErrors) return hasErrors;

    //if name has changed, check name doesn't exist in name of other units
    if (this.prevNameInput != this.nameInput) {
      let found = false;
      let foundUnit;
      this.units.forEach((unit) => {
        if (unit.name.trim().toLowerCase() == this.nameInput.trim().toLowerCase()) {
          found = true;
          foundUnit = unit;
        }

        if (found) {
          hasErrors = true;
          this.hasError["name"] = true;
          this.errorMsg["name"] = this.locale("locale_key.pages.units.error.message.unit_name_already_exists", { name: this.nameInput });
        }
      });
    }
    if (hasErrors) return hasErrors;

    // Generate custom units definitions
    const customUnits = {};
    const customUnitsDepGraph = new DepGraph();

    this.units.forEach((unit) => {
      // filter out none custom units
      // TO DO : filter out compound units
      if (!unit.isCustom || _.isEmpty(unit.symbol) || unit.isCompound) return;

      // add node to dep graph
      customUnitsDepGraph.addNode(`${unit.symbol}`);

      // TO DO : update for unit prefixes
      customUnits[`${unit.symbol}`] = {
        definition: unit.definition
      };
    });

    const unitEvaluator = create(all, {
      number: "BigNumber"
    });
    let definition = "";

    if (this.isConvertibleInput == "true") {
      definition = this.formulaToDefinition(this.unitCalculation.formula);
      try {
        const evaluatedDefinition = unitEvaluator.evaluate(definition);
        definition = evaluatedDefinition.toString();
      } catch (definitionEvaluationErr) { 
        hasErrors = true;
        // Because it's related to a formula, we should show calculation errors
        this.showCalculationErrors = true;
      }
    }

    customUnits[`${this.symbolInput}`] = { definition };

    // check if units have cyclical dependencies
    customUnitsDepGraph.addNode(`${this.symbolInput}`);
    // build dependency tree
    const customUnitSymbols = Object.keys(customUnits);
    customUnitSymbols.forEach((defSymbol) => {
      const definition = customUnits[defSymbol].definition;
      customUnitSymbols.forEach((checkSymbol) => {
        if (definition.includes(checkSymbol)) {
          customUnitsDepGraph.addDependency(defSymbol, checkSymbol);
        }
      });
    });

    // Dependency Cycles are detected when calling overallOrder
    // if found will raise DepGraphCycleError
    try {
      customUnitsDepGraph.overallOrder();
    } catch (depGraphError) {
      hasErrors = true;
      this.hasError["isConvertible"] = true;
      this.errorMsg["isConvertible"] = `${this.locale("locale_key.pages.units.message.the_unit_could_be_created_formula_problem")}`;
    }

    // check if units can be created
    // This should only check for simple units, skip for compound units because those aren't created
    if (this.isCompoundInput == "false") {
      try {
        const orderToCreateUnits = customUnitsDepGraph.overallOrder();
        orderToCreateUnits.forEach((symbol) => {
          const definition = customUnits[symbol];
          try {
            unitEvaluator.createUnit(symbol, definition);
          } catch (err) {}
        });      
      } catch (unitCreationErr) {
        hasErrors = true;
        this.hasError["isConvertible"] = true;
        this.errorMsg["isConvertible"] = `${this.locale("locale_key.pages.units.message.the_unit_could_be_created_formula_problem")}`;
      }
    }

    // if unit calculation has errors
    if (this.unitCalculation.hasError || this.unitCalculation.formula.filter((token) => token.highlightError).length > 0) {
      hasErrors = true;
      this.showCalculationErrors = true;
    }

    return hasErrors;
  }

  private getUnit(symbol){
    const checkFound = ({unit,symbol}) => {
      let found = false;
      let foundUnit;
      
      if (unit.symbol == symbol) {
        found = true;
        foundUnit = unit;
      }

      const aliases = unit.aliases.split(",");
      aliases.forEach((alias) => {
        if (alias.trim() == symbol) {
          found = true;
          foundUnit = unit;
        }
      });
      return {found,foundUnit};
    }
    let foundUnit:UnitFe;
    this.stateService.units.forEach(unit => {
      const mainResult = checkFound({unit,symbol});
      if(mainResult.found){
        foundUnit = mainResult.foundUnit;  
      }
      if(unit.hasPrefixes){
        unit.prefixList.forEach(prefixedUnit => {
          const prefixedResult = checkFound({unit:prefixedUnit,symbol});
          if(prefixedResult.found){
            foundUnit = prefixedResult.foundUnit;
          }
        })
      }
    })
    return foundUnit;
  }

  private getMt(symbol){
    const unit = this.getUnit(symbol);
    let mt = "n/a"
    if(!_.isEmpty(unit)){
      mt = unit.measurementType;
    }
    return mt;
  }

  async saveUnit() {
    if (this.isCompoundInput == "true") {
      const key = this.getMt(this.leftSymbolInput);
      const measurementType = {
        key,
        name: getUnitMeasurementTypeName(UnitMeasurementTypes[key]),
        defaultUnitSymbol: getUnitMeasurementTypeDefaultUnitSymbol(UnitMeasurementTypes[key])
      };
      this.measurementTypeInput.selectedMeasurementType = measurementType;
    }
    
    const hasErrors = await this.checkInputs();

    if (hasErrors) {
      return;
    }

    let definition = "";
    if (this.isCompoundInput == "true") {
      definition = `1 ${this.leftSymbolInput}/${this.rightSymbolInput}`;
    }

    if (this.isConvertibleInput == "true") {
      definition = this.formulaToDefinition(this.unitCalculation.formula);
      const unitEvaluator = create(all, {
        number: "BigNumber"
      });
      const evaluatedDefinition = unitEvaluator.evaluate(definition);
      definition = evaluatedDefinition.toString();
    }

    const unit = new UnitFe({
      name: this.nameInput,
      definition,
      formula: this.unitCalculation.formula,
      symbol: this.symbolInput,
      leftSymbol: this.leftSymbolInput,
      rightSymbol: this.rightSymbolInput,
      description: this.descriptionInput,
      isCustom: true,
      isConvertible: this.isConvertibleInput == "true" ? true : false,
      isCompound: false,
      hasPrefixes: this.hasPrefixesInput == "true" ? true : false,
      measurementType: this.measurementTypeInput.selectedMeasurementType?.key ?? "",
      shouldDisplay: true
    });

    if (unit.hasPrefixes) {
      unit.prefixes = "short";
    }

    this.savingUnit = true;

    if (this.state == UNIT_EDITOR_STATE.ADD_UNIT) {
      await this.stateService.addUnit(unit);
    } else if (this.state == UNIT_EDITOR_STATE.EDIT_UNIT) {
      await this.stateService.editUnit({ unit, oldUnitSymbol: this.unit.symbol });
    }

    this.unitCreated.emit(unit);

    this.savingUnit = false;
    this.clearUnit();
    this.closeModal({
      modal: ModalNames.unitEditor
    });
  }

  startDeleteUnit() {
    this.closeModal({
      modal: ModalNames.unitEditor
    });
    this.openModal({
      modal: ModalNames.deleteUnitModal,
      ignoreBackdropClick: false
    });
  }

  async confirmDeleteUnit() {
    //should really be called unitActionInProgress..
    this.deletingUnit = true;
    const newUnit = _.cloneDeep(this.unit);
    // Set display to hidden
    newUnit.shouldDisplay = false;
    await this.stateService.editUnit({ unit: newUnit, oldUnitSymbol: this.unit.symbol });
    this.deletingUnit = false;
    this.clearUnit();
    this.closeModal({
      modal: ModalNames.deleteUnitModal
    });
  }

  cancelDeleteUnit() {
    this.closeModal({
      modal: ModalNames.deleteUnitModal
    });
    this.openModal({
      modal: ModalNames.unitEditor,
      ignoreBackdropClick: false
    });
  }

  showCustomEditables() {
    let result = false;
    if (this.state == UNIT_EDITOR_STATE.ADD_UNIT) {
      result = true;
    }
    if (this.state == UNIT_EDITOR_STATE.EDIT_UNIT && this.unit && this.unit.isCustom) {
      result = true;
    }
    return result;
  }

  disableEditSymbol() {
    let result = false;

    if (this.state == UNIT_EDITOR_STATE.ADD_UNIT) {
      result = false;
    }

    if (this.state == UNIT_EDITOR_STATE.EDIT_UNIT && this.unit && !this.unit.isCustom) {
      result = true;
    }

    return result;
  }

  startCancelEditingUnits() {
    this.closeModal({
      modal: ModalNames.unitEditor
    });
    this.openModal({
      modal: ModalNames.cancelEditingUnitModal,
      ignoreBackdropClick: false
    });
  }

  exitCancelEditingUnits() {
    this.closeModal({
      modal: ModalNames.cancelEditingUnitModal
    });
    this.openModal({
      modal: ModalNames.unitEditor,
      ignoreBackdropClick: false
    });
  }

  confirmCancelEditingUnits() {
    this.closeModal({
      modal: ModalNames.cancelEditingUnitModal
    });
  }

  leftCompoundSymbolChanged({ unit }) {
    this.leftSymbolInput = `${unit.symbol}`;
  }

  rightCompoundSymbolChanged({ unit }) {
    this.rightSymbolInput = `${unit.symbol}`;
  }

  getUnitsByMeasurementType() {
    // If any logic is needed to filter the unit list, we put it here
    return this.unitsByMeasurementType;
  }

  getCustomUnits() {
    // If any logic is needed to filter the unit list, we put it here
    return this.customUnits;
  }
  onSelectedMeasurementTypeChanged(measurementType) {
    this.includeMeasurementTypes = new Set<string>();
    this.includeMeasurementTypes.add(measurementType.key);
    //If selected measurement type is standard, set isConvertibleInput to true
    if (StandardUnitMeasurementTypes.includes(measurementType.key)) {
      this.isConvertibleInput = "true";
    }
  }

  isConvertibleChanged() {
    // If a measurement type has been selected and is a standard measurement type,
    // overide isConvertibleInput to true
    const measurementType = this.measurementTypeInput.selectedMeasurementType;
    if (measurementType && StandardUnitMeasurementTypes.includes(measurementType.key)) {
      // Need to use settimeout to wait for angular to update the model then udpate the input
      setTimeout(() => {
        this.isConvertibleInput = "true";
      }, 100);
    }
  }
}

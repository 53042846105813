import { CsrdDatapointFe } from "./CsrdDatapointFe";

export class CsrdDisclosureRequirementFe {
  code: string;
  name: string;
  description: string;
  objective: string;
  phaseIn: string | null;
  iro: boolean;
  mdr: boolean;
  csrdDatapoints: CsrdDatapointFe[];
  id: string;

  constructor(
      code: string,
      name: string,
      description: string,
      objective: string,
      phaseIn: string | null,
      iro: boolean,
      mdr: boolean,
      csrdDatapoints: CsrdDatapointFe[],
      id: string
  ) {
      this.code = code;
      this.name = name;
      this.description = description;
      this.objective = objective;
      this.phaseIn = phaseIn;
      this.iro = iro;
      this.mdr = mdr;
      this.csrdDatapoints = csrdDatapoints;
      this.id = id;
  }

  public static fromJson(json: any): CsrdDisclosureRequirementFe {
    const datapoints = json.csrdDatapoints.map(dp => CsrdDatapointFe.fromJson(dp))
    const dr = new CsrdDisclosureRequirementFe(
      json['code'],
      json['name'],
      json['desription'],
      json['pbjective'],
      json['phaseIn'],
      json['iro'],
      json['mdr'],
      datapoints,
      json['id']
    )
    return dr
  }

}
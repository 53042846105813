import { FormControl, FormGroup, Validators } from "@angular/forms"
import { QuestionFe } from "./QuestionFe"
import { EntityFe } from "src/app/model/taxonomy/EntityFe"
import { TaxonomyInfoFe } from "src/app/model/taxonomy/TaxonomyInfoFe"
import { QuestionTypeConversion } from "./QuestionTypeConversionFe"
import { QuestionDateFe } from "./QuestionDateFe"
import { QuestionDataTableFe } from "./QuestionDataTableFe"
import { IdUtil } from "src/app/utils/IdUtil"
import { DataTableRequestFieldFe } from "./DataTableRequestFieldFe"
import { QuestionChoiceOptionFe } from "./QuestionChoiceOptionFe"
import { QuestionSingleChoiceFe } from "./QuestionSingleChoiceFe"
import { QuestionMultipleChoiceFe } from "./QuestionMultipleChoiceFe"

export class SectionFe {
  //Extra properties for the frontent elements
  nameControl = new FormControl(null, Validators.required)
  isTaxonomyConnected = true
  entity: EntityFe
  editTaxonomy = true

  isConnected: boolean

  constructor(public id: string, public name: string | null, public taxonomyKey: string | null, public taxonomyVersion: number | null, public questions: QuestionFe[], public taxonomyInfo?: TaxonomyInfoFe) {
    this.nameControl.patchValue(this.name)
    this.isTaxonomyConnected = taxonomyKey ? true : false
    if (this.taxonomyKey && taxonomyInfo) {
      this.entity = taxonomyInfo.entityByKey(this.taxonomyKey)
    }
  }

  setTaxonomy(entity: EntityFe, taxonomy: TaxonomyInfoFe) {
    this.taxonomyKey = entity.key;
    this.taxonomyVersion = taxonomy.version;
    this.entity = entity;
    this.questions.forEach((ques) => {
      ques.isMapped = false;
      ques.mappedToColKeys = null;
      if (ques instanceof QuestionDateFe && ques.range) {
        ques.mappedToColKeysRange = null;
        ques.mappedToColsRange = [];
      }
      ques.mappedToCols = [];
      if (ques instanceof QuestionDataTableFe) {
        ques.fields = [];
        let field = new DataTableRequestFieldFe(IdUtil.next(), null, null, "STRING", true, [], [], true, null, null, null, false, null, null);
        ques.customFields = [field];
        ques.addEntityColumn(field);
      }
    });
  }

  public static fromTransfer(transfer: any, taxonomyInfo?: TaxonomyInfoFe): SectionFe {
    let questions = transfer.questions.map((q) => QuestionTypeConversion.fromTransfer(q, transfer.id))
    let sectio = new SectionFe(transfer.id, transfer.name, transfer.taxonomyKey, transfer.taxonomyVersion, questions, taxonomyInfo)
    return sectio
  }

  intializeNameControl() {
    this.isTaxonomyConnected = false
    this.entity = null
    this.taxonomyKey = null
    this.taxonomyVersion = null
  }

  initializeTaxonomy() {
    this.isTaxonomyConnected = true
    this.nameControl.patchValue(null)
    this.name = null
  }

  confirmSectionName() {
    if (this.nameControl.errors) {
      this.nameControl.markAllAsTouched()
      return
    }
    this.name = this.nameControl.value
    this.editTaxonomy = false
  }

  getQuestionsWithoutParent(): QuestionFe[] {
    return this.questions.filter((question) => !question.isFollowUpQues)
  }

  hasChildren(parent: QuestionFe): boolean {
    let children = this.getChildrenOfParent(parent)
    return (children.length > 0) ? true : false
  }
  
  getChildrenOfParent(parent: QuestionFe): QuestionFe[] {
    return parent ? this.questions.filter(question => question.parentQuestionId == parent.id) : []
  }

  getChildrenOfParentById(parentId: string): QuestionFe[] {
    return parentId ? this.questions.filter(question => question.parentQuestionId == parentId) : []
  }

  getSiblings(question: QuestionFe): QuestionFe[] {
    const children = this.getChildrenOfParentById(question.parentQuestionId)
    return children.filter(c => c.id != question.id)  
  }

  getParent(child :QuestionFe): QuestionFe | null {
    if (!child.isFollowUpQues) {
      return null;
    }

    let formulaString = child.followUpCondition.toFormulaString();
    const index1 = formulaString.indexOf("$"),
      index2 = formulaString.indexOf(".");
    const parentQuesId = formulaString.substring(index1 + 1, index2);

    child.parentQuestionId = parentQuesId;
    const parentQues = this.questions.find((q) => q.id == parentQuesId);
    return parentQues
  }

  setParentIds() {
    this.questions.forEach(question => {
      let parent = this.getParent(question)
      question.parentQuestionId = parent?.id
    })
  }

  ifAnswerIsStringForFollowup(question: QuestionFe) {
    let parentQues = this.getParent(question)

    if (!parentQues) {
      return null;
    }
    let options: QuestionChoiceOptionFe[] = [];
    let formulaString = question.followUpCondition.toFormulaString();

    // Handle QuestionSingleChoiceFe type
    const extractOptionId = (formula: string) => {
      const optionStartIndex = formula.indexOf(".");
      const optionEndIndex = formula.indexOf(" ", optionStartIndex);
      return formula.substring(optionStartIndex + 1, optionEndIndex);
    };

    if (parentQues instanceof QuestionSingleChoiceFe) {
      const optionId = extractOptionId(formulaString);
      const option = parentQues.options.find((o) => o.id == optionId);
      if (option) options.push(option);

    } else if (parentQues instanceof QuestionMultipleChoiceFe) {
      // Handle QuestionMultipleChoiceFe type
      let optionStartIndex = formulaString.indexOf(".");
      while (optionStartIndex != -1) {
        const optionId = extractOptionId(formulaString);
        const option = parentQues.options.find((o) => o.id == optionId);
        if (option) options.push(option);

        // Move the formulaString forward for the next option
        const optionEndIndex = formulaString.indexOf(" ", optionStartIndex);
        formulaString = formulaString.substring(optionEndIndex + 1);
        optionStartIndex = formulaString.indexOf(".");
      }
    }

    return options.map(o => o.value).join(' or ');
  }

}

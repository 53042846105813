import { DOCUMENT } from "@angular/common";
import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import _ from "lodash";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { PROJECTS_PAGES } from "src/app/components/projects/projects.component";
import { View } from "src/app/model/datahub/View";
import {
  CSRDDataPointSettings,
  CSRDDisclosureSettings,
  CSRDDisclosureSettingsStatus,
  CSRDEsrsSettings,
  CSRDProjectInfoFe,
  CSRDTopicSettings
} from "src/app/model/project/CSRDProjectInfoFe";
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize";
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { LoginServiceFe } from "src/app/services/LoginServiceFe";
import { ResponsiveService } from "src/app/services/ResponsiveService";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { CSRD_PROJECT_PAGES } from "../../csrd-project-overview.component";

@Component({
  selector: "csrd-assess-dr",
  templateUrl: "./assess-dr.component.html",
  styleUrls: ["./assess-dr.component.scss"]
})
export class AssessDrComponent extends AbstractLanguageComponent implements OnInit {
  filter(arg0: any) {
    throw new Error("Method not implemented.");
  }
  collapseAll() {
    throw new Error("Method not implemented.");
  }
  expandAll() {
    throw new Error("Method not implemented.");
  }
  @ViewChild("addProjectModal") addProjectModal: TemplateRef<any>;
  @ViewChild("saveDraft") saveDraft: TemplateRef<any>;
  @ViewChild("showCalcModal") showCalcModal: TemplateRef<any>;
  @ViewChild("editCalcModal") editCalcModal: TemplateRef<any>;
  @ViewChild("selectEmissionsModal") selectEmissionsModal: TemplateRef<any>;
  @ViewChild("emissionsPortalModal") emissionsPortalModal: TemplateRef<any>;
  @ViewChild("removeProjectModal") removeProjectModal: TemplateRef<any>;
  @ViewChild("dataSuccessfullyAddedModal") dataSuccessfullyAddedModal: TemplateRef<any>;
  @ViewChild("missingEmissionsModal") missingEmissionsModal: TemplateRef<any>;
  @ViewChild("updateCategoriesModal") updateCategoriesModal: TemplateRef<any>;
  @ViewChild("exportTableModal") exportTableModal: TemplateRef<any>;
  @ViewChild("emissionCategoriesModal") emissionCategoriesModal: TemplateRef<any>;
  @ViewChild("customEmissionModal") customEmissionModal: TemplateRef<any>;
  @ViewChild("removeEmissionModal") removeEmissionModal: TemplateRef<any>;
  @ViewChild("removeEmissionsConfModal") removeEmissionsConfModal: TemplateRef<any>;
  @ViewChild("csrdPprojectModal") csrdPprojectModal: TemplateRef<any>;
  @ViewChild("editCsrdProjectModal") editCsrdProjectModal: TemplateRef<any>;
  @ViewChild("createSubTopicModal") createSubTopicModal: TemplateRef<any>;
  @ViewChild("confirmNotMaterialModal") confirmNotMaterialModal: TemplateRef<any>;
  @ViewChild("waterManagementModal") waterManagementModal: TemplateRef<any>;
  @ViewChild("editQuestionnaireModal") editQuestionnaireModal: TemplateRef<any>;
  @ViewChild("superuserModal") superuserModal: TemplateRef<any>;
  @ViewChild("selectSuperuserModal") selectSuperuserModal: TemplateRef<any>;
  @ViewChild("removeDescModal") removeDescModal: TemplateRef<any>;
  @ViewChild("addDescModal") addDescModal: TemplateRef<any>;
  @ViewChild("selectListModal") selectListModal: TemplateRef<any>;
  @ViewChild("editUnitName") editUnitName: TemplateRef<any>;
  @ViewChild("omitDataModal") omitDataModal: TemplateRef<any>;
  @ViewChild("editESRSModal") editESRSModal: TemplateRef<any>;
  @ViewChild("showDataPointModal") showDataPointModal: TemplateRef<any>;
  @ViewChild("editDataStorageModal") editDataStorageModal: TemplateRef<any>;

  loadingInProgress: boolean;

  url: string = "";
  initCacheInProgress: boolean;
  menuCollapsed: boolean;
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE;
  loadingData: boolean = false;

  activeFormNo = 1;
  progress: HTMLElement | undefined;
  circles: any;
  prevButton: HTMLButtonElement | undefined;
  nextButton: HTMLButtonElement | undefined;
  currentActive: number = 1;
  inProgress = true;
  projectName = "";
  selectedOption: string = "";
  cardHeader: boolean = true;
  cardFooter: boolean = true;
  E34WaterConsumption: boolean = true;

  pageToolbar = [
    [
      {
        shortLabel: this.locale("locale_key.general.toolbar.button.back"),
        longLabel: this.locale("locale_key.general.toolbar.button.back"),
        tooltip: this.locale("locale_key.general.toolbar.button.back"),
        icon: "la la-arrow-left",
        actionName: "back",
        visible: () => true
      }
    ],
    [
      {
        shortLabel: this.slocale("All projects"),
        longLabel: this.slocale("All projects"),
        tooltip: this.slocale("All projects"),
        icon: "la la-project-diagram",
        visible: () => true,
        actionName: "all_projects"
      }
    ]
    // [
    //   {
    //     shortLabel: this.slocale("Home"),
    //     longLabel: this.slocale("Home"),
    //     tooltip: this.slocale("Home"),
    //     icon: "la la-home",
    //     actionName: "toggle_quick_tips",
    //     visible: () => true
    //   }
    // ],
    // [
    //   {
    //     shortLabel: this.locale("locale_key.general.toolbar.button.export"),
    //     longLabel: this.locale("locale_key.general.toolbar.button.export"),
    //     tooltip: this.locale("locale_key.general.toolbar.button.export"),
    //     icon: "las la-download",
    //     actionName: "export_table",
    //     visible: () => true,
    //     disabled: false
    //   }
    // ],
    // [
    //   {
    //     shortLabel: this.locale("locale_key.general.toolbar.button.info"),
    //     longLabel: this.locale("locale_key.general.toolbar.button.show_quick_tips"),
    //     tooltip: this.locale("locale_key.general.toolbar.button.show_quick_tips"),
    //     icon: "la la-info",
    //     actionName: "toggle_quick_tips",
    //     visible: () => true
    //   }
    // ]
  ];

  stepsDR = [
    { title: this.slocale("Select data for reporting"), completed: false },
    { title: this.slocale("Assign data collection"), completed: false },
    { title: this.slocale("Define submission details"), completed: false }
  ];

  @Output() switchProjectsPage = new EventEmitter<PROJECTS_PAGES>();
  @Output() switchCsrdProjectPage = new EventEmitter<CSRD_PROJECT_PAGES>();
  @Input() activeCsrdProject: CSRDProjectInfoFe;
  @Input() activeTopic: any;
  @Input() activeEsrs: any;
  @Input() activeDr: any;

  activeDatapoint;
  csrdDatapoints;

  constructor(
    private fb: FormBuilder,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    @Inject(DOCUMENT) private _document: Document,
    public stateService: StateServiceFe,
    public displayService: DisplayServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    private loginService: LoginServiceFe
  ) {
    super(languageService);
    this.url = window.location.href;
    this.initCacheInProgress = this.stateService.initCacheInProgress;
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress;
    });

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed;
    });

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize;
    });

    this.screenSize = responsive.currentScreenWidthSize;
  }

  setDatapointCanOimt = (datapoint) => {
    //handle canOmit logic
    // If ESRS is material and Datapoint is material
    if (this.activeEsrs.isMaterial && datapoint.isMaterial) {
      if (datapoint.isVoluntary) {
        datapoint.canOmit = true;
      }
      if (datapoint.hasPhaseIn) {
        datapoint.canOmit = true;
      }
    }

    // If ESRS is not material and Datapoint is material and ESRS is not E1
    if (!this.activeEsrs.isMaterial && datapoint.isMaterial && !`${this.activeEsrs.code}`.toUpperCase().includes("E1")) {
      if (`${this.activeDr.code}`.toUpperCase().includes("IRO-2")) {
        datapoint.canOmit = true;
      }
      if (datapoint.isVoluntary) {
        datapoint.canOmit = true;
      }
      if (datapoint.hasPhaseIn) {
        datapoint.canOmit = true;
      }
    }

    // IRO-1 datapoints must be mandatory
    if (`${datapoint.id}`.toUpperCase().includes("IRO-1")) {
      datapoint.isMaterial = true;
      datapoint.canOmit = false;
    }

    // If ESRS is E1, IRO-2 datapoints are mandatory as well
    if (`${this.activeEsrs.code}`.toUpperCase().includes("E1") && `${datapoint.id}`.toUpperCase().includes("IRO-2")) {
      datapoint.isMaterial = true;
      datapoint.canOmit = false;
    }

    return datapoint;
  };

  ngOnInit(): void {
    //'ESRS 2' is mandatory
    if (this.activeEsrs.code == "ESRS 2") {
      this.activeEsrs.isMaterial = true;
    }

    this.csrdDatapoints = this.activeDr.csrdDatapoints.map((datapoint) => {
      datapoint.showMoreInfo = false;
      datapoint.isOmitted = false;
      datapoint.canOmit = false;
      datapoint.hasPhaseIn = false;
      datapoint.collectVoluntarily = false;

      const { newEsrsSettings, newDisclosureSettings } = this.getNewTopicEsrsAndDisclosureSettings();

      // Get from Esrs Settings first, then get from csrdProject Settings
      datapoint.isMaterial = newEsrsSettings.isMaterial;

      let newDatapointSettings: CSRDDataPointSettings;
      newDisclosureSettings.csrdDatapoints.forEach((datapointSettings) => {
        if (datapoint.id == datapointSettings.id) {
          newDatapointSettings = _.cloneDeep(datapointSettings);
        }
      });

      if (!_.isEmpty(newDatapointSettings)) {
        datapoint.isOmitted = newDatapointSettings.isOmitted;
        datapoint.isMaterial = newDatapointSettings.isMaterial;
        datapoint.collectVoluntarily = newDatapointSettings.collectVoluntarily;
      }

      datapoint = this.setDatapointCanOimt(datapoint);

      return datapoint;
    });
  }

  toggleTips() {
    this.displayService.toggleTips();
  }

  openModal(templateRef: TemplateRef<any>, size: string = "") {
    this.modalService.show(templateRef, { class: size });
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case "back":
        this.switchCsrdProjectPage.emit(CSRD_PROJECT_PAGES.CSRD_PROJECT_OVERVIEW);
        break;
      case "all_projects":
        this.switchProjectsPage.emit(PROJECTS_PAGES.VIEW_PROJECTS);
        this.activeCsrdProject = null;
        break;
      case "export_table":
        this.openModal(this.exportTableModal, "modal-md");
        break;
      case "toggle_quick_tips":
        this.toggleTips();
        break;
    }
  }

  update() {
    this.circles = this._document.querySelectorAll(".circle1");

    this.circles.forEach((circle: any, index: number) => {
      if (index < this.currentActive) {
        circle.classList.add("active-circle1");
      } else {
        circle.classList.remove("active-circle1");
      }
    });

    const actives = this._document.querySelectorAll(".active-circle1");
  }

  async next() {
    /*
      If on the first form, update project settings
    */
    if (this.activeFormNo == 1) {
      this.loadingInProgress = true;

      const { newTopicSettings, newEsrsSettings, newDisclosureSettings } = this.getNewTopicEsrsAndDisclosureSettings();
      newDisclosureSettings.csrdDatapoints = [];

      this.csrdDatapoints.forEach((datapoint) => {
        const setting: CSRDDataPointSettings = {
          id: datapoint.id,
          isMaterial: datapoint.isMaterial,
          isMandatory: datapoint.isMandatory,
          isOmitted: datapoint.isOmitted,
          collectVoluntarily: datapoint.collectVoluntarily
        };
        newDisclosureSettings.csrdDatapoints.push(setting);
      });

      this.updateNewTopicEsrsAndDisclosureSettings({ newTopicSettings, newEsrsSettings, newDisclosureSettings });

      await this.stateService.updateCsrdProject(this.activeCsrdProject);

      this.loadingInProgress = false;
    }

    this.currentActive++;
    this.activeFormNo++;
    this.update();
  }

  prev(): void {
    this.currentActive--;
    if (this.currentActive < 1) {
      this.currentActive = 1;
    }
    this.activeFormNo--;
    this.update();
  }

  closeModal() {
    this.modalService.hide();
  }

  saveDraftModal(saveDraft: boolean) {
    this.modalService.show(this.saveDraft, { class: "modal-md" });
  }

  saveProject() {
    this.modalService.hide();
  }

  editCalculation(id: string) {}

  setDateOption(option: string) {
    this.selectedOption = option;
  }

  toggleShowMoreInfo(datapoint) {
    datapoint.showMoreInfo = !datapoint.showMoreInfo;
  }

  toggleMateriality(datapoint) {
    datapoint.isMaterial = !datapoint.isMaterial;
    datapoint = this.setDatapointCanOimt(datapoint);
  }

  startOmitDatapoint(datapoint) {
    this.activeDatapoint = datapoint;
    if (this.activeDatapoint.isOmitted) {
      this.activeDatapoint.isOmitted = !this.activeDatapoint.isOmitted;
    } else {
      this.openModal(this.omitDataModal, "modal-md");
    }
  }

  cancelOmitDatapoint() {
    // Toggle on then off to get the checkbox unchecked
    // use setTimeout to let angular change detection mechanisms see that change
    setTimeout(() => {
      this.activeDatapoint.isOmitted = true;
      setTimeout(() => {
        this.activeDatapoint.isOmitted = false;
      }, 50);
    }, 50);
    this.closeModal();
  }

  confirmOmitDatapoint() {
    this.activeDatapoint.isOmitted = !this.activeDatapoint.isOmitted;
    this.closeModal();
  }

  toggleCollectVoluntarily(datapoint) {
    datapoint.collectVoluntarily = !datapoint.collectVoluntarily;
  }

  async complete() {
    this.loadingInProgress = true;

    const { newTopicSettings, newEsrsSettings, newDisclosureSettings } = this.getNewTopicEsrsAndDisclosureSettings();
    newDisclosureSettings.csrdDatapoints = [];

    this.csrdDatapoints.forEach((datapoint) => {
      const setting: CSRDDataPointSettings = {
        id: datapoint.id,
        isMaterial: datapoint.isMaterial,
        isMandatory: datapoint.isMandatory,
        isOmitted: datapoint.isOmitted,
        collectVoluntarily: datapoint.collectVoluntarily
      };
      newDisclosureSettings.csrdDatapoints.push(setting);
    });

    this.updateNewTopicEsrsAndDisclosureSettings({ newTopicSettings, newEsrsSettings, newDisclosureSettings });
    await this.stateService.updateCsrdProject(this.activeCsrdProject);

    this.loadingInProgress = false;

    this.switchCsrdProjectPage.emit(CSRD_PROJECT_PAGES.CSRD_PROJECT_OVERVIEW);
  }

  getNewTopicEsrsAndDisclosureSettings(): {
    newTopicSettings: CSRDTopicSettings;
    newEsrsSettings: CSRDEsrsSettings;
    newDisclosureSettings: CSRDDisclosureSettings;
  } {
    let newTopicSettings: CSRDTopicSettings;
    this.activeCsrdProject.definition.csrdSettings.forEach((topicSettings) => {
      if (topicSettings.id == this.activeTopic.id) {
        newTopicSettings = _.cloneDeep(topicSettings);
      }
    });

    if (_.isEmpty(newTopicSettings)) {
      newTopicSettings = {
        id: this.activeTopic.id,
        esrs: []
      };
    }

    let newEsrsSettings: CSRDEsrsSettings;
    newTopicSettings.esrs.forEach((esrsSettings) => {
      if (esrsSettings.id == this.activeEsrs.id) {
        newEsrsSettings = _.cloneDeep(esrsSettings);
      }
    });

    if (_.isEmpty(newEsrsSettings)) {
      newEsrsSettings = {
        id: this.activeEsrs.id,
        isAssessed: false,
        isMaterial: false,
        isMandatory: !!this.activeEsrs.isMandatory,
        disclosures: [],
        selectedSubSubTopics: [],
        selectedSubTopics: []
      };
    }

    let newDisclosureSettings: CSRDDisclosureSettings;
    newEsrsSettings.disclosures.forEach((disclosureSettings) => {
      if (disclosureSettings.id == this.activeDr.id) {
        newDisclosureSettings = _.cloneDeep(disclosureSettings);
      }
    });

    if (_.isEmpty(newDisclosureSettings)) {
      newDisclosureSettings = {
        id: this.activeDr.id,
        status: CSRDDisclosureSettingsStatus.NOT_STARTED,
        hasDraft: false,
        isMandatory: !!this.activeDr.isMandatory,
        draft: {
          csrdDatapoints: []
        },
        questionnaire: [],
        csrdDatapoints: []
      };
    }

    return { newTopicSettings, newEsrsSettings, newDisclosureSettings };
  }

  updateNewTopicEsrsAndDisclosureSettings({
    newTopicSettings,
    newEsrsSettings,
    newDisclosureSettings
  }: {
    newTopicSettings: CSRDTopicSettings;
    newEsrsSettings: CSRDEsrsSettings;
    newDisclosureSettings: CSRDDisclosureSettings;
  }) {
    let existingDisclosureFound = false;
    newEsrsSettings.disclosures = newEsrsSettings.disclosures.map((disclosureSettings) => {
      if (disclosureSettings.id == newDisclosureSettings.id) {
        existingDisclosureFound = true;
        return newDisclosureSettings;
      }
      return disclosureSettings;
    });
    if (!existingDisclosureFound) {
      newEsrsSettings.disclosures.push(newDisclosureSettings);
    }

    let existingEsrsFound = false;
    newTopicSettings.esrs = newTopicSettings.esrs.map((esrsSettings) => {
      if (esrsSettings.id == newEsrsSettings.id) {
        existingEsrsFound = true;
        return newEsrsSettings;
      }
      return esrsSettings;
    });
    if (!existingEsrsFound) {
      newTopicSettings.esrs.push(newEsrsSettings);
    }

    let existingTopicFound = false;
    this.activeCsrdProject.definition.csrdSettings = this.activeCsrdProject.definition.csrdSettings.map((topicSettings) => {
      if (topicSettings.id == newTopicSettings.id) {
        existingTopicFound = true;
        return newTopicSettings;
      }
      return topicSettings;
    });
    if (!existingTopicFound) {
      this.activeCsrdProject.definition.csrdSettings.push(newTopicSettings);
    }
  }
}

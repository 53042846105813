import { IdUtil } from "src/app/utils/IdUtil";
import { AnswerEmissionFactor_AddFe } from "../../timeline/add/answer/AnswerEmissionFactor_AddFe";
import { AnswerEmissionFactorFe } from "../../timeline/answer/AnswerEmissionFactorFe";
import { QuestionFe } from "./QuestionFe";
import { QUESTION_TYPE } from "./QuestionTypeFe";
import { BooleanStatementBuilderFe } from "./condition/BooleanStatementBuilderFe";
import { BooleanStatementFe } from "./condition/BooleanStatementFe";
import { AbstractEmissionFactorFe } from "src/app/model/emissions/AbstractEmissionFactorFe";
import { PredefinedEmissionFactorOptionFe } from "./predefinedoption/PredefinedEmissionFactorOptionFe";

export class QuestionEmissionFactorFe extends QuestionFe {
  
  newAnswer: AnswerEmissionFactor_AddFe = new AnswerEmissionFactor_AddFe(IdUtil.next(), this.sectionId, this.id, null, null)
  answer: AnswerEmissionFactorFe
  predefinedOptions: PredefinedEmissionFactorOptionFe[]
  customValuesAllowedWhenPredefinedOptionsSet: boolean = true

  constructor(
    id: string, 
    sectionId: string,
    ordinal: number | null,
    followUpCondition: BooleanStatementFe | null,
    question: string,
    desc: string | null,
    comments: boolean,
    required: boolean,
    mappedToColKeys: string[] | null,
    public datapointEf: AbstractEmissionFactorFe,
    predefinedOptions: PredefinedEmissionFactorOptionFe[],
    customValuesAllowedWhenPredefinedOptionsSet: boolean,
    csrdDatapointId: string | undefined
  ) {
    super(
      id, 
      sectionId,
      QUESTION_TYPE.EMISSION_FACTOR,
      ordinal, 
      followUpCondition,
      question, 
      desc, 
      comments, 
      required,
      mappedToColKeys,
      csrdDatapointId
    );
    this.datapointEf = datapointEf
    this.predefinedOptions = predefinedOptions
    this.customValuesAllowedWhenPredefinedOptionsSet = customValuesAllowedWhenPredefinedOptionsSet
  }

  public static fromTransfer(transfer: any, sectionId: string): QuestionEmissionFactorFe {
    transfer.followUpCondition = transfer.followUpCondition ? BooleanStatementBuilderFe.fromFormulaString(transfer.followUpCondition) : null
    let item = this.setQuestion(transfer, sectionId);
    return item
  }

  public static setQuestion(transfer: any, sectionId?): QuestionEmissionFactorFe {
    let item = new QuestionEmissionFactorFe (
      transfer.id,
      sectionId ? sectionId : transfer.sectionId,
      transfer.ordinal,
      transfer.followUpCondition,
      transfer.question,
      transfer.desc,
      transfer.comments,
      transfer.required,
      transfer.mappedToColKeys,
      transfer.datapointEf,
      transfer.predefinedOptions? transfer.predefinedOptions.map((item: any) => PredefinedEmissionFactorOptionFe.fromJsonObj(item)): [],
      transfer.customValuesAllowedWhenPredefinedOptionsSet?? true,
      transfer.csrdDatapointId
    )
    item.isFollowUpQues = !!transfer.followUpCondition
    return item
  }

}
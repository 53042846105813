import { DOCUMENT } from "@angular/common";
import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import {
  CSRDDataPointSettings,
  CSRDDisclosureSettings,
  CSRDDisclosureSettingsStatus,
  CSRDEsrsSettings,
  CSRDProjectDefinition,
  CSRDProjectInfoFe,
  CSRDTopicSettings
} from "src/app/model/project/CSRDProjectInfoFe";
import { ScreenWidthSizeFe } from "src/app/model/screens/ScreenWidthSize";
import { DisplayServiceFe } from "src/app/services/DisplayServiceFe";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { LoginServiceFe } from "src/app/services/LoginServiceFe";
import { ResponsiveService } from "src/app/services/ResponsiveService";
import { StateServiceFe } from "src/app/services/StateServiceFe";
import { AbstractLanguageComponent } from "src/app/utils/language/AbstractLanguageComponent";
import { PROJECTS_PAGES } from "../../projects.component";
import CSRD_DEFN from "../csrd_definition.json";
import _ from "lodash";
import COUNTRIES_DATA from "src/app/model/COUNTRIES.json";

export enum CSRD_PROJECT_OVERVIEW_TABS {
  "TOPIC_PROGRESS" = "TOPIC_PROGRESS",
  "SUPER_USER_PROGRESS" = "SUPER_USER_PROGRESS",
  "DATA_REQUEST_PROGRESS" = "DATA_REQUEST_PROGRESS"
}

export enum CSRD_PROJECT_PAGES {
  "CSRD_PROJECT_OVERVIEW" = "CSRD_PROJECT_OVERVIEW",
  "CSRD_DEFINE_MATERIALITY" = "CSRD_DEFINE_MATERIALITY",
  "CSRD_ASSESS_DR" = "CSRD_ASSESS_DR",
  "CSRD_DR" = "CSRD_DR"
}

export enum ESRS_TABS {
  "DISCLOSURES" = "DISCLOSURES",
  "SUPER_USER_TASKS" = "SUPER_USER_TASKS",
  "DATA_REQUESTS" = "DATA_REQUESTS"
}

@Component({
  selector: "csrd-project-overview",
  templateUrl: "./csrd-project-overview.component.html",
  styleUrls: ["./csrd-project-overview.component.scss"]
})
export class CsrdProjectOverviewComponent extends AbstractLanguageComponent implements OnInit {
  public CSRD_PROJECT_OVERVIEW_TABS = CSRD_PROJECT_OVERVIEW_TABS;
  public ESRS_TABS = ESRS_TABS;
  public DR_STATUSES = CSRDDisclosureSettingsStatus;

  filter(arg0: any) {
    throw new Error("Method not implemented.");
  }
  collapseAll() {
    throw new Error("Method not implemented.");
  }
  expandAll() {
    throw new Error("Method not implemented.");
  }

  @Output() switchProjectsPage = new EventEmitter<PROJECTS_PAGES>();
  @Input() activeCsrdProject: CSRDProjectInfoFe;
  @Input() csrdProjects: CSRDProjectInfoFe[];

  @ViewChild("addProjectModal") addProjectModal: TemplateRef<any>;
  @ViewChild("saveDraft") saveDraft: TemplateRef<any>;
  @ViewChild("saveAsDraft") saveAsDraft: TemplateRef<any>;
  @ViewChild("showCalcModal") showCalcModal: TemplateRef<any>;
  @ViewChild("editCalcModal") editCalcModal: TemplateRef<any>;
  @ViewChild("selectEmissionsModal") selectEmissionsModal: TemplateRef<any>;
  @ViewChild("emissionsPortalModal") emissionsPortalModal: TemplateRef<any>;
  @ViewChild("removeProjectModal") removeProjectModal: TemplateRef<any>;
  @ViewChild("dataSuccessfullyAddedModal") dataSuccessfullyAddedModal: TemplateRef<any>;
  @ViewChild("missingEmissionsModal") missingEmissionsModal: TemplateRef<any>;
  @ViewChild("updateCategoriesModal") updateCategoriesModal: TemplateRef<any>;
  @ViewChild("exportTableModal") exportTableModal: TemplateRef<any>;
  @ViewChild("emissionCategoriesModal") emissionCategoriesModal: TemplateRef<any>;
  @ViewChild("customEmissionModal") customEmissionModal: TemplateRef<any>;
  @ViewChild("removeEmissionModal") removeEmissionModal: TemplateRef<any>;
  @ViewChild("removeEmissionsConfModal") removeEmissionsConfModal: TemplateRef<any>;
  @ViewChild("csrdPprojectModal") csrdPprojectModal: TemplateRef<any>;
  @ViewChild("editCsrdProjectModal") editCsrdProjectModal: TemplateRef<any>;
  @ViewChild("createSubTopicModal") createSubTopicModal: TemplateRef<any>;
  @ViewChild("confirmNotMaterialModal") confirmNotMaterialModal: TemplateRef<any>;
  @ViewChild("waterManagementModal") waterManagementModal: TemplateRef<any>;
  @ViewChild("editQuestionnaireModal") editQuestionnaireModal: TemplateRef<any>;
  @ViewChild("superuserModal") superuserModal: TemplateRef<any>;
  @ViewChild("selectSuperuserModal") selectSuperuserModal: TemplateRef<any>;
  @ViewChild("removeDescModal") removeDescModal: TemplateRef<any>;
  @ViewChild("addDescModal") addDescModal: TemplateRef<any>;
  @ViewChild("selectListModal") selectListModal: TemplateRef<any>;
  @ViewChild("editUnitName") editUnitName: TemplateRef<any>;
  @ViewChild("omitDataModal") omitDataModal: TemplateRef<any>;
  @ViewChild("editESRSModal") editESRSModal: TemplateRef<any>;
  @ViewChild("showDataPointModal") showDataPointModal: TemplateRef<any>;
  @ViewChild("editDataStorageModal") editDataStorageModal: TemplateRef<any>;

  loadingInProgress: boolean;

  url: string = "";
  initCacheInProgress: boolean;
  menuCollapsed: boolean;
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE;
  loadingData: boolean = false;

  activeFormNo = 1;
  progress: HTMLElement | undefined;
  circles: any;
  prevButton: HTMLButtonElement | undefined;
  nextButton: HTMLButtonElement | undefined;
  currentActive: number = 1;
  inProgress = true;
  projectName = "";
  saveBeforeLeave = false;
  bizTravel: boolean = false;
  carbonLab: boolean = false;
  emissionsEmpty: boolean = false;
  allProjects: boolean = false;
  activeTab: string = !this.allProjects ? "tab51" : "tab51"; //"tab35"
  pullDataFromPlatform: boolean = false;
  emissionFormSustainLab: boolean = false;
  carbonEmissions2023: boolean = false;
  licencedMaterial: boolean = false;
  cardHeader: boolean = false;
  cardFooter: boolean = false;
  selectedOption: string = "";
  projCSRD: boolean = false;
  CSRDPortfolioA: boolean = true;
  materiality: boolean = false;
  E34WaterConsumption: boolean = false;

  tabNames = {
    tab31: "All data entries",
    tab32: "Per emission source",
    tab33: "Per organizational unit",
    tab34: "Request overview"
  };

  pageToolbar = [
    [
      {
        shortLabel: this.slocale("All projects"),
        longLabel: this.slocale("All projects"),
        tooltip: this.slocale("All projects"),
        icon: "la la-project-diagram",
        visible: () => true,
        actionName: "all_projects"
      }
    ]
    // [
    //   {
    //     shortLabel: this.slocale("Home"),
    //     longLabel: this.slocale("Home"),
    //     tooltip: this.slocale("Home"),
    //     icon: "la la-home",
    //     actionName: "toggle_quick_tips",
    //     visible: () => true
    //   }
    // ],
    // [
    //   {
    //     shortLabel: this.locale("locale_key.general.toolbar.button.export"),
    //     longLabel: this.locale("locale_key.general.toolbar.button.export"),
    //     tooltip: this.locale("locale_key.general.toolbar.button.export"),
    //     icon: "las la-download",
    //     actionName: "export_table",
    //     visible: () => true,
    //     disabled: false
    //   }
    // ],
    // [
    //   {
    //     shortLabel: this.locale("locale_key.general.toolbar.button.info"),
    //     longLabel: this.locale("locale_key.general.toolbar.button.show_quick_tips"),
    //     tooltip: this.locale("locale_key.general.toolbar.button.show_quick_tips"),
    //     icon: "la la-info",
    //     actionName: "toggle_quick_tips",
    //     visible: () => true
    //   }
    // ]
  ];

  steps = [
    { title: this.slocale("Learn about CarbonLab"), completed: false },
    { title: this.slocale("Pick project name"), completed: false },
    { title: this.slocale("Select emissions categories"), completed: false },
    { title: this.slocale("Define data storage"), completed: false }
  ];

  stepsCarbonLab = [
    { title: this.slocale("Learn about CarbonLab"), completed: false },
    { title: this.slocale("Pick project name"), completed: false },
    { title: this.slocale("Select emissions categories"), completed: false },
    { title: this.slocale("Define data storage"), completed: false }
  ];

  stepsCSRD = [
    { title: this.slocale("Learn about CSRD module"), completed: false },
    { title: this.slocale("Define project"), completed: false },
    { title: this.slocale("Add company info"), completed: false }
  ];

  stepsPullData = [
    { title: this.slocale("Select calculation method"), completed: false },
    { title: this.slocale("Select data category"), completed: false },
    { title: this.slocale("Filter and select data"), completed: false },
    { title: this.slocale("Add missing data"), completed: false }
  ];

  stepsWaterConsumption = [
    { title: this.slocale("Select data for reporting"), completed: false },
    { title: this.slocale("Assign data collection"), completed: false },
    { title: this.slocale("Define submission details"), completed: false }
  ];

  activePage: CSRD_PROJECT_PAGES = CSRD_PROJECT_PAGES.CSRD_PROJECT_OVERVIEW;
  activeCsrdOverviewTab: CSRD_PROJECT_OVERVIEW_TABS = CSRD_PROJECT_OVERVIEW_TABS.TOPIC_PROGRESS;
  editingProjectInfo: boolean = false;
  editForm: {
    nameInput: string;
    firstReportingYearInput: string;
    companyNameInput: string;
    companyHQCountryInput: string;
    companyHQTownInput: string;
    companyNoEmployeesInput: string;
  } = { nameInput: "", firstReportingYearInput: "", companyNameInput: "", companyHQCountryInput: "", companyHQTownInput: "", companyNoEmployeesInput: "" };
  countries = Object.entries(COUNTRIES_DATA);

  hasError = {
    reportingYear: false,
    name: false,
    firstReportingYear: false,
    companyName: false,
    companyHQCountry: false,
    companyHQTown: false,
    companyNoEmployees: false
  };

  errorMsg = {
    reportingYear: "",
    name: "",
    firstReportingYear: "",
    companyName: "",
    companyHQCountry: "",
    companyHQTown: "",
    companyNoEmployees: ""
  };

  showErrors: boolean = false;
  csrdDefinition = _.cloneDeep(CSRD_DEFN);
  activeTopic: any;
  activeEsrs: any;
  activeEsrsTab: any = {};
  activeDr: any;
  savingAsDraft: boolean = false;

  csrdFilterCriteria;
  filterTimeout;
  filteredCsrdDefinition;
  esrsOpen: Set<string> = new Set();

  showOtherStatsBox: boolean = false;
  stats: any = {
    overall: {
      totalDatapoints: 0,
      materialDatapoints: 0,
      nonMaterialDatapoints: 0,
      materialOmittedDatapoints: 0,
      nonMaterialVoluntaryDatapoints: 0
    },
    crosscutting: {
      totalDatapoints: 0,
      materialDatapoints: 0,
      nonMaterialDatapoints: 0,
      materialOmittedDatapoints: 0,
      nonMaterialVoluntaryDatapoints: 0
    },
    environmental: {
      totalDatapoints: 0,
      materialDatapoints: 0,
      nonMaterialDatapoints: 0,
      materialOmittedDatapoints: 0,
      nonMaterialVoluntaryDatapoints: 0
    },
    social: {
      totalDatapoints: 0,
      materialDatapoints: 0,
      nonMaterialDatapoints: 0,
      materialOmittedDatapoints: 0,
      nonMaterialVoluntaryDatapoints: 0
    },
    governance: {
      totalDatapoints: 0,
      materialDatapoints: 0,
      nonMaterialDatapoints: 0,
      materialOmittedDatapoints: 0,
      nonMaterialVoluntaryDatapoints: 0
    },
    esrs: {}
  };

  constructor(
    private fb: FormBuilder,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    @Inject(DOCUMENT) private _document: Document,
    public stateService: StateServiceFe,
    public displayService: DisplayServiceFe,
    languageService: LanguageService,
    private responsive: ResponsiveService,
    private loginService: LoginServiceFe
  ) {
    super(languageService);
    this.url = window.location.href;
    this.initCacheInProgress = this.stateService.initCacheInProgress;
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress;
    });

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed;
    });

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize;
    });

    this.screenSize = responsive.currentScreenWidthSize;
  }

  ngOnInit(): void {
    this.boot();
  }

  boot() {
    this.stats = {
      overall: {
        totalDatapoints: 0,
        materialDatapoints: 0,
        nonMaterialDatapoints: 0,
        materialOmittedDatapoints: 0,
        nonMaterialVoluntaryDatapoints: 0
      },
      crosscutting: {
        totalDatapoints: 0,
        materialDatapoints: 0,
        nonMaterialDatapoints: 0,
        materialOmittedDatapoints: 0,
        nonMaterialVoluntaryDatapoints: 0
      },
      environmental: {
        totalDatapoints: 0,
        materialDatapoints: 0,
        nonMaterialDatapoints: 0,
        materialOmittedDatapoints: 0,
        nonMaterialVoluntaryDatapoints: 0
      },
      social: {
        totalDatapoints: 0,
        materialDatapoints: 0,
        nonMaterialDatapoints: 0,
        materialOmittedDatapoints: 0,
        nonMaterialVoluntaryDatapoints: 0
      },
      governance: {
        totalDatapoints: 0,
        materialDatapoints: 0,
        nonMaterialDatapoints: 0,
        materialOmittedDatapoints: 0,
        nonMaterialVoluntaryDatapoints: 0
      },
      esrs: {}
    };

    const topicSettings: {
      [topicId: string]: {
        [esrsId: string]: CSRDEsrsSettings;
      };
    } = {};
    this.activeCsrdProject.definition.csrdSettings.forEach((topicSetting) => {
      topicSettings[topicSetting.id] = {};
      topicSetting.esrs.forEach((esrsSetting) => {
        topicSettings[topicSetting.id][esrsSetting.id] = esrsSetting;
      });
    });

    this.csrdDefinition = _.cloneDeep(CSRD_DEFN);
    this.csrdDefinition = this.csrdDefinition.map((topic) => {
      topic.esrs = topic.esrs.map((esrs: any) => {
        //Determine assessment and materiality by checking active project
        esrs.isAssessed = false;
        if (topicSettings[topic.id] && topicSettings[topic.id][esrs.id] && topicSettings[topic.id][esrs.id].isAssessed) {
          esrs.isAssessed = true;
        }
        esrs.isMaterial = false;
        if (topicSettings[topic.id] && topicSettings[topic.id][esrs.id] && topicSettings[topic.id][esrs.id].isMaterial) {
          esrs.isMaterial = true;
        }

        //Get selected subtopics and subsubtopics
        let selectedSubTopics = [];
        let selectedSubSubTopics = [];
        if (topicSettings[topic.id] && topicSettings[topic.id][esrs.id]) {
          selectedSubTopics = topicSettings[topic.id][esrs.id].selectedSubTopics;
          selectedSubSubTopics = topicSettings[topic.id][esrs.id].selectedSubSubTopics;
        }

        esrs.selectedSubTopics = selectedSubTopics;
        esrs.selectedSubSubTopics = selectedSubSubTopics;

        esrs.disclosures.forEach((disclosure) => {
          // Get DR Status
          const { newDisclosureSettings } = this.getNewTopicEsrsAndDisclosureSettings({ topic, esrs, disclosure });
          disclosure.status = newDisclosureSettings.status;

          //If ESRS is E2, if any DR has a status that isn't "NOT_STARTED", set isAssessed to true;
          if (esrs.code == "ESRS 2" && disclosure.status != CSRDDisclosureSettingsStatus.NOT_STARTED) {
            esrs.isAssessed = true;
            esrs.isMaterial = true;
            esrs.isMandatory = true;
          }

          disclosure.csrdDatapoints.forEach((datapoint) => {
            let newDatapointSettings: CSRDDataPointSettings;
            newDisclosureSettings.csrdDatapoints.forEach((datapointSettings) => {
              if (datapoint.id == datapointSettings.id) {
                newDatapointSettings = _.cloneDeep(datapointSettings);
              }
            });

            if (!_.isEmpty(newDatapointSettings)) {
              datapoint.isOmitted = newDatapointSettings.isOmitted;
              datapoint.isMaterial = newDatapointSettings.isMaterial;
              datapoint.collectVoluntarily = newDatapointSettings.collectVoluntarily;
            }

            //Calculate stats
            this.tickStats({ topic, esrs, disclosure, datapoint });
          });
        });

        //Filter disclosures
        if (esrs.isAssessed && !esrs.isMaterial) {
          esrs.disclosures = esrs.disclosures.filter((disclosure) => {
            if (`${disclosure.code}`.toUpperCase().includes("IRO-1")) {
              return true;
            }

            if (`${disclosure.code}`.toUpperCase().includes("IRO-2")) {
              return true;
            }

            return false;
          });
        }

        return esrs;
      });
      return topic;
    });
    this.filteredCsrdDefinition = [...this.csrdDefinition];
  }

  tickStats({ topic, esrs, disclosure, datapoint }) {
    // Only calculate stats if disclosure assessment is complete
    if (disclosure.status != CSRDDisclosureSettingsStatus.ASSESSING && disclosure.status != CSRDDisclosureSettingsStatus.NOT_STARTED) {
      this.stats.esrs[esrs.id] = this.stats.esrs[esrs.id] || {
        totalDatapoints: 0,
        materialDatapoints: 0,
        nonMaterialDatapoints: 0,
        materialOmittedDatapoints: 0,
        nonMaterialVoluntaryDatapoints: 0,
        disclosure: {}
      };

      this.stats.esrs[esrs.id].disclosure[disclosure.id] = this.stats.esrs[esrs.id].disclosure[disclosure.id] || {
        totalDatapoints: 0,
        materialDatapoints: 0,
        nonMaterialDatapoints: 0,
        materialOmittedDatapoints: 0,
        nonMaterialVoluntaryDatapoints: 0
      };

      if ((datapoint.isMaterial && !datapoint.isOmitted) || (!datapoint.isMaterial && datapoint.collectVoluntarily)) {
        // TOPIC stats
        this.stats.overall.totalDatapoints += 1;
        if (topic.id == "cross_cutting") {
          this.stats.crosscutting.totalDatapoints += 1;
        } else if (topic.id == "environmental") {
          this.stats.environmental.totalDatapoints += 1;
        } else if (topic.id == "social") {
          this.stats.social.totalDatapoints += 1;
        } else if (topic.id == "governance") {
          this.stats.governance.totalDatapoints += 1;
        }

        // ESRS stats
        this.stats.esrs[esrs.id].totalDatapoints += 1;

        // DISCLOSURE stats
        this.stats.esrs[esrs.id].disclosure[disclosure.id].totalDatapoints += 1;
      }

      if (datapoint.isMaterial) {
        // TOPIC stats
        this.stats.overall.materialDatapoints += 1;
        if (topic.id == "cross_cutting") {
          this.stats.crosscutting.materialDatapoints += 1;
        } else if (topic.id == "environmental") {
          this.stats.environmental.materialDatapoints += 1;
        } else if (topic.id == "social") {
          this.stats.social.materialDatapoints += 1;
        } else if (topic.id == "governance") {
          this.stats.governance.materialDatapoints += 1;
        }

        // ESRS stats
        this.stats.esrs[esrs.id].materialDatapoints += 1;

        // DISCLOSURE stats
        this.stats.esrs[esrs.id].disclosure[disclosure.id].materialDatapoints += 1;
      } else {
        // TOPIC stats
        this.stats.overall.nonMaterialDatapoints += 1;
        if (topic.id == "cross_cutting") {
          this.stats.crosscutting.nonMaterialDatapoints += 1;
        } else if (topic.id == "environmental") {
          this.stats.environmental.nonMaterialDatapoints += 1;
        } else if (topic.id == "social") {
          this.stats.social.nonMaterialDatapoints += 1;
        } else if (topic.id == "governance") {
          this.stats.governance.nonMaterialDatapoints += 1;
        }

        // ESRS stats
        this.stats.esrs[esrs.id].nonMaterialDatapoints += 1;

        // DISCLOSURE stats
        this.stats.esrs[esrs.id].disclosure[disclosure.id].nonMaterialDatapoints += 1;
      }

      if (datapoint.isMaterial && datapoint.isOmitted) {
        // TOPIC stats
        this.stats.overall.materialOmittedDatapoints += 1;
        if (topic.id == "cross_cutting") {
          this.stats.crosscutting.materialOmittedDatapoints += 1;
        } else if (topic.id == "environmental") {
          this.stats.environmental.materialOmittedDatapoints += 1;
        } else if (topic.id == "social") {
          this.stats.social.materialOmittedDatapoints += 1;
        } else if (topic.id == "governance") {
          this.stats.governance.materialOmittedDatapoints += 1;
        }

        // ESRS stats
        this.stats.esrs[esrs.id].materialOmittedDatapoints += 1;

        // DISCLOSURE stats
        this.stats.esrs[esrs.id].disclosure[disclosure.id].materialOmittedDatapoints += 1;
      }

      if (!datapoint.isMaterial && datapoint.collectVoluntarily) {
        // TOPIC stats
        this.stats.overall.nonMaterialVoluntaryDatapoints += 1;
        if (topic.id == "cross_cutting") {
          this.stats.crosscutting.nonMaterialVoluntaryDatapoints += 1;
        } else if (topic.id == "environmental") {
          this.stats.environmental.nonMaterialVoluntaryDatapoints += 1;
        } else if (topic.id == "social") {
          this.stats.social.nonMaterialVoluntaryDatapoints += 1;
        } else if (topic.id == "governance") {
          this.stats.governance.nonMaterialVoluntaryDatapoints += 1;
        }

        // ESRS stats
        this.stats.esrs[esrs.id].nonMaterialVoluntaryDatapoints += 1;

        // DISCLOSURE stats
        this.stats.esrs[esrs.id].disclosure[disclosure.id].nonMaterialVoluntaryDatapoints += 1;
      }
    }
  }

  toggleTips() {
    this.displayService.toggleTips();
  }

  openModal(templateRef: TemplateRef<any>, size: string = "") {
    this.modalService.show(templateRef, { class: size });
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case "all_projects":
        this.switchProjectsPage.emit(PROJECTS_PAGES.VIEW_PROJECTS);
        break;
      case "export_table":
        this.openModal(this.exportTableModal, "modal-md");
        break;
      case "toggle_quick_tips":
        this.toggleTips();
        break;
    }
  }

  update() {
    this.circles = this._document.querySelectorAll(".circle1");

    this.circles.forEach((circle: any, index: number) => {
      if (index < this.currentActive) {
        circle.classList.add("active-circle1");
      } else {
        circle.classList.remove("active-circle1");
      }
    });

    const actives = this._document.querySelectorAll(".active-circle1");
  }

  next() {
    // if (this.activeFormNo == 1) {
    //   this.reqDetailsForm.markAllAsTouched();
    //   if (this.reqDetailsForm.invalid) {
    //     return;
    //   }
    // } else {
    //   this.validateQuestionnaire();
    //   if (this.isQuestionnaireInvalid) {
    //     return;
    //   }
    // }
    this.currentActive++;
    this.activeFormNo++;
    this.update();
  }

  prev(): void {
    this.currentActive--;
    if (this.currentActive < 1) {
      this.currentActive = 1;
    }
    this.activeFormNo--;
    this.update();
  }

  closeModal() {
    this.modalService.hide();
  }

  saveDraftModal(saveDraft: boolean) {
    this.saveBeforeLeave = saveDraft;
    this.modalService.show(this.saveDraft, { class: "modal-md" });
  }

  saveProject() {
    this.modalService.hide();
    this.saveBeforeLeave = false;
  }

  selectTab(tabId: string) {
    this.activeTab = tabId;
  }

  getActiveTabName(): string {
    return this.slocale(this.tabNames[this.activeTab]);
  }

  editCalculation(id: string) {}

  setDateOption(option: string) {
    this.selectedOption = option;
  }

  switchActiveCsrdOverviewTab(tab: CSRD_PROJECT_OVERVIEW_TABS) {
    this.activeCsrdOverviewTab = tab;
  }

  getCsrdOverviewTabName(tab: CSRD_PROJECT_OVERVIEW_TABS) {
    let name = "";
    switch (tab) {
      case CSRD_PROJECT_OVERVIEW_TABS.TOPIC_PROGRESS:
        name = this.slocale("Topic progress");
        break;
      case CSRD_PROJECT_OVERVIEW_TABS.SUPER_USER_PROGRESS:
        name = this.slocale("Superuser progress");
        break;
      case CSRD_PROJECT_OVERVIEW_TABS.DATA_REQUEST_PROGRESS:
        name = this.slocale("Data request progress");
        break;
    }
    return name;
  }

  getCsrdOverviewTabTitle(tab: CSRD_PROJECT_OVERVIEW_TABS) {
    let title = "";
    switch (tab) {
      case CSRD_PROJECT_OVERVIEW_TABS.TOPIC_PROGRESS:
        title = this.slocale("This view provides project progress per topic. Check the completion rate for assigned data collection and finalized answers.");
        break;
      case CSRD_PROJECT_OVERVIEW_TABS.SUPER_USER_PROGRESS:
        title = this.slocale("This view displays all superuser tasks within the project. Review the status of each task and the overall completion rate.");
        break;
      case CSRD_PROJECT_OVERVIEW_TABS.DATA_REQUEST_PROGRESS:
        title = this.slocale(
          "This view displays all data requests within the project. Review the status of each data request and the overall completion rate."
        );
        break;
    }
    return title;
  }

  startEditProjectInfo() {
    this.editForm.nameInput = this.activeCsrdProject.name;
    this.editForm.firstReportingYearInput = this.activeCsrdProject.definition.firstReportingYear;
    this.editForm.companyNameInput = this.activeCsrdProject.definition.company.name;
    this.editForm.companyHQCountryInput = this.activeCsrdProject.definition.company.hq.country;
    this.editForm.companyHQTownInput = this.activeCsrdProject.definition.company.hq.town;
    this.editForm.companyNoEmployeesInput = this.activeCsrdProject.definition.company.noEmployees;
    this.openModal(this.editCsrdProjectModal, "modal-lg");
  }

  async confirmEditProjectInfo() {
    const hasErrors = this.checkInputs();
    if (hasErrors) {
      this.showErrors = true;
      return;
    }

    this.editingProjectInfo = true;
    const id = this.activeCsrdProject.id;
    const name = this.editForm.nameInput;
    const draft = this.activeCsrdProject.draft;
    const createdAt = this.activeCsrdProject.createdAt;
    const definition: CSRDProjectDefinition = {
      isStarted: this.activeCsrdProject.definition.isStarted,
      reportingYear: this.activeCsrdProject.definition.reportingYear,
      firstReportingYear: this.editForm.firstReportingYearInput,
      company: {
        name: this.editForm.companyNameInput,
        noEmployees: this.editForm.companyNoEmployeesInput,
        hq: {
          country: this.editForm.companyHQCountryInput,
          town: this.editForm.companyHQTownInput
        }
      },
      csrdSettings: this.activeCsrdProject.definition.csrdSettings || []
    };
    const csrdProject = new CSRDProjectInfoFe({ id, name, draft, definition, createdAt });
    await this.stateService.updateCsrdProject(csrdProject);
    this.activeCsrdProject = csrdProject;
    this.editingProjectInfo = false;
    this.modalService.hide();
  }

  defineMateriality({ esrs, topic }: { esrs: any; topic: any }) {
    //switch page to define materiality page
    this.activeEsrs = esrs;
    this.activeTopic = topic;
    this.activePage = CSRD_PROJECT_PAGES.CSRD_DEFINE_MATERIALITY;
  }

  switchCsrdProjectPage(page: CSRD_PROJECT_PAGES) {
    this.activePage = page;
    this.boot();
  }

  getActiveEsrsTab(esrs: any) {
    const id = esrs.id;
    let activeTab = this.activeEsrsTab[id];
    if (_.isEmpty(activeTab)) {
      activeTab = ESRS_TABS.DISCLOSURES;
      this.activeEsrsTab[id] = ESRS_TABS.DISCLOSURES;
    }
    return activeTab;
  }

  switchActiveEsrsTab({ esrs, tab }: { esrs: any; tab: ESRS_TABS }) {
    const id = esrs.id;
    this.activeEsrsTab[id] = tab;
  }

  assessDr({ disclosure, esrs, topic }: { disclosure: any; esrs: any; topic: any }) {
    this.activeDr = disclosure;
    this.activeEsrs = esrs;
    this.activeTopic = topic;
    this.activePage = CSRD_PROJECT_PAGES.CSRD_ASSESS_DR;
  }

  viewDr({ disclosure, esrs, topic }: { disclosure: any; esrs: any; topic: any }) {
    this.activeDr = disclosure;
    this.activeEsrs = esrs;
    this.activeTopic = topic;
    this.activePage = CSRD_PROJECT_PAGES.CSRD_DR;
  }

  callSwitchProjectsPage(event) {
    this.switchProjectsPage.emit(event);
  }

  resolveCountryName(locales) {
    if (!locales) {
      return "";
    }

    return locales[this.activeLanguage?.code] || locales["en"] || locales;
  }

  resetErrorObject() {
    this.hasError = {
      reportingYear: false,
      name: false,
      firstReportingYear: false,
      companyName: false,
      companyHQCountry: false,
      companyHQTown: false,
      companyNoEmployees: false
    };

    this.errorMsg = {
      reportingYear: "",
      name: "",
      firstReportingYear: "",
      companyName: "",
      companyHQCountry: "",
      companyHQTown: "",
      companyNoEmployees: ""
    };
  }

  checkInputs() {
    this.resetErrorObject();

    let hasErrors = false;

    if (_.isEmpty(this.editForm.nameInput)) {
      hasErrors = true;
      this.hasError["name"] = true;
      this.errorMsg["name"] = this.slocale("Project name is required");
    }

    //Check if project with same name exists
    if (!_.isEmpty(this.editForm.nameInput)) {
      for (let i = 0; i < this.csrdProjects.length; i++) {
        const project = this.csrdProjects[i];
        if (_.eq(this.editForm.nameInput.toLowerCase().trim(), project.name.toLowerCase().trim()) && this.activeCsrdProject.id != project.id) {
          hasErrors = true;
          this.hasError["name"] = true;
          this.errorMsg["name"] = this.slocale("A project with that name already exists. Please choose a different name");
          break;
        }
      }
    }

    if (!this.editForm.firstReportingYearInput) {
      hasErrors = true;
      this.hasError["firstReportingYear"] = true;
      this.errorMsg["firstReportingYear"] = this.slocale("First reporting year is required");
    }

    let firstReportingYear;
    try {
      firstReportingYear = parseInt(this.editForm.firstReportingYearInput);
      if (firstReportingYear < 2000 || firstReportingYear >= 2100) {
        hasErrors = true;
        this.hasError["firstReportingYear"] = true;
        this.errorMsg["firstReportingYear"] = this.slocale("First reporting year should be 4 numbers and start with 20XX");
      }
    } catch (err) {
      hasErrors = true;
      this.hasError["firstReportingYear"] = true;
      this.errorMsg["firstReportingYear"] = this.slocale("First reporting year should be 4 numbers and start with 20XX");
    }

    // If we are on the second form return, otherwise continue
    if (this.activeFormNo == 2) {
      return hasErrors;
    }

    if (_.isEmpty(this.editForm.companyNameInput)) {
      hasErrors = true;
      this.hasError["companyName"] = true;
      this.errorMsg["companyName"] = this.slocale("Company name is required");
    }

    if (_.isEmpty(this.editForm.companyHQCountryInput)) {
      hasErrors = true;
      this.hasError["companyHQCountry"] = true;
      this.errorMsg["companyHQCountry"] = this.slocale("Country is required");
    }

    if (_.isEmpty(this.editForm.companyHQTownInput)) {
      hasErrors = true;
      this.hasError["companyHQTown"] = true;
      this.errorMsg["companyHQTown"] = this.slocale("Town is required");
    }

    if (_.isEmpty(this.editForm.companyNoEmployeesInput)) {
      hasErrors = true;
      this.hasError["companyNoEmployees"] = true;
      this.errorMsg["companyNoEmployees"] = this.slocale("Number of employees is required");
    }

    return hasErrors;
  }

  openDataCollection() {
    this.activeCsrdProject.definition.isStarted = true;
  }

  expandAllUnderDataCollection() {
    this.csrdDefinition.forEach((topic) => {
      topic.esrs.forEach((esrs) => {
        if (esrs.isAssessed || esrs.code == "ESRS 2") {
          this.esrsOpen.add(esrs.id);
        }
      });
    });
  }

  collapseAllUnderDataCollection() {
    this.csrdDefinition.forEach((topic) => {
      topic.esrs.forEach((esrs) => {
        this.esrsOpen.delete(esrs.id);
      });
    });
  }

  filterCsrd() {
    // Do some de-bouncing
    if (!_.isEmpty(this.filterTimeout)) {
      clearTimeout(this.filterTimeout);
    }
    this.filterTimeout = setTimeout(() => {
      this.filteredCsrdDefinition = _.cloneDeep(this.csrdDefinition);

      const criteria = this.csrdFilterCriteria.toLowerCase().trim();
      if (!_.isEmpty(criteria)) {
        this.filteredCsrdDefinition = this.filteredCsrdDefinition.filter((topic) => {
          let showTopic = false;

          const topicNameCheck = topic.name.toLowerCase().trim();
          if (topicNameCheck.includes(criteria)) {
            showTopic = true;
          }

          topic.esrs = topic.esrs.filter((esrs) => {
            let showEsrs = false;

            const esrsNameCheck = esrs.name?.toLowerCase().trim() || "";
            const esrsCodeCheck = esrs.code?.toLowerCase().trim() || "";
            if (esrsNameCheck.includes(criteria)) {
              showEsrs = true;
            }
            if (esrsCodeCheck.includes(criteria)) {
              showEsrs = true;
            }

            esrs.disclosures = esrs.disclosures.filter((disclosure) => {
              let showDisclosure = false;

              const disclosureNameCheck = disclosure.name?.toLowerCase().trim() || "";
              const disclosureCodeCheck = disclosure.code?.toLowerCase().trim() || "";

              if (disclosureNameCheck.includes(criteria)) {
                showDisclosure = true;
              }

              if (disclosureCodeCheck.includes(criteria)) {
                showDisclosure = true;
              }

              if (showDisclosure) {
                showEsrs = true;
              }

              return showDisclosure;
            });

            if (showEsrs) {
              showTopic = true;
            }
            return showEsrs;
          });

          return showTopic;
        });
      }
    }, 250);
  }

  toggleEsrsOpen(esrs) {
    if (this.esrsOpen.has(esrs.id)) {
      this.esrsOpen.delete(esrs.id);
    } else {
      this.esrsOpen.add(esrs.id);
    }
  }

  firstReportingYearInputChanged(evt) {
    if (evt.key.toLowerCase() == "e") {
      evt.preventDefault();
    }
    this.checkInputs();
  }

  showOtherStats() {
    this.showOtherStatsBox = true;
  }

  hideOtherStats() {
    this.showOtherStatsBox = false;
  }

  getNewTopicEsrsAndDisclosureSettings({ topic, esrs, disclosure }): {
    newTopicSettings: CSRDTopicSettings;
    newEsrsSettings: CSRDEsrsSettings;
    newDisclosureSettings: CSRDDisclosureSettings;
  } {
    let newTopicSettings: CSRDTopicSettings;
    this.activeCsrdProject.definition.csrdSettings.forEach((topicSettings) => {
      if (topicSettings.id == topic.id) {
        newTopicSettings = _.cloneDeep(topicSettings);
      }
    });

    if (_.isEmpty(newTopicSettings)) {
      newTopicSettings = {
        id: topic.id,
        esrs: []
      };
    }

    let newEsrsSettings: CSRDEsrsSettings;
    newTopicSettings.esrs.forEach((esrsSettings) => {
      if (esrsSettings.id == esrs.id) {
        newEsrsSettings = _.cloneDeep(esrsSettings);
      }
    });

    if (_.isEmpty(newEsrsSettings)) {
      newEsrsSettings = {
        id: esrs.id,
        isAssessed: false,
        isMaterial: false,
        isMandatory: false,
        disclosures: [],
        selectedSubSubTopics: [],
        selectedSubTopics: []
      };
      //Check if Topic is mandatory
      if (esrs.code == "ESRS 2") {
        newEsrsSettings.isMandatory = true;
      }
    }

    let newDisclosureSettings: CSRDDisclosureSettings;
    newEsrsSettings.disclosures.forEach((disclosureSettings) => {
      if (disclosureSettings.id == disclosure.id) {
        newDisclosureSettings = _.cloneDeep(disclosureSettings);
      }
    });

    if (_.isEmpty(newDisclosureSettings)) {
      newDisclosureSettings = {
        id: disclosure.id,
        status: CSRDDisclosureSettingsStatus.NOT_STARTED,
        hasDraft: false,
        isMandatory: false,
        draft: {
          csrdDatapoints: []
        },
        questionnaire: [],
        csrdDatapoints: []
      };
    }

    return { newTopicSettings, newEsrsSettings, newDisclosureSettings };
  }

  showDisclosureStats(disclosure) {
    return disclosure.status != this.DR_STATUSES.NOT_STARTED && disclosure.status != this.DR_STATUSES.ASSESSING;
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { ValidationMessages } from 'src/app/model/form-validation/FormValidationMessages'
import { ValidationRegex } from 'src/app/model/form-validation/ValidationRegex'
import { ChangePasswordFe } from 'src/app/model/org/ChangePasswordFe'
import { AlertServiceFe } from 'src/app/services/AlertServiceFe'
import { LanguageService } from 'src/app/services/LanguageServiceFe'
import { LoginServiceFe } from 'src/app/services/LoginServiceFe'
import { StateServiceFe } from 'src/app/services/StateServiceFe'
import { ErrorsFe, OpenErrorsFe } from 'src/app/utils/KNOWN_ERRORS'
import { AbstractLanguageComponent } from 'src/app/utils/language/AbstractLanguageComponent'
import { Subscription } from 'rxjs'
import { Language } from 'src/app/utils/language/Language'
import { ScreenWidthSizeFe } from 'src/app/model/screens/ScreenWidthSize'
import { ResponsiveService } from 'src/app/services/ResponsiveService'
import { DisplayServiceFe } from 'src/app/services/DisplayServiceFe'
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input-gg'

interface PhoneNumber {
  countryCode: string
  number: string
}

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent extends AbstractLanguageComponent implements OnInit {
  
  currentPass = new FormControl(null, [Validators.required])
  newPass = new FormControl(null, [Validators.required, Validators.pattern(ValidationRegex.NewPasswordRegex)])
  confirmPass = new FormControl(null, [Validators.required])

  isUserOpen = false
  isPasswordOpen = false
  isSettingsOpen = false
  inProgress = false
  passChange = false
  passwordChanged = false
  firstName: string
  lastName: string
  selectedLang: Language
  selectedEmailLang: Language
  passwordMessage = ValidationMessages.password[0].message
  passwordPatternMessage = ValidationMessages.password[1].message
  passwordNoMatchMessage = ValidationMessages.password[2].message
  userPhones: PhoneNumber[] = []
  selectedCountryCode: string = '+46'
  flagClass: string = 'flag-icon-se'
  verificationCode: any
  addNewPhone: boolean = true
  languages = this.languageService.availableLanguages
  private languageSubscription: Subscription
  activeLanguage: Language
  lang: string

  url: string = ''
  initCacheInProgress: boolean
  menuCollapsed: boolean
  screenSize: ScreenWidthSizeFe = ScreenWidthSizeFe.WIDTH_LARGE
  inviteMode

  SearchCountryField = SearchCountryField
  CountryISO = CountryISO
  PhoneNumberFormat = PhoneNumberFormat
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required]),
  })

  pageToolbar = [
    ,
    [
      {
        shortLabel: this.locale('locale_key.general.toolbar.button.info'),
        longLabel: this.locale('locale_key.general.toolbar.button.show_quick_tips'),
        tooltip: this.locale('locale_key.general.toolbar.button.show_quick_tips'),
        icon: 'la la-info-circle',
        actionName: 'toggle_quick_tips',
        visible: () => true,
        disabled: false,
      },
    ],
  ]

  isSaveButtonDisabled: boolean = true

  constructor(
    languageService: LanguageService,
    private loginService: LoginServiceFe,
    private stateService: StateServiceFe,
    private alertService: AlertServiceFe,
    private displayService: DisplayServiceFe,
    private responsive: ResponsiveService,
    private ErrorsFe: ErrorsFe,
  ) {
    super(languageService)
    this.url = window.location.href
    this.initCacheInProgress = this.stateService.initCacheInProgress
    this.stateService.initCacheSubject.subscribe((initCacheInProgress) => {
      this.initCacheInProgress = initCacheInProgress
    })

    this.responsive.menuCollapsedSubject.subscribe((collapsed) => {
      this.menuCollapsed = collapsed
    })

    this.responsive.screenWidthSizeSubject.subscribe((screenSize: ScreenWidthSizeFe) => {
      this.screenSize = screenSize
    })
    this.screenSize = responsive.currentScreenWidthSize
    this.confirmPass.setValidators([Validators.required, this.passwordMatchValidator.bind(this)]);

    this.newPass.valueChanges.subscribe(() => {
      this.confirmPass.updateValueAndValidity();
    });

    this.confirmPass.valueChanges.subscribe(() => {
      this.confirmPass.updateValueAndValidity();
    });
  }

  async ngOnInit(): Promise<void> {
    this.firstName = this.loginService.loginUser.firstName
    this.lastName = this.loginService.loginUser.lastName
    this.selectedLang = this.languageService.getActiveLanguage()
    
    this.languageSubscription = this.languageService.languageSubject.subscribe((language: Language) => {
      this.activeLanguage = language
      this.lang = language.toString()
    })

    this.selectedEmailLang = this.languageService.getLanguageObject(this.loginService.loginUser.emailLanguage)
  }

  async changePassword() {
    if (this.newPass.invalid || this.currentPass.invalid || this.confirmPass.invalid) {
      this.newPass.markAllAsTouched()
      this.confirmPass.markAllAsTouched()
      this.currentPass.markAllAsTouched()
      return
    }
    try {
      this.inProgress = true
      let userObj = new ChangePasswordFe(this.currentPass.value, this.newPass.value, this.confirmPass.value)
      await this.stateService.changePassword(userObj)
      this.inProgress = false
      this.passChange = false
      this.passwordChanged = true;
      this.newPass.reset()
      this.loginService.updateUserPasswordUpdateTimestamp();
      this.confirmPass.reset()
      this.currentPass.reset()
    } catch (error) {
        let knownError = this.ErrorsFe.matchError(error.error);
        if (knownError === OpenErrorsFe.PASSWORD_IS_ALREADY_USED) {
          this.newPass.setErrors({ previouslyUsed: true });
          this.confirmPass.setErrors({ previouslyUsed: true });
        } else if (knownError == OpenErrorsFe.INCORRECT_PASS_ERROR_WHILE_CHANGING_PASS || knownError == OpenErrorsFe.UNMATCHED_PASSES_ERROR_WHILE_CHANGING_PASS) {
          this.alertService.showError(knownError.message)
        }
      this.inProgress = false
    }
  }

  async changeLanguage() {
    this.inProgress = true
    try {
      await this.stateService.changeProfileEmailLanguage(this.selectedLang.code, this.selectedEmailLang.code)
      this.languageService.changeLanguage(this.selectedLang.code)
      this.loginService.changeLangauage(this.selectedLang.code)
      this.loginService.changeEmailLangauage(this.selectedEmailLang.code)
    } catch (err) {}
    this.inProgress = false
    this.isSaveButtonDisabled = true
  }

  passwordMatchValidator(control: AbstractControl) {
    const newPassword = this.newPass?.value;
    const confirmPassword = control.value;
    if (newPassword !== confirmPassword) {
      return { notMatch: true };
    } else {
      return null;
    }
  }

  addPhoneNumber(): void {
    const phonenumber = this.phoneForm.get('phone').value
  }

  deletePhoneNumber(index: number): void {
    this.userPhones.splice(index, 1)
  }

  setSelectedCountryCode(code: string) {
    this.selectedCountryCode = code
    this.flagClass = `flag-icon flag-icon-${code === '+46' ? 'se' : 'gb'}` // adjust flag class based on selected country code
  }

  getFlagClass(countryCode: string): string {
    switch (countryCode) {
      case '+44':
        return 'flag-icon-gb'
      case '+46':
        return 'flag-icon-se'
      // add more cases for other country codes/flags
      default:
        return ''
    }
  }

  addNewPhoneNumber() {
    this.addNewPhone = true
  }

  onLanguageChange(code: string) {
    this.languageService.changeLanguage(code)
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe()
    }
  }

  getActiveLanguageLabel(): string {
    const language = this.languageService.availableLanguages.find((lang) => lang.code === this.lang)
    return language ? language.label : 'English (Default)'
  }
  toggleTips() {
    this.displayService.toggleTips()
  }

  handleToolbarAction(actionName: string) {
    switch (actionName) {
      case 'toggle_quick_tips':
        this.toggleTips()
        break
    }
  }

  changePlatformLanguage(language: Language) {
    this.isSaveButtonDisabled = this.activeLanguage.code === language.code && this.loginService.loginUser.emailLanguage === this.selectedEmailLang.code;
    this.selectedLang = language;
  }

  changeEmailLanguage(language: Language) {
    this.isSaveButtonDisabled = this.loginService.loginUser.emailLanguage === language.code && this.activeLanguage.code === this.selectedLang.code;
    this.selectedEmailLang = language;
  }

  isEmailLanguageChanged() {
    return this.loginService.loginUser.emailLanguage !== this.selectedEmailLang.code;
  }

  isPlatformLanguageChanged() {
    return this.loginService.loginUser.language !== this.selectedLang.code;
  }

  getChangedLanguageFlag() {
    if (this.isEmailLanguageChanged() && this.isPlatformLanguageChanged()) {

    } else if (this.isEmailLanguageChanged()) {
      return this.selectedEmailLang.icon
    } else if (this.isPlatformLanguageChanged()) {
      return this.selectedLang.icon
    }
  }

  getChangedLanguageLabel() {
    if (this.isEmailLanguageChanged() && this.isPlatformLanguageChanged()) {

    } else if (this.isEmailLanguageChanged()) {
      return this.selectedEmailLang.label
    } else if (this.isPlatformLanguageChanged()) {
      return this.selectedLang.label
    }
  }

  getChangedLanguageMessage() {
    if (this.isEmailLanguageChanged()) {
      return this.locale('locale_key.pages.settings.email_language.message.setting_email_language_to')
    } else if (this.isPlatformLanguageChanged()) {
      return this.locale('locale_key.pages.settings.email_language.message.setting_platform_language_to')
    }
  }

  isPlatfomAndEmailChanges() {
    return this.isEmailLanguageChanged() && this.isPlatformLanguageChanged()
  }

  isPasswordOnChange(): boolean {
    return this.currentPass.touched || this.newPass.touched || this.confirmPass.touched
  }
}

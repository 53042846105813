import { BrowserModule } from '@angular/platform-browser'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { AppRoutingModule } from './route/app-routing.module'
import { AppComponent } from './app.component'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { RouterFe } from './route/RouterFe'
import { DashboardComponent } from './components/dashboard/dashboard.component'
import { DashboardDetailComponent } from './components/dashboard/dashboard.detail.component'
import { LoginComponent } from './components/auth/login.component'
import { FilePortalComponent } from './components/file/file-portal.component'
import { FileViewerComponent } from './components/file/file-viewer.component'
import { FilePickerComponent } from './components/file/file-picker.component'
import { DataPortalComponent } from './components/data/data-portal.component'
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal'
import { AlertModule } from 'ngx-bootstrap/alert'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { GlobalInterceptorFe } from 'src/app/services/GlobalInterceptorFe'
import { CollapseModule } from 'ngx-bootstrap/collapse'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FooterComponent } from './components/layout/footer/footer.component'
import { HeaderComponent } from './components/layout/header/header.component'
import { SidebarComponent } from './components/layout/sidebar/sidebar.component'
import { FeatherModule } from 'angular-feather'
import { Maximize, Minimize, Menu, PlusSquare, Globe, Smile, DollarSign, User, Mail, Minus, Users, UserPlus, RefreshCw, XCircle, LogOut, Edit, List, ChevronDown } from 'angular-feather/icons'
import { AlertModal } from './components/layout/header/alert.modal'
import { OpenLayoutComponent } from './components/layout/open-layout/open-layout.component'
import { WorkspaceLayoutComponent } from './components/layout/workspace-layout/workspace-layout.component'
import { InvitecustomerComponent } from './components/invitecustomer/invitecustomer.component'
import { RegisterComponent } from './components/register/register.component'
import { ResetRequestComponent } from './components/reset-request/reset-request.component'
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component'
import { ResetforgotpasswordComponent } from './components/resetforgotpassword/resetforgotpassword.component'
import { NotificationComponent } from './components/notification/notification.component'
import { NgxDocViewerModule } from 'ngx-doc-viewer'
import { EmissionPortalComponent } from './components/emission/emission-portal.component'
import { TaxonomyComponent } from './components/taxonomy/taxonomy.component'
import { DataCategoriesComponent } from './components/data-categories/data-categories.component'
import { OrganizationComponent } from './components/organization/organization.component'
import { DataEntryInsertComponent } from './components/data/data.entry.component'
import { ImageCropperModule } from 'ngx-image-cropper'
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer'
import { AngularResizeEventModule } from 'angular-resize-event'
import { ReviseOrganizationModal } from './components/organization/ReviseOrganizationModal'
import { HomeComponent } from './components/home/home.component'
import { ReferenceWidgetsComponent } from './components/reference-widgets/reference-widgets.component'
import { DetailsComponent } from './components/details/details.component'
import { DataSupplierCompanyEntryComponent } from './components/data-suppliers/data-supplier-company/data-supplier-company-entry.component'
import { DataSupplierPersonnelEntryComponent } from './components/data-suppliers/data-supplier-personnel/data-supplier-personnel-entry.component'
import { DataSupplierPersonnelPortalComponent } from './components/data-suppliers/data-supplier-personnel/data-supplier-personnel-portal.component'
import { RequestDetailsComponent } from './components/data-suppliers/data-supplier-request/details.component'
import { TaskDetailsComponent } from './components/data-suppliers/data-supplier-task/details.component'
import { PartnerDetailsComponent } from './components/data-suppliers/data-supplier-company/details.component'
import { WorkspacesComponent } from './components/workspaces/workspaces.component'
import { CacheLoadingProgressComponent } from './components/cache-loading/cache-loading-progress.component'
import { PreWorkspaceLayoutComponent } from './components/layout/preworkspace-layout/preworkspace-layout.component'
import { CommonModule, DatePipe } from '@angular/common'
import { CustomerEntryComponent } from './components/invitecustomer/customer-entry.component'
import { ManagerEntryComponent } from './components/invitecustomer/manager-entry.component'
import { AttachmentDetailsComponent } from './components/details/attachment-details.component'
import { TaskAttachmentDetailsComponent } from './components/data-suppliers/data-supplier-task/attachment-details.component'
import { RequestAttachmentDetailsComponent } from './components/data-suppliers/data-supplier-request/attachment-details.component'
import { ChartSettingsComponent } from './components/dashboard/chart-settings.component'
import { NgxResizerDirective } from './components/details/resizer.directive'
import { RequestCreationLauncherComponent } from './components/data-suppliers/data-supplier-request/request-creation-launcher.component'
import { QuestionnairePreviewComponent } from './components/data-suppliers/data-supplier-request/questionnaire-preview.component'
import { InventoryComponent } from './components/inventory/inventory.component'
import { VerifySharedDashboardComponent } from './components/verify/verrifySharedDashboard.component'
import { EmailTableComponent } from './components/admin-portal/email-table/email-table.component'
import { ClientsComponent } from './components/admin-portal/clients/clients.component'
import { CustomerSuccessComponent } from './components/admin-portal/admins/customer-success.component'
import { CustomerSuccessInviteComponent } from './components/admin-portal/admins/customer-success-invite.component'
import { FileUploaderPickerComponent } from './components/file/file-upload-picker.component'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { AccordionModule } from 'ngx-bootstrap/accordion'
import { DragulaModule } from 'ng2-dragula'
import { SignInSettingsModalComponent } from './components/admin-portal/signInSettings.modal'
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg'
import { NetworkPortalComponent } from './components/network/network.component'
import { NetworkDetailsComponent } from './components/network/details.component'
import { NgSelectModule } from '@ng-select/ng-select'
import { TraceDataComponent } from './components/trace-data/trace-data.component'
import { DataHubComponent } from './components/data-hub/data-hub.component'
import { DatahubDetailsComponent } from './components/data-hub/details.component'
import { DataSupplierViewAnswerComponent } from './components/data-suppliers/data-supplier-task/data-supplier-view-answer.component'
import { DoSubmissionsComponent } from './components/data-hub/doSubmissions.component'
import { MasterTablePortalComponent } from './components/data-hub/masterTablePortal.component'
import { MasterTableComponent } from './components/data-hub/masterTable.component'
import { DataEntriesPortalComponent } from './components/data-hub/dataEntriesPortal.component'
import { DataEntryComponent } from './components/data-hub/dataEntry.component'
import { FilesPortalComponent } from './components/data-hub/filesPortal.component'
import { AutomatedFeedsPortalComponent } from './components/data-hub/automatedFeedsPortal.component'
import { AutomatedFeedComponent } from './components/data-hub/automatedFeed.component'
import { DatahubHomeComponent } from './components/data-hub/datahubHome.component'
import { RequestDraftComponent } from './components/setup/drafts/drafts.component'
import { SMInboxComponent } from './components/setup/inbox/inbox.component'
import { RequestAnswerComponent } from './components/setup/request-answer/request-answer.component'
import { ChatBoxComponent } from './components/common/chat-box/chat-box.component'
import { InboxCardComponent } from './components/common/inbox-card.component'
import { RequestsComponent } from './components/setup/requests/requests.component'
import { DOInboxComponent } from './components/dataReporter/inbox/inbox.component'
import { DORequestsComponent } from './components/dataReporter/requests/requests.component'
import { TaskPreviewComponent } from './components/dataReporter/task-preview/task-preview.component'
import { AnswerComponent } from './components/dataReporter/answer/answer.component'
import { InsightsHubComponent } from './components/insights-hub/insights-hub.component'
import { FilterComponent } from './components/common/filter/filter.component'
import { InsightDetailComponent } from './components/insights-hub/insight.detail.component'
import { CategorySelectionComponent } from './components/common/category-selection/category-selection.component'
import { CategorySwitcherComponent } from './components/data-categories/category-switcher/category-switcher.component'
import { CalculationBuilderComponent } from './components/data-categories/calculation-builder/calculation-builder.component'
import { FormulaBuilderComponent } from './components/data-categories/calculation-builder/formula-builder/formula-builder.component'
import { ConditionsBuilderComponent } from './components/data-categories/calculation-builder/condition-builder/conditions-builder.component'
import { FormulaOperandDropdownComponent } from './components/data-categories/calculation-builder/formula-builder/formula-operand-dropdown/formula-operand-dropdown.component'
import { FormulaOperatorDropdownComponent } from './components/data-categories/calculation-builder/formula-builder/formula-operator-dropdown/formula-operator-dropdown.component'
import { AddDatapointDialogComponent } from './components/data-categories/add-datapoint-dialog/add-datapoint-dialog.component'
import { ConditionContainerComponent } from './components/data-categories/calculation-builder/condition-builder/condition-container/condition-container.component'
import { UnitSystemsComponent } from './components/unit-systems/unit-systems.component'
import { UnitSelectorComponent } from './components/unit-systems/unit-selector/unit-selector.component'
import { UnitEditorComponent } from './components/unit-systems/unit-editor/unit-editor.component'
import { AddDataCategoryComponent } from './components/common/add-data-category/add-data-category.component'
import { ConnectedActionsComponent } from './components/details/connected-actions.component'
import { AddDatapointComponent } from './components/common/add-data-point/add-data-point.component'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { NgxCaptureModule } from 'ngx-capture'
import { PdfViewerModule } from 'ng2-pdf-viewer'
import { NgFlowchartArrowComponent } from './components/projects/ng-flowchart/src/lib/ng-flowchart-arrow/ng-flowchart-arrow.component'
import { NgFlowchartStepComponent } from './components/projects/ng-flowchart/src/lib/ng-flowchart-step/ng-flowchart-step.component'
import { CustomStepComponent } from './components/projects/ng-flowchart/src/lib/custom-step/custom-step.component'
import { NgxModalDraggableDirective } from './components/projects/ng-flowchart/src/lib/directives/modalDraggable.directive'
import { NgFlowchartCanvasDirective } from './components/projects/ng-flowchart/src/lib/directives/ng-flowchart-canvas.directive'
import { NgFlowchartStepDirective } from './components/projects/ng-flowchart/src/lib/directives/ng-flowchart-step.directive'
import { FlowChartComponent } from './components/projects/ng-flowchart/src/lib/flowChart.component'
import { FormStepComponent } from './components/projects/ng-flowchart/src/lib/form-step/form-step.component'
import { DeleteNodeModalComponent } from './components/projects/ng-flowchart/src/lib/modal/deleteNode/deleteNode.modal'
import { EditNodeModalComponent } from './components/projects/ng-flowchart/src/lib/modal/editNode/editNode.modal'
import { NestedFlowComponent } from './components/projects/ng-flowchart/src/lib/nested-flow/nested-flow.component'
import { DataExtractorComponent } from './components/projects/data-grid-ui/data-extractor/dataExtractor.component'
import { ArrowDivDirective } from './components/projects/data-grid-ui/directive/arrow.directive'
import { DragSelectDirective } from './components/projects/data-grid-ui/directive/dragSelect.directive'
import { ResizeColumnDirective } from './components/projects/data-grid-ui/directive/resizeColumn.directive'
import { BooleanValidator } from './components/projects/data-grid-ui/directive/validator.directive'
import { ExcelTableComponent } from './components/projects/data-grid-ui/excel-table/excel-table.component'
import { MappingModalComponent } from './components/projects/data-grid-ui/modal/mappingModal/mappingModal.component'
import { ModifyColumnModalComponent } from './components/projects/data-grid-ui/modal/modifyColumnModal/modifyColumnModal.component'
import { NestedParentHandlerModalComponent } from './components/projects/data-grid-ui/modal/nestedParentHandlerModal/modal.component'
import { RowInRefTableHandlerModalComponent } from './components/projects/data-grid-ui/modal/rowInRefTableHandlerModal/modal.component'
import { DataGridTableComponent } from './components/projects/data-grid-ui/table.component'
import { SettingsComponent } from './components/settings/settings.component'
import { TabComponent } from './components/settings/tab/tab.component'
import { TabsComponent } from './components/settings/tabs/tabs.component'
import { CompanySettingsComponent } from './components/settings/company-settings/company-settings.component'
import { UserSettingsComponent } from './components/settings/user-settings/user-settings.component'
import { ViewTemplateComponent } from './components/settings/company-settings/view-template/view-template.component'
import { EmissionFactorDetailComponent } from './components/common/emission-factor-detail/emission-factor-detail.component'
import { EmissionFactorDetailsComponent } from './components/common/emission-factor-detail/ef-details.component'
import { EmissionFactorFilterComponent } from './components/common/emission-factor-detail/ef-filter.component'
import { EmissionFactorComponent } from './components/common/emission-factor-detail/emission-factor.component'
import { ConfirmCancelModalComponent } from './components/settings/confirmation-modal/confirm-cancel-modal.component'
import { RotatePasswordComponent } from './components/passwordrotation/rotatepassword.component'
import { ProjectsComponent } from './components/projects/projects.component';
import { CarbonlabSetupComponent } from './components/projects/setup/carbonlab-setup.component'
import { CarbonlabOverviewComponent } from './components/projects/overview/carbonlab-overview.component';
import { ProjectRequestComponent } from './components/projects/request/project-request.component';
import { EditQuestionnaireRequestComponent } from './components/data-suppliers/data-supplier-request/edit-questionnaire.component'
import { SubmissionDetailsRequestComponent } from './components/data-suppliers/data-supplier-request/submission-details-questionnaire.component'
import { CreateNewCSRDProjectComponent } from './components/projects/csrd/createNewCSRDProject/create-new-csrd-project.component'
import { CsrdProjectOverviewComponent } from './components/projects/csrd/csrdProjectOverview/csrd-project-overview.component';
import { DefineMaterialityComponent } from './components/projects/csrd/csrdProjectOverview/defineMateriality/define-materiality/define-materiality.component';
import { AssessDrComponent } from './components/projects/csrd/csrdProjectOverview/assessDr/assess-dr/assess-dr.component'
import {CsrdCarbonlabProjectSetupComponent} from './components/projects/csrd/csrdProjectOverview/_not_used_csrd-overview.component';
import { DrComponent } from './components/projects/csrd/csrdProjectOverview/dr/dr.component';
import { DrOverviewComponent } from './components/projects/csrd/csrdProjectOverview/dr/dr-overview/dr-overview.component';
import { DrDatapointsComponent } from './components/projects/csrd/csrdProjectOverview/dr/dr-datapoints/dr-datapoints.component';
import { DrCollectedDataComponent } from './components/projects/csrd/csrdProjectOverview/dr/dr-collected-data/dr-collected-data.component';
import { DrDatapointsViewComponent } from './components/projects/csrd/csrdProjectOverview/dr/dr-datapoints/dr-datapoints-view/dr-datapoints-view.component';
import { DrDatapointsEditComponent } from './components/projects/csrd/csrdProjectOverview/dr/dr-datapoints/dr-datapoints-edit/dr-datapoints-edit.component';
import { CsrdInfoTabComponent } from './components/details/csrd-info-tab/csrd-info-tab.component'
import { QuestionnairePredefinedOptionEditorComponent } from './components/data-suppliers/data-supplier-request/questionnaire-predefined-option-editor/questionnaire-predefined-option-editor.component'
import { QuestionaireCreatorFullPageComponent } from './components/data-suppliers/data-supplier-request/questionaire-creator-full-page.component'
import { QuestionaireCreatorMainFormComponent } from './components/data-suppliers/data-supplier-request/questionaire-creator-main-form.component'

const icons = {
  Maximize,
  Minimize,
  Menu,
  User,
  Mail,
  Minus,
  Users,
  UserPlus,
  RefreshCw,
  XCircle,
  LogOut,
  Edit,
  List,
  PlusSquare,
  Globe,
  Smile,
  DollarSign,
  ChevronDown,
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    HomeComponent,
    DashboardComponent,
    DashboardDetailComponent,
    LoginComponent,
    FilePortalComponent,
    FileViewerComponent,
    FilePickerComponent,
    FileUploaderPickerComponent,
    DataPortalComponent,
    LoginComponent,
    FooterComponent,
    OpenLayoutComponent,
    PreWorkspaceLayoutComponent,
    WorkspaceLayoutComponent,
    InvitecustomerComponent,
    CustomerEntryComponent,
    RegisterComponent,
    ResetRequestComponent,
    ForgotpasswordComponent,
    ResetforgotpasswordComponent,
    RotatePasswordComponent,
    EmissionPortalComponent,
    TaxonomyComponent,
    ConditionContainerComponent,
    ConditionsBuilderComponent,
    FormulaOperatorDropdownComponent,
    FormulaOperandDropdownComponent,
    FormulaBuilderComponent,
    CalculationBuilderComponent,
    CategorySwitcherComponent,
    AddDatapointDialogComponent,
    DataCategoriesComponent,
    OrganizationComponent,
    DataEntryInsertComponent,
    NotificationComponent,
    ReviseOrganizationModal,
    ReferenceWidgetsComponent,
    DataSupplierCompanyEntryComponent,
    NetworkPortalComponent,
    DataSupplierPersonnelEntryComponent,
    DataSupplierPersonnelPortalComponent,
    DataSupplierViewAnswerComponent,
    DetailsComponent,
    NetworkDetailsComponent,
    RequestDetailsComponent,
    TaskDetailsComponent,
    PartnerDetailsComponent,
    WorkspacesComponent,
    CacheLoadingProgressComponent,
    ManagerEntryComponent,
    AttachmentDetailsComponent,
    TaskAttachmentDetailsComponent,
    RequestAttachmentDetailsComponent,
    TaskAttachmentDetailsComponent,
    ChartSettingsComponent,
    NgxResizerDirective,
    AlertModal,
    QuestionaireCreatorFullPageComponent,
    QuestionaireCreatorMainFormComponent,
    RequestCreationLauncherComponent,
    QuestionnairePreviewComponent,
    InventoryComponent,
    UserSettingsComponent,
    VerifySharedDashboardComponent,
    EmailTableComponent,
    ClientsComponent,
    CustomerSuccessComponent,
    CustomerSuccessInviteComponent,
    SignInSettingsModalComponent,
    TraceDataComponent,
    DataHubComponent,
    DatahubDetailsComponent,
    DoSubmissionsComponent,
    MasterTablePortalComponent,
    MasterTableComponent,
    DataEntriesPortalComponent,
    DataEntryComponent,
    FilesPortalComponent,
    FilesPortalComponent,
    AutomatedFeedsPortalComponent,
    AutomatedFeedComponent,
    DatahubHomeComponent,
    RequestDraftComponent,
    SMInboxComponent,
    RequestAnswerComponent,
    ChatBoxComponent,
    InboxCardComponent,
    RequestsComponent,
    DOInboxComponent,
    DORequestsComponent,
    TaskPreviewComponent,
    AnswerComponent,
    UnitSystemsComponent,
    UnitSelectorComponent,
    UnitEditorComponent,
    InsightsHubComponent,
    FilterComponent,
    InsightDetailComponent,
    CategorySelectionComponent,
    AddDataCategoryComponent,
    AddDatapointComponent,
    ConnectedActionsComponent,
    TabComponent,
    TabsComponent,
    SettingsComponent,
    CompanySettingsComponent,
    ViewTemplateComponent,
    ConfirmCancelModalComponent,
    FlowChartComponent,
    CustomStepComponent,
    NestedFlowComponent,
    FormStepComponent,
    EditNodeModalComponent,
    DeleteNodeModalComponent,
    NgFlowchartCanvasDirective,
    NgFlowchartStepDirective,
    NgFlowchartStepComponent,
    NgFlowchartArrowComponent,
    NgxModalDraggableDirective,
    DataGridTableComponent,
    ModifyColumnModalComponent,
    ExcelTableComponent,
    BooleanValidator,
    ArrowDivDirective,
    ResizeColumnDirective,
    DragSelectDirective,
    DataExtractorComponent,
    MappingModalComponent,
    RowInRefTableHandlerModalComponent,
    NestedParentHandlerModalComponent,
    EmissionFactorDetailComponent,
    EmissionFactorDetailsComponent,
    EmissionFactorFilterComponent,
    EmissionFactorComponent,
    ProjectsComponent,
    CarbonlabSetupComponent,
    CarbonlabOverviewComponent,
    ProjectRequestComponent,
    EditQuestionnaireRequestComponent,
    SubmissionDetailsRequestComponent,
    CreateNewCSRDProjectComponent,
    CsrdProjectOverviewComponent,
    DefineMaterialityComponent,
    AssessDrComponent,
    CsrdCarbonlabProjectSetupComponent,
    DrComponent,
    DrOverviewComponent,
    DrDatapointsComponent,
    DrCollectedDataComponent,
    DrDatapointsViewComponent,
    DrDatapointsEditComponent,
    CsrdInfoTabComponent,
    QuestionnairePredefinedOptionEditorComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    NgxDocViewerModule,
    ImageCropperModule,
    NgxExtendedPdfViewerModule,
    ModalModule.forRoot(),
    FeatherModule.pick(icons),
    AlertModule.forRoot(),
    CollapseModule.forRoot(),
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    AppRoutingModule,
    HttpClientModule,
    FeatherModule.pick(icons),
    AngularResizeEventModule,
    InfiniteScrollModule,
    NgxCaptureModule,
    PdfViewerModule,
    DragulaModule.forRoot(),
    NgxIntlTelInputModule,
    ScrollingModule
  ],
  providers: [
    RouterFe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalInterceptorFe,
      multi: true,
    },
    BsModalService,
    BsModalRef,
    DatePipe,
  ],
  entryComponents: [NgFlowchartStepComponent, NgFlowchartArrowComponent],
  bootstrap: [AppComponent],
  exports: [FeatherModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

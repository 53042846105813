import { RowStatusFe } from "src/app/model/schema/RowStatusFe";
import { DirectEntryInfoFe } from "src/app/model/directEntry/DirectEntryInfoFe";
import { TableDataFe } from "src/app/model/schema/TableDataFe";
import { EntityFe } from "src/app/model/taxonomy/EntityFe";
import { RouterFe } from "src/app/route/RouterFe";
import { DateUtil } from "src/app/utils/DateUtil";
import * as XLSX from "xlsx";
import { DataGridRow } from "../projects/data-grid-ui/model/DataGridRow";
import { DataGridTableData } from "../projects/data-grid-ui/model/DataGridTableData";
import { DataGridTableMode } from "../projects/data-grid-ui/model/DataGridTableMode";
import { DataGridObserverInterface } from "../projects/data-grid-ui/service/DataGridObserverInterface";
import { DataGridServiceInterface } from "../projects/data-grid-ui/service/DataGridServiceInterface";
import { LanguageService } from "src/app/services/LanguageServiceFe";
import { DataGridColumnType } from "../projects/data-grid-ui/model/DataGridColumnType";

export class StageTableDataGridIntergationService implements DataGridServiceInterface {
  backendService: RouterFe;
  stageTableInfo: DirectEntryInfoFe;
  entity: EntityFe;
  currentPageData: TableDataFe;
  observer: DataGridObserverInterface | undefined;
  mode: string = DataGridTableMode.EDIT_TABLE_DATA;
  public modified: boolean;
  public isDataValid: boolean;
  hasTablePagination = true;
  downloading = false;
  backendTableData: TableDataFe

  constructor(stageTableInfo: DirectEntryInfoFe, entity: EntityFe, backendService: RouterFe, public languageService: LanguageService) {
    this.stageTableInfo = stageTableInfo;
    this.entity = entity;
    this.backendService = backendService;
  }

  registerObserver(observer: DataGridObserverInterface): void {
    this.observer = observer;
  }

  getMode(): string {
    return this.mode;
  }

  getMainTableData(): DataGridTableData {
    if (this.backendTableData) {
      this.currentPageData = TableDataFe.fromTransfer(this.backendTableData, this.entity, this.languageService.getDisplayActiveLanguage());
      this.currentPageData.key = this.stageTableInfo.taxonomyKey;
    }
    let dgtd = this.currentPageData.toDataGridTableData();
    return dgtd;
  }

  hasMainTablePagination(): boolean {
    return this.hasTablePagination;
  }

  async loadMainTablePage(pageSize: number = 10, pageNumber: number = 1): Promise<DataGridTableData> {
    this.stageTableInfo.loadInProgress = true;
    this.backendTableData = await this.backendService.readStageTable(this.stageTableInfo.id, pageSize, pageNumber);
    this.currentPageData = TableDataFe.fromTransfer(this.backendTableData, this.entity, this.languageService.getDisplayActiveLanguage());
    this.currentPageData.key = this.stageTableInfo.taxonomyKey;
    this.stageTableInfo.tableData = this.currentPageData;
    this.stageTableInfo.loadInProgress = false;
    let dgtd = this.currentPageData.toDataGridTableData();
    return dgtd;
  }

  async saveChages() {
    this.stageTableInfo.saveInProgress = true;
    let dgmt: DataGridTableData = this.observer.getModifiedTableData();
    let modifedTable = TableDataFe.fromDataGridTableData(dgmt);
    modifedTable.name = this.stageTableInfo.tableData.name;
    modifedTable.rows = modifedTable.rows.filter(
      (row) => row.status == RowStatusFe.MODIFIED || row.status == RowStatusFe.ADDED || row.status == RowStatusFe.DELETED
    );
    try {
      this.backendTableData = await this.backendService.updateStageTable(modifedTable, this.stageTableInfo.id);
      this.currentPageData = TableDataFe.fromTransfer(this.backendTableData, this.entity, this.languageService.getDisplayActiveLanguage());
      this.stageTableInfo.tableData = this.currentPageData;
      this.stageTableInfo.lastModified = new Date();
      this.stageTableInfo.lastModifiedString = DateUtil.toString3(this.stageTableInfo.lastModified);
      this.stageTableInfo.modified = true;
      await this.observer.renderNewDataTable();
      this.stageTableInfo.saveInProgress = false;
    } catch (err) {
      this.stageTableInfo.saveInProgress = false;
    }
  }

  markDataModified(modified: boolean, isDataValid: boolean): void {
    this.modified = modified;
    this.isDataValid = isDataValid;
  }

  hasDataModifiedElseWhere(): boolean {
    return false;
  }

  saveReferenceTable(table: DataGridTableData): void {}
  async exportSelectedRows(rows: DataGridRow[]): Promise<void> {}
  getReferenceTables(): DataGridTableData[] {
    return [];
  }

  getNoOfMinRows(): number {
    return 5;
  }

  async downloadDataEntry(exportType?: string) {
    this.stageTableInfo.saveInProgress = true;
    this.downloading = true;
    // set default csv format as previous logic, unless the method caller requests xlsx format
    const extension: string = (exportType && ['csv', 'xlsx'].includes(exportType)) ? exportType : 'csv';
    let fileName = `${this.stageTableInfo.taxonomyEntity.getLabel()}_${this.stageTableInfo.timeFromString}_${this.stageTableInfo.timeToString}.${extension}`;
    let backendTableData = await this.backendService.readWholeStageTable(this.stageTableInfo.id);
    let table = TableDataFe.fromTransfer(backendTableData, this.entity, this.languageService.getDisplayActiveLanguage());

    var data = [];
    data[0] = [];
    for (let col of table.dataSchema) {
      data[0].push(col.getColumnName());
      if (col.type == "FLOAT64" || col.type == "FLOAT" || col.type == "NUMERIC" || col.type == "INTEGER") {
        data[0].push(`${col.label} ${this.languageService.getLocale('locale_key.pages.data_category.unit')}`)
      }
    }

    table.rows.forEach((r, i) => {
      data.push([]);
      r.values.forEach((val, colIndex) => {
        const type = table.dataSchema[colIndex].type
        if (type == "FLOAT64" || type == "FLOAT" || type == "NUMERIC" || type == "INTEGER") {
     
          data[i+1].push(val?.quantity, val?.unit);
        } else {
          data[i+1].push(val)
        }
      })
    })
    
    const sheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet);
    await XLSX.writeFile(workbook, fileName);
    this.downloading = false;
    this.stageTableInfo.saveInProgress = false;
  }

  deepCopy() {
    let table = new TableDataFe();
    table.name = this.currentPageData.name;
    table.key = this.currentPageData.key;
    table.dataSchema = this.currentPageData.dataSchema;
    table.referenceSchema = this.currentPageData.referenceSchema;
    table.ordered = this.currentPageData.ordered;
    table.rowCount = this.currentPageData.rowCount;
    table.pageNumber = this.currentPageData.pageNumber;
    table.pageSize = this.currentPageData.pageSize;
    table.rows = this.currentPageData.rows.slice(0, this.currentPageData.pageSize);
    return table;
  }
}

<div class="d-flex flex-column h-100" *ngIf="activePage == 'CSRD_PROJECT_OVERVIEW'">
  <!-- empty toolbar + page-header and 3dots menu columns-->
  <div class="d-flex flex-row">
    <!-- empty-->
    <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()"></div>
    <!-- header + 3 dots-->
    <div class="flex-fill">
      <div class="d-flex flex-row justify-content-between">
        <nav>
          <ul class="nav">
            <li class="navbar-item">
              <a>
                <h3 class="mt-2">{{ slocale("PROJECTS") }}</h3>
              </a>
            </li>
          </ul>
        </nav>
        <!-- dropdown menu instead of toolbar for smaller pages-->
        <div class="btn-group" dropdown placement="bottom left" *ngIf="screenSize.isSmallerSize()">
          <button id="button-alignment" dropdownToggle type="button" class="btn page-toolbar-dropdown-toggle ml-4 mt-" aria-controls="dropdown-alignment">
            <i class="la la-ellipsis-h fs-2" style="rotate: 90deg"></i>
          </button>
          <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right page-toolbar-dropdown-menu" role="menu" aria-labelledby="button-alignment">
            <ng-container *ngFor="let toolbarGroup of pageToolbar; let groupIndex = index">
              <div>
                <ng-container *ngFor="let toolbarButton of toolbarGroup">
                  <li role="menuitem">
                    <button type="button" class="btn" (click)="handleToolbarAction(toolbarButton.actionName)" *ngIf="toolbarButton.visible()">
                      <i [class]="toolbarButton.icon"></i>
                      <span>{{ toolbarButton.longLabel }}</span>
                    </button>
                  </li>
                </ng-container>
                <!-- <li class="divider dropdown-divider" *ngIf="groupIndex + 1 < pageToolbar.length"></li> -->
              </div>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- toolbar + content columns -->
  <div class="flex-fill">
    <div class="d-flex flex-row h-100">
      <!-- toolbar column-->
      <div class="page-toolbar-wrapper" *ngIf="!screenSize.isSmallerSize()">
        <ng-container *ngFor="let toolbarGroup of pageToolbar">
          <div class="page-toolbar-button-group">
            <ng-container *ngFor="let toolbarButton of toolbarGroup">
              <button *ngIf="toolbarButton.visible()" type="button" class="btn page-toolbar-button" (click)="handleToolbarAction(toolbarButton.actionName)" data-bs-toggle="tooltip" data-placement="top" [title]="toolbarButton.tooltip"
                show-delay="3000">
                <i [class]="toolbarButton.icon" class="fs-4"></i>
                <span>{{ toolbarButton.shortLabel }}</span>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <!-- content column-->
      <div class="flex-fill">
        <div class="d-flex h-100 flex-column">
          <div class="card me-2" style="height: 84vh; max-width: 100vw !important" [ngStyle]="!cardHeader ? { border: 'none' } : {}">
            <div class="pb-0" *ngIf="cardHeader">
              <ng-container *ngIf="cardHeader || !cardHeader">
                <div class="card-header bg-white">
                  <div class="d-flex flex-row justify-content-between">
                    <h5 class="card-title mb-0 pb-0">{{ slocale("E3-4 – Water consumption") }}</h5>
                  </div>
                </div> v bv
              </ng-container>
              <div class=" p-sm-0 m-sm-0" *ngIf="!pullDataFromPlatform">
                <ng-container *ngIf="screenSize.isLargeSize() || screenSize.isXLargeSize()">
                  <div class="row text-center pb-3 pt-4">
                    <div class="mt-0 pt-0 mx-0 ps-1 pe-0" [ngClass]="projCSRD || E34WaterConsumption ? 'col-4' : 'col-3'">
                      <div class="d-flex flex-row mb-2 mt-0 pt-0 text-start mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
                        <div class="circle1 active-circle1 mx-0 px-0">1</div>
                        <ng-container *ngIf="carbonLab">
                          <h6 class="my-auto ms-1">{{ slocale("Learn about CarbonLab") }}</h6>
                        </ng-container>
                        <ng-container *ngIf="projCSRD">
                          <h6 class="my-auto ms-1">{{ slocale("Learn about CSRD module") }}</h6>
                        </ng-container>
                        <ng-container *ngIf="E34WaterConsumption">
                          <h6 class="my-auto ms-1">{{ slocale("Select data for reporting") }}</h6>
                        </ng-container>
                        <span class="ms-1 mt-1" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
                      </div>
                    </div>
                    <div class="text-start mx-0 px-0" [ngClass]="projCSRD || E34WaterConsumption ? 'col-4' : 'col-3'">
                      <div class="d-flex flex-row mb-1 mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
                        <div class="circle1 active mx-0 px-0">2</div>
                        <ng-container *ngIf="carbonLab">
                          <h6 class="my-auto ms-1">{{ slocale("Pick project name") }}</h6>
                        </ng-container>
                        <ng-container *ngIf="projCSRD">
                          <h6 class="my-auto ms-1">{{ slocale("Define project") }}</h6>
                        </ng-container>
                        <ng-container *ngIf="E34WaterConsumption">
                          <h6 class="my-auto ms-1">{{ slocale("Assign data collection") }}</h6>
                        </ng-container>
                        <span class="ms-1 mt-1" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
                      </div>
                    </div>
                    <div class="text-start mx-0 px-0" [ngClass]="projCSRD || E34WaterConsumption ? 'col-4' : 'col-3'">
                      <div class="d-flex flex-row mb-2 mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
                        <div class="circle1 active mx-0 px-0">3</div>
                        <ng-container *ngIf="carbonLab">
                          <h6 class="my-auto ms-1">{{ slocale("Select emissions categories") }}</h6>
                        </ng-container>
                        <ng-container *ngIf="projCSRD">
                          <h6 class="my-auto ms-1">{{ slocale("Add company info") }}</h6>
                        </ng-container>
                        <ng-container *ngIf="E34WaterConsumption">
                          <h6 class="my-auto ms-1">{{ slocale("Define submission details") }}</h6>
                        </ng-container>
                        <span class="ms-1 mt-1" style="font-size: 1.5rem" *ngIf="carbonLab"><i class="las la-angle-right"></i></span>
                      </div>
                    </div>
                    <ng-container *ngIf="carbonLab">
                      <div class="col-3 text-start mx-0 px-0">
                        <div class="d-flex flex-row mb-2 mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
                          <div class="circle1 active mx-0 px-0">4</div>
                          <h6 class="my-auto ms-1 mt-1 mb-0 pb-0">{{ slocale("Define data storage") }}</h6>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="screenSize.isMediumSize() || screenSize.isSmallerSize() || screenSize.isXSmallSize()">
                  <div class="d-flex flex-row justify-content-center mb-1 pt-3">
                    <div class="d-block text-center">
                      <div class="col stepper text-center" *ngFor="let step of projCSRD ? stepsCSRD : carbonLab ? stepsCarbonLab : stepsWaterConsumption; let i = index" [class.active-stepper]="currentActive === i + 1 || step.completed">
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-center mb-1">
                    <h6 class="text-center m-0">
                      {{ (projCSRD ? stepsCSRD : carbonLab ? stepsCarbonLab : stepsWaterConsumption)[currentActive - 1].title }} ({{ currentActive }}/{{ (projCSRD ? stepsCSRD : carbonLab ? stepsCarbonLab : stepsWaterConsumption).length }})
                    </h6>
                  </div>
                </ng-container>
              </div>
            </div>
            <hr class="m-0 pt-0 px-0" [ngClass]="!cardHeader ? 'hide' : 'show'" />
            <!-- Pull existing data from platform -->
            <div class=" p-sm-0 m-sm-0 mx-0 px-0" *ngIf="pullDataFromPlatform">
              <ng-container *ngIf="screenSize.isLargeSize() || screenSize.isXLargeSize()">
                <div class="d-flex fled-row justify-content-start align-items-center pt-3 ps-2">
                  <h4 class="ps-2">{{ slocale("Pull existing data from platform") }}</h4>
                </div>
                <hr class="m-0 p-0" />
                <div class="row text-center pb-3 pt-4">
                  <div class="col-3 mt-0 pt-0 mx-0 ps-1 pe-0">
                    <div class="d-flex flex-row mb-2 mt-0 pt-0 text-start mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
                      <div class="circle1 active-circle1 mx-0 px-0">1</div>
                      <h6 class="my-auto ms-1">{{ slocale("Select calculation method") }}</h6>
                      <span class="ms-1 mt-1" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
                    </div>
                  </div>
                  <div class="col-3 text-start mx-0 px-0">
                    <div class="d-flex flex-row mb-1 mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
                      <div class="circle1 active mx-0 px-0">2</div>
                      <h6 class="my-auto ms-1">{{ slocale("Select data category") }}</h6>
                      <span class="ms-1 mt-1" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
                    </div>
                  </div>
                  <div class="col-3 text-start mx-0 px-0">
                    <div class="d-flex flex-row mb-2 mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
                      <div class="circle1 active mx-0 px-0">3</div>
                      <h6 class="my-auto ms-1">{{ slocale("Filter and select data") }}</h6>
                      <span class="ms-1 mt-1" style="font-size: 1.5rem"><i class="las la-angle-right"></i></span>
                    </div>
                  </div>
                  <div class="col-3 text-start mx-0 px-0">
                    <div class="d-flex flex-row mb-2 mx-0 px-0 d-flex flex-row justify-content-center align-items-center">
                      <div class="circle1 active mx-0 px-0">4</div>
                      <h6 class="my-auto ms-1 mt-1 mb-0 pb-0">{{ slocale("Add missing data") }}</h6>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="screenSize.isMediumSize() || screenSize.isSmallerSize() || screenSize.isXSmallSize()">
                <div class="d-flex flex-row justify-content-center mb-1 pt-3">
                  <div class="d-block text-center">
                    <div class="col stepper text-center" *ngFor="let step of stepsPullData; let i = index" [class.active-stepper]="currentActive === i + 1 || step.completed"></div>
                  </div>
                </div>
                <div class="d-flex flex-row justify-content-center mb-1">
                  <h6 class="text-center m-0">{{ stepsPullData[currentActive - 1].title }} ({{ currentActive }}/{{ steps.length }})</h6>
                </div>
              </ng-container>
            </div>
            <hr class="mt-0 pt-0 mx-0 px-0" [ngClass]="!pullDataFromPlatform ? 'hide' : 'show'" />
            <div class="card-body mt-0 pt-0 px-0" style="overflow-y: scroll !important; border-bottom: none; max-width: 100vw !important" *ngIf="pullDataFromPlatform && !bizTravel">
              <!--NEW PROJECT PAGE-->
              <div *ngIf="activeFormNo == 1" class="px-3 h-100">
                <div class="" style="height: 90% !important">
                  <div class=" p-sm-0 m-sm-0 vh-auto">
                    <div class="row p-3 p-sm-1 text-left">
                      <h5 class="text-justify pt-3">{{ slocale("Select which type of emissions you want to calculate (select at least one option).") }}</h5>
                      <div class="d-flex flex-row justify-content-start align-items-center">
                        <input type="radio" id="yes" name="emissionFactors" value="yes" checked class="d-inline me-2" />
                        <label for="yes" class="d-inline">{{ slocale("Location-based emissions") }}</label>
                        <input type="radio" id="no" name="emissionFactors" value="no" class="d-inline ms-4 me-2" />
                        <label for="no" class="d-inline">{{ slocale("Market-based emissions") }}</label>
                      </div>
                    </div>
                    <div class="row p-3 p-sm-1">
                      <h5>{{ slocale("Before you can get started you need to select the calculation method for the data you want to pull.") }}</h5>
                    </div>
                    <div class="d-flex flex-row justify-content-evenly align-items-center w-100 flex-wrap">
                      <div class="card platform-card mb-3 p-0 h-100" style="border-top: 10px solid var(--purple); border-radius: 0">
                        <div class="card-body px-0">
                          <div class="d-flex flex-column justify-content-center align-items-center">
                            <div class="p-4 pt-5">
                              <input type="radio" id="newProject" name="projectType" value="newProject" checked class="d-inline me-2" />
                              <label for="newProject" class="d-inline">
                                <h5 class="d-inline">{{ slocale("Weight data") }}</h5>
                              </label>
                              <h6 class="pt-3">{{ slocale("Data required for emission calculation") }}</h6>
                              <ul class="">
                                <li>{{ slocale("Waste material") }}</li>
                                <li>{{ slocale("Waste volume in weight") }}</li>
                                <li>{{ slocale("Waste treatment method") }}</li>
                                <li>{{ slocale("Emission Factor") }}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer bg-comp-grey text-center pt-2">
                          <h5 class="mb-0 pb-0 text-muted">{{ slocale("ACTIVITY-BASED") }}</h5>
                        </div>
                      </div>
                      <div class="card platform-card mb-3 p-0 h-100" style="border-top: 10px solid var(--complementary-color1); border-radius: 0">
                        <div class="card-body px-0">
                          <div class="d-flex flex-column justify-content-center align-items-center">
                            <div class="p-4 pt-5">
                              <input type="radio" id="newProject" name="projectType" value="newProject" checked class="d-inline me-2" />
                              <label for="newProject" class="d-inline">
                                <h5 class="d-inline">{{ slocale("Unit data") }} <span class="badge bg-comp-grey w-auto text-wrap p-2 success-text"> Best practice</span></h5>
                              </label>
                              <h6 class="pt-3">{{ slocale("Data required for emission calculation") }}</h6>
                              <ul class="">
                                <li>{{ slocale("Product group") }}</li>
                                <li>{{ slocale("Waste volume (in number of units)") }}</li>
                                <li>{{ slocale("Average weight per unit") }}</li>
                                <li>{{ slocale("Waste treatment method") }}</li>
                                <li>{{ slocale("Emission Factor") }}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer bg-comp-grey text-center pt-2">
                          <h5 class="mb-0 pb-0 text-muted">{{ slocale("ACTIVITY-BASED") }}</h5>
                        </div>
                      </div>
                      <div class="card platform-card mb-3 p-0 h-100" style="border-top: 10px solid var(--complementary-color1); border-radius: 0">
                        <div class="card-body px-0">
                          <div class="d-flex flex-column justify-content-center align-items-center">
                            <div class="p-4 pt-5">
                              <input type="radio" id="newProject" name="projectType" value="newProject" checked class="d-inline me-2" />
                              <label for="newProject" class="d-inline">
                                <h5 class="d-inline">{{ slocale("Unit data") }}</h5>
                              </label>
                              <h6 class="pt-3">{{ slocale("Data required for emission calculation") }}</h6>
                              <ul class="">
                                <li>{{ slocale("Product group") }}</li>
                                <li>{{ slocale("Waste volume (in number of units)") }}</li>
                                <li>{{ slocale("Average weight per unit") }}</li>
                                <li>{{ slocale("Waste treatment method") }}</li>
                                <li>{{ slocale("Emission Factor") }}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer bg-comp-grey text-center pt-2">
                          <h5 class="mb-0 pb-0 text-muted">{{ slocale("ACTIVITY-BASED") }}</h5>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-row justify-content-evenly align-items-center w-100 flex-wrap">
                      <div class="card platform-card mb-3 p-0 h-100" style="border-top: 10px solid var(--complementary-color1); border-radius: 0">
                        <div class="card-body px-0">
                          <div class="d-flex flex-column justify-content-center align-items-center">
                            <div class="p-4 pt-5">
                              <input type="radio" id="newProject" name="projectType" value="newProject" checked class="d-inline me-2" />
                              <label for="newProject" class="d-inline">
                                <h5 class="d-inline">{{ slocale("Unit data") }}</h5>
                              </label>
                              <h6 class="pt-3">{{ slocale("Data required for emission calculation") }}</h6>
                              <ul class="">
                                <li>{{ slocale("Product group") }}</li>
                                <li>{{ slocale("Waste volume (in number of units)") }}</li>
                                <li>{{ slocale("Average weight per unit") }}</li>
                                <li>{{ slocale("Waste treatment method") }}</li>
                                <li>{{ slocale("Emission Factor") }}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer bg-comp-grey text-center pt-2">
                          <h5 class="mb-0 pb-0 text-muted">{{ slocale("ACTIVITY-BASED") }}</h5>
                        </div>
                      </div>
                      <div class="card platform-card mb-3 p-0 h-100" style="border-top: 10px solid var(--complementary-color1); border-radius: 0">
                        <div class="card-body px-0">
                          <div class="d-flex flex-column justify-content-center align-items-center">
                            <div class="p-4 pt-5">
                              <input type="radio" id="newProject" name="projectType" value="newProject" checked class="d-inline me-2" />
                              <label for="newProject" class="d-inline">
                                <h5 class="d-inline">{{ slocale("Unit data") }}</h5>
                              </label>
                              <h6 class="pt-3">{{ slocale("Data required for emission calculation") }}</h6>
                              <ul class="">
                                <li>{{ slocale("Product group") }}</li>
                                <li>{{ slocale("Waste volume (in number of units)") }}</li>
                                <li>{{ slocale("Average weight per unit") }}</li>
                                <li>{{ slocale("Waste treatment method") }}</li>
                                <li>{{ slocale("Emission Factor") }}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer bg-comp-grey text-center pt-2">
                          <h5 class="mb-0 pb-0 text-muted">{{ slocale("ACTIVITY-BASED") }}</h5>
                        </div>
                      </div>
                      <div class="card platform-card mb-3 p-0 h-100 disabled" style="border-top: 10px solid var(--complementary-color1); border-radius: 0">
                        <div class="card-body px-0">
                          <div class="d-flex flex-column justify-content-center align-items-center">
                            <div class="p-4 pt-5">
                              <input type="radio" id="newProject" name="projectType" value="newProject" checked class="d-inline me-2" />
                              <label for="newProject" class="d-inline">
                                <h5 class="d-inline">{{ slocale("Spend data") }}</h5>
                              </label>
                              <h6 class="pt-3">{{ slocale("This method calculates emissions by assessing the monetary value of goods or services purchased and applying emission factors to those expenditures.") }}</h6>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer bg-comp-grey text-center pt-2">
                          <h5 class="mb-0 pb-0 text-muted" style="color: var(--orange) !important">{{ slocale("COMING SOON") }}</h5>
                        </div>
                      </div>
                    </div>
                    <div class="row px-3 text-center">
                      <p class="text-justify pt-3">{{ slocale("Questions about which method to choose? Contact your customer support representative for assistance.") }}</p>
                    </div>
                    <hr />
                    <div class="row px-3 text-start">
                      <div class="d-flex flex-row flex-wrap pt-3 pb-2">
                        <h5 class="text-justify">{{ slocale("Should data reporters submit emission factors?") }}</h5>
                        <i class="la la-info-circle info-popover-arrow d-inline size-15 ms-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                        <div class="dropdown-menu arrow p-2 info-popover">
                          <p>{{ slocale("Project name should be unique.") }}</p>
                        </div>
                      </div>
                      <div class="d-flex flex-row justify-content-start align-items-center pb-3">
                        <button class="btn tertiary-button me-2 custom-button">{{ slocale("Yes") }}</button>
                        <button class="btn btn-pink custom-button">{{ slocale("No") }}</button>
                      </div>
                    </div>
                    <hr />
                    <div class="row px-3 text-start">
                      <div class="d-flex flex-row flex-wrap pt-3 pb-2">
                        <h5 class="text-justify">{{ slocale("Should data reporters submit emission factors?") }}</h5>
                        <i class="la la-info-circle info-popover-arrow d-inline size-15 ms-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                        <div class="dropdown-menu arrow p-2 info-popover">
                          <p>{{ slocale("Project name should be unique.") }}</p>
                        </div>
                      </div>
                      <div class="d-flex flex-row justify-content-start align-items-center">
                        <button class="btn btn-pink custom-button me-2">{{ slocale("Yes") }}</button>
                        <button class="btn tertiary-button custom-button">{{ slocale("No") }}</button>
                      </div>
                      <div class="d-flex flex-column px-3 py-2">
                        <small>This is the information the data reporters will receive for the required proof documentation. Edit the description so that it matches your requirements.</small>
                        <textarea class="form-control mt-2 w-75" id="exampleFormControlTextarea1" rows="3">
                            Proof documentation may include supporting evidence such as invoices, utility bills, or operational records. This documentation validates and verifies the reported data, ensuring accuracy and reliability in the carbon calculation process.
                          </textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--NAME THE PROJECT PAGE-->
              <div *ngIf="activeFormNo == 2 && !bizTravel" class="px-3">
                <div class="d-flex flex-row flex-wrap">
                  <h5>{{ slocale("Which data category contains data for Waste generated in operations?") }}</h5>
                  <i class="la la-info-circle info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                  <div class="dropdown-menu arrow p-2 info-popover">
                    <p>{{ slocale("Project name should be unique.") }}</p>
                  </div>
                </div>
                <div class="form-group">
                  <select class="form-select" aria-label="Default select example">
                    <option selected>{{ slocale("Select data category") }}</option>
                    <option value="1">{{ slocale("Option 1") }}</option>
                    <option value="2">{{ slocale("Option 2") }}</option>
                    <option value="3">{{ slocale("Option 3") }}</option>
                  </select>
                </div>
              </div>
              <!--SELECT EMISSIONS PAGE-->
              <div *ngIf="activeFormNo == 3 && !bizTravel" style="overflow-x: hidden; height: 100% !important">
                <div class=" p-sm-0 m-sm-0">
                  <div class="row">
                    <p class="ps-4">
                      {{
                      slocale(
                      "Filter the table to only show the data you want to use to calculate the emissions for Waste generated in operations. Next, select which column contains the relevant data for each of the required data points listed below. If any data is missing you can add it in the next step."
                      )
                      }}
                    </p>
                  </div>
                  <div class="bg-light-gray pb-2 pt-3 px-3 mx-2 d-flex flex-row justify-content-between align-items-center flex-wrap border">
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale("Waste material") }} <i class="la la-info-circle info-popover-arrow bold" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale("Waste material .....") }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale("Waste treatment method") }} <i class="la la-info-circle info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale("Waste treament.....") }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale("Waste volume in weight") }} <i class="la la-info-circle info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale("Waste vol in weight.") }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5 d-none" style="position: relative; top: 2px"></i>
                      {{ slocale("Emission factor") }} <i class="la la-info-circle info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale("Emission factor.") }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale("Time") }} <i class="la la-info-circle info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale("Time.") }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale("Organizational unit") }} <i class="la la-info-circle info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale("Org unit....") }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale("Country") }} <i class="la la-info-circle info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale("Country....") }}</p>
                      </div>
                    </h6>
                  </div>
                  <div class="w-100 h-100 p-2">
                    <img src="assets/img/table.jpg" alt="CarbonLab-Icons-01" class="img-fluid w-100 h-100" />
                  </div>
                </div>
              </div>
              <!--EMISSIONS DASHBOARD PAGE-->
              <div *ngIf="activeFormNo == 4 && !bizTravel" class="px-2 bg-light h-100" style="overflow-x: hidden">
                <div class=" p-sm-0 m-sm-0">
                  <div class="row">
                    <p class="ps-4">
                      {{ slocale("The necessary data points for calculating the GHG emissions have been added. Fill in any missing data to continue with the calculation.") }}
                    </p>
                  </div>
                  <div class="bg-light-gray pb-2 pt-3 px-3 mx-2 d-flex flex-row justify-content-between align-items-center flex-wrap border">
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale("Waste material") }} <i class="la la-info-circle info-popover-arrow bold" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale("Waste material .....") }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale("Waste treatment method") }} <i class="la la-info-circle info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale("Waste treament.....") }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale("Waste volume in weight") }} <i class="la la-info-circle info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale("Waste vol in weight.") }}</p>
                      </div>
                    </h6>
                    <h6>
                      <span class="bg-pink w-auto rounded px-2 text-white">{{ slocale("New") }}</span>
                      {{ slocale("Emission factor") }} <i class="la la-info-circle info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale("Emission factor.") }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale("Time") }} <i class="la la-info-circle info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale("Time.") }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale("Organizational unit") }} <i class="la la-info-circle info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale("Org unit....") }}</p>
                      </div>
                    </h6>
                    <h6>
                      <i class="la la-check-circle-o grad-green fs-5" style="position: relative; top: 2px"></i>
                      {{ slocale("Country") }} <i class="la la-info-circle info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale("Country....") }}</p>
                      </div>
                    </h6>
                  </div>
                  <div class="w-100 h-100 p-2">
                    <img src="assets/img/table.jpg" alt="CarbonLab-Icons-01" class="img-fluid w-100 h-100" />
                  </div>
                </div>
              </div>
            </div>
            <ng-container *ngIf="materiality">
              <div class="card-body mt-0 pt-0 px-0" style="overflow-y: scroll !important; border-bottom: none; max-width: 100vw !important">
                <div class="card-header bg-white">
                  <h5 class="mb-0 pb-0 py-2">{{ slocale("Materiality of ESRS E3 Water and marine resources") }}</h5>
                </div>
                <div class="p-3 p-sm-1">
                  <h6 class="text-justify bold">{{ slocale('Is the topic "E3 Water and marine resources" fully/partially or not material to your organization based on the results of your Double Materiality Assessment?') }}</h6>
                  <p class="text-justify">
                    {{ slocale("A sustainability matter is “material” when it meets the criteria defined for impact materiality (see section 3.4 of the Standard) or financial materiality (see section 3.5 of the Standard), or both (§28).")
                    }}
                  </p>
                  <p class="text-justify py-2">
                    <span class="me-2 border rounded px-2 bg-pink text-white">{{ slocale("Topic is fully / partially material") }}</span><span class="me-2 border rounded px-2">{{ slocale("Topic is not material") }}</span>
                  </p>
                  <p class="bold mb-0 pb-0">{{ slocale("Select the sub- and sub-sub-topics that are material to your organization.") }}</p>
                  <div class="d-flex flex-row flex-wrap justify-content-between align-items-start">
                    <div class="d-flex flex-column justify-content-start w-auto flex-fill me-1 mt-1">
                      <div class="p-3 p-sm-1 d-flex justify-content-between align-items-center bg-light-gray">
                        <div class="d-flex flex-row justify-content-between align-items-center">
                          <h6 class="bold mb-0 pb-0">{{ slocale("Sub-topics") }}</h6>
                        </div>
                        <div class="">
                          <a class="underline mx-1">{{ slocale("Select all") }}</a>
                          <a class="underline mx-1">{{ slocale("Deselect all") }}</a>
                        </div>
                      </div>
                      <div class="p-3 p-sm-1 d-flex flex-row align-items-center thick-bt-selected w-100 border mt-1">
                        <div class="d-flex flex-row justify-content-between align-items-center w-100">
                          <span>
                            <h6 class="mb-0 pb-0">{{ slocale("Water") }}</h6>
                            <i class="la la-info-circle ms-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover">
                              <p>{{ slocale("Text to be added!") }}</p>
                            </div>
                          </span>
                          <i class="las la-check bold pink"></i>
                        </div>
                      </div>
                      <div class="p-3 p-sm-1 d-flex flex-row align-items-center thick-bt-gray flex-fill border mt-1">
                        <div class="d-flex flex-row justify-content-between align-items-center w-100">
                          <span>
                            <h6 class="mb-0 pb-0">{{ slocale("Water") }}</h6>
                            <i class="la la-info-circle ms-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover">
                              <p>{{ slocale("Text to be added!") }}</p>
                            </div>
                          </span>
                          <i class="las la-check bold pink d-none"></i>
                        </div>
                      </div>
                      <button class="btn tertiary-button w-100 mt-2" (click)="openModal(createSubTopicModal, 'modal-md')"><i class="la la-plus"></i> {{ slocale("Create a sub-topic") }}</button>
                    </div>
                    <div class="d-flex flex-column justify-content-start w-auto flex-fill me-1 mt-1">
                      <div class="p-3 p-sm-1 d-flex justify-content-between align-items-center bg-light-gray">
                        <div class="">
                          <h6 class="bold mb-0 pb-0">{{ slocale("Sub-sub-topics") }}</h6>
                        </div>
                        <div class="">
                          <a class="underline mx-1">{{ slocale("Select all") }}</a>
                          <a class="underline mx-1">{{ slocale("Deselect all") }}</a>
                        </div>
                      </div>
                      <div class="p-3 p-sm-1 d-flex flex-row align-items-center thick-bt-gray w-100 border mt-1">
                        <div class="d-flex flex-row justify-content-between align-items-center w-100">
                          <span>
                            <h6 class="mb-0 pb-0">{{ slocale("Water") }}</h6>
                            <i class="la la-info-circle ms-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover">
                              <p>{{ slocale("Text to be added!") }}</p>
                            </div>
                          </span>
                          <i class="las la-check bold pink d-none"></i>
                        </div>
                      </div>
                      <div class="p-3 p-sm-1 d-flex flex-row align-items-center thick-bt-gray w-100 border mt-1">
                        <div class="d-flex flex-row justify-content-between align-items-center w-100">
                          <span>
                            <h6 class="mb-0 pb-0">{{ slocale("Water") }}</h6>
                            <i class="la la-info-circle ms-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover">
                              <p>{{ slocale("Text to be added!") }}</p>
                            </div>
                          </span>
                          <i class="las la-check bold pink d-none"></i>
                        </div>
                      </div>
                      <div class="p-3 p-sm-1 d-flex flex-row align-items-center thick-bt-selected w-100 border mt-1">
                        <div class="d-flex flex-row justify-content-between align-items-center w-100">
                          <span>
                            <h6 class="mb-0 pb-0">{{ slocale("Water") }}</h6>
                            <i class="la la-info-circle ms-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover">
                              <p>{{ slocale("Text to be added!") }}</p>
                            </div>
                          </span>
                          <i class="las la-check bold pink"></i>
                        </div>
                      </div>
                      <div class="p-3 p-sm-1 d-flex flex-row align-items-center thick-bt-gray w-100 border mt-1">
                        <div class="d-flex flex-row justify-content-between align-items-center w-100">
                          <span>
                            <h6 class="mb-0 pb-0">{{ slocale("Water") }}</h6>
                            <i class="la la-info-circle ms-1 info-popover-arrow" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div class="dropdown-menu arrow p-2 info-popover">
                              <p>{{ slocale("Text to be added!") }}</p>
                            </div>
                          </span>
                          <i class="las la-check bold pink d-none"></i>
                        </div>
                      </div>
                      <button class="btn tertiary-button w-100 mt-2" (click)="openModal(createSubTopicModal, 'modal-md')"><i class="la la-plus"></i> {{ slocale("Cerate a sub-topic") }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div id="card-footer" class="card-footer float-end mt-auto text-end quest-footer py-2 px-1 mx-0" style="max-width: 100vw; background-color: transparent" *ngIf="cardFooter">
              <ng-container *ngIf="!loadingInProgress">
                <button type="button" class="btn m-1 tertiary-button custom-button" (click)="saveDraftModal(true)">{{ locale("locale_key.general.buttons.cancel") }}</button>
                <button type="button" class="btn m-1 secondary-button custom-button" [hidden]="activeFormNo == 1" (click)="saveDraftModal(false)">{{ locale("locale_key.button.save_as_draft") }}</button>
                <button type="button" class="btn m-1 tertiary-button custom-button" *ngIf="activeFormNo != 1" (click)="prev()">{{ locale("locale_key.general.buttons.previous") }}</button>
                <button type="button" class="btn m-1 primary-button custom-button mt-sm-1" *ngIf="activeFormNo != 4" (click)="next()">{{ locale("locale_key.general.buttons.next") }}</button>
                <button type="button" class="btn m-1 primary-button custom-button mt-sm-1" (click)="pullDataFromPlatform ? openModal(emissionsPortalModal, 'modal-lg') : closeModal()" *ngIf="activeFormNo == 4">
                  {{ activeFormNo == 4 && pullDataFromPlatform ? slocale("Next") : slocale("Finish") }}
                </button>

                <button type="button" class="btn m-1 primary-button custom-button mt-sm-1" *ngIf="activeFormNo == 1" (click)="openModal(updateCategoriesModal, 'modal-lg')">{{ slocale("Test") }}</button>
                <button type="button" class="btn m-1 primary-button custom-button mt-sm-1" (click)="openModal(confirmNotMaterialModal, 'modal-lg')">{{ slocale("Confirm") }}</button>
                <!-- <button type="button" class="btn mx-1 primary-button custom-button" [hidden]="activeFormNo > 1" >{{ locale("locale_key.pages.data_request.create_wizard.button.send_request") }}</button> -->
              </ng-container>
            </div>

            <!--BUSINESS TRAVEL EMISSIONS PAGE-->
            <div class="card-body px-0 mx-2 m-auto pt-3" *ngIf="bizTravel" style="overflow-x: hidden">
              <div class="d-flex flex-row justify-content-between align-content-start px-3 w-auto flex-wrap" style="max-width: 95% !important; max-height: 200px">
                <div class="d-flex fled-row justify-content-start align-items-center">
                  <i class="la la-plane size-15 me-2 bold" style="transform: rotate(90deg)"></i>
                  <h4 class="mb-0 pb-0">{{ slocale("Business travel") }}</h4>
                </div>
                <div class="d-flex flex-row justify-content-end align-items-center">
                  <span style="color: lightgray">{{ slocale("ACTIVITY-BASED") }}</span>
                </div>
              </div>
              <hr />
              <div class="d-flex flex-row justify-content-evenly align-items-center bg-white mt-1 p-3 p-sm-1 flex-wrap" style="max-width: 100vw !important">
                <div class="card px-5 py-4 mt-2 bizTravel-card">
                  <div class="card-body">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                      <img _ngcontent-qgx-c224="" src="/assets/img/logos/Logo-Sustainlab.png" class="brand-logo img-responsive img-fluid pb-1" style="width: 2.5rem" />
                      <small class="card-title col pt-1">{{ slocale("Pull existing data from platform") }}</small>
                      <button class="btn primary-button mx-auto custom-button" (click)="pullDataFromPlatform = true; bizTravel = false">{{ slocale("Start") }}</button>
                    </div>
                  </div>
                </div>
                <div class="card px-5 py-4 mt-2 bizTravel-card flex-wrap">
                  <div class="card-body">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                      <i class="la la-envelope-o my-auto mx-2 dark-text" style="font-size: 3.5rem"></i>
                      <small class="card-title col">{{ slocale("Request from data reporters") }}</small>
                      <button class="btn primary-button mx-auto custom-button">{{ slocale("Start") }}</button>
                    </div>
                  </div>
                </div>
                <div class="card px-5 py-4 mt-2 bizTravel-card">
                  <div class="card-body">
                    <div class="d-flex flex-column justify-content-center align-items-center">
                      <i class="la la-keyboard-o my-auto mx-2 dark-text" style="font-size: 3.5rem"></i>
                      <small class="card-title col">{{ slocale("Request from data reporters") }}</small>
                      <button class="btn primary-button mx-auto custom-button">{{ slocale("Start") }}</button>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row px-3 flex-wrap" *ngIf="!emissionsEmpty" style="max-width: 100vw !important">
                <div class="col-lg-6 col-sm-12" style="height: 100% !important">
                  <div class="d-flex flex-row justify-content-center align-items-end blue-border-b p-2 mt-auto" style="height: 100% !important; min-height: 99px; background-color: var(--light-blue)">
                    <h6 class="mt-auto" style="position: relative; top: 10px">{{ slocale("TOTAL: ") }}</h6>
                    <p class="mt-auto mb-0 pb-0 mx-2" style="position: relative; top: 7px">-</p>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 pb-2">
                  <div class="d-flex flex-row justify-content-center align-items-center large-box px-2">
                    <small class="m-auto">{{ slocale("COMPLETED REQUESTS") }}</small>
                    <span class="m-auto">-</span>
                    <small class="m-auto">{{ slocale("PENDING REQUESTS") }}</small>
                    <span class="m-auto">-</span>
                    <small class="m-auto">{{ slocale("SUBMISSIONS TO REVIEW") }}</small>
                    <a><i class="la la-arrow-right fs-3 m-auto"></i></a>
                  </div>
                </div>
                <div class="">
                  <div class="col-12 border bg-comp-grey text-center h-100 d-flex flex-column justify-content-center align-items-center" style="height: 100%; min-height: 300px">
                    <p class="my-auto">{{ slocale("No data reportted yet") }}</p>
                  </div>
                </div>
              </div>
              <div class="row px-3 flex-wrap" *ngIf="emissionsEmpty" style="max-width: 100vw !important">
                <div class="col-lg-6 col-md-12" style="height: 100% !important">
                  <div class="d-flex flex-row justify-content-center align-items-center blue-border-b p-2 mt-auto" style="height: 100% !important; min-height: 99px; background-color: var(--light-blue)">
                    <h3 class="mt-auto" style="position: relative; top: 10px">{{ slocale("TOTAL: ") }}</h3>
                    <h3 class="mt-auto mb-0 pb-0" style="position: relative; top: 3px"><span class="bold mx-2">44</span>tCO2e</h3>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 pb-2">
                  <div class="d-flex flex-row justify-content-center align-items-center large-box px-2">
                    <small class="m-auto">{{ slocale("COMPLETED REQUESTS") }}</small>
                    <h4 class="m-auto bold d-inline">90%</h4>
                    <small class="m-auto">{{ slocale("PENDING REQUESTS") }}</small>
                    <h4 class="m-auto bold d-inline">2</h4>
                    <small class="m-auto">{{ slocale("SUBMISSIONS TO REVIEW") }}</small>
                    <div class="icon-circle-pink-lg me-2 my-auto"><span class="text-white bold d-inline">2</span></div>
                    <a><i class="la la-arrow-right fs-3 m-auto"></i></a>
                  </div>
                </div>
                <div class="px-2">
                  <div class="border bg-comp-grey h-100 mx-1">
                    <div class="pt-3" style="height: 100%; min-height: 300px; max-width: 100% !important">
                      <div class=" p-sm-0 m-sm-0">
                        <ul class="nav nav-tabs nav-underline no-hover-bg w-100 bg-transparent" *ngIf="screenSize.isLargeSize() || screenSize.isXLargeSize()">
                          <li class="nav-item bg-transparent">
                            <a class="nav-link mb-0 pb-0" id="base-tab31" [class.active]="activeTab === 'tab31'" (click)="selectTab('tab31')"> {{ slocale("All data entries") }}</a>
                          </li>
                          <li class="nav-item bg-transparent">
                            <a class="nav-link mb-0 pb-0" id="base-tab32" [class.active]="activeTab === 'tab32'" (click)="selectTab('tab32')">{{ slocale("Per organizational unit") }}</a>
                          </li>
                          <li class="nav-item bg-transparent">
                            <a class="nav-link mb-0 pb-0" id="base-tab33" [class.active]="activeTab === 'tab33'" (click)="selectTab('tab33')">{{ slocale("Input Log") }}</a>
                          </li>
                          <div class="ms-auto">
                            <input type="text" class="form-control w-100" placeholder="{{ locale('locale_key.general.search') }}" (input)="filter(search.value)" #search />
                          </div>
                        </ul>
                        <div class="d-flex flex-row justify-content-between align-items-center" *ngIf="screenSize.isSmallerSize() || screenSize.isXSmallSize() || screenSize.isMediumSize()">
                          <h5 class="">{{ getActiveTabName() }}</h5>
                          <div class="dropdown ms-auto no-border">
                            <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <ul class="no-hover-bg nav-underline w-100 bg-transparent">
                                <li class="nav-item bg-transparent">
                                  <a class="nav-link active" id="base-tab31" [class.active]="activeTab === 'tab31'" (click)="selectTab('tab31')"> {{ slocale("All data entries") }}</a>
                                </li>
                                <li class="nav-item bg-transparent">
                                  <a class="nav-link" id="base-tab32" [class.active]="activeTab === 'tab32'" (click)="selectTab('tab32')">{{ slocale("Per organizational unit") }}</a>
                                </li>
                                <li class="nav-item bg-transparent">
                                  <a class="nav-link" id="base-tab33" [class.active]="activeTab === 'tab33'" (click)="selectTab('tab33')">{{ slocale("Input Log") }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="me-auto" *ngIf="screenSize.isSmallerSize() || screenSize.isXSmallSize() || screenSize.isMediumSize()">
                          <input type="text" class="form-control w-auto" placeholder="{{ locale('locale_key.general.search') }}" (input)="filter(search.value)" #search />
                        </div>
                        <div class="tab-content px-1 pt-1">
                          <div role="tabpanel" class="tab-pane" id="tab31" [class.active]="activeTab === 'tab31'" aria-labelledby="base-tab31">
                            <div class="">
                              <table class="table table-hover table-borderless table-responsive mt-2">
                                <thead>
                                  <tr>
                                    <th>
                                      <h6 class="text-muted mb-1">
                                        {{ slocale("Emission source") }} <a><i class="la la-filter"></i></a>
                                      </h6>
                                    </th>
                                    <th class="hide-on-small">
                                      <h6 class="text-muted mb-1">
                                        {{ slocale("Organizational unit") }} <a><i class="la la-filter"></i></a>
                                      </h6>
                                    </th>
                                    <th class="hide-on-small">
                                      <h6 class="text-muted mb-1">
                                        {{ slocale("Time") }} <a><i class="la la-filter"></i></a>
                                      </h6>
                                    </th>
                                    <th>
                                      <h6 class="text-muted mb-1">{{ slocale("Calculation method") }}</h6>
                                    </th>
                                    <th>
                                      <h6 class="text-muted mb-1">
                                        {{ slocale("Emission factor") }} <i class="la la-info-circle info-popover-arrow warning" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                                        <div class="dropdown-menu arrow p-2 info-popover">
                                          <p>{{ slocale("Explanation about emissions.") }}</p>
                                        </div>
                                      </h6>
                                    </th>
                                    <th>
                                      <h6 class="text-muted mb-1">
                                        {{ slocale("GHG emissions") }}
                                      </h6>
                                    </th>
                                    <th class="hide-on-medium">
                                      <h6 class="text-muted mb-1">
                                        {{ slocale("Added to project") }} <a><i class="la la-filter"></i></a>
                                      </h6>
                                    </th>
                                    <th>{{ slocale("") }}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="row-1">
                                    <td class="pt-3">
                                      {{ slocale("Air travel") }}
                                    </td>
                                    <td class="pt-3 hide-on-small">{{ slocale("Sthlm office") }}</td>
                                    <td class="pt-3 hide-on-small">2021-09-01</td>
                                    <td class="pt-3">{{ slocale("Weight method") }}</td>
                                    <td class="pt-3">3,1 kgCO2e/Km</td>
                                    <td class="pt-3">416 kgCO2e/Km</td>
                                    <td class="pt-3 hide-on-medium">2021-09-01</td>
                                    <td>
                                      <div class="dropdown ms-auto no-border">
                                        <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                                        </button>
                                        <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                                          <a class="cursor d-block" (click)="openModal(showCalcModal, 'modal-md')"><i class="la la-calculator me-2"></i>{{ slocale("Show calculation") }}</a>
                                          <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')"><i class="la la-edit me-2"></i>{{ slocale("Edit emission factor") }}</a>
                                          <a class="cursor d-block" (click)="openModal(emissionsPortalModal, 'modal-xl')"><i class="la la-code-branch me-2"></i>{{ slocale("Trace source") }}</a>
                                          <a class="cursor d-block" (click)="openModal(removeProjectModal, 'modal-lg')"><i class="la la-trash me-2 warning"></i>{{ slocale("Remove entry from project") }}</a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr class="row-2">
                                    <td class="pt-3">
                                      {{ slocale("Air travel") }}
                                    </td>
                                    <td class="pt-3 hide-on-small">{{ slocale("Sthlm office") }}</td>
                                    <td class="pt-3 hide-on-small">2021-09-01</td>
                                    <td class="pt-3">{{ slocale("Weight method") }}</td>
                                    <td class="pt-3">3,1 kgCO2e/Km</td>
                                    <td class="pt-3">416 kgCO2e/Km</td>
                                    <td class="pt-3 hide-on-medium">2021-09-01</td>
                                    <td>
                                      <div class="dropdown ms-auto no-border">
                                        <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                                        </button>
                                        <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                                          <a class="cursor d-block" (click)="openModal(showCalcModal, 'modal-md')"><i class="la la-calculator me-2"></i>{{ slocale("Show calculation") }}</a>
                                          <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')"><i class="la la-edit me-2"></i>{{ slocale("Edit emission factor") }}</a>
                                          <a class="cursor d-block" (click)="openModal(emissionsPortalModal, 'modal-xl')"><i class="la la-code-branch me-2"></i>{{ slocale("Trace source") }}</a>
                                          <a class="cursor d-block" (click)="openModal(removeProjectModal, 'modal-lg')"><i class="la la-trash me-2 warning"></i>{{ slocale("Remove entry from project") }}</a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr class="row-3">
                                    <td class="pt-3">
                                      {{ slocale("Air travel") }}
                                    </td>
                                    <td class="pt-3 hide-on-small" colspan="5">
                                      <div class="border rounded w-100 d-flex flex-row justify-content-between p-2">
                                        <div class="d-flex flex-row justify-content-start align-items-center d-inline">
                                          <h6 class="mb-0 pb-0 d-inline">
                                            {{ slocale("waiting for data submissions from ") }}
                                            <div class="icon-circle-dark mx-2 my-auto d-inline"><span class="text-white px-2">3</span></div>
                                            {{ slocale("data reporters") }}
                                          </h6>
                                        </div>
                                        <span class="badge my-auto badge-turquoise">{{ slocale("Sent") }}</span>
                                      </div>
                                    </td>
                                    <td class="pt-3" colspan="3" *ngIf="screenSize.isSmallerSize() || screenSize.isXSmallSize()">
                                      <div class="border rounded w-100 d-flex flex-row justify-content-between p-2">
                                        <div class="d-flex flex-row justify-content-start align-items-center d-inline">
                                          <h6 class="mb-0 pb-0 d-inline">
                                            {{ slocale("waiting for data submissions from ") }}
                                            <div class="icon-circle-dark mx-2 my-auto d-inline"><span class="text-white px-2">3</span></div>
                                            {{ slocale("data reporters") }}
                                          </h6>
                                        </div>
                                        <span class="badge my-auto badge-turquoise">{{ slocale("Sent") }}</span>
                                      </div>
                                    </td>
                                    <td class="pt-3 hide-on-medium">2021-09-01</td>
                                    <td>
                                      <div class="dropdown ms-auto no-border">
                                        <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                                        </button>
                                        <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                                          <a class="cursor d-block" (click)="openModal(showCalcModal, 'modal-md')"><i class="la la-calculator me-2"></i>{{ slocale("Show calculation") }}</a>
                                          <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')"><i class="la la-edit me-2"></i>{{ slocale("Edit emission factor") }}</a>
                                          <a class="cursor d-block" (click)="openModal(emissionsPortalModal, 'modal-xl')"><i class="la la-code-branch me-2"></i>{{ slocale("Trace source") }}</a>
                                          <a class="cursor d-block" (click)="openModal(removeProjectModal, 'modal-lg')"><i class="la la-trash me-2 warning"></i>{{ slocale("Remove entry from project") }}</a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr class="row-4">
                                    <td class="pt-3">
                                      {{ slocale("To be defined by data reporters") }}
                                    </td>
                                    <td class="pt-3 hide-on-small" colspan="5" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">
                                      <div class="border rounded w-100 d-flex flex-row justify-content-between p-2">
                                        <div class="d-flex flex-row justify-content-start align-items-center d-inline">
                                          <h6 class="mb-0 pb-0 d-inline">
                                            {{ slocale("waiting for data submissions from ") }}
                                            <div class="icon-circle-dark mx-2 my-auto d-inline"><span class="text-white px-2">3</span></div>
                                            {{ slocale("data reporters") }}
                                          </h6>
                                        </div>
                                        <span class="badge my-auto badge-orange">{{ slocale("In progress") }}</span>
                                      </div>
                                    </td>
                                    <td class="pt-3" colspan="3" *ngIf="screenSize.isSmallerSize() || screenSize.isXSmallSize()">
                                      <div class="border rounded w-100 d-flex flex-row justify-content-between p-2">
                                        <div class="d-flex flex-row justify-content-start align-items-center d-inline">
                                          <h6 class="mb-0 pb-0 d-inline">
                                            {{ slocale("waiting for data submissions from ") }}
                                            <div class="icon-circle-dark mx-2 my-auto d-inline"><span class="text-white px-2">3</span></div>
                                            {{ slocale("data reporters") }}
                                          </h6>
                                        </div>
                                        <span class="badge my-auto badge-orange">{{ slocale("In progress") }}</span>
                                      </div>
                                    </td>
                                    <td class="pt-3 hide-on-medium">2021-09-01</td>
                                    <td>
                                      <div class="dropdown ms-auto no-border">
                                        <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                                        </button>
                                        <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                                          <a class="cursor d-block" (click)="openModal(showCalcModal, 'modal-md')"><i class="la la-calculator me-2"></i>{{ slocale("Show calculation") }}</a>
                                          <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')"><i class="la la-edit me-2"></i>{{ slocale("Edit emission factor") }}</a>
                                          <a class="cursor d-block" (click)="openModal(emissionsPortalModal, 'modal-xl')"><i class="la la-code-branch me-2"></i>{{ slocale("Trace source") }}</a>
                                          <a class="cursor d-block" (click)="openModal(removeProjectModal, 'modal-lg')"><i class="la la-trash me-2 warning"></i>{{ slocale("Remove entry from project") }}</a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr class="row-5">
                                    <td class="pt-3">
                                      {{ slocale("Air travel") }}
                                    </td>
                                    <td class="pt-3 hide-on-small">{{ slocale("Sthlm office") }}</td>
                                    <td class="pt-3 hide-on-small">2021-09-01</td>
                                    <td class="pt-3">{{ slocale("Weight method") }}</td>
                                    <td class="pt-3">3,1 kgCO2e/Km</td>
                                    <td class="pt-3">416 kgCO2e/Km</td>
                                    <td class="pt-3 hide-on-medium">2021-09-01</td>
                                    <td>
                                      <div class="dropdown ms-auto no-border">
                                        <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                                        </button>
                                        <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                                          <a class="cursor d-block" (click)="openModal(showCalcModal, 'modal-md')"><i class="la la-calculator me-2"></i>{{ slocale("Show calculation") }}</a>
                                          <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')"><i class="la la-edit me-2"></i>{{ slocale("Edit emission factor") }}</a>
                                          <a class="cursor d-block"><i class="la la-code-branch me-2"></i>{{ slocale("Trace source") }}</a>
                                          <a class="cursor d-block" (click)="openModal(removeProjectModal, 'modal-lg')"><i class="la la-trash me-2 warning"></i>{{ slocale("Remove entry from project") }}</a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr class="row-6">
                                    <td class="pt-3">
                                      {{ slocale("Air travel") }}
                                    </td>
                                    <td class="pt-3 hide-on-small">{{ slocale("Sthlm office") }}</td>
                                    <td class="pt-3 hide-on-small">2021-09-01</td>
                                    <td class="pt-3">{{ slocale("Weight method") }}</td>
                                    <td class="pt-3">3,1 kgCO2e/Km</td>
                                    <td class="pt-3">416 kgCO2e/Km</td>
                                    <td class="pt-3 hide-on-medium">2021-09-01</td>
                                    <td>
                                      <div class="dropdown ms-auto no-border">
                                        <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                                        </button>
                                        <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                                          <a class="cursor d-block" (click)="openModal(showCalcModal, 'modal-md')"><i class="la la-calculator me-2"></i>{{ slocale("Show calculation") }}</a>
                                          <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')"><i class="la la-edit me-2"></i>{{ slocale("Edit emission factor") }}</a>
                                          <a class="cursor d-block" (click)="openModal(emissionsPortalModal, 'modal-xl')"><i class="la la-code-branch me-2"></i>{{ slocale("Trace source") }}</a>
                                          <a class="cursor d-block" (click)="openModal(removeProjectModal, 'modal-lg')"><i class="la la-trash me-2 warning"></i>{{ slocale("Remove entry from project") }}</a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr class="">
                                    <td class="pt-3">
                                      {{ slocale("Air travel") }}
                                    </td>
                                    <td class="pt-3 hide-on-small">{{ slocale("Sthlm office") }}</td>
                                    <td class="pt-3 hide-on-small">2021-09-01</td>
                                    <td class="pt-3">{{ slocale("Weight method") }}</td>
                                    <td class="pt-3">3,1 kgCO2e/Km</td>
                                    <td class="pt-3">416 kgCO2e/Km</td>
                                    <td class="pt-3 hide-on-medium">2021-09-01</td>
                                    <td>
                                      <div class="dropdown ms-auto no-border">
                                        <button class="btn no-border" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <i class="la la-ellipsis-v cursor fs-2 mt-0 pt-0"></i>
                                        </button>
                                        <div class="dropdown-menu w-100 px-2" aria-labelledby="dropdownMenuButton">
                                          <a class="cursor d-block" (click)="openModal(showCalcModal, 'modal-md')"><i class="la la-calculator me-2"></i>{{ slocale("Show calculation") }}</a>
                                          <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')"><i class="la la-edit me-2"></i>{{ slocale("Edit emission factor") }}</a>
                                          <a class="cursor d-block" (click)="openModal(emissionsPortalModal, 'modal-xl')"><i class="la la-code-branch me-2"></i>{{ slocale("Trace source") }}</a>
                                          <a class="cursor d-block" (click)="openModal(removeProjectModal, 'modal-lg')"><i class="la la-trash me-2 warning"></i>{{ slocale("Remove entry from project") }}</a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="tab-pane" id="tab32" [class.active]="activeTab === 'tab32'" aria-labelledby="base-tab32">
                            <p>Sugar plum tootsie roll biscuit caramels. Liquorice brownie pastry cotton candy oat cake fruitcake jelly chupa chups. Pudding caramels pastry powder cake soufflé wafer caramels. Jelly-o pie cupcake.</p>
                          </div>
                          <div class="tab-pane" id="tab33" [class.active]="activeTab === 'tab33'" aria-labelledby="base-tab33">
                            <p>Biscuit ice cream halvah candy canes bear claw ice cream cake chocolate bar donut. Toffee cotton candy liquorice. Oat cake lemon drops gingerbread dessert caramels. Sweet dessert jujubes powder sweet sesame
                              snaps.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--ALL PROJECTS DASHBOARD and SUMMARY Table-->
            <div class="card-body px-0 mt-0 pt-0" *ngIf="allProjects && !cardHeader && !cardFooter" style="overflow-x: hidden">
              <div class="h-100 mx-1 p-2">
                <div class="" style="height: 100%; min-height: 300px; max-width: 100% !important">
                  <div class=" p-sm-0 m-sm-0">
                    <ul class="nav nav-tabs nav-underline no-hover-bg w-100 bg-transparent">
                      <li class="nav-item bg-transparent">
                        <a class="nav-link mb-0 pb-0 active" id="base-tab35" [class.active]="activeTab === 'tab35'" (click)="selectTab('tab35')"> {{ slocale("Dashboard") }}</a>
                      </li>
                      <li class="nav-item bg-transparent">
                        <a class="nav-link mb-0 pb-0" id="base-tab36" [class.active]="activeTab === 'tab36'" (click)="selectTab('tab36')">{{ slocale("Summary table") }}</a>
                      </li>
                    </ul>
                    <div class="tab-content px-1 pt-1">
                      <div role="tabpanel" class="tab-pane" id="tab35" [class.active]="activeTab === 'tab35'" aria-labelledby="base-tab35">
                        <div class=" p-sm-0 m-sm-0">
                          <img src="assets/img/dashboard.jpg" class="img-fluid w-100 h-100 mt-2" alt="dashboard" />
                        </div>
                      </div>
                      <div class="tab-pane" id="tab36" [class.active]="activeTab === 'tab36'" aria-labelledby="base-tab36">
                        <div class=" p-sm-0 m-sm-0">
                          <div class="row py-3">
                            <select class="form-select w-auto" aria-label="Default select example">
                              <option selected>{{ slocale("All entries") }}</option>
                              <option value="1">Project 1</option>
                              <option value="2">Project 2</option>
                              <option value="3">Project 3</option>
                            </select>
                          </div>
                          <img src="assets/img/table.jpg" class="img-fluid w-100 h-100 mt-2" alt="dashboard" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Carbon Emissions 2023 screen 10 emissions categories-->
            <div class="card-body px-0 pt-0 mt-0 bg-comp-grey scroll-y" *ngIf="carbonEmissions2023 && !cardHeader && !cardFooter">
              <div class=" p-sm-0 m-sm-0 px-0">
                <div class="d-flex flex-row flex-wrap justify-content-between bg-white mt-3 p-3 p-sm-1">
                  <div class="col-lg-6 col-sm-12">
                    <h5 class="mb-lg-0 mb-sm-2 pt-2">{{ slocale("Carbon emissions 2023") }}</h5>
                  </div>
                  <div class="col-lg-6 col-sm-12 d-flex flex-row justify-content-lg-end justify-content-sm-start justify-content-md-start">
                    <div class="d-flex flex-row justify-content-lg-end justify-content-sm-start justify-content-md-start">
                      <div class="green-border w-auto d-inline px-2">
                        <span>{{ slocale("10 emission categories selected") }}</span>
                      </div>
                      <i class="la la-edit size-15 d-inline ms-2 mt-1" (click)="openModal(emissionCategoriesModal, 'modal-lg')"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class=" p-sm-0 m-sm-0 px-0">
                <div class="row mt-2 bg-white pt-2 mx-0 px-0">
                  <div class="col-lg-7 col-md-12 d-flex flex-row flex-wrap justify-content-around">
                    <div class="d-flex flex-row justify-content-between align-items-center m-0 p-0 scope-container-sm">
                      <div class="d-flex flex-column justify-content-between green-border-b p-2 mt-auto mx-1">
                        <h6 class="me-auto">{{ slocale("SCOPE 1") }}</h6>
                        <p class="ms-auto">-</p>
                      </div>
                      <div class="d-flex flex-column justify-content-between mx-1">
                        <select class="form-select" aria-label="Default select example">
                          <option selected>{{ slocale("Location based") }}</option>
                          <option value="1">{{ slocale("Option 1") }}</option>
                          <option value="2">{{ slocale("Option 2") }}</option>
                          <option value="3">{{ slocale("Option 3") }}</option>
                        </select>
                        <div class="w-100 bg-white" style="height: 10px"></div>
                        <div class="d-flex flex-column justify-content-between align-items-end yellow-border-b mt-auto p-2">
                          <h6 class="me-auto">{{ slocale("SCOPE 2") }}</h6>
                          <p class="ms-auto">-</p>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-column justify-content-between align-items-end blue-border-b p-2 mt-auto mx-1">
                      <h6 class="me-auto">{{ slocale("SCOPE 3") }}</h6>
                      <p class="ms-auto">-</p>
                    </div>
                    <div class="d-flex flex-row justify-content-between align-items-end pink-border-b flex-grow-1 p-2 mt-auto mx-1">
                      <h6 class="me-auto mt-auto" style="position: relative; top: 10px">{{ slocale("TOTAL") }}</h6>
                      <p class="ms-auto mt-auto mb-0 pb-0" style="position: relative; top: 8px">-</p>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-12 pb-2">
                    <div class="d-flex flex-row justify-content-center align-items-center large-box px-2">
                      <small class="m-auto">{{ slocale("COMPLETED REQUESTS") }}</small>
                      <span class="m-auto">-</span>
                      <small class="m-auto">{{ slocale("PENDING REQUESTS") }}</small>
                      <span class="m-auto">-</span>
                      <small class="m-auto">{{ slocale("SUBMISSIONS TO REVIEW") }}</small>
                      <i class="la la-arrow-right fs-3 m-auto"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class=" p-sm-0 m-sm-0 px-0">
                <div class="p-3 p-sm-1 bg-white d-flex flex-row justify-content-between align-items-center mt-2">
                  <h6 class="mb-0 pb-0 d-inline">{{ slocale("Data collection") }}</h6>
                  <button class="btn primary-button custom-button text-white">{{ slocale("Start") }}</button>
                </div>
              </div>
              <div class=" p-sm-0 m-sm-0 px-0">
                <div class="p-3 p-sm-1 bg-white d-flex flex-row justify-content-between align-items-center mt-2">
                  <h6 class="mb-0 pb-0 d-inline">{{ slocale("Dashboard & Summary") }}</h6>
                  <a class=""><i class="la la-arrow-right size-15"></i></a>
                </div>
              </div>
              <div class=" p-sm-0 m-sm-0 px-0">
                <div class="bg-white d-flex flex-row justify-content-between align-items-center mt-2">
                  <div class="accordion w-100" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <a class="accordion-button d-flex flex-row justify-content-between align-items-center w-100 px-3 bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                          aria-controls="collapseOne">
                          <div class="m-0 p-0">
                            <h6 class="mb-0 pb-0 text-wrap">{{ slocale("Data collection") }}</h6>
                          </div>
                          <div class="m-0 p-0 d-flex flex-row justify-content-end align-items-center flex-grow-1">
                            <input type="text" class="form-control me-1 w-auto input-small" placeholder="{{ locale('locale_key.general.search') }}" (input)="filter(search.value)" #search />
                            <a class="underline my-auto mx-2 cursor" (click)="expandAll()">{{ locale("locale_key.pages.kpi_library.button.expand_all") }} </a>
                            <a class="underline my-auto me-2 cursor" (click)="collapseAll()">{{ locale("locale_key.pages.kpi_library.button.collapse_all") }} </a>
                          </div>
                        </a>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                        <div class="accordion-body p-0 pb-3">
                          <div class="bg-light-gray p-3 p-sm-1 mx-0">
                            <h6 class="mb-0 pb-0">{{ slocale("Reporting company") }}</h6>
                          </div>
                          <p class="px-2 mb-0 mt-2">{{ slocale("Scope 1 (Direct)") }}</p>
                          <div class="accordion w-100 px-2" id="accordionExample1">
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white green-border-l" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                  aria-expanded="false" aria-controls="collapseTwo">
                                  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                    <i class="la la-building-o size-15 me-2"></i>
                                    <h6 class="mb-0 pb-0">{{ slocale("Company facilities") }}</h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center">
                                    <h6 class="mb-0 pb-0 me-2">{{ slocale("Total: ") }}<span class="bold me-1">80</span>tCO2e</h6>
                                  </div>
                                </a>
                              </h2>
                              <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button"><i class="la la-plus-circle me-2 size-15 bold"></i>Add data</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white green-border-l" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                  aria-expanded="false" aria-controls="collapseThree">
                                  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                    <i class="la la-bus size-15 me-2"></i>
                                    <h6 class="mb-0 pb-0">{{ slocale("Company vehicles") }}</h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center">
                                    <h6 class="mb-0 pb-0 me-2">{{ slocale("Total: ") }}<span class="bold me-1">25</span>tCO2e</h6>
                                  </div>
                                </a>
                              </h2>
                              <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button"><i class="la la-plus-circle me-2 size-15 bold"></i>Add data</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p class="px-2 mb-0 mt-2">{{ slocale("Scope 2 (Indirect)") }}</p>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white teal-border-l thin-border-t" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                  aria-expanded="false" aria-controls="collapseFour">
                                  <div class="d-flex flex-row justify-content-start align-items-center">
                                    <i class="la la-lightbulb size-15 me-2"></i>
                                    <h6 class="mb-0 pb-0">{{ slocale("Purchased electricity, steam, heating & cooling") }}</h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center flex-grow-1">
                                    <select class="form-select w-auto me-2" aria-label="Default select example">
                                      <option selected>{{ slocale("Location based") }}</option>
                                      <option value="1">{{ slocale("Option 1") }}</option>
                                      <option value="2">{{ slocale("Option 2") }}</option>
                                      <option value="3">{{ slocale("Option 3") }}</option>
                                    </select>
                                    <h6 class="mb-0 pb-0 me-2">{{ slocale("Total: ") }}<span class="bold me-1">25</span>tCO2e</h6>
                                  </div>
                                </a>
                              </h2>
                              <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button"><i class="la la-plus-circle me-2 size-15 bold"></i>Add data</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="bg-light-gray p-3 p-sm-1 mx-0 mt-2">
                              <h6 class="mb-0 pb-0">{{ slocale("Upstream Activities") }}</h6>
                            </div>
                            <p class="px-2 mb-0 mt-2">{{ slocale("Scope 3 (Indirect)") }}</p>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white blue-border-l thin-border-t" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive"
                                  aria-expanded="false" aria-controls="collapseFive">
                                  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                    <i class="la la-handshake size-15 me-2"></i>
                                    <h6 class="mb-0 pb-0">{{ slocale("Purchased goods and services") }}</h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center">
                                    <h6 class="mb-0 pb-0 me-2">{{ slocale("Total: ") }}<span class="bold me-1">25</span>tCO2e</h6>
                                  </div>
                                </a>
                              </h2>
                              <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button"><i class="la la-plus-circle me-2 size-15 bold"></i>Add data</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white blue-border-l" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix"
                                  aria-expanded="false" aria-controls="collapseSix">
                                  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                    <i class="la la-car-side size-15 me-2"></i>
                                    <h6 class="mb-0 pb-0">{{ slocale("Upstream transportation and distribution") }}</h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center">
                                    <h6 class="mb-0 pb-0 me-2">{{ slocale("Total: ") }}<span class="bold me-1">25</span>tCO2e</h6>
                                  </div>
                                </a>
                              </h2>
                              <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button"><i class="la la-plus-circle me-2 size-15 bold"></i>Add data</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white blue-border-l" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven"
                                  aria-expanded="false" aria-controls="collapseSeven">
                                  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                    <i class="la la-trash-alt size-15 me-2"></i>
                                    <h6 class="mb-0 pb-0">{{ slocale("Waste generated in operations") }}</h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center">
                                    <h6 class="mb-0 pb-0 me-2">{{ slocale("Total: ") }}<span class="bold me-1">25</span>tCO2e</h6>
                                  </div>
                                </a>
                              </h2>
                              <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button"><i class="la la-plus-circle me-2 size-15 bold"></i>Add data</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white blue-border-l" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight"
                                  aria-expanded="false" aria-controls="collapseEight">
                                  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                    <i class="la la-plane size-15 me-2" style="transform: rotate(90deg)"></i>
                                    <h6 class="mb-0 pb-0">{{ slocale("Business travel") }}</h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center">
                                    <h6 class="mb-0 pb-0 me-2">{{ slocale("Total: ") }}<span class="bold me-1">25</span>tCO2e</h6>
                                  </div>
                                </a>
                              </h2>
                              <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button" (click)="bizTravel = true"><i class="la la-plus-circle me-2 size-15 bold"></i>Add data</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white blue-border-l" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9"
                                  aria-expanded="false" aria-controls="collapse9">
                                  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                    <i class="la la-bus size-15 me-2" style="transform: rotate(90deg)"></i>
                                    <h6 class="mb-0 pb-0">{{ slocale("Employee commuting") }}</h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center">
                                    <h6 class="mb-0 pb-0 me-2">{{ slocale("Total: ") }}<span class="bold me-1">25</span>tCO2e</h6>
                                  </div>
                                </a>
                              </h2>
                              <div id="collapse9" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button"><i class="la la-plus-circle me-2 size-15 bold"></i>Add data</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="bg-light-gray p-3 p-sm-1 mx-0 mt-2">
                              <h6 class="mb-0 pb-0">{{ slocale("Downstream Activities") }}</h6>
                            </div>
                            <p class="px-2 mb-0 mt-2">{{ slocale("Scope 3 (Indirect)") }}</p>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white pink-border-l thin-border-t" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10"
                                  aria-expanded="false" aria-controls="collapse10">
                                  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                    <i class="la la-handshake size-15 me-2"></i>
                                    <h6 class="mb-0 pb-0">{{ slocale("Use of sold products") }}</h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center">
                                    <h6 class="mb-0 pb-0 me-2">{{ slocale("Total: ") }}<span class="bold me-1">25</span>tCO2e</h6>
                                  </div>
                                </a>
                              </h2>
                              <div id="collapse10" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button"><i class="la la-plus-circle me-2 size-15 bold"></i>Add data</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header">
                                <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white pink-border-l" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11"
                                  aria-expanded="false" aria-controls="collapse11">
                                  <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                    <i class="la la-car-side size-15 me-2"></i>
                                    <h6 class="mb-0 pb-0">{{ slocale("Downstream transportation and distribution") }}</h6>
                                  </div>
                                  <div class="d-flex flex-row justify-content-end align-items-center">
                                    <h6 class="mb-0 pb-0 me-2">{{ slocale("Total: ") }}<span class="bold me-1">25</span>tCO2e</h6>
                                  </div>
                                </a>
                              </h2>
                              <div id="collapse11" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                <div class="accordion-body p-0 bg-comp-grey">
                                  <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                    <p>Add data to start calculating GHG emissions for this emission category.</p>
                                    <button class="btn tertiary-button custom-button"><i class="la la-plus-circle me-2 size-15 bold"></i>Add data</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                ---FILTERS---
                <div class="accordion" id="accordionExample15">
                  <div class="d-flex flex-row justify-content-between align-items-center py-2 px-3 bg-white border">
                    <span>
                      <span class="mb-0 pb-0 ms-1">{{ slocale("Sort by name") }}</span><label class="form-check-label">
                        <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                        A-Z
                      </label>
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                        Z-A
                      </label>
                    </span>
                    <a class="underline">{{ slocale("Clear") }}</a>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <a class="accordion-button bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse15" aria-expanded="true" aria-controls="collapse15">
                        {{ slocale("Filter by condition") }}
                      </a>
                    </h2>
                    <div id="collapse15" class="accordion-collapse collapse" data-bs-parent="#accordionExample15">
                      <div class="accordion-body pb-2">
                        <div class="d-flex flex-row justify-content-between align-items-center">
                          <div class="">
                            <div class="btn-group" dropdown container="body">
                              <button type="button" class="form-control w-auto dark-text mx-1" id="group-button" dropdownToggle aria-controls="group-dropdown-menu" style="text-align: left">
                                {{ selectedOption || slocale("None") }}
                              </button>
                              <div class="dropdown-menu" id="group-dropdown-menu" *dropdownMenu role="menu" aria-labelledby="group-button">
                                <a class="dropdown-item" (click)="setDateOption(slocale('Is empty'))">{{ slocale("Is empty") }}</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" (click)="setDateOption(slocale('Is not empty'))">{{ slocale("Is not empty") }}</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" (click)="setDateOption(slocale('Date is before'))">{{ slocale("Date is before") }}</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" (click)="setDateOption(slocale('Date is between'))">{{ slocale("Date is between") }}</a>
                              </div>
                            </div>
                          </div>
                          <a class="underline my-auto mx-2 cursor">{{ slocale("Clear all") }} </a>
                        </div>
                        <div *ngIf="selectedOption === slocale('Date is before')" class="mt-1 px-1">
                          <input type="date" class="form-control" />
                        </div>
                        <div *ngIf="selectedOption === slocale('Date is between')" class="mt-1 d-flex align-items-center px-1">
                          <input type="date" class="form-control me-2" />
                          <span>{{ slocale("and") }}</span>
                          <input type="date" class="form-control ms-2" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <a class="accordion-button collapsed bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16" aria-expanded="false" aria-controls="collapse16">
                        {{ slocale("Filter by value") }}
                      </a>
                    </h2>
                    <div id="collapse16" class="accordion-collapse collapse" data-bs-parent="#accordionExample15">
                      <div class="accordion-body">
                        <div class="d-flex flex-row justify-content-between align-items-center">
                          <input type="text" class="form-control me-1 w-auto input-small" placeholder="{{ locale('locale_key.general.search') }}" (input)="filter(search.value)" #search />
                          <div class="d-flex flex-row justify-content-end align-items-center">
                            <a class="underline my-auto mx-2 cursor">{{ slocale("Select all") }} </a>
                            <a class="underline my-auto me-2 cursor">{{ slocale("Deselect all") }} </a>
                          </div>
                        </div>
                        <div class="d-flex flex-column mt-2">
                          <div class="d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1">
                            <label class="form-check-label">
                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                              01.013.2023
                            </label>
                          </div>
                          <div class="d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1">
                            <label class="form-check-label">
                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                              01.013.2023
                            </label>
                          </div>
                          <div class="d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1">
                            <label class="form-check-label">
                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                              01.013.2023
                            </label>
                          </div>
                          <div class="d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1">
                            <label class="form-check-label">
                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                              01.013.2023
                            </label>
                          </div>
                          <div class="d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1">
                            <label class="form-check-label">
                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                              01.013.2023
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <a class="accordion-button collapsed bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse17" aria-expanded="false" aria-controls="collapse17">
                        {{ slocale("Filter by emission source") }}
                      </a>
                    </h2>
                    <div id="collapse17" class="accordion-collapse collapse" data-bs-parent="#accordionExample15">
                      <div class="accordion-body">
                        <div class="d-flex flex-row justify-content-between align-items-center">
                          <input type="text" class="form-control me-1 w-auto input-small" placeholder="{{ locale('locale_key.general.search') }}" (input)="filter(search.value)" #search />
                          <div class="d-flex flex-row justify-content-end align-items-center">
                            <a class="underline my-auto mx-2 cursor">{{ slocale("Select all") }} </a>
                            <a class="underline my-auto me-2 cursor">{{ slocale("Deselect all") }} </a>
                          </div>
                        </div>
                        <div class="d-flex flex-column mt-2">
                          <div class="d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1">
                            <label class="form-check-label">
                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                              Air travel
                            </label>
                          </div>
                          <div class="d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1">
                            <label class="form-check-label">
                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                              Train travel
                            </label>
                          </div>
                          <div class="d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1">
                            <label class="form-check-label">
                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                              Road travel
                            </label>
                          </div>
                          <div class="d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1">
                            <label class="form-check-label">
                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                              Hotel accomodation
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- CSRD OVERVIEW PAGE -->
            <div class="card-body px-0 pt-0 mt-0 bg-comp-grey scroll-y">
              <div class=" p-sm-0 m-sm-0 px-0">
                <div class="csrdOverviewHeader">
                  <div class="title">
                    <div class="location">
                      {{ slocale("Overview") }}
                    </div>
                    <div class="label">
                      {{ activeCsrdProject.name }}
                    </div>
                  </div>
                  <div class="controls">
                    <div class="editProjectInfoControlBox">
                      <div class="dropdown m-1 projectInfoDropdown">
                        <a class="dropdown-toggle remove-arrow-drpdown form-control filter-button" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="la la-calendar-check-o"></i>
                          <span>{{ slocale("Project info") }}</span>
                        </a>
                        <div class="dropdown-menu p-4 projectInfoDropdownMenu">
                          <div class="mb-2">
                            {{ activeCsrdProject.name }}
                          </div>
                          <div class="mb-2">
                            <small class="text-muted">{{ slocale("Reporting year") }}</small>
                            <h6>{{ activeCsrdProject.definition.reportingYear }}</h6>
                          </div>
                          <div class="mb-2">
                            <small class="text-muted">{{ slocale("Company name") }}</small>
                            <h6> {{ activeCsrdProject.definition.company.name }} </h6>
                          </div>
                          <div class="mb-2">
                            <small class="text-muted">{{ slocale("Country, town") }}</small>
                            <h6>{{ activeCsrdProject.definition.company.hq.country }}, {{ activeCsrdProject.definition.company.hq.town }}</h6>
                          </div>
                          <div class="mb-2">
                            <small class="text-muted">{{ slocale("Employee range") }}</small>
                            <h6 *ngIf="activeCsrdProject.definition.company.noEmployees == '0_750'">0 - 750</h6>
                            <h6 *ngIf="activeCsrdProject.definition.company.noEmployees == '750+'">750+</h6>
                          </div>
                          <div class="d-flex align-items-center justify-content-center">
                            <div class="px-3 py-1 border cursor-pointer editProjectInfoBtn" (click)="startEditProjectInfo()">
                              <i class="la la-edit size-15 me-2"></i>
                              {{ slocale("Edit project info") }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="overviewTabChangerBox">

                      <div class="dropdown m-1">
                        <a class="dropdown-toggle remove-arrow-drpdown form-control filter-button" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          {{ getCsrdOverviewTabName(activeCsrdOverviewTab) }}
                          <i class="las la-info-circle" data-bs-toggle="tooltip" data-placement="top" [title]="getCsrdOverviewTabTitle(activeCsrdOverviewTab)" show-delay="3000"></i>
                        </a>

                        <ul class="dropdown-menu">
                          <li *ngIf="activeCsrdOverviewTab != CSRD_PROJECT_OVERVIEW_TABS.TOPIC_PROGRESS">
                            <a class="dropdown-item" (click)="switchActiveCsrdOverviewTab(CSRD_PROJECT_OVERVIEW_TABS.TOPIC_PROGRESS)">{{ slocale("Topic progress") }}
                              <i class="las la-info-circle" data-bs-toggle="tooltip" data-placement="top"
                                [title]="slocale('This view displays all superuser tasks within the project. Review the status of each task and the overall completion rate.')" show-delay="3000"></i></a>
                          </li>
                          <!-- TO DO : Determine if we still need a superuser tasks page -->
                          <!-- <li *ngIf="activeCsrdOverviewTab != CSRD_PROJECT_OVERVIEW_TABS.SUPER_USER_PROGRESS">
                            <a class="dropdown-item" (click)="switchActiveCsrdOverviewTab(CSRD_PROJECT_OVERVIEW_TABS.SUPER_USER_PROGRESS)">{{ slocale("Superuser progress") }}
                              <i class="las la-info-circle" data-bs-toggle="tooltip" data-placement="top"
                                [title]="slocale('This view displays all superuser tasks within the project. Review the status of each task and the overall completion rate.')" show-delay="3000"></i></a>
                          </li> -->
                          <li *ngIf="activeCsrdOverviewTab != CSRD_PROJECT_OVERVIEW_TABS.DATA_REQUEST_PROGRESS">
                            <a class="dropdown-item" (click)="switchActiveCsrdOverviewTab(CSRD_PROJECT_OVERVIEW_TABS.DATA_REQUEST_PROGRESS)">{{ slocale("Data request progress") }}
                              <i class="las la-info-circle" data-bs-toggle="tooltip" data-placement="top"
                                [title]="slocale('This view displays all data requests within the project. Review the status of each data request and the overall completion rate.')" show-delay="3000"></i></a>
                          </li>
                        </ul>
                      </div>

                    </div>
                  </div>
                </div>

                <!-- TOPIC PROGRESS PAGE-->
                <ng-container *ngIf="activeCsrdOverviewTab == CSRD_PROJECT_OVERVIEW_TABS.TOPIC_PROGRESS">
                  <!-- <div class="row mt-0 bg-white mx-0 py-2 px-1 bg-white">
                    <div class="d-flex align-items-center m-0 p-0 flex-wrap">
                      <div class="col gray-border-b-lg p-2 mt-auto mx-1 w-auto" style="background-color: #f8fbfb">
                        <small class="">{{ slocale("CROSS-CUTTING") }}</small>
                        <div class="d-flex flex-row flex-wrap">
                          <div class="d-flex flex-column">
                            <small class="text-muted">{{ slocale("Collected") }}</small>
                            <h5 class="">0%</h5>
                          </div>
                          <div class="d-flex flex-column ms-2">
                            <small class="text-muted">{{ slocale("Final answer") }}</small>
                            <h5 class="">0%</h5>
                          </div>
                        </div>
                      </div>
                      <div class="col green-border-b-lg p-2 mt-auto mx-1 w-auto" style="background-color: #f8fbfb">
                        <small class="">{{ slocale("ENVIRONMENTAL") }}</small>
                        <div class="d-flex flex-row flex-wrap">
                          <div class="d-flex flex-column">
                            <small class="text-muted">{{ slocale("Collected") }}</small>
                            <h5 class="">0%</h5>
                          </div>
                          <div class="d-flex flex-column ms-2">
                            <small class="text-muted">{{ slocale("Final answer") }}</small>
                            <h5 class="">0%</h5>
                          </div>
                        </div>
                      </div>
                      <div class="col yellow-border-b-lg p-2 mt-auto mx-1 w-auto" style="background-color: #f8fbfb">
                        <small class="">{{ slocale("SOCIAL") }}</small>
                        <div class="d-flex flex-row flex-wrap">
                          <div class="d-flex flex-column">
                            <small class="text-muted">{{ slocale("Collected") }}</small>
                            <h5 class="">0%</h5>
                          </div>
                          <div class="d-flex flex-column ms-2">
                            <small class="text-muted">{{ slocale("Final answer") }}</small>
                            <h5 class="">0%</h5>
                          </div>
                        </div>
                      </div>
                      <div class="col blue-border-b-lg p-2 mt-auto mx-1 w-auto" style="background-color: #f8fbfb">
                        <small class="">{{ slocale("GOVERNANCE") }}</small>
                        <div class="d-flex flex-row flex-wrap">
                          <div class="d-flex flex-column">
                            <small class="text-muted">{{ slocale("Collected") }}</small>
                            <h5 class="">0%</h5>
                          </div>
                          <div class="d-flex flex-column ms-2">
                            <small class="text-muted">{{ slocale("Final answer") }}</small>
                            <h5 class="">0%</h5>
                          </div>
                        </div>
                      </div>
                      <div class="col pink-border-b-lg p-2 mt-auto mx-1 flex-grow-1" style="background-color: #f8fbfb">
                        <small class="">{{ slocale("OVERALL") }}</small>
                        <div class="d-flex flex-row flex-wrap">
                          <div class="d-flex flex-column">
                            <small class="text-muted">{{ slocale("Collected") }}</small>
                            <h5 class="">0%</h5>
                          </div>
                          <div class="d-flex flex-column ms-2">
                            <small class="text-muted">{{ slocale("Final answer") }}</small>
                            <h5 class="">0%</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-sm-0 m-sm-0 px-0"></div> -->

                  <div class="p-sm-0 m-sm-0 px-0">

                    <div class="bg-white d-flex flex-row justify-content-between align-items-center mt-2">
                      <div class="accordion w-100" id="accordionExample">
                        <div class="accordion-item">



                          <div class="topicProgressHeader">
                            <div class="title">
                              {{ slocale("Topic Progress") }}
                            </div>
                            <div class="controls">
                              <div class="searchControlBox">
                                <input type="text" class="form-control input-small h-fit-content search_bar" [(ngModel)]="csrdFilterCriteria" (keyup)="filterCsrd()" placeholder="{{ locale('locale_key.general.search') }}" />
                              </div>
                              <div class="otherControlsBox">
                                <a class="underline cursor expand_all" (click)="expandAllUnderDataCollection()">{{ locale("locale_key.pages.kpi_library.button.expand_all") }} </a>
                                <a class="underline cursor collapse_all" (click)="collapseAllUnderDataCollection()">{{ locale("locale_key.pages.kpi_library.button.collapse_all") }} </a>
                                <a class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#csrdTopics" aria-expanded="true" aria-controls="collapseOne"></a>
                              </div>
                            </div>
                          </div>

                          <div class="topicProgressStats">
                            <div class="statRow">
                              <div class="statRowTitle overall">{{ slocale("OVERALL") }}</div>
                              <div class="statRowStatlist">
                                <div class="stat">
                                  <div class="statName">{{ slocale("Overall collected progress (OCP)") }}</div>
                                  <div class="statValue">
                                    0%
                                  </div>
                                </div>
                                <div class="stat">
                                  <div class="statName">{{ slocale("Total data points (TDP)") }}</div>
                                  <div class="statValue">
                                    {{ stats.overall.totalDatapoints }}
                                  </div>
                                </div>
                                <div class="stat">
                                  <div class="statName">{{ slocale("Material (M)") }}</div>
                                  <div class="statValue">
                                    {{ stats.overall.materialDatapoints }}
                                  </div>
                                </div>
                                <div class="stat">
                                  <div class="statName">{{ slocale("Voluntary (VOL)") }}</div>
                                  <div class="statValue">
                                    {{ stats.overall.nonMaterialVoluntaryDatapoints }}
                                  </div>
                                </div>
                                <div class="stat">
                                  <div class="statName">{{ slocale("Not material (NM)") }}</div>
                                  <div class="statValue">
                                    {{ stats.overall.nonMaterialDatapoints }}
                                  </div>
                                </div>
                                <div class="stat">
                                  <div class="statName">{{ slocale("Omitted (OM)") }}</div>
                                  <div class="statValue">
                                    {{ stats.overall.materialOmittedDatapoints }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="OtherStatsBoxControls">
                              <div *ngIf="!showOtherStatsBox" class="showOtherStatsBox control" (click)="showOtherStats()">
                                {{ slocale("Show all topics progress") }}
                              </div>
                              <div *ngIf="showOtherStatsBox" class="hideOtherStatsBox control" (click)="hideOtherStats()">
                                {{ slocale("Hide all topics progress") }}
                              </div>
                            </div>
                            <div class="otherStats" *ngIf="showOtherStatsBox">
                              <div class="statRow">
                                <div class="statRowTitle crosscutting">{{ slocale("CROSS-CUTTING") }}</div>
                                <div class="statRowStatlist">
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Overall collected progress (OCP)") }}</div>
                                    <div class="statValue">
                                      0%
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Total data points (TDP)") }}</div>
                                    <div class="statValue">
                                      {{ stats.crosscutting.totalDatapoints }}
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Material (M)") }}</div>
                                    <div class="statValue">
                                      {{ stats.crosscutting.materialDatapoints }}
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Voluntary (VOL)") }}</div>
                                    <div class="statValue">
                                      {{ stats.crosscutting.nonMaterialVoluntaryDatapoints }}
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Not material (NM)") }}</div>
                                    <div class="statValue">
                                      {{ stats.crosscutting.nonMaterialDatapoints }}
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Omitted (OM)") }}</div>
                                    <div class="statValue">
                                      {{ stats.crosscutting.materialOmittedDatapoints }}
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div class="statRow">
                                <div class="statRowTitle environmental">{{ slocale("ENVIRONMENTAL") }}</div>
                                <div class="statRowStatlist">
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Overall collected progress (OCP)") }}</div>
                                    <div class="statValue">
                                      0%
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Total data points (TDP)") }}</div>
                                    <div class="statValue">
                                      {{ stats.environmental.totalDatapoints }}
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Material (M)") }}</div>
                                    <div class="statValue">
                                      {{ stats.environmental.materialDatapoints }}
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Voluntary (VOL)") }}</div>
                                    <div class="statValue">
                                      {{ stats.environmental.nonMaterialVoluntaryDatapoints }}
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Not material (NM)") }}</div>
                                    <div class="statValue">
                                      {{ stats.environmental.nonMaterialDatapoints }}
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Omitted (OM)") }}</div>
                                    <div class="statValue">
                                      {{ stats.environmental.materialOmittedDatapoints }}
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div class="statRow">
                                <div class="statRowTitle social">{{ slocale("SOCIAL") }}</div>
                                <div class="statRowStatlist">
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Overall collected progress (OCP)") }}</div>
                                    <div class="statValue">
                                      0%
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Total data points (TDP)") }}</div>
                                    <div class="statValue">
                                      {{ stats.social.totalDatapoints }}
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Material (M)") }}</div>
                                    <div class="statValue">
                                      {{ stats.social.materialDatapoints }}
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Voluntary (VOL)") }}</div>
                                    <div class="statValue">
                                      {{ stats.social.nonMaterialVoluntaryDatapoints }}
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Not material (NM)") }}</div>
                                    <div class="statValue">
                                      {{ stats.social.nonMaterialDatapoints }}
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Omitted (OM)") }}</div>
                                    <div class="statValue">
                                      {{ stats.social.materialOmittedDatapoints }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="statRow">
                                <div class="statRowTitle governance">{{ slocale("GOVERNANCE") }}</div>
                                <div class="statRowStatlist">
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Overall collected progress (OCP)") }}</div>
                                    <div class="statValue">
                                      0%
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Total data points (TDP)") }}</div>
                                    <div class="statValue">
                                      {{ stats.governance.totalDatapoints }}
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Material (M)") }}</div>
                                    <div class="statValue">
                                      {{ stats.governance.materialDatapoints }}
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Voluntary (VOL)") }}</div>
                                    <div class="statValue">
                                      {{ stats.governance.nonMaterialVoluntaryDatapoints }}
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Not material (NM)") }}</div>
                                    <div class="statValue">
                                      {{ stats.governance.nonMaterialDatapoints }}
                                    </div>
                                  </div>
                                  <div class="stat">
                                    <div class="statName">{{ slocale("Omitted (OM)") }}</div>
                                    <div class="statValue">
                                      {{ stats.governance.materialOmittedDatapoints }}
                                    </div>
                                  </div>
                                </div>

                              </div>

                            </div>
                          </div>

                          <!-- <a class="d-flex flex-row justify-content-between align-items-center w-100 px-3 bg-white">
                              <div class="m-0 p-0">
                                <h6 class="mb-0 pb-0 text-wrap bold">{{ slocale("Data collection") }}</h6>
                              </div>
                              <div class="m-0 p-0 d-flex flex-row justify-content-end align-items-center flex-grow-1">
                                <input type="text" class="form-control me-1 w-auto input-small" placeholder="{{ locale('locale_key.general.search') }}" (input)="filter(search.value)" #search />
                                <a class="underline my-auto mx-2 cursor" (click)="expandAll()">{{ locale("locale_key.pages.kpi_library.button.expand_all") }} </a>
                                <a class="underline my-auto me-2 cursor" (click)="collapseAll()">{{ locale("locale_key.pages.kpi_library.button.collapse_all") }} </a>
                                <button class="btn primary-button custom-button text-white">{{ slocale("Start") }}</button>
                                <a class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></a>
                              </div>
                            </a> -->
                          <div id="csrdTopics" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div class="accordion-body p-0 pb-3">
                              <ng-container *ngFor="let topic of filteredCsrdDefinition">
                                <div class="bg-light-gray p-3 p-sm-1 mx-0">
                                  <h6 class="mb-0 pb-0">{{ topic.name }}</h6>
                                </div>
                                <div class="accordion w-100 px-2" [id]="topic.name+'_accordion'">
                                  <ng-container *ngFor="let esrs of topic.esrs">
                                    <div class="accordion-item">


                                      <!-- MANDATORY ESRS HEADER -->
                                      <ng-container *ngIf="!esrs.isAssessed && esrs.code == 'ESRS 2'">
                                        <div [class]="'esrsHeader ' + topic.id">
                                          <div class="esrsCode">{{ esrs.code }}</div>
                                          <div class="esrsName">{{ esrs.name }}</div>
                                          <div class="esrsBadge">
                                            <span class="badge-mandatory ms-2" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">{{ slocale("Mandatory") }}</span>
                                          </div>
                                          <div class="esrsControl">
                                            <button class="btn primary-button text-white ms-auto me-2 custom-button" data-bs-toggle="collapse" (click)="toggleEsrsOpen(esrs)">{{ slocale("Start") }}</button>
                                          </div>
                                        </div>
                                      </ng-container>

                                      <!-- NOT ASSESSED ESRS HEADER -->
                                      <ng-container *ngIf="!esrs.isAssessed && esrs.code != 'ESRS 2'">
                                        <div [class]="'esrsHeader ' + topic.id">
                                          <div class="esrsCode">{{ esrs.code }}</div>
                                          <div class="esrsName">{{ esrs.name }}</div>
                                          <div class="esrsBadge">
                                            <span class="badge-not-assessed ms-2" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">{{ slocale("Not assessed") }}</span>
                                          </div>
                                          <div class="esrsControl">
                                            <button class="btn primary-button text-white ms-auto me-2 custom-button defineMaterialityBtn" (click)="defineMateriality({esrs, topic})">{{ slocale("Define") }}</button>
                                          </div>
                                        </div>
                                      </ng-container>


                                      <!-- ASSESSED ESRS HEADER -->
                                      <ng-container *ngIf="esrs.isAssessed">
                                        <div [class]="'assessed esrsHeader ' + topic.id" (click)="toggleEsrsOpen(esrs)">
                                          <div class="info">
                                            <div class="esrsCode">{{ esrs.code }}</div>
                                            <div class="esrsName">{{ esrs.name }}</div>
                                            <div class="esrsBadge">
                                              <ng-container *ngIf="esrs.isMaterial && !esrs.isMandatory">
                                                <span class="badge-material ms-2" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">{{ slocale("Material") }}</span>
                                              </ng-container>
                                              <ng-container *ngIf="!esrs.isMaterial && !esrs.isMandatory">
                                                <span class="badge-not-material ms-2" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">{{ slocale("Not material") }}</span>
                                              </ng-container>
                                              <ng-container *ngIf="esrs.isMandatory">
                                                <span class="badge-mandatory ms-2" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">{{ slocale("Mandatory") }}</span>
                                              </ng-container>
                                            </div>
                                          </div>

                                          <div class="statsBox">
                                            <div class="stat">
                                              <div class="statName">{{ slocale("OCP") }}</div>
                                              <div class="statValue">0%</div>
                                            </div>
                                            <div class="stat">
                                              <div class="statName">{{ slocale("TDP") }}</div>
                                              <div class="statValue" *ngIf="stats.esrs[esrs.id]; else noData">{{ stats.esrs[esrs.id].totalDatapoints }}</div>
                                            </div>
                                            <div class="stat">
                                              <div class="statName">{{ slocale("M") }}</div>
                                              <div class="statValue" *ngIf="stats.esrs[esrs.id]; else noData">{{ stats.esrs[esrs.id].materialDatapoints }}</div>
                                            </div>
                                            <div class="stat">
                                              <div class="statName">{{ slocale("VOL") }}</div>
                                              <div class="statValue" *ngIf="stats.esrs[esrs.id]; else noData">{{ stats.esrs[esrs.id].nonMaterialVoluntaryDatapoints }}</div>
                                            </div>
                                            <div class="stat">
                                              <div class="statName">{{ slocale("NM") }}</div>
                                              <div class="statValue" *ngIf="stats.esrs[esrs.id]; else noData">{{ stats.esrs[esrs.id].nonMaterialDatapoints }}</div>
                                            </div>
                                            <div class="stat">
                                              <div class="statName">{{ slocale("OM") }}</div>
                                              <div class="statValue" *ngIf="stats.esrs[esrs.id]; else noData">{{ stats.esrs[esrs.id].materialOmittedDatapoints }}</div>
                                            </div>
                                            <ng-template #noData>
                                              <div class="statValue">0</div>
                                            </ng-template>
                                            <div class="esrsControl">
                                              <a class="accordion-button" [class.collapsed]="!this.esrsOpen.has(esrs.id)" type="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="collapseTwo"></a>
                                            </div>
                                          </div>
                                        </div>
                                      </ng-container>

                                      <div [id]="'collapse_' + esrs.id" class="accordion-collapse collapse" [class.show]="esrsOpen.has(esrs.id)" data-bs-parent="#accordionExample1">
                                        <div class="accordion-body p-0 bg-comp-grey p-2 scroll-y">
                                          <div class="d-flex flex-row justify-content-between align-items-center px-3 py-2 bg-white my-2">
                                            <div class="d-flex flex-row align-items-center">
                                              <h6 class="mb-0 pb-0 d-flex gap-1">
                                                {{ slocale("Topic was defined as ") }}
                                                <span *ngIf="esrs.code == 'ESRS 2'" class="mandatory d-none d-lg-block d-xl-block">{{ slocale("Mandatory") }}</span>
                                                <span *ngIf="esrs.code != 'ESRS 2' && esrs.isMaterial" class="material d-none d-lg-block d-xl-block">{{ slocale("Material") }}</span>
                                                <span *ngIf="esrs.code != 'ESRS 2' && !esrs.isMaterial" class="not-material d-none d-lg-block d-xl-block">{{ slocale("Not Material") }}</span>
                                              </h6>
                                            </div>
                                            <div class="d-flex flex-row align-items-center">
                                              <ng-container *ngIf="esrs.code != 'ESRS 2'">
                                                <span class="blue-text mx-1">{{ esrs.selectedSubTopics.length }}</span>
                                                <h6 class="mb-0 pb-0 d-inline">{{ slocale("Sub topics & ") }}</h6>
                                                <span class="blue-text mx-1">{{ esrs.selectedSubSubTopics.length }}</span>
                                                <span class="">{{ slocale("Sub-sub topics") }}</span>
                                                <div class="dropdown">
                                                  <a class="dropdown-toggle remove-arrow-drpdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="la la-arrows-alt ms-2"></i>
                                                  </a>
                                                  <div class="dropdown-menu p-2">
                                                    <span *ngIf="esrs.selectedSubTopics.length > 0" class="blue-text mx-2"> {{ esrs.selectedSubTopics.length }} </span><span>{{ slocale("Sub topics") }}</span>
                                                    <ul class="subtopics_list" *ngIf="esrs.selectedSubTopics.length > 0">
                                                      <ng-container *ngFor="let subtopic of esrs.selectedSubTopics">
                                                        <li>
                                                          <div class="dropdown-item">{{ subtopic }}</div>
                                                        </li>
                                                      </ng-container>
                                                    </ul>
                                                    <span *ngIf="esrs.selectedSubSubTopics.length > 0" class="blue-text mx-2">{{ esrs.selectedSubSubTopics.length }}</span><span>{{ slocale("Sub-sub topics") }}</span>
                                                    <ul class="subsubtopics_list" *ngIf="esrs.selectedSubSubTopics.length > 0">
                                                      <ng-container *ngFor="let subsubtopic of esrs.selectedSubSubTopics">
                                                        <li>
                                                          <div class="dropdown-item">{{ subsubtopic }}</div>
                                                        </li>
                                                      </ng-container>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </ng-container>
                                            </div>
                                            <div class="d-flex flex-row align-items-center">
                                              <span class="blue-text me-1">{{ esrs.disclosures.length + " " }}</span>
                                              <h6 class="mb-0 pb-0 d-inline">{{ slocale("Disclosure Requirements") }}</h6>
                                            </div>
                                            <div class="d-flex flex-row align-items-center">
                                              <ng-container *ngIf="esrs.code != 'ESRS 2'">
                                                <i class="cursor-pointer la la-edit size-15 me-2" (click)="defineMateriality({esrs, topic})"></i>
                                              </ng-container>
                                            </div>
                                          </div>
                                          <div class="bg-white w-100">
                                            <ul class="nav nav-tabs nav-underline no-hover-bg w-100 bg-transparent">
                                              <li class="nav-item bg-transparent">
                                                <a class="nav-link mb-0 pb-0" id="base-tab40" [class.active]="getActiveEsrsTab(esrs) == ESRS_TABS.DISCLOSURES" (click)="switchActiveEsrsTab({esrs, tab: ESRS_TABS.DISCLOSURES})"> {{
                                                  slocale("Disclosure
                                                  requirements") }}</a>
                                              </li>
                                              <!-- <li class="nav-item bg-transparent">
                                                <a class="nav-link mb-0 pb-0" id="base-tab41" [class.active]="getActiveEsrsTab(esrs) == ESRS_TABS.SUPER_USER_TASKS" (click)="switchActiveEsrsTab({esrs, tab:ESRS_TABS.SUPER_USER_TASKS})">{{
                                                  slocale("Superuser
                                                  tasks overview") }}</a>
                                              </li> -->
                                              <li class="nav-item bg-transparent">
                                                <a class="nav-link mb-0 pb-0" id="base-tab42" [class.active]="getActiveEsrsTab(esrs) == ESRS_TABS.DATA_REQUESTS" (click)="switchActiveEsrsTab({esrs, tab:ESRS_TABS.DATA_REQUESTS})">{{
                                                  slocale("Data request
                                                  progress") }}</a>
                                              </li>
                                            </ul>
                                            <div class="tab-content px-1 pt-1 bg-white">
                                              <div role="tabpanel" class="tab-pane p-2 scroll-y h-100" id="disclosureReq" [class.active]="getActiveEsrsTab(esrs) == ESRS_TABS.DISCLOSURES" aria-labelledby="base-disclosureReq">

                                                <ng-container *ngFor="let disclosure of esrs.disclosures">

                                                  <!-- DISCLOSURE HEADER -->
                                                  <div class="disclosureHeader">
                                                    <div class="disclosureCodeAndName">
                                                      <div class="disclosureCode">{{ disclosure.code + ' - ' }}</div>
                                                      <div class="disclosureName">{{ disclosure.name }}</div>
                                                    </div>
                                                    <div class="statsBox" (click)="viewDr({ disclosure, esrs, topic })">
                                                      <div class="stat">
                                                        <div class="statName">{{ slocale("OCP") }}</div>
                                                        <div class="statValue">0%</div>
                                                      </div>
                                                      <div class="stat">
                                                        <div class="statName">{{ slocale("TDP") }}</div>
                                                        <div *ngIf="!showDisclosureStats(disclosure)" class="statValue">0</div>
                                                        <div *ngIf="showDisclosureStats(disclosure)" class="statValue">{{
                                                          stats.esrs[esrs.id].disclosure[disclosure.id].totalDatapoints }}</div>
                                                      </div>
                                                      <div class="stat">
                                                        <div class="statName">{{ slocale("M") }}</div>
                                                        <div *ngIf="!showDisclosureStats(disclosure)" class="statValue">0</div>
                                                        <div *ngIf="showDisclosureStats(disclosure)" class="statValue">{{
                                                          stats.esrs[esrs.id].disclosure[disclosure.id].materialDatapoints }}</div>
                                                      </div>
                                                      <div class="stat">
                                                        <div class="statName">{{ slocale("VOL") }}</div>
                                                        <div *ngIf="!showDisclosureStats(disclosure)" class="statValue">0</div>
                                                        <div *ngIf="showDisclosureStats(disclosure)" class="statValue">{{
                                                          stats.esrs[esrs.id].disclosure[disclosure.id].nonMaterialVoluntaryDatapoints }}</div>
                                                      </div>
                                                      <div class="stat">
                                                        <div class="statName">{{ slocale("NM") }}</div>
                                                        <div *ngIf="!showDisclosureStats(disclosure)" class="statValue">0</div>
                                                        <div *ngIf="showDisclosureStats(disclosure)" class="statValue">{{
                                                          stats.esrs[esrs.id].disclosure[disclosure.id].nonMaterialDatapoints }}</div>
                                                      </div>
                                                      <div class="stat">
                                                        <div class="statName">{{ slocale("OM") }}</div>
                                                        <div *ngIf="!showDisclosureStats(disclosure)" class="statValue">0</div>
                                                        <div *ngIf="showDisclosureStats(disclosure)" class="statValue">{{
                                                          stats.esrs[esrs.id].disclosure[disclosure.id].materialOmittedDatapoints }}</div>
                                                      </div>
                                                      <div class="disclosureStatus">
                                                        <span *ngIf="disclosure.status == DR_STATUSES.NOT_STARTED" class="disclosureStatusBadge notstarted">{{ slocale("Not-started") }}</span>
                                                        <span *ngIf="disclosure.status == DR_STATUSES.ASSESSING" class="disclosureStatusBadge assessing">{{ slocale("Assessing") }}</span>
                                                        <span *ngIf="disclosure.status == DR_STATUSES.ASSESSED" class="disclosureStatusBadge assessed">{{ slocale("Assessed") }}</span>
                                                        <span *ngIf="disclosure.status == DR_STATUSES.DRAFTING" class="disclosureStatusBadge drafting">{{ slocale("Drafting") }}</span>
                                                        <span *ngIf="disclosure.status == DR_STATUSES.COLLECTING" class="disclosureStatusBadge collecting">{{ slocale("Collecting") }}</span>
                                                        <span *ngIf="disclosure.status == DR_STATUSES.COMPLETED" class="disclosureStatusBadge completed">{{ slocale("Completed") }}</span>
                                                      </div>
                                                      <div class="disclosureControl">
                                                        <i class="la la-arrow-right size-15"></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </ng-container>

                                                <!-- THESE DESIGNS ARE NEEDED FOR PHASE 3 -->
                                                <!-- <div class="d-flex flex-row justify-content-between align-items-center mb-2 border ps-2">
                                                  <div class="col-8 d-flex flex-column justify-content-start align-items-start">
                                                    <h6 class="mb-0 pb-0">{{ slocale("E3-1 – Policies related to water and marine resources") }}</h6>
                                                  </div>
                                                  <div class="col-4 p-2 m-0 thin-border-l" style="background-color: #f8fbfb">
                                                    <div class="d-flex flex-row justify-content-between align-items-center w-100 px-2">
                                                      <div class="d-flex flex-row flex-wrap">
                                                        <div class="d-flex flex-column">
                                                          <small class="text-muted">{{ slocale("Collected") }}</small>
                                                          <h5 class="">0%</h5>
                                                        </div>
                                                        <div class="d-flex flex-column ms-2">
                                                          <small class="text-muted">{{ slocale("Final answer") }}</small>
                                                          <h5 class="">0%</h5>
                                                        </div>
                                                      </div>
                                                      <span class="badge badge-green d-none d-lg-block d-xl-block">{{ slocale("Completed") }}</span>
                                                      <a class=""><i class="la la-arrow-right size-15"></i></a>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="d-flex flex-row justify-content-between align-items-center mb-2 border ps-2">
                                                  <div class="col-8 d-flex flex-column justify-content-start align-items-start">
                                                    <h6 class="mb-0 pb-0">{{ slocale("E3-1 – Policies related to water and marine resources") }}</h6>
                                                  </div>
                                                  <div class="col-4 p-2 m-0 thin-border-l" style="background-color: #f8fbfb">
                                                    <div class="d-flex flex-row justify-content-between align-items-center w-100 px-2">
                                                      <div class="d-flex flex-row flex-wrap">
                                                        <div class="d-flex flex-column">
                                                          <small class="text-muted">{{ slocale("Collected") }}</small>
                                                          <h5 class="">0%</h5>
                                                        </div>
                                                        <div class="d-flex flex-column ms-2">
                                                          <small class="text-muted">{{ slocale("Final answer") }}</small>
                                                          <h5 class="">0%</h5>
                                                        </div>
                                                      </div>
                                                      <span class="badge badge-gray d-none d-lg-block d-xl-block">{{ slocale("Not-started") }}</span>
                                                      <a class=""><i class="la la-arrow-right size-15"></i></a>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="d-flex flex-row justify-content-between align-items-center mb-2 border ps-2">
                                                  <div class="col-8 d-flex flex-column justify-content-start align-items-start">
                                                    <h6 class="mb-0 pb-0">{{ slocale("E3-1 – Policies related to water and marine resources") }}</h6>
                                                  </div>
                                                  <div class="col-4 p-2 m-0 thin-border-l" style="background-color: #f8fbfb">
                                                    <div class="d-flex flex-row justify-content-between align-items-center w-100 px-2">
                                                      <div class="d-flex flex-row flex-wrap">
                                                        <div class="d-flex flex-column">
                                                          <small class="text-muted">{{ slocale("Collected") }}</small>
                                                          <h5 class="">0%</h5>
                                                        </div>
                                                        <div class="d-flex flex-column ms-2">
                                                          <small class="text-muted">{{ slocale("Final answer") }}</small>
                                                          <h5 class="">0%</h5>
                                                        </div>
                                                      </div>
                                                      <span class="badge badge-orange d-none d-lg-block d-xl-block">{{ slocale("Progress") }}</span>
                                                      <a class=""><i class="la la-arrow-right size-15"></i></a>
                                                    </div>
                                                  </div>
                                                </div> -->
                                              </div>
                                              <div class="tab-pane h-100 scroll-y p-2" id="superuserTO" [class.active]="getActiveEsrsTab(esrs) == ESRS_TABS.SUPER_USER_TASKS" aria-labelledby="base-superuserTO">
                                                <span class="mx-auto text-muted">{{ slocale("No superuser tasks to show yet.") }}</span>
                                              </div>
                                              <div class="tab-pane h-100 scroll-y p-2" id="drOverview" [class.active]="getActiveEsrsTab(esrs) == ESRS_TABS.DATA_REQUESTS" aria-labelledby="base-drOverview">
                                                <span class="mx-auto text-muted">{{ slocale("Sent Data Requests for this standup will show up in this section") }}</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </ng-container>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- THESE DESIGNS ARE NEEDED FOR PHASE 3 -->
                    <!-- <div class="bg-white d-flex flex-row justify-content-between align-items-center mt-2">
                      -- SPIKE :: 2 --
                      <div class="accordion w-100" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <a class="accordion-button d-flex flex-row justify-content-between align-items-center w-100 px-3 bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                              aria-controls="collapseOne">
                              <div class="m-0 p-0">
                                <h6 class="mb-0 pb-0 text-wrap">{{ slocale("Data collection") }}</h6>
                              </div>
                              <div class="m-0 p-0 d-flex flex-row justify-content-end align-items-center flex-grow-1">
                                <input type="text" class="form-control me-1 w-auto input-small" placeholder="{{ locale('locale_key.general.search') }}" (input)="filter(search.value)" #search />
                                <a class="underline my-auto mx-2 cursor" (click)="expandAll()">{{ locale("locale_key.pages.kpi_library.button.expand_all") }} </a>
                                <a class="underline my-auto me-2 cursor" (click)="collapseAll()">{{ locale("locale_key.pages.kpi_library.button.collapse_all") }} </a>
                              </div>
                            </a>
                          </h2>
                          <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div class="accordion-body p-0 pb-3">
                              <div class="bg-light-gray p-3 p-sm-1 mx-0">
                                <h6 class="mb-0 pb-0">{{ slocale("Cross-cutting topics") }}</h6>
                              </div>
                              <div class="accordion w-100 px-2" id="accordionExample1">
                                <div class="accordion-item">
                                  <h2 class="accordion-header">
                                    <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white gray-border-l" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                      aria-expanded="false" aria-controls="collapseTwo">
                                      <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                        <h6 class="mb-0 pb-0">{{ slocale("ESRS 2") }}</h6>
                                        <span class="badge-mandatory ms-2" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">{{ slocale("Mandatory") }}</span>
                                      </div>
                                      <div class="d-flex flex-row justify-content-end align-items-center" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">
                                        <div class="d-flex flex-row">
                                          <small class="mb-0 pb-0 me-2 text-muted">{{ slocale("Collected") }}</small><span class="bold me-1">0%</span>
                                        </div>
                                        <div class="d-flex flex-row">
                                          <small class="mb-0 pb-0 me-2 text-muted">{{ slocale("Final answer") }}</small><span class="bold me-1">0%</span>
                                        </div>
                                      </div>
                                    </a>
                                  </h2>
                                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                    <div class="accordion-body p-0 bg-comp-grey p-2 scroll-y">
                                      <div class="d-flex flex-row justify-content-between align-items-center px-3 py-2 bg-white my-2">
                                        <div class="d-flex flex-row align-items-center">
                                          <h6 class="mb-0 pb-0 d-inline">
                                            {{ slocale("Topic was defined as ") }} <span class="material d-none d-lg-block d-xl-block">{{ slocale("Material") }}</span>
                                          </h6>
                                          <span class="badge-mandatory ms-2 d-none d-lg-block d-xl-block">{{ slocale("Mandatory") }}</span>
                                        </div>
                                        <div class="d-flex flex-row align-items-center">
                                          <span class="blue-text mx-1">{{ slocale("2 ") }}</span>
                                          <h6 class="mb-0 pb-0 d-inline">{{ slocale("Sub topics & ") }}</h6>
                                          <span class="blue-text mx-1">{{ slocale("2 ") }}</span>
                                          <span class="">{{ slocale("Sub-sub topics") }}</span>
                                          <div class="dropdown">
                                            <a class="dropdown-toggle remove-arrow-drpdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                              <i class="la la-arrows-alt ms-2"></i>
                                            </a>
                                            <div class="dropdown-menu p-2">
                                              <span class="blue-text mx-2">2</span><span>{{ slocale("Sub topics") }}</span>
                                              <ul>
                                                <li>
                                                  <a class="dropdown-item">{{ slocale("Water") }}</a>
                                                </li>
                                                <li>
                                                  <a class="dropdown-item">{{ slocale("Marine resources") }}</a>
                                                </li>
                                              </ul>
                                              <span class="blue-text mx-2">2</span><span>{{ slocale("Sub-sub topics") }}</span>
                                              <ul>
                                                <li>
                                                  <a class="dropdown-item">{{ slocale("Water consumption") }}</a>
                                                </li>
                                                <li>
                                                  <a class="dropdown-item">{{ slocale("Extraction and use of marine resources") }}</a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="d-flex flex-row align-items-center">
                                          <span class="blue-text me-1">{{ slocale("5 ") }}</span>
                                          <h6 class="mb-0 pb-0 d-inline">{{ slocale("Disclosure Requirements") }}</h6>
                                        </div>
                                        <div class="d-flex flex-row align-items-center">
                                          <i class="la la-edit size-15 me-2"></i>
                                        </div>
                                      </div>
                                      <div class="bg-white w-100">
                                        <ul class="nav nav-tabs nav-underline no-hover-bg w-100 bg-transparent">
                                          <li class="nav-item bg-transparent">
                                            <a class="nav-link mb-0 pb-0" id="base-tab40" [class.active]="activeTab === 'disclosureReq'" (click)="selectTab('disclosureReq')"> {{ slocale("Disclosure requirements") }}</a>
                                          </li>
                                          <li class="nav-item bg-transparent">
                                            <a class="nav-link mb-0 pb-0" id="base-tab41" [class.active]="activeTab === 'superuserTO'" (click)="selectTab('superuserTO')">{{ slocale("Superuser tasks overview") }}</a>
                                          </li>
                                          <li class="nav-item bg-transparent">
                                            <a class="nav-link mb-0 pb-0" id="base-tab42" [class.active]="activeTab === 'drOverview'" (click)="selectTab('drOverview')">{{ slocale("Data request overview") }}</a>
                                          </li>
                                        </ul>
                                        <div class="tab-content px-1 pt-1 bg-white">
                                          <div role="tabpanel" class="tab-pane p-2 scroll-y h-100" id="disclosureReq" [class.active]="activeTab === 'disclosureReq'" aria-labelledby="base-disclosureReq">
                                            <div class="d-flex flex-row justify-content-between align-items-center mb-2 border ps-2">
                                              <div class="col-8 d-flex flex-column justify-content-start align-items-start">
                                                <h6 class="mb-0 pb-0">{{ slocale("E3-1 – Policies related to water and marine resources") }}</h6>
                                              </div>
                                              <div class="col-4 p-2 m-0 thin-border-l" style="background-color: #f8fbfb">
                                                <div class="d-flex flex-row justify-content-between align-items-center w-100 px-2">
                                                  <div class="d-flex flex-row flex-wrap">
                                                    <div class="d-flex flex-column">
                                                      <small class="text-muted">{{ slocale("Collected") }}</small>
                                                      <h5 class="">0%</h5>
                                                    </div>
                                                    <div class="d-flex flex-column ms-2">
                                                      <small class="text-muted">{{ slocale("Final answer") }}</small>
                                                      <h5 class="">0%</h5>
                                                    </div>
                                                  </div>
                                                  <span class="badge badge-green d-none d-lg-block d-xl-block">{{ slocale("Completed") }}</span>
                                                  <a class=""><i class="la la-arrow-right size-15"></i></a>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="d-flex flex-row justify-content-between align-items-center mb-2 border ps-2">
                                              <div class="col-8 d-flex flex-column justify-content-start align-items-start">
                                                <h6 class="mb-0 pb-0">{{ slocale("E3-1 – Policies related to water and marine resources") }}</h6>
                                              </div>
                                              <div class="col-4 p-2 m-0 thin-border-l" style="background-color: #f8fbfb">
                                                <div class="d-flex flex-row justify-content-between align-items-center w-100 px-2">
                                                  <div class="d-flex flex-row flex-wrap">
                                                    <div class="d-flex flex-column">
                                                      <small class="text-muted">{{ slocale("Collected") }}</small>
                                                      <h5 class="">0%</h5>
                                                    </div>
                                                    <div class="d-flex flex-column ms-2">
                                                      <small class="text-muted">{{ slocale("Final answer") }}</small>
                                                      <h5 class="">0%</h5>
                                                    </div>
                                                  </div>
                                                  <span class="badge badge-gray d-none d-lg-block d-xl-block">{{ slocale("Not-started") }}</span>
                                                  <a class=""><i class="la la-arrow-right size-15"></i></a>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="d-flex flex-row justify-content-between align-items-center mb-2 border ps-2">
                                              <div class="col-8 d-flex flex-column justify-content-start align-items-start">
                                                <h6 class="mb-0 pb-0">{{ slocale("E3-1 – Policies related to water and marine resources") }}</h6>
                                              </div>
                                              <div class="col-4 p-2 m-0 thin-border-l" style="background-color: #f8fbfb">
                                                <div class="d-flex flex-row justify-content-between align-items-center w-100 px-2">
                                                  <div class="d-flex flex-row flex-wrap">
                                                    <div class="d-flex flex-column">
                                                      <small class="text-muted">{{ slocale("Collected") }}</small>
                                                      <h5 class="">0%</h5>
                                                    </div>
                                                    <div class="d-flex flex-column ms-2">
                                                      <small class="text-muted">{{ slocale("Final answer") }}</small>
                                                      <h5 class="">0%</h5>
                                                    </div>
                                                  </div>
                                                  <span class="badge badge-orange d-none d-lg-block d-xl-block">{{ slocale("Progress") }}</span>
                                                  <a class=""><i class="la la-arrow-right size-15"></i></a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="tab-pane h-100 scroll-y p-2" id="superuserTO" [class.active]="activeTab === 'superuserTO'" aria-labelledby="base-superuserTO">
                                            <span class="mx-auto text-muted">{{ slocale("No superuser tasks with that name found.") }}</span>
                                          </div>
                                          <div class="tab-pane h-100 scroll-y p-2" id="drOverview" [class.active]="activeTab === 'drOverview'" aria-labelledby="base-drOverview">
                                            <span class="mx-auto text-muted">{{ slocale("No data requests with that name found.") }}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="bg-light-gray p-3 p-sm-1 mx-0 mt-2">
                                <h6 class="mb-0 pb-0">{{ slocale("Environmental topics") }}</h6>
                              </div>
                              <div class="accordion w-100 px-2" id="accordionExample1">
                                <div class="accordion-item">
                                  <h2 class="accordion-header">
                                    <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white green-border-l" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                      aria-expanded="false" aria-controls="collapseTwo">
                                      <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                        <h6 class="mb-0 pb-0">{{ slocale("ESRS E1") }}</h6>
                                        <span class="ms-2">{{ slocale("Climate Change") }}</span>
                                        <span class="badge-material ms-2" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">{{ slocale("Material") }}</span>
                                      </div>
                                      <div class="d-flex flex-row justify-content-end align-items-center" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">
                                        <div class="d-flex flex-row">
                                          <small class="mb-0 pb-0 me-2 text-muted">{{ slocale("Collected") }}</small><span class="bold me-1">0%</span>
                                        </div>
                                        <div class="d-flex flex-row">
                                          <small class="mb-0 pb-0 me-2 text-muted">{{ slocale("Final answer") }}</small><span class="bold me-1">0%</span>
                                        </div>
                                      </div>
                                    </a>
                                  </h2>
                                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                    <div class="accordion-body p-0 bg-comp-grey">
                                      <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                        <p>Add data to start calculating GHG emissions for this emission category.</p>
                                        <button class="btn tertiary-button custom-button"><i class="la la-plus-circle me-2 size-15 bold"></i>Add data</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header">
                                    <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white green-border-l" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                      aria-expanded="false" aria-controls="collapseTwo">
                                      <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                        <h6 class="mb-0 pb-0">{{ slocale("ESRS E2") }}</h6>
                                        <span class="ms-2">{{ slocale("Pollution") }}</span>
                                        <span class="badge-not-material ms-2" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">{{ slocale("Not material") }}</span>
                                      </div>
                                      <div class="d-flex flex-row justify-content-end align-items-center" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">
                                        <div class="d-flex flex-row">
                                          <small class="mb-0 pb-0 me-2 text-muted">{{ slocale("Collected") }}</small><span class="bold me-1">0%</span>
                                        </div>
                                        <div class="d-flex flex-row">
                                          <small class="mb-0 pb-0 me-2 text-muted">{{ slocale("Final answer") }}</small><span class="bold me-1">0%</span>
                                        </div>
                                      </div>
                                    </a>
                                  </h2>
                                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                    <div class="accordion-body p-0 bg-comp-grey">
                                      <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                        <p>Add data to start calculating GHG emissions for this emission category.</p>
                                        <button class="btn tertiary-button custom-button"><i class="la la-plus-circle me-2 size-15 bold"></i>Add data</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="bg-light-gray p-3 p-sm-1 mx-0 mt-2">
                                <h6 class="mb-0 pb-0">{{ slocale("Social topics") }}</h6>
                              </div>
                              <div class="accordion w-100 px-2" id="accordionExample1">
                                <div class="accordion-item">
                                  <h2 class="accordion-header">
                                    <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white yellow-border-l" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                      aria-expanded="false" aria-controls="collapseTwo">
                                      <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                        <h6 class="mb-0 pb-0">{{ slocale("ESRS S1") }}</h6>
                                        <span class="ms-2">{{ slocale("Own workforce") }}</span>
                                        <span class="badge-material ms-2" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">{{ slocale("Material") }}</span>
                                      </div>
                                      <div class="d-flex flex-row justify-content-end align-items-center" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">
                                        <div class="d-flex flex-row">
                                          <small class="mb-0 pb-0 me-2 text-muted">{{ slocale("Collected") }}</small><span class="bold me-1">0%</span>
                                        </div>
                                        <div class="d-flex flex-row">
                                          <small class="mb-0 pb-0 me-2 text-muted">{{ slocale("Final answer") }}</small><span class="bold me-1">0%</span>
                                        </div>
                                      </div>
                                    </a>
                                  </h2>
                                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                    <div class="accordion-body p-0 bg-comp-grey">
                                      <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                        <p>Add data to start calculating GHG emissions for this emission category.</p>
                                        <button class="btn tertiary-button custom-button"><i class="la la-plus-circle me-2 size-15 bold"></i>Add data</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header">
                                    <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white yellow-border-l" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                      aria-expanded="false" aria-controls="collapseTwo">
                                      <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                        <h6 class="mb-0 pb-0">{{ slocale("ESRS S2") }}</h6>
                                        <span class="ms-2">{{ slocale("Affected communities") }}</span>
                                        <span class="badge-not-material ms-2" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">{{ slocale("Not material") }}</span>
                                      </div>
                                      <div class="d-flex flex-row justify-content-end align-items-center" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">
                                        <div class="d-flex flex-row">
                                          <small class="mb-0 pb-0 me-2 text-muted">{{ slocale("Collected") }}</small><span class="bold me-1">0%</span>
                                        </div>
                                        <div class="d-flex flex-row">
                                          <small class="mb-0 pb-0 me-2 text-muted">{{ slocale("Final answer") }}</small><span class="bold me-1">0%</span>
                                        </div>
                                      </div>
                                    </a>
                                  </h2>
                                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                    <div class="accordion-body p-0 bg-comp-grey">
                                      <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                        <p>Add data to start calculating GHG emissions for this emission category.</p>
                                        <button class="btn tertiary-button custom-button"><i class="la la-plus-circle me-2 size-15 bold"></i>Add data</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="bg-light-gray p-3 p-sm-1 mx-0 mt-2">
                                <h6 class="mb-0 pb-0">{{ slocale("Governance topics") }}</h6>
                              </div>
                              <div class="accordion w-100 px-2" id="accordionExample1">
                                <div class="accordion-item">
                                  <h2 class="accordion-header">
                                    <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white blue-border-l" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                      aria-expanded="false" aria-controls="collapseTwo">
                                      <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                        <h6 class="mb-0 pb-0">{{ slocale("ESRS G1") }}</h6>
                                        <span class="ms-2">{{ slocale("Business conduct") }}</span>
                                        <span class="badge-material ms-2" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">{{ slocale("Material") }}</span>
                                      </div>
                                      <div class="d-flex flex-row justify-content-end align-items-center" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">
                                        <div class="d-flex flex-row">
                                          <small class="mb-0 pb-0 me-2 text-muted">{{ slocale("Collected") }}</small><span class="bold me-1">0%</span>
                                        </div>
                                        <div class="d-flex flex-row">
                                          <small class="mb-0 pb-0 me-2 text-muted">{{ slocale("Final answer") }}</small><span class="bold me-1">0%</span>
                                        </div>
                                      </div>
                                    </a>
                                  </h2>
                                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                    <div class="accordion-body p-0 bg-comp-grey">
                                      <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                        <p>Add data to start calculating GHG emissions for this emission category.</p>
                                        <button class="btn tertiary-button custom-button"><i class="la la-plus-circle me-2 size-15 bold"></i>Add data</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header">
                                    <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-white blue-border-l" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                      aria-expanded="false" aria-controls="collapseTwo">
                                      <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                        <h6 class="mb-0 pb-0">{{ slocale("ESRS G2") }}</h6>
                                        <span class="ms-2">{{ slocale("Affected communities") }}</span>
                                        <span class="badge-not-material ms-2" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">{{ slocale("Not material") }}</span>
                                      </div>
                                      <div class="d-flex flex-row justify-content-end align-items-center" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">
                                        <div class="d-flex flex-row">
                                          <small class="mb-0 pb-0 me-2 text-muted">{{ slocale("Collected") }}</small><span class="bold me-1">0%</span>
                                        </div>
                                        <div class="d-flex flex-row">
                                          <small class="mb-0 pb-0 me-2 text-muted">{{ slocale("Final answer") }}</small><span class="bold me-1">0%</span>
                                        </div>
                                      </div>
                                    </a>
                                  </h2>
                                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                    <div class="accordion-body p-0 bg-comp-grey">
                                      <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                        <p>Add data to start calculating GHG emissions for this emission category.</p>
                                        <button class="btn tertiary-button custom-button"><i class="la la-plus-circle me-2 size-15 bold"></i>Add data</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="bg-white d-flex flex-row justify-content-between align-items-center mt-2">
                      -- SPIKE :: 3 --
                      <div class="accordion w-100" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <a class="accordion-button d-flex flex-row justify-content-between align-items-center w-100 px-3 bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                              aria-controls="collapseOne">
                              <div class="m-0 p-0">
                                <h6 class="mb-0 pb-0 text-wrap d-inline">{{ slocale("Data collection") }}</h6>
                                <select class="form-select w-auto ms-2 d-inline" aria-label="Default select example" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">
                                  <option selected>{{ slocale("Per topic") }}</option>
                                  <option value="1">{{ slocale("Cross-cutting") }}</option>
                                  <option value="2">{{ slocale("Environmental") }}</option>
                                  <option value="3">{{ slocale("Social") }}</option>
                                  <option value="4">{{ slocale("Governance") }}</option>
                                </select>
                              </div>
                              <div class="m-0 p-0 d-flex flex-row justify-content-end align-items-center flex-grow-1">
                                <input type="text" class="form-control me-1 w-auto input-small" placeholder="{{ locale('locale_key.general.search') }}" (input)="filter(search.value)" #search />
                                <a class="underline my-auto mx-2 cursor" (click)="expandAll()">{{ locale("locale_key.pages.kpi_library.button.expand_all") }} </a>
                                <a class="underline my-auto me-2 cursor" (click)="collapseAll()">{{ locale("locale_key.pages.kpi_library.button.collapse_all") }} </a>
                              </div>
                            </a>
                          </h2>
                          <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div class="accordion-body p-0 pb-3">
                              <div class="bg-light-gray p-3 p-sm-1 mx-0">
                                <h6 class="mb-0 pb-0">{{ slocale("Cross-cutting topics") }}</h6>
                              </div>
                              <div class="w-100 px-2">
                                <div class="accordion-item">
                                  <h2 class="accordion-header">
                                    <div class="px-3 py-2 d-flex flex-row justify-content-between align-content-center bg-white gray-border-l">
                                      <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                        <h6 class="mb-0 pb-0">{{ slocale("ESRS 2") }}</h6>
                                        <span class="badge-mandatory ms-2" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">{{ slocale("Mandatory") }}</span>
                                      </div>
                                      <div class="d-flex flex-row justify-content-end align-items-center">
                                        <button class="btn primary-button text-white ms-auto me-2 custom-button" (click)="openModal(csrdPprojectModal, 'modal-md')">{{ slocale("Start") }}</button>
                                      </div>
                                    </div>
                                  </h2>
                                </div>
                              </div>
                              <div class="bg-light-gray p-3 p-sm-1 mx-0 mt-2">
                                <h6 class="mb-0 pb-0">{{ slocale("Environmental topics") }}</h6>
                              </div>
                              <div class="w-100 px-2">
                                <div class="accordion-item">
                                  <h2 class="accordion-header">
                                    <div class="px-3 py-2 d-flex flex-row justify-content-between align-content-center bg-white green-border-l">
                                      <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                        <h6 class="mb-0 pb-0">{{ slocale("E3-4") }}</h6>
                                        <span class="fs-6 ms-2">{{ slocale("Water consumption") }}</span>
                                        <span class="badge-not-assessed ms-2" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">{{ slocale("Not assessed") }}</span>
                                      </div>
                                      <div class="d-flex flex-row justify-content-end align-items-center">
                                        <button class="btn primary-button text-white ms-auto me-2 custom-button defineMaterialityBtn" (click)="defineMateriality()"
                                        >{{ slocale("Define") }}</button>
                                      </div>
                                    </div>
                                  </h2>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header">
                                    <div class="px-3 py-2 d-flex flex-row justify-content-between align-content-center bg-white green-border-l">
                                      <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                        <h6 class="mb-0 pb-0">{{ slocale("ESRS E2") }}</h6>
                                        <span class="fs-6 ms-2">{{ slocale("Pollution") }}</span>
                                        <span class="badge-not-assessed ms-2" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">{{ slocale("Not assessed") }}</span>
                                      </div>
                                      <div class="d-flex flex-row justify-content-end align-items-center">
                                        <button class="btn primary-button text-white ms-auto me-2 custom-button defineMaterialityBtn" (click)="defineMateriality()">{{ slocale("Define") }}</button>
                                      </div>
                                    </div>
                                  </h2>
                                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                    <div class="accordion-body p-0 bg-comp-grey">
                                      <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                        <p>Add data to start calculating GHG emissions for this emission category.</p>
                                        <button class="btn tertiary-button custom-button"><i class="la la-plus-circle me-2 size-15 bold"></i>Add data</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="bg-light-gray p-3 p-sm-1 mx-0 mt-2">
                                <h6 class="mb-0 pb-0">{{ slocale("Social topics") }}</h6>
                              </div>
                              <div class="accordion w-100 px-2" id="accordionExample1">
                                <div class="accordion-item">
                                  <h2 class="accordion-header">
                                    <div class="px-3 py-2 d-flex flex-row justify-content-between align-content-center bg-white yellow-border-l">
                                      <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                        <h6 class="mb-0 pb-0">{{ slocale("ESRS S1") }}</h6>
                                        <span class="fs-6 ms-2">{{ slocale("Own workforce") }}</span>
                                        <span class="badge-not-assessed ms-2" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">{{ slocale("Not assessed") }}</span>
                                      </div>
                                      <div class="d-flex flex-row justify-content-end align-items-center">
                                        <button class="btn primary-button text-white ms-auto me-2 custom-button defineMaterialityBtn" (click)="defineMateriality()">{{ slocale("Define") }}</button>
                                      </div>
                                    </div>
                                  </h2>
                                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                    <div class="accordion-body p-0 bg-comp-grey">
                                      <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                        <p>Add data to start calculating GHG emissions for this emission category.</p>
                                        <button class="btn tertiary-button custom-button"><i class="la la-plus-circle me-2 size-15 bold"></i>Add data</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header">
                                    <div class="px-3 py-2 d-flex flex-row justify-content-between align-content-center bg-white yellow-border-l">
                                      <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                        <h6 class="mb-0 pb-0">{{ slocale("ESRS S2") }}</h6>
                                        <span class="fs-6 ms-2">{{ slocale("Affected communities") }}</span>
                                        <span class="badge-not-assessed ms-2" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">{{ slocale("Not assessed") }}</span>
                                      </div>
                                      <div class="d-flex flex-row justify-content-end align-items-center">
                                        <button class="btn primary-button text-white ms-auto me-2 custom-button defineMaterialityBtn" (click)="defineMateriality()">{{ slocale("Define") }}</button>
                                      </div>
                                    </div>
                                  </h2>
                                  <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                                    <div class="accordion-body p-0 bg-comp-grey">
                                      <div class="d-flex flex-column justify-content-center align-items-center py-3">
                                        <p>Add data to start calculating GHG emissions for this emission category.</p>
                                        <button class="btn tertiary-button custom-button"><i class="la la-plus-circle me-2 size-15 bold"></i>Add data</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="bg-light-gray p-3 p-sm-1 mx-0 mt-2">
                                <h6 class="mb-0 pb-0">{{ slocale("Governance topics") }}</h6>
                              </div>
                              <div class="accordion w-100 px-2" id="accordionExample1">
                                <div class="accordion-item">
                                  <h2 class="accordion-header">
                                    <div class="px-3 py-2 d-flex flex-row justify-content-between align-content-center bg-white blue-border-l">
                                      <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                        <h6 class="mb-0 pb-0">{{ slocale("ESRS G1") }}</h6>
                                        <span class="fs-6 ms-2">{{ slocale("Business conduct") }}</span>
                                        <span class="badge-not-assessed ms-2" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">{{ slocale("Not assessed") }}</span>
                                      </div>
                                      <div class="d-flex flex-row justify-content-end align-items-center">
                                        <button class="btn primary-button text-white ms-auto me-2 custom-button defineMaterialityBtn" (click)="defineMateriality()">{{ slocale("Define") }}</button>
                                      </div>
                                    </div>
                                  </h2>
                                </div>
                                <div class="accordion-item">
                                  <h2 class="accordion-header">
                                    <div class="px-3 py-2 d-flex flex-row justify-content-between align-content-center bg-white blue-border-l">
                                      <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                        <h6 class="mb-0 pb-0">{{ slocale("ESRS G2") }}</h6>
                                        <span class="fs-6 ms-2">{{ slocale("Affected communities") }}</span>
                                        <span class="badge-not-assessed ms-2" *ngIf="!screenSize.isSmallerSize() || !screenSize.isXSmallSize()">{{ slocale("Not assessed") }}</span>
                                      </div>
                                      <div class="d-flex flex-row justify-content-end align-items-center">
                                        <button class="btn primary-button text-white ms-auto me-2 custom-button defineMaterialityBtn" (click)="defineMateriality()">{{ slocale("Define") }}</button>
                                      </div>
                                    </div>
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->

                    <!-- TO DO : To be enabled in phase 2 -->
                    <!-- <div class="bg-white d-flex flex-row justify-content-between align-items-center mt-2">
                      <div class="accordion w-100 mt-2">
                        <div class="accordion-item py-1 bg-white">
                          <h2 class="accordion-header">
                            <a class="p-3 p-sm-1 d-flex flex-row justify-content-between align-content-center bg-white">
                              <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                                <h6 class="mb-0 pb-0">{{ slocale("Summary") }}</h6>
                              </div>
                              <div class="d-flex flex-row justify-content-end align-items-center my-auto">
                                <i class="la la-arrow-right bold size-15 my-auto"></i>
                              </div>
                            </a>
                          </h2>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </ng-container>
                <!-- SUPER USER PROGRESS PAGE-->
                <ng-container *ngIf="activeCsrdOverviewTab == CSRD_PROJECT_OVERVIEW_TABS.SUPER_USER_PROGRESS">
                  <div class="d-flex flex-column flex-md-row flex-sm-row w-100 py-2 px-1 bg-white">
                    <div class="d-flex flex-row justify-content-between align-items-center m-1 bg-comp-grey border rounded px-3 w-100 py-2">
                      <div class="d-flex flex-row justify-content-start align-items-center p-2">
                        <h6 class="mb-0 pb-0 d-inline">{{ slocale("TOTAL SUPERUSER TASKS") }}</h6>
                        <div class="icon-circle-pink-lg mx-2 my-auto"><span class="text-white bold d-inline">2</span></div>
                      </div>
                      <div class="d-flex flex-row justify-content-start align-items-center p-2">
                        <h6 class="mb-0 pb-0 d-inline">{{ slocale("COMPLETED SUPERUSER TASKS") }}</h6>
                        <h5 class="mb-0 pb-0 bold d-inline ms-2">0%</h5>
                      </div>
                    </div>
                    <div class="d-flex flex-row justify-content-between align-items-center m-1 bg-comp-grey border rounded px-3 w-100 py-2">
                      <div class="d-flex flex-row justify-content-start align-items-center p-2">
                        <h6 class="mb-0 pb-0 d-inline">{{ slocale("TOTAL SUPERUSER TASKS") }}</h6>
                        <div class="icon-circle-pink-lg mx-2 my-auto"><span class="text-white bold d-inline">2</span></div>
                      </div>
                      <div class="d-flex flex-row justify-content-start align-items-center p-2">
                        <h6 class="mb-0 pb-0 d-inline">{{ slocale("COMPLETED SUPERUSER TASKS") }}</h6>
                        <h5 class="mb-0 pb-0 bold d-inline ms-2">0%</h5>
                      </div>
                    </div>
                  </div>
                  <div class="bg-white d-flex flex-row justify-content-between align-items-center my-2">
                    <div class="accordion w-100" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <a class="accordion-button d-flex flex-row justify-content-between align-items-center w-100 px-3 bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                            aria-controls="collapseOne">
                            <div class="m-0 p-0 d-flex flex-row justify-content-between align-items-centern flex-wrap">
                              <h5 class="mb-0 pb-0 text-wrap bold me-2">{{ slocale("Data collection") }}</h5>
                              <span class="pt-1">{{ slocale("2 results found") }}</span>
                            </div>
                            <div class="m-0 p-0 d-flex flex-row align-items-center flex-grow-1 mx-2 flex-wrap">
                              <input type="text" class="form-control m-1 w-auto input-small d-none d-xl-block d-lg-block" placeholder="{{ locale('locale_key.general.search') }}" (input)="filter(search.value)" #search />
                              <div class="dropdown d-none d-xl-block flex-grow-1 m-1">
                                <a class="dropdown-toggle remove-arrow-drpdown form-control filter-button w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false"> <i class="la la-filter me-2"></i>{{ slocale("Filter") }} </a>
                                <div class="dropdown-menu w-100">
                                  <div class="accordion-body px-0 pt-0">
                                    <div class="bg-light-gray col p-3 p-sm-1">
                                      <h6>{{ slocale("Applied filters") }}</h6>
                                      <span class="text-muted">{{ slocale("None") }}</span>
                                    </div>
                                    <div class="d-flex flex-row justify-content-between align-items-center py-3 border flex-wrap px-1">
                                      <div class="d-flex flex-row">
                                        <label class="form-check-label me-2">
                                          <input type="checkbox" class="form-check-input mx-1" style="position: relative; bottom: 2px" />
                                          {{ slocale("Show all tasks") }}
                                        </label>
                                        <label class="form-check-label me-2">
                                          <input type="checkbox" class="form-check-input mx-1" style="position: relative; bottom: 2px" />
                                          {{ slocale("Show own tasks only") }}
                                        </label>
                                        <label class="form-check-label me-2">
                                          <input type="checkbox" class="form-check-input mx-1" style="position: relative; bottom: 2px" />
                                          {{ slocale("Show other superuser tasks only") }}
                                        </label>
                                      </div>
                                      <div class="d-flex flex-row">
                                        <a class="underline my-auto mx-2 cursor">{{ slocale("Clear") }} </a>
                                      </div>
                                    </div>
                                    <div class="d-flex flex-row justify-content-between align-items-center py-3 border flex-wrap px-1">
                                      <div class="d-flex flex-row">
                                        <label class="form-check-label me-2">
                                          <input type="checkbox" class="form-check-input mx-1" style="position: relative; bottom: 2px" />
                                          {{ slocale("Show only requests not started") }}
                                        </label>
                                        <label class="form-check-label me-2">
                                          <input type="checkbox" class="form-check-input mx-1" style="position: relative; bottom: 2px" />
                                          {{ slocale("Show only requests sent") }}
                                        </label>
                                        <label class="form-check-label">
                                          <input type="checkbox" class="form-check-input mx-1" style="position: relative; bottom: 2px" />
                                          {{ slocale("Show only completed requests") }}
                                        </label>
                                        <label class="form-check-label">
                                          <input type="checkbox" class="form-check-input mx-1" style="position: relative; bottom: 2px" />
                                          {{ slocale("Show only in progress requests") }}
                                        </label>
                                      </div>
                                      <div class="d-flex flex-row">
                                        <a class="underline my-auto mx-2 cursor">{{ slocale("Clear") }} </a>
                                      </div>
                                    </div>
                                    <div class="d-flex flex-row justify-content-center align-items-center py-3 border flex-wrap px-1">
                                      <button class="btn tertiary-button"><i class="la la-times size-15" style="position: relative; top: 2px"></i> {{ slocale("Clear filters") }}</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="dropdown m-1 d-none d-xl-block d-lg-block">
                                <a class="dropdown-toggle remove-arrow-drpdown form-control filter-button" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  {{ slocale("Sort by: None") }}
                                </a>
                                <ul class="dropdown-menu">
                                  <li>
                                    <a class="dropdown-item">{{ slocale("Created date: oldest to newwest") }}</a>
                                  </li>
                                  <div class="dropdown-divider"></div>
                                  <li>
                                    <a class="dropdown-item">{{ slocale("Created date: newest to oldest") }}</a>
                                  </li>
                                  <div class="dropdown-divider"></div>
                                  <li>
                                    <a class="dropdown-item">{{ slocale("Deadline: oldest to newwest") }}</a>
                                  </li>
                                  <div class="dropdown-divider"></div>
                                  <li>
                                    <a class="dropdown-item">{{ slocale("Deadline: newest to oldest") }}</a>
                                  </li>
                                  <div class="dropdown-divider"></div>
                                  <li>
                                    <a class="dropdown-item">{{ slocale("Organizational unit name: A-Z") }}</a>
                                  </li>
                                  <div class="dropdown-divider"></div>
                                  <li>
                                    <a class="dropdown-item">{{ slocale("Organizational unit name: Z-A") }}</a>
                                  </li>
                                </ul>
                              </div>
                              <div class="dropdown m-1 d-none d-xl-block d-lg-block">
                                <a class="dropdown-toggle remove-arrow-drpdown form-control filter-button" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  {{ slocale("Group by: None") }}
                                </a>
                                <ul class="dropdown-menu">
                                  <li><a class="dropdown-item">Action</a></li>
                                  <li><a class="dropdown-item">Another action</a></li>
                                  <li><a class="dropdown-item">Something else here</a></li>
                                </ul>
                              </div>
                            </div>
                            <a class="underline my-auto mx-2 cursor" (click)="expandAll()">{{ locale("locale_key.pages.kpi_library.button.expand_all") }} </a>
                            <a class="underline my-auto me-2 cursor" (click)="collapseAll()">{{ locale("locale_key.pages.kpi_library.button.collapse_all") }} </a>
                          </a>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                          <div class="accordion-body p-2 pb-3">
                            <div class="d-flex flex-row justify-content-between align-items-center p-3 p-sm-1 border mb-2">
                              <div class="col-xl-2 col-lg-2 col-10 d-flex flex-column justify-content-start align-items-start">
                                <span class="text-muted">{{ slocale("Disclosure requirement title") }}</span>
                                <h6 class="mb-0 pb-0">{{ slocale("Organizational unit name") }}</h6>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <div class="d-flex flex-row">
                                  <div class="icon-circle-dark my-auto me-2">
                                    <i class="white la la-user size-15"></i>
                                  </div>
                                  <span class="text-dark">{{ slocale("Silvia (You)") }}</span>
                                </div>
                                <h6 class="mb-0 pb-0">{{ slocale("(SustainLab Demo 1)") }}</h6>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <span class="text-dark d-block">{{ slocale("Created") }}</span>
                                <span class="badge badge-gray">{{ slocale("Jul 24, 2022") }}</span>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <span class="text-dark d-block">{{ slocale("Created") }}</span>
                                <span class="badge badge-alert">{{ slocale("Jul 24, 2022") }}</span>
                              </div>
                              <div class="col-2 d-flex flex-column justify-content-start align-items-start d-none d-xl-block d-lg-block">
                                <span class="badge badge-gray">{{ slocale("Not started") }}</span>
                              </div>
                              <div class="col-2 d-flex flex-column justify-content-start align-items-end">
                                <button class="btn primary-button custom-button">{{ slocale("Start") }}</button>
                              </div>
                            </div>
                            <div class="d-flex flex-row justify-content-between align-items-center p-3 p-sm-1 border mb-2">
                              <div class="col-xl-2 col-lg-2 col-10 d-flex flex-column justify-content-start align-items-start">
                                <span class="text-muted">{{ slocale("Disclosure requirement title") }}</span>
                                <h6 class="mb-0 pb-0">{{ slocale("Organizational unit name") }}</h6>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <div class="d-flex flex-row">
                                  <div class="icon-circle-dark my-auto me-2">
                                    <i class="white la la-user size-15"></i>
                                  </div>
                                  <span class="text-dark">{{ slocale("Silvia (You)") }}</span>
                                </div>
                                <h6 class="mb-0 pb-0">{{ slocale("(SustainLab Demo 1)") }}</h6>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <span class="text-dark d-block">{{ slocale("Created") }}</span>
                                <span class="badge badge-gray">{{ slocale("Jul 24, 2022") }}</span>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <span class="text-dark d-block">{{ slocale("Created") }}</span>
                                <span class="badge badge-alert">{{ slocale("Jul 24, 2022") }}</span>
                              </div>
                              <div class="col-2 d-flex flex-column justify-content-start align-items-start d-none d-xl-block d-lg-block">
                                <span class="badge badge-orange">{{ slocale("Preparing") }}</span>
                              </div>
                              <div class="col-2 d-flex flex-column justify-content-start align-items-end"></div>
                            </div>
                            <div class="d-flex flex-row justify-content-between align-items-center p-3 p-sm-1 border mb-2">
                              <div class="col-xl-2 col-lg-2 col-10 d-flex flex-column justify-content-start align-items-start">
                                <span class="text-muted">{{ slocale("Disclosure requirement title") }}</span>
                                <h6 class="mb-0 pb-0">{{ slocale("Organizational unit name") }}</h6>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <div class="d-flex flex-row">
                                  <div class="icon-circle-dark my-auto me-2">
                                    <i class="white la la-user size-15"></i>
                                  </div>
                                  <span class="text-dark">{{ slocale("Silvia (You)") }}</span>
                                </div>
                                <h6 class="mb-0 pb-0">{{ slocale("(SustainLab Demo 1)") }}</h6>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <span class="text-dark d-block">{{ slocale("Created") }}</span>
                                <span class="badge badge-gray">{{ slocale("Jul 24, 2022") }}</span>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <span class="text-dark d-block">{{ slocale("Created") }}</span>
                                <span class="badge badge-alert">{{ slocale("Jul 24, 2022") }}</span>
                              </div>
                              <div class="col-2 d-flex flex-column justify-content-start align-items-start d-none d-xl-block d-lg-block">
                                <span class="badge badge-green">{{ slocale("Completed") }}</span>
                              </div>
                              <div class="col-2 d-flex flex-column justify-content-start align-items-end">
                                <a class=""><i class="la la-arrow-right size-15"></i></a>
                              </div>
                            </div>
                            <div class="d-flex flex-row justify-content-between align-items-center p-3 p-sm-1 border mb-2">
                              <div class="col-xl-2 col-lg-2 col-10 d-flex flex-column justify-content-start align-items-start">
                                <span class="text-muted">{{ slocale("Disclosure requirement title") }}</span>
                                <h6 class="mb-0 pb-0">{{ slocale("Organizational unit name") }}</h6>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <div class="d-flex flex-row">
                                  <div class="icon-circle-dark my-auto me-2">
                                    <i class="white la la-user size-15"></i>
                                  </div>
                                  <span class="text-dark">{{ slocale("Silvia (You)") }}</span>
                                </div>
                                <h6 class="mb-0 pb-0">{{ slocale("(SustainLab Demo 1)") }}</h6>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <span class="text-dark d-block">{{ slocale("Created") }}</span>
                                <span class="badge badge-gray">{{ slocale("Jul 24, 2022") }}</span>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <span class="text-dark d-block">{{ slocale("Created") }}</span>
                                <span class="badge badge-alert">{{ slocale("Jul 24, 2022") }}</span>
                              </div>
                              <div class="col-2 d-flex flex-column justify-content-start align-items-start d-none d-xl-block d-lg-block">
                                <span class="badge badge-orange">{{ slocale("Preparing") }}</span>
                              </div>
                              <div class="col-2 d-flex flex-column justify-content-start align-items-end"></div>
                            </div>
                            <div class="d-flex flex-row justify-content-between align-items-center p-3 p-sm-1 border mb-2">
                              <div class="col-xl-2 col-lg-2 col-10 d-flex flex-column justify-content-start align-items-start">
                                <span class="text-muted">{{ slocale("Disclosure requirement title") }}</span>
                                <h6 class="mb-0 pb-0">{{ slocale("Organizational unit name") }}</h6>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <div class="d-flex flex-row">
                                  <div class="icon-circle-dark my-auto me-2">
                                    <i class="white la la-user size-15"></i>
                                  </div>
                                  <span class="text-dark">{{ slocale("Silvia (You)") }}</span>
                                </div>
                                <h6 class="mb-0 pb-0">{{ slocale("(SustainLab Demo 1)") }}</h6>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <span class="text-dark d-block">{{ slocale("Created") }}</span>
                                <span class="badge badge-gray">{{ slocale("Jul 24, 2022") }}</span>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <span class="text-dark d-block">{{ slocale("Created") }}</span>
                                <span class="badge badge-alert">{{ slocale("Jul 24, 2022") }}</span>
                              </div>
                              <div class="col-2 d-flex flex-column justify-content-start align-items-start d-none d-xl-block d-lg-block">
                                <span class="badge badge-gray">{{ slocale("Not started") }}</span>
                              </div>
                              <div class="col-2 d-flex flex-column justify-content-start align-items-end">
                                <button class="btn primary-button custom-button">{{ slocale("Start") }}</button>
                              </div>
                            </div>
                            <div class="d-flex flex-row justify-content-between align-items-center p-3 p-sm-1 border mb-2">
                              <div class="col-xl-2 col-lg-2 col-10 d-flex flex-column justify-content-start align-items-start">
                                <span class="text-muted">{{ slocale("Disclosure requirement title") }}</span>
                                <h6 class="mb-0 pb-0">{{ slocale("Organizational unit name") }}</h6>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <div class="d-flex flex-row">
                                  <div class="icon-circle-dark my-auto me-2">
                                    <i class="white la la-user size-15"></i>
                                  </div>
                                  <span class="text-dark">{{ slocale("Silvia (You)") }}</span>
                                </div>
                                <h6 class="mb-0 pb-0">{{ slocale("(SustainLab Demo 1)") }}</h6>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <span class="text-dark d-block">{{ slocale("Created") }}</span>
                                <span class="badge badge-gray">{{ slocale("Jul 24, 2022") }}</span>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <span class="text-dark d-block">{{ slocale("Created") }}</span>
                                <span class="badge badge-alert">{{ slocale("Jul 24, 2022") }}</span>
                              </div>
                              <div class="col-2 d-flex flex-column justify-content-start align-items-start d-none d-xl-block d-lg-block">
                                <span class="badge badge-orange">{{ slocale("Preparing") }}</span>
                              </div>
                              <div class="col-2 d-flex flex-column justify-content-start align-items-end"></div>
                            </div>
                            <div class="d-flex flex-row justify-content-between align-items-center p-3 p-sm-1 border mb-2">
                              <div class="col-xl-2 col-lg-2 col-10 d-flex flex-column justify-content-start align-items-start">
                                <span class="text-muted">{{ slocale("Disclosure requirement title") }}</span>
                                <h6 class="mb-0 pb-0">{{ slocale("Organizational unit name") }}</h6>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <div class="d-flex flex-row">
                                  <div class="icon-circle-dark my-auto me-2">
                                    <i class="white la la-user size-15"></i>
                                  </div>
                                  <span class="text-dark">{{ slocale("Silvia (You)") }}</span>
                                </div>
                                <h6 class="mb-0 pb-0">{{ slocale("(SustainLab Demo 1)") }}</h6>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <span class="text-dark d-block">{{ slocale("Created") }}</span>
                                <span class="badge badge-gray">{{ slocale("Jul 24, 2022") }}</span>
                              </div>
                              <div class="col-2 d-none d-xl-block d-lg-block d-flex flex-column justify-content-start align-items-start">
                                <span class="text-dark d-block">{{ slocale("Created") }}</span>
                                <span class="badge badge-alert">{{ slocale("Jul 24, 2022") }}</span>
                              </div>
                              <div class="col-2 d-flex flex-column justify-content-start align-items-start d-none d-xl-block d-lg-block">
                                <span class="badge badge-turquoise">{{ slocale("Collecting") }}</span>
                              </div>
                              <div class="col-2 d-flex flex-column justify-content-start align-items-end"></div>
                            </div>
                            ---CSRD SETUP FILTERS ---
                            <div class="accordion" id="accordionExample15">
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <a class="accordion-button collapsed bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16" aria-expanded="false" aria-controls="collapse16">
                                    {{ slocale("Topics") }}
                                  </a>
                                </h2>
                                <div id="collapse16" class="accordion-collapse collapse" data-bs-parent="#accordionExample15">
                                  <div class="accordion-body">
                                    <div class="d-flex flex-row justify-content-between align-items-center">
                                      <input type="text" class="form-control me-1 w-auto input-small" placeholder="{{ locale('locale_key.general.search') }}" (input)="filter(search.value)" #search />
                                      <div class="d-flex flex-row justify-content-end align-items-center">
                                        <a class="underline my-auto mx-2 cursor">{{ slocale("Select all") }} </a>
                                        <a class="underline my-auto me-2 cursor">{{ slocale("Deselect all") }} </a>
                                      </div>
                                    </div>
                                    <div class="d-flex flex-column mt-2">
                                      <div class="accordion-item">
                                        <a class="accordion-button d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1 bg-white">
                                          <label class="form-check-label">
                                            <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                            {{ slocale("Cross-cutting topics") }}
                                          </label>
                                        </a>
                                        <div id="collapseOne1" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                          <div class="accordion-body px-3 py-2 bg-comp-grey">
                                            <label class="form-check-label">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("ESRS 2 General Disclosures") }}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <a class="accordion-button d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1 bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2"
                                          aria-expanded="true" aria-controls="collapseOne2">
                                          <label class="form-check-label">
                                            <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                            {{ slocale("Environmental topics") }}
                                          </label>
                                        </a>
                                        <div id="collapseOne2" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                          <div class="accordion-body px-3 py-2 bg-comp-grey">
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("ESRS E1 Climate Change") }}
                                            </label>
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("ESRS E2 Pollution") }}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <a class="accordion-button d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1 bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne3"
                                          aria-expanded="true" aria-controls="collapseOne3">
                                          <label class="form-check-label">
                                            <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                            {{ slocale("Social topics") }}
                                          </label>
                                        </a>
                                        <div id="collapseOne3" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                          <div class="accordion-body px-3 py-2 bg-comp-grey">
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("ESRS S1 Own workforce") }}
                                            </label>
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("ESRS E2 Pollution") }}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <a class="accordion-button d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1 bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne4"
                                          aria-expanded="true" aria-controls="collapseOne4">
                                          <label class="form-check-label">
                                            <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                            {{ slocale("Economic topics") }}
                                          </label>
                                        </a>
                                        <div id="collapseOne4" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                          <div class="accordion-body px-3 py-2 bg-comp-grey">
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("ESRS S1 Own workforce") }}
                                            </label>
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("ESRS E2 Pollution") }}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="accordion-item">
                                        <a class="accordion-button d-flex flex-row justify-content-start align-items-center border w-100 p-2 mb-1 bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne5"
                                          aria-expanded="true" aria-controls="collapseOne5">
                                          <label class="form-check-label">
                                            <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                            {{ slocale("Governance topics") }}
                                          </label>
                                        </a>
                                        <div id="collapseOne5" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                          <div class="accordion-body px-3 py-2 bg-comp-grey">
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("ESRS G1 Business Conduct") }}
                                            </label>
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("ESRS E2 Pollution") }}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <a class="accordion-button collapsed bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse17" aria-expanded="false" aria-controls="collapse17">
                                    {{ slocale("Organizational unit name") }}
                                  </a>
                                </h2>
                                <div id="collapse17" class="accordion-collapse collapse" data-bs-parent="#accordionExample17">
                                  <div class="accordion-body">
                                    <div class="d-flex flex-row justify-content-between align-items-center">
                                      <input type="text" class="form-control me-1 w-auto input-small" placeholder="{{ locale('locale_key.general.search') }}" (input)="filter(search.value)" #search />
                                      <div class="d-flex flex-row justify-content-end align-items-center">
                                        <a class="underline my-auto mx-2 cursor">{{ slocale("Select all") }} </a>
                                        <a class="underline my-auto me-2 cursor">{{ slocale("Deselect all") }} </a>
                                      </div>
                                    </div>
                                    <div class="d-flex flex-column mt-2">
                                      <div class="">
                                        <div class="d-flex flex-column w-100 p-2 mb-1 bg-white">
                                          <div class="p-2 border mb-2 w-100 mx-2">
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("Copy name") }}
                                            </label>
                                          </div>
                                          <div class="p-2 border mb-2 w-100 mx-2">
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("Copy name") }}
                                            </label>
                                          </div>
                                          <div class="p-2 border mb-2 w-100 mx-2">
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("Copy name") }}
                                            </label>
                                          </div>
                                          <div class="p-2 border mb-2 w-100 mx-2">
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("Copy name") }}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <a class="accordion-button collapsed bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse18" aria-expanded="false" aria-controls="collapse18">
                                    {{ slocale("Superusers") }}
                                  </a>
                                </h2>
                                <div id="collapse18" class="accordion-collapse collapse" data-bs-parent="#accordionExample18">
                                  <div class="accordion-body">
                                    <div class="d-flex flex-row justify-content-between align-items-center">
                                      <input type="text" class="form-control me-1 w-auto input-small" placeholder="{{ locale('locale_key.general.search') }}" (input)="filter(search.value)" #search />
                                      <div class="d-flex flex-row justify-content-end align-items-center">
                                        <a class="underline my-auto mx-2 cursor">{{ slocale("Select all") }} </a>
                                        <a class="underline my-auto me-2 cursor">{{ slocale("Deselect all") }} </a>
                                      </div>
                                    </div>
                                    <div class="d-flex flex-column mt-2">
                                      <div class="">
                                        <div class="d-flex flex-column w-100 p-2 mb-1 bg-white">
                                          <div class="p-2 border mb-2 w-100 mx-2">
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("Silvia (You)") }}
                                            </label>
                                          </div>
                                          <div class="p-2 border mb-2 w-100 mx-2">
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("Oliver") }}
                                            </label>
                                          </div>
                                          <div class="p-2 border mb-2 w-100 mx-2">
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("Sohia") }}
                                            </label>
                                          </div>
                                          <div class="p-2 border mb-2 w-100 mx-2">
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("John") }}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item">
                                <h2 class="accordion-header">
                                  <a class="accordion-button collapsed bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse19" aria-expanded="false" aria-controls="collapse19">
                                    {{ slocale("Company") }}
                                  </a>
                                </h2>
                                <div id="collapse19" class="accordion-collapse collapse" data-bs-parent="#accordionExample19">
                                  <div class="accordion-body">
                                    <div class="d-flex flex-row justify-content-between align-items-center">
                                      <input type="text" class="form-control me-1 w-auto input-small" placeholder="{{ locale('locale_key.general.search') }}" (input)="filter(search.value)" #search />
                                      <div class="d-flex flex-row justify-content-end align-items-center">
                                        <a class="underline my-auto mx-2 cursor">{{ slocale("Select all") }} </a>
                                        <a class="underline my-auto me-2 cursor">{{ slocale("Deselect all") }} </a>
                                      </div>
                                    </div>
                                    <div class="d-flex flex-column mt-2">
                                      <div class="">
                                        <div class="d-flex flex-column w-100 p-2 mb-1 bg-white">
                                          <div class="p-2 border mb-2 w-100 mx-2">
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("SustainLab Demo 1") }}
                                            </label>
                                          </div>
                                          <div class="p-2 border mb-2 w-100 mx-2">
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("SustainLab Demo 2") }}
                                            </label>
                                          </div>
                                          <div class="p-2 border mb-2 w-100 mx-2">
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("SustainLab Demo 3") }}
                                            </label>
                                          </div>
                                          <div class="p-2 border mb-2 w-100 mx-2">
                                            <label class="form-check-label d-block">
                                              <input type="checkbox" class="form-check-input mx-2" style="position: relative; top: 2px" />
                                              {{ slocale("SustainLab Demo 4") }}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <!-- DATA REQUEST PROGRESS PAGE-->
                <ng-container *ngIf="activeCsrdOverviewTab == CSRD_PROJECT_OVERVIEW_TABS.DATA_REQUEST_PROGRESS">
                  <div class="d-flex flex-row w-100 py-2 px-1 bg-white">
                    <div class="d-flex flex-column flex-sm-row justify-content-around align-items-center m-1 bg-comp-grey border rounded px-3 w-100 py-2">
                      <div class="d-flex flex-row justify-content-start align-items-center p-2 text-wrap w-100">
                        <h6 class="mb-0 pb-0 d-inline">{{ slocale("QUESTIONNAIRE COPIES SENT") }}</h6>
                        <div class="icon-circle-pink-lg ms-1 my-auto"><span class="text-white bold d-inline">0</span></div>
                      </div>
                      <div class="d-flex flex-row justify-content-start align-items-center p-2 text-wrap w-100">
                        <h6 class="mb-0 pb-0 d-inline">{{ slocale("DATA SUBMISSIONS TO REVIEW") }}</h6>
                        <div class="icon-circle-pink-lg ms-1 my-auto"><span class="text-white bold d-inline">0</span></div>
                      </div>
                      <div class="d-flex flex-row justify-content-start align-items-center p-2 text-wrap w-100">
                        <h6 class="mb-0 pb-0 d-inline">{{ slocale("COMPLETED REQUESTS") }}</h6>
                        <div class="icon-circle-pink-lg ms-1 my-auto"><span class="text-white bold">0</span></div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #saveAsDraft>
    <div class="modal-header alert-modal-header">
      <h4 class="modal-title mb-0 pb-0 ms-2 mb-0">{{ slocale("Save as draft") }}</h4>
      <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <p class="ps-2">
        {{ slocale("Your project will be saved in the Project Overview page.") }}
      </p>
    </div>
    <div class="modal-footer" style="border-top: none">
      <div *ngIf="savingAsDraft" class="ball-pulse loader-primary text-center m-auto">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div>
      <ng-container *ngIf="!savingAsDraft">
        <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
        <button type="button" class="btn primary-button custom-button text-white" (click)="saveProject()">{{ this.saveBeforeLeave ? slocale("Return to page") : slocale("Save") }}</button>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #saveDraft>
    <div class="modal-header alert-modal-header">
      <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14" *ngIf="saveBeforeLeave">{{ slocale("Save as draft") }}</h4>
      <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14" *ngIf="projCSRD">{{ slocale("Cancel new project") }}</h4>
      <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14" *ngIf="materiality">{{ slocale("Cancel process") }}</h4>
      <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <p class="ps-2" *ngIf="!saveBeforeLeave">
        {{ slocale("Your project will be saved in the Project Overview page.") }}
      </p>
      <p class="ps-2" *ngIf="saveBeforeLeave">{{ slocale("If you leave this page your changes won't be saved. To save changes return to the page and select the save option you want.") }}</p>
      <p class="ps-2" *ngIf="projCSRD">{{ slocale("Are you sure you want to cancel? Your changes will not be saved.") }}</p>
      <p class="ps-2" *ngIf="materiality">{{ slocale("Are you sure you want to cancel? Your changes in this session will be undone and not saved. To keep your progress, return to the page and use the 'back' button to exit the process.") }}
      </p>
    </div>
    <div class="modal-footer" style="border-top: none">
      <!--Enable when loading-->
      <!-- <div class="ball-pulse loader-primary text-center m-auto" >
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
          <div style="background-color: var(--main-dark-color1)"></div>
        </div> -->
      <ng-container>
        <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
        <button type="button" class="btn primary-button custom-button text-white" (click)="saveProject()">{{ this.saveBeforeLeave ? slocale("Return to page") : slocale("Save") }}</button>
      </ng-container>
    </div>
  </ng-template>
</div>

<csrd-define-materiality *ngIf="activePage == 'CSRD_DEFINE_MATERIALITY'" [activeTopic]="activeTopic" [activeEsrs]="activeEsrs" [activeCsrdProject]="activeCsrdProject" (switchCsrdProjectPage)="switchCsrdProjectPage($event)"
  (switchProjectsPage)="switchProjectsPage.emit($event)"></csrd-define-materiality>

<csrd-assess-dr *ngIf="activePage == 'CSRD_ASSESS_DR'" [activeTopic]="activeTopic" [activeDr]="activeDr" [activeEsrs]="activeEsrs" [activeCsrdProject]="activeCsrdProject" (switchCsrdProjectPage)="switchCsrdProjectPage($event)"
  (switchProjectsPage)="switchProjectsPage.emit($event)"></csrd-assess-dr>

<csrd-dr *ngIf="activePage == 'CSRD_DR'" [activeTopic]="activeTopic" [activeDr]="activeDr" [activeEsrs]="activeEsrs" [activeCsrdProject]="activeCsrdProject" (switchCsrdProjectPage)="switchCsrdProjectPage($event)"
  (switchProjectsPage)="switchProjectsPage.emit($event)"></csrd-dr>

<!-- Show Calculations Modal -->
<ng-template #showCalcModal>
  <div class="modal-header alert-modal-header">
    <h5 class="modal-title mb-0 pb-0 ms-2 mb-0 bold" id="myModalLabel14">
      {{ slocale("GHG emissions ") }} <span class="text-muted" style="font-weight: 300">{{ slocale("in the unit") }}</span> {{ slocale("Kg CO2e") }}
    </h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body p-4">
    <div class="d-flex flex-row justify-content-between align-items-center blue-border w-100 p-2 bg-selected">
      <span class="mb-0 pb-0">{{ slocale("Activity data * Emission factor waste") }}</span>
      <i class="la la-edit size-15 me-2 bold" (click)="openModal(editCalcModal, 'modal-lg')"></i>
    </div>
  </div>
</ng-template>

<!-- Edit calculation modal-->
<ng-template #editCalcModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Edit calculation formula") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body p-2" style="border-top: none">
    <div class="row px-2">
      <p class="pt-3 bold">{{ slocale("Edit calculation formula for this entry") }}</p>
    </div>
    <div class="row px-2">
      <p class="">
        <span class="bold">{{ slocale("GHG emissions ") }}</span>{{ slocale("will be calculated as ") }}
      </p>
    </div>
    <div class="row px-1">
      <div class="d-flex flex-row justify-content-between align-items-center w-100">
        <div class="d-flex flex-row justify-content-start align-items-center w-100 flex-grow-1">
          <span class="px-2 py-1 dotted-square mx-2"><i class="la la-plus-circle size-15 m-auto bold" (click)="editCalculation('id')" style="position: relative; top: 2px"></i></span>
          <span class="teal-border-round p-2 w-auto">
            <span class="me-auto">{{ slocale("Electricity energy ") }}<span class="ms-auto text-mutted">{{ slocale("Kwh") }}</span>
            </span>
          </span>
          <span class="teal-sqaure bold size-15 mx-2">+</span>
          <span class="px-2 py-1 dotted-square"><i class="la la-plus-circle size-15 m-auto bold" (click)="editCalculation('id')" style="position: relative; top: 2px"></i></span>
        </div>
        <div class="d-flex flex-row justify-content-end align-items-center p-2" [ngClass]="screenSize.isLargeSize() || screenSize.isXLargeSize() ? 'text-nowrap' : 'text-wrap'">
          <i class="la la-close size-15 ms-auto my-auto bold mx-1" (click)="editCalculation('id')"></i>
          <span *ngIf="screenSize.isLargeSize() || screenSize.isXLargeSize()">{{ slocale("Clear calculation") }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button" data-test="request-create-button">{{ locale("locale_key.general.buttons.save_changes") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Create project modal-->
<ng-template #selectEmissionsModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 *ngIf="!emissionFormSustainLab" class="modal-title text-white">{{ slocale("Select from my company’s emission factors") }}</h5>
    <h5 *ngIf="emissionFormSustainLab" class="modal-title text-white">{{ slocale("Search in emission databases provided by SustainLab") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none" *ngIf="!emissionFormSustainLab">
    <div class=" p-sm-0 m-sm-0 p-0 m-0">
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <div class="d-flex flex-row justify-content-start align-content-center">
            <div class="d-flex flex-column justify-content-start align-content-center flex-grow-1">
              <p class="mb-0 pb-0 pt-1"><i class="la la-arrow-left bold size-15 d-inline me-2" style="position: relative; top: 3px"></i>{{ slocale("Back to menu") }}</p>
            </div>
            <div class="d-flex flex-row justify-content-end align-content-center">
              <input type="text" class="form-control w-auto" placeholder="{{ locale('locale_key.general.search') }}" (input)="filter(search.value)" #search />
            </div>
          </div>
          <p class="bold mb-0">{{ slocale("Click on the emission factor to select") }}</p>
          <div class="row p-0 m-0">
            <table class="table table-hover table-responsive table-bordered mt-1">
              <thead>
                <tr class="bg-comp-grey pt-2">
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale("Emission source") }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale("Emission source unit") }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale("Conversion unit") }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale("Conversion factor") }}</span>
                    </h6>
                  </th>
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale("Edit") }}</span>
                    </h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')"><i class="la la-edit me-2"></i>{{ slocale("Edit") }}</a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')"><i class="la la-edit me-2"></i>{{ slocale("Edit") }}</a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')"><i class="la la-edit me-2"></i>{{ slocale("Edit") }}</a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')"><i class="la la-edit me-2"></i>{{ slocale("Edit") }}</a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')"><i class="la la-edit me-2"></i>{{ slocale("Edit") }}</a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')"><i class="la la-edit me-2"></i>{{ slocale("Edit") }}</a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')"><i class="la la-edit me-2"></i>{{ slocale("Edit") }}</a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')"><i class="la la-edit me-2"></i>{{ slocale("Edit") }}</a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')"><i class="la la-edit me-2"></i>{{ slocale("Edit") }}</a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')"><i class="la la-edit me-2"></i>{{ slocale("Edit") }}</a>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="bg-comp-grey">
                    <h6 class="text-muted pt-2">
                      <a class="cursor d-block" (click)="openModal(selectEmissionsModal, 'modal-xl')"><i class="la la-edit me-2"></i>{{ slocale("Edit") }}</a>
                    </h6>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 h-100 border rounded" style="height: 80vh !important">
          <table class="table table-responsive mt-1 table-sm">
            <thead style="border-bottom: 4px solid var(--main-contrast-color)">
              <tr class="">
                <th colspan="2">
                  <h6 class="text-muted mb-0 pb-0 mt-2">{{ slocale("Custom emission detail factor") }}</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="">
                <td class="">
                  <h6 class="text-muted mt-2">{{ slocale("Emission source") }}</h6>
                </td>
                <td class="">
                  <h6 class="text-muted bold mt-2">{{ slocale("Biofuel") }}</h6>
                </td>
              </tr>
              <tr class="">
                <td>
                  <h6 class="text-muted mt-2">{{ slocale("Emission source unit") }}</h6>
                </td>
                <td>
                  <h6 class="text-muted bold mt-2">{{ slocale("litres") }}</h6>
                </td>
              </tr>
              <tr>
                <td>
                  <h6 class="text-muted mt-2">{{ slocale("Conversion unit") }}</h6>
                </td>
                <td>
                  <h6 class="text-muted bold mt-2">kg CH4</h6>
                </td>
              </tr>
              <tr>
                <td>
                  <h6 class="text-muted mt-2">{{ slocale("Conversion factor") }}</h6>
                </td>
                <td>
                  <h6 class="text-muted mt-2">2.01888</h6>
                </td>
              </tr>
            </tbody>
            <h6 class="text-muted ps-2 pt-3">{{ slocale("Emission factor origin") }}</h6>
            <p class="ps-2 text-justify">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
            </p>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body" style="border-top: none" *ngIf="emissionFormSustainLab">
    <div class=" p-sm-0 m-sm-0 p-0 m-0">
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <div class="d-flex flex-row justify-content-start align-content-center">
            <div class="d-flex flex-column justify-content-start align-content-center flex-grow-1">
              <p class="mb-0 pb-0 pt-1"><i class="la la-arrow-left bold size-15 d-inline me-2" style="position: relative; top: 3px"></i>{{ slocale("Back to menu") }}</p>
            </div>
            <div class="d-flex flex-row justify-content-end align-content-center">
              <input type="text" class="form-control w-auto" placeholder="{{ locale('locale_key.general.search') }}" (input)="filter(search.value)" #search />
            </div>
          </div>
          <p class="bold mb-0">{{ slocale("Click on the emission factor to select") }}</p>
          <div class="row p-0 m-0">
            <table class="table table-hover table-responsive table-bordered mt-1">
              <thead>
                <tr class="bg-comp-grey pt-2">
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale("Emission source") }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale("Emission source unit") }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale("Conversion unit") }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th>
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale("Conversion factor") }}</span>
                    </h6>
                  </th>
                  <th class="">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale("Database name") }}</span>
                      <a><i class="la la-filter ms-auto ms-1"></i></a>
                    </h6>
                  </th>
                  <th class="hide-on-small">
                    <h6 class="text-muted mb-1">
                      <span class="me-auto">{{ slocale("Additional details") }}</span>
                    </h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small" *ngIf="!licencedMaterial">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small" *ngIf="!licencedMaterial">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3" *ngIf="!licencedMaterial">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3 hide-on-small" colspan="3" *ngIf="licencedMaterial">
                    <div class="d-flex flex-row flex-wrap justify-content-center align-items-center w-100 h-100">
                      <h4 class="text-muted mb-1 my-auto">{{ slocale("Licenced material") }}</h4>
                      <i class="la la-info-circle info-popover-arrow ms-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale("Click on the link below to subscribe to licenced material.") }}</p>
                      </div>
                    </div>
                    <div class="d-flex flex-row justify-content-between w-100 align-items-center">
                      <div class="pt-3 hide-on-small">
                        <h6 class="blurred-text">{{ slocale("liters") }}</h6>
                      </div>
                      <div class="pt-3 hide-on-small">
                        <h6 class="blurred-text">kg CH4</h6>
                      </div>
                      <div class="pt-3">
                        <h6 class="blurred-text">0.00107</h6>
                      </div>
                    </div>
                  </td>
                  <td class="pt-3 show-on-small" colspan="3" *ngIf="licencedMaterial">
                    <div class="d-flex flex-row flex-wrap justify-content-center align-items-center w-100 h-100">
                      <h4 class="text-muted mb-1 my-auto">{{ slocale("Licenced material") }}</h4>
                      <i class="la la-info-circle info-popover-arrow ms-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                      <div class="dropdown-menu arrow p-2 info-popover">
                        <p>{{ slocale("Click on the link below to subscribe to licenced material.") }}</p>
                      </div>
                    </div>
                    <div class="d-flex flex-row justify-content-between w-100 align-items-center">
                      <div class="pt-3 hide-on-small">
                        <h6 class="blurred-text">{{ slocale("liters") }}</h6>
                      </div>
                    </div>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">IPCC Waste 2020</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap"> {{ slocale("Type: Natural gas") }}</span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">{{ slocale("Sub-Type: Natural gas") }}</span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">{{ slocale("Location: Europe") }}</span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap"> {{ slocale("Type: Natural gas") }}</span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">{{ slocale("Sub-Type: Natural gas") }}</span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">{{ slocale("Location: Europe") }}</span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap"> {{ slocale("Type: Natural gas") }}</span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">{{ slocale("Sub-Type: Natural gas") }}</span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">{{ slocale("Location: Europe") }}</span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">IPCC Waste 2020</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap"> {{ slocale("Type: Natural gas") }}</span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">{{ slocale("Sub-Type: Natural gas") }}</span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">{{ slocale("Location: Europe") }}</span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap"> {{ slocale("Type: Natural gas") }}</span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">{{ slocale("Sub-Type: Natural gas") }}</span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">{{ slocale("Location: Europe") }}</span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap"> {{ slocale("Type: Natural gas") }}</span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">{{ slocale("Sub-Type: Natural gas") }}</span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">{{ slocale("Location: Europe") }}</span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap"> {{ slocale("Type: Natural gas") }}</span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">{{ slocale("Sub-Type: Natural gas") }}</span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">{{ slocale("Location: Europe") }}</span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap"> {{ slocale("Type: Natural gas") }}</span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">{{ slocale("Sub-Type: Natural gas") }}</span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">{{ slocale("Location: Europe") }}</span>
                    </h6>
                  </td>
                </tr>
                <tr class="row-1">
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">{{ slocale("Biofuel") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">{{ slocale("liters") }}</h6>
                  </td>
                  <td class="pt-3 hide-on-small">
                    <h6 class="text-muted mb-1">kg CH4</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">0.00107</h6>
                  </td>
                  <td class="pt-3">
                    <h6 class="text-muted mb-1">DEFRA</h6>
                  </td>
                  <td class="hide-on-small">
                    <h6 class="text-muted pt-2 d-flex flex-row flex-wrap">
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap"> {{ slocale("Type: Natural gas") }}</span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">{{ slocale("Sub-Type: Natural gas") }}</span>
                      <span class="badge badge-gray m-1 w-auto bg-light-yellow text-wrap">{{ slocale("Location: Europe") }}</span>
                    </h6>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 h-100 border rounded" style="height: 100vh !important">
          <ul class="nav nav-tabs nav-underline no-hover-bg w-100 bg-transparent">
            <li class="nav-item bg-transparent">
              <a class="nav-link mb-0 pb-0" id="base-tab31" [class.active]="activeTab === 'emissionFactor'" (click)="selectTab('emissionFactor')"> {{ slocale("Emission factor detail") }}</a>
            </li>
            <li class="nav-item bg-transparent">
              <a class="nav-link mb-0 pb-0" id="base-tab32" [class.active]="activeTab === 'databaseInfo'" (click)="selectTab('databaseInfo')">{{ slocale("Database info") }}</a>
            </li>
          </ul>
          <div class="tab-content px-1 pt-1">
            <div role="tabpanel" class="tab-pane" id="emissionFactor" [class.active]="activeTab === 'emissionFactor'" aria-labelledby="base-emissionFactor">
              <div class="">
                <table class="table table-responsive mt-1 table-sm">
                  <thead>
                    <tr class="">
                      <th colspan="2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="">
                      <td class="">
                        <h6 class="text-muted mt-2">{{ slocale("Category") }}</h6>
                      </td>
                      <td class="">
                        <h6 class="text-muted bold mt-2">{{ slocale("Biofuel") }}</h6>
                      </td>
                    </tr>
                    <tr class="">
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale("Type") }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale("Natural gas") }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale("Sub-type") }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale("Biodiesel (from used cooking oil)") }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale("Conversion factor") }}</h6>
                      </td>
                      <td>
                        <div class="d-flex flex-row flex-wrap justify-content-start align-items-center w-auto h-100 bg-comp-grey p-2">
                          <h5 class="text-muted my-auto italic">{{ slocale("Licenced material") }}</h5>
                          <i class="la la-info-circle info-popover-arrow ms-1 size-15" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale("Click on the link below to subscribe to licenced material.") }}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale("Conversion unit") }}</h6>
                      </td>
                      <td>
                        <div class="d-flex flex-row flex-wrap justify-content-start align-items-center w-auto h-100 bg-comp-grey p-2">
                          <h5 class="text-muted my-auto italic">{{ slocale("Licenced material") }}</h5>
                          <i class="la la-info-circle info-popover-arrow ms-1 size-15" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale("Click on the link below to subscribe to licenced material.") }}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale("Clasification") }}</h6>
                      </td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale("Commodity Unit") }}</h6>
                      </td>
                      <td>
                        <div class="d-flex flex-row flex-wrap justify-content-start align-items-center w-auto h-100 bg-comp-grey p-2">
                          <h5 class="text-muted my-auto italic">{{ slocale("Licenced material") }}</h5>
                          <i class="la la-info-circle info-popover-arrow ms-1 size-15" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> </i>
                          <div class="dropdown-menu arrow p-2 info-popover">
                            <p>{{ slocale("Click on the link below to subscribe to licenced material.") }}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale("Continent") }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale("Europe") }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale("Data source") }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale("UK Government GHG Conversion Factors for Company Reporting") }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale("Location") }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale("-") }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale("Scope") }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale("Scope 1") }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="text-muted mt-2">{{ slocale("Year") }}</h6>
                      </td>
                      <td>
                        <h6 class="text-muted bold mt-2">{{ slocale("2020") }}</h6>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h6 class="text-muted ps-2 pt-3 w-100">{{ slocale("Emission factor origin") }}</h6>
                <p class="ps-2 text-justify">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                </p>
              </div>
            </div>
            <div class="tab-pane" id="databaseInfo" [class.active]="activeTab === 'databaseInfo'" aria-labelledby="base-databaseInfo">
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale("Database name") }}</h6>
                <h6 class="bold">DEFRA</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale("Original release date") }}</h6>
                <h6 class="bold">Jan 01 2022</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale("Upload date in platform") }}</h6>
                <h6 class="bold">Jun 01, 2022</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale("Publisher organization") }}</h6>
                <h6 class="bold">ACME Inc</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale("Applied geography") }}</h6>
                <h6 class="bold">UK, US, Europe</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale("Applied time period") }}</h6>
                <h6 class="bold">Dec 2021- Dec 2023</h6>
              </div>
              <div class="thin-border-b w-100">
                <h6 class="text-muted mt-2">{{ slocale("Database name") }}</h6>
                <p class="bold">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Incidunt, eius voluptatibus? Deleniti autem animi ipsam explicabo iste similique ipsa, illum voluptates omnis tenetur ut fuga nostrum provident dignissimos dicta
                  eos officiis
                  eius nam saepe corrupti optio? Et consequatur impedit autem iure pariatur esse aspernatur minima quae eos sit fugit fuga at molestias, suscipit voluptatibus. Nulla temporibus magnam voluptates repellendus, porro minus quod
                  quasi
                  tempora a labore assumenda placeat, qui ea quae voluptatem tempore ducimus unde delectus facilis at? Ab corporis deserunt a magni quis sint quasi et, iusto tempore nulla saepe assumenda ipsum consequatur facere, natus, in
                  laboriosam
                  iste? Ipsa?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button type="button" class="btn tertiary-button" (click)="closeModal()" data-test="request-cancel-button">{{ slocale("Apply no factor") }}</button>
      <button class="btn primary-button custom-button" data-test="request-create-button">{{ slocale("Apply factor") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Create project modal-->
<ng-template #emissionsPortalModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Emissions portal") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none">
    <div class="row mx-auto text-center">
      <h3 class="bold mx-auto">{{ slocale("Choose your action.") }}</h3>
    </div>
    <div class=" p-sm-0 m-sm-0">
      <div class="d-flex flex-row justify-content-evenly align-items-center bg-white mt-1 p-3 p-sm-1 flex-wrap">
        <div class="card px-5 py-4 mt-2 bizTravel-card">
          <div class="card-body">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <i class="la la-search my-auto mx-2 dark-text" style="font-size: 3.5rem"></i>
              <small class="card-title col mx-auto">{{ slocale("Search in emission databases") }}</small>
              <small class="card-title col mx-auto">{{ slocale("provided by SustainLab") }}</small>
              <button class="btn primary-button mx-auto custom-button" (click)="activeTab = 'emissionFactor'; emissionFormSustainLab = true; openModal(selectEmissionsModal, 'modal-xl')">{{ slocale("Search") }}</button>
            </div>
          </div>
        </div>
        <div class="card px-5 py-4 mt-2 bizTravel-card">
          <div class="card-body">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <i class="la la-database my-auto mx-2 dark-text" style="font-size: 3.5rem"></i>
              <small class="card-title col mx-auto">{{ slocale("Select from my company's") }}</small>
              <small class="card-title col mx-auto">{{ slocale("emission factors") }}</small>
              <button class="btn primary-button mx-auto custom-button" (click)="emissionFormSustainLab = false; openModal(selectEmissionsModal, 'modal-xl')">{{ slocale("Select") }}</button>
            </div>
          </div>
        </div>
        <div class="card px-5 py-4 mt-2 bizTravel-card">
          <div class="card-body">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <i _ngcontent-qgx-c224="" class="la la-plus my-auto mx-2 dark-text" style="font-size: 3.5rem"></i>
              <small class="card-title col mx-auto">{{ slocale("Add custom") }}</small>
              <small class="card-title col mx-auto">{{ slocale("emission factor") }}</small>
              <button class="btn primary-button mx-auto custom-button">{{ slocale("Add custom") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Remove from Project Modal -->
<ng-template #removeProjectModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Remove from project") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{ slocale("Are you sure you want to remove this entry? The data will be removed from this carbon lab project and this action cannot be undone. Removing this entry will not affect the data categories in the platform.") }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button" (click)="closeModal()">{{ slocale("Return to page") }}</button>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale("Remove") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Data sucessfully added to project Modal -->
<ng-template #dataSuccessfullyAddedModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Data successfully added to project") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{ slocale("You have successfully added data into the CarbonLab project. The emissions will now be calculated based on your selected calculation method.") }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale("OK") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Missing emissions factors Modal -->
<ng-template #missingEmissionsModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Missing emission factors") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{ slocale("You have not filled in all necessary emission factors to calculated the emissions. Do you want to fill them in now or at a later time?") }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button" (click)="closeModal()">{{ slocale("Fill in now") }}</button>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale("Fill in later") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Update Data Categories modal-->
<ng-template #updateCategoriesModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Update data categories") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none">
    <div class=" p-sm-0 m-sm-0">
      <div class="row">
        <p class="py-3 bold">{{ slocale("To be able to calculate the GHG emissions new necessary data points need to be added to this data category. The new data points will be added to the data categories once you save the changes.") }}
        </p>
      </div>
      <div class="accordion w-100 px-2 mt-3" id="accordionExample1">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <a class="accordion-button collapsed d-flex flex-row justify-content-between align-content-center bg-comp-grey" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
              aria-controls="collapseTwo">
              <div class="d-flex flex-row justify-content-start align-items-center flex-grow-1">
                <i class="la la-table size-15 me-2"></i>
                <h6 class="mb-0 pb-0">{{ slocale("Data points to be added") }}</h6>
              </div>
            </a>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
            <div class="accordion-body p-0">
              <div class="d-flex flex-row justify-content-start align-items-center p-3 p-sm-1 flex-wrap">
                <span class="badge badge-green bg-green text-wrap w-auto m-1">Environmental > Waste generate > Waste generated in operations</span>
                <span class="badge badge-pink bg-light-pink text-wrap m-1 w-auto">Emission factor - scope 3</span> <span class="badge badge-pink bg-light-pink w-auto text-wrap m-1"> GHG emissions</span><span><i
                    class="la la-edit size-15 ms-1 mt-1"></i></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button">{{ slocale("Save changes") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Export Table modal-->
<ng-template #exportTableModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Export table") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="border-top: none">
    <div class=" p-sm-0 m-sm-0">
      <div class="row">
        <p class="pt-3 pb-1">{{ slocale("Select in what format to export the summary table") }}</p>
      </div>
      <div class="d-flex flex-row justify-content-evenly align-items-center bg-white mt-1 p-3 p-sm-1 flex-wrap">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <i class="la la-file-pdf my-auto mx-2" style="font-size: 3.5rem; color: red !important"></i>
          <label class="card-title mx-auto">
            <input type="checkbox" class="form-check-input me-1" id="exampleCheck1" style="position: relative; top: 2px" />
            {{ slocale("Export to PDF") }}
          </label>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <i class="la la-file-excel my-auto mx-2 bg-gradient" style="font-size: 3.5rem; color: var(--main-gradient-start-color) !important"></i>
          <label class="card-title mx-auto">
            <input type="checkbox" class="form-check-input me-1" id="exampleCheck1" style="position: relative; top: 2px" />
            {{ slocale("Export to in CSV") }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button">{{ locale("locale_key.general.toolbar.button.export") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Emission Categories Modal -->
<ng-template #emissionCategoriesModal>
  <div class="main-modal-header modal-header py-0 px-3">
    <h5 class="modal-title text-white py-3" id="myModalLabel14">{{ slocale("Edit project setup") }}</h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4 text-white"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class=" p-sm-0 m-sm-0 p-2 scroll-y">
      <div class="d-flex flex-row flex-wrap">
        <h5>{{ slocale("Project Name*") }}</h5>
        <i class="la la-info-circle info-popover-arrow ms-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="position: relative; top: 3px"> </i>
        <div class="dropdown-menu arrow p-2 info-popover">
          <p>{{ slocale("Choose a name for your carbon project to specify eg. the time period or organization sections included in the calculations.") }}</p>
        </div>
      </div>
      <div class="form-group">
        <input type="text" class="form-control" id="projectName" placeholder="{{ slocale('Enter project name') }}" />
      </div>
      <div class="d-flex flex-row justify-content-between align-items-center mt-2">
        <span class="w-75"> {{ slocale("Choose the emission categories to include in your project scope. If you require additional categories beyond those provided, you can add custom ones by editing the custom categories.") }}</span>
        <span>
          <i class="la la-edit size-15 ms-1" style="position: relative; top: 2px" (click)="openModal(customEmissionModal, 'modal-md')"> </i>
          Edit custom categories
        </span>
      </div>
      <p>You can add custom section or custom emission category if the requirement is out of scope.</p>
      !!!Dropdown content to be added here!!!
    </div>
  </div>
  <div class="modal-footer">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale("Confirm") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Custom Emission Categories Modal -->
<ng-template #customEmissionModal>
  <div class="main-modal-header modal-header py-0 px-3">
    <h5 class="modal-title text-white py-3" id="myModalLabel14">{{ slocale("Add custom emission category to Scope 1 (Direct)") }}</h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4 text-white"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class=" p-sm-0 m-sm-0 p-2 scroll-y">
      <label class="form-label">{{ slocale("Category name*") }}</label>
      <div class="form-group">
        <input type="text" class="form-control" id="projectName" placeholder="{{ slocale('Enter project name') }}" />
      </div>
      <label class="form-label">{{ slocale("Description") }}</label>
      <div class="form-group">
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button type="button" class="btn btn-add text-white custom-button" (click)="openModal(removeEmissionModal, 'modal-lg')">{{ slocale("Confirm") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Remove Emission Categories Modal -->
<ng-template #removeEmissionModal>
  <div class="main-modal-header modal-header py-0 px-3">
    <h5 class="modal-title text-white py-3" id="myModalLabel14">{{ slocale("Remove Emission Category") }}</h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4 text-white"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class=" p-sm-0 m-sm-0 p-2 scroll-y">
      <p>
        {{
        slocale(
        "Are you sure you want to remove this emission category? The data will be removed from this CarbonLab project and this action cannot be undone. This action will not affect the Data Categories in the platform and your data will still
        be available in the platform."
        )
        }}
      </p>
      !!!Content to be added here!!!
    </div>
  </div>
  <div class="modal-footer">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button type="button" class="btn btn-add text-white custom-button" (click)="openModal(removeEmissionsConfModal, 'modal-sm')">{{ slocale("Confirm") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Remove emissions Confirmation Modal -->
<ng-template #removeEmissionsConfModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Remove emission category") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{ slocale("The category is currently empty and it will not affect your project.") }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn btn-add text-white custom-button">{{ slocale("Confirm") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Start CSRD project -->
<ng-template #csrdPprojectModal>
  <div class="modal-header alert-modal-header py-2 mt-2">
    <div class="d-flex flex-row justify-content-start align-items-center">
      <div class="d-flex flex-column justify-content-center align-items-center my-4 px-2 d-inline ms-4">
        <div class="eu-logo-sm">
          <h5 id="eu-sm" class="pt-2">EU</h5>
          <div class="star-sm"></div>
          <div class="star-sm"></div>
          <div class="star-sm"></div>
          <div class="star-sm"></div>
          <div class="star-sm"></div>
          <div class="star-sm"></div>
          <div class="star-sm"></div>
          <div class="star-sm"></div>
          <div class="star-sm"></div>
          <div class="star-sm"></div>
          <div class="star-sm"></div>
          <div class="star-sm"></div>
        </div>
      </div>
      <h6 class="modal-title mb-0 pb-0 ms-4 mb-0 bold">CSRD 2024</h6>
    </div>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body p-4">
    <div class="d-flex flex-column">
      <div class="col">
        <small class="text-muted">{{ slocale("Reporting year") }}</small>
        <h6 class="bold">2024</h6>
      </div>
      <div class="col">
        <small class="text-muted">{{ slocale("Company name") }}</small>
        <h6 class="bold">Akkins Invertment Inc.</h6>
      </div>
      <div class="col">
        <small class="text-muted">{{ slocale("Country, town") }}</small>
        <h6 class="bold">Sweden, Stockholm</h6>
      </div>
      <div class="col">
        <small class="text-muted">{{ slocale("Emplyee range") }}</small>
        <h6 class="bold">0-750</h6>
      </div>
      <div class="row text-center">
        <button class="btn tertiary-button m-auto w-auto" (click)="openModal(editCsrdProjectModal, 'modal-lg')"><i class="la la-edit size-15 me-2 bold"></i>{{ slocale("Edit project info") }}</button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Edit CSRD Project Modal -->
<ng-template #editCsrdProjectModal>
  <div class="main-modal-header modal-header py-0 px-3">
    <h5 class="modal-title text-white py-3" id="myModalLabel14">{{ slocale("Edit project information") }}</h5>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4 text-white"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class=" p-sm-0 m-sm-0 p-2 scroll-y">
      <div class="d-flex flex-row flex-wrap">
        <h5>{{ slocale("Project Definition") }}</h5>
      </div>
      <div class="d-flex align-items-center">
        <p class="d-inline">{{ slocale("Enter the reporting year covered in this project") }}</p>
        <i class="la la-info-circle info-popover-arrow size-15 ms-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="position: relative; top: -7px"> </i>
        <div class="dropdown-menu arrow p-2 info-popover">
          <p>
            {{
            slocale(
            "Reporting year is not editable. If you want to change the reporting year, please duplicate this project and change the year when you set up the new copy."
            )
            }}
          </p>
        </div>
      </div>
      <div class="form-group">
        <input type="text" class="form-control w-auto" placeholder="{{ activeCsrdProject.definition.reportingYear }}" disabled />
      </div>
      <div class="d-flex align-items-center">
        <p class="d-inline">{{ slocale("Choose a name for your CSRD project.") }}</p>
      </div>
      <div class="form-group">
        <input [(ngModel)]="editForm.nameInput" type="text" class="form-control w-auto" required />
        <div class="error_box warning-message" [style.display]="showErrors && hasError['name'] ? 'block' : 'none'">
          {{ errorMsg.name }}
        </div>
      </div>
      <div class="d-flex align-items-center">
        <p class="d-inline">{{ slocale("Enter the first year of reporting") }}</p>
        <!-- DISABLE TOOLTIP UNTIL WE ENABLED PHASE IN LOGIC -->
        <!-- <i class="la la-info-circle info-popover-arrow size-15 ms-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="position: relative; top: -7px"> </i>
        <div class="dropdown-menu arrow p-2 info-popover">
          <p>{{ slocale("Some disclosure requirements and data points can be omitted in the first reporting year(s), which is why it's important for us to know which year your organization creates its first mandatory CSRD report. The phase-in options will be displayed based on this input.") }}</p>
        </div> -->
      </div>
      <div class="form-group">
        <input type="number" [(ngModel)]="editForm.firstReportingYearInput" (keypress)="firstReportingYearInputChanged($event)" min="2000" max="2100" step="1" class="form-control firstReportingYearInput"
          placeholder="{{ slocale('Enter year') }}" name="projectName" required />
        <div class="error_box warning-message" [style.display]="showErrors && hasError['firstReportingYear'] ? 'block' : 'none'">
          {{ errorMsg.firstReportingYear }}
        </div>
      </div>
      <div class="d-flex flex-row flex-wrap">
        <h5>{{ slocale("Company information") }}</h5>
      </div>
      <div class="d-flex align-items-center">
        <p class="d-inline">{{ slocale("Please confirm your legal company name") }}</p>
        <i class="la la-info-circle info-popover-arrow size-15 ms-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="position: relative; top: -7px"> </i>
        <div class="dropdown-menu arrow p-2 info-popover">
          <p>{{ slocale("In compliance with CSRD regulations, the legal company name must be included in the final report.") }}</p>
        </div>
      </div>
      <div class="form-group" [ngClass]="screenSize.isSmallerSize() || screenSize.isXSmallSize() ? 'w-100' : 'w-50'">
        <input type="text" [(ngModel)]="editForm.companyNameInput" class="form-control" placeholder="{{ slocale('Enter company name') }}" name="projectName" required />
        <div class="error_box warning-message" [style.display]="showErrors && hasError['companyName'] ? 'block' : 'none'">
          {{ errorMsg.companyName }}
        </div>
      </div>
      <div class="d-flex align-items-center">
        <p class="d-inline">{{ slocale("Where are the company’s headquarter located? (Country + town) ") }}</p>
        <i class="la la-info-circle info-popover-arrow size-15 ms-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="position: relative; top: -7px"> </i>
        <div class="dropdown-menu arrow p-2 info-popover">
          <p>{{ slocale("In compliance with CSRD regulations, the headquarter location must be included in the final report.") }}</p>
        </div>
      </div>
      <div class="form-group d-flex flex-row justify-content-between" [ngClass]="screenSize.isSmallerSize() || screenSize.isXSmallSize() ? 'w-100' : 'w-50'">
        <div>
          <select class="form-select w-100" [(ngModel)]="editForm.companyHQCountryInput" (change)="checkInputs()">
            <option value="" selected>{{ slocale("Choose country") }}</option>
            <option *ngFor="let country of countries" [value]="country[0]">{{ resolveCountryName(country[1]) }}</option>
          </select>
          <div class="error_box warning-message" [style.display]="showErrors && hasError['companyHQCountry'] ? 'block' : 'none'">
            {{ errorMsg.companyHQCountry }}
          </div>
        </div>
        <div>
          <input [(ngModel)]="editForm.companyHQTownInput" (keyup)="checkInputs()" type="text" class="form-control ms-2 w-100" placeholder="{{ slocale('Enter town') }}" />
          <div class="error_box warning-message" [style.display]="showErrors && hasError['companyHQTown'] ? 'block' : 'none'">
            {{ errorMsg.companyHQTown }}
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <p class="d-inline">{{ slocale("What was the average number employees during the reporting year (on a consolidated basis where applicable)?") }}</p>
        <!-- DISABLE TOOLTIP UNTIL WE ENABLED PHASE IN LOGIC -->
        <!-- <i class="la la-info-circle info-popover-arrow size-15 ms-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="position: relative; top: -7px"> </i>
        <div class="dropdown-menu arrow p-2 info-popover">
          <p>{{ slocale("The average employee number is relevant for certain phase-in options. In the data collection we'll highlight where this could be relevant for your reporting.") }}</p>
        </div> -->
      </div>
      <div class="form-group" [ngClass]="screenSize.isSmallerSize() || screenSize.isXSmallSize() ? 'w-100' : 'w-50'">
        <select class="form-select" [(ngModel)]="editForm.companyNoEmployeesInput" (change)="checkInputs()">
          <option selected disabled>{{ slocale("Select employee number") }}</option>
          <option value="0_750">{{ slocale("0 - 750 employees") }}</option>
          <option value="750+">{{ slocale("above 750 employees") }}</option>
        </select>
        <div class="error_box warning-message" [style.display]="showErrors && hasError['companyNoEmployees'] ? 'block' : 'none'">
          {{ errorMsg.companyNoEmployees }}
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <!--Enable when loading-->
    <ng-container *ngIf="editingProjectInfo">
      <div class="ball-pulse loader-primary text-center m-auto">
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="!editingProjectInfo">
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button type="button" class="btn btn-add text-white custom-button" (click)="confirmEditProjectInfo()">{{ slocale("Confirm") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Logout due to inactivity Modal -->
<ng-template #logoutModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Logout due to inactivity") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{ slocale("You are about to be logged out due to being inactive for a long time. Your work will be automatically saved and you can continue from where you left of after logging in again.") }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn primary-button custom-button text-white" (click)="saveProject()">{{ slocale("OK") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Create sub-topic modal-->
<ng-template #createSubTopicModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Create a sub-topic") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3 p-sm-1" style="border-top: none">
    <div class="d-flex flex-column">
      <p class="pt-3 bold mb-0 pb-0">{{ slocale("Sub-topic name") }}</p>
      <input type="text" class="form-control w-auto" placeholder="{{ slocale('Enter sub-topic name') }}" />
      <p class="pt-3 bold mb-0 pb-0">{{ slocale("Sub-topic description") }}</p>
      <textarea class="form-control" rows="3" placeholder="{{ slocale('Enter description') }}"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button" data-test="request-create-button">{{ locale("locale_key.general.buttons.save") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Confirm Not Material Modal -->
<ng-template #confirmNotMaterialModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Confirm 'Not Material' status for ESRS E3 (no data collected yet)") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
      slocale(
      "Data collection for 'ESRS E3 Water and Marine resources' has already started, but no data has been submitted yet. If you change the materiality to 'not material,' all ongoing data requests and superuser tasks will be closed,
      preventing further data submission.Would you like to proceed?"
      )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn primary-button custom-button text-white" (click)="closeModal()">{{ slocale("Confirm") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Water management modal-->
<ng-template #waterManagementModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Preview: Disclosure of whether and how policy addresses water management ") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3 p-sm-1" style="border-top: none">
    <div class="d-flex flex-lg-row flex-xl-row flex-column-reverse justify-content-between">
      <div class="d-flex flex-column w-75">
        <p class="pt-3 mb-0 pb-0">
          {{ slocale("Do your organisation's policies address water management including the use and sourcing of water and marine resources?") }}<span class="text-orange bold ms-1">{{ slocale("(Edited)") }}</span>
        </p>
        <div class="accordion" id="accordionExample">
          <div class="accordion-item w-auto" style="border: none !important">
            <h2 class="accordion-header w-auto">
              <a class="accordion-button w-auto bg-white small-accordion ms-0 ps-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                {{ slocale("Description") }}
              </a>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div class="accordion-body w-100">
                <p>
                  <input type="radio" name="waterManagement" value="yes" /> <span class="ms-2">{{ slocale("Yes") }}</span>
                </p>
                <p>
                  <input type="radio" name="waterManagement" value="no" /> <span class="ms-2">{{ slocale("No") }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <p class="pt-3 mb-0 pb-0">
          {{
          slocale(
          "Please describe how these policies address water management including: i) the use and sourcing of water and marine resources in your organisation's own operations; ii) water treatment as a step towards more sustainable sourcing
          of water; iii) the prevention and abatement of water pollution resulting from your organisation's activities."
          )
          }}
        </p>
        <div class="accordion" id="accordionExample">
          <div class="accordion-item" style="border: none !important">
            <h2 class="accordion-header">
              <a class="accordion-button collapsed small-accordion bg-white ms-0 ps-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                {{ slocale("Description") }}
              </a>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <textarea class="form-control bg-comp-grey" rows="3" placeholder="{{ slocale('Enter description') }}"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row">
        <button class="btn tertiary-button mb-auto w-auto me-auto" (click)="openModal(editQuestionnaireModal, 'modal-md')"><i class="la la-edit size-15 me-2 bold"></i>{{ slocale("Edit questions") }}</button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button" data-test="request-create-button" (click)="openModal(editQuestionnaireModal, 'modal-md')">{{ locale("locale_key.general.buttons.save") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Edit the questionnaire Modal -->
<ng-template #editQuestionnaireModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Editing the questionnaire") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{ slocale("Editing the questionnaire requires a deep understanding of its structure. Incorrect modifications may result in data inaccuracies and survey errors.If you are unsure, please contact your customer success representative.")
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn primary-button custom-button text-white" (click)="closeModal()">{{ slocale("Edit") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Superuser Modal -->
<ng-template #superuserModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <h6 class="mb-0">
      {{ slocale("Superuser") }}
    </h6>
    <div class="px-2">
      <div class="row bg-light-gray">
        <div class="col-4 border py-2">
          <p class="my-auto">{{ slocale("Name/ (Number of units)") }}</p>
        </div>
        <div class="col-4 border py-2">
          <p class="my-auto">{{ slocale("Company") }}</p>
        </div>
        <div class="col-4 border py-2">
          <p class="my-auto">{{ slocale("Organizational unit name") }}</p>
        </div>
      </div>
      <div class="row bg-comp-grey">
        <div class="col-4 thin-border-l thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("Silvia (1) (You)") }}</p>
        </div>
        <div class="col-4 thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("SustainLab Demo 1") }}</p>
        </div>
        <div class="col-4 thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
        </div>
      </div>
      <div class="row bg-comp-grey">
        <div class="col-4 thin-border-l thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("Silvia (1) (You)") }}</p>
        </div>
        <div class="col-4 thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("SustainLab Demo 1") }}</p>
        </div>
        <div class="col-4 thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
        </div>
      </div>
    </div>
    <h6 class="mt-3 mb-0">
      {{ slocale("Data reporter list") }}
    </h6>
    <div class="px-2">
      <div class="row bg-light-gray">
        <div class="col-4 border py-2">
          <p class="my-auto">{{ slocale("Name/ (Number of units)") }}</p>
        </div>
        <div class="col-4 border py-2">
          <p class="my-auto">{{ slocale("Company") }}</p>
        </div>
        <div class="col-4 border py-2">
          <p class="my-auto">{{ slocale("Organizational unit name") }}</p>
        </div>
      </div>
      <div class="row bg-comp-grey">
        <div class="col-4 thin-border-l thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("Silvia (1) (You)") }}</p>
        </div>
        <div class="col-4 thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("SustainLab Demo 1") }}</p>
        </div>
        <div class="col-4 thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
        </div>
      </div>
      <div class="row bg-comp-grey">
        <div class="col-4 thin-border-l thin-border-b thin-border-r py-2">
          <p class="my-auto">
            {{ slocale("Silvia (1)") }} <span class="badge badge-turquoise mx-2">{{ slocale("Invited") }}</span>
          </p>
        </div>
        <div class="col-4 thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("SustainLab Demo 1") }}</p>
        </div>
        <div class="col-4 thin-border-b thin-border-r py-2">
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <!-- <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn primary-button custom-button text-white" (click)="closeModal()">{{ slocale("Edit") }}</button> -->
    </ng-container>
  </div>
</ng-template>

<!-- Select superuser modal-->
<ng-template #selectSuperuserModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Select superuser to create final answer") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3 p-sm-1" style="border-top: none">
    <h6>
      {{ slocale("Select a superuser to create the final answer of this ESRS data point for reporting.") }}
    </h6>
    <div class="m-0 p-0 d-flex flex-row justify-content-between mb-2">
      <h6 class="mt-auto pb-0">
        {{ slocale("Superuser") }}
      </h6>
      <input type="text" class="form-control w-auto" placeholder="{{ slocale('Search') }}" />
    </div>
    <div class="px-2 mb-3">
      <div class="row btn-add">
        <div class="col-4 py-2">
          <p class="my-auto text-white">{{ slocale("Name") }}</p>
        </div>
        <div class="col-4 py-2">
          <p class="my-auto text-white">{{ slocale("Email") }}</p>
        </div>
        <div class="col-4 py-2">
          <p class="my-auto text-white">{{ slocale("Company") }}</p>
        </div>
      </div>
      <div class="row bg-comp-grey thin-border-l thin-border-b thin-border-r">
        <div class="col-4 py-2">
          <input type="radio" name="superuser" value="Silvia" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-4 py-2">
          <p class="my-auto">{{ slocale("SustainLab Demo 1") }}</p>
        </div>
        <div class="col-4 py-2">
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
        </div>
      </div>
      <div class="row thin-border-l thin-border-b thin-border-r">
        <div class="col-4 py-2">
          <input type="radio" name="superuser" value="Silvia" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-4 py-2">
          <p class="my-auto">{{ slocale("SustainLab Demo 1") }}</p>
        </div>
        <div class="col-4 py-2">
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
        </div>
      </div>
      <div class="row bg-comp-grey thin-border-l thin-border-b thin-border-r">
        <div class="col-4 py-2">
          <input type="radio" name="superuser" value="Silvia" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-4 py-2">
          <p class="my-auto">{{ slocale("SustainLab Demo 1") }}</p>
        </div>
        <div class="col-4 py-2">
          <p class="my-auto">{{ slocale("Office Stockholm") }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button" data-test="request-create-button">{{ slocale("Confirm") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Remove additional description Modal -->
<ng-template #removeDescModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Remove additional description") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{ slocale("Are you sure you want to remove the additional description?") }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn btn-danger custom-button text-white" (click)="closeModal()">{{ slocale("Remove") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Add additional description modal-->
<ng-template #addDescModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Add additional description") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3 p-sm-1" style="border-top: none">
    <div class="d-flex flex-column">
      <p class="pt-3 bold mb-0 pb-0">{{ slocale("Provide additional information about this ESRS data point for the reporters.") }}</p>
      <textarea class="form-control" rows="5" placeholder="{{ slocale('Enter description') }}"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button" data-test="request-create-button">{{ locale("locale_key.general.buttons.save") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Select from the list modal-->
<ng-template #selectListModal let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Select from the list to assign") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3 p-sm-1" style="border-top: none">
    <h6>
      {{ slocale("Select which people should provide data on this ESRS data point. Specify the organizational unit(s) they will report for.") }}
    </h6>
    <div class="d-flex flex-row m-0 p-0">
      <button class="btn green-button me-auto my-2 button-lg" (click)="openModal(waterManagementModal, 'modal-lg')">{{ slocale("8 assignments") }}</button>
    </div>
    <div class="m-0 p-0 d-flex flex-row justify-content-between mb-2">
      <h6 class="mt-auto pb-0 bold">
        {{ slocale("Superusers") }}
      </h6>
      <input type="text" class="form-control w-auto" placeholder="{{ slocale('Search') }}" />
    </div>
    <div class="px-2 mb-3">
      <div class="row btn-add">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" /><span class="my-auto text-white ms-2">{{ slocale("Name") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto text-white">{{ slocale("Email") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto text-white">{{ slocale("Company") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto text-white">{{ slocale("Number of organizational units") }}</p>
        </div>
        <div class="col-3 py-2 my-auto">
          <p class="my-auto text-white">{{ slocale("Organizational unit name") }}</p>
        </div>
      </div>
      <div class="row bg-comp-grey thin-border-l thin-border-b thin-border-r">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sl@sl.co") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <input type="number" class="form-control" />
        </div>
        <div class="col-3 py-2 d-flex flex-row justify-content-between my-auto">
          <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
          <i class="la la-edit size-15 me-2 my-auto" (click)="openModal(editUnitName, 'modal-md')"></i>
        </div>
      </div>
      <div class="row bg-white thin-border-l thin-border-b thin-border-r">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" value="Silvia" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sl@sl.co") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <input type="number" class="form-control" placeholder="{{ slocale('') }}" min="0" max="100" step="1" />
        </div>
        <div class="col-3 py-2 d-flex flex-row justify-content-between my-auto">
          <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
          <i class="la la-edit size-15 me-2 my-auto" (click)="openModal(editUnitName, 'modal-md')"></i>
        </div>
      </div>
      <div class="row bg-comp-grey thin-border-l thin-border-b thin-border-r">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" value="Silvia" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sl@sl.co") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <input type="number" class="form-control" placeholder="{{ slocale('') }}" min="0" max="100" step="1" />
        </div>
        <div class="col-3 py-2 d-flex flex-row justify-content-between my-auto">
          <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
          <i class="la la-edit size-15 me-2 my-auto" (click)="openModal(editUnitName, 'modal-md')"></i>
        </div>
      </div>
      <div class="row bg-white thin-border-l thin-border-b thin-border-r">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" value="Silvia" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sl@sl.co") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <input type="number" class="form-control" placeholder="{{ slocale('') }}" min="0" max="100" step="1" />
        </div>
        <div class="col-3 py-2 d-flex flex-row justify-content-between my-auto">
          <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
          <i class="la la-edit size-15 me-2 my-auto" (click)="openModal(editUnitName, 'modal-md')"></i>
        </div>
      </div>
    </div>
    <div class="m-0 p-0 d-flex flex-row justify-content-between mt-4">
      <h6 class="mb-0 pb-0 bold">
        {{ slocale("Data reporters") }}
      </h6>
    </div>
    <div class="px-2 mb-3">
      <div class="row btn-add">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" /><span class="my-auto text-white ms-2">{{ slocale("Name") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto text-white">{{ slocale("Email") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto text-white">{{ slocale("Company") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto text-white">{{ slocale("Number of organizational units") }}</p>
        </div>
        <div class="col-3 py-2 my-auto">
          <p class="my-auto text-white">{{ slocale("Organizational unit name") }}</p>
        </div>
      </div>
      <div class="row bg-comp-grey thin-border-l thin-border-b thin-border-r">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sl@sl.co") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <input type="number" class="form-control" />
        </div>
        <div class="col-3 py-2 d-flex flex-row justify-content-between my-auto">
          <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
          <i class="la la-edit size-15 me-2 my-auto" (click)="openModal(editUnitName, 'modal-md')"></i>
        </div>
      </div>
      <div class="row bg-white thin-border-l thin-border-b thin-border-r">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" value="Silvia" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sl@sl.co") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <input type="number" class="form-control" placeholder="{{ slocale('') }}" min="0" max="100" step="1" />
        </div>
        <div class="col-3 py-2 d-flex flex-row justify-content-between my-auto">
          <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
          <i class="la la-edit size-15 me-2 my-auto" (click)="openModal(editUnitName, 'modal-md')"></i>
        </div>
      </div>
      <div class="row bg-comp-grey thin-border-l thin-border-b thin-border-r">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" value="Silvia" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sl@sl.co") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <input type="number" class="form-control" placeholder="{{ slocale('') }}" min="0" max="100" step="1" />
        </div>
        <div class="col-3 py-2 d-flex flex-row justify-content-between my-auto">
          <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
          <i class="la la-edit size-15 me-2 my-auto" (click)="openModal(editUnitName, 'modal-md')"></i>
        </div>
      </div>
      <div class="row bg-white thin-border-l thin-border-b thin-border-r">
        <div class="col-3 py-2 my-auto">
          <input type="checkbox" name="superuser" value="Silvia" /> <span class="ms-2">{{ slocale("Silvia") }}</span>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sl@sl.co") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <p class="my-auto">{{ slocale("Sustain Lab Demo1") }}</p>
        </div>
        <div class="col-2 py-2 my-auto">
          <input type="number" class="form-control" placeholder="{{ slocale('') }}" min="0" max="100" step="1" />
        </div>
        <div class="col-3 py-2 d-flex flex-row justify-content-between my-auto">
          <div class="d-flex flex-column">
            <div class="d-flex flex-row justify-content-between my-auto">
              <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
              <i class="la la-trash me-2 my-auto"></i>
            </div>
            <div class="d-flex flex-row justify-content-between my-auto">
              <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
              <i class="la la-trash me-2 my-auto"></i>
            </div>
            <div class="d-flex flex-row justify-content-between my-auto">
              <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
              <i class="la la-trash me-2 my-auto"></i>
            </div>
            <div class="d-flex flex-row justify-content-between my-auto">
              <span class="my-auto">{{ slocale("Office Stockholm") }}</span>
              <i class="la la-trash me-2 my-auto"></i>
            </div>
          </div>
          <i class="la la-edit size-15 me-2 my-auto" (click)="openModal(editUnitName, 'modal-md')"></i>
        </div>
      </div>
    </div>
    <div class="m-0 p-0 d-flex flex-row justify-content-between mb-2">
      <button class="btn tertiary-button me-auto button-lg" (click)="openModal(waterManagementModal, 'modal-lg')"><i class="la la-user-plus"></i> {{ slocale("Add data reporter") }}</button>
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button" data-test="request-create-button">{{ slocale("Confirm") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Edit name of org unit modal-->
<ng-template #editUnitName let-modal>
  <div class="main-modal-header modal-header py-0 px-3" data-test="request-window-header">
    <h5 class="modal-title text-white">{{ slocale("Edit name of organizational unit") }}</h5>
    <button type="button" class="btn close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="false" style="font-size: 26px; font-weight: 300" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3 p-sm-1" style="border-top: none">
    <div class="d-flex flex-column">
      <p class="pt-3 mb-0 pb-0">{{ slocale("Edit the name of the organizational unit that Silvia should report data for.") }}</p>
      <input class="form-control" type="text" placeholder="{{ slocale('Unit A') }}" />
    </div>
  </div>
  <div class="modal-footer">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button" (click)="closeModal()" data-test="request-cancel-button">{{ locale("locale_key.general.buttons.cancel") }}</button>
      <button class="btn primary-button custom-button" data-test="request-create-button">{{ locale("locale_key.general.buttons.save") }}</button>
    </ng-container>
    <!-- <div class="ball-pulse loader-primary" *ngIf="">
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
      <div style="background-color: var(--main-dark-color1)"></div>
    </div> -->
  </div>
</ng-template>

<!-- Remove additional description Modal -->
<ng-template #removeDescModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Remove additional description") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{ slocale("Are you sure you want to remove the additional description?") }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn btn-danger custom-button text-white" (click)="closeModal()">{{ slocale("Remove") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Omit data point in reporting Modal -->
<ng-template #omitDataModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Omit data point in reporting") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
      slocale(
      "You have switched on the General phase in information toggle for all phase in data points. Turning off for this data point will keep the state of other currently omitted data points and show “Customized” text next to the General
      phase in information toggle."
      )
      }}
    </p>
    <p class="ps-2">
      {{ slocale("Are you sure you want to turn this toggle off?") }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn primary-button text-white" (click)="closeModal()">{{ slocale("Omit data point") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Edit ESRS data point Modal -->
<ng-template #editESRSModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Edit ESRS data point materiality and assignment") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
      slocale(
      "Reporters have already started filling in data, preventing changes to the materiality of ESRS data points to avoid data loss. For any assessment changes, reach out to your customer success representative.Assigning of ESRS data points
      for reporting is limited to adding new reporters. If you wish to cancel the data submission of a reporter you can close it from the data request page."
      )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn primary-button text-white" (click)="closeModal()">{{ slocale("Add reporters") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Show data point Modal -->
<ng-template #showDataPointModal>
  <div class="modal-header alert-modal-header">
    <h5 class="modal-title mb-0 pb-0 ms-2 mb-0 d-inline" id="myModalLabel14">{{ slocale("These data points appear in multiple ESRS data points.") }} <span class="ms-1">
        <i class="la la-info-circle info-popover-arrow size-15" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="position: relative; top: 1px"></i>
        <div class="dropdown-menu arrow p-2 info-popover">
          <p>{{ slocale("The data from this data point is utilized in multiple ESRS data points. As a result, the storage settings for these data points must be consistent, and the data should be stored in the same data category.") }}</p>
        </div>
      </span></h5>

    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="green-border-l m-0 p-0">
      <div class="d-flex flex-column border green-border-l p-3 p-sm-1 mb-2">
        <div class="row mb-2">
          <div class="col-4 fw-bold">{{ slocale("Description") }}</div>
          <div class="col-4 fw-bold">{{ slocale("Short Name") }}</div>
          <div class="col-4 fw-bold">{{ slocale("Data Type") }}</div>
        </div>
        <div class="row mb-1">
          <div class="col-4">{{ slocale("Organizational unit this policy applies to") }}</div>
          <div class="col-4">{{ slocale("Organizational unit") }}</div>
          <div class="col-4"><span class="bold">A</span>{{ slocale("Text - Calculated") }}</div>
        </div>
        <div class="row mb-1">
          <div class="col-12" style="background-color: #FCF4EB;">
            {{ slocale("Disclosure of whether and how policy addresses water management") }}
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-12" style="background-color: #FCF4EB;">
            {{ slocale("Disclosure of whether and how policy addresses the use and sourcing of water and marine resources in own operations") }}
          </div>
        </div>
      </div>
    </div>
    <div class="green-border-l m-0 p-0">
      <div class="d-flex flex-column border green-border-l p-3 p-sm-1">
        <div class="row mb-2">
          <div class="col-4 fw-bold">{{ slocale("Description") }}</div>
          <div class="col-4 fw-bold">{{ slocale("Short Name") }}</div>
          <div class="col-4 fw-bold">{{ slocale("Data Type") }}</div>
        </div>
        <div class="row mb-1">
          <div class="col-4">{{ slocale("Whether or not the company has a policy for water management in place") }}</div>
          <div class="col-4">{{ slocale("Existing water management policy") }}</div>
          <div class="col-4"><i class="las la-adjust"></i>{{ slocale("True / False") }}</div>
        </div>
        <div class="row mb-1">
          <div class="col-12" style="background-color: #FCF4EB;">
            {{ slocale("Disclosure of whether and how policy addresses water management") }}
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-12" style="background-color: #FCF4EB;">
            {{ slocale("Disclosure of whether and how policy addresses the use and sourcing of water and marine resources in own operations") }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="border-top: none">
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn primary-button text-white" (click)="closeModal()">{{ slocale("Add reporters") }}</button>
    </ng-container>
  </div>
</ng-template>

<!-- Edit data storage for ESRS data point Modal -->
<ng-template #editDataStorageModal>
  <div class="modal-header alert-modal-header">
    <h4 class="modal-title mb-0 pb-0 ms-2 mb-0" id="myModalLabel14">{{ slocale("Edit data storage for ESRS data point") }}</h4>
    <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true"><i class="las la-times fs-4"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p class="ps-2">
      {{
      slocale(
      "This ESRS data point shares data with at least one other data point. If you change the storage location, you must also update the other ESRS data point(s) to match, to ensure data integrity."
      )
      }}
    </p>
  </div>
  <div class="modal-footer" style="border-top: none">
    <!--Enable when loading-->
    <!-- <div class="ball-pulse loader-primary text-center m-auto" >
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
        <div style="background-color: var(--main-dark-color1)"></div>
      </div> -->
    <ng-container>
      <button type="button" class="btn secondary-button custom-button text-white" (click)="closeModal()">{{ slocale("Cancel") }}</button>
      <button type="button" class="btn primary-button text-white custom-button" (click)="closeModal()">{{ slocale("Edit") }}</button>
    </ng-container>
  </div>
</ng-template>